import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type ApAdvanceEntity = AuditEntity & {
  __typename?: 'APAdvanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  supplierAllocation: Array<ApAllocationEntity>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
};


export type ApAdvanceEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ApAllocationEntity = AuditEntity & {
  __typename?: 'APAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  allocationStatus: AllocationStatus;
  nonEditable: Scalars['Boolean'];
};

export type ApCreditNoteEntity = AuditEntity & {
  __typename?: 'APCreditNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  supplierID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  supplierAllocation: Array<ApAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  APCreditNoteItem?: Maybe<Array<ApCreditNoteItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type ApCreditNoteEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type ApCreditNoteItemEntity = AuditEntity & {
  __typename?: 'APCreditNoteItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID: Scalars['String'];
  APCreditNoteID: Scalars['String'];
  APCreditNote?: Maybe<ApCreditNoteEntity>;
  wbs?: Maybe<WbsEntity>;
  costItem?: Maybe<CostItemEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  APCreditNoteTaxItem?: Maybe<Array<ApCreditNoteTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type ApCreditNoteItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type ApCreditNoteTaxItemEntity = AuditEntity & {
  __typename?: 'APCreditNoteTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  APCreditNoteItemID: Scalars['String'];
  APCreditNoteItem?: Maybe<ApCreditNoteItemEntity>;
};

export type ApDebitNoteEntity = AuditEntity & {
  __typename?: 'APDebitNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  supplierID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  supplierAllocation: Array<ApAllocationEntity>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  eInvoiceDocRefInvoiceID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  APDebitNoteItem?: Maybe<Array<ApDebitNoteItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type ApDebitNoteEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type ApDebitNoteItemEntity = AuditEntity & {
  __typename?: 'APDebitNoteItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID: Scalars['String'];
  APDebitNoteID: Scalars['String'];
  APDebitNote?: Maybe<ApDebitNoteEntity>;
  wbs?: Maybe<WbsEntity>;
  costItem?: Maybe<CostItemEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  APDebitNoteTaxItem?: Maybe<Array<ApDebitNoteTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type ApDebitNoteItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type ApDebitNoteTaxItemEntity = AuditEntity & {
  __typename?: 'APDebitNoteTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  APDebitNoteItemID: Scalars['String'];
  APDebitNoteItem?: Maybe<ApDebitNoteItemEntity>;
};

export type ApeInvoiceTransactionEntity = AuditEntity & {
  __typename?: 'APEInvoiceTransactionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
};

export type ApInvoiceEntity = AuditEntity & {
  __typename?: 'APInvoiceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  supplierID: Scalars['String'];
  contractID: Scalars['String'];
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  discountAmt: Scalars['Float'];
  otherChargeAmt: Scalars['Float'];
  /** CustomFieldResolver */
  invoiceAmt?: Maybe<Scalars['Float']>;
  grnMatch?: Maybe<Array<Scalars['JSON']>>;
  supplierAllocation: Array<ApAllocationEntity>;
  APInvoiceItem?: Maybe<Array<ApInvoiceItemEntity>>;
  pendingPayment?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  invoiceDo?: Maybe<Array<DoEntity>>;
  /** CustomFieldResolver */
  outstanding?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type ApInvoiceEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type ApInvoiceInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  isEInvoice?: Maybe<Scalars['Boolean']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  eInvoiceDocRefInvoiceID?: Maybe<Scalars['String']>;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  glItem?: Maybe<Array<ApTransactionItemInput>>;
  discountAmt?: Maybe<Scalars['Float']>;
  otherChargeAmt?: Maybe<Scalars['Float']>;
  doAmt?: Maybe<Scalars['Float']>;
};

export type ApInvoiceItemEntity = AuditEntity & {
  __typename?: 'APInvoiceItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID: Scalars['String'];
  APInvoiceID: Scalars['String'];
  APInvoice?: Maybe<ApInvoiceEntity>;
  wbs?: Maybe<WbsEntity>;
  costItem?: Maybe<CostItemEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  doItemID?: Maybe<Scalars['String']>;
  doItem?: Maybe<DoItemEntity>;
  withDO: Scalars['Boolean'];
  APInvoiceTaxItem?: Maybe<Array<ApInvoiceTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type ApInvoiceItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type ApInvoiceItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  wbsID?: Maybe<Scalars['String']>;
  costClass?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  docAmt?: Maybe<Scalars['Float']>;
  baseAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  doID?: Maybe<Scalars['String']>;
  doItemID?: Maybe<Scalars['String']>;
};

export type ApInvoiceTaxItemEntity = AuditEntity & {
  __typename?: 'APInvoiceTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  APInvoiceItemID: Scalars['String'];
  APInvoiceItem?: Maybe<ApInvoiceItemEntity>;
};

export type ApPaymentEntity = AuditEntity & {
  __typename?: 'APPaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  bankAccount?: Maybe<BankAccountEntity>;
  supplierID: Scalars['String'];
  supplier?: Maybe<SupplierEntity>;
  supplierAllocation: Array<ApAllocationEntity>;
  batchPaymentHeaderID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
};


export type ApPaymentEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ApPaymentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt: Scalars['Float'];
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  bankAccountID: Scalars['String'];
  receiptStatus: ReceiptStatus;
  rejectedDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<BankDetailsInput>;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
};

export type ApRefundEntity = AuditEntity & {
  __typename?: 'APRefundEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  supplierAllocation: Array<ApAllocationEntity>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
};

export type ApTransactionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  isEInvoice?: Maybe<Scalars['Boolean']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  eInvoiceDocRefInvoiceID?: Maybe<Scalars['String']>;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  glItem?: Maybe<Array<ApTransactionItemInput>>;
};

export type ApTransactionItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  wbsID?: Maybe<Scalars['String']>;
  costClass?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  docAmt?: Maybe<Scalars['Float']>;
  baseAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
};

export type AccountSummary = {
  __typename?: 'AccountSummary';
  VOSum: Scalars['String'];
  claimSum: Scalars['String'];
  originalClaimSum: Scalars['String'];
  adjustmentSum: Scalars['String'];
  retentionSum: Scalars['String'];
  contractSum: Scalars['String'];
  progressRetention: Scalars['String'];
  maxRetention: Scalars['String'];
  expectedRetentionReleaseDate: Scalars['String'];
  cpcDate: Scalars['String'];
  cmgdDate: Scalars['String'];
  penaltyAmt: Scalars['String'];
  revisedContractSum: Scalars['String'];
  billtoDate: Scalars['String'];
  maxRetentionAmt: Scalars['String'];
  balancetoClaim: Scalars['String'];
  name: Scalars['String'];
  retentionReleaseSum: Scalars['String'];
  taxCode: Scalars['String'];
  customerID: Scalars['String'];
};

export type ActivityLog = {
  __typename?: 'ActivityLog';
  remarks?: Maybe<Scalars['String']>;
  logDate?: Maybe<Scalars['DateTime']>;
  defectStatus?: Maybe<DefectStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ActivityLogInput = {
  remarks?: Maybe<Scalars['String']>;
  logDate?: Maybe<Scalars['DateTime']>;
  defectStatus?: Maybe<DefectStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export enum ActivityStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
  Closed = 'CLOSED'
}

/** JsonType */
export type Address = {
  __typename?: 'Address';
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  postCodeName?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export type AddressInput = {
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  postCodeName?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export enum AddressType {
  Primary = 'PRIMARY',
  Delivery = 'DELIVERY',
  Site = 'SITE'
}

export type AdjustmentItem = AuditEntity & {
  __typename?: 'AdjustmentItem';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
};

export type AdjustmentTaxItem = AuditEntity & {
  __typename?: 'AdjustmentTaxItem';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
};

export type AdjustmentTypeEntity = AuditEntity & {
  __typename?: 'AdjustmentTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  code: Scalars['String'];
  description: Scalars['String'];
  commonStatus: CommonStatus;
  glAdjustmentDynamic?: Maybe<Array<GlAdjustmentDynamicEntity>>;
  contractDn?: Maybe<Array<ContractDnEntity>>;
  contractCn?: Maybe<Array<ContractCnEntity>>;
  clientDn?: Maybe<Array<ClientDnEntity>>;
  clientCn?: Maybe<Array<ClientCnEntity>>;
  subcontractDn?: Maybe<Array<SubcontractDnEntity>>;
  subcontractCn?: Maybe<Array<SubcontractCnEntity>>;
  dnToSubcon?: Maybe<Array<DNtoSubconEntity>>;
  cnToSubcon?: Maybe<Array<CNtoSubconEntity>>;
  apDn?: Maybe<Array<ApDebitNoteEntity>>;
  apCn?: Maybe<Array<ApCreditNoteEntity>>;
  dnToSupplier?: Maybe<Array<DNtoSupplierEntity>>;
  cnToSupplier?: Maybe<Array<CNtoSupplierEntity>>;
};

export type AdjustmentTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  code: Scalars['String'];
  description: Scalars['String'];
};

export type AlertAssignmentEntity = AuditEntity & {
  __typename?: 'AlertAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  alertID: Scalars['String'];
  alert?: Maybe<AlertEntity>;
  userID: Scalars['String'];
  reminder?: Maybe<Scalars['String']>;
  isAdminControl: Scalars['Boolean'];
};

export type AlertAssignmentInput = {
  alertID: Scalars['String'];
  userID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  reminder?: Maybe<Scalars['String']>;
};

export type AlertCountInput = {
  ID: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  sqlCode: Scalars['String'];
};

export type AlertEntity = AuditEntity & {
  __typename?: 'AlertEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  sqlCode: Scalars['String'];
  commonStatus: CommonStatus;
  alertAssignment?: Maybe<Array<AlertAssignmentEntity>>;
  /** CustomFieldResolver */
  defaultFromAdmin?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  alertCount?: Maybe<Scalars['Float']>;
};

export type AlertInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  userID: Scalars['String'];
  reminder: Scalars['String'];
};

export type AllStandardCoreBaseEntity = StandardAuditEntity & {
  __typename?: 'AllStandardCoreBaseEntity';
  created_ts: Scalars['DateTime'];
  created_by?: Maybe<Scalars['String']>;
  mod_ts: Scalars['DateTime'];
  mod_by?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export enum AllocationStatus {
  Credit = 'CREDIT',
  Retention = 'RETENTION',
  Pending = 'PENDING'
}

export enum ApprovalLevel {
  First = 'FIRST',
  Second = 'SECOND',
  Third = 'THIRD',
  Fourth = 'FOURTH',
  Fifth = 'FIFTH'
}

export type ApprovalList = {
  __typename?: 'ApprovalList';
  stepNo?: Maybe<Scalars['Float']>;
  roleID?: Maybe<Scalars['String']>;
  predicate?: Maybe<Scalars['String']>;
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
};

export type ApprovalListInput = {
  stepNo?: Maybe<Scalars['Float']>;
  roleID?: Maybe<Scalars['String']>;
  predicate?: Maybe<Scalars['String']>;
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
};

export type ApprovalLog = {
  __typename?: 'ApprovalLog';
  approvalID?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['DateTime']>;
  remarks?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};

export type ApprovalLogInput = {
  approvalID?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['DateTime']>;
  remarks?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};

export type ApprovalPolicyAssignmentDeleteInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  listlength?: Maybe<Scalars['Float']>;
  category?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};

export type ApprovalPolicyAssignmentEntity = AuditEntity & {
  __typename?: 'ApprovalPolicyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  approvalPolicyID: Scalars['String'];
  approvalPolicy?: Maybe<ApprovalPolicyEntity>;
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  approvalList?: Maybe<Array<Scalars['JSON']>>;
  category: Scalars['String'];
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  latestLimitAssignment: Scalars['JSON'];
};

export type ApprovalPolicyAssignmentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  approvalPolicyID?: Maybe<Scalars['String']>;
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  approvalList: Array<ApprovalPolicyList>;
  contractID?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
};

export type ApprovalPolicyDeleteInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  category?: Maybe<Scalars['String']>;
};

export type ApprovalPolicyEntity = AuditEntity & {
  __typename?: 'ApprovalPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  approvalList?: Maybe<Array<Scalars['JSON']>>;
  category: Scalars['String'];
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  approvalPolicyAssignment?: Maybe<Array<ApprovalPolicyAssignmentEntity>>;
  latestLimit: Scalars['JSON'];
};

export type ApprovalPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  category?: Maybe<Scalars['String']>;
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  approvalList: Array<ApprovalPolicyList>;
};

export type ApprovalPolicyList = {
  stepNo?: Maybe<Scalars['Float']>;
  predicate?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  roleID?: Maybe<Scalars['String']>;
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
};

export enum ApprovalStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  Pendingclose = 'PENDINGCLOSE',
  Cancelled = 'CANCELLED'
}

export type ApprovePaymentDetailInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  associateID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  batchPaymentDetailInput: Array<BatchPaymentDetailInput>;
};

export type ApprovedVendorsEntity = AuditEntity & {
  __typename?: 'ApprovedVendorsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  supplierName: Scalars['String'];
  registrationNo: Scalars['String'];
  description: Scalars['String'];
  address: Scalars['JSON'];
  email: Scalars['String'];
  gstRegNo: Scalars['String'];
  gstExpDate: Scalars['String'];
  bankDetails?: Maybe<Scalars['JSON']>;
  ratingType: RatingType;
  taxSchemeID: Scalars['String'];
  taxDetail?: Maybe<ConTaxSchemeEntity>;
};

export enum AsgMainStatus {
  Active = 'ACTIVE',
  Returned = 'RETURNED',
  PartiallyReturned = 'PARTIALLY_RETURNED',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED'
}

export type AssignmentEntity = AuditEntity & {
  __typename?: 'AssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  plantMachineryID: Scalars['String'];
  plantMachinery?: Maybe<PlantMachineryEntity>;
  costCategoryID?: Maybe<Scalars['String']>;
  costCategory?: Maybe<CostCategoryEntity>;
  siteID?: Maybe<Scalars['String']>;
  site?: Maybe<SiteEntity>;
  description?: Maybe<Scalars['String']>;
  assignedQty?: Maybe<Scalars['Float']>;
  outstandingQty?: Maybe<Scalars['Float']>;
  assignmentDate: Scalars['DateTime'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  cost?: Maybe<Scalars['Float']>;
  trackingLog?: Maybe<Array<Scalars['JSON']>>;
  assignmentRate: RateUomType;
  returnDate?: Maybe<Scalars['String']>;
  returnRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  assignmentStatus: AsgMainStatus;
  maintenance?: Maybe<Array<MaintenanceEntity>>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
};

export type AssignmentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  plantMachineryID: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  siteID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  assignedQty?: Maybe<Scalars['Float']>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  cost?: Maybe<Scalars['Float']>;
  assignmentRate: RateUomType;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type AssignmentReturnInput = {
  returnDate?: Maybe<Scalars['String']>;
  returnRemarks?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  cost?: Maybe<Scalars['Float']>;
  returnQty?: Maybe<Scalars['Float']>;
};

export type AssociatedDetails = {
  __typename?: 'AssociatedDetails';
  revisedBudgetSubcontractSum?: Maybe<Scalars['Float']>;
  subcontCostTotal?: Maybe<Scalars['Float']>;
  revisedBudgetMaterialSum?: Maybe<Scalars['Float']>;
  materialCostTotal?: Maybe<Scalars['Float']>;
  revisedBudgetPlantSum?: Maybe<Scalars['Float']>;
  plantCostTotal?: Maybe<Scalars['Float']>;
  revisedBudgetLabourSum?: Maybe<Scalars['Float']>;
  labourCostTotal?: Maybe<Scalars['Float']>;
  revisedBudgetMiscSum?: Maybe<Scalars['Float']>;
  miscCostTotal?: Maybe<Scalars['Float']>;
  revisedBudgetTotalSum?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  totalActualCost?: Maybe<Scalars['Float']>;
  latestOriginalDate?: Maybe<Scalars['DateTime']>;
  latestRevisedDate?: Maybe<Scalars['DateTime']>;
  latestActualCostDate?: Maybe<Scalars['DateTime']>;
  originalBudgetTotalSum?: Maybe<Scalars['Float']>;
  budgetVarianceAmt?: Maybe<Scalars['Float']>;
  origMaterialItemBudget?: Maybe<Scalars['JSON']>;
  origSubconItemBudget?: Maybe<Scalars['JSON']>;
  origPlantItemBudget?: Maybe<Scalars['JSON']>;
  origLabourItemBudget?: Maybe<Scalars['JSON']>;
  origMiscItemBudget?: Maybe<Scalars['JSON']>;
  revMaterialItemBudget?: Maybe<Scalars['JSON']>;
  revSubconItemBudget?: Maybe<Scalars['JSON']>;
  revPlantItemBudget?: Maybe<Scalars['JSON']>;
  revLabourItemBudget?: Maybe<Scalars['JSON']>;
  revMiscItemBudget?: Maybe<Scalars['JSON']>;
  doSumPerCC?: Maybe<Scalars['JSON']>;
  originalBudgetLabourSum?: Maybe<Scalars['Float']>;
  originalBudgetMaterialSum?: Maybe<Scalars['Float']>;
  originalBudgetMiscSum?: Maybe<Scalars['Float']>;
  originalBudgetPlantSum?: Maybe<Scalars['Float']>;
  originalBudgetSubcontractSum?: Maybe<Scalars['Float']>;
  transferredSubconBudget?: Maybe<Scalars['Float']>;
  transferredMaterialBudget?: Maybe<Scalars['Float']>;
  transferredLabourBudget?: Maybe<Scalars['Float']>;
  transferredPlantBudget?: Maybe<Scalars['Float']>;
  transferredMiscBudget?: Maybe<Scalars['Float']>;
  subconCommCost?: Maybe<Scalars['Float']>;
  subContractCommittedCost?: Maybe<Scalars['Float']>;
  subconBalCommCost?: Maybe<Scalars['Float']>;
  materialCommCost?: Maybe<Scalars['Float']>;
  labourCommCost?: Maybe<Scalars['Float']>;
  plantCommCost?: Maybe<Scalars['Float']>;
  miscCommCost?: Maybe<Scalars['Float']>;
  subconCostToDate?: Maybe<Scalars['Float']>;
  materialCostToDate?: Maybe<Scalars['Float']>;
  plantCostToDate?: Maybe<Scalars['Float']>;
  labourCostToDate?: Maybe<Scalars['Float']>;
  miscCostToDate?: Maybe<Scalars['Float']>;
  earnedValue?: Maybe<Scalars['Float']>;
  costVariance?: Maybe<Scalars['Float']>;
  cpi?: Maybe<Scalars['Float']>;
  completionPerc?: Maybe<Scalars['Float']>;
};

export enum AssociatedStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Blacklisted = 'BLACKLISTED'
}

export enum AssociationType {
  ClientAccount = 'CLIENT_ACCOUNT',
  SubcontractorAccount = 'SUBCONTRACTOR_ACCOUNT',
  SupplierAccount = 'SUPPLIER_ACCOUNT',
  ProjectPurchasing = 'PROJECT_PURCHASING',
  PurchasingOnBehalf = 'PURCHASING_ON_BEHALF'
}

export type AuditEntity = {
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
};

export type AzureStorageOutput = {
  __typename?: 'AzureStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  Url: Scalars['String'];
  MediaType: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  PrivateUrl?: Maybe<Scalars['String']>;
};

export type BqEntity = AuditEntity & {
  __typename?: 'BQEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  billNo?: Maybe<Scalars['Float']>;
  pageNo?: Maybe<Scalars['Float']>;
  itemNo?: Maybe<Scalars['Float']>;
  uomID: Scalars['String'];
  uom?: Maybe<UomEntity>;
  description: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  isLastNode: Scalars['Boolean'];
  bqWbsAssignment?: Maybe<Array<BqWbsAssignmentEntity>>;
  childrenBq?: Maybe<Array<BqEntity>>;
  parentBq?: Maybe<BqEntity>;
  /** CustomFieldResolver */
  numOfWbsBudgetDetail?: Maybe<Scalars['Float']>;
};


export type BqEntityChildrenBqArgs = {
  wbsID?: Maybe<Scalars['String']>;
};

export type BqExcelInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  billNo?: Maybe<Scalars['Float']>;
  pageNo?: Maybe<Scalars['Float']>;
  itemNo?: Maybe<Scalars['Float']>;
  wbsID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  rowNo?: Maybe<Scalars['Float']>;
  wbsName?: Maybe<Scalars['String']>;
  uomName?: Maybe<Scalars['String']>;
};

export type BqInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  billNo?: Maybe<Scalars['Float']>;
  pageNo?: Maybe<Scalars['Float']>;
  itemNo?: Maybe<Scalars['Float']>;
  wbsID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  rowNo?: Maybe<Scalars['Float']>;
};

export type BqWbsAssignmentEntity = AuditEntity & {
  __typename?: 'BQWbsAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  bqID: Scalars['String'];
  bq?: Maybe<BqEntity>;
  wbsID: Scalars['String'];
  wbs?: Maybe<WbsEntity>;
};

export type BqWbsAssignmentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  bqID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
};

export type BankAccountCancellationInput = {
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type BankAccountDeleteInput = {
  ID: Scalars['String'];
};

export type BankAccountEntity = AuditEntity & {
  __typename?: 'BankAccountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  accountHolder: Scalars['String'];
  accountNo: Scalars['String'];
  branch: Scalars['String'];
  swiftCode?: Maybe<Scalars['String']>;
  contactNo: Scalars['String'];
  status: BankAccountStatus;
  address: Scalars['JSON'];
  contactPerson: Array<Scalars['JSON']>;
  cancellationDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  glAccountCode?: Maybe<Scalars['String']>;
  contract?: Maybe<Array<ContractEntity>>;
  contractReceipt?: Maybe<Array<ContractReceiptEntity>>;
  contractAdvance?: Maybe<Array<ContractAdvanceEntity>>;
  contractRefund?: Maybe<Array<ContractRefundEntity>>;
  subcontractAdvance?: Maybe<Array<SubcontractAdvanceEntity>>;
  subcontractPayment?: Maybe<Array<SubcontractPaymentEntity>>;
  subcontractRefund?: Maybe<Array<SubcontractRefundEntity>>;
  APAdvance?: Maybe<Array<ApAdvanceEntity>>;
  APPayment?: Maybe<Array<ApPaymentEntity>>;
  batchPaymentHeader?: Maybe<Array<BatchPaymentHeaderEntity>>;
  company?: Maybe<SubscriptionCompanyType>;
};

export type BankAccountInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  accountHolder: Scalars['String'];
  accountNo: Scalars['String'];
  contactNo: Scalars['String'];
  companyID: Scalars['String'];
  branch: Scalars['String'];
  swiftCode?: Maybe<Scalars['String']>;
  address: AddressInput;
  contactPerson: Array<ContactPersonInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export enum BankAccountStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED'
}

/** JsonType */
export type BankDetails = {
  __typename?: 'BankDetails';
  name?: Maybe<Scalars['String']>;
  acctHolder?: Maybe<Scalars['String']>;
  acctNo?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
};

export type BankDetailsInput = {
  name?: Maybe<Scalars['String']>;
  acctHolder?: Maybe<Scalars['String']>;
  acctNo?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
};

export type BaseFuelInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  tankID: Scalars['String'];
  tankTable: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  file: Array<Scalars['Upload']>;
};

export type BaseInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type BatchPaymentDetailDeleteInput = {
  ID: Scalars['String'];
};

export type BatchPaymentDetailEntity = AuditEntity & {
  __typename?: 'BatchPaymentDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  batchPaymentHeaderID: Scalars['String'];
  batchPaymentHeader?: Maybe<BatchPaymentHeaderEntity>;
  docRefTable: Scalars['String'];
  docRefID: Scalars['String'];
  associateRefTable: Scalars['String'];
  associateRefID: Scalars['String'];
  paymentAmt: Scalars['Float'];
  APPaymentID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  ledgerInfo?: Maybe<Scalars['JSON']>;
};

export type BatchPaymentDetailInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  batchPaymentHeaderID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  docRefID?: Maybe<Scalars['String']>;
  associateRefTable?: Maybe<Scalars['String']>;
  associateRefID?: Maybe<Scalars['String']>;
  paymentAmt?: Maybe<Scalars['Float']>;
  APPaymentID?: Maybe<Scalars['String']>;
};

export type BatchPaymentHeaderDeleteInput = {
  ID: Scalars['String'];
};

export type BatchPaymentHeaderEntity = AuditEntity & {
  __typename?: 'BatchPaymentHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  bankAccountID: Scalars['String'];
  bankAccount?: Maybe<BankAccountEntity>;
  submittedDate?: Maybe<Scalars['String']>;
  submittedBy?: Maybe<Scalars['String']>;
  paidDate?: Maybe<Scalars['String']>;
  paidBy?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  batchPaymentDetail?: Maybe<Array<BatchPaymentDetailEntity>>;
  /** CustomFieldResolver */
  totalAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  batchPaymentAssocDetail?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  batchPaymentDocNum?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
};

export type BatchPaymentHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  description: Scalars['String'];
  bankAccountID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type BillInterestEntity = AuditEntity & {
  __typename?: 'BillInterestEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  customerID?: Maybe<Scalars['String']>;
  customer?: Maybe<CustomerEntity>;
  contractID?: Maybe<Scalars['String']>;
  contract?: Maybe<ContractEntity>;
  docRefTable?: Maybe<Scalars['String']>;
  docID: Scalars['String'];
  billDate: Scalars['String'];
  billAmt: Scalars['Float'];
  interestAmt: Scalars['Float'];
  waiveAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
};

export type BillInterestInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
  docID: Scalars['String'];
  billDate: Scalars['String'];
  billAmt: Scalars['Float'];
  interestAmt: Scalars['Float'];
  waiveAmt: Scalars['Float'];
  remarks: Scalars['String'];
};

export type BudgetAmt = {
  __typename?: 'BudgetAmt';
  budgetAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdT?: Maybe<Scalars['DateTime']>;
  modTs?: Maybe<Scalars['DateTime']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type BudgetAmtInput = {
  budgetAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdT?: Maybe<Scalars['DateTime']>;
  modTs?: Maybe<Scalars['DateTime']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export enum BudgetChecker {
  Ignore = 'IGNORE',
  Warning = 'WARNING',
  Block = 'BLOCK'
}

export type BudgetInfo = {
  __typename?: 'BudgetInfo';
  isExceeded?: Maybe<Scalars['Boolean']>;
  isAllocated?: Maybe<Scalars['Boolean']>;
  budgetAmt?: Maybe<Scalars['Float']>;
  totalAmt?: Maybe<Scalars['Float']>;
};

export enum BudgetStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING'
}

export enum BudgetType {
  Cost = 'COST',
  Revenue = 'REVENUE'
}

export type BulkSubcontractorInput = {
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  paymentTo?: Maybe<Scalars['String']>;
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<ContactInput>;
  ratingType: RatingType;
  cidbDetails: CidbDetailsInput;
  costCategoryID?: Maybe<Scalars['String']>;
  pkkDetails?: Maybe<PkkDetailsInput>;
  statusRemarks?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<BankDetailsInput>;
  nominatedSC?: Maybe<Scalars['Boolean']>;
  creditorAC?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
};

export type BulkSupplierInput = {
  currencyID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<ContactInput>;
  ratingType: RatingType;
  bankDetails?: Maybe<BankDetailsInput>;
  creditorAC?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};

export type CbExportDeleteInput = {
  ID: Scalars['String'];
};

export type CbExportDetailEntity = AuditEntity & {
  __typename?: 'CBExportDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  cbExportID: Scalars['String'];
  bankAccountID: Scalars['String'];
  accountHolder: Scalars['String'];
  accountNo: Scalars['String'];
  branch: Scalars['String'];
  clientName: Scalars['String'];
  docRefID: Scalars['String'];
  docRefTable?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  docDate: Scalars['String'];
  docNo: Scalars['String'];
  docRef?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  cbExport?: Maybe<CbExportEntity>;
};

export type CbExportDetailInput = {
  advCustomerID: Array<Scalars['String']>;
  advCustomerRefTable: Scalars['String'];
  recCustomerID: Array<Scalars['String']>;
  recCustomerRefTable: Scalars['String'];
  refundCustomerID: Array<Scalars['String']>;
  refundCustomerRefTable: Scalars['String'];
  advSubcontractorID: Array<Scalars['String']>;
  advSubcontractorRefTable: Scalars['String'];
  recSubcontractorID: Array<Scalars['String']>;
  recSubcontractorRefTable: Scalars['String'];
  refundSubcontractorID: Array<Scalars['String']>;
  refundSubcontractorRefTable: Scalars['String'];
  advSupplierID: Array<Scalars['String']>;
  advSupplierRefTable: Scalars['String'];
  recSupplierID: Array<Scalars['String']>;
  recSupplierRefTable: Scalars['String'];
  refundSupplierID: Array<Scalars['String']>;
  refundSupplierRefTable: Scalars['String'];
};

export type CbExportEntity = AuditEntity & {
  __typename?: 'CBExportEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  transactionDate?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  cbExportDetail?: Maybe<Array<CbExportDetailEntity>>;
};

export type CbExportInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};

export type CbLedgerInput = {
  IDs: Array<Scalars['String']>;
  docRefTable: Scalars['String'];
};

export type CNtoSubconEntity = AuditEntity & {
  __typename?: 'CNtoSubconEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  chargeToRevenue: Scalars['Boolean'];
  eInvoiceDocRefInvoiceID?: Maybe<Scalars['String']>;
  CNtoSubconItem?: Maybe<Array<CNtoSubconItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  transactionType?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type CNtoSubconEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type CNtoSubconItemEntity = AuditEntity & {
  __typename?: 'CNtoSubconItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  revenueCategoryID?: Maybe<Scalars['String']>;
  CNtoSubconID: Scalars['String'];
  CNtoSubcon?: Maybe<CNtoSubconEntity>;
  costItem?: Maybe<CostItemEntity>;
  revenueCategory?: Maybe<RevenueCategoryEntity>;
  wbs?: Maybe<WbsEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  CNtoSubconTaxItem?: Maybe<Array<CNtoSubconTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type CNtoSubconItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type CNtoSubconTaxItemEntity = AuditEntity & {
  __typename?: 'CNtoSubconTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  subcontractID: Scalars['String'];
  CNtoSubconItemID: Scalars['String'];
  CNtoSubconItem?: Maybe<CNtoSubconItemEntity>;
};

export type CNtoSupplierEntity = AuditEntity & {
  __typename?: 'CNtoSupplierEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  supplierID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  supplierAllocation: Array<ApAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  eInvoiceDocRefInvoiceID?: Maybe<Scalars['String']>;
  CNtoSupplierItem?: Maybe<Array<CNtoSupplierItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type CNtoSupplierEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type CNtoSupplierItemEntity = AuditEntity & {
  __typename?: 'CNtoSupplierItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID: Scalars['String'];
  CNtoSupplierID: Scalars['String'];
  CNtoSupplier?: Maybe<CNtoSupplierEntity>;
  wbs?: Maybe<WbsEntity>;
  costItem?: Maybe<CostItemEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  CNtoSupplierTaxItem?: Maybe<Array<CNtoSupplierTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type CNtoSupplierItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type CNtoSupplierTaxItemEntity = AuditEntity & {
  __typename?: 'CNtoSupplierTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  CNtoSupplierItemID: Scalars['String'];
  CNtoSupplierItem?: Maybe<CNtoSupplierItemEntity>;
};

export type CalendarDeleteInput = {
  ID: Scalars['String'];
};

export type CalendarEntity = AuditEntity & {
  __typename?: 'CalendarEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  stateID: Scalars['String'];
  holidayTypeID: Scalars['String'];
  state: Scalars['String'];
  year: Scalars['String'];
  isDefault: Scalars['Boolean'];
  weekend: Scalars['JSON'];
  commonStatus: CommonStatus;
  publicHolConnection?: Maybe<Array<CalendarPhPolicyEntity>>;
  holidayType?: Maybe<HolidayTypeEntity>;
  publicHoliday: Array<PublicHolidayEntity>;
  costCategory: Array<CalendarEntity>;
};

export type CalendarInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  state?: Maybe<Scalars['String']>;
  stateID?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  weekend?: Maybe<Scalars['JSON']>;
};

export type CalendarPhPolicyDeleteInput = {
  ID: Scalars['String'];
};

export type CalendarPhPolicyEntity = AuditEntity & {
  __typename?: 'CalendarPHPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  calendarID: Scalars['String'];
  publicHolidayID: Scalars['String'];
  holidayTypeID: Scalars['String'];
  calendar?: Maybe<CalendarEntity>;
  publicHoliday?: Maybe<PublicHolidayEntity>;
  holidayType?: Maybe<HolidayTypeEntity>;
};

export type CalendarPhPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  publicHolidayID?: Maybe<Scalars['String']>;
  calendarID?: Maybe<Scalars['String']>;
};

export type CalendarWeekendPolicyDeleteInput = {
  ID: Scalars['String'];
};

export type CalendarWeekendPolicyEntity = AuditEntity & {
  __typename?: 'CalendarWeekendPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  calendarID: Scalars['String'];
  daysOfWeek: DaysofWeek;
};

export type CalendarWeekendPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  siteID?: Maybe<Scalars['String']>;
  calendarID?: Maybe<Scalars['String']>;
  daysOfWeek: DaysofWeek;
};

export type CancelInput = {
  companyID?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
};

export type CashFlowEntity = AuditEntity & {
  __typename?: 'CashFlowEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  cashFlowSum: Scalars['Float'];
  inflowAmt: Scalars['Float'];
  outFlowAmt: Scalars['Float'];
  netCashFlowAmt: Scalars['Float'];
  projectedCashFlowAmt: Scalars['Float'];
};

export type CashFlowInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  cashFlowSum: Scalars['Float'];
  inflowAmt: Scalars['Float'];
  outFlowAmt: Scalars['Float'];
  netCashFlowAmt: Scalars['Float'];
  projectedCashFlowAmt: Scalars['Float'];
};

export type CheckMultipleWbsBudgetDetailInput = {
  wbsID: Scalars['String'];
  costCategoryID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
};

export type CheckingInput = {
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type ChecklistCategoryEntity = AuditEntity & {
  __typename?: 'ChecklistCategoryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  color: Scalars['String'];
  safetyChecklist?: Maybe<Array<SafetyChecklistEntity>>;
};

export type CidbDetails = {
  __typename?: 'CidbDetails';
  registrationNo?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
};

export type CidbDetailsInput = {
  registrationNo?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
};

export type ClaimDetailsInput = {
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ClaimLog = {
  __typename?: 'ClaimLog';
  logDate?: Maybe<Scalars['String']>;
  approvalAmt?: Maybe<Scalars['Float']>;
  approvalID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};

export type ClaimLogInput = {
  logDate?: Maybe<Scalars['String']>;
  approvalAmt?: Maybe<Scalars['Float']>;
  approvalID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};

export type ClaimRetentionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docDate: Scalars['String'];
  retentionAmt: Scalars['Float'];
  taxSchemeID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmt: Scalars['Float'];
  docDueDate?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};

export type ClaimSummary = {
  __typename?: 'ClaimSummary';
  previousFinalClaim?: Maybe<Scalars['Float']>;
  previousClaim?: Maybe<Scalars['Float']>;
  previousMOS?: Maybe<Scalars['Float']>;
  previousVOClaim?: Maybe<Scalars['Float']>;
  previousDeduction?: Maybe<Scalars['Float']>;
  previousDayWork?: Maybe<Scalars['Float']>;
  retentionPerc?: Maybe<Scalars['Float']>;
  previousRetentionAmt?: Maybe<Scalars['Float']>;
  previousRetentionSum?: Maybe<Scalars['Float']>;
  retentionBalance?: Maybe<Scalars['Float']>;
  advanceSum?: Maybe<Scalars['Float']>;
  advanceRecouped?: Maybe<Scalars['Float']>;
  advanceBalance?: Maybe<Scalars['Float']>;
  deductionSum?: Maybe<Scalars['Float']>;
  deductionRecouped?: Maybe<Scalars['Float']>;
  deductionBalance?: Maybe<Scalars['Float']>;
  checkPendingSubmit?: Maybe<Scalars['Boolean']>;
  previousDocDate?: Maybe<Scalars['DateTime']>;
  submittedDocDate?: Maybe<Scalars['DateTime']>;
};

export type ClientCnEntity = AuditEntity & {
  __typename?: 'ClientCNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  clientCNItem?: Maybe<Array<ClientCnItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type ClientCnEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ClientCnItemEntity = AuditEntity & {
  __typename?: 'ClientCNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  costItemID: Scalars['String'];
  clientCNID: Scalars['String'];
  clientCN?: Maybe<ClientCnEntity>;
  wbs?: Maybe<WbsEntity>;
  costItem?: Maybe<CostItemEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  clientCNTaxItem?: Maybe<Array<ClientCnTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type ClientCnItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type ClientCnTaxItemEntity = AuditEntity & {
  __typename?: 'ClientCNTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  clientCNItemID: Scalars['String'];
  clientCNItem?: Maybe<ClientCnItemEntity>;
};

export type ClientConAdjustmentTransaction = AuditEntity & {
  __typename?: 'ClientConAdjustmentTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
};

export type ClientContractAccReceipt = AuditEntity & {
  __typename?: 'ClientContractAccReceipt';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
};

export type ClientDnEntity = AuditEntity & {
  __typename?: 'ClientDNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  clientDNItem?: Maybe<Array<ClientDnItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type ClientDnEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ClientDnItemEntity = AuditEntity & {
  __typename?: 'ClientDNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  costItemID: Scalars['String'];
  clientDNID: Scalars['String'];
  clientDN?: Maybe<ClientDnEntity>;
  wbs?: Maybe<WbsEntity>;
  costItem?: Maybe<CostItemEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  clientDNTaxItem?: Maybe<Array<ClientDnTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type ClientDnItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type ClientDnTaxItemEntity = AuditEntity & {
  __typename?: 'ClientDNTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  clientDNItemID: Scalars['String'];
  clientDNItem?: Maybe<ClientDnItemEntity>;
};

export type ClientEInvoiceAccTransaction = AuditEntity & {
  __typename?: 'ClientEInvoiceAccTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
};

export type ClientEInvoiceAdjustmentTransaction = AuditEntity & {
  __typename?: 'ClientEInvoiceAdjustmentTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
};

export type ClientEInvoiceClaimTransaction = AuditEntity & {
  __typename?: 'ClientEInvoiceClaimTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  claimDocNo?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  claimAmt?: Maybe<Scalars['Float']>;
  sequenceNo?: Maybe<Scalars['Float']>;
  revisedNo?: Maybe<Scalars['Float']>;
  submittedDate: Scalars['String'];
  voDisplayAmt?: Maybe<Scalars['Float']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  balanceAdvanceAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  balanceDeductionAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  claimVOLog?: Maybe<Array<Scalars['JSON']>>;
  isRetention: Scalars['Boolean'];
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
};

export type ClientSoaInfo = {
  __typename?: 'ClientSOAInfo';
  totalOsIvCount?: Maybe<Scalars['Float']>;
  totalOsIvAmt?: Maybe<Scalars['Float']>;
};

export type CollectionActivityDeleteId = {
  ID?: Maybe<Scalars['String']>;
};

export type CollectionActivityEntity = AuditEntity & {
  __typename?: 'CollectionActivityEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contactID?: Maybe<Scalars['String']>;
  contact?: Maybe<ContactEntity>;
  customerID: Scalars['String'];
  customer?: Maybe<CustomerEntity>;
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  date: Scalars['String'];
  time: Scalars['String'];
  description: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  activityStatus: ActivityStatus;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
};

export type CollectionActivityInput = {
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  contactID?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  time: Scalars['String'];
  description: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  activityStatus?: Maybe<ActivityStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CollectionActivityStatusInput = {
  ID?: Maybe<Scalars['String']>;
  contactID?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CommonBaseInput = {
  id?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export enum CommonStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Block = 'BLOCK'
}

export type Company = {
  __typename?: 'Company';
  CompanyID?: Maybe<Scalars['String']>;
  ParentCompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  BaseCurrencyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  SSTNo?: Maybe<Scalars['String']>;
  GSTNo?: Maybe<Scalars['String']>;
  GSTExpiryDate?: Maybe<Scalars['String']>;
  Image?: Maybe<Scalars['String']>;
  ImageUrl?: Maybe<Scalars['String']>;
  ImageBucketFile?: Maybe<Scalars['String']>;
  WebsiteUrl?: Maybe<Scalars['String']>;
  RegionID?: Maybe<Scalars['String']>;
  ImageLogoPosition?: Maybe<Scalars['String']>;
  WatermarkImplementation?: Maybe<Scalars['Boolean']>;
  WatermarkWording?: Maybe<Scalars['String']>;
  IsoImplementation?: Maybe<Scalars['Boolean']>;
  IsoLogoPosition?: Maybe<Scalars['String']>;
  CompanyTax?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<Scalars['String']>;
  Address?: Maybe<Address>;
};

export type CompanyEntity = AuditEntity & {
  __typename?: 'CompanyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  AccountID: Scalars['String'];
  ParentCompanyID?: Maybe<Scalars['String']>;
  BaseCurrencyID: Scalars['String'];
  Currency: CurrencyEntity;
  Name: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  CompanyRegNo: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Address?: Maybe<Address>;
  ContactNo?: Maybe<Scalars['String']>;
  OfficeTel?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  SSTNo?: Maybe<Scalars['String']>;
  GSTNo?: Maybe<Scalars['String']>;
  GSTExpiryDate?: Maybe<Scalars['String']>;
  GroupGSTRegNo?: Maybe<Scalars['String']>;
  Image?: Maybe<Scalars['String']>;
  ImageUrl?: Maybe<Scalars['String']>;
  ImageBucketFile?: Maybe<Scalars['String']>;
  WebsiteUrl?: Maybe<Scalars['String']>;
  RegionID?: Maybe<Scalars['String']>;
  ImageLogoPosition?: Maybe<Scalars['String']>;
  WatermarkImplementation: Scalars['Boolean'];
  WatermarkWording?: Maybe<Scalars['String']>;
  IsoImplementation: Scalars['Boolean'];
  IsoLogoPosition?: Maybe<Scalars['String']>;
  CompanyTax: CompanyTax;
  TourismTaxNo?: Maybe<Scalars['String']>;
  Tin?: Maybe<Scalars['String']>;
  eSign?: Maybe<Scalars['String']>;
  eSignExpiryDate?: Maybe<Scalars['DateTime']>;
  eInvIntegrated: Scalars['Boolean'];
  eInvStartDate?: Maybe<Scalars['String']>;
  eInvEndDate?: Maybe<Scalars['String']>;
  eInvClientID?: Maybe<Scalars['String']>;
  eInvClientSecret?: Maybe<Scalars['String']>;
  eInvClientSecret2?: Maybe<Scalars['String']>;
  RecordStatus: Scalars['String'];
  companyMsicAssignment?: Maybe<Array<CompanyMsicAssignmentEntity>>;
};

export type CompanyInput = {
  CompanyID?: Maybe<Scalars['String']>;
  BaseCurrencyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Address?: Maybe<AddressInput>;
  ContactNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  SSTNo?: Maybe<Scalars['String']>;
  GSTNo?: Maybe<Scalars['String']>;
  GSTExpiryDate?: Maybe<Scalars['String']>;
  GroupGSTRegNo?: Maybe<Scalars['String']>;
  WebsiteUrl?: Maybe<Scalars['String']>;
  RegionID?: Maybe<Scalars['String']>;
  ImageLogoPosition?: Maybe<Scalars['String']>;
  WatermarkWording?: Maybe<Scalars['String']>;
  WatermarkImplementation?: Maybe<Scalars['Boolean']>;
  IsoImplementation?: Maybe<Scalars['Boolean']>;
  IsoLogoPosition?: Maybe<Scalars['String']>;
  eInvIntegrated?: Maybe<Scalars['Boolean']>;
  Tin?: Maybe<Scalars['String']>;
  eSign?: Maybe<Scalars['String']>;
  eSignExpiryDate?: Maybe<Scalars['DateTime']>;
  eInvStartDate?: Maybe<Scalars['String']>;
  eInvEndDate?: Maybe<Scalars['String']>;
  eInvClientID?: Maybe<Scalars['String']>;
  eInvClientSecret?: Maybe<Scalars['String']>;
  eInvClientSecret2?: Maybe<Scalars['String']>;
  CompanyTax?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<Scalars['String']>;
  FileImage?: Maybe<Scalars['Upload']>;
};

export type CompanyLogoInput = {
  ImageLogoPosition?: Maybe<Scalars['String']>;
  WatermarkImplementation?: Maybe<Scalars['Boolean']>;
  WatermarkWording?: Maybe<Scalars['String']>;
  IsoImplementation?: Maybe<Scalars['Boolean']>;
  IsoLogoPosition?: Maybe<Scalars['String']>;
};

export type CompanyMsicAssignmentEntity = AuditEntity & {
  __typename?: 'CompanyMsicAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  company?: Maybe<CompanyEntity>;
  eInvoiceMsicCodeID: Scalars['String'];
  eInvoiceMsicCode?: Maybe<EInvoiceMsicEntity>;
  isDefault: Scalars['Boolean'];
};

export type CompanySignatureInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  documentType: Scalars['String'];
  isToggle: Scalars['Boolean'];
};

export enum CompanyTax {
  NoTax = 'NO_TAX',
  SstNo = 'SST_NO',
  GstNo = 'GST_NO'
}

export type ConDocTypeEntity = AuditEntity & {
  __typename?: 'ConDocTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  docName: Scalars['String'];
  commonStatus: CommonStatus;
  docCode: ContractDocNum;
  code?: Maybe<Scalars['String']>;
  numberFormat: Array<ConNumberFormatEntity>;
  docNumHeader: Array<DocNumHeaderEntity>;
};

export type ConNumberFormatEntity = AuditEntity & {
  __typename?: 'ConNumberFormatEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docTypeID: Scalars['String'];
  numberFormat: Scalars['String'];
  numberLength: Scalars['Float'];
  prefix: Scalars['String'];
  commonStatus: CommonStatus;
  docType: ConDocTypeEntity;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  softwareCode: Scalars['String'];
  documentDivider: Scalars['String'];
  prefixExtra?: Maybe<Scalars['String']>;
  runningNum: Array<ConRunningNumberSetupEntity>;
  company?: Maybe<SubscriptionCompanyType>;
};

export type ConRunningNumberSetupEntity = AuditEntity & {
  __typename?: 'ConRunningNumberSetupEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  numberFormatID: Scalars['String'];
  nextNumber: Scalars['Float'];
  accountID: Scalars['String'];
  numberFormat: ConNumberFormatEntity;
};

export type ConTaxEffectiveDateEntity = AuditEntity & {
  __typename?: 'ConTaxEffectiveDateEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  date?: Maybe<Scalars['DateTime']>;
  taxRate: Scalars['Float'];
};

export type ConTaxEffectiveDateInput = {
  taxSchemeID: Scalars['String'];
  date: Scalars['String'];
  description: Scalars['String'];
};

export type ConTaxEffectiveDateRateInput = {
  effectiveDate?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
};

export type ConTaxSchemeEffectiveInput = {
  ID?: Maybe<Scalars['String']>;
  taxTypeID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isClaimable?: Maybe<Scalars['Boolean']>;
  taxClass?: Maybe<TaxClass>;
  taxCategory?: Maybe<TaxCategory>;
  commonStatus?: Maybe<CommonStatus>;
  taxSchemeID: Scalars['String'];
  effectiveDateRate: Array<ConTaxEffectiveDateRateInput>;
};

export type ConTaxSchemeEntity = AuditEntity & {
  __typename?: 'ConTaxSchemeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxTypeID: Scalars['String'];
  taxType?: Maybe<ConTaxTypeEntity>;
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  taxCategory: TaxCategory;
  taxClass: TaxClass;
  isClaimable: Scalars['Boolean'];
  commonStatus: CommonStatus;
  /** CustomFieldResolver */
  latestTax?: Maybe<TaxObject>;
  taxEffective?: Maybe<Array<ConTaxEffectiveDateEntity>>;
  contract?: Maybe<Array<ContractEntity>>;
  subcontract?: Maybe<Array<SubcontractEntity>>;
  subcontractCostWbs?: Maybe<Array<SubcontractCostWbsEntity>>;
  poItem?: Maybe<Array<PoItemEntity>>;
  apInvoiceItem?: Maybe<Array<ApInvoiceItemEntity>>;
  revenueCategory?: Maybe<Array<RevenueCategoryEntity>>;
  miscExpense?: Maybe<Array<MiscExpenseEntity>>;
  projectExpenseItem?: Maybe<Array<ProjectExpenseItemEntity>>;
  customer?: Maybe<Array<CustomerEntity>>;
  supplier?: Maybe<Array<SupplierEntity>>;
  subcontractor?: Maybe<Array<SubcontractorEntity>>;
  costItem?: Maybe<Array<CostItemEntity>>;
  approvedVendors?: Maybe<Array<ApprovedVendorsEntity>>;
  contractDNItem?: Maybe<Array<ContractDnItemEntity>>;
  contractCNItem?: Maybe<Array<ContractCnItemEntity>>;
  clientDNItem?: Maybe<Array<ClientDnItemEntity>>;
  clientCNItem?: Maybe<Array<ClientCnItemEntity>>;
  contractMiscInvoiceItem?: Maybe<Array<ContractMiscInvoiceItemEntity>>;
  subcontractMiscInvoiceItem?: Maybe<Array<SubcontractMiscInvoiceItemEntity>>;
  subcontractDNItem?: Maybe<Array<SubcontractDnItemEntity>>;
  subcontractCNItem?: Maybe<Array<SubcontractCnItemEntity>>;
  DNtoSubconItem?: Maybe<Array<DNtoSubconItemEntity>>;
  CNtoSubconItem?: Maybe<Array<CNtoSubconItemEntity>>;
  APDebitNoteItem?: Maybe<Array<ApDebitNoteItemEntity>>;
  APCreditNoteItem?: Maybe<Array<ApCreditNoteItemEntity>>;
  DNtoSupplierItem?: Maybe<Array<DNtoSupplierItemEntity>>;
  CNtoSupplierItem?: Maybe<Array<CNtoSupplierItemEntity>>;
  contractClaim?: Maybe<Array<ContractClaimEntity>>;
  subcontractClaim?: Maybe<Array<SubcontractClaimEntity>>;
};


export type ConTaxSchemeEntityLatestTaxArgs = {
  docDate?: Maybe<Scalars['String']>;
};

export type ConTaxSchemeInput = {
  ID?: Maybe<Scalars['String']>;
  taxTypeID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isClaimable?: Maybe<Scalars['Boolean']>;
  taxClass?: Maybe<TaxClass>;
  taxCategory?: Maybe<TaxCategory>;
  commonStatus?: Maybe<CommonStatus>;
};

export type ConTaxTypeEntity = AuditEntity & {
  __typename?: 'ConTaxTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  taxScheme?: Maybe<Array<ConTaxSchemeEntity>>;
  glTaxDynamic?: Maybe<Array<GlTaxDynamicEntity>>;
};

export type ConTaxTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export enum ContPermission {
  ContractMgmtIcon = 'CONTRACT_MGMT__________ICON',
  ContractMgmtView = 'CONTRACT_MGMT__________VIEW',
  ContractMgmtCreate = 'CONTRACT_MGMT__________CREATE',
  ContractMgmtProjectWbsBudgettingView = 'CONTRACT_MGMT_PROJECT_WBS_BUDGETTING_______VIEW',
  ContractMgmtProjectWbsBudgettingCreate = 'CONTRACT_MGMT_PROJECT_WBS_BUDGETTING_______CREATE',
  ContractMgmtProjectWbsBudgettingUpdate = 'CONTRACT_MGMT_PROJECT_WBS_BUDGETTING_______UPDATE',
  ContractMgmtProjectWbsBudgettingDelete = 'CONTRACT_MGMT_PROJECT_WBS_BUDGETTING_______DELETE',
  ContractMgmtProjectWbsBudgettingCancel = 'CONTRACT_MGMT_PROJECT_WBS_BUDGETTING_______CANCEL',
  ContractMgmtProjectWbsBudgettingMoveDown = 'CONTRACT_MGMT_PROJECT_WBS_BUDGETTING_______MOVE_DOWN',
  ContractMgmtProjectWbsBudgettingMoveUp = 'CONTRACT_MGMT_PROJECT_WBS_BUDGETTING_______MOVE_UP',
  ContractMgmtProjectWbsBudgettingUpload = 'CONTRACT_MGMT_PROJECT_WBS_BUDGETTING_______UPLOAD',
  ContractMgmtWbsDayworkView = 'CONTRACT_MGMT_WBS_DAYWORK_______VIEW',
  ContractMgmtWbsDayworkUpdate = 'CONTRACT_MGMT_WBS_DAYWORK_______UPDATE',
  ContractMgmtBudgetRevisionCreate = 'CONTRACT_MGMT_BUDGET_REVISION_______CREATE',
  ContractMgmtBudgetRevisionUpdate = 'CONTRACT_MGMT_BUDGET_REVISION_______UPDATE',
  ContractMgmtBudgetRevisionDelete = 'CONTRACT_MGMT_BUDGET_REVISION_______DELETE',
  ContractMgmtBudgetRevisionCancel = 'CONTRACT_MGMT_BUDGET_REVISION_______CANCEL',
  ContractMgmtBudgetRevisionView = 'CONTRACT_MGMT_BUDGET_REVISION_______VIEW',
  ContractMgmtUtilizedBudgetView = 'CONTRACT_MGMT_UTILIZED_BUDGET_______VIEW',
  ContractMgmtUtilizedBudgetCreate = 'CONTRACT_MGMT_UTILIZED_BUDGET_______CREATE',
  ContractMgmtUtilizedBudgetUpdate = 'CONTRACT_MGMT_UTILIZED_BUDGET_______UPDATE',
  ContractMgmtUtilizedBudgetDelete = 'CONTRACT_MGMT_UTILIZED_BUDGET_______DELETE',
  ContractMgmtUtilizedBudgetCancel = 'CONTRACT_MGMT_UTILIZED_BUDGET_______CANCEL',
  ContractMgmtBudgetTransferView = 'CONTRACT_MGMT_BUDGET_TRANSFER_______VIEW',
  ContractMgmtBudgetTransferCreate = 'CONTRACT_MGMT_BUDGET_TRANSFER_______CREATE',
  ContractMgmtContractInfoView = 'CONTRACT_MGMT_CONTRACT_INFO_______VIEW',
  ContractMgmtContractInfoUpdate = 'CONTRACT_MGMT_CONTRACT_INFO_______UPDATE',
  ContractMgmtContractGuaranteeView = 'CONTRACT_MGMT_CONTRACT_GUARANTEE_______VIEW',
  ContractMgmtContractGuaranteeCreate = 'CONTRACT_MGMT_CONTRACT_GUARANTEE_______CREATE',
  ContractMgmtContractGuaranteeUpdate = 'CONTRACT_MGMT_CONTRACT_GUARANTEE_______UPDATE',
  ContractMgmtContractGuaranteeDelete = 'CONTRACT_MGMT_CONTRACT_GUARANTEE_______DELETE',
  ContractMgmtContractGuaranteeCancel = 'CONTRACT_MGMT_CONTRACT_GUARANTEE_______CANCEL',
  ContractMgmtContractInsuranceView = 'CONTRACT_MGMT_CONTRACT_INSURANCE_______VIEW',
  ContractMgmtContractInsuranceCreate = 'CONTRACT_MGMT_CONTRACT_INSURANCE_______CREATE',
  ContractMgmtContractInsuranceUpdate = 'CONTRACT_MGMT_CONTRACT_INSURANCE_______UPDATE',
  ContractMgmtContractInsuranceDelete = 'CONTRACT_MGMT_CONTRACT_INSURANCE_______DELETE',
  ContractMgmtContractInsuranceCancel = 'CONTRACT_MGMT_CONTRACT_INSURANCE_______CANCEL',
  ContractMgmtContractVoView = 'CONTRACT_MGMT_CONTRACT_VO_______VIEW',
  ContractMgmtContractVoCreate = 'CONTRACT_MGMT_CONTRACT_VO_______CREATE',
  ContractMgmtContractVoUpdate = 'CONTRACT_MGMT_CONTRACT_VO_______UPDATE',
  ContractMgmtContractVoApproveReject = 'CONTRACT_MGMT_CONTRACT_VO_______APPROVE_REJECT',
  ContractMgmtContractVoHealthIndexView = 'CONTRACT_MGMT_CONTRACT_VO_HEALTH_INDEX_______VIEW',
  ContractMgmtContractAccStatusView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_______VIEW',
  ContractMgmtContractAccStatusClientProfileView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_CLIENT_PROFILE____VIEW',
  ContractMgmtContractAccStatusAdvancesView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_ADVANCES____VIEW',
  ContractMgmtContractAccStatusProgressClaimView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_PROGRESS_CLAIM____VIEW',
  ContractMgmtContractAccStatusRetentionClaimsView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_RETENTION_CLAIMS____VIEW',
  ContractMgmtContractAccStatusMiscellaneousInvoiceView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_MISCELLANEOUS_INVOICE____VIEW',
  ContractMgmtContractAccStatusDebitNotesView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_DEBIT_NOTES____VIEW',
  ContractMgmtContractAccStatusCreditNotesView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_CREDIT_NOTES____VIEW',
  ContractMgmtContractAccStatusReceiptsView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_RECEIPTS____VIEW',
  ContractMgmtContractAccStatusRefundsView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_REFUNDS____VIEW',
  ContractMgmtContractAccStatusClientDebitNotesView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_CLIENT_DEBIT_NOTES____VIEW',
  ContractMgmtContractAccStatusClientCreditNotesView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_CLIENT_CREDIT_NOTES____VIEW',
  ContractMgmtContractAccStatusOsReceivableView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_OS_RECEIVABLE____VIEW',
  ContractMgmtMiscellaneousExpenseView = 'CONTRACT_MGMT_MISCELLANEOUS_EXPENSE_______VIEW',
  ContractMgmtMiscellaneousExpenseCreate = 'CONTRACT_MGMT_MISCELLANEOUS_EXPENSE_______CREATE',
  ContractMgmtMiscellaneousExpenseUpdate = 'CONTRACT_MGMT_MISCELLANEOUS_EXPENSE_______UPDATE',
  ContractMgmtMiscellaneousExpenseCancel = 'CONTRACT_MGMT_MISCELLANEOUS_EXPENSE_______CANCEL',
  ContractMgmtRetentionView = 'CONTRACT_MGMT_RETENTION_______VIEW',
  ContractMgmtRetentionCreate = 'CONTRACT_MGMT_RETENTION_______CREATE',
  ContractMgmtRetentionApproveReject = 'CONTRACT_MGMT_RETENTION_______APPROVE_REJECT',
  ContractMgmtRetentionDelete = 'CONTRACT_MGMT_RETENTION_______DELETE',
  ContractMgmtRetentionUpdate = 'CONTRACT_MGMT_RETENTION_______UPDATE',
  ContractMgmtExtensionOfTimeView = 'CONTRACT_MGMT_EXTENSION_OF_TIME_______VIEW',
  ContractMgmtExtensionOfTimeCreate = 'CONTRACT_MGMT_EXTENSION_OF_TIME_______CREATE',
  ContractMgmtContractClosureCreate = 'CONTRACT_MGMT_CONTRACT_CLOSURE_______CREATE',
  ContractMgmtContractTerminationCreate = 'CONTRACT_MGMT_CONTRACT_TERMINATION_______CREATE',
  ContractMgmtDigitalDocumentsView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_______VIEW',
  ContractMgmtDigitalDocumentsContractInfoView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INFO_______VIEW',
  ContractMgmtDigitalDocumentsContractInfoUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INFO_______UPLOAD',
  ContractMgmtDigitalDocumentsContractInfoDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INFO_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractInfoDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INFO_______DELETE',
  ContractMgmtDigitalDocumentsContractGuaranteeView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_GUARANTEE_______VIEW',
  ContractMgmtDigitalDocumentsContractGuaranteeUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_GUARANTEE_______UPLOAD',
  ContractMgmtDigitalDocumentsContractGuaranteeDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_GUARANTEE_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractGuaranteeDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_GUARANTEE_______DELETE',
  ContractMgmtDigitalDocumentsContractInsuranceView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INSURANCE_______VIEW',
  ContractMgmtDigitalDocumentsContractInsuranceUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INSURANCE_______UPLOAD',
  ContractMgmtDigitalDocumentsContractInsuranceDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INSURANCE_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractInsuranceDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INSURANCE_______DELETE',
  ContractMgmtDigitalDocumentsContractVoView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_VO_______VIEW',
  ContractMgmtDigitalDocumentsContractVoUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_VO_______UPLOAD',
  ContractMgmtDigitalDocumentsContractVoDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_VO_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractVoDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_VO_______DELETE',
  ContractMgmtDigitalDocumentsContractAdvanceView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_ADVANCE_______VIEW',
  ContractMgmtDigitalDocumentsContractAdvanceUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_ADVANCE_______UPLOAD',
  ContractMgmtDigitalDocumentsContractAdvanceDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_ADVANCE_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractAdvanceDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_ADVANCE_______DELETE',
  ContractMgmtDigitalDocumentsContractClaimView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLAIM_______VIEW',
  ContractMgmtDigitalDocumentsContractClaimUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLAIM_______UPLOAD',
  ContractMgmtDigitalDocumentsContractClaimDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLAIM_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractClaimDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLAIM_______DELETE',
  ContractMgmtDigitalDocumentsMiscellaneousInvoiceView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_MISCELLANEOUS_INVOICE_______VIEW',
  ContractMgmtDigitalDocumentsMiscellaneousInvoiceUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_MISCELLANEOUS_INVOICE_______UPLOAD',
  ContractMgmtDigitalDocumentsMiscellaneousInvoiceDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_MISCELLANEOUS_INVOICE_______DOWNLOAD',
  ContractMgmtDigitalDocumentsMiscellaneousInvoiceDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_MISCELLANEOUS_INVOICE_______DELETE',
  ContractMgmtDigitalDocumentsContractDnView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_DN_______VIEW',
  ContractMgmtDigitalDocumentsContractDnUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_DN_______UPLOAD',
  ContractMgmtDigitalDocumentsContractDnDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_DN_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractDnDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_DN_______DELETE',
  ContractMgmtDigitalDocumentsContractCnView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CN_______VIEW',
  ContractMgmtDigitalDocumentsContractCnUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CN_______UPLOAD',
  ContractMgmtDigitalDocumentsContractCnDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CN_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractCnDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CN_______DELETE',
  ContractMgmtDigitalDocumentsContractReceiptView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RECEIPT_______VIEW',
  ContractMgmtDigitalDocumentsContractReceiptUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RECEIPT_______UPLOAD',
  ContractMgmtDigitalDocumentsContractReceiptDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RECEIPT_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractReceiptDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RECEIPT_______DELETE',
  ContractMgmtDigitalDocumentsContractRefundView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_REFUND_______VIEW',
  ContractMgmtDigitalDocumentsContractRefundUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_REFUND_______UPLOAD',
  ContractMgmtDigitalDocumentsContractRefundDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_REFUND_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractRefundDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_REFUND_______DELETE',
  ContractMgmtDigitalDocumentsClientDnView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_DN_______VIEW',
  ContractMgmtDigitalDocumentsClientDnUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_DN_______UPLOAD',
  ContractMgmtDigitalDocumentsClientDnDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_DN_______DOWNLOAD',
  ContractMgmtDigitalDocumentsClientDnDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_DN_______DELETE',
  ContractMgmtDigitalDocumentsClientCnView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_CN_______VIEW',
  ContractMgmtDigitalDocumentsClientCnUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_CN_______UPLOAD',
  ContractMgmtDigitalDocumentsClientCnDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_CN_______DOWNLOAD',
  ContractMgmtDigitalDocumentsClientCnDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_CN_______DELETE',
  ContractMgmtDigitalDocumentsContractRetentionCpcView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CPC_______VIEW',
  ContractMgmtDigitalDocumentsContractRetentionCpcUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CPC_______UPLOAD',
  ContractMgmtDigitalDocumentsContractRetentionCpcDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CPC_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractRetentionCpcDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CPC_______DELETE',
  ContractMgmtDigitalDocumentsContractRetentionDlpView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_DLP_______VIEW',
  ContractMgmtDigitalDocumentsContractRetentionDlpUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_DLP_______UPLOAD',
  ContractMgmtDigitalDocumentsContractRetentionDlpDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_DLP_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractRetentionDlpDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_DLP_______DELETE',
  ContractMgmtDigitalDocumentsContractRetentionCmgView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CMG_______VIEW',
  ContractMgmtDigitalDocumentsContractRetentionCmgUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CMG_______UPLOAD',
  ContractMgmtDigitalDocumentsContractRetentionCmgDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CMG_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractRetentionCmgDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CMG_______DELETE',
  ContractMgmtDigitalDocumentsContractExtensionView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_EXTENSION_______VIEW',
  ContractMgmtDigitalDocumentsContractExtensionUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_EXTENSION_______UPLOAD',
  ContractMgmtDigitalDocumentsContractExtensionDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_EXTENSION_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractExtensionDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_EXTENSION_______DELETE',
  ContractMgmtDigitalDocumentsContractClosureView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLOSURE_______VIEW',
  ContractMgmtDigitalDocumentsContractClosureUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLOSURE_______UPLOAD',
  ContractMgmtDigitalDocumentsContractClosureDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLOSURE_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractClosureDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLOSURE_______DELETE',
  ContractMgmtDigitalDocumentsContractTerminationView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_TERMINATION_______VIEW',
  ContractMgmtDigitalDocumentsContractTerminationUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_TERMINATION_______UPLOAD',
  ContractMgmtDigitalDocumentsContractTerminationDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_TERMINATION_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractTerminationDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_TERMINATION_______DELETE',
  ContractMgmtGeneralSettingsIcon = 'CONTRACT_MGMT_GENERAL_SETTINGS_______ICON',
  ContractMgmtGeneralSettingsSubcontractTradeView = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____VIEW',
  ContractMgmtGeneralSettingsSubcontractTradeCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____CREATE',
  ContractMgmtGeneralSettingsSubcontractTradeUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPDATE',
  ContractMgmtGeneralSettingsSubcontractTradeDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DELETE',
  ContractMgmtGeneralSettingsSubcontractTradeInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____INACTIVE',
  ContractMgmtGeneralSettingsSubcontractTradeActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____ACTIVE',
  ContractMgmtGeneralSettingsSubcontractTradeDownload = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DOWNLOAD',
  ContractMgmtGeneralSettingsSubcontractTradeUpload = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPLOAD',
  ContractMgmtGeneralSettingsLabourCategoryView = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____VIEW',
  ContractMgmtGeneralSettingsLabourCategoryCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____CREATE',
  ContractMgmtGeneralSettingsLabourCategoryUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____UPDATE',
  ContractMgmtGeneralSettingsLabourCategoryDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____DELETE',
  ContractMgmtGeneralSettingsLabourCategoryInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____INACTIVE',
  ContractMgmtGeneralSettingsLabourCategoryActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____ACTIVE',
  ContractMgmtGeneralSettingsLabourCategoryDownload = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____DOWNLOAD',
  ContractMgmtGeneralSettingsLabourCategoryUpload = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____UPLOAD',
  ContractMgmtGeneralSettingsBuildingMaterialView = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____VIEW',
  ContractMgmtGeneralSettingsBuildingMaterialCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____CREATE',
  ContractMgmtGeneralSettingsBuildingMaterialUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____UPDATE',
  ContractMgmtGeneralSettingsBuildingMaterialDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____DELETE',
  ContractMgmtGeneralSettingsBuildingMaterialInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____INACTIVE',
  ContractMgmtGeneralSettingsBuildingMaterialActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____ACTIVE',
  ContractMgmtGeneralSettingsBuildingMaterialDownload = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____DOWNLOAD',
  ContractMgmtGeneralSettingsBuildingMaterialUpload = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____UPLOAD',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemView = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_VIEW',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_CREATE',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_UPDATE',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_DELETE',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_INACTIVE',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_ACTIVE',
  ContractMgmtGeneralSettingsPmCategoryView = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____VIEW',
  ContractMgmtGeneralSettingsPmCategoryCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____CREATE',
  ContractMgmtGeneralSettingsPmCategoryUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____UPDATE',
  ContractMgmtGeneralSettingsPmCategoryDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____DELETE',
  ContractMgmtGeneralSettingsPmCategoryInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____INACTIVE',
  ContractMgmtGeneralSettingsPmCategoryActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____ACTIVE',
  ContractMgmtGeneralSettingsPmCategoryDownload = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____DOWNLOAD',
  ContractMgmtGeneralSettingsPmCategoryUpload = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____UPLOAD',
  ContractMgmtGeneralSettingsMiscellaneousExpenseView = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____VIEW',
  ContractMgmtGeneralSettingsMiscellaneousExpenseCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____CREATE',
  ContractMgmtGeneralSettingsMiscellaneousExpenseUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPDATE',
  ContractMgmtGeneralSettingsMiscellaneousExpenseDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DELETE',
  ContractMgmtGeneralSettingsMiscellaneousExpenseInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____INACTIVE',
  ContractMgmtGeneralSettingsMiscellaneousExpenseActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____ACTIVE',
  ContractMgmtGeneralSettingsMiscellaneousExpenseDownload = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DOWNLOAD',
  ContractMgmtGeneralSettingsMiscellaneousExpenseUpload = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPLOAD',
  ContractMgmtGeneralSettingsGuaranteeTypeView = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____VIEW',
  ContractMgmtGeneralSettingsGuaranteeTypeCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____CREATE',
  ContractMgmtGeneralSettingsGuaranteeTypeUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____UPDATE',
  ContractMgmtGeneralSettingsGuaranteeTypeActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____ACTIVE',
  ContractMgmtGeneralSettingsGuaranteeTypeInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____INACTIVE',
  ContractMgmtGeneralSettingsGuaranteeTypeDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____DELETE',
  ContractMgmtGeneralSettingsInsuranceTypeView = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____VIEW',
  ContractMgmtGeneralSettingsInsuranceTypeCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____CREATE',
  ContractMgmtGeneralSettingsInsuranceTypeUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____UPDATE',
  ContractMgmtGeneralSettingsInsuranceTypeDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____DELETE',
  ContractMgmtGeneralSettingsInsuranceTypeActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____ACTIVE',
  ContractMgmtGeneralSettingsInsuranceTypeInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____INACTIVE',
  ContractMgmtContractAprovalPolicyIcon = 'CONTRACT_MGMT_CONTRACT_APROVAL_POLICY_______ICON',
  ProfitRecognitionIcon = 'PROFIT_RECOGNITION__________ICON',
  ProfitRecognitionView = 'PROFIT_RECOGNITION__________VIEW',
  ProfitRecognitionProfitRecognitionAssignmentView = 'PROFIT_RECOGNITION_PROFIT_RECOGNITION_ASSIGNMENT__________VIEW',
  ProfitRecognitionProfitRecognitionAssignmentUpdate = 'PROFIT_RECOGNITION_PROFIT_RECOGNITION_ASSIGNMENT__________UPDATE',
  ProfitRecognitionNumberingStructureView = 'PROFIT_RECOGNITION_NUMBERING_STRUCTURE__________VIEW',
  ProfitRecognitionNumberingStructureUpdate = 'PROFIT_RECOGNITION_NUMBERING_STRUCTURE__________UPDATE',
  ProfitRecognitionProfitRecognitionGenerationView = 'PROFIT_RECOGNITION_PROFIT_RECOGNITION_GENERATION__________VIEW',
  ProfitRecognitionProfitRecognitionGenerationCreate = 'PROFIT_RECOGNITION_PROFIT_RECOGNITION_GENERATION__________CREATE',
  ProfitRecognitionProfitRecognitionGenerationUpdate = 'PROFIT_RECOGNITION_PROFIT_RECOGNITION_GENERATION__________UPDATE',
  ProfitRecognitionGeneralSettingsIcon = 'PROFIT_RECOGNITION_GENERAL_SETTINGS__________ICON',
  ProfitRecognitionContractApprovalPolicyIcon = 'PROFIT_RECOGNITION_CONTRACT_APPROVAL_POLICY__________ICON',
  ClientAcctIcon = 'CLIENT_ACCT__________ICON',
  ClientAcctView = 'CLIENT_ACCT__________VIEW',
  ClientAcctClientProfileView = 'CLIENT_ACCT_CLIENT_PROFILE_______VIEW',
  ClientAcctClientProfileCreate = 'CLIENT_ACCT_CLIENT_PROFILE_______CREATE',
  ClientAcctClientProfileUpdate = 'CLIENT_ACCT_CLIENT_PROFILE_______UPDATE',
  ClientAcctAccTransactionsView = 'CLIENT_ACCT_ACC_TRANSACTIONS_______VIEW',
  ClientAcctAccTransactionsAdvancesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____VIEW',
  ClientAcctAccTransactionsAdvancesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____CREATE',
  ClientAcctAccTransactionsAdvancesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____UPDATE',
  ClientAcctAccTransactionsAdvancesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____APPROVE_REJECT',
  ClientAcctAccTransactionsAdvancesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____CANCEL',
  ClientAcctAccTransactionsAdvancesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____DELETE',
  ClientAcctAccTransactionsProgressClaimsView = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____VIEW',
  ClientAcctAccTransactionsProgressClaimsCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____CREATE',
  ClientAcctAccTransactionsProgressClaimsUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____UPDATE',
  ClientAcctAccTransactionsProgressClaimsApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____APPROVE_REJECT',
  ClientAcctAccTransactionsProgressClaimsCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____CANCEL',
  ClientAcctAccTransactionsProgressClaimsDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____DELETE',
  ClientAcctAccTransactionsRetentionClaimsView = 'CLIENT_ACCT_ACC_TRANSACTIONS_RETENTION_CLAIMS____VIEW',
  ClientAcctAccTransactionsRetentionClaimsCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_RETENTION_CLAIMS____CANCEL',
  ClientAcctAccTransactionsRetentionClaimsUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_RETENTION_CLAIMS____UPDATE',
  ClientAcctAccTransactionsRetentionClaimsDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_RETENTION_CLAIMS____DELETE',
  ClientAcctAccTransactionsRetentionClaimsApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_RETENTION_CLAIMS____APPROVE_REJECT',
  ClientAcctAccTransactionsMiscellaneousInvoicesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____VIEW',
  ClientAcctAccTransactionsMiscellaneousInvoicesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____CREATE',
  ClientAcctAccTransactionsMiscellaneousInvoicesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____CANCEL',
  ClientAcctAccTransactionsMiscellaneousInvoicesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____APPROVE_REJECT',
  ClientAcctAccTransactionsMiscellaneousInvoicesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____UPDATE',
  ClientAcctAccTransactionsMiscellaneousInvoicesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____DELETE',
  ClientAcctAccTransactionsDebitNotesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____VIEW',
  ClientAcctAccTransactionsDebitNotesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____CREATE',
  ClientAcctAccTransactionsDebitNotesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____APPROVE_REJECT',
  ClientAcctAccTransactionsDebitNotesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____UPDATE',
  ClientAcctAccTransactionsDebitNotesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____CANCEL',
  ClientAcctAccTransactionsDebitNotesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____DELETE',
  ClientAcctAccTransactionsCreditNotesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____VIEW',
  ClientAcctAccTransactionsCreditNotesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____CREATE',
  ClientAcctAccTransactionsCreditNotesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____UPDATE',
  ClientAcctAccTransactionsCreditNotesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____APPROVE_REJECT',
  ClientAcctAccTransactionsCreditNotesAllocation = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____ALLOCATION',
  ClientAcctAccTransactionsCreditNotesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____CANCEL',
  ClientAcctAccTransactionsCreditNotesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____DELETE',
  ClientAcctAccTransactionsReceiptsView = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____VIEW',
  ClientAcctAccTransactionsReceiptsCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____CREATE',
  ClientAcctAccTransactionsReceiptsApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____APPROVE_REJECT',
  ClientAcctAccTransactionsReceiptsAllocation = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____ALLOCATION',
  ClientAcctAccTransactionsReceiptsUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____UPDATE',
  ClientAcctAccTransactionsReceiptsCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____CANCEL',
  ClientAcctAccTransactionsReceiptsDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____DELETE',
  ClientAcctAccTransactionsRefundsView = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____VIEW',
  ClientAcctAccTransactionsRefundsCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____CREATE',
  ClientAcctAccTransactionsRefundsApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____APPROVE_REJECT',
  ClientAcctAccTransactionsRefundsCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____CANCEL',
  ClientAcctAccTransactionsRefundsUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____UPDATE',
  ClientAcctAccTransactionsRefundsDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____DELETE',
  ClientAcctAccTransactionsClientDebitNotesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____VIEW',
  ClientAcctAccTransactionsClientDebitNotesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____CREATE',
  ClientAcctAccTransactionsClientDebitNotesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____APPROVE_REJECT',
  ClientAcctAccTransactionsClientDebitNotesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____UPDATE',
  ClientAcctAccTransactionsClientDebitNotesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____DELETE',
  ClientAcctAccTransactionsClientDebitNotesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____CANCEL',
  ClientAcctAccTransactionsClientDebitNotesAllocation = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____ALLOCATION',
  ClientAcctAccTransactionsClientCreditNotesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____VIEW',
  ClientAcctAccTransactionsClientCreditNotesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____CREATE',
  ClientAcctAccTransactionsClientCreditNotesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____APPROVE_REJECT',
  ClientAcctAccTransactionsClientCreditNotesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____CANCEL',
  ClientAcctAccTransactionsClientCreditNotesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____UPDATE',
  ClientAcctAccTransactionsClientCreditNotesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____DELETE',
  ClientAcctAccTransactionsOsReceivableView = 'CLIENT_ACCT_ACC_TRANSACTIONS_OS_RECEIVABLE____VIEW',
  ClientAcctCreditControlView = 'CLIENT_ACCT_CREDIT_CONTROL_______VIEW',
  ClientAcctCreditControlOutstandingCollectionView = 'CLIENT_ACCT_CREDIT_CONTROL_OUTSTANDING_COLLECTION____VIEW',
  ClientAcctCreditControlOutstandingCollectionOutstandingCollectionActivityView = 'CLIENT_ACCT_CREDIT_CONTROL_OUTSTANDING_COLLECTION_OUTSTANDING_COLLECTION_ACTIVITY_VIEW',
  ClientAcctCreditControlOutstandingCollectionOutstandingCollectionActivityCreate = 'CLIENT_ACCT_CREDIT_CONTROL_OUTSTANDING_COLLECTION_OUTSTANDING_COLLECTION_ACTIVITY_CREATE',
  ClientAcctCreditControlOutstandingCollectionOutstandingCollectionActivityUpdate = 'CLIENT_ACCT_CREDIT_CONTROL_OUTSTANDING_COLLECTION_OUTSTANDING_COLLECTION_ACTIVITY_UPDATE',
  ClientAcctCreditControlOutstandingCollectionOutstandingCollectionActivityReschedule = 'CLIENT_ACCT_CREDIT_CONTROL_OUTSTANDING_COLLECTION_OUTSTANDING_COLLECTION_ACTIVITY_RESCHEDULE',
  ClientAcctCreditControlCollectionActivityView = 'CLIENT_ACCT_CREDIT_CONTROL_COLLECTION_ACTIVITY____VIEW',
  ClientAcctCreditControlCollectionActivityUpdate = 'CLIENT_ACCT_CREDIT_CONTROL_COLLECTION_ACTIVITY____UPDATE',
  ClientAcctCreditControlCollectionActivityReschedule = 'CLIENT_ACCT_CREDIT_CONTROL_COLLECTION_ACTIVITY____RESCHEDULE',
  ClientAcctCreditControlCollectionActivityCreate = 'CLIENT_ACCT_CREDIT_CONTROL_COLLECTION_ACTIVITY____CREATE',
  ClientAcctCreditControlPaymentReminderView = 'CLIENT_ACCT_CREDIT_CONTROL_PAYMENT_REMINDER____VIEW',
  ClientAcctCreditControlPaymentReminderDownload = 'CLIENT_ACCT_CREDIT_CONTROL_PAYMENT_REMINDER____DOWNLOAD',
  ClientAcctCreditControlPaymentReminderCreate = 'CLIENT_ACCT_CREDIT_CONTROL_PAYMENT_REMINDER____CREATE',
  ClientAcctStatementAccountsView = 'CLIENT_ACCT_STATEMENT_ACCOUNTS_______VIEW',
  ClientAcctPaymentReminderView = 'CLIENT_ACCT_PAYMENT_REMINDER_______VIEW',
  ClientAcctPaymentReminderPaymentReminderSettingView = 'CLIENT_ACCT_PAYMENT_REMINDER_PAYMENT_REMINDER_SETTING_______VIEW',
  ClientAcctPaymentReminderPaymentReminderSettingCreate = 'CLIENT_ACCT_PAYMENT_REMINDER_PAYMENT_REMINDER_SETTING_______CREATE',
  ClientAcctDigitalReportingView = 'CLIENT_ACCT_DIGITAL_REPORTING_______VIEW',
  ClientAcctDigitalReportingClientLedgerReportParameter = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_LEDGER_REPORT____PARAMETER',
  ClientAcctDigitalReportingClientLedgerReportView = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_LEDGER_REPORT____VIEW',
  ClientAcctDigitalReportingClientLedgerReportReporting = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_LEDGER_REPORT____REPORTING',
  ClientAcctDigitalReportingClientAgingDetailByContractParameter = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_DETAIL_BY_CONTRACT____PARAMETER',
  ClientAcctDigitalReportingClientAgingDetailByContractView = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_DETAIL_BY_CONTRACT____VIEW',
  ClientAcctDigitalReportingClientAgingDetailByContractReporting = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_DETAIL_BY_CONTRACT____REPORTING',
  ClientAcctDigitalReportingClientAgingSummaryParameter = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_SUMMARY____PARAMETER',
  ClientAcctDigitalReportingClientAgingSummaryView = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_SUMMARY____VIEW',
  ClientAcctDigitalReportingClientAgingSummaryReporting = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_SUMMARY____REPORTING',
  ClientAcctDigitalReportingClientAgingDetailByTrxDateParameter = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_DETAIL_BY_TRX_DATE____PARAMETER',
  ClientAcctDigitalReportingClientAgingDetailByTrxDateView = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_DETAIL_BY_TRX_DATE____VIEW',
  ClientAcctDigitalReportingClientAgingDetailByTrxDateReporting = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_DETAIL_BY_TRX_DATE____REPORTING',
  ClientAcctDigitalReportingStatementOfAccountParameter = 'CLIENT_ACCT_DIGITAL_REPORTING_STATEMENT_OF_ACCOUNT____PARAMETER',
  ClientAcctDigitalReportingStatementOfAccountView = 'CLIENT_ACCT_DIGITAL_REPORTING_STATEMENT_OF_ACCOUNT____VIEW',
  ClientAcctDigitalReportingStatementOfAccountReporting = 'CLIENT_ACCT_DIGITAL_REPORTING_STATEMENT_OF_ACCOUNT____REPORTING',
  ClientAcctDigitalReportingClientTaxLedgerReportParameter = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_TAX_LEDGER_REPORT____PARAMETER',
  ClientAcctDigitalReportingClientTaxLedgerReportView = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_TAX_LEDGER_REPORT____VIEW',
  ClientAcctDigitalReportingClientTaxLedgerReportReporting = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_TAX_LEDGER_REPORT____REPORTING',
  ClientAcctDigitalReportingRetentionLedgerReportParameter = 'CLIENT_ACCT_DIGITAL_REPORTING_RETENTION_LEDGER_REPORT____PARAMETER',
  ClientAcctDigitalReportingClientRetentionLedgerReportView = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_RETENTION_LEDGER_REPORT____VIEW',
  ClientAcctGeneralSettingsIcon = 'CLIENT_ACCT_GENERAL_SETTINGS_____ICON',
  ClientAcctContractApprovalPolicyIcon = 'CLIENT_ACCT_CONTRACT_APPROVAL_POLICY_____ICON',
  SubconTenderIcon = 'SUBCON_TENDER__________ICON',
  SubconTenderView = 'SUBCON_TENDER__________VIEW',
  SubconTenderCreate = 'SUBCON_TENDER__________CREATE',
  SubconTenderUpdate = 'SUBCON_TENDER__________UPDATE',
  SubconTenderDelete = 'SUBCON_TENDER__________DELETE',
  SubconTenderTenderInviteesView = 'SUBCON_TENDER_TENDER_INVITEES_______VIEW',
  SubconTenderTenderInviteesCreate = 'SUBCON_TENDER_TENDER_INVITEES_______CREATE',
  SubconTenderTenderInviteesUninvite = 'SUBCON_TENDER_TENDER_INVITEES_______UNINVITE',
  SubconTenderTenderInviteesTenderSubmissionView = 'SUBCON_TENDER_TENDER_INVITEES_TENDER_SUBMISSION____VIEW',
  SubconTenderTenderInviteesTenderSubmissionCreate = 'SUBCON_TENDER_TENDER_INVITEES_TENDER_SUBMISSION____CREATE',
  SubconTenderEvaluationSelectionCreate = 'SUBCON_TENDER_EVALUATION_SELECTION_______CREATE',
  SubconTenderGeneralSettingsIcon = 'SUBCON_TENDER_GENERAL_SETTINGS_____ICON',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeView = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_VIEW',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeCreate = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_CREATE',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeUpdate = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_UPDATE',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeDownload = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_DOWNLOAD',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeUpload = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_UPLOAD',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeDelete = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_DELETE',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeActive = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_ACTIVE',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeInactive = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_INACTIVE',
  SubcontractsIcon = 'SUBCONTRACTS__________ICON',
  SubcontractsView = 'SUBCONTRACTS__________VIEW',
  SubcontractsCreate = 'SUBCONTRACTS__________CREATE',
  SubcontractsSubcontractInfoView = 'SUBCONTRACTS_SUBCONTRACT_INFO_______VIEW',
  SubcontractsSubcontractInfoUpdate = 'SUBCONTRACTS_SUBCONTRACT_INFO_______UPDATE',
  SubcontractsSubcontractGuaranteeView = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______VIEW',
  SubcontractsSubcontractGuaranteeCreate = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______CREATE',
  SubcontractsSubcontractGuaranteeApproveReject = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______APPROVE_REJECT',
  SubcontractsSubcontractGuaranteeUpdate = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______UPDATE',
  SubcontractsSubcontractGuaranteeDelete = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______DELETE',
  SubcontractsSubcontractGuaranteeCancel = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______CANCEL',
  SubcontractsSubcontractInsuranceView = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______VIEW',
  SubcontractsSubcontractInsuranceCreate = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______CREATE',
  SubcontractsSubcontractInsuranceUpdate = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______UPDATE',
  SubcontractsSubcontractInsuranceDelete = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______DELETE',
  SubcontractsSubcontractInsuranceCancel = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______CANCEL',
  SubcontractsSubcontractInsuranceApproveReject = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______APPROVE_REJECT',
  SubcontractsSubcontractVoView = 'SUBCONTRACTS_SUBCONTRACT_VO_______VIEW',
  SubcontractsSubcontractVoCreate = 'SUBCONTRACTS_SUBCONTRACT_VO_______CREATE',
  SubcontractsSubcontractVoApproveReject = 'SUBCONTRACTS_SUBCONTRACT_VO_______APPROVE_REJECT',
  SubcontractsSubcontractVoUpdate = 'SUBCONTRACTS_SUBCONTRACT_VO_______UPDATE',
  SubcontractsSubcontractVoDelete = 'SUBCONTRACTS_SUBCONTRACT_VO_______DELETE',
  SubcontractsSubcontractAccStatusView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_______VIEW',
  SubcontractsSubcontractAccStatusSubcontractorProfileView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_SUBCONTRACTOR_PROFILE___VIEW',
  SubcontractsSubcontractAccStatusAdvancesView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_ADVANCES____VIEW',
  SubcontractsSubcontractAccStatusProgressClaimsView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_PROGRESS_CLAIMS____VIEW',
  SubcontractsSubcontractAccStatusRetentionClaimsView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_RETENTION_CLAIMS____VIEW',
  SubcontractsSubcontractAccStatusMiscellaneousInvoiceView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_MISCELLANEOUS_INVOICE____VIEW',
  SubcontractsSubcontractAccStatusDebitNotesView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_DEBIT_NOTES____VIEW',
  SubcontractsSubcontractAccStatusCreditNotesView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_CREDIT_NOTES____VIEW',
  SubcontractsSubcontractAccStatusPaymentsView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_PAYMENTS____VIEW',
  SubcontractsSubcontractAccStatusRefundsView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_REFUNDS____VIEW',
  SubcontractsSubcontractAccStatusDebitNotesSubconView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_DEBIT_NOTES_SUBCON____VIEW',
  SubcontractsSubcontractAccStatusCreditNotesSubconView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_CREDIT_NOTES_SUBCON____VIEW',
  SubcontractsSubcontractAccStatusOsReceivableView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_OS_RECEIVABLE____VIEW',
  SubcontractsRetentionView = 'SUBCONTRACTS_RETENTION_______VIEW',
  SubcontractsRetentionCreate = 'SUBCONTRACTS_RETENTION_______CREATE',
  SubcontractsRetentionUpdate = 'SUBCONTRACTS_RETENTION_______UPDATE',
  SubcontractsRetentionApproveReject = 'SUBCONTRACTS_RETENTION_______APPROVE_REJECT',
  SubcontractsRetentionDelete = 'SUBCONTRACTS_RETENTION_______DELETE',
  SubcontractsExtensionOfTimeView = 'SUBCONTRACTS_EXTENSION_OF_TIME_______VIEW',
  SubcontractsExtensionOfTimeCreate = 'SUBCONTRACTS_EXTENSION_OF_TIME_______CREATE',
  SubcontractsSubcontractClosureCreate = 'SUBCONTRACTS_SUBCONTRACT_CLOSURE_______CREATE',
  SubcontractsSubcontractTerminationCreate = 'SUBCONTRACTS_SUBCONTRACT_TERMINATION_______CREATE',
  SubcontractsDigitalDocumentView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_______VIEW',
  SubcontractsDigitalDocumentSubcontractInfoView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INFO_______VIEW',
  SubcontractsDigitalDocumentSubcontractInfoUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INFO_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractInfoDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INFO_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractInfoDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INFO_______DELETE',
  SubcontractsDigitalDocumentSubcontractGuaranteeView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_GUARANTEE_______VIEW',
  SubcontractsDigitalDocumentSubcontractGuaranteeUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_GUARANTEE_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractGuaranteeDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_GUARANTEE_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractGuaranteeDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_GUARANTEE_______DELETE',
  SubcontractsDigitalDocumentSubcontractInsuranceView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INSURANCE_______VIEW',
  SubcontractsDigitalDocumentSubcontractInsuranceUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INSURANCE_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractInsuranceDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INSURANCE_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractInsuranceDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INSURANCE_______DELETE',
  SubcontractsDigitalDocumentSubcontractVoView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_VO_______VIEW',
  SubcontractsDigitalDocumentSubcontractVoUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_VO_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractVoDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_VO_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractVoDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_VO_______DELETE',
  SubcontractsDigitalDocumentSubcontractAdvanceView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_ADVANCE_______VIEW',
  SubcontractsDigitalDocumentSubcontractAdvanceUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_ADVANCE_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractAdvanceDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_ADVANCE_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractAdvanceDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_ADVANCE_______DELETE',
  SubcontractsDigitalDocumentSubcontractClaimView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLAIM_______VIEW',
  SubcontractsDigitalDocumentSubcontractClaimUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLAIM_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractClaimDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLAIM_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractClaimDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLAIM_______DELETE',
  SubcontractsDigitalDocumentSubcontractMiscellaneousInvoicesView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_MISCELLANEOUS_INVOICES_______VIEW',
  SubcontractsDigitalDocumentSubcontractMiscellaneousInvoicesUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_MISCELLANEOUS_INVOICES_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractMiscellaneousInvoicesDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_MISCELLANEOUS_INVOICES_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractMiscellaneousInvoicesDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_MISCELLANEOUS_INVOICES_______DELETE',
  SubcontractsDigitalDocumentSubcontractDnView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_DN_______VIEW',
  SubcontractsDigitalDocumentSubcontractDnUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_DN_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractDnDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_DN_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractDnDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_DN_______DELETE',
  SubcontractsDigitalDocumentSubcontractCnView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CN_______VIEW',
  SubcontractsDigitalDocumentSubcontractCnUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CN_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractCnDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CN_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractCnDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CN_______DELETE',
  SubcontractsDigitalDocumentSubcontractPaymentView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_PAYMENT_______VIEW',
  SubcontractsDigitalDocumentSubcontractPaymentUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_PAYMENT_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractPaymentDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_PAYMENT_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractPaymentDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_PAYMENT_______DELETE',
  SubcontractsDigitalDocumentSubcontractRefundView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_REFUND_______VIEW',
  SubcontractsDigitalDocumentSubcontractRefundUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_REFUND_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractRefundDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_REFUND_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractRefundDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_REFUND_______DELETE',
  SubcontractsDigitalDocumentDnToSubconView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_DN_TO_SUBCON_______VIEW',
  SubcontractsDigitalDocumentDnToSubconUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_DN_TO_SUBCON_______UPLOAD',
  SubcontractsDigitalDocumentDnToSubconDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_DN_TO_SUBCON_______DOWNLOAD',
  SubcontractsDigitalDocumentDnToSubconDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_DN_TO_SUBCON_______DELETE',
  SubcontractsDigitalDocumentCnToSubconView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_CN_TO_SUBCON_______VIEW',
  SubcontractsDigitalDocumentCnToSubconUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_CN_TO_SUBCON_______UPLOAD',
  SubcontractsDigitalDocumentCnToSubconDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_CN_TO_SUBCON_______DOWNLOAD',
  SubcontractsDigitalDocumentCnToSubconDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_CN_TO_SUBCON_______DELETE',
  SubcontractsDigitalDocumentSubcontractRetentionCpcView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CPC_______VIEW',
  SubcontractsDigitalDocumentSubcontractRetentionCpcUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CPC_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionCpcDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CPC_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionCpcDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CPC_______DELETE',
  SubcontractsDigitalDocumentSubcontractRetentionDlpView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_DLP_______VIEW',
  SubcontractsDigitalDocumentSubcontractRetentionDlpUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_DLP_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionDlpDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_DLP_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionDlpDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_DLP_______DELETE',
  SubcontractsDigitalDocumentSubcontractRetentionCmgView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CMG_______VIEW',
  SubcontractsDigitalDocumentSubcontractRetentionCmgUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CMG_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionCmgDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CMG_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionCmgDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CMG_______DELETE',
  SubcontractsDigitalDocumentSubcontractExtensionView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_EXTENSION_______VIEW',
  SubcontractsDigitalDocumentSubcontractExtensionUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_EXTENSION_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractExtensionDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_EXTENSION_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractExtensionDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_EXTENSION_______DELETE',
  SubcontractsDigitalDocumentSubcontractClosureView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLOSURE_______VIEW',
  SubcontractsDigitalDocumentSubcontractClosureUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLOSURE_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractClosureDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLOSURE_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractClosureDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLOSURE_______DELETE',
  SubcontractsDigitalDocumentSubcontractTerminationView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_TERMINATION_______VIEW',
  SubcontractsDigitalDocumentSubcontractTerminationUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_TERMINATION_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractTerminationDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_TERMINATION_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractTerminationDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_TERMINATION_______DELETE',
  SubcontractsGeneralSettingsIcon = 'SUBCONTRACTS_GENERAL_SETTINGS_______ICON',
  SubcontractsGeneralSettingsSubcontractTradeView = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____VIEW',
  SubcontractsGeneralSettingsSubcontractTradeCreate = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____CREATE',
  SubcontractsGeneralSettingsSubcontractTradeUpdate = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPDATE',
  SubcontractsGeneralSettingsSubcontractTradeDelete = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DELETE',
  SubcontractsGeneralSettingsSubcontractTradeInactive = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____INACTIVE',
  SubcontractsGeneralSettingsSubcontractTradeActive = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____ACTIVE',
  SubcontractsGeneralSettingsSubcontractTradeDownload = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DOWNLOAD',
  SubcontractsGeneralSettingsSubcontractTradeUpload = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPLOAD',
  SubcontractsGeneralSettingsGuaranteeTypeView = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____VIEW',
  SubcontractsGeneralSettingsGuaranteeTypeCreate = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____CREATE',
  SubcontractsGeneralSettingsGuaranteeTypeUpdate = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____UPDATE',
  SubcontractsGeneralSettingsGuaranteeTypeActive = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____ACTIVE',
  SubcontractsGeneralSettingsGuaranteeTypeInactive = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____INACTIVE',
  SubcontractsGeneralSettingsGuaranteeTypeDelete = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____DELETE',
  SubcontractsGeneralSettingsInsuranceTypeView = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____VIEW',
  SubcontractsGeneralSettingsInsuranceTypeCreate = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____CREATE',
  SubcontractsGeneralSettingsInsuranceTypeUpdate = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____UPDATE',
  SubcontractsGeneralSettingsInsuranceTypeActive = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____ACTIVE',
  SubcontractsGeneralSettingsInsuranceTypeInactive = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____INACTIVE',
  SubcontractsGeneralSettingsInsuranceTypeDelete = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____DELETE',
  SubcontractsContractApprovalPolicyIcon = 'SUBCONTRACTS_CONTRACT_APPROVAL_POLICY____ICON',
  SubconAccountIcon = 'SUBCON_ACCOUNT__________ICON',
  SubconAccountView = 'SUBCON_ACCOUNT__________VIEW',
  SubconAccountCreate = 'SUBCON_ACCOUNT__________CREATE',
  SubconAccountDownload = 'SUBCON_ACCOUNT__________DOWNLOAD',
  SubconAccountUpload = 'SUBCON_ACCOUNT__________UPLOAD',
  SubconAccountSubcontractorProfileView = 'SUBCON_ACCOUNT_SUBCONTRACTOR_PROFILE_______VIEW',
  SubconAccountSubcontractorProfileCreate = 'SUBCON_ACCOUNT_SUBCONTRACTOR_PROFILE_______CREATE',
  SubconAccountSubcontractorProfileUpdate = 'SUBCON_ACCOUNT_SUBCONTRACTOR_PROFILE_______UPDATE',
  SubconAccountAccountTransactionView = 'SUBCON_ACCOUNT_ACCOUNT_TRANSACTION_______VIEW',
  SubconAccountAdvancesView = 'SUBCON_ACCOUNT_ADVANCES_______VIEW',
  SubconAccountAdvancesCreate = 'SUBCON_ACCOUNT_ADVANCES_______CREATE',
  SubconAccountAdvancesCancel = 'SUBCON_ACCOUNT_ADVANCES_______CANCEL',
  SubconAccountAdvancesApproveReject = 'SUBCON_ACCOUNT_ADVANCES_______APPROVE_REJECT',
  SubconAccountAdvancesUpdate = 'SUBCON_ACCOUNT_ADVANCES_______UPDATE',
  SubconAccountAdvancesDelete = 'SUBCON_ACCOUNT_ADVANCES_______DELETE',
  SubconAccountProgressClaimsView = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______VIEW',
  SubconAccountProgressClaimsCreate = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______CREATE',
  SubconAccountProgressClaimsUpdate = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______UPDATE',
  SubconAccountProgressClaimsApproveReject = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______APPROVE_REJECT',
  SubconAccountProgressClaimsCancel = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______CANCEL',
  SubconAccountProgressClaimsDelete = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______DELETE',
  SubconAccountRetentionClaimsView = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______VIEW',
  SubconAccountRetentionClaimsCreate = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______CREATE',
  SubconAccountRetentionClaimsUpdate = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______UPDATE',
  SubconAccountRetentionClaimsCancel = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______CANCEL',
  SubconAccountRetentionClaimsApproveReject = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______APPROVE_REJECT',
  SubconAccountRetentionClaimsDelete = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______DELETE',
  SubconAccountMiscellaneousInvoiceView = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______VIEW',
  SubconAccountMiscellaneousInvoiceCreate = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______CREATE',
  SubconAccountMiscellaneousInvoiceUpdate = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______UPDATE',
  SubconAccountMiscellaneousInvoiceCancel = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______CANCEL',
  SubconAccountMiscellaneousInvoiceApproveReject = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______APPROVE_REJECT',
  SubconAccountMiscellaneousInvoiceDelete = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______DELETE',
  SubconAccountDebitNotesView = 'SUBCON_ACCOUNT_DEBIT_NOTES_______VIEW',
  SubconAccountDebitNotesCreate = 'SUBCON_ACCOUNT_DEBIT_NOTES_______CREATE',
  SubconAccountDebitNotesUpdate = 'SUBCON_ACCOUNT_DEBIT_NOTES_______UPDATE',
  SubconAccountDebitNotesDelete = 'SUBCON_ACCOUNT_DEBIT_NOTES_______DELETE',
  SubconAccountDebitNotesCancel = 'SUBCON_ACCOUNT_DEBIT_NOTES_______CANCEL',
  SubconAccountDebitNotesApproveReject = 'SUBCON_ACCOUNT_DEBIT_NOTES_______APPROVE_REJECT',
  SubconAccountCreditNotesView = 'SUBCON_ACCOUNT_CREDIT_NOTES_______VIEW',
  SubconAccountCreditNotesCreate = 'SUBCON_ACCOUNT_CREDIT_NOTES_______CREATE',
  SubconAccountCreditNotesUpdate = 'SUBCON_ACCOUNT_CREDIT_NOTES_______UPDATE',
  SubconAccountCreditNotesApproveReject = 'SUBCON_ACCOUNT_CREDIT_NOTES_______APPROVE_REJECT',
  SubconAccountCreditNotesAllocation = 'SUBCON_ACCOUNT_CREDIT_NOTES_______ALLOCATION',
  SubconAccountCreditNotesCancel = 'SUBCON_ACCOUNT_CREDIT_NOTES_______CANCEL',
  SubconAccountCreditNotesDelete = 'SUBCON_ACCOUNT_CREDIT_NOTES_______DELETE',
  SubconAccountPaymentView = 'SUBCON_ACCOUNT_PAYMENT_______VIEW',
  SubconAccountPaymentCreate = 'SUBCON_ACCOUNT_PAYMENT_______CREATE',
  SubconAccountPaymentCancel = 'SUBCON_ACCOUNT_PAYMENT_______CANCEL',
  SubconAccountPaymentUpdate = 'SUBCON_ACCOUNT_PAYMENT_______UPDATE',
  SubconAccountPaymentApproveReject = 'SUBCON_ACCOUNT_PAYMENT_______APPROVE_REJECT',
  SubconAccountPaymentDelete = 'SUBCON_ACCOUNT_PAYMENT_______DELETE',
  SubconAccountRefundsView = 'SUBCON_ACCOUNT_REFUNDS_______VIEW',
  SubconAccountRefundsCreate = 'SUBCON_ACCOUNT_REFUNDS_______CREATE',
  SubconAccountRefundsApproveReject = 'SUBCON_ACCOUNT_REFUNDS_______APPROVE_REJECT',
  SubconAccountRefundsCancel = 'SUBCON_ACCOUNT_REFUNDS_______CANCEL',
  SubconAccountRefundsUpdate = 'SUBCON_ACCOUNT_REFUNDS_______UPDATE',
  SubconAccountRefundsDelete = 'SUBCON_ACCOUNT_REFUNDS_______DELETE',
  SubconAccountRefundsAllocation = 'SUBCON_ACCOUNT_REFUNDS_______ALLOCATION',
  SubconAccountCnSubconView = 'SUBCON_ACCOUNT_CN_SUBCON_______VIEW',
  SubconAccountCnSubconCreate = 'SUBCON_ACCOUNT_CN_SUBCON_______CREATE',
  SubconAccountCnSubconCancel = 'SUBCON_ACCOUNT_CN_SUBCON_______CANCEL',
  SubconAccountCnSubconUpdate = 'SUBCON_ACCOUNT_CN_SUBCON______UPDATE',
  SubconAccountCnSubconApproveReject = 'SUBCON_ACCOUNT_CN_SUBCON______APPROVE_REJECT',
  SubconAccountCnSubconDelete = 'SUBCON_ACCOUNT_CN_SUBCON______DELETE',
  SubconAccountDnSubconView = 'SUBCON_ACCOUNT_DN_SUBCON_______VIEW',
  SubconAccountDnSubconCreate = 'SUBCON_ACCOUNT_DN_SUBCON_______CREATE',
  SubconAccountDnSubconCancel = 'SUBCON_ACCOUNT_DN_SUBCON_______CANCEL',
  SubconAccountDnSubconUpdate = 'SUBCON_ACCOUNT_DN_SUBCON______UPDATE',
  SubconAccountDnSubconApproveReject = 'SUBCON_ACCOUNT_DN_SUBCON______APPROVE_REJECT',
  SubconAccountDnSubconDelete = 'SUBCON_ACCOUNT_DN_SUBCON______DELETE',
  SubconAccountDnSubconAllocation = 'SUBCON_ACCOUNT_DN_SUBCON______ALLOCATION',
  SubconAccountDigitalReportingView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_______VIEW',
  SubconAccountDigitalReportingSubcontractorLedgerDetailTrxDateView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_LEDGER_DETAIL_TRX_DATE____VIEW',
  SubconAccountDigitalReportingSubcontractorLedgerDetailTrxDateParameter = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_LEDGER_DETAIL_TRX_DATE____PARAMETER',
  SubconAccountDigitalReportingSubcontractorLedgerDetailTrxDateReporting = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_LEDGER_DETAIL_TRX_DATE____REPORTING',
  SubconAccountDigitalReportingSubcontractorAgingDetailProjectView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_DETAIL_PROJECT____VIEW',
  SubconAccountDigitalReportingSubcontractorAgingDetailProjectParameter = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_DETAIL_PROJECT____PARAMETER',
  SubconAccountDigitalReportingSubcontractorAgingDetailProjectReporting = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_DETAIL_PROJECT____REPORTING',
  SubconAccountDigitalReportingSubcontractorAgingDetailTrxDateView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_DETAIL_TRX_DATE____VIEW',
  SubconAccountDigitalReportingSubcontractorAgingDetailTrxDateParameter = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_DETAIL_TRX_DATE____PARAMETER',
  SubconAccountDigitalReportingSubcontractorAgingDetailTrxDateReporting = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_DETAIL_TRX_DATE____REPORTING',
  SubconAccountDigitalReportingSubcontractorAgingSummaryTrxDateView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_SUMMARY_TRX_DATE____VIEW',
  SubconAccountDigitalReportingSubcontractorAgingSummaryTrxDateParameter = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_SUMMARY_TRX_DATE____PARAMETER',
  SubconAccountDigitalReportingSubcontractorAgingSummaryTrxDateReporting = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_SUMMARY_TRX_DATE____REPORTING',
  SubconAccountDigitalReportingSubcontractorDetailBreakdownView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_DETAIL_BREAKDOWN____VIEW',
  SubconAccountDigitalReportingSubcontractorDetailBreakdownParameter = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_DETAIL_BREAKDOWN____PARAMETER',
  SubconAccountDigitalReportingSubcontractorDetailBreakdownReporting = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_DETAIL_BREAKDOWN____REPORTING',
  SubconAccountDigitalReportingSubcontractorLedgerDetailProjectView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_LEDGER_DETAIL_PROJECT____VIEW',
  SubconAccountDigitalReportingSubcontractorLedgerDetailProjectParameter = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_LEDGER_DETAIL_PROJECT____PARAMETER',
  SubconAccountDigitalReportingSubcontractorLedgerDetailProjectReporting = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_LEDGER_DETAIL_PROJECT____REPORTING',
  SubconAccountDigitalReportingSubcontractorControlSummaryView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_CONTROL_SUMMARY____VIEW',
  SubconAccountDigitalReportingSubcontractorControlSummaryParameter = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_CONTROL_SUMMARY____PARAMETER',
  SubconAccountDigitalReportingSubcontractorControlSummaryReporting = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_CONTROL_SUMMARY____REPORTING',
  SubconAccountDigitalReportingSubcontractorRetentionLedgerView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_RETENTION_LEDGER____VIEW',
  SubconAccountDigitalReportingSubcontractorRetentionLedgerParameter = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_RETENTION_LEDGER____PARAMETER',
  SubconAccountOsReceivableView = 'SUBCON_ACCOUNT_OS_RECEIVABLE_______VIEW',
  SubconAccountGeneralSettingsIcon = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_______ICON',
  SubconAccountGeneralSettingsSubcontractTradeView = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____VIEW',
  SubconAccountGeneralSettingsSubcontractTradeUpdate = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPDATE',
  SubconAccountGeneralSettingsSubcontractTradeCreate = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____CREATE',
  SubconAccountGeneralSettingsSubcontractTradeDownload = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DOWNLOAD',
  SubconAccountGeneralSettingsSubcontractTradeUpload = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPLOAD',
  SubconAccountGeneralSettingsSubcontractTradeDelete = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DELETE',
  SubconAccountGeneralSettingsSubcontractTradeInactive = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____INACTIVE',
  SubconAccountGeneralSettingsSubcontractTradeActive = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____ACTIVE',
  SubconAccountContractApprovalPolicyIcon = 'SUBCON_ACCOUNT_CONTRACT_APPROVAL_POLICY_______ICON',
  ProjectPurchaseIcon = 'PROJECT_PURCHASE__________ICON',
  ProjectPurchaseContractView = 'PROJECT_PURCHASE_CONTRACT_______VIEW',
  ProjectPurchaseNegotiatedSupplyView = 'PROJECT_PURCHASE_NEGOTIATED_SUPPLY_______VIEW',
  ProjectPurchaseNegotiatedSupplyCreate = 'PROJECT_PURCHASE_NEGOTIATED_SUPPLY_______CREATE',
  ProjectPurchaseNegotiatedSupplyUpdate = 'PROJECT_PURCHASE_NEGOTIATED_SUPPLY_______UPDATE',
  ProjectPurchaseNegotiatedSupplyCancel = 'PROJECT_PURCHASE_NEGOTIATED_SUPPLY_______CANCEL',
  ProjectPurchasePurchaseRequisitionView = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______VIEW',
  ProjectPurchasePurchaseRequisitionCreate = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______CREATE',
  ProjectPurchasePurchaseRequisitionUpdate = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______UPDATE',
  ProjectPurchasePurchaseRequisitionCancel = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______CANCEL',
  ProjectPurchasePurchaseRequisitionApproveReject = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______APPROVE_REJECT',
  ProjectPurchasePurchaseRequisitionDraft = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______DRAFT',
  ProjectPurchasePurchaseRequisitionDelete = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______DELETE',
  ProjectPurchasePurchaseRequisitionBehalfView = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______VIEW',
  ProjectPurchasePurchaseRequisitionBehalfCreate = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______CREATE',
  ProjectPurchasePurchaseRequisitionBehalfDraft = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______DRAFT',
  ProjectPurchasePurchaseRequisitionBehalfUpdate = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______UPDATE',
  ProjectPurchasePurchaseRequisitionBehalfCancel = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______CANCEL',
  ProjectPurchasePurchaseRequisitionBehalfDelete = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______DELETE',
  ProjectPurchasePurchaseRequisitionBehalfApproveReject = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______APPROVE_REJECT',
  ProjectPurchaseRequestForQuotationView = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_______VIEW',
  ProjectPurchaseRequestForQuotationCreate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_______CREATE',
  ProjectPurchaseRequestForQuotationUpdate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_______UPDATE',
  ProjectPurchaseRequestForQuotationApproveReject = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_______APPROVE_REJECT',
  ProjectPurchaseRequestForQuotationDelete = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_______DELETE',
  ProjectPurchaseRequestForQuotationRfqInviteesView = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES____VIEW',
  ProjectPurchaseRequestForQuotationRfqInviteesCreate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES____CREATE',
  ProjectPurchaseRequestForQuotationRfqInviteesUninvite = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES____UNINVITE',
  ProjectPurchaseRequestForQuotationRfqInviteesQuotedRfqView = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES_QUOTED_RFQ_VIEW',
  ProjectPurchaseRequestForQuotationRfqInviteesQuotedRfqCreate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES_QUOTED_RFQ_CREATE',
  ProjectPurchaseRequestForQuotationRfqInviteesQuotedRfqUpdate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES_QUOTED_RFQ_UPDATE',
  ProjectPurchaseRequestForQuotationEvaluationAndSelectionView = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_EVALUATION_AND_SELECTION____VIEW',
  ProjectPurchaseRequestForQuotationEvaluationAndSelectionCreate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_EVALUATION_AND_SELECTION____CREATE',
  ProjectPurchasePurchaseOrderView = 'PROJECT_PURCHASE_PURCHASE_ORDER_______VIEW',
  ProjectPurchasePurchaseOrderCreate = 'PROJECT_PURCHASE_PURCHASE_ORDER_______CREATE',
  ProjectPurchasePurchaseOrderUpdate = 'PROJECT_PURCHASE_PURCHASE_ORDER_______UPDATE',
  ProjectPurchasePurchaseOrderApproveReject = 'PROJECT_PURCHASE_PURCHASE_ORDER_______APPROVE_REJECT',
  ProjectPurchasePurchaseOrderClose = 'PROJECT_PURCHASE_PURCHASE_ORDER_______CLOSE',
  ProjectPurchasePurchaseOrderBehalfView = 'PROJECT_PURCHASE_PURCHASE_ORDER_BEHALF_______VIEW',
  ProjectPurchasePurchaseOrderBehalfUpdate = 'PROJECT_PURCHASE_PURCHASE_ORDER_BEHALF_______UPDATE',
  ProjectPurchasePurchaseOrderBehalfCreate = 'PROJECT_PURCHASE_PURCHASE_ORDER_BEHALF_______CREATE',
  ProjectPurchasePurchaseOrderBehalfApproveReject = 'PROJECT_PURCHASE_PURCHASE_ORDER_BEHALF_______APPROVE_REJECT',
  ProjectPurchasePurchaseOrderBehalfClose = 'PROJECT_PURCHASE_PURCHASE_ORDER_BEHALF_______CLOSE',
  ProjectPurchaseDeliveryOrderView = 'PROJECT_PURCHASE_DELIVERY_ORDER_______VIEW',
  ProjectPurchaseDeliveryOrderCreate = 'PROJECT_PURCHASE_DELIVERY_ORDER_______CREATE',
  ProjectPurchaseDeliveryOrderUpdate = 'PROJECT_PURCHASE_DELIVERY_ORDER_______UPDATE',
  ProjectPurchaseDeliveryOrderDelete = 'PROJECT_PURCHASE_DELIVERY_ORDER_______DELETE',
  ProjectPurchaseDeliveryOrderApproveReject = 'PROJECT_PURCHASE_DELIVERY_ORDER_______APPROVE_REJECT',
  ProjectPurchaseDeliveryOrderClose = 'PROJECT_PURCHASE_DELIVERY_ORDER_______CLOSE',
  ProjectPurchaseGoodReturnNoteView = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______VIEW',
  ProjectPurchaseGoodReturnNoteCreate = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______CREATE',
  ProjectPurchaseGoodReturnNoteUpdate = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______UPDATE',
  ProjectPurchaseGoodReturnNoteDelete = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______DELETE',
  ProjectPurchaseGoodReturnNoteApproveReject = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______APPROVE_REJECT',
  ProjectPurchaseGoodReturnNoteClose = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______CLOSE',
  ProjectPurchaseDigitalReportingView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_______VIEW',
  ProjectPurchaseDigitalReportingFiveWaysMatchReportingView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_FIVE_WAYS_MATCH_REPORTING_______VIEW',
  ProjectPurchaseDigitalReportingFiveWaysMatchReportingParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_FIVE_WAYS_MATCH_REPORTING_______PARAMETER',
  ProjectPurchaseDigitalReportingFiveWaysMatchReportingReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_FIVE_WAYS_MATCH_REPORTING_______REPORTING',
  ProjectPurchaseDigitalReportingLateDeliveryReportView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_LATE_DELIVERY_REPORT_______VIEW',
  ProjectPurchaseDigitalReportingLateDeliveryReportParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_LATE_DELIVERY_REPORT_______PARAMETER',
  ProjectPurchaseDigitalReportingLateDeliveryReportReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_LATE_DELIVERY_REPORT_______REPORTING',
  ProjectPurchaseDigitalReportingOutstandingDeliveryReportView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_OUTSTANDING_DELIVERY_REPORT_______VIEW',
  ProjectPurchaseDigitalReportingOutstandingDeliveryReportParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_OUTSTANDING_DELIVERY_REPORT_______PARAMETER',
  ProjectPurchaseDigitalReportingOutstandingDeliveryReportReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_OUTSTANDING_DELIVERY_REPORT_______REPORTING',
  ProjectPurchaseDigitalReportingPricingHistoryReportView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PRICING_HISTORY_REPORT_______VIEW',
  ProjectPurchaseDigitalReportingPricingHistoryReportParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PRICING_HISTORY_REPORT_______PARAMETER',
  ProjectPurchaseDigitalReportingPricingHistoryReportReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PRICING_HISTORY_REPORT_______REPORTING',
  ProjectPurchaseDigitalReportingProjectPurchaseAnalysisReportView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PROJECT_PURCHASE_ANALYSIS_REPORT_______VIEW',
  ProjectPurchaseDigitalReportingProjectPurchaseAnalysisReportParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PROJECT_PURCHASE_ANALYSIS_REPORT_______PARAMETER',
  ProjectPurchaseDigitalReportingProjectPurchaseAnalysisReportReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PROJECT_PURCHASE_ANALYSIS_REPORT_______REPORTING',
  ProjectPurchaseDigitalReportingPurchaseAnalysisByItemView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ANALYSIS_BY_ITEM_______VIEW',
  ProjectPurchaseDigitalReportingPurchaseAnalysisByItemParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ANALYSIS_BY_ITEM_______PARAMETER',
  ProjectPurchaseDigitalReportingPurchaseAnalysisByItemReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ANALYSIS_BY_ITEM_______REPORTING',
  ProjectPurchaseDigitalReportingPurchaseAnalysisBySupplierView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ANALYSIS_BY_SUPPLIER_______VIEW',
  ProjectPurchaseDigitalReportingPurchaseAnalysisBySupplierParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ANALYSIS_BY_SUPPLIER_______PARAMETER',
  ProjectPurchaseDigitalReportingPurchaseAnalysisBySupplierReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ANALYSIS_BY_SUPPLIER_______REPORTING',
  ProjectPurchaseDigitalReportingPurchaseOrderStatusReportView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ORDER_STATUS_REPORT_______VIEW',
  ProjectPurchaseDigitalReportingPurchaseOrderStatusReportParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ORDER_STATUS_REPORT_______PARAMETER',
  ProjectPurchaseDigitalReportingPurchaseOrderStatusReportReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ORDER_STATUS_REPORT_______REPORTING',
  ProjectPurchaseDigitalReportingPurchaseOrderStatusReportByProjectView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ORDER_STATUS_REPORT_BY_PROJECT_______VIEW',
  ProjectPurchaseDigitalReportingPurchaseOrderStatusReportByProjectParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ORDER_STATUS_REPORT_BY_PROJECT_______PARAMETER',
  ProjectPurchaseDigitalReportingPurchaseOrderStatusReportByProjectReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ORDER_STATUS_REPORT_BY_PROJECT_______REPORTING',
  ProjectPurchaseGeneralSettingsIcon = 'PROJECT_PURCHASE_GENERAL_SETTINGS_______ICON',
  ProjectPurchaseGeneralSettingsBuildingMaterialView = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____VIEW',
  ProjectPurchaseGeneralSettingsBuildingMaterialCreate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____CREATE',
  ProjectPurchaseGeneralSettingsBuildingMaterialUpdate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____UPDATE',
  ProjectPurchaseGeneralSettingsBuildingMaterialDelete = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____DELETE',
  ProjectPurchaseGeneralSettingsBuildingMaterialDownload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____DOWNLOAD',
  ProjectPurchaseGeneralSettingsBuildingMaterialUpload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____UPLOAD',
  ProjectPurchaseGeneralSettingsBuildingMaterialInactive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____INACTIVE',
  ProjectPurchaseGeneralSettingsBuildingMaterialActive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____ACTIVE',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemView = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_VIEW',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemCreate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_CREATE',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemUpdate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_UPDATE',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemInactive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_INACTIVE',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemActive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_ACTIVE',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemDelete = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_DELETE',
  ProjectPurchaseGeneralSettingsLabourCategoryView = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____VIEW',
  ProjectPurchaseGeneralSettingsLabourCategoryCreate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____CREATE',
  ProjectPurchaseGeneralSettingsLabourCategoryUpdate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____UPDATE',
  ProjectPurchaseGeneralSettingsLabourCategoryDownload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____DOWNLOAD',
  ProjectPurchaseGeneralSettingsLabourCategoryUpload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____UPLOAD',
  ProjectPurchaseGeneralSettingsLabourCategoryDelete = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____DELETE',
  ProjectPurchaseGeneralSettingsLabourCategoryInactive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____INACTIVE',
  ProjectPurchaseGeneralSettingsLabourCategoryActive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____ACTIVE',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseView = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____VIEW',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseCreate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____CREATE',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseUpdate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPDATE',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseDelete = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DELETE',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseDownload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DOWNLOAD',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseUpload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPLOAD',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseInactive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____INACTIVE',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseActive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____ACTIVE',
  ProjectPurchaseGeneralSettingsPMCategoryView = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____VIEW',
  ProjectPurchaseGeneralSettingsPMCategoryCreate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____CREATE',
  ProjectPurchaseGeneralSettingsPMCategoryUpdate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____UPDATE',
  ProjectPurchaseGeneralSettingsPMCategoryDelete = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____DELETE',
  ProjectPurchaseGeneralSettingsPMCategoryDownload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____DOWNLOAD',
  ProjectPurchaseGeneralSettingsPMCategoryUpload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____UPLOAD',
  ProjectPurchaseGeneralSettingsPMCategoryActive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____ACTIVE',
  ProjectPurchaseGeneralSettingsPMCategoryInactive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____INACTIVE',
  ProjectPurchaseContractApprovalPolicyIcon = 'PROJECT_PURCHASE_CONTRACT_APPROVAL_POLICY_______ICON',
  SupplierAcctIcon = 'SUPPLIER_ACCT__________ICON',
  SupplierAcctView = 'SUPPLIER_ACCT__________VIEW',
  SupplierAcctCreate = 'SUPPLIER_ACCT__________CREATE',
  SupplierAcctSupplierProfileView = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_______VIEW',
  SupplierAcctSupplierProfileCreate = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_______CREATE',
  SupplierAcctSupplierProfileUpdate = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_______UPDATE',
  SupplierAcctSupplierProfileDelete = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_______DELETE',
  SupplierAcctSupplierProfileDownload = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_______DOWNLOAD',
  SupplierAcctSupplierProfileUpload = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_______UPLOAD',
  SupplierAcctSupplierProfilePreferredSupplierProductsCreate = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_PREFERRED_SUPPLIER_PRODUCTS_______CREATE',
  SupplierAcctSupplierProfilePreferredSupplierProductsView = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_PREFERRED_SUPPLIER_PRODUCTS_______VIEW',
  SupplierAcctSupplierProfilePreferredSupplierProductsUpdate = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_PREFERRED_SUPPLIER_PRODUCTS_______UPDATE',
  SupplierAcctSupplierProfileOsPayableView = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_OS_PAYABLE_______VIEW',
  SupplierAcctPreferredSupplierProductsCreate = 'SUPPLIER_ACCT_PREFERRED_SUPPLIER_PRODUCTS_______CREATE',
  SupplierAcctPreferredSupplierProductsUpdate = 'SUPPLIER_ACCT_PREFERRED_SUPPLIER_PRODUCTS_______UPDATE',
  SupplierAcctAccTransactionsView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_______VIEW',
  SupplierAcctAdvanceDepositsView = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____VIEW',
  SupplierAcctAdvanceDepositsCreate = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____CREATE',
  SupplierAcctAdvanceDepositsUpdate = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____UPDATE',
  SupplierAcctAdvanceDepositsCancel = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____CANCEL',
  SupplierAcctAdvanceDepositsApproveReject = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____APPROVE_REJECT',
  SupplierAcctAdvanceDepositsDelete = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____DELETE',
  SupplierAcctAdvanceDepositsAllocation = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____ALLOCATION',
  SupplierAcctAccTransactionsDepositsView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____VIEW',
  SupplierAcctAccTransactionsDepositsCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____CREATE',
  SupplierAcctAccTransactionsDepositsUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____UPDATE',
  SupplierAcctAccTransactionsDepositsApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____APPROVE_REJECT',
  SupplierAcctAccTransactionsDepositsCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____CANCEL',
  SupplierAcctAccTransactionsDepositsAllocation = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____ALLOCATION',
  SupplierAcctAccTransactionsDepositsDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____DELETE',
  SupplierAcctInvoicesView = 'SUPPLIER_ACCT_INVOICES____VIEW',
  SupplierAcctInvoicesCreate = 'SUPPLIER_ACCT_INVOICES____CREATE',
  SupplierAcctInvoicesUpdate = 'SUPPLIER_ACCT_INVOICES____UPDATE',
  SupplierAcctInvoicesApproveReject = 'SUPPLIER_ACCT_INVOICES____APPROVE_REJECT',
  SupplierAcctInvoicesCancel = 'SUPPLIER_ACCT_INVOICES____CANCEL',
  SupplierAcctInvoicesDelete = 'SUPPLIER_ACCT_INVOICES____DELETE',
  SupplierAcctAccTransactionsInvoicesView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____VIEW',
  SupplierAcctAccTransactionsInvoicesCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____CREATE',
  SupplierAcctAccTransactionsInvoicesUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____UPDATE',
  SupplierAcctAccTransactionsInvoicesApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____APPROVE_REJECT',
  SupplierAcctAccTransactionsInvoicesCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____CANCEL',
  SupplierAcctAccTransactionsInvoicesDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____DELETE',
  SupplierAcctDebitNotesView = 'SUPPLIER_ACCT_DEBIT_NOTES____VIEW',
  SupplierAcctDebitNotesCreate = 'SUPPLIER_ACCT_DEBIT_NOTES____CREATE',
  SupplierAcctDebitNotesUpdate = 'SUPPLIER_ACCT_DEBIT_NOTES____UPDATE',
  SupplierAcctDebitNotesApproveReject = 'SUPPLIER_ACCT_DEBIT_NOTES____APPROVE_REJECT',
  SupplierAcctDebitNotesCancel = 'SUPPLIER_ACCT_DEBIT_NOTES____CANCEL',
  SupplierAcctDebitNotesDelete = 'SUPPLIER_ACCT_DEBIT_NOTES____DELETE',
  SupplierAcctAccTransactionsDebitNotesView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____VIEW',
  SupplierAcctAccTransactionsDebitNotesCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____CREATE',
  SupplierAcctAccTransactionsDebitNotesUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____UPDATE',
  SupplierAcctAccTransactionsDebitNotesApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____APPROVE_REJECT',
  SupplierAcctAccTransactionsDebitNotesCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____CANCEL',
  SupplierAcctAccTransactionsDebitNotesDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____DELETE',
  SupplierAcctCreditNotesView = 'SUPPLIER_ACCT_CREDIT_NOTES____VIEW',
  SupplierAcctCreditNotesCreate = 'SUPPLIER_ACCT_CREDIT_NOTES____CREATE',
  SupplierAcctCreditNotesUpdate = 'SUPPLIER_ACCT_CREDIT_NOTES____UPDATE',
  SupplierAcctCreditNotesApproveReject = 'SUPPLIER_ACCT_CREDIT_NOTES____APPROVE_REJECT',
  SupplierAcctCreditNotesCancel = 'SUPPLIER_ACCT_CREDIT_NOTES____CANCEL',
  SupplierAcctCreditNotesDelete = 'SUPPLIER_ACCT_CREDIT_NOTES____DELETE',
  SupplierAcctCreditNotesAllocation = 'SUPPLIER_ACCT_CREDIT_NOTES____ALLOCATION',
  SupplierAcctAccTransactionsCreditNotesView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____VIEW',
  SupplierAcctAccTransactionsCreditNotesCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____CREATE',
  SupplierAcctAccTransactionsCreditNotesUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____UPDATE',
  SupplierAcctAccTransactionsCreditNotesApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____APPROVE_REJECT',
  SupplierAcctAccTransactionsCreditNotesCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____CANCEL',
  SupplierAcctAccTransactionsCreditNotesDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____DELETE',
  SupplierAcctAccTransactionsCreditNotesAllocation = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____ALLOCATION',
  SupplierAcctPaymentsView = 'SUPPLIER_ACCT_PAYMENTS____VIEW',
  SupplierAcctPaymentsCreate = 'SUPPLIER_ACCT_PAYMENTS____CREATE',
  SupplierAcctPaymentsUpdate = 'SUPPLIER_ACCT_PAYMENTS____UPDATE',
  SupplierAcctPaymentsApproveReject = 'SUPPLIER_ACCT_PAYMENTS____APPROVE_REJECT',
  SupplierAcctPaymentsCancel = 'SUPPLIER_ACCT_PAYMENTS____CANCEL',
  SupplierAcctPaymentsDelete = 'SUPPLIER_ACCT_PAYMENTS____DELETE',
  SupplierAcctAccTransactionsPaymentsView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____VIEW',
  SupplierAcctAccTransactionsPaymentsCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____CREATE',
  SupplierAcctAccTransactionsPaymentsUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____UPDATE',
  SupplierAcctAccTransactionsPaymentsApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____APPROVE_REJECT',
  SupplierAcctAccTransactionsPaymentsCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____CANCEL',
  SupplierAcctAccTransactionsPaymentsDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____DELETE',
  SupplierAcctDnSupplierCreate = 'SUPPLIER_ACCT_DN_SUPPLIER____CREATE',
  SupplierAcctDnSupplierView = 'SUPPLIER_ACCT_DN_SUPPLIER____VIEW',
  SupplierAcctDnSupplierUpdate = 'SUPPLIER_ACCT_DN_SUPPLIER____UPDATE',
  SupplierAcctDnSupplierApproveReject = 'SUPPLIER_ACCT_DN_SUPPLIER____APPROVE_REJECT',
  SupplierAcctDnSupplierCancel = 'SUPPLIER_ACCT_DN_SUPPLIER____CANCEL',
  SupplierAcctDnSupplierDelete = 'SUPPLIER_ACCT_DN_SUPPLIER____DELETE',
  SupplierAcctDnSupplierAllocation = 'SUPPLIER_ACCT_DN_SUPPLIER____ALLOCATION',
  SupplierAcctAccTransactionsDnSupplierCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____CREATE',
  SupplierAcctAccTransactionsDnSupplierView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____VIEW',
  SupplierAcctAccTransactionsDnSupplierUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____UPDATE',
  SupplierAcctAccTransactionsDnSupplierApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____APPROVE_REJECT',
  SupplierAcctAccTransactionsDnSupplierCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____CANCEL',
  SupplierAcctAccTransactionsDnSupplierDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____DELETE',
  SupplierAcctAccTransactionsDnSupplierAllocation = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____ALLOCATION',
  SupplierAcctCnSupplierCreate = 'SUPPLIER_ACCT_CN_SUPPLIER____CREATE',
  SupplierAcctCnSupplierView = 'SUPPLIER_ACCT_CN_SUPPLIER____VIEW',
  SupplierAcctCnSupplierUpdate = 'SUPPLIER_ACCT_CN_SUPPLIER____UPDATE',
  SupplierAcctCnSupplierCancel = 'SUPPLIER_ACCT_CN_SUPPLIER____CANCEL',
  SupplierAcctCnSupplierApproveReject = 'SUPPLIER_ACCT_CN_SUPPLIER____APPROVE_REJECT',
  SupplierAcctCnSupplierDelete = 'SUPPLIER_ACCT_CN_SUPPLIER____DELETE',
  SupplierAcctAccTransactionsCnSupplierCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____CREATE',
  SupplierAcctAccTransactionsCnSupplierView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____VIEW',
  SupplierAcctAccTransactionsCnSupplierUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____UPDATE',
  SupplierAcctAccTransactionsCnSupplierCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____CANCEL',
  SupplierAcctAccTransactionsCnSupplierApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____APPROVE_REJECT',
  SupplierAcctAccTransactionsCnSupplierDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____DELETE',
  SupplierAcctAccTransactionsOsPayableView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_OS_PAYABLE____VIEW',
  SupplierAcctDigitalReportingView = 'SUPPLIER_ACCT_DIGITAL_REPORTING____VIEW',
  SupplierAcctDigitalReportingSupplierLedgerReportView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_LEDGER_REPORT____VIEW',
  SupplierAcctDigitalReportingSupplierLedgerReportParameter = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_LEDGER_REPORT____PARAMETER',
  SupplierAcctDigitalReportingSupplierLedgerReportReporting = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_LEDGER_REPORT____REPORTING',
  SupplierAcctDigitalReportingSupplierAgingDetailByProjectView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_DETAIL_BY_PROJECT____VIEW',
  SupplierAcctDigitalReportingSupplierAgingDetailByProjectParameter = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_DETAIL_BY_PROJECT____PARAMETER',
  SupplierAcctDigitalReportingSupplierAgingDetailByProjectReporting = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_DETAIL_BY_PROJECT____REPORTING',
  SupplierAcctDigitalReportingSupplierAgingDetailReportByTrxDateView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_DETAIL_REPORT_BY_TRX_DATE____VIEW',
  SupplierAcctDigitalReportingSupplierAgingDetailReportByTrxDateParameter = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_DETAIL_REPORT_BY_TRX_DATE____PARAMETER',
  SupplierAcctDigitalReportingSupplierAgingDetailReportByTrxDateReporting = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_DETAIL_REPORT_BY_TRX_DATE____REPORTING',
  SupplierAcctDigitalReportingSupplierAgingSummaryReportByTrxDateView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_SUMMARY_REPORT_BY_TRX_DATE____VIEW',
  SupplierAcctDigitalReportingSupplierAgingSummaryReportByTrxDateParameter = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_SUMMARY_REPORT_BY_TRX_DATE____PARAMETER',
  SupplierAcctDigitalReportingSupplierAgingSummaryReportByTrxDateReporting = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_SUMMARY_REPORT_BY_TRX_DATE____REPORTING',
  SupplierAcctDigitalReportingSupplierControlSummaryReportView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_CONTROL_SUMMARY_REPORT____VIEW',
  SupplierAcctDigitalReportingSupplierControlSummaryReportParameter = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_CONTROL_SUMMARY_REPORT____PARAMETER',
  SupplierAcctDigitalReportingSupplierControlSummaryReportReporting = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_CONTROL_SUMMARY_REPORT____REPORTING',
  SupplierAcctDigitalReportingSupplierDetailBreakdownReportView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_DETAIL_BREAKDOWN_REPORT____VIEW',
  SupplierAcctDigitalReportingSupplierDetailBreakdownReportParameter = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_DETAIL_BREAKDOWN_REPORT____PARAMETER',
  SupplierAcctDigitalReportingSupplierDetailBreakdownReportReporting = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_DETAIL_BREAKDOWN_REPORT____REPORTING',
  SupplierAcctDigitalReportingSupplierLedgerReportByProjectView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_LEDGER_REPORT_BY_PROJECT____VIEW',
  SupplierAcctDigitalReportingSupplierLedgerReportByProjectParameter = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_LEDGER_REPORT_BY_PROJECT____PARAMETER',
  SupplierAcctDigitalReportingSupplierLedgerReportByProjectReporting = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_LEDGER_REPORT_BY_PROJECT____REPORTING',
  SupplierAcctGeneralSettingsIcon = 'SUPPLIER_ACCT_GENERAL_SETTINGS_______ICON',
  SupplierAcctGeneralSettingsBuildingMaterialView = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____VIEW',
  SupplierAcctGeneralSettingsBuildingMaterialCreate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____CREATE',
  SupplierAcctGeneralSettingsBuildingMaterialUpdate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____UPDATE',
  SupplierAcctGeneralSettingsBuildingMaterialDelete = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____DELETE',
  SupplierAcctGeneralSettingsBuildingMaterialDownload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____DOWNLOAD',
  SupplierAcctGeneralSettingsBuildingMaterialUpload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____UPLOAD',
  SupplierAcctGeneralSettingsBuildingMaterialInactive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____INACTIVE',
  SupplierAcctGeneralSettingsBuildingMaterialActive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____ACTIVE',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemView = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_VIEW',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemCreate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_CREATE',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemUpdate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_UPDATE',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemDelete = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_DELETE',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemInactive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_INACTIVE',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemActive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_ACTIVE',
  SupplierAcctGeneralSettingsPAndMCategoryView = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____VIEW',
  SupplierAcctGeneralSettingsPAndMCategoryCreate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____CREATE',
  SupplierAcctGeneralSettingsPAndMCategoryUpdate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____UPDATE',
  SupplierAcctGeneralSettingsPAndMCategoryDelete = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____DELETE',
  SupplierAcctGeneralSettingsPAndMCategoryDownload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____DOWNLOAD',
  SupplierAcctGeneralSettingsPAndMCategoryUpload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____UPLOAD',
  SupplierAcctGeneralSettingsPAndMCategoryInactive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____INACTIVE',
  SupplierAcctGeneralSettingsPAndMCategoryActive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____ACTIVE',
  SupplierAcctGeneralSettingsLabourCategoryView = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____VIEW',
  SupplierAcctGeneralSettingsLabourCategoryCreate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____CREATE',
  SupplierAcctGeneralSettingsLabourCategoryUpdate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____UPDATE',
  SupplierAcctGeneralSettingsLabourCategoryDelete = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____DELETE',
  SupplierAcctGeneralSettingsLabourCategoryDownload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____DOWNLOAD',
  SupplierAcctGeneralSettingsLabourCategoryUpload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____UPLOAD',
  SupplierAcctGeneralSettingsLabourCategoryInactive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____INACTIVE',
  SupplierAcctGeneralSettingsLabourCategoryActive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____ACTIVE',
  SupplierAcctGeneralSettingsMiscellaneousExpenseView = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____VIEW',
  SupplierAcctGeneralSettingsMiscellaneousExpenseCreate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____CREATE',
  SupplierAcctGeneralSettingsMiscellaneousExpenseUpdate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPDATE',
  SupplierAcctGeneralSettingsMiscellaneousExpenseDelete = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DELETE',
  SupplierAcctGeneralSettingsMiscellaneousExpenseDownload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DOWNLOAD',
  SupplierAcctGeneralSettingsMiscellaneousExpenseUpload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPLOAD',
  SupplierAcctGeneralSettingsMiscellaneousExpenseInactive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____INACTIVE',
  SupplierAcctGeneralSettingsMiscellaneousExpenseActive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____ACTIVE',
  SupplierAcctContractApprovalPolicyIcon = 'SUPPLIER_ACCT_CONTRACT_APPROVAL_POLICY_______ICON',
  SiteMgmtIcon = 'SITE_MGMT__________ICON',
  ProjectCostingIcon = 'PROJECT_COSTING__________ICON',
  ProjectCostingContractView = 'PROJECT_COSTING_CONTRACT_______VIEW',
  ProjectCostingBudgetingView = 'PROJECT_COSTING_BUDGETING_______VIEW',
  ProjectCostingBudgetingCostCategoryView = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY____VIEW',
  ProjectCostingBudgetingCostCategoryOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingCostCategoryOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingCostCategoryOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingCostCategoryOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingCostCategoryReviseBudgetView = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingCostCategoryReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_REVISE_BUDGET_UPDATE',
  ProjectCostingBudgetingSubcontractTradeCostView = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST____VIEW',
  ProjectCostingBudgetingSubcontractTradeCostOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingSubcontractTradeCostOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingSubcontractTradeCostOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingSubcontractTradeCostOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingSubcontractTradeCostReviseBudgetView = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingSubcontractTradeCostReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_REVISE_BUDGET_UPDATE',
  ProjectCostingBudgetingMaterialCostView = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST____VIEW',
  ProjectCostingBudgetingMaterialCostOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingMaterialCostOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingMaterialCostOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingMaterialCostOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingMaterialCostReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_REVISE_BUDGET_UPDATE',
  ProjectCostingBudgetingMaterialCostReviseBudgetView = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingPAndMCostView = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST____VIEW',
  ProjectCostingBudgetingPAndMCostOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingPAndMCostOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingPAndMCostOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingPAndMCostOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingPAndMCostReviseBudgetView = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingPAndMCostReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_REVISE_BUDGET_UPDATE',
  ProjectCostingBudgetingLabourCostView = 'PROJECT_COSTING_BUDGETING_LABOUR_COST____VIEW',
  ProjectCostingBudgetingLabourCostOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingLabourCostOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingLabourCostOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingLabourCostOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingLabourCostReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_REVISE_BUDGET_UPDATE',
  ProjectCostingBudgetingLabourCostReviseBudgetView = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingMiscExpensesView = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES____VIEW',
  ProjectCostingBudgetingMiscExpensesOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingMiscExpensesOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingMiscExpensesOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingMiscExpensesOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingMiscExpensesReviseBudgetView = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingMiscExpensesReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_REVISE_BUDGET_UPDATE',
  ProjectCostingMiscellaneousExpenseView = 'PROJECT_COSTING_MISCELLANEOUS_EXPENSE____VIEW',
  ProjectCostingMiscellaneousExpenseCreate = 'PROJECT_COSTING_MISCELLANEOUS_EXPENSE____CREATE',
  ProjectCostingMiscellaneousExpenseUpdate = 'PROJECT_COSTING_MISCELLANEOUS_EXPENSE____UPDATE',
  ProjectCostingMiscellaneousExpenseCancel = 'PROJECT_COSTING_MISCELLANEOUS_EXPENSE____CANCEL',
  ProjectCostingMiscellaneousExpenseDelete = 'PROJECT_COSTING_MISCELLANEOUS_EXPENSE____DELETE',
  ProjectCostingMiscellaneousExpenseApproveReject = 'PROJECT_COSTING_MISCELLANEOUS_EXPENSE____APPROVE_REJECT',
  ProjectCostingProjectCostingView = 'PROJECT_COSTING_PROJECT_COSTING_______VIEW',
  ProjectCostingProjectCostingExport = 'PROJECT_COSTING_PROJECT_COSTING_______EXPORT',
  ProjectCostingProjectCostingShare = 'PROJECT_COSTING_PROJECT_COSTING_______SHARE',
  ProjectCostingProjectCostingSubcontractCostView = 'PROJECT_COSTING_PROJECT_COSTING_SUBCONTRACT_COST____VIEW',
  ProjectCostingProjectCostingSubcontractCostDownload = 'PROJECT_COSTING_PROJECT_COSTING_SUBCONTRACT_COST____DOWNLOAD',
  ProjectCostingProjectCostingMaterialCostView = 'PROJECT_COSTING_PROJECT_COSTING_MATERIAL_COST____VIEW',
  ProjectCostingProjectCostingMaterialCostDownload = 'PROJECT_COSTING_PROJECT_COSTING_MATERIAL_COST____DOWNLOAD',
  ProjectCostingProjectCostingPAndMCostView = 'PROJECT_COSTING_PROJECT_COSTING_P_AND_M_COST____VIEW',
  ProjectCostingProjectCostingPAndMCostDownload = 'PROJECT_COSTING_PROJECT_COSTING_P_AND_M_COST____DOWNLOAD',
  ProjectCostingProjectCostingLabourCostView = 'PROJECT_COSTING_PROJECT_COSTING_LABOUR_COST____VIEW',
  ProjectCostingProjectCostingLabourCostDownload = 'PROJECT_COSTING_PROJECT_COSTING_LABOUR_COST____DOWNLOAD',
  ProjectCostingProjectCostingMiscellaneousExpensesView = 'PROJECT_COSTING_PROJECT_COSTING_MISCELLANEOUS_EXPENSES____VIEW',
  ProjectCostingProjectCostingMiscellaneousExpensesDownload = 'PROJECT_COSTING_PROJECT_COSTING_MISCELLANEOUS_EXPENSES____DOWNLOAD',
  ProjectCostingProjectPlView = 'PROJECT_COSTING_PROJECT_PL_______VIEW',
  ProjectCostingProjectPlShare = 'PROJECT_COSTING_PROJECT_PL_______SHARE',
  ProjectCostingProjectPlDownload = 'PROJECT_COSTING_PROJECT_PL____DOWNLOAD',
  ProjectCostingProjectPlExport = 'PROJECT_COSTING_PROJECT_PL____EXPORT',
  ProjectCostingProjectPlRevisedContractSumsView = 'PROJECT_COSTING_PROJECT_PL_REVISED_CONTRACT_SUMS_____VIEW',
  ProjectCostingProjectPlProgressIncomeView = 'PROJECT_COSTING_PROJECT_PL_PROGRESS_INCOME_____VIEW',
  ProjectCostingProjectPlProgressIncomeDownload = 'PROJECT_COSTING_PROJECT_PL_PROGRESS_INCOME____DOWNLOAD',
  ProjectCostingProjectPlAdjustmentClientView = 'PROJECT_COSTING_PROJECT_PL_ADJUSTMENT_CLIENT_____VIEW',
  ProjectCostingProjectPlAdjustmentClientDownload = 'PROJECT_COSTING_PROJECT_PL_ADJUSTMENT_CLIENT____DOWNLOAD',
  ProjectCostingProjectPlMiscellaneousInvoicesArView = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_INVOICES_AR_____VIEW',
  ProjectCostingProjectPlMiscellaneousInvoicesArDownload = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_INVOICES_AR____DOWNLOAD',
  ProjectCostingProjectPlSubcontractCostView = 'PROJECT_COSTING_PROJECT_PL_SUBCONTRACT_COST_____VIEW',
  ProjectCostingProjectPlSubcontractCostDownload = 'PROJECT_COSTING_PROJECT_PL_SUBCONTRACT_COST____DOWNLOAD',
  ProjectCostingProjectPlMaterialCostView = 'PROJECT_COSTING_PROJECT_PL_MATERIAL_COST_____VIEW',
  ProjectCostingProjectPlMaterialCostDownload = 'PROJECT_COSTING_PROJECT_PL_MATERIAL_COST____DOWNLOAD',
  ProjectCostingProjectPlPAndMCostView = 'PROJECT_COSTING_PROJECT_PL_P_AND_M_COST_____VIEW',
  ProjectCostingProjectPlPAndMCostDownload = 'PROJECT_COSTING_PROJECT_PL_P_AND_M_COST____DOWNLOAD',
  ProjectCostingProjectPlLabourCostView = 'PROJECT_COSTING_PROJECT_PL_LABOUR_COST_____VIEW',
  ProjectCostingProjectPlLabourCostDownload = 'PROJECT_COSTING_PROJECT_PL_LABOUR_COST____DOWNLOAD',
  ProjectCostingProjectPlMiscellaneousInvoiceApView = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_INVOICE_AP_____VIEW',
  ProjectCostingProjectPlMiscellaneousInvoiceApDownload = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_INVOICE_AP____DOWNLOAD',
  ProjectCostingProjectPlAdjustmentSupplierSubconView = 'PROJECT_COSTING_PROJECT_PL_ADJUSTMENT_SUPPLIER_SUBCON_____VIEW',
  ProjectCostingProjectPlAdjustmentSupplierSubconDownload = 'PROJECT_COSTING_PROJECT_PL_ADJUSTMENT_SUPPLIER_SUBCON____DOWNLOAD',
  ProjectCostingProjectPlMiscellaneousExpensesView = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_EXPENSES_____VIEW',
  ProjectCostingProjectPlMiscellaneousExpensesDownload = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_EXPENSES____DOWNLOAD',
  ProjectCostingProjectCashFlowView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_______VIEW',
  ProjectCostingProjectCashFlowShare = 'PROJECT_COSTING_PROJECT_CASH_FLOW_______SHARE',
  ProjectCostingProjectCashFlowReceivedFromClientView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_RECEIVED_FROM_CLIENT____VIEW',
  ProjectCostingProjectCashFlowReceivedFromClientDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_RECEIVED_FROM_CLIENT____DOWNLOAD',
  ProjectCostingProjectCashFlowPaidToSubcontractorView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_PAID_TO_SUBCONTRACTOR____VIEW',
  ProjectCostingProjectCashFlowPaidToSubcontractorDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_PAID_TO_SUBCONTRACTOR____DOWNLOAD',
  ProjectCostingProjectCashFlowPaidToSupplierView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_PAID_TO_SUPPLIER____VIEW',
  ProjectCostingProjectCashFlowPaidToSupplierDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_PAID_TO_SUPPLIER____DOWNLOAD',
  ProjectCostingProjectCashFlowOtherExpensesView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OTHER_EXPENSES____VIEW',
  ProjectCostingProjectCashFlowOsReceivableFromClientView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_RECEIVABLE_FROM_CLIENT____VIEW',
  ProjectCostingProjectCashFlowOsReceivableFromClientDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_RECEIVABLE_FROM_CLIENT____DOWNLOAD',
  ProjectCostingProjectCashFlowOsPayableToSubcontractorView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_PAYABLE_TO_SUBCONTRACTOR____VIEW',
  ProjectCostingProjectCashFlowOsPayableToSubcontractorDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_PAYABLE_TO_SUBCONTRACTOR____DOWNLOAD',
  ProjectCostingProjectCashFlowOsPayableToSupplierView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_PAYABLE_TO_SUPPLIER____VIEW',
  ProjectCostingProjectCashFlowOsPayableToSupplierDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_PAYABLE_TO_SUPPLIER____DOWNLOAD',
  ProjectCostingProjectCostPerformanceView = 'PROJECT_COSTING_PROJECT_COST_PERFORMANCE_______VIEW',
  ProjectCostingProjectCostPerformanceShare = 'PROJECT_COSTING_PROJECT_COST_PERFORMANCE_______SHARE',
  ProjectCostingProjectCostPerformancePercentageOfCompletionUpdate = 'PROJECT_COSTING_PROJECT_COST_PERFORMANCE_PERCENTAGE_OF_COMPLETION____UPDATE',
  ProjectCostingProjectFinancialStatusView = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_______VIEW',
  ProjectCostingProjectFinancialStatusShare = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_______SHARE',
  ProjectCostingProjectFinancialStatusPercentageOfCompletionUpdate = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_PERCENTAGE_OF_COMPLETION____UPDATE',
  ProjectCostingProjectFinancialStatusBillingToDateView = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_BILLING_TO_DATE____VIEW',
  ProjectCostingProjectFinancialStatusCollectionToDateView = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_COLLECTION_TO_DATE____VIEW',
  ProjectCostingProjectFinancialStatusPayableToDateView = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_PAYABLE_TO_DATE____VIEW',
  ProjectCostingProjectFinancialStatusPaymentToDateView = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_PAYMENT_TO_DATE____VIEW',
  ProjectCostingProjectBudgetAnalysisShare = 'PROJECT_COSTING_PROJECT_BUDGET_ANALYSIS_______SHARE',
  ProjectCostingProjectBudgetAnalysisView = 'PROJECT_COSTING_PROJECT_BUDGET_ANALYSIS_______VIEW',
  ProjectCostingProjectBudgetAnalysisOriginalBudgetView = 'PROJECT_COSTING_PROJECT_BUDGET_ANALYSIS_ORIGINAL_BUDGET_______VIEW',
  ProjectCostingProjectBudgetAnalysisRevisedBudgetView = 'PROJECT_COSTING_PROJECT_BUDGET_ANALYSIS_REVISED_BUDGET_______VIEW',
  ProjectCostingProjectBudgetVsActualAnalysisView = 'PROJECT_COSTING_PROJECT_BUDGET_VS_ACTUAL_ANALYSIS_______VIEW',
  ProjectCostingProjectBudgetVsActualAnalysisShare = 'PROJECT_COSTING_PROJECT_BUDGET_VS_ACTUAL_ANALYSIS_______SHARE',
  ProjectCostingDigitalReportView = 'PROJECT_COSTING_DIGITAL_REPORT_______VIEW',
  ProjectCostingDigitalReportExecutiveSummaryReportView = 'PROJECT_COSTING_DIGITAL_REPORT_EXECUTIVE_SUMMARY_REPORT_______VIEW',
  ProjectCostingDigitalReportExecutiveSummaryReportParameter = 'PROJECT_COSTING_DIGITAL_REPORT_EXECUTIVE_SUMMARY_REPORT_______PARAMETER',
  ProjectCostingDigitalReportExecutiveSummaryReportReporting = 'PROJECT_COSTING_DIGITAL_REPORT_EXECUTIVE_SUMMARY_REPORT_______REPORTING',
  ProjectCostingDigitalReportProjectCostAnalysisReportView = 'PROJECT_COSTING_DIGITAL_REPORT_PROJECT_COST_ANALYSIS_REPORT_______VIEW',
  ProjectCostingDigitalReportProjectCostAnalysisReportParameter = 'PROJECT_COSTING_DIGITAL_REPORT_PROJECT_COST_ANALYSIS_REPORT_______PARAMETER',
  ProjectCostingDigitalReportProjectCostAnalysisReportReporting = 'PROJECT_COSTING_DIGITAL_REPORT_PROJECT_COST_ANALYSIS_REPORT_______REPORTING',
  ProjectCostingDigitalReportProjectLedgerReportView = 'PROJECT_COSTING_DIGITAL_REPORT_PROJECT_LEDGER_REPORT_______VIEW',
  ProjectCostingDigitalReportProjectLedgerReportParameter = 'PROJECT_COSTING_DIGITAL_REPORT_PROJECT_LEDGER_REPORT_______PARAMETER',
  ProjectCostingDigitalReportProjectLedgerReportReporting = 'PROJECT_COSTING_DIGITAL_REPORT_PROJECT_LEDGER_REPORT_______REPORTING',
  ProjectCostingGeneralSettingsIcon = 'PROJECT_COSTING_GENERAL_SETTINGS_______ICON',
  ProjectCostingGeneralSettingsSubcontractTradeCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____CREATE',
  ProjectCostingGeneralSettingsSubcontractTradeView = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____VIEW',
  ProjectCostingGeneralSettingsSubcontractTradeUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPDATE',
  ProjectCostingGeneralSettingsSubcontractTradeDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DELETE',
  ProjectCostingGeneralSettingsSubcontractTradeDownload = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DOWNLOAD',
  ProjectCostingGeneralSettingsSubcontractTradeUpload = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPLOAD',
  ProjectCostingGeneralSettingsSubcontractTradeInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____INACTIVE',
  ProjectCostingGeneralSettingsSubcontractTradeActive = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____ACTIVE',
  ProjectCostingGeneralSettingsLabourCategoryView = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____VIEW',
  ProjectCostingGeneralSettingsLabourCategoryCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____CREATE',
  ProjectCostingGeneralSettingsLabourCategoryUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____UPDATE',
  ProjectCostingGeneralSettingsLabourCategoryDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____DELETE',
  ProjectCostingGeneralSettingsLabourCategoryDownload = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____DOWNLOAD',
  ProjectCostingGeneralSettingsLabourCategoryUpload = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____UPLOAD',
  ProjectCostingGeneralSettingsLabourCategoryInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____INACTIVE',
  ProjectCostingGeneralSettingsLabourCategoryActive = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____ACTIVE',
  ProjectCostingGeneralSettingsBuildingMaterialView = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____VIEW',
  ProjectCostingGeneralSettingsBuildingMaterialCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____CREATE',
  ProjectCostingGeneralSettingsBuildingMaterialUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____UPDATE',
  ProjectCostingGeneralSettingsBuildingMaterialDownload = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____DOWNLOAD',
  ProjectCostingGeneralSettingsBuildingMaterialUpload = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____UPLOAD',
  ProjectCostingGeneralSettingsBuildingMaterialDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____DELETE',
  ProjectCostingGeneralSettingsBuildingMaterialInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____INACTIVE',
  ProjectCostingGeneralSettingsBuildingMaterialActive = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____ACTIVE',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemView = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_VIEW',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_CREATE',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_UPDATE',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_DELETE',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_INACTIVE',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemActive = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_ACTIVE',
  ProjectCostingGeneralSettingsPAndMCategoryView = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____VIEW',
  ProjectCostingGeneralSettingsPAndMCategoryCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____CREATE',
  ProjectCostingGeneralSettingsPAndMCategoryUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____UPDATE',
  ProjectCostingGeneralSettingsPAndMCategoryDownload = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____DOWNLOAD',
  ProjectCostingGeneralSettingsPAndMCategoryUpload = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____UPLOAD',
  ProjectCostingGeneralSettingsPAndMCategoryDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____DELETE',
  ProjectCostingGeneralSettingsPAndMCategoryInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____INACTIVE',
  ProjectCostingGeneralSettingsPAndMCategoryActive = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____ACTIVE',
  ProjectCostingGeneralSettingsMiscellaneousExpensesView = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____VIEW',
  ProjectCostingGeneralSettingsMiscellaneousExpensesCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____CREATE',
  ProjectCostingGeneralSettingsMiscellaneousExpensesUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____UPDATE',
  ProjectCostingGeneralSettingsMiscellaneousExpensesDownload = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____DOWNLOAD',
  ProjectCostingGeneralSettingsMiscellaneousExpensesUpload = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____UPLOAD',
  ProjectCostingGeneralSettingsMiscellaneousExpensesDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____DELETE',
  ProjectCostingGeneralSettingsMiscellaneousExpensesInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____INACTIVE',
  ProjectCostingGeneralSettingsMiscellaneousExpensesActive = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____ACTIVE',
  PlantMachineryIcon = 'PLANT_MACHINERY__________ICON',
  PlantMachineryView = 'PLANT_MACHINERY__________VIEW',
  PlantMachineryOwnedPMView = 'PLANT_MACHINERY_OWNED_P_M_______VIEW',
  PlantMachineryOwnedPMCreate = 'PLANT_MACHINERY_OWNED_P_M_______CREATE',
  PlantMachineryOwnedPMUpdate = 'PLANT_MACHINERY_OWNED_P_M_______UPDATE',
  PlantMachineryOwnedPMAssignmentHistoryView = 'PLANT_MACHINERY_OWNED_P_M_ASSIGNMENT_HISTORY____VIEW',
  PlantMachineryOwnedPMMaintenanceHistoryView = 'PLANT_MACHINERY_OWNED_P_M_MAINTENANCE_HISTORY____VIEW',
  PlantMachineryRentedPMView = 'PLANT_MACHINERY_RENTED_P_M_______VIEW',
  PlantMachineryRentedPMCreate = 'PLANT_MACHINERY_RENTED_P_M_______CREATE',
  PlantMachineryRentedPMUpdate = 'PLANT_MACHINERY_RENTED_P_M_______UPDATE',
  PlantMachineryRentedPMReturn = 'PLANT_MACHINERY_RENTED_P_M_______RETURN',
  PlantMachineryAssignmentRecordsView = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______VIEW',
  PlantMachineryAssignmentRecordsCreate = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______CREATE',
  PlantMachineryAssignmentRecordsUpdate = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______UPDATE',
  PlantMachineryAssignmentRecordsReturn = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______RETURN',
  PlantMachineryAssignmentRecordsCancel = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______CANCEL',
  PlantMachineryAssignmentRecordsSendMaintenance = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______SEND_MAINTENANCE',
  PlantMachineryMaintanenceRecordsView = 'PLANT_MACHINERY_MAINTANENCE_RECORDS_______VIEW',
  PlantMachineryMaintanenceRecordsCreate = 'PLANT_MACHINERY_MAINTANENCE_RECORDS_______CREATE',
  PlantMachineryMaintanenceRecordsReturn = 'PLANT_MACHINERY_MAINTANENCE_RECORDS_______RETURN',
  PlantMachineryMaintanenceRecordsCancel = 'PLANT_MACHINERY_MAINTANENCE_RECORDS_______CANCEL',
  PlantMachineryGeneralSettingsIcon = 'PLANT_MACHINERY_GENERAL_SETTINGS_______ICON',
  PlantMachineryGeneralSettingsPMCategoryView = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____VIEW',
  PlantMachineryGeneralSettingsPMCategoryCreate = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____CREATE',
  PlantMachineryFuelConsumptionView = 'PLANT_MACHINERY_FUEL_CONSUMPTION_______VIEW',
  PlantMachineryFuelConsumptionFuelTankSetupView = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_TANK_SETUP____VIEW',
  PlantMachineryFuelConsumptionFuelTankSetupCreate = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_TANK_SETUP____CREATE',
  PlantMachineryFuelConsumptionFuelTankSetupUpdate = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_TANK_SETUP____UPDATE',
  PlantMachineryFuelConsumptionFuelTankSetupDelete = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_TANK_SETUP____DELETE',
  PlantMachineryFuelConsumptionFuelTankMeterReadingView = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_TANK_METER_READING____VIEW',
  PlantMachineryFuelConsumptionFuelTankMeterReadingCreate = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_TANK_METER_READING____CREATE',
  PlantMachineryFuelConsumptionFuelTankMeterReadingUpdate = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_TANK_METER_READING____UPDATE',
  PlantMachineryFuelConsumptionFuelTankMeterReadingDelete = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_TANK_METER_READING____DELETE',
  PlantMachineryFuelConsumptionFuelSupplyView = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_SUPPLY____VIEW',
  PlantMachineryFuelConsumptionFuelSupplyCreate = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_SUPPLY____CREATE',
  PlantMachineryFuelConsumptionFuelSupplyUpdate = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_SUPPLY____UPDATE',
  PlantMachineryFuelConsumptionFuelSupplyDelete = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_SUPPLY____DELETE',
  PlantMachineryFuelConsumptionFuelSupplyApprove = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_SUPPLY____APPROVE',
  PlantMachineryFuelConsumptionFuelSupplyReject = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_SUPPLY____REJECT',
  PlantMachineryFuelConsumptionFuelDischargeView = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_DISCHARGE____VIEW',
  PlantMachineryFuelConsumptionFuelDischargeCreate = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_DISCHARGE____CREATE',
  PlantMachineryFuelConsumptionFuelDischargeUpdate = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_DISCHARGE____UPDATE',
  PlantMachineryFuelConsumptionFuelDischargeDelete = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_DISCHARGE____DELETE',
  PlantMachineryFuelConsumptionFuelDischargeApprove = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_DISCHARGE____APPROVE',
  PlantMachineryFuelConsumptionFuelDischargeReject = 'PLANT_MACHINERY_FUEL_CONSUMPTION_FUEL_DISCHARGE____REJECT',
  PlantMachineryDigitalReportingView = 'PLANT_MACHINERY_DIGITAL_REPORTING____VIEW',
  PlantMachineryDigitalReportingFuelConsumptionDetailReportView = 'PLANT_MACHINERY_DIGITAL_REPORTING_FUEL_CONSUMPTION_DETAIL_REPORT____VIEW',
  PlantMachineryDigitalReportingFuelConsumptionSummaryReportView = 'PLANT_MACHINERY_DIGITAL_REPORTING_FUEL_CONSUMPTION_SUMMARY_REPORT____VIEW',
  PlantMachineryGeneralSettingsPMCategoryUpdate = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____UPDATE',
  PlantMachineryGeneralSettingsPMCategoryDownload = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____DOWNLOAD',
  PlantMachineryGeneralSettingsPMCategoryUpload = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____UPLOAD',
  PlantMachineryGeneralSettingsPMCategoryInactive = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____INACTIVE',
  PlantMachineryGeneralSettingsPMCategoryActive = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____ACTIVE',
  PlantMachineryGeneralSettingsPMCategoryDelete = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____DELETE',
  PlantMachineryGeneralSettingsWorkshopMaintenanceView = 'PLANT_MACHINERY_GENERAL_SETTINGS_WORKSHOP_MAINTENANCE____VIEW',
  PlantMachineryGeneralSettingsWorkshopMaintenanceCreate = 'PLANT_MACHINERY_GENERAL_SETTINGS_WORKSHOP_MAINTENANCE____CREATE',
  PlantMachineryGeneralSettingsWorkshopMaintenanceUpdate = 'PLANT_MACHINERY_GENERAL_SETTINGS_WORKSHOP_MAINTENANCE____UPDATE',
  GlInterfaceIcon = 'GL_INTERFACE__________ICON',
  GlInterfaceView = 'GL_INTERFACE_______VIEW',
  GlInterfaceGeneralLedgerExportView = 'GL_INTERFACE_GENERAL_LEDGER_EXPORT____VIEW',
  GlInterfaceGeneralLedgerExportCreate = 'GL_INTERFACE_GENERAL_LEDGER_EXPORT____CREATE',
  GlInterfaceGeneralLedgerExportExport = 'GL_INTERFACE_GENERAL_LEDGER_EXPORT____EXPORT',
  GlInterfaceCashBookView = 'GL_INTERFACE_CASH_BOOK____VIEW',
  GlInterfaceCashBookCreate = 'GL_INTERFACE_CASH_BOOK____CREATE',
  GlInterfaceCashBookExport = 'GL_INTERFACE_CASH_BOOK____EXPORT',
  GlInterfaceDigitalReportingView = 'GL_INTERFACE_DIGITAL_REPORTING_______VIEW',
  GlInterfaceDigitalReportingTaxListingReportView = 'GL_INTERFACE_DIGITAL_REPORTING_TAX_LISTING_REPORT____VIEW',
  GlInterfaceDigitalReportingGlTaxLedgerReportView = 'GL_INTERFACE_DIGITAL_REPORTING_GL_TAX_LEDGER_REPORT____VIEW',
  GlInterfaceDigitalReportingFiTaxLedgerReportView = 'GL_INTERFACE_DIGITAL_REPORTING_FI_TAX_LEDGER_REPORT____VIEW',
  GlInterfaceDigitalReportingGstSummaryReportView = 'GL_INTERFACE_DIGITAL_REPORTING_GST_SUMMARY_REPORT____VIEW',
  GlInterfaceGeneralSettingsIcon = 'GL_INTERFACE_GENERAL_SETTINGS_______ICON',
  AccessSecurityIcon = 'ACCESS_SECURITY__________ICON',
  AccessSecurityView = 'ACCESS_SECURITY__________VIEW',
  AccessSecurityUserView = 'ACCESS_SECURITY_USER_______VIEW',
  AccessSecurityUserCreate = 'ACCESS_SECURITY_USER_______CREATE',
  AccessSecurityUserUpdate = 'ACCESS_SECURITY_USER_______UPDATE',
  AccessSecurityUserChangePassword = 'ACCESS_SECURITY_USER_______CHANGE_PASSWORD',
  AccessSecurityUserUnblock = 'ACCESS_SECURITY_USER_______UNBLOCK',
  AccessSecurityUserAssign = 'ACCESS_SECURITY_USER_______ASSIGN',
  AccessSecurityRolesView = 'ACCESS_SECURITY_ROLES_______VIEW',
  AccessSecurityRolesCreate = 'ACCESS_SECURITY_ROLES_______CREATE',
  AccessSecurityRolesUpdate = 'ACCESS_SECURITY_ROLES_______UPDATE',
  AccessSecurityRolesDelete = 'ACCESS_SECURITY_ROLES_______DELETE',
  AccessSecurityDigitalReportView = 'ACCESS_SECURITY_DIGITAL_REPORT_______VIEW',
  AccessSecurityProjectRoleAuthView = 'ACCESS_SECURITY_PROJECT_ROLE_AUTH____VIEW',
  AccessSecurityProjectRoleAuthCreate = 'ACCESS_SECURITY_PROJECT_ROLE_AUTH____CREATE',
  AccessSecurityProjectRoleAuthUpdate = 'ACCESS_SECURITY_PROJECT_ROLE_AUTH____UPDATE',
  CommonSettingsIcon = 'COMMON_SETTINGS__________ICON',
  CommonSettingsCurrencySetupView = 'COMMON_SETTINGS_CURRENCY_SETUP_______VIEW',
  CommonSettingsCurrencySetupCreate = 'COMMON_SETTINGS_CURRENCY_SETUP_______CREATE',
  CommonSettingsCurrencySetupUpdate = 'COMMON_SETTINGS_CURRENCY_SETUP_______UPDATE',
  CommonSettingsCurrencySetupDelete = 'COMMON_SETTINGS_CURRENCY_SETUP_______DELETE',
  CommonSettingsTaxSetupView = 'COMMON_SETTINGS_TAX_SETUP_______VIEW',
  CommonSettingsTaxSetupCreate = 'COMMON_SETTINGS_TAX_SETUP_______CREATE',
  CommonSettingsTaxSetupUpdate = 'COMMON_SETTINGS_TAX_SETUP_______UPDATE',
  CommonSettingsTaxSetupDelete = 'COMMON_SETTINGS_TAX_SETUP_______DELETE',
  CommonSettingsTaxSetupInactive = 'COMMON_SETTINGS_TAX_SETUP_______INACTIVE',
  CommonSettingsTaxSetupActive = 'COMMON_SETTINGS_TAX_SETUP_______ACTIVE',
  CommonSettingsTaxSetupViewHistory = 'COMMON_SETTINGS_TAX_SETUP_______VIEW_HISTORY',
  CommonSettingsUomSetupView = 'COMMON_SETTINGS_UOM_SETUP_______VIEW',
  CommonSettingsUomSetupCreate = 'COMMON_SETTINGS_UOM_SETUP_______CREATE',
  CommonSettingsUomSetupUpdate = 'COMMON_SETTINGS_UOM_SETUP_______UPDATE',
  CommonSettingsUomSetupDelete = 'COMMON_SETTINGS_UOM_SETUP_______DELETE',
  CommonSettingsUomSetupInactive = 'COMMON_SETTINGS_UOM_SETUP_______INACTIVE',
  CommonSettingsUomSetupActive = 'COMMON_SETTINGS_UOM_SETUP_______ACTIVE',
  CommonSettingsUomSetupDownload = 'COMMON_SETTINGS_UOM_SETUP_______DOWNLOAD',
  CommonSettingsUomSetupUpload = 'COMMON_SETTINGS_UOM_SETUP_______UPLOAD',
  CommonSettingsUomConversionSetupView = 'COMMON_SETTINGS_UOM_CONVERSION_SETUP_______VIEW',
  CommonSettingsUomConversionSetupUpdate = 'COMMON_SETTINGS_UOM_CONVERSION_SETUP_______UPDATE',
  CommonSettingsUomConversionSetupCreate = 'COMMON_SETTINGS_UOM_CONVERSION_SETUP_______CREATE',
  CommonSettingsUomConversionSetupDelete = 'COMMON_SETTINGS_UOM_CONVERSION_SETUP_______DELETE',
  CommonSettingsAlertAssignmentView = 'COMMON_SETTINGS_ALERT_ASSIGNMENT_______VIEW',
  CommonSettingsAlertAssignmentUpdate = 'COMMON_SETTINGS_ALERT_ASSIGNMENT_______UPDATE',
  CompanySetupIcon = 'COMPANY_SETUP__________ICON',
  CompanySetupView = 'COMPANY_SETUP__________VIEW',
  CompanySetupCreate = 'COMPANY_SETUP__________CREATE',
  CompanySetupCompanyProfileView = 'COMPANY_SETUP_COMPANY_PROFILE_______VIEW',
  CompanySetupCompanyProfileUpdate = 'COMPANY_SETUP_COMPANY_PROFILE_______UPDATE',
  CompanySetupDocumentNumberingSetupView = 'COMPANY_SETUP_DOCUMENT_NUMBERING_SETUP_______VIEW',
  CompanySetupDocumentNumberingSetupAccountReceivableView = 'COMPANY_SETUP_DOCUMENT_NUMBERING_SETUP_ACCOUNT_RECEIVABLE____VIEW',
  CompanySetupDocumentNumberingSetupAccountPayableView = 'COMPANY_SETUP_DOCUMENT_NUMBERING_SETUP_ACCOUNT_PAYABLE____VIEW',
  CompanySetupDocumentNumberingSetupProjectPurchaseView = 'COMPANY_SETUP_DOCUMENT_NUMBERING_SETUP_PROJECT_PURCHASE____VIEW',
  CompanySetupDocumentNumberingSetupSiteManagementView = 'COMPANY_SETUP_DOCUMENT_NUMBERING_SETUP_SITE_MANAGEMENT____VIEW',
  CompanySetupBankAccountView = 'COMPANY_SETUP_BANK_ACCOUNT_______VIEW',
  CompanySetupBankAccountCreate = 'COMPANY_SETUP_BANK_ACCOUNT_______CREATE',
  CompanySetupBankAccountUpdate = 'COMPANY_SETUP_BANK_ACCOUNT_______UPDATE',
  CompanySetupBankAccountActive = 'COMPANY_SETUP_BANK_ACCOUNT_______ACTIVE',
  CompanySetupBankAccountInactive = 'COMPANY_SETUP_BANK_ACCOUNT_______INACTIVE',
  CompanySetupBankAccountDelete = 'COMPANY_SETUP_BANK_ACCOUNT_______DELETE',
  CompanySetupWatermarkLogoSetupUpdate = 'COMPANY_SETUP_WATERMARK_LOGO_SETUP_______UPDATE',
  CompanySetupCompanyAssignmentView = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_______VIEW',
  CompanySetupCompanyAssignmentUserAssignmentView = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_USER_ASSIGNMENT____VIEW',
  CompanySetupCompanyAssignmentUserAssignmentAssign = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_USER_ASSIGNMENT____ASSIGN',
  CompanySetupCompanyAssignmentUserAssignmentUnassign = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_USER_ASSIGNMENT____UNASSIGN',
  CompanySetupCompanyAssignmentSupplierAssignmentView = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_SUPPLIER_ASSIGNMENT____VIEW',
  CompanySetupCompanyAssignmentSupplierAssignmentAssign = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_SUPPLIER_ASSIGNMENT____ASSIGN',
  CompanySetupCompanyAssignmentSupplierAssignmentUnassign = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_SUPPLIER_ASSIGNMENT____UNASSIGN',
  CompanySetupCompanyAssignmentClientAssignmentView = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_CLIENT_ASSIGNMENT____VIEW',
  CompanySetupCompanyAssignmentClientAssignmentAssign = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_CLIENT_ASSIGNMENT____ASSIGN',
  CompanySetupCompanyAssignmentClientAssignmentUnassign = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_CLIENT_ASSIGNMENT____UNASSIGN',
  CompanySetupCompanyAssignmentSubcontractorAssignmentView = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_SUBCONTRACTOR_ASSIGNMENT____VIEW',
  CompanySetupCompanyAssignmentSubcontractorAssignmentAssign = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_SUBCONTRACTOR_ASSIGNMENT____ASSIGN',
  CompanySetupCompanyAssignmentSubcontractorAssignmentUnassign = 'COMPANY_SETUP_COMPANY_ASSIGNMENT_SUBCONTRACTOR_ASSIGNMENT____UNASSIGN',
  GeneralSettingsIcon = 'GENERAL_SETTINGS__________ICON',
  GeneralSettingsSubcontractTradeView = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE_______VIEW',
  GeneralSettingsSubcontractTradeCreate = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE_______CREATE',
  GeneralSettingsSubcontractTradeUpdate = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE_______UPDATE',
  GeneralSettingsSubcontractTradeActive = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE_______ACTIVE',
  GeneralSettingsSubcontractTradeInactive = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE_______INACTIVE',
  GeneralSettingsSubcontractTradeDelete = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE_______DELETE',
  GeneralSettingsSubcontractTradeUpload = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE_______UPLOAD',
  GeneralSettingsSubcontractTradeDownload = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE_______DOWNLOAD',
  GeneralSettingsCostCategoryView = 'GENERAL_SETTINGS_COST_CATEGORY_______VIEW',
  GeneralSettingsCostCategoryCreate = 'GENERAL_SETTINGS_COST_CATEGORY_______CREATE',
  GeneralSettingsCostCategoryUpdate = 'GENERAL_SETTINGS_COST_CATEGORY_______UPDATE',
  GeneralSettingsCostCategoryActive = 'GENERAL_SETTINGS_COST_CATEGORY_______ACTIVE',
  GeneralSettingsCostCategoryInactive = 'GENERAL_SETTINGS_COST_CATEGORY_______INACTIVE',
  GeneralSettingsCostCategoryDelete = 'GENERAL_SETTINGS_COST_CATEGORY_______DELETE',
  GeneralSettingsCostCategoryDownload = 'GENERAL_SETTINGS_COST_CATEGORY_______DOWNLOAD',
  GeneralSettingsCostCategoryUpload = 'GENERAL_SETTINGS_COST_CATEGORY_______UPLOAD',
  GeneralSettingsLabourCategoryView = 'GENERAL_SETTINGS_LABOUR_CATEGORY_______VIEW',
  GeneralSettingsLabourCategoryCreate = 'GENERAL_SETTINGS_LABOUR_CATEGORY_______CREATE',
  GeneralSettingsLabourCategoryUpdate = 'GENERAL_SETTINGS_LABOUR_CATEGORY_______UPDATE',
  GeneralSettingsLabourCategoryActive = 'GENERAL_SETTINGS_LABOUR_CATEGORY_______ACTIVE',
  GeneralSettingsLabourCategoryInactive = 'GENERAL_SETTINGS_LABOUR_CATEGORY_______INACTIVE',
  GeneralSettingsLabourCategoryDelete = 'GENERAL_SETTINGS_LABOUR_CATEGORY_______DELETE',
  GeneralSettingsLabourCategoryUpload = 'GENERAL_SETTINGS_LABOUR_CATEGORY_______UPLOAD',
  GeneralSettingsLabourCategoryDownload = 'GENERAL_SETTINGS_LABOUR_CATEGORY_______DOWNLOAD',
  GeneralSettingsBuildingMaterialView = 'GENERAL_SETTINGS_BUILDING_MATERIAL_______VIEW',
  GeneralSettingsBuildingMaterialCreate = 'GENERAL_SETTINGS_BUILDING_MATERIAL_______CREATE',
  GeneralSettingsBuildingMaterialUpdate = 'GENERAL_SETTINGS_BUILDING_MATERIAL_______UPDATE',
  GeneralSettingsBuildingMaterialActive = 'GENERAL_SETTINGS_BUILDING_MATERIAL_______ACTIVE',
  GeneralSettingsBuildingMaterialInactive = 'GENERAL_SETTINGS_BUILDING_MATERIAL_______INACTIVE',
  GeneralSettingsBuildingMaterialDelete = 'GENERAL_SETTINGS_BUILDING_MATERIAL_______DELETE',
  GeneralSettingsBuildingMaterialUpload = 'GENERAL_SETTINGS_BUILDING_MATERIAL_______UPLOAD',
  GeneralSettingsBuildingMaterialDownload = 'GENERAL_SETTINGS_BUILDING_MATERIAL_______DOWNLOAD',
  GeneralSettingsMiscellaneousExpenseView = 'GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______VIEW',
  GeneralSettingsMiscellaneousExpenseCreate = 'GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______CREATE',
  GeneralSettingsMiscellaneousExpenseUpdate = 'GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______UPDATE',
  GeneralSettingsMiscellaneousExpenseActive = 'GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______ACTIVE',
  GeneralSettingsMiscellaneousExpenseInactive = 'GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______INACTIVE',
  GeneralSettingsMiscellaneousExpenseDelete = 'GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______DELETE',
  GeneralSettingsMiscellaneousExpenseUpload = 'GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______UPLOAD',
  GeneralSettingsMiscellaneousExpenseDownload = 'GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______DOWNLOAD',
  GeneralSettingsGuaranteeTypeView = 'GENERAL_SETTINGS_GUARANTEE_TYPE_______VIEW',
  GeneralSettingsGuaranteeTypeCreate = 'GENERAL_SETTINGS_GUARANTEE_TYPE_______CREATE',
  GeneralSettingsGuaranteeTypeUpdate = 'GENERAL_SETTINGS_GUARANTEE_TYPE_______UPDATE',
  GeneralSettingsGuaranteeTypeInactive = 'GENERAL_SETTINGS_GUARANTEE_TYPE_______INACTIVE',
  GeneralSettingsGuaranteeTypeDelete = 'GENERAL_SETTINGS_GUARANTEE_TYPE_______DELETE',
  GeneralSettingsSupplierTypeView = 'GENERAL_SETTINGS_SUPPLIER_TYPE_______VIEW',
  GeneralSettingsSupplierTypeCreate = 'GENERAL_SETTINGS_SUPPLIER_TYPE_______CREATE',
  GeneralSettingsSupplierTypeUpdate = 'GENERAL_SETTINGS_SUPPLIER_TYPE_______UPDATE',
  GeneralSettingsSupplierTypeDelete = 'GENERAL_SETTINGS_SUPPLIER_TYPE_______DELETE',
  GeneralSettingsSupplierTypeActive = 'GENERAL_SETTINGS_SUPPLIER_TYPE_______ACTIVE',
  GeneralSettingsSupplierTypeInactive = 'GENERAL_SETTINGS_SUPPLIER_TYPE_______INACTIVE',
  GeneralSettingsSubcontractorTypeView = 'GENERAL_SETTINGS_SUBCONTRACTOR_TYPE_______VIEW',
  GeneralSettingsSubcontractorTypeCreate = 'GENERAL_SETTINGS_SUBCONTRACTOR_TYPE_______CREATE',
  GeneralSettingsSubcontractorTypeUpdate = 'GENERAL_SETTINGS_SUBCONTRACTOR_TYPE_______UPDATE',
  GeneralSettingsSubcontractorTypeDelete = 'GENERAL_SETTINGS_SUBCONTRACTOR_TYPE_______DELETE',
  GeneralSettingsSubcontractorTypeActive = 'GENERAL_SETTINGS_SUBCONTRACTOR_TYPE_______ACTIVE',
  GeneralSettingsSubcontractorTypeInactive = 'GENERAL_SETTINGS_SUBCONTRACTOR_TYPE_______INACTIVE',
  GeneralSettingsInsuranceTypeView = 'GENERAL_SETTINGS_INSURANCE_TYPE_______VIEW',
  GeneralSettingsInsuranceTypeCreate = 'GENERAL_SETTINGS_INSURANCE_TYPE_______CREATE',
  GeneralSettingsInsuranceTypeUpdate = 'GENERAL_SETTINGS_INSURANCE_TYPE_______UPDATE',
  GeneralSettingsInsuranceTypeInactive = 'GENERAL_SETTINGS_INSURANCE_TYPE_______INACTIVE',
  GeneralSettingsInsuranceTypeDelete = 'GENERAL_SETTINGS_INSURANCE_TYPE_______DELETE',
  GeneralSettingsWorkingHoursPolicyView = 'GENERAL_SETTINGS_WORKING_HOURS_POLICY____VIEW',
  GeneralSettingsWorkingHoursPolicyUpdate = 'GENERAL_SETTINGS_WORKING_HOURS_POLICY____UPDATE',
  GeneralSettingsWagesRatePolicyCreate = 'GENERAL_SETTINGS_WAGES_RATE_POLICY____CREATE',
  GeneralSettingsWagesRatePolicyView = 'GENERAL_SETTINGS_WAGES_RATE_POLICY____VIEW',
  GeneralSettingsWagesRatePolicyUpdate = 'GENERAL_SETTINGS_WAGES_RATE_POLICY____UPDATE',
  GeneralSettingsWorkerProfileCreate = 'GENERAL_SETTINGS_WORKER_PROFILE____CREATE',
  GeneralSettingsWorkerProfileView = 'GENERAL_SETTINGS_WORKER_PROFILE____VIEW',
  GeneralSettingsWorkerProfileUpdate = 'GENERAL_SETTINGS_WORKER_PROFILE____UPDATE',
  GeneralSettingsPmCategoryView = 'GENERAL_SETTINGS_PM_CATEGORY_______VIEW',
  GeneralSettingsPmCategoryCreate = 'GENERAL_SETTINGS_PM_CATEGORY_______CREATE',
  GeneralSettingsPmCategoryUpdate = 'GENERAL_SETTINGS_PM_CATEGORY_______UPDATE',
  GeneralSettingsPmCategoryActive = 'GENERAL_SETTINGS_PM_CATEGORY_______ACTIVE',
  GeneralSettingsPmCategoryInactive = 'GENERAL_SETTINGS_PM_CATEGORY_______INACTIVE',
  GeneralSettingsPmCategoryDelete = 'GENERAL_SETTINGS_PM_CATEGORY_______DELETE',
  GeneralSettingsPmCategoryUpload = 'GENERAL_SETTINGS_PM_CATEGORY_______UPLOAD',
  GeneralSettingsPmCategoryDownload = 'GENERAL_SETTINGS_PM_CATEGORY_______DOWNLOAD',
  GeneralSettingsWorkshopMaintenanceView = 'GENERAL_SETTINGS_WORKSHOP_MAINTENANCE_______VIEW',
  GeneralSettingsWorkshopMaintenanceCreate = 'GENERAL_SETTINGS_WORKSHOP_MAINTENANCE_______CREATE',
  GeneralSettingsWorkshopMaintenanceUpdate = 'GENERAL_SETTINGS_WORKSHOP_MAINTENANCE_______UPDATE',
  GeneralSettingsClientAccountSetupView = 'GENERAL_SETTINGS_CLIENT_ACCOUNT_SETUP_______VIEW',
  GeneralSettingsClientAccountSetupCreate = 'GENERAL_SETTINGS_CLIENT_ACCOUNT_SETUP_______CREATE',
  GeneralSettingsClientAccountSetupUpdate = 'GENERAL_SETTINGS_CLIENT_ACCOUNT_SETUP_______UPDATE',
  GeneralSettingsClientAccountSetupAssign = 'GENERAL_SETTINGS_CLIENT_ACCOUNT_SETUP_______ASSIGN',
  GeneralSettingsClientAccountSetupDownload = 'GENERAL_SETTINGS_CLIENT_ACCOUNT_SETUP_______DOWNLOAD',
  GeneralSettingsClientAccountSetupUpload = 'GENERAL_SETTINGS_CLIENT_ACCOUNT_SETUP_______UPLOAD',
  GeneralSettingsSubcontractorAccountSetupView = 'GENERAL_SETTINGS_SUBCONTRACTOR_ACCOUNT_SETUP_______VIEW',
  GeneralSettingsSubcontractorAccountSetupCreate = 'GENERAL_SETTINGS_SUBCONTRACTOR_ACCOUNT_SETUP_______CREATE',
  GeneralSettingsSubcontractorAccountSetupUpdate = 'GENERAL_SETTINGS_SUBCONTRACTOR_ACCOUNT_SETUP_______UPDATE',
  GeneralSettingsSubcontractorAccountSetupAssign = 'GENERAL_SETTINGS_SUBCONTRACTOR_ACCOUNT_SETUP_______ASSIGN',
  GeneralSettingsSubcontractorAccountSetupDownload = 'GENERAL_SETTINGS_SUBCONTRACTOR_ACCOUNT_SETUP_______DOWNLOAD',
  GeneralSettingsSubcontractorAccountSetupUpload = 'GENERAL_SETTINGS_SUBCONTRACTOR_ACCOUNT_SETUP_______UPLOAD',
  GeneralSettingsSupplierAccountSetupView = 'GENERAL_SETTINGS_SUPPLIER_ACCOUNT_SETUP_______VIEW',
  GeneralSettingsSupplierAccountSetupCreate = 'GENERAL_SETTINGS_SUPPLIER_ACCOUNT_SETUP_______CREATE',
  GeneralSettingsSupplierAccountSetupUpdate = 'GENERAL_SETTINGS_SUPPLIER_ACCOUNT_SETUP_______UPDATE',
  GeneralSettingsSupplierAccountSetupAssign = 'GENERAL_SETTINGS_SUPPLIER_ACCOUNT_SETUP_______ASSIGN',
  GeneralSettingsSupplierAccountSetupDownload = 'GENERAL_SETTINGS_SUPPLIER_ACCOUNT_SETUP_______DOWNLOAD',
  GeneralSettingsSupplierAccountSetupUpload = 'GENERAL_SETTINGS_SUPPLIER_ACCOUNT_SETUP_______UPLOAD',
  GeneralSettingsPurchaseAccountSetupView = 'GENERAL_SETTINGS_PURCHASE_ACCOUNT_SETUP_______VIEW',
  GeneralSettingsPurchaseAccountSetupUpdate = 'GENERAL_SETTINGS_PURCHASE_ACCOUNT_SETUP_______UPDATE',
  GeneralSettingsPobAccountSetupView = 'GENERAL_SETTINGS_POB_ACCOUNT_SETUP_______VIEW',
  GeneralSettingsPobAccountSetupUpdate = 'GENERAL_SETTINGS_POB_ACCOUNT_SETUP_______UPDATE',
  GeneralSettingsBankAccountSetupView = 'GENERAL_SETTINGS_BANK_ACCOUNT_SETUP_______VIEW',
  GeneralSettingsBankAccountSetupUpdate = 'GENERAL_SETTINGS_BANK_ACCOUNT_SETUP_______UPDATE',
  GeneralSettingsTaxAccountSetupView = 'GENERAL_SETTINGS_TAX_ACCOUNT_SETUP_______VIEW',
  GeneralSettingsTaxAccountSetupUpdate = 'GENERAL_SETTINGS_TAX_ACCOUNT_SETUP_______UPDATE',
  GeneralSettingsTradeAccountSetupView = 'GENERAL_SETTINGS_TRADE_ACCOUNT_SETUP_______VIEW',
  GeneralSettingsTradeAccountSetupUpdate = 'GENERAL_SETTINGS_TRADE_ACCOUNT_SETUP_______UPDATE',
  GeneralSettingsMaterialCategorySetupView = 'GENERAL_SETTINGS_MATERIAL_CATEGORY_SETUP_______VIEW',
  GeneralSettingsMaterialCategorySetupUpdate = 'GENERAL_SETTINGS_MATERIAL_CATEGORY_SETUP_______UPDATE',
  GeneralSettingsPmCategorySetupView = 'GENERAL_SETTINGS_PM_CATEGORY_SETUP_______VIEW',
  GeneralSettingsPmCategorySetupUpdate = 'GENERAL_SETTINGS_PM_CATEGORY_SETUP_______UPDATE',
  GeneralSettingsLabourCategorySetupView = 'GENERAL_SETTINGS_LABOUR_CATEGORY_SETUP_______VIEW',
  GeneralSettingsLabourCategorySetupUpdate = 'GENERAL_SETTINGS_LABOUR_CATEGORY_SETUP_______UPDATE',
  GeneralSettingsMiscCategorySetupView = 'GENERAL_SETTINGS_MISC_CATEGORY_SETUP_______VIEW',
  GeneralSettingsMiscCategorySetupUpdate = 'GENERAL_SETTINGS_MISC_CATEGORY_SETUP_______UPDATE',
  GeneralSettingsJournalTypeSetupView = 'GENERAL_SETTINGS_JOURNAL_TYPE_SETUP_______VIEW',
  GeneralSettingsJournalTypeSetupUpdate = 'GENERAL_SETTINGS_JOURNAL_TYPE_SETUP_______UPDATE',
  GeneralSettingsGlAccountCodeSetupView = 'GENERAL_SETTINGS_GL_ACCOUNT_CODE_SETUP_______VIEW',
  GeneralSettingsGlAccountCodeSetupCreate = 'GENERAL_SETTINGS_GL_ACCOUNT_CODE_SETUP_______CREATE',
  GeneralSettingsStandardApprovalPolicyView = 'GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY_______VIEW',
  GeneralSettingsStandardApprovalPolicyUpdate = 'GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY____UPDATE',
  GeneralSettingsStandardApprovalPolicyCreate = 'GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY____CREATE',
  GeneralSettingsRevenueCategoryView = 'GENERAL_SETTINGS_REVENUE_CATEGORY_______VIEW',
  GeneralSettingsRevenueCategoryCreate = 'GENERAL_SETTINGS_REVENUE_CATEGORY_______CREATE',
  GeneralSettingsRevenueCategoryUpdate = 'GENERAL_SETTINGS_REVENUE_CATEGORY_______UPDATE',
  GeneralSettingsRevenueCategoryActive = 'GENERAL_SETTINGS_REVENUE_CATEGORY_______ACTIVE',
  GeneralSettingsRevenueCategoryInactive = 'GENERAL_SETTINGS_REVENUE_CATEGORY_______INACTIVE',
  GeneralSettingsRevenueCategoryDelete = 'GENERAL_SETTINGS_REVENUE_CATEGORY_______DELETE',
  GeneralSettingsRevenueCategoryDownload = 'GENERAL_SETTINGS_REVENUE_CATEGORY_______DOWNLOAD',
  GeneralSettingsRevenueCategoryUpload = 'GENERAL_SETTINGS_REVENUE_CATEGORY_______UPLOAD',
  GeneralSettingsLabourItemsView = 'GENERAL_SETTINGS_LABOUR_ITEMS_______VIEW',
  GeneralSettingsLabourItemsCreate = 'GENERAL_SETTINGS_LABOUR_ITEMS_______CREATE',
  GeneralSettingsLabourItemsUpdate = 'GENERAL_SETTINGS_LABOUR_ITEMS_______UPDATE',
  GeneralSettingsLabourItemsActive = 'GENERAL_SETTINGS_LABOUR_ITEMS_______ACTIVE',
  GeneralSettingsLabourItemsInactive = 'GENERAL_SETTINGS_LABOUR_ITEMS_______INACTIVE',
  GeneralSettingsLabourItemsDelete = 'GENERAL_SETTINGS_LABOUR_ITEMS_______DELETE',
  GeneralSettingsLabourItemsDownload = 'GENERAL_SETTINGS_LABOUR_ITEMS_______DOWNLOAD',
  GeneralSettingsLabourItemsUpload = 'GENERAL_SETTINGS_LABOUR_ITEMS_______UPLOAD',
  GeneralSettingsMaterialItemsView = 'GENERAL_SETTINGS_MATERIAL_ITEMS_______VIEW',
  GeneralSettingsMaterialItemsCreate = 'GENERAL_SETTINGS_MATERIAL_ITEMS_______CREATE',
  GeneralSettingsMaterialItemsUpdate = 'GENERAL_SETTINGS_MATERIAL_ITEMS_______UPDATE',
  GeneralSettingsMaterialItemsActive = 'GENERAL_SETTINGS_MATERIAL_ITEMS_______ACTIVE',
  GeneralSettingsMaterialItemsInactive = 'GENERAL_SETTINGS_MATERIAL_ITEMS_______INACTIVE',
  GeneralSettingsMaterialItemsDelete = 'GENERAL_SETTINGS_MATERIAL_ITEMS_______DELETE',
  GeneralSettingsMaterialItemsDownload = 'GENERAL_SETTINGS_MATERIAL_ITEMS_______DOWNLOAD',
  GeneralSettingsMaterialItemsUpload = 'GENERAL_SETTINGS_MATERIAL_ITEMS_______UPLOAD',
  GeneralSettingsPmItemsView = 'GENERAL_SETTINGS_PM_ITEMS_______VIEW',
  GeneralSettingsPmItemsCreate = 'GENERAL_SETTINGS_PM_ITEMS_______CREATE',
  GeneralSettingsPmItemsUpdate = 'GENERAL_SETTINGS_PM_ITEMS_______UPDATE',
  GeneralSettingsPmItemsActive = 'GENERAL_SETTINGS_PM_ITEMS_______ACTIVE',
  GeneralSettingsPmItemsInactive = 'GENERAL_SETTINGS_PM_ITEMS_______INACTIVE',
  GeneralSettingsPmItemsDelete = 'GENERAL_SETTINGS_PM_ITEMS_______DELETE',
  GeneralSettingsPmItemsDownload = 'GENERAL_SETTINGS_PM_ITEMS_______DOWNLOAD',
  GeneralSettingsPmItemsUpload = 'GENERAL_SETTINGS_PM_ITEMS_______UPLOAD',
  GeneralSettingsMiscExpenseItemsView = 'GENERAL_SETTINGS_MISC_EXPENSE_ITEMS_______VIEW',
  GeneralSettingsMiscExpenseItemsCreate = 'GENERAL_SETTINGS_MISC_EXPENSE_ITEMS_______CREATE',
  GeneralSettingsMiscExpenseItemsUpdate = 'GENERAL_SETTINGS_MISC_EXPENSE_ITEMS_______UPDATE',
  GeneralSettingsMiscExpenseItemsActive = 'GENERAL_SETTINGS_MISC_EXPENSE_ITEMS_______ACTIVE',
  GeneralSettingsMiscExpenseItemsInactive = 'GENERAL_SETTINGS_MISC_EXPENSE_ITEMS_______INACTIVE',
  GeneralSettingsMiscExpenseItemsDelete = 'GENERAL_SETTINGS_MISC_EXPENSE_ITEMS_______DELETE',
  GeneralSettingsMiscExpenseItemsDownload = 'GENERAL_SETTINGS_MISC_EXPENSE_ITEMS_______DOWNLOAD',
  GeneralSettingsMiscExpenseItemsUpload = 'GENERAL_SETTINGS_MISC_EXPENSE_ITEMS_______UPLOAD',
  GeneralSettingsPaymentReminderSettingView = 'GENERAL_SETTINGS_PAYMENT_REMINDER_SETTING_______VIEW',
  GeneralSettingsSubcontractTradeView = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____VIEW',
  GeneralSettingsSubcontractTradeCreate = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____CREATE',
  GeneralSettingsSubcontractTradeUpdate = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPDATE',
  GeneralSettingsSubcontractTradeInactive = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____INACTIVE',
  GeneralSettingsSubcontractTradeActive = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____ACTIVE',
  GeneralSettingsSubcontractTradeDelete = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____DELETE',
  GeneralSettingsSubcontractTradeDownload = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____DOWNLOAD',
  GeneralSettingsSubcontractTradeUpload = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPLOAD',
  GeneralSettingsPublicHolidayView = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____VIEW',
  GeneralSettingsPublicHolidayCreate = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____CREATE',
  GeneralSettingsPublicHolidayUpdate = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____UPDATE',
  GeneralSettingsPublicHolidayInactive = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____INACTIVE',
  GeneralSettingsPublicHolidayActive = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____ACTIVE',
  GeneralSettingsPublicHolidayDelete = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____DELETE',
  GeneralSettingsPublicHolidayCancel = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____CANCEL',
  GeneralSettingsCalendarPolicyView = 'GENERAL_SETTINGS_CALENDAR_POLICY____VIEW',
  GeneralSettingsCalendarPolicyCreate = 'GENERAL_SETTINGS_CALENDAR_POLICY____CREATE',
  GeneralSettingsCalendarPolicyUpdate = 'GENERAL_SETTINGS_CALENDAR_POLICY____UPDATE',
  GeneralSettingsJobTypeView = 'GENERAL_SETTINGS_JOB_TYPE____VIEW',
  GeneralSettingsJobTypeCreate = 'GENERAL_SETTINGS_JOB_TYPE____CREATE',
  GeneralSettingsJobTypeUpdate = 'GENERAL_SETTINGS_JOB_TYPE____UPDATE',
  GeneralSettingsJobTypeInactive = 'GENERAL_SETTINGS_JOB_TYPE____INACTIVE',
  GeneralSettingsJobTypeActive = 'GENERAL_SETTINGS_JOB_TYPE____ACTIVE',
  GeneralSettingsJobTypeCancel = 'GENERAL_SETTINGS_JOB_TYPE____CANCEL',
  GeneralSettingsJobTypeDelete = 'GENERAL_SETTINGS_JOB_TYPE____DELETE',
  GeneralSettingsBuildingMaterialView = 'GENERAL_SETTINGS_BUILDING_MATERIAL____VIEW',
  GeneralSettingsBuildingMaterialCreate = 'GENERAL_SETTINGS_BUILDING_MATERIAL____CREATE',
  GeneralSettingsBuildingMaterialUpdate = 'GENERAL_SETTINGS_BUILDING_MATERIAL____UPDATE',
  GeneralSettingsBuildingMaterialInactive = 'GENERAL_SETTINGS_BUILDING_MATERIAL____INACTIVE',
  GeneralSettingsBuildingMaterialActive = 'GENERAL_SETTINGS_BUILDING_MATERIAL____ACTIVE',
  GeneralSettingsBuildingMaterialDelete = 'GENERAL_SETTINGS_BUILDING_MATERIAL____DELETE',
  GeneralSettingsBuildingMaterialDownload = 'GENERAL_SETTINGS_BUILDING_MATERIAL____DOWNLOAD',
  GeneralSettingsBuildingMaterialUpload = 'GENERAL_SETTINGS_BUILDING_MATERIAL____UPLOAD',
  GeneralSettingsSiteBuildingMaterialView = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____VIEW',
  GeneralSettingsSiteBuildingMaterialCreate = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____CREATE',
  GeneralSettingsSiteBuildingMaterialUpdate = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____UPDATE',
  GeneralSettingsSiteBuildingMaterialInactive = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____INACTIVE',
  GeneralSettingsSiteBuildingMaterialActive = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____ACTIVE',
  GeneralSettingsSiteBuildingMaterialDelete = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____DELETE',
  GeneralSettingsSiteBuildingMaterialDownload = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____DOWNLOAD',
  GeneralSettingsSiteBuildingMaterialUpload = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____UPLOAD',
  GeneralSettingsSiteMaterialPolicyView = 'GENERAL_SETTINGS_SITE_MATERIAL_POLICY____VIEW',
  GeneralSettingsSiteMaterialPolicyUpdate = 'GENERAL_SETTINGS_SITE_MATERIAL_POLICY____UPDATE',
  GeneralSettingsVideoCamSetupView = 'GENERAL_SETTINGS_VIDEO_CAM_SETUP____VIEW',
  GeneralSettingsVideoCamSetupCreate = 'GENERAL_SETTINGS_VIDEO_CAM_SETUP____CREATE',
  GeneralSettingsVideoCamSetupUpdate = 'GENERAL_SETTINGS_VIDEO_CAM_SETUP____UPDATE',
  GeneralSettingsVideoCamSetupDelete = 'GENERAL_SETTINGS_VIDEO_CAM_SETUP____DELETE',
  SystemAdminIcon = 'SYSTEM_ADMIN__________ICON',
  SystemAdminView = 'SYSTEM_ADMIN__________VIEW',
  SystemAdminProjectSiteView = 'SYSTEM_ADMIN_PROJECT_SITE_______VIEW',
  SystemAdminProjectSiteCreate = 'SYSTEM_ADMIN_PROJECT_SITE_______CREATE',
  SystemAdminProjectSiteUpdate = 'SYSTEM_ADMIN_PROJECT_SITE_______UPDATE',
  SystemAdminProjectSiteDelete = 'SYSTEM_ADMIN_PROJECT_SITE_______DELETE',
  SystemAdminSubconSetupView = 'SYSTEM_ADMIN_SUBCON_SETUP_______VIEW',
  SystemAdminSubconSetupCreate = 'SYSTEM_ADMIN_SUBCON_SETUP_______CREATE',
  SystemAdminSubconSetupUpdate = 'SYSTEM_ADMIN_SUBCON_SETUP_______UPDATE',
  SystemAdminSubconSetupDelete = 'SYSTEM_ADMIN_SUBCON_SETUP_______DELETE',
  SystemAdminTaxSetupView = 'SYSTEM_ADMIN_TAX_SETUP_______VIEW',
  SystemAdminTaxSetupCreate = 'SYSTEM_ADMIN_TAX_SETUP_______CREATE',
  SystemAdminTaxSetupUpdate = 'SYSTEM_ADMIN_TAX_SETUP_______UPDATE',
  SystemAdminTaxSetupDelete = 'SYSTEM_ADMIN_TAX_SETUP_______DELETE',
  SystemAdminTaxSetupActive = 'SYSTEM_ADMIN_TAX_SETUP_______ACTIVE',
  SystemAdminTaxSetupInactive = 'SYSTEM_ADMIN_TAX_SETUP_______INACTIVE',
  SystemAdminTaxSetupViewHistory = 'SYSTEM_ADMIN_TAX_SETUP_______VIEW_HISTORY',
  SystemAdminUomSetupView = 'SYSTEM_ADMIN_UOM_SETUP_______VIEW',
  SystemAdminUomSetupCreate = 'SYSTEM_ADMIN_UOM_SETUP_______CREATE',
  SystemAdminUomSetupUpdate = 'SYSTEM_ADMIN_UOM_SETUP_______UPDATE',
  SystemAdminUomSetupDelete = 'SYSTEM_ADMIN_UOM_SETUP_______DELETE',
  SystemAdminUomSetupActive = 'SYSTEM_ADMIN_UOM_SETUP_______ACTIVE',
  SystemAdminUomSetupInactive = 'SYSTEM_ADMIN_UOM_SETUP_______INACTIVE',
  SystemAdminUomSetupDownload = 'SYSTEM_ADMIN_UOM_SETUP_______DOWNLOAD',
  SystemAdminUomSetupUpload = 'SYSTEM_ADMIN_UOM_SETUP_______UPLOAD',
  SystemAdminUomConversionView = 'SYSTEM_ADMIN_UOM_CONVERSION_______VIEW',
  SystemAdminUomConversionCreate = 'SYSTEM_ADMIN_UOM_CONVERSION_______CREATE',
  SystemAdminUomConversionUpdate = 'SYSTEM_ADMIN_UOM_CONVERSION_______UPDATE',
  SystemAdminUomConversionDelete = 'SYSTEM_ADMIN_UOM_CONVERSION_______DELETE',
  SystemAdminCompanySetupView = 'SYSTEM_ADMIN_COMPANY_SETUP_______VIEW',
  SystemAdminCompanySetupUpdate = 'SYSTEM_ADMIN_COMPANY_SETUP_______UPDATE',
  SystemAdminCompanySetupWatermarkLogoSetupView = 'SYSTEM_ADMIN_COMPANY_SETUP_WATERMARK_LOGO_SETUP____VIEW',
  SystemAdminContractSettingsView = 'SYSTEM_ADMIN_CONTRACT_SETTINGS_______VIEW',
  GroupBusinessInsightIcon = 'GROUP_BUSINESS_INSIGHT__________ICON',
  GroupBusinessInsightView = 'GROUP_BUSINESS_INSIGHT__________VIEW',
  GroupBusinessInsightKeyKpiView = 'GROUP_BUSINESS_INSIGHT_KEY_KPI_______VIEW',
  GroupBusinessInsightSiteGalleryView = 'GROUP_BUSINESS_INSIGHT_SITE_GALLERY_______VIEW',
  GroupBusinessInsightProjectCostingView = 'GROUP_BUSINESS_INSIGHT_PROJECT_COSTING_______VIEW',
  GroupBusinessInsightProjectPlView = 'GROUP_BUSINESS_INSIGHT_PROJECT_PL_______VIEW',
  GroupBusinessInsightCashFlowView = 'GROUP_BUSINESS_INSIGHT_CASH_FLOW_______VIEW',
  GroupBusinessInsightArView = 'GROUP_BUSINESS_INSIGHT_AR_______VIEW',
  GroupBusinessInsightVoView = 'GROUP_BUSINESS_INSIGHT_VO_______VIEW',
  DefectMgmtIcon = 'DEFECT_MGMT__________ICON',
  DefectMgmtView = 'DEFECT_MGMT__________VIEW',
  DefectMgmtCreate = 'DEFECT_MGMT__________CREATE',
  DefectMgmtUpdate = 'DEFECT_MGMT__________UPDATE',
  DefectMgmtActivityLogCreate = 'DEFECT_MGMT_ACTIVITY_LOG_______CREATE',
  DefectMgmtActivityLogView = 'DEFECT_MGMT_ACTIVITY_LOG_______VIEW',
  DefectMgmtActivityLogUpdate = 'DEFECT_MGMT_ACTIVITY_LOG_______UPDATE',
  DefectMgmtDefectAssignmentView = 'DEFECT_MGMT_DEFECT_ASSIGNMENT_______VIEW',
  DefectMgmtDefectAssignmentCreate = 'DEFECT_MGMT_DEFECT_ASSIGNMENT_______CREATE',
  DefectMgmtGeneralSettingsIcon = 'DEFECT_MGMT_GENERAL_SETTINGS_______ICON',
  DefectMgmtGeneralSettingsSubcontractPackageView = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____VIEW',
  DefectMgmtGeneralSettingsSubcontractPackageCreate = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____CREATE',
  DefectMgmtGeneralSettingsSubcontractPackageUpdate = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____UPDATE',
  DefectMgmtGeneralSettingsSubcontractPackageDelete = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____DELETE',
  DefectMgmtGeneralSettingsSubcontractPackageDownload = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____DOWNLOAD',
  DefectMgmtGeneralSettingsSubcontractPackageUpload = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____UPLOAD',
  SiteAttendanceIcon = 'SITE_ATTENDANCE__________ICON',
  SiteAttendanceView = 'SITE_ATTENDANCE__________VIEW',
  SiteAttendanceWorkerProfileCreate = 'SITE_ATTENDANCE_WORKER_PROFILE_______CREATE',
  SiteAttendanceWorkerProfileView = 'SITE_ATTENDANCE_WORKER_PROFILE_______VIEW',
  SiteAttendanceWorkerProfileUpdate = 'SITE_ATTENDANCE_WORKER_PROFILE_______UPDATE',
  SiteAttendanceWorkerProfileDelete = 'SITE_ATTENDANCE_WORKER_PROFILE_______DELETE',
  SiteAttendanceWorkerProfileDownload = 'SITE_ATTENDANCE_WORKER_PROFILE_______DOWNLOAD',
  SiteAttendanceWorkerProfileUpload = 'SITE_ATTENDANCE_WORKER_PROFILE_______UPLOAD',
  SiteAttendanceWorkerAttendanceCreate = 'SITE_ATTENDANCE_WORKER_ATTENDANCE_______CREATE',
  SiteAttendanceWorkerAttendanceView = 'SITE_ATTENDANCE_WORKER_ATTENDANCE_______VIEW',
  SiteAttendanceWorkerAttendanceExport = 'SITE_ATTENDANCE_WORKER_ATTENDANCE_______EXPORT',
  SiteAttendanceGeneralSettingsIcon = 'SITE_ATTENDANCE_GENERAL_SETTINGS_______ICON',
  SiteAttendanceGeneralSettingsPublicHolidayCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CREATE',
  SiteAttendanceGeneralSettingsPublicHolidayView = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____VIEW',
  SiteAttendanceGeneralSettingsPublicHolidayUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____UPDATE',
  SiteAttendanceGeneralSettingsPublicHolidayDelete = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____DELETE',
  SiteAttendanceGeneralSettingsPublicHolidayCancel = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CANCEL',
  SiteAttendanceGeneralSettingsPublicHolidayActive = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____ACTIVE',
  SiteAttendanceGeneralSettingsCalendarPolicyCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_CALENDAR_POLICY____CREATE',
  SiteAttendanceGeneralSettingsCalendarPolicyView = 'SITE_ATTENDANCE_GENERAL_SETTINGS_CALENDAR_POLICY____VIEW',
  SiteAttendanceGeneralSettingsCalendarPolicyUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_CALENDAR_POLICY____UPDATE',
  SiteAttendanceGeneralSettingsWorkingHoursPolicyCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_WORKING_HOURS_POLICY____CREATE',
  SiteAttendanceGeneralSettingsWorkingHoursPolicyUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_WORKING_HOURS_POLICY____UPDATE',
  SiteAttendanceGeneralSettingsJobTypeCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____CREATE',
  SiteAttendanceGeneralSettingsJobTypeView = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____VIEW',
  SiteAttendanceGeneralSettingsJobTypeUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____UPDATE',
  SiteAttendanceGeneralSettingsJobTypeDelete = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____DELETE',
  SiteAttendanceGeneralSettingsJobTypeCancel = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____CANCEL',
  SiteAttendanceGeneralSettingsJobTypeActive = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____ACTIVE',
  SiteWagesIcon = 'SITE_WAGES__________ICON',
  SiteWagesWorkerAttendanceView = 'SITE_WAGES_WORKER_ATTENDANCE__________VIEW',
  SiteWagesWorkerAttendanceCreate = 'SITE_WAGES_WORKER_ATTENDANCE__________CREATE',
  SiteWagesView = 'SITE_WAGES__________VIEW',
  SiteWagesDailyRegisteredWorkHoursCreate = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______CREATE',
  SiteWagesDailyRegisteredWorkHoursView = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______VIEW',
  SiteWagesDailyRegisteredWorkHoursUpdate = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______UPDATE',
  SiteWagesDailyRegisteredWorkHoursDelete = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______DELETE',
  SiteWagesDailyWagesVerificationCreate = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______CREATE',
  SiteWagesDailyWagesVerificationView = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______VIEW',
  SiteWagesDailyWagesVerificationUpdate = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______UPDATE',
  SiteWagesDailyWagesVerificationDelete = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______DELETE',
  SiteWagesWagesBookView = 'SITE_WAGES_WAGES_BOOK_______VIEW',
  SiteWagesWagesBookCreate = 'SITE_WAGES_WAGES_BOOK_______CREATE',
  SiteWagesWagesBookUpdate = 'SITE_WAGES_WAGES_BOOK_______UPDATE',
  SiteWagesWagesBookDelete = 'SITE_WAGES_WAGES_BOOK_______DELETE',
  SiteWagesWageCycleClosureCreate = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______CREATE',
  SiteWagesWageCycleClosureView = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______VIEW',
  SiteWagesWageCycleClosureUpdate = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______UPDATE',
  SiteWagesWageCycleClosureDelete = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______DELETE',
  SiteWagesGeneralSettingsIcon = 'SITE_WAGES_GENERAL_SETTINGS_______ICON',
  SiteInventoryIcon = 'SITE_INVENTORY__________ICON',
  SiteInventoryView = 'SITE_INVENTORY__________VIEW',
  SiteInventoryGoodReceiveNoteCreate = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______CREATE',
  SiteInventoryGoodReceiveNoteView = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______VIEW',
  SiteInventoryGoodReceiveNoteUpdate = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______UPDATE',
  SiteInventoryGoodReceiveNoteApproveReject = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______APPROVE_REJECT',
  SiteInventoryGoodReceiveNoteCancel = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______CANCEL',
  SiteInventoryGoodReceiveNoteDelete = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______DELETE',
  SiteInventoryStockIssueCreate = 'SITE_INVENTORY_STOCK_ISSUE_______CREATE',
  SiteInventoryStockIssueView = 'SITE_INVENTORY_STOCK_ISSUE_______VIEW',
  SiteInventoryStockIssueUpdate = 'SITE_INVENTORY_STOCK_ISSUE_______UPDATE',
  SiteInventoryStockIssueDelete = 'SITE_INVENTORY_STOCK_ISSUE_______DELETE',
  SiteInventoryStockIssueDownload = 'SITE_INVENTORY_STOCK_ISSUE_______DOWNLOAD',
  SiteInventoryStockIssueApproveReject = 'SITE_INVENTORY_STOCK_ISSUE_______APPROVE_REJECT',
  SiteInventoryStockIssueCancel = 'SITE_INVENTORY_STOCK_ISSUE_______CANCEL',
  SiteInventoryStockTransferInCreate = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______CREATE',
  SiteInventoryStockTransferInView = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______VIEW',
  SiteInventoryStockTransferInUpdate = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______UPDATE',
  SiteInventoryStockTransferInDelete = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______DELETE',
  SiteInventoryStockTransferInDownload = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______DOWNLOAD',
  SiteInventoryStockTransferInApproveReject = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______APPROVE_REJECT',
  SiteInventoryStockTransferInCancel = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______CANCEL',
  SiteInventoryStockTransferOutCreate = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______CREATE',
  SiteInventoryStockTransferOutView = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______VIEW',
  SiteInventoryStockTransferOutUpdate = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______UPDATE',
  SiteInventoryStockTransferOutDelete = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______DELETE',
  SiteInventoryStockTransferOutDownload = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______DOWNLOAD',
  SiteInventoryStockTransferOutApproveReject = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______APPROVE_REJECT',
  SiteInventoryStockTransferOutCancel = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______CANCEL',
  SiteInventoryStockAdjustmentCreate = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______CREATE',
  SiteInventoryStockAdjustmentView = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______VIEW',
  SiteInventoryStockAdjustmentUpdate = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______UPDATE',
  SiteInventoryStockAdjustmentDelete = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______DELETE',
  SiteInventoryStockAdjustmentDownload = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______DOWNLOAD',
  SiteInventoryStockAdjustmentApproveReject = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______APPROVE_REJECT',
  SiteInventoryStockAdjustmentCancel = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______CANCEL',
  SiteInventoryStockMovementCreate = 'SITE_INVENTORY_STOCK_MOVEMENT_______CREATE',
  SiteInventoryStockMovementView = 'SITE_INVENTORY_STOCK_MOVEMENT_______VIEW',
  SiteInventoryStockMovementUpdate = 'SITE_INVENTORY_STOCK_MOVEMENT_______UPDATE',
  SiteInventoryStockMovementDelete = 'SITE_INVENTORY_STOCK_MOVEMENT_______DELETE',
  SiteInventorySiteUsageComparisonCreate = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______CREATE',
  SiteInventorySiteUsageComparisonView = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______VIEW',
  SiteInventorySiteUsageComparisonUpdate = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______UPDATE',
  SiteInventorySiteUsageComparisonDelete = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______DELETE',
  SiteInventorySiteUsageComparisonExport = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______EXPORT',
  SiteInventorySiteWorkerAttendanceTrackingView = 'SITE_INVENTORY_SITE_WORKER_ATTENDANCE_TRACKING_______VIEW',
  SiteInventorySiteWorkerAttendanceTrackingExport = 'SITE_INVENTORY_SITE_WORKER_ATTENDANCE_TRACKING_______EXPORT',
  SiteInventoryStockUsageAnalysisCreate = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______CREATE',
  SiteInventoryStockUsageAnalysisView = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______VIEW',
  SiteInventoryStockUsageAnalysisUpdate = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______UPDATE',
  SiteInventoryStockUsageAnalysisDelete = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______DELETE',
  SiteInventoryStockUsageAnalysisExport = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______EXPORT',
  SiteInventoryStockUsageDailyAnalysisCreate = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______CREATE',
  SiteInventoryStockUsageDailyAnalysisView = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______VIEW',
  SiteInventoryStockUsageDailyAnalysisUpdate = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______UPDATE',
  SiteInventoryStockUsageDailyAnalysisDelete = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______DELETE',
  SiteInventoryStockUsageDailyAnalysisExport = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______EXPORT',
  SiteInventoryStockUsageMonthlyAnalysisCreate = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______CREATE',
  SiteInventoryStockUsageMonthlyAnalysisView = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______VIEW',
  SiteInventoryStockUsageMonthlyAnalysisUpdate = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______UPDATE',
  SiteInventoryStockUsageMonthlyAnalysisDelete = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______DELETE',
  SiteInventoryStockUsageMonthlyAnalysisExport = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______EXPORT',
  SiteInventoryStockMoveInOutAnalysisCreate = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______CREATE',
  SiteInventoryStockMoveInOutAnalysisView = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______VIEW',
  SiteInventoryStockMoveInOutAnalysisUpdate = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______UPDATE',
  SiteInventoryStockMoveInOutAnalysisDelete = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______DELETE',
  SiteInventoryStockMoveInOutAnalysisExport = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______EXPORT',
  SiteInventorySiteUsageAnalysisCreate = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______CREATE',
  SiteInventorySiteUsageAnalysisView = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______VIEW',
  SiteInventorySiteUsageAnalysisUpdate = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______UPDATE',
  SiteInventorySiteUsageAnalysisDelete = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______DELETE',
  SiteInventorySiteUsageAnalysisExport = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______EXPORT',
  SiteInventoryDigitalReportingView = 'SITE_INVENTORY_DIGITAL_REPORTING_______VIEW',
  SiteInventoryDigitalReportingStockMovementLedgerReportView = 'SITE_INVENTORY_DIGITAL_REPORTING_STOCK_MOVEMENT_LEDGER_REPORT____VIEW',
  SiteInventoryGeneralSettingsIcon = 'SITE_INVENTORY_GENERAL_SETTINGS_______ICON',
  SiteInventoryGeneralSettingsSiteMaterialCreate = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____CREATE',
  SiteInventoryGeneralSettingsSiteMaterialView = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____VIEW',
  SiteInventoryGeneralSettingsSiteMaterialUpdate = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____UPDATE',
  SiteInventoryGeneralSettingsSiteMaterialDelete = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____DELETE',
  SiteInventoryGeneralSettingsSiteMaterialCancel = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____CANCEL',
  SiteInventoryGeneralSettingsSiteMaterialActive = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____ACTIVE',
  SiteInventoryGeneralSettingsSiteMaterialDownload = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____DOWNLOAD',
  SiteInventoryGeneralSettingsSiteMaterialUpload = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____UPLOAD',
  SiteInventoryContractSettingsIcon = 'SITE_INVENTORY_CONTRACT_SETTINGS_______ICON',
  SiteSurveillanceIcon = 'SITE_SURVEILLANCE__________ICON',
  SiteSurveillanceView = 'SITE_SURVEILLANCE__________VIEW',
  SiteSurveillanceSiteImagesCreate = 'SITE_SURVEILLANCE_SITE_IMAGES_______CREATE',
  SiteSurveillanceSiteImagesView = 'SITE_SURVEILLANCE_SITE_IMAGES_______VIEW',
  SiteSurveillanceSiteImagesUpdate = 'SITE_SURVEILLANCE_SITE_IMAGES_______UPDATE',
  SiteSurveillanceSiteImagesDelete = 'SITE_SURVEILLANCE_SITE_IMAGES_______DELETE',
  SiteSurveillanceLiveVideocamView = 'SITE_SURVEILLANCE_LIVE_VIDEOCAM_______VIEW',
  SiteSurveillanceGeneralSettingsIcon = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_______ICON',
  SiteSurveillanceGeneralSettingsVideoCamSetupCreate = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____CREATE',
  SiteSurveillanceGeneralSettingsVideoCamSetupView = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____VIEW',
  SiteSurveillanceGeneralSettingsVideoCamSetupUpdate = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____UPDATE',
  SiteSurveillanceGeneralSettingsVideoCamSetupDelete = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____DELETE',
  SiteSafetyIcon = 'SITE_SAFETY__________ICON',
  SiteSafetyView = 'SITE_SAFETY__________VIEW',
  SiteSafetyCreate = 'SITE_SAFETY__________CREATE',
  BusinessInsightIcon = 'BUSINESS_INSIGHT__________ICON',
  BusinessInsightView = 'BUSINESS_INSIGHT__________VIEW',
  BusinessInsightDefectStatusAnalysisView = 'BUSINESS_INSIGHT_DEFECT_STATUS_ANALYSIS__________VIEW',
  BusinessInsightDefectStatusAnalysisExport = 'BUSINESS_INSIGHT_DEFECT_STATUS_ANALYSIS__________EXPORT',
  BusinessInsightDefectTradeAnalysisView = 'BUSINESS_INSIGHT_DEFECT_TRADE_ANALYSIS__________VIEW',
  BusinessInsightDefectTradeAnalysisExport = 'BUSINESS_INSIGHT_DEFECT_TRADE_ANALYSIS__________EXPORT',
  BusinessInsightDefectSubconAnalysisView = 'BUSINESS_INSIGHT_DEFECT_SUBCON_ANALYSIS__________VIEW',
  BusinessInsightDefectSubconAnalysisExport = 'BUSINESS_INSIGHT_DEFECT_SUBCON_ANALYSIS__________EXPORT',
  BusinessInsightDefectsLodgedOverTimeView = 'BUSINESS_INSIGHT_DEFECTS_LODGED_OVER_TIME__________VIEW',
  BusinessInsightDefectsLodgedOverTimeExport = 'BUSINESS_INSIGHT_DEFECTS_LODGED_OVER_TIME__________EXPORT',
  BusinessInsightGeneralSettingsIcon = 'BUSINESS_INSIGHT_GENERAL_SETTINGS__________ICON',
  DigitalDocumentIcon = 'DIGITAL_DOCUMENT__________ICON',
  DigitalDocumentView = 'DIGITAL_DOCUMENT__________VIEW',
  DigitalDocumentCreate = 'DIGITAL_DOCUMENT__________CREATE',
  DigitalDocumentDelete = 'DIGITAL_DOCUMENT__________DELETE',
  DigitalDocumentGeneralSettingsIcon = 'DIGITAL_DOCUMENT_GENERAL_SETTINGS__________ICON'
}

export type ContactEntity = AuditEntity & {
  __typename?: 'ContactEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  associateID: Scalars['String'];
  name: Scalars['String'];
  designation?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  contactNo: Scalars['String'];
  email: Scalars['String'];
  relationship?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
  userDetail?: Maybe<PlatformUserEntity>;
  worker: WorkerEntity;
  collectionActivity?: Maybe<Array<CollectionActivityEntity>>;
};

export type ContactInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  associateID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
};

/** JsonType */
export type ContactPerson = {
  __typename?: 'ContactPerson';
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ContactPersonInput = {
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ContractAccountEntity = AuditEntity & {
  __typename?: 'ContractAccountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  contractWbsID: Scalars['String'];
  contractWbs?: Maybe<WbsEntity>;
  revenueCategoryID: Scalars['String'];
  revenueCategory?: Maybe<RevenueCategoryEntity>;
  subcontractWbsID: Scalars['String'];
  subcontractWbs?: Maybe<WbsEntity>;
  costItemID: Scalars['String'];
  costItem?: Maybe<CostItemEntity>;
};

export type ContractActionInput = {
  closureRemarks?: Maybe<Scalars['String']>;
  closureDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  contractStatus: ContractStatus;
};

export type ContractAdvanceEntity = AuditEntity & {
  __typename?: 'ContractAdvanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  isRecoupment: Scalars['Boolean'];
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type ContractAdvanceEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ContractAllocationEntity = AuditEntity & {
  __typename?: 'ContractAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  allocationStatus: AllocationStatus;
  nonEditable: Scalars['Boolean'];
  contractCN: Array<ContractCnEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
};

export type ContractApprovalLogEntity = AuditEntity & {
  __typename?: 'ContractApprovalLogEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  toDoID: Scalars['String'];
  approverID: Scalars['String'];
  logDate?: Maybe<Scalars['DateTime']>;
  notifiedDate?: Maybe<Scalars['DateTime']>;
  toDoStatus: ToDoStatus;
  remarks?: Maybe<Scalars['String']>;
  toDo?: Maybe<ContractToDoEntity>;
};

export type ContractBudget = {
  __typename?: 'ContractBudget';
  originalBudgetSum?: Maybe<Scalars['Float']>;
  revisedBudgetSum?: Maybe<Scalars['Float']>;
};

export type ContractCnEntity = AuditEntity & {
  __typename?: 'ContractCNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  cancelledClaimAmt?: Maybe<Scalars['Float']>;
  cancelledRetentionAmt?: Maybe<Scalars['Float']>;
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  contractCNItem?: Maybe<Array<ContractCnItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  claimInfo?: Maybe<ContractClaimEntity>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type ContractCnEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ContractCnItemEntity = AuditEntity & {
  __typename?: 'ContractCNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  revenueCategoryID: Scalars['String'];
  contractCNID: Scalars['String'];
  contractCN?: Maybe<ContractCnEntity>;
  wbs?: Maybe<WbsEntity>;
  revenueCategory?: Maybe<RevenueCategoryEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  contractCNTaxItem?: Maybe<Array<ContractCnTaxItemEntity>>;
};

export type ContractCnTaxItemEntity = AuditEntity & {
  __typename?: 'ContractCNTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  contractCNItemID: Scalars['String'];
  contractCNItem?: Maybe<ContractCnItemEntity>;
};

export type ContractCertificateInput = {
  cmgdDate?: Maybe<Scalars['String']>;
  cpcDate?: Maybe<Scalars['String']>;
  dlpDate?: Maybe<Scalars['String']>;
  currentSubmittedStatus?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ContractClaimAllocInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  wbsID: Scalars['String'];
  revenueCategoryID: Scalars['String'];
  currentSubmission: Scalars['Float'];
  contractClaimID?: Maybe<Scalars['String']>;
};

export type ContractClaimAllocationEntity = AuditEntity & {
  __typename?: 'ContractClaimAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  allocationStatus: AllocationStatus;
  contractClaimID: Scalars['String'];
  contractClaim?: Maybe<ContractClaimEntity>;
  wbsBudgetDetailID?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
  wbsID?: Maybe<Scalars['String']>;
  wbs?: Maybe<WbsEntity>;
  revenueCategoryID?: Maybe<Scalars['String']>;
  revenueCategory?: Maybe<RevenueCategoryEntity>;
  classificationCodeID?: Maybe<Scalars['String']>;
  latestWbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type ContractClaimAllocationEntityLatestWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type ContractClaimEntity = AuditEntity & {
  __typename?: 'ContractClaimEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  claimDocNo?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  claimAmt?: Maybe<Scalars['Float']>;
  sequenceNo?: Maybe<Scalars['Float']>;
  revisedNo?: Maybe<Scalars['Float']>;
  submittedDate: Scalars['String'];
  voDisplayAmt?: Maybe<Scalars['Float']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  balanceAdvanceAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  balanceDeductionAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  claimVOLog?: Maybe<Array<Scalars['JSON']>>;
  isRetention: Scalars['Boolean'];
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  /** CustomFieldResolver */
  revenueAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  outstandingCertifiedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  contractClaimVOAllocation?: Maybe<Array<ContractClaimVoAllocationEntity>>;
  contractClaimAllocation?: Maybe<Array<ContractClaimAllocationEntity>>;
  contractClaimVORevenueWbsAllocation?: Maybe<Array<ContractClaimVoRevenueWbsAllocationEntity>>;
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  claimDetailRevenueWBS?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  claimWithDeduction?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  nonEditableAllocation?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  outstandingClaimCertified?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  grossAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  adjustmentInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
};


export type ContractClaimEntityRevenueAmtArgs = {
  withTax?: Maybe<Scalars['Boolean']>;
};


export type ContractClaimEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};


export type ContractClaimEntityNonEditableAllocationArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type ContractClaimInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  remarks?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['String']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentAdvBalance?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  currentDeductionBalance?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  voDisplayAmt?: Maybe<Scalars['Float']>;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
};

export type ContractClaimVoAllocInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  contractVOID: Scalars['String'];
  currentSubmission: Scalars['Float'];
  contractClaimID?: Maybe<Scalars['String']>;
};

export type ContractClaimVoAllocationEntity = AuditEntity & {
  __typename?: 'ContractClaimVOAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  allocationStatus: AllocationStatus;
  contractClaimID: Scalars['String'];
  contractClaim?: Maybe<ContractClaimEntity>;
  contractVOID: Scalars['String'];
  contractVO?: Maybe<ContractVoEntity>;
};

export type ContractClaimVoRevenueWbsAllocInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  contractVORevenueWbsID: Scalars['String'];
  currentSubmission: Scalars['Float'];
  contractClaimID?: Maybe<Scalars['String']>;
};

export type ContractClaimVoRevenueWbsAllocationEntity = AuditEntity & {
  __typename?: 'ContractClaimVORevenueWbsAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  allocationStatus: AllocationStatus;
  contractClaimID: Scalars['String'];
  contractClaim?: Maybe<ContractClaimEntity>;
  contractVORevenueWbsID: Scalars['String'];
  contractVORevenueWbs?: Maybe<ContractVoRevenueWbsEntity>;
};

export type ContractDnEntity = AuditEntity & {
  __typename?: 'ContractDNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  eInvoiceDocRefInvoiceID?: Maybe<Scalars['String']>;
  contractDNItem?: Maybe<Array<ContractDnItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type ContractDnEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type ContractDnItemEntity = AuditEntity & {
  __typename?: 'ContractDNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  revenueCategoryID: Scalars['String'];
  contractDNID: Scalars['String'];
  contractDN?: Maybe<ContractDnEntity>;
  wbs?: Maybe<WbsEntity>;
  revenueCategory?: Maybe<RevenueCategoryEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  contractDNTaxItem?: Maybe<Array<ContractDnTaxItemEntity>>;
};

export type ContractDnTaxItemEntity = AuditEntity & {
  __typename?: 'ContractDNTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  contractDNItemID: Scalars['String'];
  contractDNItem?: Maybe<ContractDnItemEntity>;
};

export enum ContractDocNum {
  ArClaim = 'AR_CLAIM',
  ArMiscIv = 'AR_MISC_IV',
  ArCn = 'AR_CN',
  ArDn = 'AR_DN',
  ArAdvanceRecoup = 'AR_ADVANCE_RECOUP',
  ArPayment = 'AR_PAYMENT',
  ArReceipt = 'AR_RECEIPT',
  ArInvoice = 'AR_INVOICE',
  ApClaim = 'AP_CLAIM',
  ApMiscIv = 'AP_MISC_IV',
  ApCn = 'AP_CN',
  ApDn = 'AP_DN',
  ApAdvanceRecoup = 'AP_ADVANCE_RECOUP',
  ApPayment = 'AP_PAYMENT',
  ApReceipt = 'AP_RECEIPT',
  ApInvoice = 'AP_INVOICE',
  Default = 'DEFAULT',
  Df = 'DF',
  StockMvmt = 'STOCK_MVMT',
  Grn = 'GRN',
  Grtn = 'GRTN',
  Po = 'PO',
  Pr = 'PR',
  Rfq = 'RFQ',
  Vo = 'VO',
  Svo = 'SVO',
  Fs = 'FS',
  Fd = 'FD',
  ProjectMiscExpense = 'PROJECT_MISC_EXPENSE',
  BudgetTransfer = 'BUDGET_TRANSFER'
}

export type ContractEntity = AuditEntity & {
  __typename?: 'ContractEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  progressRetentionPerc: Scalars['Float'];
  maxRetentionPerc: Scalars['Float'];
  ladAmt?: Maybe<Scalars['Float']>;
  ladRate?: Maybe<RateUomType>;
  targetProfitPerc?: Maybe<Scalars['Float']>;
  cmgdDate?: Maybe<Scalars['String']>;
  cpcDate?: Maybe<Scalars['String']>;
  dlpDate?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
  extendedDate?: Maybe<Scalars['String']>;
  extensionDate?: Maybe<Scalars['JSON']>;
  closureDate?: Maybe<Scalars['String']>;
  closureRemarks?: Maybe<Scalars['String']>;
  completionPerc?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Array<Scalars['JSON']>>;
  companyID?: Maybe<Scalars['String']>;
  customerID: Scalars['String'];
  customerDetail?: Maybe<CustomerEntity>;
  bankAccountID?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<BankAccountEntity>;
  taxSchemeID: Scalars['String'];
  taxDetail?: Maybe<ConTaxSchemeEntity>;
  costCenterID?: Maybe<Scalars['String']>;
  costCenter?: Maybe<CostCenterEntity>;
  profitRecogFormulaID?: Maybe<Scalars['String']>;
  profitRecogFormula?: Maybe<ProfitRecogFormulaEntity>;
  contractNo: Scalars['String'];
  contractDate: Scalars['String'];
  contractSum: Scalars['Float'];
  department?: Maybe<Scalars['String']>;
  implementWbs: Scalars['Boolean'];
  pdfDocumentTitle?: Maybe<Scalars['String']>;
  budgetChecker: BudgetChecker;
  contractStatus: ContractStatus;
  VO: Array<ContractVoEntity>;
  deliveryOrder?: Maybe<Array<DoEntity>>;
  miscExpenses?: Maybe<Array<MiscExpenseEntity>>;
  negotiatedSupply?: Maybe<Array<NegotiatedSupplyEntity>>;
  purchaseReq?: Maybe<Array<PurchaseReqEntity>>;
  purchaseOrder?: Maybe<Array<PurchaseOrderEntity>>;
  rfq?: Maybe<Array<RfqEntity>>;
  siteMaterialContractPolicy?: Maybe<Array<SiteMaterialContractPolicyEntity>>;
  goodReturnNote?: Maybe<Array<GoodReturnNoteEntity>>;
  subcontractTenderDetail?: Maybe<Array<SubcontractTenderEntity>>;
  subcontractDetail?: Maybe<Array<SubcontractEntity>>;
  glExportDetailSource?: Maybe<Array<GlExportDetailSourceEntity>>;
  approvalPolicyAssignment?: Maybe<Array<ApprovalPolicyAssignmentEntity>>;
  projectBudget: Array<ProjectBudgetEntity>;
  site?: Maybe<Array<SiteEntity>>;
  assignment?: Maybe<Array<AssignmentEntity>>;
  defect?: Maybe<Array<DefectManagementEntity>>;
  siteProgress?: Maybe<Array<SiteProgressEntity>>;
  pnmRental?: Maybe<Array<PnmRentalEntity>>;
  profitRecogAssgmt?: Maybe<Array<ProfitRecogAssignmentEntity>>;
  profitRecogGeneration?: Maybe<Array<ProfitRecogGenerationEntity>>;
  collectionActivity?: Maybe<Array<CollectionActivityEntity>>;
  budget?: Maybe<Array<WbsBudgetEntity>>;
  wbs?: Maybe<Array<WbsEntity>>;
  projectExpense?: Maybe<Array<ProjectExpenseEntity>>;
  contractAccount?: Maybe<Array<ContractAccountEntity>>;
  billInterest?: Maybe<Array<BillInterestEntity>>;
  contractClaim?: Maybe<Array<ContractClaimEntity>>;
  subcontractClaim?: Maybe<Array<SubcontractClaimEntity>>;
  contractMiscInvoice?: Maybe<Array<ContractMiscInvoiceEntity>>;
  contractDN?: Maybe<Array<ContractDnEntity>>;
  contractCN?: Maybe<Array<ContractCnEntity>>;
  contractClientDN?: Maybe<Array<ClientDnEntity>>;
  contractClientCN?: Maybe<Array<ClientCnEntity>>;
  contractInv?: Maybe<Array<ApInvoiceEntity>>;
  contractDebitNote?: Maybe<Array<ApDebitNoteEntity>>;
  contractCreditNote?: Maybe<Array<ApCreditNoteEntity>>;
  contractDNSupplier?: Maybe<Array<DNtoSupplierEntity>>;
  contractCNSupplier?: Maybe<Array<CNtoSupplierEntity>>;
  contractReceipt?: Maybe<Array<ContractReceiptEntity>>;
  contractAdvance?: Maybe<Array<ContractAdvanceEntity>>;
  contractRefund?: Maybe<Array<ContractRefundEntity>>;
  apAdvance?: Maybe<Array<ApAdvanceEntity>>;
  apRefund?: Maybe<Array<ApRefundEntity>>;
  contractUtilizedBudget?: Maybe<Array<WbsUtilizedBudgetEntity>>;
  wbsBudgetTransfer?: Maybe<Array<WbsBudgetTransferEntity>>;
  wbsBudgetTransferHeader?: Maybe<Array<WbsBudgetTransferHeaderEntity>>;
  bq?: Maybe<Array<BqEntity>>;
  fuelMeterReading?: Maybe<Array<FuelMeterReadingEntity>>;
  fuelDischarge?: Maybe<Array<FuelDischargeEntity>>;
  fuelSupply?: Maybe<Array<FuelSupplyEntity>>;
  docNumHeader?: Maybe<Array<DocNumHeaderEntity>>;
  wbsDaywork?: Maybe<Array<WbsDayworkCostRevenueEntity>>;
  /** CustomFieldResolver */
  company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  companyLoader?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  latestBudgetID?: Maybe<Scalars['JSON']>;
  contact?: Maybe<Array<ContactEntity>>;
  /** CustomFieldResolver */
  financialClientReceipt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  latestColAct?: Maybe<Scalars['JSON']>;
  latestCollectionActivity?: Maybe<CollectionActivityEntity>;
  /** CustomFieldResolver */
  contractAgingInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  latestRunNum?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  docNumChecking?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  subcontractTenderSummary?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  subcontractSummary?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  purchasingSummary?: Maybe<Scalars['JSON']>;
  costCateg: Array<CostCategoryEntity>;
  /** CustomFieldResolver */
  contractRolesUsersAssigned?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  defectStatusByContract?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  voSum?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  revisedContractSum?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  contractPOCounts?: Maybe<Scalars['JSON']>;
  maxRetentionSum: Scalars['Float'];
  /** CustomFieldResolver */
  contractDefectCounts?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  osContractAmt?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  originalBudget?: Maybe<WbsBudgetEntity>;
  /** CustomFieldResolver */
  revisedBudget?: Maybe<WbsBudgetEntity>;
};


export type ContractEntityLatestCollectionActivityArgs = {
  selectedDate?: Maybe<Scalars['String']>;
};


export type ContractEntityCostCategArgs = {
  costCategoryID?: Maybe<Scalars['String']>;
  costClass?: Maybe<CostClass>;
};


export type ContractEntityContractRolesUsersAssignedArgs = {
  softwareCode?: Maybe<Scalars['String']>;
};

export type ContractExtensionInput = {
  extensionDate?: Maybe<ExtensionDateInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ContractGuaranteeCancellationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  remarks?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  contractID?: Maybe<Scalars['String']>;
};

export type ContractGuaranteeEntity = AuditEntity & {
  __typename?: 'ContractGuaranteeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  guaranteeTypeID: Scalars['String'];
  collateralAmt: Scalars['Float'];
  guaranteeStatus: GrntInsStatus;
  contractID: Scalars['String'];
  guaranteeType?: Maybe<GuaranteeTypeEntity>;
};

export type ContractGuaranteeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  issuer: Scalars['String'];
  description: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
  contractID: Scalars['String'];
  guaranteeTypeID: Scalars['String'];
  collateralAmt: Scalars['Float'];
  rejectedDate?: Maybe<Scalars['String']>;
  guaranteeStatus?: Maybe<GrntInsStatus>;
};

export type ContractInfo = {
  __typename?: 'ContractInfo';
  contractSum?: Maybe<Scalars['Float']>;
  contractDate?: Maybe<Scalars['DateTime']>;
};

export type ContractInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  title: Scalars['String'];
  description: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  progressRetentionPerc: Scalars['Float'];
  maxRetentionPerc: Scalars['Float'];
  creditTerm?: Maybe<Scalars['Float']>;
  ladAmt?: Maybe<Scalars['Float']>;
  ladRate?: Maybe<RateUomType>;
  claimRunNum?: Maybe<Scalars['Float']>;
  claimPrefix?: Maybe<Scalars['String']>;
  voRunNum?: Maybe<Scalars['Float']>;
  voPrefix?: Maybe<Scalars['String']>;
  targetProfitPerc?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Array<ContactPersonInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
  customerID: Scalars['String'];
  contractNo: Scalars['String'];
  implementWbs: Scalars['Boolean'];
  contractDate: Scalars['String'];
  contractSum: Scalars['Float'];
  pdfDocumentTitle?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  roleID?: Maybe<Scalars['String']>;
  bankAccountID?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
  address?: Maybe<Array<AddressInput>>;
  contractStatus?: Maybe<ContractStatus>;
  budgetChecker?: Maybe<BudgetChecker>;
  LADFile?: Maybe<Array<Scalars['Upload']>>;
};

export type ContractInsuranceCancellationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  remarks?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  contractID?: Maybe<Scalars['String']>;
};

export type ContractInsuranceEntity = AuditEntity & {
  __typename?: 'ContractInsuranceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  insuranceTypeID: Scalars['String'];
  insuranceStatus: GrntInsStatus;
  contractID: Scalars['String'];
  insuranceType?: Maybe<InsuranceTypeEntity>;
};

export type ContractInsuranceInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  issuer: Scalars['String'];
  description: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
  contractID: Scalars['String'];
  insuranceTypeID: Scalars['String'];
  rejectedDate?: Maybe<Scalars['String']>;
  insuranceStatus?: Maybe<GrntInsStatus>;
};

export type ContractMiscInvoiceEntity = AuditEntity & {
  __typename?: 'ContractMiscInvoiceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  contractMiscInvoiceItem?: Maybe<Array<ContractMiscInvoiceItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
  /** CustomFieldResolver */
  isDaywork?: Maybe<Scalars['Boolean']>;
};


export type ContractMiscInvoiceEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ContractMiscInvoiceItemEntity = AuditEntity & {
  __typename?: 'ContractMiscInvoiceItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  revenueCategoryID: Scalars['String'];
  contractMiscInvoiceID: Scalars['String'];
  contractMiscInvoice?: Maybe<ContractMiscInvoiceEntity>;
  wbs?: Maybe<WbsEntity>;
  revenueCategory?: Maybe<RevenueCategoryEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  contractMiscInvoiceTaxItem?: Maybe<Array<ContractMiscInvoiceTaxItemEntity>>;
};

export type ContractMiscInvoiceTaxItemEntity = AuditEntity & {
  __typename?: 'ContractMiscInvoiceTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  contractMiscInvoiceItemID: Scalars['String'];
  contractMiscInvoiceItem?: Maybe<ContractMiscInvoiceItemEntity>;
};

export type ContractNewsEntity = AuditEntity & {
  __typename?: 'ContractNewsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  postStatus: PostStatus;
  image?: Maybe<Scalars['String']>;
  newsRead?: Maybe<Array<ContractNewsReadEntity>>;
};

export type ContractNewsInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  title: Scalars['String'];
  content: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  postStatus: PostStatus;
};

export type ContractNewsReadEntity = AuditEntity & {
  __typename?: 'ContractNewsReadEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  userID: Scalars['String'];
  newsID: Scalars['String'];
  news?: Maybe<ContractNewsEntity>;
};

export type ContractNewsReadInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  newsID: Scalars['DateTime'];
  userID: Scalars['String'];
};

export type ContractNotificationEntity = AuditEntity & {
  __typename?: 'ContractNotificationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRefID: Scalars['String'];
  workflowID?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  notificationRecipient?: Maybe<Array<ContractNotificationRecipientEntity>>;
};

export type ContractNotificationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  senderID: Scalars['String'];
  recipientID: Scalars['String'];
  documentID: Scalars['String'];
  docRefTable: Scalars['String'];
  subject: Scalars['String'];
  body: Scalars['String'];
  readStatus: ReadStatus;
};

export type ContractNotificationRecipientEntity = AuditEntity & {
  __typename?: 'ContractNotificationRecipientEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  notificationID?: Maybe<Scalars['String']>;
  recipientID: Scalars['String'];
  isRead: Scalars['Boolean'];
  notification?: Maybe<ContractNotificationEntity>;
};

export enum ContractPermission {
  HomeExecSummaryView = 'HOME_EXEC_SUMMARY_____________VIEW',
  HomeContractView = 'HOME_CONTRACT_____________VIEW',
  HomeContractCreate = 'HOME_CONTRACT_____________CREATE',
  HomeContractExecSummaryView = 'HOME_CONTRACT_EXEC_SUMMARY__________VIEW',
  HomeContractExecSummaryContractBudgetView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_BUDGET_______VIEW',
  HomeContractExecSummaryContractInfoView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INFO_______VIEW',
  HomeContractExecSummaryContractInfoUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INFO_______UPDATE',
  HomeContractExecSummaryContractGuaranteeView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_GUARANTEE_______VIEW',
  HomeContractExecSummaryContractGuaranteeCreate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_GUARANTEE_______CREATE',
  HomeContractExecSummaryContractGuaranteeUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_GUARANTEE_______UPDATE',
  HomeContractExecSummaryContractGuaranteeCancel = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_GUARANTEE_______CANCEL',
  HomeContractExecSummaryContractInsuranceView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INSURANCE_______VIEW',
  HomeContractExecSummaryContractInsuranceCreate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INSURANCE_______CREATE',
  HomeContractExecSummaryContractInsuranceUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INSURANCE_______UPDATE',
  HomeContractExecSummaryContractInsuranceCancel = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INSURANCE_______CANCEL',
  HomeContractExecSummaryContractVoView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______VIEW',
  HomeContractExecSummaryContractVoCreate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______CREATE',
  HomeContractExecSummaryContractVoDraft = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______DRAFT',
  HomeContractExecSummaryContractVoUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______UPDATE',
  HomeContractExecSummaryContractVoApprove = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______APPROVE',
  HomeContractExecSummaryContractVoReject = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______REJECT',
  HomeContractExecSummaryContractVoHealthIndexView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_HEALTH_INDEX_______VIEW',
  HomeContractExecSummaryContractVoHealthIndexDownload = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_HEALTH_INDEX_______DOWNLOAD',
  HomeContractExecSummaryContractVoHealthIndexExport = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_HEALTH_INDEX_______EXPORT',
  HomeContractExecSummaryContractAccountStatusView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS____VIEW',
  HomeContractExecSummaryContractAccountStatusClientProfileView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_CLIENT_PROFILE____VIEW',
  HomeContractExecSummaryContractAccountStatusAdvancesView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_ADVANCES____VIEW',
  HomeContractExecSummaryContractAccountStatusProgressClaimView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_PROGRESS_CLAIM____VIEW',
  HomeContractExecSummaryContractAccountStatusRetentionClaimView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_RETENTION_CLAIM____VIEW',
  HomeContractExecSummaryContractAccountStatusDebitNotesView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_DEBIT_NOTES____VIEW',
  HomeContractExecSummaryContractAccountStatusCreditNotesView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_CREDIT_NOTES____VIEW',
  HomeContractExecSummaryContractAccountStatusReceiptsView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_RECEIPTS____VIEW',
  HomeContractExecSummaryContractAccountStatusRefundsView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_REFUNDS____VIEW',
  HomeContractExecSummaryContractAccountStatusClientDebitNotesView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_CLIENT_DEBIT_NOTES____VIEW',
  HomeContractExecSummaryContractAccountStatusOsReceivableView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_OS_RECEIVABLE____VIEW',
  HomeContractExecSummaryMiscExpensesView = 'HOME_CONTRACT_EXEC_SUMMARY_MISC_EXPENSES_______VIEW',
  HomeContractExecSummaryMiscExpensesCreate = 'HOME_CONTRACT_EXEC_SUMMARY_MISC_EXPENSES_______CREATE',
  HomeContractExecSummaryMiscExpensesUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_MISC_EXPENSES_______UPDATE',
  HomeContractExecSummaryMiscExpensesCancel = 'HOME_CONTRACT_EXEC_SUMMARY_MISC_EXPENSES_______CANCEL',
  HomeContractExecSummaryRetentionView = 'HOME_CONTRACT_EXEC_SUMMARY_RETENTION_______VIEW',
  HomeContractExecSummaryRetentionCreate = 'HOME_CONTRACT_EXEC_SUMMARY_RETENTION_______CREATE',
  HomeContractExecSummaryRetentionUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_RETENTION_______UPDATE',
  HomeContractExecSummaryRetentionAttachmentDownload = 'HOME_CONTRACT_EXEC_SUMMARY_RETENTION_ATTACHMENT____DOWNLOAD',
  HomeContractExecSummaryExtensionOfTimeView = 'HOME_CONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_______VIEW',
  HomeContractExecSummaryExtensionOfTimeCreate = 'HOME_CONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_______CREATE',
  HomeContractExecSummaryExtensionOfTimeAttachmentDownload = 'HOME_CONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_ATTACHMENT____DOWNLOAD',
  HomeContractExecSummaryContractClosureView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_CLOSURE_______VIEW',
  HomeContractExecSummaryContractTerminationView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_TERMINATION_______VIEW',
  HomeCustomerView = 'HOME_CUSTOMER_____________VIEW',
  HomeCustomerExecSummaryView = 'HOME_CUSTOMER_EXEC_SUMMARY__________VIEW',
  HomeCustomerExecSummaryCustomerProfileView = 'HOME_CUSTOMER_EXEC_SUMMARY_CUSTOMER_PROFILE_______VIEW',
  HomeCustomerExecSummaryCustomerProfileUpdate = 'HOME_CUSTOMER_EXEC_SUMMARY_CUSTOMER_PROFILE_______UPDATE',
  HomeCustomerExecSummaryCustomerProfileCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_CUSTOMER_PROFILE_______CREATE',
  HomeCustomerExecSummaryAccountTransactionAdvancesCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_ADVANCES____CREATE',
  HomeCustomerExecSummaryAccountTransactionAdvancesView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_ADVANCES____VIEW',
  HomeCustomerExecSummaryAccountTransactionAdvancesCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_ADVANCES____CANCEL',
  HomeCustomerExecSummaryAccountTransactionProgressClaimView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____VIEW',
  HomeCustomerExecSummaryAccountTransactionProgressClaimApprove = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____APPROVE',
  HomeCustomerExecSummaryAccountTransactionProgressClaimReject = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____REJECT',
  HomeCustomerExecSummaryAccountTransactionProgressClaimCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____CANCEL',
  HomeCustomerExecSummaryAccountTransactionProgressClaimCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____CREATE',
  HomeCustomerExecSummaryAccountTransactionProgressClaimResubmit = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____RESUBMIT',
  HomeCustomerExecSummaryAccountTransactionProgressClaimDraft = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____DRAFT',
  HomeCustomerExecSummaryAccountTransactionProgressClaimDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____VIEW',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimApprove = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____APPROVE',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimReject = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____REJECT',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____CANCEL',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____CREATE',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimResubmit = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____RESUBMIT',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimDraft = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____DRAFT',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionDebitNotesView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_DEBIT_NOTES____VIEW',
  HomeCustomerExecSummaryAccountTransactionDebitNotesCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_DEBIT_NOTES____CANCEL',
  HomeCustomerExecSummaryAccountTransactionDebitNotesCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_DEBIT_NOTES____CREATE',
  HomeCustomerExecSummaryAccountTransactionDebitNotesDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_DEBIT_NOTES____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionCreditNotesView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CREDIT_NOTES____VIEW',
  HomeCustomerExecSummaryAccountTransactionCreditNotesCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CREDIT_NOTES____CANCEL',
  HomeCustomerExecSummaryAccountTransactionCreditNotesCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CREDIT_NOTES____CREATE',
  HomeCustomerExecSummaryAccountTransactionCreditNotesDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CREDIT_NOTES____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionCreditNotesAllocate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CREDIT_NOTES____ALLOCATE',
  HomeCustomerExecSummaryAccountTransactionReceiptsView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RECEIPTS____VIEW',
  HomeCustomerExecSummaryAccountTransactionReceiptsAllocate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RECEIPTS____ALLOCATE',
  HomeCustomerExecSummaryAccountTransactionReceiptsCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RECEIPTS____CANCEL',
  HomeCustomerExecSummaryAccountTransactionReceiptsCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RECEIPTS____CREATE',
  HomeCustomerExecSummaryAccountTransactionReceiptsDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RECEIPTS____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionRefundsView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____VIEW',
  HomeCustomerExecSummaryAccountTransactionRefundsDraft = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____DRAFT',
  HomeCustomerExecSummaryAccountTransactionRefundsCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____CANCEL',
  HomeCustomerExecSummaryAccountTransactionRefundsCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____CREATE',
  HomeCustomerExecSummaryAccountTransactionRefundsApprove = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____APPROVE',
  HomeCustomerExecSummaryAccountTransactionRefundsReject = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____REJECT',
  HomeCustomerExecSummaryAccountTransactionRefundsDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionClientDebitNotesView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CLIENT_DEBIT_NOTES____VIEW',
  HomeCustomerExecSummaryAccountTransactionClientDebitNotesCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CLIENT_DEBIT_NOTES____CANCEL',
  HomeCustomerExecSummaryAccountTransactionClientDebitNotesCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CLIENT_DEBIT_NOTES____CREATE',
  HomeCustomerExecSummaryAccountTransactionOsReceivableView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_OS_RECEIVABLE____VIEW',
  HomeCustomerExecSummaryCreditControlCustomerListingView = 'HOME_CUSTOMER_EXEC_SUMMARY_CREDIT_CONTROL_CUSTOMER_LISTING____VIEW',
  HomeCustomerExecSummaryStatementOfAccountCustomerAccountListingView = 'HOME_CUSTOMER_EXEC_SUMMARY_STATEMENT_OF_ACCOUNT_CUSTOMER_ACCOUNT_LISTING____VIEW',
  HomeSubcontractView = 'HOME_SUBCONTRACT_____________VIEW',
  HomeSubcontractCreate = 'HOME_SUBCONTRACT_____________CREATE',
  HomeSubcontractExecSummaryView = 'HOME_SUBCONTRACT_EXEC_SUMMARY__________VIEW',
  HomeSubcontractExecSummarySubcontractInfoView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INFO_______VIEW',
  HomeSubcontractExecSummarySubcontractInfoUpdate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INFO_______UPDATE',
  HomeSubcontractExecSummarySubcontractGuaranteeCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_GUARANTEE_______CREATE',
  HomeSubcontractExecSummarySubcontractGuaranteeView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_GUARANTEE_______VIEW',
  HomeSubcontractExecSummarySubcontractGuaranteeUpdate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_GUARANTEE_______UPDATE',
  HomeSubcontractExecSummarySubcontractGuaranteeCancel = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_GUARANTEE_______CANCEL',
  HomeSubcontractExecSummarySubcontractInsuracneCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INSURACNE_______CREATE',
  HomeSubcontractExecSummarySubcontractInsuracneView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INSURACNE_______VIEW',
  HomeSubcontractExecSummarySubcontractInsuracneCancel = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INSURACNE_______CANCEL',
  HomeSubcontractExecSummarySubcontractInsuracneUpdate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INSURACNE_______UPDATE',
  HomeSubcontractExecSummarySubcontractVoCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______CREATE',
  HomeSubcontractExecSummarySubcontractVoUpdate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______UPDATE',
  HomeSubcontractExecSummarySubcontractVoApprove = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______APPROVE',
  HomeSubcontractExecSummarySubcontractVoReject = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______REJECT',
  HomeSubcontractExecSummarySubcontractVoDraft = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______DRAFT',
  HomeSubcontractExecSummarySubcontractVoView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusSubcontractorProfileView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_SUBCONTRACTOR_PROFILE____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusAdvancesView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_ADVANCES____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusProgressClaimView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_PROGRESS_CLAIM____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusRetentionClaimView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_RETENTION_CLAIM____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusDebitNotesView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_DEBIT_NOTES____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusCreditNotesView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_CREDIT_NOTES____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusPaymentsView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_PAYMENTS____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusRefundsView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_REFUNDS____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusDnToSubconView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_DN_TO_SUBCON____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusOsPayableView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_OS_PAYABLE____VIEW',
  HomeSubcontractExecSummaryRetentionView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_RETENTION_______VIEW',
  HomeSubcontractExecSummaryRetentionCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_RETENTION_______CREATE',
  HomeSubcontractExecSummaryRetentionUpdate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_RETENTION_______UPDATE',
  HomeSubcontractExecSummaryRetentionAttachmentDownload = 'HOME_SUBCONTRACT_EXEC_SUMMARY_RETENTION_ATTACHMENT____DOWNLOAD',
  HomeSubcontractExecSummaryExtensionOfTimeView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_______VIEW',
  HomeSubcontractExecSummaryExtensionOfTimeCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_______CREATE',
  HomeSubcontractExecSummaryExtensionOfTimeAttachmentDownload = 'HOME_SUBCONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_ATTACHMENT____DOWNLOAD',
  HomeSubcontractExecSummarySubcontractClosureCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_CLOSURE_______CREATE',
  HomeSubcontractExecSummarySubcontractTerminationCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_TERMINATION_______CREATE',
  HomeSubcontractorAccountCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_____________CREATE',
  HomeSubcontractorAccountView = 'HOME_SUBCONTRACTOR_ACCOUNT_____________VIEW',
  HomeSubcontractorAccountExecSummaryView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY__________VIEW',
  HomeSubcontractorAccountExecSummarySubcontractorProfileView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_SUBCONTRACTOR_PROFILE_______VIEW',
  HomeSubcontractorAccountExecSummarySubcontractorProfileUpdate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_SUBCONTRACTOR_PROFILE_______UPDATE',
  HomeSubcontractorAccountExecSummaryAdvancesCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_ADVANCES_______CREATE',
  HomeSubcontractorAccountExecSummaryAdvancesView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_ADVANCES_______VIEW',
  HomeSubcontractorAccountExecSummaryAdvancesUpdate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_ADVANCES_______UPDATE',
  HomeSubcontractorAccountExecSummaryAdvancesCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_ADVANCES_______CANCEL',
  HomeSubcontractorAccountExecSummaryProgressClaimView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______VIEW',
  HomeSubcontractorAccountExecSummaryProgressClaimApprove = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______APPROVE',
  HomeSubcontractorAccountExecSummaryProgressClaimReject = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______REJECT',
  HomeSubcontractorAccountExecSummaryProgressClaimCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______CANCEL',
  HomeSubcontractorAccountExecSummaryProgressClaimCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______CREATE',
  HomeSubcontractorAccountExecSummaryProgressClaimResubmit = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______RESUBMIT',
  HomeSubcontractorAccountExecSummaryProgressClaimDraft = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______DRAFT',
  HomeSubcontractorAccountExecSummaryProgressClaimDownload = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______DOWNLOAD',
  HomeSubcontractorAccountExecSummaryRetentionClaimView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______VIEW',
  HomeSubcontractorAccountExecSummaryRetentionClaimApprove = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______APPROVE',
  HomeSubcontractorAccountExecSummaryRetentionClaimReject = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______REJECT',
  HomeSubcontractorAccountExecSummaryRetentionClaimCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______CANCEL',
  HomeSubcontractorAccountExecSummaryRetentionClaimCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______CREATE',
  HomeSubcontractorAccountExecSummaryRetentionClaimResubmit = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______RESUBMIT',
  HomeSubcontractorAccountExecSummaryRetentionClaimDraft = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______DRAFT',
  HomeSubcontractorAccountExecSummaryRetentionClaimDownload = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______DOWNLOAD',
  HomeSubcontractorAccountExecSummaryDebitNotesCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DEBIT_NOTES_______CREATE',
  HomeSubcontractorAccountExecSummaryDebitNotesView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DEBIT_NOTES_______VIEW',
  HomeSubcontractorAccountExecSummaryDebitNotesUpdate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DEBIT_NOTES_______UPDATE',
  HomeSubcontractorAccountExecSummaryDebitNotesCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DEBIT_NOTES_______CANCEL',
  HomeSubcontractorAccountExecSummaryCreditNotesCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_CREDIT_NOTES_______CREATE',
  HomeSubcontractorAccountExecSummaryCreditNotesAllocate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_CREDIT_NOTES_______ALLOCATE',
  HomeSubcontractorAccountExecSummaryCreditNotesUpdate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_CREDIT_NOTES_______UPDATE',
  HomeSubcontractorAccountExecSummaryCreditNotesView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_CREDIT_NOTES_______VIEW',
  HomeSubcontractorAccountExecSummaryCreditNotesCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_CREDIT_NOTES_______CANCEL',
  HomeSubcontractorAccountExecSummaryPaymentsCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______CREATE',
  HomeSubcontractorAccountExecSummaryPaymentsAttachmentDownload = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_ATTACHMENT____DOWNLOAD',
  HomeSubcontractorAccountExecSummaryPaymentsView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______VIEW',
  HomeSubcontractorAccountExecSummaryPaymentsDraft = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______DRAFT',
  HomeSubcontractorAccountExecSummaryPaymentsApprove = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______APPROVE',
  HomeSubcontractorAccountExecSummaryPaymentsReject = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______REJECT',
  HomeSubcontractorAccountExecSummaryPaymentsCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______CANCEL',
  HomeSubcontractorAccountExecSummaryDnToSubconView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DN_TO_SUBCON_______VIEW',
  HomeSubcontractorAccountExecSummaryDnToSubconUpdate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DN_TO_SUBCON_______UPDATE',
  HomeSubcontractorAccountExecSummaryDnToSubconCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DN_TO_SUBCON_______CANCEL',
  HomeSubcontractorAccountExecSummaryDnToSubconAttachmentDownload = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DN_TO_SUBCON_ATTACHMENT____DOWNLOAD',
  HomeSubcontractorAccountExecSummaryOsPayableView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_OS_PAYABLE_______VIEW',
  HomeProjectPurchasesContractListingView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING__________VIEW',
  HomeProjectPurchasesContractListingExecSummaryView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_______VIEW',
  HomeProjectPurchasesContractListingExecSummaryNegotiatedSupplyCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_NEGOTIATED_SUPPLY____CREATE',
  HomeProjectPurchasesContractListingExecSummaryNegotiatedSupplyView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_NEGOTIATED_SUPPLY____VIEW',
  HomeProjectPurchasesContractListingExecSummaryNegotiatedSupplyUpdate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_NEGOTIATED_SUPPLY____UPDATE',
  HomeProjectPurchasesContractListingExecSummaryNegotiatedSupplyCancel = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_NEGOTIATED_SUPPLY____CANCEL',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____CREATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____VIEW',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionUpdate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____UPDATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionCancel = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____CANCEL',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionApprove = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____APPROVE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionAttachmentDownload = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ATTACHMENT_DOWNLOAD',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionReject = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____REJECT',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER____VIEW',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER____CREATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderCancel = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER____CANCEL',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderApprove = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER____APPROVE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderReject = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER____REJECT',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderAttachmentDownload = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ATTACHMENT_DOWNLOAD',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____CREATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____VIEW',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfUpdate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____UPDATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfCancel = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____CANCEL',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfApprove = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____APPROVE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfAttachmentDownload = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF_ATTACHMENT_DOWNLOAD',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfReject = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____REJECT',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderOnbehalfView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ONBEHALF____VIEW',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderOnbehalfCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ONBEHALF____CREATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderOnbehalfCancel = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ONBEHALF____CANCEL',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderOnbehalfApprove = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ONBEHALF____APPROVE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderOnbehalfReject = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ONBEHALF____REJECT',
  HomeProjectPurchasesContractListingExecSummaryDeliveryOrderCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_DELIVERY_ORDER____CREATE',
  HomeProjectPurchasesContractListingExecSummaryDeliveryOrderView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_DELIVERY_ORDER____VIEW',
  HomeProjectPurchasesContractListingExecSummaryDeliveryOrderUpdate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_DELIVERY_ORDER____UPDATE',
  HomeSupplierAccountView = 'HOME_SUPPLIER_ACCOUNT_____________VIEW',
  HomeSupplierAccountCreate = 'HOME_SUPPLIER_ACCOUNT_____________CREATE',
  HomeSupplierAccountExecSummaryView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY__________VIEW',
  HomeSupplierAccountExecSummarySupplierProfileView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_PROFILE_______VIEW',
  HomeSupplierAccountExecSummarySupplierProfileUpdate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_PROFILE_______UPDATE',
  HomeSupplierAccountExecSummaryPrefSupplierProductsView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_PREF_SUPPLIER_PRODUCTS_______VIEW',
  HomeSupplierAccountExecSummaryPrefSupplierProductsCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_PREF_SUPPLIER_PRODUCTS_______CREATE',
  HomeSupplierAccountExecSummaryPrefSupplierProductsDelete = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_PREF_SUPPLIER_PRODUCTS_______DELETE',
  HomeSupplierAccountExecSummarySupplierAccountsView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_______VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____CREATE',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsCancel = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____CANCEL',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsAllocate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____ALLOCATE',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsAttachmentDownload = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS_ATTACHMENT_DOWNLOAD',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsApprove = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____APPROVE',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsReject = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____REJECT',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsInvoicesCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_INVOICES____CREATE',
  HomeSupplierAccountExecSummarySupplierAccountsInvoicesView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_INVOICES____VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsInvoicesCancel = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_INVOICES____CANCEL',
  HomeSupplierAccountExecSummarySupplierAccountsDebitNotesCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEBIT_NOTES____CREATE',
  HomeSupplierAccountExecSummarySupplierAccountsDebitNotesView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEBIT_NOTES____VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsDebitNotesUpdate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEBIT_NOTES____UPDATE',
  HomeSupplierAccountExecSummarySupplierAccountsDebitNotesCancel = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEBIT_NOTES____CANCEL',
  HomeSupplierAccountExecSummarySupplierAccountsCreditNotesCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_CREDIT_NOTES____CREATE',
  HomeSupplierAccountExecSummarySupplierAccountsCreditNotesView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_CREDIT_NOTES____VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsCreditNotesAllocate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_CREDIT_NOTES____ALLOCATE',
  HomeSupplierAccountExecSummarySupplierAccountsCreditNotesUpdate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_CREDIT_NOTES____UPDATE',
  HomeSupplierAccountExecSummarySupplierAccountsCreditNotesCancel = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_CREDIT_NOTES____CANCEL',
  HomeSupplierAccountExecSummarySupplierAccountsPaymentsCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_PAYMENTS____CREATE',
  HomeSupplierAccountExecSummarySupplierAccountsPaymentsUpdate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_PAYMENTS____UPDATE',
  HomeSupplierAccountExecSummarySupplierAccountsPaymentsView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_PAYMENTS____VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsPaymentsCancel = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_PAYMENTS____CANCEL',
  HomeSupplierAccountExecSummarySupplierAccountsOsPayableView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_OS_PAYABLE____VIEW',
  HomePlantAndMachineryExecSummaryView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY__________VIEW',
  HomePlantAndMachineryExecSummaryOwnedPnmView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_OWNED_PNM_______VIEW',
  HomePlantAndMachineryExecSummaryOwnedPnmCreate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_OWNED_PNM_______CREATE',
  HomePlantAndMachineryExecSummaryOwnedPnmUpdate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_OWNED_PNM_______UPDATE',
  HomePlantAndMachineryExecSummaryOwnedPnmAssignmentHistoryView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_OWNED_PNM_ASSIGNMENT_HISTORY____VIEW',
  HomePlantAndMachineryExecSummaryOwnedPnmMaintenanceHistoryView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_OWNED_PNM_MAINTENANCE_HISTORY____VIEW',
  HomePlantAndMachineryExecSummaryRentedPnmCreate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_RENTED_PNM_______CREATE',
  HomePlantAndMachineryExecSummaryRentedPnmView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_RENTED_PNM_______VIEW',
  HomePlantAndMachineryExecSummaryRentedPnmUpdate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_RENTED_PNM_______UPDATE',
  HomePlantAndMachineryExecSummaryRentedPnmReturn = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_RENTED_PNM_______RETURN',
  HomePlantAndMachineryExecSummaryAssignmentRecordsCreate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_ASSIGNMENT_RECORDS_______CREATE',
  HomePlantAndMachineryExecSummaryAssignmentRecordsView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_ASSIGNMENT_RECORDS_______VIEW',
  HomePlantAndMachineryExecSummaryAssignmentRecordsUpdate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_ASSIGNMENT_RECORDS_______UPDATE',
  HomePlantAndMachineryExecSummaryAssignmentRecordsCancel = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_ASSIGNMENT_RECORDS_______CANCEL',
  HomePlantAndMachineryExecSummaryAssignmentRecordsReturn = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_ASSIGNMENT_RECORDS_______RETURN',
  HomePlantAndMachineryExecSummaryMaintenanceRecordsCreate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_MAINTENANCE_RECORDS_______CREATE',
  HomePlantAndMachineryExecSummaryMaintenanceRecordsView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_MAINTENANCE_RECORDS_______VIEW',
  HomePlantAndMachineryExecSummaryMaintenanceRecordsUpdate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_MAINTENANCE_RECORDS_______UPDATE',
  HomePlantAndMachineryExecSummaryMaintenanceRecordsCancel = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_MAINTENANCE_RECORDS_______CANCEL',
  HomePlantAndMachineryExecSummaryMaintenanceRecordsReturn = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_MAINTENANCE_RECORDS_______RETURN',
  HomeBusinessInsightView = 'HOME_BUSINESS_INSIGHT_____________VIEW',
  HomeBusinessInsightContractListingProjectCostingView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_______VIEW',
  HomeBusinessInsightContractListingProjectCostingAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectCostingAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectCostingSubcontractCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_SUBCONTRACT_COST____VIEW',
  HomeBusinessInsightContractListingProjectCostingMaterialCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_MATERIAL_COST____VIEW',
  HomeBusinessInsightContractListingProjectCostingPmCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_PM_COST____VIEW',
  HomeBusinessInsightContractListingProjectCostingLabourCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_LABOUR_COST____VIEW',
  HomeBusinessInsightContractListingProjectCostingMiscCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_MISC_COST____VIEW',
  HomeBusinessInsightContractListingProjectPLAttachmentView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_ATTACHMENT____VIEW',
  HomeBusinessInsightContractListingProjectPLAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectPLAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectPLRevisedContractSumView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_REVISED_CONTRACT_SUM____VIEW',
  HomeBusinessInsightContractListingProjectPLProgressIncomeView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_PROGRESS_INCOME____VIEW',
  HomeBusinessInsightContractListingProjectPLSubcontractCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_SUBCONTRACT_COST____VIEW',
  HomeBusinessInsightContractListingProjectPLMaterialCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_MATERIAL_COST____VIEW',
  HomeBusinessInsightContractListingProjectPLPmCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_PM_COST____VIEW',
  HomeBusinessInsightContractListingProjectPLLabourCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_LABOUR_COST____VIEW',
  HomeBusinessInsightContractListingProjectPLMiscCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_MISC_COST____VIEW',
  HomeBusinessInsightContractListingProjectCashFlowAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectCashFlowAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectCashFlowView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_______VIEW',
  HomeBusinessInsightContractListingProjectCashFlowReceivedFromClientView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_RECEIVED_FROM_CLIENT____VIEW',
  HomeBusinessInsightContractListingProjectCashFlowPaidToSubcontractorView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_PAID_TO_SUBCONTRACTOR____VIEW',
  HomeBusinessInsightContractListingProjectCashFlowPaidToSupplierView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_PAID_TO_SUPPLIER____VIEW',
  HomeBusinessInsightContractListingProjectCashFlowOtherExpensesView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_OTHER_EXPENSES____VIEW',
  HomeBusinessInsightContractListingProjectCashFlowNetCashFlowView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_NET_CASH_FLOW____VIEW',
  HomeBusinessInsightContractListingProjectCostPerformanceView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COST_PERFORMANCE_______VIEW',
  HomeBusinessInsightContractListingProjectCostPerformanceAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COST_PERFORMANCE_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectCostPerformanceAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COST_PERFORMANCE_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectCostPerformanceCompletionPercCreate = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COST_PERFORMANCE_COMPLETION_PERC____CREATE',
  HomeBusinessInsightContractListingProjectFinancialStatusView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_______VIEW',
  HomeBusinessInsightContractListingProjectFinancialStatusAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectFinancialStatusAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectFinancialStatusCompletionPercCreate = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_COMPLETION_PERC____CREATE',
  HomeBusinessInsightContractListingProjectFinancialStatusBillingToDateView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_BILLING_TO_DATE____VIEW',
  HomeBusinessInsightContractListingProjectFinancialStatusCollectionToDateView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_COLLECTION_TO_DATE____VIEW',
  HomeBusinessInsightContractListingProjectFinancialStatusCostToDateView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_COST_TO_DATE____VIEW',
  HomeBusinessInsightContractListingProjectFinancialStatusPaymentToDateView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_PAYMENT_TO_DATE____VIEW',
  HomeBusinessInsightContractListingProjectBudgetAnalysisAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_BUDGET_ANALYSIS_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectBudgetAnalysisAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_BUDGET_ANALYSIS_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectBudgetAnalysisOriginalBudgetView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_BUDGET_ANALYSIS_ORIGINAL_BUDGET____VIEW',
  HomeBusinessInsightContractListingProjectBudgetAnalysisRevisedBudgetView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_BUDGET_ANALYSIS_REVISED_BUDGET____VIEW',
  HomeBusinessInsightContractListingProjectBudgetAnalysisBudgetVarianceView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_BUDGET_ANALYSIS_BUDGET_VARIANCE____VIEW',
  HomeBusinessInsightContractListingBudgetVsActualAnalysisView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_BUDGET_VS_ACTUAL_ANALYSIS_______VIEW',
  HomeBusinessInsightContractListingBudgetVsActualAnalysisAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_BUDGET_VS_ACTUAL_ANALYSIS_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingBudgetVsActualAnalysisAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_BUDGET_VS_ACTUAL_ANALYSIS_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingContractBudgetOriginalBudgetView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_CONTRACT_BUDGET_ORIGINAL_BUDGET____VIEW',
  HomeBusinessInsightContractListingContractBudgetOriginalBudgetCreate = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_CONTRACT_BUDGET_ORIGINAL_BUDGET____CREATE',
  HomeBusinessInsightContractListingContractBudgetRevisedBudgetView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_CONTRACT_BUDGET_REVISED_BUDGET____VIEW',
  HomeBusinessInsightContractListingContractBudgetRevisedBudgetCreate = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_CONTRACT_BUDGET_REVISED_BUDGET____CREATE',
  HomeSiteManagementSiteListingExecSummaryView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_______VIEW',
  HomeSiteManagementSiteListingExecSummarySiteWorkerAttendanceView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_WORKER_ATTENDANCE____VIEW',
  HomeSiteManagementSiteListingExecSummaryGrnView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_GRN____VIEW',
  HomeSiteManagementSiteListingExecSummaryGrnCreate = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_GRN____CREATE',
  HomeSiteManagementSiteListingExecSummaryGrnUpdate = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_GRN____UPDATE',
  HomeSiteManagementSiteListingExecSummarySiteStockTrackingView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_STOCK_TRACKING____VIEW',
  HomeSiteManagementSiteListingExecSummarySiteStockTrackingCreate = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_STOCK_TRACKING____CREATE',
  HomeSiteManagementSiteListingExecSummarySiteProgressCreate = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_PROGRESS____CREATE',
  HomeSiteManagementSiteListingExecSummarySiteProgressView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_PROGRESS____VIEW',
  HomeSiteManagementSiteListingExecSummarySiteProgressUpdate = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_PROGRESS____UPDATE',
  HomeSiteManagementSiteListingExecSummarySiteProgressDelete = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_PROGRESS____DELETE',
  HomeSiteManagementSiteListingExecSummaryLiveVideoCamView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_LIVE_VIDEO_CAM____VIEW',
  HomeSiteManagementSiteListingExecSummaryLiveVideoCamRecord = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_LIVE_VIDEO_CAM____RECORD',
  HomeDefectManagementContractListingView = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING__________VIEW',
  HomeDefectManagementContractListingDefectListingCreate = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING_DEFECT_LISTING_______CREATE',
  HomeDefectManagementContractListingDefectListingUpdate = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING_DEFECT_LISTING_______UPDATE',
  HomeDefectManagementContractListingDefectListingView = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING_DEFECT_LISTING_______VIEW',
  HomeDefectManagementContractListingDefectListingReAssignDefectCreate = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING_DEFECT_LISTING_RE_ASSIGN_DEFECT____CREATE',
  HomeDefectManagementContractListingDefectListingActivityLogCreate = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING_DEFECT_LISTING_ACTIVITY_LOG____CREATE',
  HomeGlInterfaceView = 'HOME_GL_INTERFACE_____________VIEW',
  HomeGlInterfaceAttachmentDownload = 'HOME_GL_INTERFACE__________ATTACHMENT_DOWNLOAD',
  HomeGlInterfaceContractView = 'HOME_GL_INTERFACE_CONTRACT__________VIEW',
  HomeGlInterfaceSubcontractView = 'HOME_GL_INTERFACE_SUBCONTRACT__________VIEW',
  HomeGlInterfaceSupplierView = 'HOME_GL_INTERFACE_SUPPLIER__________VIEW',
  HomeWorkDeskToDoView = 'HOME_WORK_DESK_TO_DO__________VIEW',
  HomeWorkDeskToDoApprove = 'HOME_WORK_DESK_TO_DO__________APPROVE',
  HomeWorkDeskToDoReject = 'HOME_WORK_DESK_TO_DO__________REJECT',
  HomeWorkDeskNotificationView = 'HOME_WORK_DESK_NOTIFICATION__________VIEW',
  GeneralSettingExecSummaryView = 'GENERAL_SETTING_EXEC_SUMMARY_____________VIEW',
  GeneralSettingExecSummaryPoliciesAndGuidelinesView = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES__________VIEW',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPrApprovalPolicyExecSummaryView = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PR_APPROVAL_POLICY_EXEC_SUMMARY____VIEW',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPrApprovalPolicyExecSummaryCreate = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PR_APPROVAL_POLICY_EXEC_SUMMARY____CREATE',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPrApprovalPolicyExecSummaryEdit = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PR_APPROVAL_POLICY_EXEC_SUMMARY____EDIT',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPoApprovalPolicyExecSummaryView = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PO_APPROVAL_POLICY_EXEC_SUMMARY____VIEW',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPoApprovalPolicyExecSummaryCreate = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PO_APPROVAL_POLICY_EXEC_SUMMARY____CREATE',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPoApprovalPolicyExecSummaryUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PO_APPROVAL_POLICY_EXEC_SUMMARY____UPDATE',
  GeneralSettingExecSummarySubcontractPackagesView = 'GENERAL_SETTING_EXEC_SUMMARY_SUBCONTRACT_PACKAGES__________VIEW',
  GeneralSettingExecSummarySubcontractPackagesCreate = 'GENERAL_SETTING_EXEC_SUMMARY_SUBCONTRACT_PACKAGES__________CREATE',
  GeneralSettingExecSummarySubcontractPackagesUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_SUBCONTRACT_PACKAGES__________UPDATE',
  GeneralSettingExecSummarySubcontractPackagesDelete = 'GENERAL_SETTING_EXEC_SUMMARY_SUBCONTRACT_PACKAGES__________DELETE',
  GeneralSettingExecSummaryPmCategoryView = 'GENERAL_SETTING_EXEC_SUMMARY_PM_CATEGORY__________VIEW',
  GeneralSettingExecSummaryPmCategoryCreate = 'GENERAL_SETTING_EXEC_SUMMARY_PM_CATEGORY__________CREATE',
  GeneralSettingExecSummaryPmCategoryUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_PM_CATEGORY__________UPDATE',
  GeneralSettingExecSummaryPmCategoryDelete = 'GENERAL_SETTING_EXEC_SUMMARY_PM_CATEGORY__________DELETE',
  GeneralSettingExecSummaryBuildingMaterialsCreate = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS__________CREATE',
  GeneralSettingExecSummaryBuildingMaterialsView = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS__________VIEW',
  GeneralSettingExecSummaryBuildingMaterialsUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS__________UPDATE',
  GeneralSettingExecSummaryBuildingMaterialsDelete = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS__________DELETE',
  GeneralSettingExecSummaryBuildingMaterialsItemListingCreate = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS_ITEM_LISTING_______CREATE',
  GeneralSettingExecSummaryBuildingMaterialsItemListingView = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS_ITEM_LISTING_______VIEW',
  GeneralSettingExecSummaryBuildingMaterialsItemListingUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS_ITEM_LISTING_______UPDATE',
  GeneralSettingExecSummaryBuildingMaterialsItemListingDelete = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS_ITEM_LISTING_______DELETE',
  GeneralSettingExecSummaryLabourCategoryCreate = 'GENERAL_SETTING_EXEC_SUMMARY_LABOUR_CATEGORY__________CREATE',
  GeneralSettingExecSummaryLabourCategoryView = 'GENERAL_SETTING_EXEC_SUMMARY_LABOUR_CATEGORY__________VIEW',
  GeneralSettingExecSummaryLabourCategoryUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_LABOUR_CATEGORY__________UPDATE',
  GeneralSettingExecSummaryLabourCategoryDelete = 'GENERAL_SETTING_EXEC_SUMMARY_LABOUR_CATEGORY__________DELETE',
  GeneralSettingExecSummaryMiscExpenseCreate = 'GENERAL_SETTING_EXEC_SUMMARY_MISC_EXPENSE__________CREATE',
  GeneralSettingExecSummaryMiscExpenseView = 'GENERAL_SETTING_EXEC_SUMMARY_MISC_EXPENSE__________VIEW',
  GeneralSettingExecSummaryMiscExpenseUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_MISC_EXPENSE__________UPDATE',
  GeneralSettingExecSummaryMiscExpenseDelete = 'GENERAL_SETTING_EXEC_SUMMARY_MISC_EXPENSE__________DELETE',
  GeneralSettingExecSummaryDocumentNumberingView = 'GENERAL_SETTING_EXEC_SUMMARY_DOCUMENT_NUMBERING__________VIEW',
  GeneralSettingExecSummaryDocumentNumberingCreate = 'GENERAL_SETTING_EXEC_SUMMARY_DOCUMENT_NUMBERING__________CREATE',
  GeneralSettingExecSummaryDocumentNumberingUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_DOCUMENT_NUMBERING__________UPDATE',
  GeneralSettingExecSummaryBankAccountView = 'GENERAL_SETTING_EXEC_SUMMARY_BANK_ACCOUNT__________VIEW',
  GeneralSettingExecSummaryBankAccountCreate = 'GENERAL_SETTING_EXEC_SUMMARY_BANK_ACCOUNT__________CREATE',
  GeneralSettingExecSummaryBankAccountUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_BANK_ACCOUNT__________UPDATE',
  GeneralSettingExecSummaryBankAccountDelete = 'GENERAL_SETTING_EXEC_SUMMARY_BANK_ACCOUNT__________DELETE',
  GeneralSettingPlatformCostCenterView = 'GENERAL_SETTING_PLATFORM_COST_CENTER__________VIEW',
  GeneralSettingPlatformCostCenterCreate = 'GENERAL_SETTING_PLATFORM_COST_CENTER__________CREATE',
  GeneralSettingPlatformCostCenterUpdate = 'GENERAL_SETTING_PLATFORM_COST_CENTER__________UPDATE',
  GeneralSettingPlatformCostCenterDelete = 'GENERAL_SETTING_PLATFORM_COST_CENTER__________DELETE',
  GeneralSettingExecSummaryUomView = 'GENERAL_SETTING_EXEC_SUMMARY_UOM__________VIEW',
  GeneralSettingPlatformGuaranteeTypeView = 'GENERAL_SETTING_PLATFORM_GUARANTEE_TYPE__________VIEW',
  GeneralSettingPlatformInsuranceTypeView = 'GENERAL_SETTING_PLATFORM_INSURANCE_TYPE__________VIEW',
  GeneralSettingExecSummaryTaxSetupView = 'GENERAL_SETTING_EXEC_SUMMARY_TAX_SETUP__________VIEW'
}

export type ContractPermissionEntity = {
  __typename?: 'ContractPermissionEntity';
  ID: Scalars['String'];
  permissionName: Scalars['String'];
  permissionID: Scalars['Float'];
};

export type ContractReceiptEntity = AuditEntity & {
  __typename?: 'ContractReceiptEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type ContractReceiptEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ContractReceiptInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt: Scalars['Float'];
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  bankAccountID: Scalars['String'];
  receiptStatus: ReceiptStatus;
  rejectedDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<BankDetailsInput>;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
};

export type ContractRefundEntity = AuditEntity & {
  __typename?: 'ContractRefundEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type ContractRefundEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ContractRetentionAllocationEntity = AuditEntity & {
  __typename?: 'ContractRetentionAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  allocationStatus: AllocationStatus;
};

export type ContractRetentionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  submittedDate: Scalars['String'];
  retentionAmt: Scalars['Float'];
  taxSchemeID: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
  customerID: Scalars['String'];
};

export enum ContractStatus {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Submit = 'SUBMIT',
  Rejected = 'REJECTED',
  Closed = 'CLOSED',
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Terminated = 'TERMINATED'
}

export type ContractToDoEntity = AuditEntity & {
  __typename?: 'ContractToDoEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  submitterID: Scalars['String'];
  docRefID: Scalars['String'];
  docRefTable: Scalars['String'];
  notifiedDate?: Maybe<Scalars['DateTime']>;
  subject: Scalars['String'];
  body: Scalars['String'];
  toDoStatus: ToDoStatus;
  approvalLevel?: Maybe<ApprovalLevel>;
  docRefAmt?: Maybe<Scalars['Float']>;
  approvalLog?: Maybe<Array<ContractApprovalLogEntity>>;
};

export type ContractToDoInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  senderID: Scalars['String'];
  recipientID: Scalars['String'];
  toDoType: ToDoType;
  documentID: Scalars['String'];
  docRefTable: Scalars['String'];
  subject: Scalars['String'];
  body: Scalars['String'];
  toDoStatus: ToDoStatus;
};

export type ContractTransactionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  contractID: Scalars['String'];
  customerID: Scalars['String'];
};

export type ContractTransactionItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  costClass?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  revenueCategoryID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  docAmt?: Maybe<Scalars['Float']>;
  baseAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  wbsID?: Maybe<Scalars['String']>;
};

export type ContractTransactionWithItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  contractID: Scalars['String'];
  customerID: Scalars['String'];
  isEInvoice?: Maybe<Scalars['Boolean']>;
  eInvoiceDocRefInvoiceID?: Maybe<Scalars['String']>;
  glItem?: Maybe<Array<ContractTransactionItemInput>>;
};

export type ContractVo = {
  __typename?: 'ContractVO';
  voSum?: Maybe<Scalars['Float']>;
  voOmission?: Maybe<Scalars['Float']>;
  voSumCount?: Maybe<Scalars['Float']>;
  voOmissionCount?: Maybe<Scalars['Float']>;
  totalVoAmt?: Maybe<Scalars['Float']>;
  voClientCount?: Maybe<Scalars['Float']>;
  voSubconCount?: Maybe<Scalars['Float']>;
};

export type ContractVoActionInput = {
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  docDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  VOStatus: VoStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ContractVoDeleteInput = {
  ID?: Maybe<Scalars['String']>;
};

export type ContractVoDetailsInput = {
  ID?: Maybe<Scalars['String']>;
  contractVOID?: Maybe<Scalars['String']>;
  wbsID: Scalars['String'];
  revenueCategoryID: Scalars['String'];
  submissionAmt: Scalars['Float'];
};

export type ContractVoEntity = AuditEntity & {
  __typename?: 'ContractVOEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef: Scalars['String'];
  rejectedDate?: Maybe<Scalars['String']>;
  submittedDate: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  architectNo: Scalars['String'];
  engineeringNo: Scalars['String'];
  VOType: VoType;
  VOStatus: VoStatus;
  submissionAmt?: Maybe<Scalars['Float']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  contract: ContractEntity;
  contractClaimVOAllocation?: Maybe<Array<ContractClaimVoAllocationEntity>>;
  contractVORevenueWbs?: Maybe<Array<ContractVoRevenueWbsEntity>>;
  toDo: Array<ContractToDoEntity>;
  /** CustomFieldResolver */
  contractClaimVOAlloc?: Maybe<Scalars['JSON']>;
};

export type ContractVoInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  description: Scalars['String'];
  architectNo: Scalars['String'];
  engineeringNo: Scalars['String'];
  submissionAmt: Scalars['Float'];
  submittedDate: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  VOType?: Maybe<VoType>;
  VOStatus: VoStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
  contractID: Scalars['String'];
};

export type ContractVoRevenueWbsEntity = AuditEntity & {
  __typename?: 'ContractVORevenueWbsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractVOID: Scalars['String'];
  contractVO?: Maybe<ContractVoEntity>;
  wbsBudgetDetailID?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
  wbsID?: Maybe<Scalars['String']>;
  wbs?: Maybe<WbsEntity>;
  revenueCategoryID?: Maybe<Scalars['String']>;
  revenueCategory?: Maybe<RevenueCategoryEntity>;
  submissionAmt?: Maybe<Scalars['Float']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  contractClaimVORevenueWbsAllocation?: Maybe<Array<ContractClaimVoRevenueWbsAllocationEntity>>;
  /** CustomFieldResolver */
  previousRevenueVOAmt?: Maybe<Scalars['JSON']>;
  latestWbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type ContractVoRevenueWbsEntityLatestWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type CopyBudgetInput = {
  copyFromBudgetID: Scalars['String'];
  contractID: Scalars['String'];
};

export type CoreAllocationEntity = AuditEntity & {
  __typename?: 'CoreAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
};

export type CoreApprovalLogEntity = AuditEntity & {
  __typename?: 'CoreApprovalLogEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  toDoID: Scalars['String'];
  approverID: Scalars['String'];
  logDate?: Maybe<Scalars['DateTime']>;
  notifiedDate?: Maybe<Scalars['DateTime']>;
  toDoStatus: ToDoStatus;
  remarks?: Maybe<Scalars['String']>;
};

export type CoreAssociateEntity = AuditEntity & {
  __typename?: 'CoreAssociateEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  tinNo?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  address: Scalars['JSON'];
  platformAccess: Scalars['Boolean'];
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  associatedStatus: AssociatedStatus;
  eInvIntegrated: Scalars['Boolean'];
  eInvoiceType: EInvoiceType;
};

export type CoreAssociateInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  tinNo?: Maybe<Scalars['String']>;
  eInvIntegrated?: Maybe<Scalars['Boolean']>;
  eInvoiceType?: Maybe<EInvoiceType>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<Array<ContactInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
  attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreBaseEntity = AuditEntity & {
  __typename?: 'CoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export type CoreClaimAllocationEntity = AuditEntity & {
  __typename?: 'CoreClaimAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  allocationStatus: AllocationStatus;
};

export type CoreClaimAllocationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
};

export type CoreClaimEntity = AuditEntity & {
  __typename?: 'CoreClaimEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  claimDocNo?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  claimAmt?: Maybe<Scalars['Float']>;
  sequenceNo?: Maybe<Scalars['Float']>;
  revisedNo?: Maybe<Scalars['Float']>;
  submittedDate: Scalars['String'];
  voDisplayAmt?: Maybe<Scalars['Float']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  balanceAdvanceAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  balanceDeductionAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  claimVOLog?: Maybe<Array<Scalars['JSON']>>;
  isRetention: Scalars['Boolean'];
};

export type CoreClaimInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  remarks?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['String']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentAdvBalance?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  currentDeductionBalance?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  voDisplayAmt?: Maybe<Scalars['Float']>;
};

export type CoreClaimRetentionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docDate: Scalars['String'];
  retentionAmt: Scalars['Float'];
  taxSchemeID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmt: Scalars['Float'];
  docDueDate?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreClaimVoAllocationEntity = AuditEntity & {
  __typename?: 'CoreClaimVOAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  allocationStatus: AllocationStatus;
};

export type CoreClaimVoAllocationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
};

export type CoreConsubconActionInput = {
  closureRemarks?: Maybe<Scalars['String']>;
  closureDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconCertificateInput = {
  cmgdDate?: Maybe<Scalars['String']>;
  cpcDate?: Maybe<Scalars['String']>;
  dlpDate?: Maybe<Scalars['String']>;
  currentSubmittedStatus?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconExtensionInput = {
  extensionDate?: Maybe<ExtensionDateInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconGuarInsCancellationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  remarks?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconGuarInsInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  issuer: Scalars['String'];
  description: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  title: Scalars['String'];
  description: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  progressRetentionPerc: Scalars['Float'];
  maxRetentionPerc: Scalars['Float'];
  creditTerm?: Maybe<Scalars['Float']>;
  ladAmt?: Maybe<Scalars['Float']>;
  ladRate?: Maybe<RateUomType>;
  claimRunNum?: Maybe<Scalars['Float']>;
  claimPrefix?: Maybe<Scalars['String']>;
  voRunNum?: Maybe<Scalars['Float']>;
  voPrefix?: Maybe<Scalars['String']>;
  targetProfitPerc?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Array<ContactPersonInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconRetentionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  submittedDate: Scalars['String'];
  retentionAmt: Scalars['Float'];
  taxSchemeID: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconVoActionInput = {
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  docDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  VOStatus: VoStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconVoDeleteInput = {
  ID?: Maybe<Scalars['String']>;
};

export type CoreConsubconVoInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  description: Scalars['String'];
  architectNo: Scalars['String'];
  engineeringNo: Scalars['String'];
  submissionAmt: Scalars['Float'];
  submittedDate: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  VOType?: Maybe<VoType>;
  VOStatus: VoStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreContractEntity = AuditEntity & {
  __typename?: 'CoreContractEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  progressRetentionPerc: Scalars['Float'];
  maxRetentionPerc: Scalars['Float'];
  ladAmt?: Maybe<Scalars['Float']>;
  ladRate?: Maybe<RateUomType>;
  targetProfitPerc?: Maybe<Scalars['Float']>;
  cmgdDate?: Maybe<Scalars['String']>;
  cpcDate?: Maybe<Scalars['String']>;
  dlpDate?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
  extendedDate?: Maybe<Scalars['String']>;
  extensionDate?: Maybe<Scalars['JSON']>;
  closureDate?: Maybe<Scalars['String']>;
  closureRemarks?: Maybe<Scalars['String']>;
  completionPerc?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Array<Scalars['JSON']>>;
};

export type CoreDocTypeEntity = AuditEntity & {
  __typename?: 'CoreDocTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  docName: Scalars['String'];
  commonStatus: CommonStatus;
};

export type CoreEInvoiceTransactionEntity = AuditEntity & {
  __typename?: 'CoreEInvoiceTransactionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
};

export type CoreEntityRoleUserAssignmentEntity = AuditEntity & {
  __typename?: 'CoreEntityRoleUserAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
};

export type CoreGuarInsEntity = AuditEntity & {
  __typename?: 'CoreGuarInsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
};

export type CoreGuaranteeEntity = AuditEntity & {
  __typename?: 'CoreGuaranteeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  guaranteeTypeID: Scalars['String'];
  collateralAmt: Scalars['Float'];
  guaranteeStatus: GrntInsStatus;
};

export type CoreInsuranceEntity = AuditEntity & {
  __typename?: 'CoreInsuranceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  insuranceTypeID: Scalars['String'];
  insuranceStatus: GrntInsStatus;
};

export type CoreNewsEntity = AuditEntity & {
  __typename?: 'CoreNewsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  postStatus: PostStatus;
  image?: Maybe<Scalars['String']>;
};

export type CoreNewsReadEntity = AuditEntity & {
  __typename?: 'CoreNewsReadEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  userID: Scalars['String'];
};

export type CoreNotiRoleUserAssignmentEntity = AuditEntity & {
  __typename?: 'CoreNotiRoleUserAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  notiPolicyID: Scalars['String'];
  roleID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
};

export type CoreNotificationEntity = AuditEntity & {
  __typename?: 'CoreNotificationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  recipientID?: Maybe<Scalars['String']>;
  senderID?: Maybe<Scalars['String']>;
  docRefID: Scalars['String'];
  docRefTable: Scalars['String'];
  subject: Scalars['String'];
  body: Scalars['String'];
  readStatus: ReadStatus;
};

export type CoreNotificationGuidelineEntity = AuditEntity & {
  __typename?: 'CoreNotificationGuidelineEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRefTable: Scalars['String'];
  commonStatus: CommonStatus;
};

export type CoreNotificationGuidelineInput = {
  roleID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
};

export type CoreNumberFormatEntity = AuditEntity & {
  __typename?: 'CoreNumberFormatEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docTypeID: Scalars['String'];
  numberFormat: Scalars['String'];
  numberLength: Scalars['Float'];
  prefix: Scalars['String'];
  commonStatus: CommonStatus;
};

export type CorePermissionEntity = {
  __typename?: 'CorePermissionEntity';
  index: Scalars['String'];
  name: Scalars['String'];
};

export type CorePolicyAssignmentInput = {
  roleID: Scalars['String'];
  approvalLevel: ApprovalLevel;
};

export type CorePolicyGuidelineEntity = AuditEntity & {
  __typename?: 'CorePolicyGuidelineEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  docRefTable: Scalars['String'];
  commonStatus: CommonStatus;
};

export type CorePolicyGuidelineInput = {
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  docRefTable: Scalars['String'];
};

export type CorePolicyRoleAssignmentEntity = AuditEntity & {
  __typename?: 'CorePolicyRoleAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  policyID: Scalars['String'];
  roleID: Scalars['String'];
  approvalLevel: ApprovalLevel;
};

export type CoreReceiptPaymentEntity = AuditEntity & {
  __typename?: 'CoreReceiptPaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
};

export type CoreReceiptPaymentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt: Scalars['Float'];
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  bankAccountID: Scalars['String'];
  receiptStatus: ReceiptStatus;
  rejectedDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<BankDetailsInput>;
};

export type CoreRoleEntity = AuditEntity & {
  __typename?: 'CoreRoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
};

export type CoreRolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'CoreRolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
};

export type CoreRunningNumberSetupEntity = AuditEntity & {
  __typename?: 'CoreRunningNumberSetupEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  numberFormatID: Scalars['String'];
  nextNumber: Scalars['Float'];
};

export type CoreToDoEntity = AuditEntity & {
  __typename?: 'CoreToDoEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  submitterID: Scalars['String'];
  docRefID: Scalars['String'];
  docRefTable: Scalars['String'];
  notifiedDate?: Maybe<Scalars['DateTime']>;
  subject: Scalars['String'];
  body: Scalars['String'];
  toDoStatus: ToDoStatus;
  approvalLevel?: Maybe<ApprovalLevel>;
};

export type CoreTransactionEntity = AuditEntity & {
  __typename?: 'CoreTransactionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
};

export type CoreTransactionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
};

export type CoreVoEntity = AuditEntity & {
  __typename?: 'CoreVOEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef: Scalars['String'];
  rejectedDate?: Maybe<Scalars['String']>;
  submittedDate: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  architectNo: Scalars['String'];
  engineeringNo: Scalars['String'];
  VOType: VoType;
  VOStatus: VoStatus;
  submissionAmt?: Maybe<Scalars['Float']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
};

export type CostAdjItemEntity = AuditEntity & {
  __typename?: 'CostAdjItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  costItemID: Scalars['String'];
};

export type CostCategoryEntity = AuditEntity & {
  __typename?: 'CostCategoryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  parentCostCategoryID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  glAccountCode?: Maybe<Scalars['String']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  pnmCostCat?: Maybe<Scalars['JSON']>;
  isLastNode: Scalars['Boolean'];
  tankType: TankType;
  fuelConsumption?: Maybe<Scalars['JSON']>;
  creditTerm?: Maybe<Scalars['Float']>;
  costClass: CostClass;
  commonStatus: CommonStatus;
  costToDate?: Maybe<Scalars['Float']>;
  committedCost?: Maybe<Scalars['Float']>;
  supplierConnection: Array<SupplierCCategoryEntity>;
  contractConnection: Array<ProjectBudgetEntity>;
  workshopConnection?: Maybe<Array<PnmCategoryWorkshopEntity>>;
  subcontractTrade: Array<SubcontractEntity>;
  miscExpenses?: Maybe<Array<MiscExpenseEntity>>;
  fromWbsBudgetTransferDetail?: Maybe<Array<WbsBudgetTransferDetailEntity>>;
  toWbsBudgetTransferDetail?: Maybe<Array<WbsBudgetTransferDetailEntity>>;
  costItem?: Maybe<Array<CostItemEntity>>;
  plantMachinery?: Maybe<Array<PlantMachineryEntity>>;
  costSubcontractWbs?: Maybe<Array<SubcontractCostWbsEntity>>;
  assignment?: Maybe<Array<AssignmentEntity>>;
  budget: Array<ProjectBudgetEntity>;
  /** CustomFieldResolver */
  plantMachineryStatus?: Maybe<PmStatus>;
  subconTender: Array<SubcontractTenderEntity>;
  pnmRental?: Maybe<Array<PnmRentalEntity>>;
  defect?: Maybe<Array<DefectEntity>>;
  wbsBudgetDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  wbsUtilizedBudget?: Maybe<Array<WbsUtilizedBudgetEntity>>;
  subcontractorPackage?: Maybe<Array<SubcontractorCCategoryEntity>>;
  subcontractor?: Maybe<Array<SubcontractorEntity>>;
  supplier?: Maybe<Array<SupplierEntity>>;
  projectExpenseItem?: Maybe<Array<ProjectExpenseItemEntity>>;
  wbsDaywork?: Maybe<Array<WbsDayworkCostRevenueEntity>>;
  /** CustomFieldResolver */
  parentName?: Maybe<Scalars['String']>;
  classificationCode?: Maybe<EInvoiceClassificationEntity>;
  parentCostCategory?: Maybe<CostCategoryEntity>;
  childrenCostCategory?: Maybe<Array<CostCategoryEntity>>;
  subcontract: Array<SubcontractEntity>;
  /** CustomFieldResolver */
  childrenIDs?: Maybe<Array<Scalars['String']>>;
};


export type CostCategoryEntityPlantMachineryArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type CostCategoryEntityBudgetArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type CostCategoryEntityPlantMachineryStatusArgs = {
  companyID?: Maybe<Scalars['String']>;
};

export type CostCategoryImportInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  sequence?: Maybe<Scalars['Float']>;
  costName?: Maybe<Scalars['String']>;
  parentCostName?: Maybe<Scalars['String']>;
  parentCostCategoryId?: Maybe<Scalars['String']>;
  costClass?: Maybe<Scalars['String']>;
  originalCostClass?: Maybe<Scalars['String']>;
  glAccountCode?: Maybe<Scalars['String']>;
  tankType?: Maybe<Scalars['String']>;
  consumptionMethod?: Maybe<Scalars['String']>;
  newCostCategory?: Maybe<Scalars['Boolean']>;
  roadTax?: Maybe<Scalars['Boolean']>;
  trackQty?: Maybe<Scalars['Boolean']>;
  warranty?: Maybe<Scalars['Boolean']>;
  insurance?: Maybe<Scalars['Boolean']>;
  isTank?: Maybe<Scalars['Boolean']>;
  lowerRange?: Maybe<Scalars['Float']>;
  upperRange?: Maybe<Scalars['Float']>;
};

export type CostCategoryInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
  costItems?: Maybe<Array<CostItems>>;
  costClass?: Maybe<CostClass>;
  tankType?: Maybe<TankType>;
  pnmCostCat?: Maybe<PnmCostCatInput>;
  fuelConsumption?: Maybe<FuelConsumptionInput>;
};

export type CostCategoryUpdateInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
};

export type CostCenterDeleteInput = {
  ID: Scalars['String'];
};

export type CostCenterEntity = AuditEntity & {
  __typename?: 'CostCenterEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  code: Scalars['String'];
  description: Scalars['String'];
  costCenterType: CostCenterType;
  commonStatus: CommonStatus;
  contract?: Maybe<Array<ContractEntity>>;
};

export type CostCenterInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  code: Scalars['String'];
  description: Scalars['String'];
  costCenterType?: Maybe<CostCenterType>;
};

export enum CostCenterType {
  Company = 'COMPANY',
  Contract = 'CONTRACT',
  Bank = 'BANK'
}

export enum CostClass {
  Main = 'MAIN',
  Subcontract = 'SUBCONTRACT',
  Labour = 'LABOUR',
  Misc = 'MISC',
  Material = 'MATERIAL',
  Plant = 'PLANT'
}

export type CostCodeEntity = AuditEntity & {
  __typename?: 'CostCodeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  parentCostCodeID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  level: Scalars['Float'];
  isLastNode: Scalars['Boolean'];
};

export type CostCodeInput = {
  parentCostCodeID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Float']>;
  isLastNode?: Maybe<Scalars['Boolean']>;
};

export type CostEInvoiceAdjItemEntity = AuditEntity & {
  __typename?: 'CostEInvoiceAdjItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID: Scalars['String'];
};

export type CostItemDeleteInput = {
  ID?: Maybe<Scalars['String']>;
};

export type CostItemEntity = AuditEntity & {
  __typename?: 'CostItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costCategoryID: Scalars['String'];
  costCategory?: Maybe<CostCategoryEntity>;
  uomID?: Maybe<Scalars['String']>;
  UOM?: Maybe<UomEntity>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxDetail?: Maybe<ConTaxSchemeEntity>;
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  lastPurchasePrice?: Maybe<Scalars['Float']>;
  variancePerc?: Maybe<Scalars['Float']>;
  markupPerc?: Maybe<Scalars['Float']>;
  onBehalf: Scalars['Boolean'];
  isInventory: Scalars['Boolean'];
  isDaywork: Scalars['Boolean'];
  commonStatus: CommonStatus;
  /** CustomFieldResolver */
  latestTax?: Maybe<TaxObject>;
  poItem?: Maybe<Array<PoItemEntity>>;
  siteMaterialPolicy?: Maybe<Array<SiteMaterialPolicyEntity>>;
  siteMaterialContractPolicy?: Maybe<Array<SiteMaterialContractPolicyEntity>>;
  prItem?: Maybe<Array<PrItemEntity>>;
  apInvoiceItem?: Maybe<Array<ApInvoiceItemEntity>>;
  APDebitNoteItem?: Maybe<Array<ApDebitNoteItemEntity>>;
  APCreditNoteItem?: Maybe<Array<ApCreditNoteItemEntity>>;
  DNtoSupplierItem?: Maybe<Array<DNtoSupplierItemEntity>>;
  CNtoSupplierItem?: Maybe<Array<CNtoSupplierItemEntity>>;
  grnItem?: Maybe<Array<GrnItemEntity>>;
  negotiatedSupplyItem?: Maybe<Array<NegotiatedSupplyItemEntity>>;
  siteStockLedger?: Maybe<Array<SiteStockLedgerEntity>>;
  supplierConnection: Array<PricingSupplierEntity>;
  stockMovement?: Maybe<Array<StockMovementEntity>>;
  fromWbsBudgetTransferDetail?: Maybe<Array<WbsBudgetTransferDetailEntity>>;
  toWbsBudgetTransferDetail?: Maybe<Array<WbsBudgetTransferDetailEntity>>;
  wbsBudgetDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  contractAccount?: Maybe<Array<ContractAccountEntity>>;
  projectExpenseItem?: Maybe<Array<ProjectExpenseItemEntity>>;
  subcontractMiscInvoiceItem?: Maybe<Array<SubcontractMiscInvoiceItemEntity>>;
  subcontractDNItem?: Maybe<Array<SubcontractDnItemEntity>>;
  subcontractCNItem?: Maybe<Array<SubcontractCnItemEntity>>;
  DNtoSubconItem?: Maybe<Array<DNtoSubconItemEntity>>;
  CNtoSubconItem?: Maybe<Array<CNtoSubconItemEntity>>;
  clientDNItem?: Maybe<Array<ClientDnItemEntity>>;
  clientCNItem?: Maybe<Array<ClientCnItemEntity>>;
  wbsDaywork?: Maybe<Array<WbsDayworkCostRevenueEntity>>;
  /** CustomFieldResolver */
  latestPurchase?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  isInventoryChecking?: Maybe<Scalars['Boolean']>;
};


export type CostItemEntityLatestTaxArgs = {
  docDate?: Maybe<Scalars['String']>;
};

export type CostItemImportInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  sequence?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['String']>;
  costName?: Maybe<Scalars['String']>;
  uom?: Maybe<Scalars['String']>;
  newCostItem?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  variancePercentage?: Maybe<Scalars['Float']>;
  onBehalf?: Maybe<Scalars['Boolean']>;
  markupPercentage?: Maybe<Scalars['Float']>;
  isInventory?: Maybe<Scalars['Boolean']>;
};

export type CostItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  costCategoryID: Scalars['String'];
  uomID: Scalars['String'];
  name: Scalars['String'];
  unitPrice: Scalars['Float'];
  onBehalf: Scalars['Boolean'];
  isInventory: Scalars['Boolean'];
  isDaywork: Scalars['Boolean'];
  code?: Maybe<Scalars['String']>;
  markupPerc?: Maybe<Scalars['Float']>;
  variancePerc?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
};

export type CostItems = {
  costCategoryID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  onBehalf?: Maybe<Scalars['Boolean']>;
  markupPerc?: Maybe<Scalars['Float']>;
  code?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  variancePerc?: Maybe<Scalars['Float']>;
};

export type CostRevEInvoiceAdjItemEntity = AuditEntity & {
  __typename?: 'CostRevEInvoiceAdjItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  revenueCategoryID?: Maybe<Scalars['String']>;
};

export type CreateAuditInput = {
  createdBy?: Maybe<Scalars['String']>;
};

export type CreatePermRoleAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  permId: Scalars['String'];
  roleId: Scalars['String'];
};

export type CurrencyEntity = AuditEntity & {
  __typename?: 'CurrencyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  symbol: Scalars['String'];
  format: NumericFormatter;
  commonStatus: CommonStatus;
  company: Array<CompanyEntity>;
};

export type CurrencyExchangeEntity = AuditEntity & {
  __typename?: 'CurrencyExchangeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  fromCurrencyID: Scalars['String'];
  toCurrencyID: Scalars['String'];
  effectiveDate: Scalars['String'];
  fromAmt: Scalars['Float'];
  toAmt: Scalars['Float'];
  exchangeRate: Scalars['Float'];
  currencyLog?: Maybe<Array<Scalars['JSON']>>;
  /** CustomFieldResolver */
  fromCurrency: Scalars['JSON'];
  /** CustomFieldResolver */
  toCurrency: Scalars['JSON'];
};

export type CurrencyExchangeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  fromCurrencyID?: Maybe<Scalars['String']>;
  toCurrencyID?: Maybe<Scalars['String']>;
  effectiveDate?: Maybe<Scalars['String']>;
  fromAmt?: Maybe<Scalars['Float']>;
  toAmt?: Maybe<Scalars['Float']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  currencyLog?: Maybe<Array<ExchangeLogInput>>;
};

export type CurrencyNoteEntity = AuditEntity & {
  __typename?: 'CurrencyNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  symbol?: Maybe<Scalars['String']>;
  format: NumericFormatter;
};

export type CurrencySetupDeleteInput = {
  ID: Scalars['String'];
};

export type CurrencySetupInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  code: Scalars['String'];
  symbol?: Maybe<Scalars['String']>;
};

export type CustomerCompanyAssignmentEntity = AuditEntity & {
  __typename?: 'CustomerCompanyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  customerID: Scalars['String'];
  customer?: Maybe<CustomerEntity>;
};

export type CustomerCompanyAssignmentInput = {
  customerID: Scalars['String'];
  companyID: Scalars['String'];
};

export type CustomerDeleteInput = {
  ID: Scalars['String'];
  companyID?: Maybe<Scalars['String']>;
};

export type CustomerEntity = AuditEntity & {
  __typename?: 'CustomerEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  tinNo?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  address: Scalars['JSON'];
  platformAccess: Scalars['Boolean'];
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  associatedStatus: AssociatedStatus;
  eInvIntegrated: Scalars['Boolean'];
  eInvoiceType: EInvoiceType;
  taxDetail?: Maybe<ConTaxSchemeEntity>;
  genPaymentReminder: Scalars['Boolean'];
  contactPerson: Array<ContactEntity>;
  contract?: Maybe<Array<ContractEntity>>;
  followUp?: Maybe<Array<FollowUpEntity>>;
  collectionActivity?: Maybe<Array<CollectionActivityEntity>>;
  contractClaim?: Maybe<Array<ContractClaimEntity>>;
  customerMiscInvoice?: Maybe<Array<ContractMiscInvoiceEntity>>;
  customerDN?: Maybe<Array<ContractDnEntity>>;
  customerCN?: Maybe<Array<ContractCnEntity>>;
  customerClientDN?: Maybe<Array<ClientDnEntity>>;
  customerClientCN?: Maybe<Array<ClientCnEntity>>;
  customerReceipt?: Maybe<Array<ContractReceiptEntity>>;
  customerAdvance?: Maybe<Array<ContractAdvanceEntity>>;
  customerRefund?: Maybe<Array<ContractRefundEntity>>;
  paymentReminder?: Maybe<Array<DocReminderEntity>>;
  billInterest?: Maybe<Array<BillInterestEntity>>;
  customerCompanyAssignment?: Maybe<Array<CustomerCompanyAssignmentEntity>>;
  projectOpportunity?: Maybe<Array<ProjectOpportunityEntity>>;
  /** CustomFieldResolver */
  activeContract?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  overdueInvInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  overdueInvInfoNoBound?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  clientReceiptInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  clientAgingInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  OSClientAgingInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  latestCollectionActivity?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  consubconDropdown?: Maybe<Scalars['String']>;
};


export type CustomerEntityContractArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type CustomerEntityActiveContractArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type CustomerEntityOverdueInvInfoNoBoundArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type CustomerEntityClientAgingInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type CustomerEntityOsClientAgingInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type CustomerEntityConsubconDropdownArgs = {
  companyID?: Maybe<Scalars['String']>;
};

export type CustomerImportInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  tinNo?: Maybe<Scalars['String']>;
  eInvIntegrated?: Maybe<Scalars['Boolean']>;
  eInvoiceType?: Maybe<EInvoiceType>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<Array<ContactInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
  attachment?: Maybe<Array<Scalars['Upload']>>;
  genPaymentReminder?: Maybe<Scalars['Boolean']>;
  contactName: Scalars['String'];
  contactDesignation: Scalars['String'];
  contactEmail?: Maybe<Scalars['String']>;
  contactMobileNo: Scalars['String'];
};

export type CustomerInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  tinNo?: Maybe<Scalars['String']>;
  eInvIntegrated?: Maybe<Scalars['Boolean']>;
  eInvoiceType?: Maybe<EInvoiceType>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<Array<ContactInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
  attachment?: Maybe<Array<Scalars['Upload']>>;
  genPaymentReminder: Scalars['Boolean'];
  associateID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};

export type CustomerReminderEntity = AuditEntity & {
  __typename?: 'CustomerReminderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  paymentReminder1: Scalars['Float'];
  paymentReminder2: Scalars['Float'];
  paymentReminder3: Scalars['Float'];
  paymentReminder4: Scalars['Float'];
  minimumAmt: Scalars['Float'];
  reminderFor: ReminderFor;
  printedCopy?: Maybe<Scalars['Boolean']>;
  emailCopy?: Maybe<Scalars['Boolean']>;
  commonStatus: CommonStatus;
};

export type CustomerReminderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  paymentReminder1: Scalars['Float'];
  paymentReminder2: Scalars['Float'];
  paymentReminder3: Scalars['Float'];
  paymentReminder4: Scalars['Float'];
  minimumAmt: Scalars['Float'];
  reminderFor: ReminderFor;
  printedCopy?: Maybe<Scalars['Boolean']>;
  emailCopy?: Maybe<Scalars['Boolean']>;
};

export type DNtoSubconEntity = AuditEntity & {
  __typename?: 'DNtoSubconEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  markupAmt?: Maybe<Scalars['Float']>;
  markupTaxAmt?: Maybe<Scalars['Float']>;
  cancelledClaimAmt?: Maybe<Scalars['Float']>;
  cancelledRetentionAmt?: Maybe<Scalars['Float']>;
  chargeToRevenue: Scalars['Boolean'];
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  DNtoSubconItem?: Maybe<Array<DNtoSubconItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  claimInfo?: Maybe<SubcontractClaimEntity>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  transactionType?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type DNtoSubconEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type DNtoSubconItemEntity = AuditEntity & {
  __typename?: 'DNtoSubconItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  revenueCategoryID?: Maybe<Scalars['String']>;
  DNtoSubconID: Scalars['String'];
  DNtoSubcon?: Maybe<DNtoSubconEntity>;
  costItem?: Maybe<CostItemEntity>;
  revenueCategory?: Maybe<RevenueCategoryEntity>;
  wbs?: Maybe<WbsEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  markupAmt?: Maybe<Scalars['Float']>;
  DNtoSubconTaxItem?: Maybe<Array<DNtoSubconTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type DNtoSubconItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type DNtoSubconTaxItemEntity = AuditEntity & {
  __typename?: 'DNtoSubconTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  subcontractID: Scalars['String'];
  DNtoSubconItemID: Scalars['String'];
  DNtoSubconItem?: Maybe<DNtoSubconItemEntity>;
};

export type DNtoSupplierEntity = AuditEntity & {
  __typename?: 'DNtoSupplierEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  supplierID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  supplierAllocation: Array<ApAllocationEntity>;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  DNtoSupplierItem?: Maybe<Array<DNtoSupplierItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type DNtoSupplierEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type DNtoSupplierItemEntity = AuditEntity & {
  __typename?: 'DNtoSupplierItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID: Scalars['String'];
  DNtoSupplierID: Scalars['String'];
  DNtoSupplier?: Maybe<DNtoSupplierEntity>;
  wbs?: Maybe<WbsEntity>;
  costItem?: Maybe<CostItemEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  DNtoSupplierTaxItem?: Maybe<Array<DNtoSupplierTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type DNtoSupplierItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type DNtoSupplierTaxItemEntity = AuditEntity & {
  __typename?: 'DNtoSupplierTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  DNtoSupplierItemID: Scalars['String'];
  DNtoSupplierItem?: Maybe<DNtoSupplierItemEntity>;
};

export type DoActionInput = {
  remarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  cancelCloseRemark?: Maybe<Scalars['String']>;
  doStatus: DoStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type DoActionItemInput = {
  grtnID?: Maybe<Scalars['String']>;
  purchaseOrderID?: Maybe<Scalars['String']>;
  doItemID: Scalars['String'];
  poItemID?: Maybe<Scalars['String']>;
  grtnItemID?: Maybe<Scalars['String']>;
  acceptedQty: Scalars['Float'];
  deliveredQty?: Maybe<Scalars['Float']>;
  isInventory?: Maybe<Scalars['Boolean']>;
};

export type DoEntity = AuditEntity & {
  __typename?: 'DOEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  supplierID: Scalars['String'];
  supplier?: Maybe<SupplierEntity>;
  siteID?: Maybe<Scalars['String']>;
  site?: Maybe<SiteEntity>;
  deliveryAddress?: Maybe<Scalars['JSON']>;
  docDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  doNo: Scalars['String'];
  contactPerson: Scalars['JSON'];
  glExportID?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  cancelCloseRemark?: Maybe<Scalars['String']>;
  doStatus: DoStatus;
  /** CustomFieldResolver */
  DODetails?: Maybe<Scalars['JSON']>;
  packedDetail?: Maybe<Array<Scalars['JSON']>>;
  shippedDetail?: Maybe<Array<Scalars['JSON']>>;
  doItem?: Maybe<Array<DoItemEntity>>;
  poItemConnection: Array<DoItemEntity>;
  stockMovement?: Maybe<Array<StockMovementEntity>>;
  POs: Array<PurchaseOrderEntity>;
  GRTNs: Array<GoodReturnNoteEntity>;
  /** CustomFieldResolver */
  upToDateAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  DOdocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  deliveryStatus?: Maybe<Scalars['JSON']>;
};


export type DoEntityUpToDateAmtArgs = {
  costCategoryID?: Maybe<Scalars['String']>;
  taxInclusive?: Maybe<Scalars['Boolean']>;
};


export type DoEntityDOdocAmtArgs = {
  taxInclusive?: Maybe<Scalars['Boolean']>;
};

export type DoInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  supplierID: Scalars['String'];
  doNo: Scalars['String'];
  siteID: Scalars['String'];
  remarks: Scalars['String'];
  contractID: Scalars['String'];
  companyID: Scalars['String'];
  doStatus: DoStatus;
  docDate?: Maybe<Scalars['String']>;
  contactPerson?: Maybe<ContactPersonInput>;
  deliveryAddress?: Maybe<AddressInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type DoItem = {
  __typename?: 'DOItem';
  costItemID?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['Float']>;
};

export type DoItemEntity = AuditEntity & {
  __typename?: 'DOItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  deliveryOrderID: Scalars['String'];
  DO?: Maybe<DoEntity>;
  purchaseOrderID?: Maybe<Scalars['String']>;
  POs?: Maybe<PurchaseOrderEntity>;
  grtnID?: Maybe<Scalars['String']>;
  goodReturnNote?: Maybe<GoodReturnNoteEntity>;
  poItemID?: Maybe<Scalars['String']>;
  poItem?: Maybe<PoItemEntity>;
  grtnItemID?: Maybe<Scalars['String']>;
  grtnItem?: Maybe<GoodReturnNoteItemEntity>;
  acceptedQty: Scalars['Float'];
  deliveredQty?: Maybe<Scalars['Float']>;
  acceptedAmt?: Maybe<Scalars['Float']>;
  deliveredAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  doItemAmt?: Maybe<Scalars['Float']>;
  apInvoiceItem?: Maybe<Array<ApInvoiceItemEntity>>;
  goodReturnNoteItem?: Maybe<Array<GoodReturnNoteItemEntity>>;
  /** CustomFieldResolver */
  doItemSubmitted?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  upToDateQty?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  returnedQty?: Maybe<Scalars['Float']>;
};

export type DoItemInput = {
  grtnID?: Maybe<Scalars['String']>;
  purchaseOrderID?: Maybe<Scalars['String']>;
  POItemsInput: Array<PoItemsInput>;
};

export type DorpaResult = {
  __typename?: 'DORPAResult';
  supplierID?: Maybe<Scalars['String']>;
  doNo?: Maybe<Scalars['String']>;
  poNo?: Maybe<Scalars['String']>;
  descriptions?: Maybe<Scalars['String']>;
  deliveryDate?: Maybe<Scalars['String']>;
  deliveryAddress?: Maybe<Scalars['String']>;
  doItems?: Maybe<Array<DoItem>>;
};

export type DOsInput = {
  purchaseOrderID: Scalars['String'];
  poItemID: Scalars['String'];
  receivedQty: Scalars['Float'];
};


export enum DaysofWeek {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY'
}

export type DefectAssignmentEntity = AuditEntity & {
  __typename?: 'DefectAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  defectID: Scalars['String'];
  defect?: Maybe<DefectEntity>;
  subcontractorID: Scalars['String'];
  subcontractor?: Maybe<SubcontractorEntity>;
  assignmentDate: Scalars['String'];
  expectedDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
};

export type DefectEntity = AuditEntity & {
  __typename?: 'DefectEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  site?: Maybe<SiteEntity>;
  costCategoryID: Scalars['String'];
  trade?: Maybe<CostCategoryEntity>;
  reportedDate: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  expectedDate?: Maybe<Scalars['String']>;
  completionDate?: Maybe<Scalars['String']>;
  docNo: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  activityLog?: Maybe<Array<Scalars['JSON']>>;
  defectStatus: DefectStatus;
  defectAssignment?: Maybe<Array<DefectAssignmentEntity>>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
};

export type DefectLog = {
  __typename?: 'DefectLog';
  costCategoryID?: Maybe<Scalars['Float']>;
  contractID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdT?: Maybe<Scalars['DateTime']>;
  defectStatus?: Maybe<DefectStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type DefectManagementEntity = AuditEntity & {
  __typename?: 'DefectManagementEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractorID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  expectedDate?: Maybe<Scalars['String']>;
  docNo: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  defectLog?: Maybe<Array<Scalars['JSON']>>;
  defectStatus: DefectStatus;
  contract?: Maybe<ContractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
};

export enum DefectStatus {
  Open = 'OPEN',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED',
  InProgress = 'IN_PROGRESS',
  Resolved = 'RESOLVED',
  Assigned = 'ASSIGNED',
  Reassigned = 'REASSIGNED',
  SelfAssigned = 'SELF_ASSIGNED'
}

export type DeleteTaxInput = {
  id: Scalars['String'];
};

export type DeliveryOrder = {
  __typename?: 'DeliveryOrder';
  doNo?: Maybe<Scalars['String']>;
};

export type DepartmentGlInterfaceInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  glAccountCode?: Maybe<Scalars['String']>;
};

export type DiaryTypeEntity = AuditEntity & {
  __typename?: 'DiaryTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  followUp: Scalars['Boolean'];
  siteDiary?: Maybe<Array<SiteDiaryEntity>>;
  siteSafetyItem?: Maybe<Array<SiteSafetyItemEntity>>;
};

export type DigitalDocumentInput = {
  refTable: Scalars['String'];
  refID: Scalars['String'];
  associateID?: Maybe<Scalars['String']>;
  documentFolderID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  file: Array<Scalars['Upload']>;
};

export type DigitalDocuments = AuditEntity & {
  __typename?: 'DigitalDocuments';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  mediaType: Scalars['String'];
  refTable: RefTable;
  documentFolderID?: Maybe<Scalars['String']>;
  documentsFolder?: Maybe<DocumentFolderEntity>;
  associateID?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  bucketFileName?: Maybe<Scalars['String']>;
  opacity?: Maybe<Scalars['String']>;
  isPrivate: Scalars['Boolean'];
  folderExist: Scalars['Boolean'];
  commonStatus: CommonStatus;
  size: Scalars['String'];
};

export type DirectPoInput = {
  ID?: Maybe<Scalars['String']>;
  supplierID: Scalars['String'];
  totalAmt: Scalars['Float'];
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  purchaseReqID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  expectedDate?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  purchaseStatus?: Maybe<PurchaseStatus>;
  instructions?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  prGenerated?: Maybe<Scalars['Boolean']>;
  purchaseType?: Maybe<PurchaseType>;
  contactPerson?: Maybe<Scalars['JSON']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type DoGrnMatch = {
  __typename?: 'DoGrnMatch';
  ID?: Maybe<Scalars['String']>;
  doID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  doAmt?: Maybe<Scalars['Float']>;
  doDate?: Maybe<Scalars['DateTime']>;
};

export type DoGrnMatchInput = {
  ID?: Maybe<Scalars['String']>;
  doID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  doAmt?: Maybe<Scalars['Float']>;
  doDate?: Maybe<Scalars['DateTime']>;
};

export type DoPackedInput = {
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  packedByID?: Maybe<Scalars['String']>;
  packedByName?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type DoRejectInput = {
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
};

export type DoShippedInput = {
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  packedByID?: Maybe<Scalars['String']>;
  packedByName?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export enum DoStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Rejected = 'REJECTED',
  Approved = 'APPROVED',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED',
  Pendingclose = 'PENDINGCLOSE',
  New = 'NEW',
  Packed = 'PACKED'
}

export type DocNumDetailDeleteInput = {
  ID: Scalars['String'];
};

export type DocNumDetailEntity = AuditEntity & {
  __typename?: 'DocNumDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNumHeaderID: Scalars['String'];
  docNumHeader: DocNumHeaderEntity;
  sequence: Scalars['Float'];
  formatType: Scalars['String'];
  formatValue: Scalars['String'];
  startFrom?: Maybe<Scalars['Float']>;
  nextNumber?: Maybe<Scalars['Float']>;
  commonStatus: CommonStatus;
};

export type DocNumDetailInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docNumHeaderID?: Maybe<Scalars['String']>;
  sequence: Scalars['Float'];
  formatType: Scalars['String'];
  formatValue: Scalars['String'];
  nextNumber?: Maybe<Scalars['Float']>;
  startFrom?: Maybe<Scalars['Float']>;
};

export type DocNumHeaderDeleteInput = {
  ID: Scalars['String'];
};

export type DocNumHeaderEntity = AuditEntity & {
  __typename?: 'DocNumHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  contract?: Maybe<ContractEntity>;
  docTypeID?: Maybe<Scalars['String']>;
  docType?: Maybe<ConDocTypeEntity>;
  sampleOutput: Scalars['String'];
  commonStatus: CommonStatus;
  docNumDetail: Array<DocNumDetailEntity>;
  company?: Maybe<SubscriptionCompanyType>;
};

export type DocNumHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  docTypeID?: Maybe<Scalars['String']>;
  sampleOutput: Scalars['String'];
};

export type DocNumInput = {
  contractID?: Maybe<Scalars['String']>;
  nextNumber?: Maybe<Scalars['Float']>;
  prefix?: Maybe<Scalars['String']>;
  numberLength?: Maybe<Scalars['Float']>;
  runNumID?: Maybe<Scalars['String']>;
  numFormatID?: Maybe<Scalars['String']>;
};

export type DocRef = AuditEntity & {
  __typename?: 'DocRef';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
};

export type DocRefInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type DocReminderEntity = AuditEntity & {
  __typename?: 'DocReminderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  paymentReminderID: Scalars['String'];
  paymentReminder?: Maybe<PaymentReminderEntity>;
  customerID: Scalars['String'];
  customer?: Maybe<CustomerEntity>;
  docRefTable: Scalars['String'];
  docRefID: Scalars['String'];
  reminderSeq?: Maybe<Scalars['Float']>;
  outstandingAmt: Scalars['Float'];
};

export type DocReminderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  paymentReminderID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
  docRefID: Scalars['String'];
  reminderSeq: Scalars['Float'];
  outstandingAmt: Scalars['Float'];
};

export type DocTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docName: Scalars['String'];
  code: Scalars['String'];
  docCode: ContractDocNum;
};

export type DocumentFolderEntity = AuditEntity & {
  __typename?: 'DocumentFolderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  siteID: Scalars['String'];
  site?: Maybe<SiteEntity>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<DocumentsEntity>>;
};

export type DocumentFolderInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  siteID: Scalars['String'];
};

export type DocumentsEntity = AuditEntity & {
  __typename?: 'DocumentsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  mediaType: Scalars['String'];
  refTable: RefTable;
  documentFolderID?: Maybe<Scalars['String']>;
  documentsFolder?: Maybe<DocumentFolderEntity>;
  associateID?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  bucketFileName?: Maybe<Scalars['String']>;
  opacity?: Maybe<Scalars['String']>;
  isPrivate: Scalars['Boolean'];
  folderExist: Scalars['Boolean'];
  commonStatus: CommonStatus;
};

export type DocumentsInput = {
  fileType?: Maybe<FileType>;
  refTable?: Maybe<RefTable>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['Upload']>;
  status?: Maybe<CommonStatus>;
  bucketFileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
};

export type EInvoiceItem = AuditEntity & {
  __typename?: 'EInvoiceItem';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
};

export enum EInvoiceType {
  Individual = 'INDIVIDUAL',
  Company = 'COMPANY',
  Foreigner = 'FOREIGNER',
  Government = 'GOVERNMENT'
}

export type EditTaxInput = {
  id?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  code: Scalars['String'];
  description: Scalars['String'];
  classType: Scalars['String'];
  isClaimable: Scalars['Boolean'];
  schemeTypeId: Scalars['String'];
  taxDetail: Array<TaxDetail>;
  taxId: Scalars['String'];
  deletedId: Array<Scalars['String']>;
};

export type EntityRoleUserAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type EvalSelectInput = {
  rfqItemID?: Maybe<Scalars['String']>;
  rfqSubmissionItemID?: Maybe<Scalars['String']>;
};

export type ExchangeLog = {
  __typename?: 'ExchangeLog';
  exchangeRate?: Maybe<Scalars['Float']>;
  modTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['DateTime']>;
};

export type ExchangeLogInput = {
  exchangeRate?: Maybe<Scalars['Float']>;
  modTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['DateTime']>;
};

export type ExportedGlTransferResponse = {
  __typename?: 'ExportedGLTransferResponse';
  AccountID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  JournalTypeName?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  JournalDate?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  DepartmentCode?: Maybe<Scalars['String']>;
  MasterCOACode?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
};

export type ExtensionDate = {
  __typename?: 'ExtensionDate';
  remarks?: Maybe<Scalars['String']>;
  cncDate?: Maybe<Scalars['DateTime']>;
  extensionDate?: Maybe<Scalars['DateTime']>;
  eDocument?: Maybe<Scalars['String']>;
};

export type ExtensionDateInput = {
  remarks?: Maybe<Scalars['String']>;
  cncDate?: Maybe<Scalars['DateTime']>;
  extensionDate?: Maybe<Scalars['DateTime']>;
  eDocument?: Maybe<Scalars['String']>;
};

export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export type FieldTypeEntity = AuditEntity & {
  __typename?: 'FieldTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  qualificationChecklistItem?: Maybe<Array<QualificationChecklistItemEntity>>;
};

export type FileObjectType = {
  __typename?: 'FileObjectType';
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
};

export enum FileType {
  Image = 'IMAGE',
  Logo = 'LOGO',
  Pdf = 'PDF'
}

export type FinancialStatusDetails = {
  __typename?: 'FinancialStatusDetails';
  costToDateSum?: Maybe<Scalars['Float']>;
  subconBillingSum?: Maybe<Scalars['Float']>;
  supplierInvSum?: Maybe<Scalars['Float']>;
  miscExpSum?: Maybe<Scalars['Float']>;
  subconPaymentRefundSum?: Maybe<Scalars['Float']>;
  subconSuppAdvanceSum?: Maybe<Scalars['Float']>;
  supplierPaymentSumStatus?: Maybe<Scalars['Float']>;
  billToDateSum?: Maybe<Scalars['Float']>;
  collectionSum?: Maybe<Scalars['Float']>;
  clientAdvanceSum?: Maybe<Scalars['Float']>;
  advClientAmt?: Maybe<Scalars['Float']>;
  completionPercentage?: Maybe<Scalars['JSON']>;
  conBilling?: Maybe<Scalars['JSON']>;
  collectionListing?: Maybe<Scalars['JSON']>;
  clientAdvanceListing?: Maybe<Scalars['JSON']>;
  subconBilling?: Maybe<Scalars['JSON']>;
  supplierInvoice?: Maybe<Scalars['JSON']>;
  miscExpenseStatus?: Maybe<Scalars['JSON']>;
  subconPaymentRefund?: Maybe<Scalars['JSON']>;
  subconSuppAdvanceListing?: Maybe<Scalars['JSON']>;
  supplierPaymentStatus?: Maybe<Scalars['JSON']>;
  advClientListing?: Maybe<Scalars['JSON']>;
  miscExpense?: Maybe<Scalars['JSON']>;
  latestBillingDate?: Maybe<Scalars['DateTime']>;
  latestCollectionDate?: Maybe<Scalars['DateTime']>;
  latestSubconBillDate?: Maybe<Scalars['DateTime']>;
  latestSupplierInvDate?: Maybe<Scalars['DateTime']>;
  latestMiscExpStatusDate?: Maybe<Scalars['DateTime']>;
  latestSubconPaymentRefundDate?: Maybe<Scalars['DateTime']>;
  latestSupplierPaymentDateStatus?: Maybe<Scalars['DateTime']>;
  latestClientAdvanceDate?: Maybe<Scalars['DateTime']>;
  latestSubconSuppAdvanceDate?: Maybe<Scalars['DateTime']>;
};

export type FollowUpEntity = AuditEntity & {
  __typename?: 'FollowUpEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  customerID: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  contactPerson: Scalars['JSON'];
  followUpStatus: FollowUpStatus;
  customer?: Maybe<CustomerEntity>;
};

export type FollowUpInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  customerID: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  contactPerson: ContactPersonInput;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export enum FollowUpStatus {
  Call = 'CALL',
  Whatsapp = 'WHATSAPP',
  Audio = 'AUDIO',
  Email = 'EMAIL',
  Message = 'MESSAGE'
}

export type Forex = AuditEntity & {
  __typename?: 'Forex';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
};

export type ForexInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};

export enum Frequency {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY'
}

export type FuelConsumption = {
  __typename?: 'FuelConsumption';
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  consumptionMethod?: Maybe<Scalars['String']>;
};

export type FuelConsumptionInput = {
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  consumptionMethod?: Maybe<Scalars['String']>;
};

export enum FuelConsumptionMethod {
  Km = 'KM',
  Hour = 'HOUR'
}

export type FuelDischargeDeleteInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type FuelDischargeEntity = AuditEntity & {
  __typename?: 'FuelDischargeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  tankID: Scalars['String'];
  tankTable: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  dischargeDate: Scalars['DateTime'];
  docNo?: Maybe<Scalars['String']>;
  dischargeToTable: Scalars['String'];
  dischargeToID: Scalars['String'];
  currentDischarge: Scalars['Float'];
  totalDischarge: Scalars['Float'];
  operatorName: Scalars['String'];
  isMalfunction?: Maybe<Scalars['Boolean']>;
  remarks?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['JSON']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  approvalStatus: ApprovalStatus;
  /** CustomFieldResolver */
  tank?: Maybe<TankObjectType>;
};

export type FuelDischargeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  tankID: Scalars['String'];
  tankTable: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  file: Array<Scalars['Upload']>;
  dischargeDate: Scalars['DateTime'];
  dischargeToTable: Scalars['String'];
  dischargeToID: Scalars['String'];
  currentDischarge: Scalars['Float'];
  totalDischarge: Scalars['Float'];
  operatorName: Scalars['String'];
  companyID: Scalars['String'];
  isMalfunction: Scalars['Boolean'];
  attachment?: Maybe<Scalars['Upload']>;
  approvalStatus?: Maybe<ApprovalStatus>;
};

export type FuelDischargeUpdateStatus = {
  approvalStatus: ApprovalStatus;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  companyID: Scalars['String'];
};

export type FuelMeterReadingDeleteInput = {
  ID: Scalars['String'];
};

export type FuelMeterReadingEntity = AuditEntity & {
  __typename?: 'FuelMeterReadingEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  tankID: Scalars['String'];
  tankTable: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  docDate: Scalars['String'];
  lastSupply: Scalars['Float'];
  currentSupply: Scalars['Float'];
  totalSupply: Scalars['Float'];
  lastDischarge: Scalars['Float'];
  currentDischarge: Scalars['Float'];
  totalDischarge: Scalars['Float'];
  balanceCF: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  tank?: Maybe<TankObjectType>;
  /** CustomFieldResolver */
  unionTank?: Maybe<TankMachinery>;
};

export type FuelMeterReadingInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  tankID: Scalars['String'];
  tankTable: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  file: Array<Scalars['Upload']>;
  docDate: Scalars['String'];
  lastSupply: Scalars['Float'];
  currentSupply: Scalars['Float'];
  totalSupply: Scalars['Float'];
  lastDischarge: Scalars['Float'];
  currentDischarge: Scalars['Float'];
  totalDischarge: Scalars['Float'];
  balanceCF: Scalars['Float'];
};

export type FuelSupplyActionInput = {
  companyID: Scalars['String'];
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  approvalStatus: ApprovalStatus;
};

export type FuelSupplyDeleteInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type FuelSupplyEntity = AuditEntity & {
  __typename?: 'FuelSupplyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  tankID: Scalars['String'];
  tankTable: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  docDate: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  doNo: Scalars['String'];
  currentSupply: Scalars['Float'];
  totalSupply: Scalars['Float'];
  supplyByTable: Scalars['String'];
  supplyRefID: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['JSON']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  approvalStatus: ApprovalStatus;
  /** CustomFieldResolver */
  tank?: Maybe<TankObjectType>;
};

export type FuelSupplyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  tankID: Scalars['String'];
  tankTable: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  file: Array<Scalars['Upload']>;
  doNo: Scalars['String'];
  docDate: Scalars['String'];
  currentSupply: Scalars['Float'];
  totalSupply: Scalars['Float'];
  supplyByTable: Scalars['String'];
  supplyRefID: Scalars['String'];
  companyID: Scalars['String'];
  attachment?: Maybe<Scalars['Upload']>;
  approvalStatus?: Maybe<ApprovalStatus>;
};

export type FuelSupplyUpdateStatus = {
  ID: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  tankID: Scalars['String'];
  tankTable: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  file: Array<Scalars['Upload']>;
  approvalStatus?: Maybe<ApprovalStatus>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
};

export type FuelTankSetupDeleteInput = {
  ID: Scalars['String'];
};

export type FuelTankSetupInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  tankType: TankType;
  name: Scalars['String'];
  regNo: Scalars['String'];
  companyID: Scalars['String'];
  plantMachineryID: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  fuelConsumptionLowerLimit?: Maybe<Scalars['Float']>;
  fuelConsumptionUpperLimit?: Maybe<Scalars['Float']>;
  fuelConsumptionMethod: FuelConsumptionMethod;
  serialNo?: Maybe<Scalars['String']>;
  warrantyDate?: Maybe<Scalars['String']>;
  purchaseDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  attachment?: Maybe<Scalars['Upload']>;
};

export type GlAdjustmentDynamicEntity = AuditEntity & {
  __typename?: 'GLAdjustmentDynamicEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  adjustmentTypeID: Scalars['String'];
  adjustmentType?: Maybe<AdjustmentTypeEntity>;
  accountCode: Scalars['String'];
  commonStatus: CommonStatus;
};

export type GlAdjustmentDynamicInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  adjustmentTypeID: Scalars['String'];
  accountCode: Scalars['String'];
};

export type GlAssociationEntity = AuditEntity & {
  __typename?: 'GLAssociationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  commonStatus: CommonStatus;
  glInterfaceMaster?: Maybe<Array<GlInterfaceMasterEntity>>;
};

export type GlAssociationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
};

export type GlDetailLedgerBatchInput = {
  IDs: Array<Scalars['String']>;
  docRefTable: Scalars['String'];
};

export type GlExportDeleteInput = {
  ID: Scalars['String'];
};

export type GlExportDetailEntity = AuditEntity & {
  __typename?: 'GLExportDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  glExportID: Scalars['String'];
  accountCode: Scalars['String'];
  type: Scalars['String'];
  associateRefTable: Scalars['String'];
  docRefTable: Scalars['String'];
  amount: Scalars['Float'];
  negative: Scalars['Boolean'];
  commonStatus: CommonStatus;
  glExport?: Maybe<GlExportEntity>;
};

export type GlExportDetailInput = {
  accountCode: Scalars['String'];
  type: Scalars['String'];
  amount: Scalars['Float'];
  negative: Scalars['Boolean'];
  associateRefTable: Scalars['String'];
  claimColumn?: Maybe<Scalars['String']>;
};

export type GlExportDetailSourceEntity = AuditEntity & {
  __typename?: 'GLExportDetailSourceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  glExportID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docAmt?: Maybe<Scalars['Float']>;
  claimDocAmt?: Maybe<Scalars['Float']>;
  associateRefID: Scalars['String'];
  associateRefTable: Scalars['String'];
  docRefID: Scalars['String'];
  docRefTable: Scalars['String'];
  docDate: Scalars['String'];
  docNo: Scalars['String'];
  doNo?: Maybe<Scalars['String']>;
  claimDocNo?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  glExport?: Maybe<GlExportEntity>;
  contract?: Maybe<ContractEntity>;
};

export type GlExportEntity = AuditEntity & {
  __typename?: 'GLExportEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  transactionDate: Scalars['String'];
  commonStatus: CommonStatus;
  glExportDetail?: Maybe<Array<GlExportDetailEntity>>;
  glExportDetailSource?: Maybe<Array<GlExportDetailSourceEntity>>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
};

export type GlExportInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  transactionDate: Scalars['String'];
};

export type GlExportPostResponse = {
  __typename?: 'GLExportPostResponse';
  GLPost?: Maybe<Scalars['Boolean']>;
  ExportedGLTransfer?: Maybe<Array<ExportedGlTransferResponse>>;
};

export type GlInterfaceDetailEntity = AuditEntity & {
  __typename?: 'GLInterfaceDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  glInterfaceMasterID: Scalars['String'];
  glInterfaceMaster?: Maybe<GlInterfaceMasterEntity>;
  debitAccountCode1: Scalars['String'];
  debitAccountCode2?: Maybe<Scalars['String']>;
  creditAccountCode1: Scalars['String'];
  creditAccountCode2?: Maybe<Scalars['String']>;
};

export type GlInterfaceDetailInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  glInterfaceMasterID: Scalars['String'];
  debitAccountCode1: Scalars['String'];
  debitAccountCode2?: Maybe<Scalars['String']>;
  creditAccountCode1: Scalars['String'];
  creditAccountCode2?: Maybe<Scalars['String']>;
};

export type GlInterfaceDynamicInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  taxTypeID?: Maybe<Scalars['String']>;
  adjustmentTypeID?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  accountCode: Scalars['String'];
};

export type GlInterfaceMasterEntity = AuditEntity & {
  __typename?: 'GLInterfaceMasterEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  glAssociationID: Scalars['String'];
  glTransactionID: Scalars['String'];
  index?: Maybe<Scalars['Float']>;
  debitAccountCode1: Scalars['String'];
  debitAccountCode2?: Maybe<Scalars['String']>;
  creditAccountCode1: Scalars['String'];
  creditAccountCode2?: Maybe<Scalars['String']>;
  glInterfaceDetail?: Maybe<GlInterfaceDetailEntity>;
  glAssociation?: Maybe<GlAssociationEntity>;
  glTransaction?: Maybe<GlTransactionEntity>;
};

export type GlInterfaceMasterInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  associationType: AssociationType;
  transactionType: TransactionType;
  debitAccountCode1: Scalars['String'];
  debitAccountCode2?: Maybe<Scalars['String']>;
  creditAccountCode1: Scalars['String'];
  creditAccountCode2?: Maybe<Scalars['String']>;
};

export type GlInterfacePolicyEntity = AuditEntity & {
  __typename?: 'GLInterfacePolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  glIntegration: Scalars['String'];
  glTransactionGeneration: Scalars['String'];
  autoGlExport: Scalars['Boolean'];
};

export type GlInterfaceSetupDeleteInput = {
  ID: Scalars['String'];
};

export type GlItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  doID?: Maybe<Scalars['String']>;
  doItemID?: Maybe<Scalars['String']>;
  costClass?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  revenueCategoryID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  docAmt?: Maybe<Scalars['Float']>;
  baseAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  wbsID?: Maybe<Scalars['String']>;
};

export type GlLedgerInput = {
  IDs: Array<Scalars['String']>;
  docRefTable: Scalars['String'];
  GLExportDetailInput: Array<GlExportDetailInput>;
};

export type GlMonth = {
  __typename?: 'GLMonth';
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
};

export type GlTaxDynamicEntity = AuditEntity & {
  __typename?: 'GLTaxDynamicEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxTypeID: Scalars['String'];
  taxType?: Maybe<ConTaxTypeEntity>;
  accountCode: Scalars['String'];
  commonStatus: CommonStatus;
};

export type GlTaxDynamicInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  taxTypeID: Scalars['String'];
  accountCode: Scalars['String'];
};

export type GlTransactionEntity = AuditEntity & {
  __typename?: 'GLTransactionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  commonStatus: CommonStatus;
  glInterfaceMaster?: Maybe<Array<GlInterfaceMasterEntity>>;
};

export type GlTransactionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
};

export type GrnEntity = AuditEntity & {
  __typename?: 'GRNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  supplier: Scalars['String'];
  siteID: Scalars['String'];
  site?: Maybe<SiteEntity>;
  doID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  docNo: Scalars['String'];
  docRef: Scalars['String'];
  cancelRejectDate?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  contactPerson?: Maybe<Scalars['JSON']>;
  grnStatus: DoStatus;
  grnItem?: Maybe<Array<GrnItemEntity>>;
  stockMovement?: Maybe<Array<StockMovementEntity>>;
};

export type GrnItem = {
  __typename?: 'GRNItem';
  costItemID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  productCode?: Maybe<Scalars['String']>;
  uom?: Maybe<Uom>;
  unitPrice?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
};

export type GrnItemEntity = AuditEntity & {
  __typename?: 'GRNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  grnID: Scalars['String'];
  doItemID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  grnAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  grn?: Maybe<GrnEntity>;
  siteMaterial?: Maybe<SiteMaterialEntity>;
  uom?: Maybe<UomEntity>;
  costItem?: Maybe<CostItemEntity>;
};

export type GrtnDeleteInput = {
  ID: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type GrtnItemDeleteInput = {
  ID: Scalars['String'];
};

export type GrtnItemInput = {
  grtnID?: Maybe<Scalars['String']>;
  doItemID: Scalars['String'];
  returnedQty: Scalars['Float'];
  replacement: Scalars['Boolean'];
};

export type GrtnItemInputGrn = {
  doItemID: Scalars['String'];
  DOItemInput: Array<DoItemInput>;
};

export type GoodReceiveNoteRpaResult = {
  __typename?: 'GoodReceiveNoteRPAResult';
  customerAddress?: Maybe<Scalars['String']>;
  customerAddressRecipient?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  grnDate?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  invoiceDate?: Maybe<Scalars['String']>;
  invoiceID?: Maybe<Scalars['String']>;
  invoiceTotal?: Maybe<Scalars['Float']>;
  purchaseOrder?: Maybe<Scalars['String']>;
  remittanceAddressRecipient?: Maybe<Scalars['String']>;
  subTotal?: Maybe<Scalars['Float']>;
  vendorAddress?: Maybe<Scalars['String']>;
  vendorName?: Maybe<Scalars['String']>;
  invoiceItems?: Maybe<Array<GrnItem>>;
};

export type GoodReturnNoteEntity = AuditEntity & {
  __typename?: 'GoodReturnNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  supplierID: Scalars['String'];
  supplier?: Maybe<SupplierEntity>;
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  siteID: Scalars['String'];
  site?: Maybe<SiteEntity>;
  docDate: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  cancelCloseRemark?: Maybe<Scalars['String']>;
  grtnStatus: PurchaseStatus;
  goodReturnNoteItem?: Maybe<Array<GoodReturnNoteItemEntity>>;
  stockMovement?: Maybe<Array<StockMovementEntity>>;
  doItem?: Maybe<Array<DoItemEntity>>;
  /** CustomFieldResolver */
  GRTNdocAmt?: Maybe<Scalars['Float']>;
};


export type GoodReturnNoteEntityGrtNdocAmtArgs = {
  taxInclusive?: Maybe<Scalars['Boolean']>;
};

export type GoodReturnNoteInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  supplierID: Scalars['String'];
  siteID: Scalars['String'];
  contractID: Scalars['String'];
  companyID: Scalars['String'];
  docDate: Scalars['String'];
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  grtnStatus?: Maybe<PurchaseStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type GoodReturnNoteItemEntity = AuditEntity & {
  __typename?: 'GoodReturnNoteItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  grtnID: Scalars['String'];
  goodReturnNote?: Maybe<GoodReturnNoteEntity>;
  doItemID: Scalars['String'];
  doItem?: Maybe<DoItemEntity>;
  returnedQty: Scalars['Float'];
  outstandingQty?: Maybe<Scalars['Float']>;
  replacement: Scalars['Boolean'];
  returnedDoItem?: Maybe<Array<DoItemEntity>>;
};

export enum GrntInsStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING'
}

export type GroupBizVo = {
  __typename?: 'GroupBizVO';
  contractSum?: Maybe<Scalars['Float']>;
  approvedVoAmt?: Maybe<Scalars['Float']>;
  approvedsubVoAmt?: Maybe<Scalars['Float']>;
  voPerc: Scalars['String'];
  vosubPerc: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
};

export type GrtnActionInput = {
  remarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  cancelCloseRemark?: Maybe<Scalars['String']>;
  grtnStatus: PurchaseStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type GrtnRejectInput = {
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
};

export type GuarInsTypeDeleteInput = {
  ID: Scalars['String'];
};

export type GuarInsTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type GuaranteeTypeEntity = AuditEntity & {
  __typename?: 'GuaranteeTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  commonStatus: CommonStatus;
  contract?: Maybe<Array<ContractGuaranteeEntity>>;
  subcontract?: Maybe<Array<SubcontractGuaranteeEntity>>;
};

export type HolidayTypeDeleteInput = {
  ID: Scalars['String'];
};

export type HolidayTypeEntity = AuditEntity & {
  __typename?: 'HolidayTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  wagesRatePolicy?: Maybe<Array<WagesRatePolicyEntity>>;
  commonStatus: CommonStatus;
  calendar?: Maybe<Array<CalendarEntity>>;
  calendarPHP?: Maybe<Array<CalendarPhPolicyEntity>>;
  workingHourPolicy?: Maybe<Array<WorkingHourPolicyEntity>>;
};

export type HolidayTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
};

export type HolidayTypeRate = {
  __typename?: 'HolidayTypeRate';
  holidayTypeID: Scalars['String'];
  rate: Scalars['Float'];
};

export type HolidayTypeRateInput = {
  holidayTypeID: Scalars['String'];
  rate: Scalars['Float'];
};

export type HomeCalendarOutput = {
  __typename?: 'HomeCalendarOutput';
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  contractName?: Maybe<Scalars['String']>;
  contractNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['DateTime']>;
  cpcDate?: Maybe<Scalars['DateTime']>;
  cmgdDate?: Maybe<Scalars['DateTime']>;
  dlpDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  voStartDate?: Maybe<Scalars['DateTime']>;
  voEndDate?: Maybe<Scalars['DateTime']>;
  publicHolidayOffDayDate?: Maybe<Scalars['DateTime']>;
};

export type HuaweiStorageOutput = {
  __typename?: 'HuaweiStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  Url: Scalars['String'];
  MediaType: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  PrivateUrl?: Maybe<Scalars['String']>;
};

export type IncomingEInvoiceEntity = AuditEntity & {
  __typename?: 'IncomingEInvoiceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
};

export type InsuranceTypeEntity = AuditEntity & {
  __typename?: 'InsuranceTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  commonStatus: CommonStatus;
  contract: Array<ContractInsuranceEntity>;
  subcontract?: Maybe<Array<SubcontractInsuranceEntity>>;
};

export type InvitedVendorsEntity = AuditEntity & {
  __typename?: 'InvitedVendorsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title: Scalars['String'];
  officeNo: Scalars['String'];
  email: Scalars['String'];
  description: Scalars['String'];
};

export type InvoiceRpaResult = {
  __typename?: 'InvoiceRPAResult';
  contractID?: Maybe<Scalars['String']>;
  contractTitle?: Maybe<Scalars['String']>;
  deliveryOrderNo?: Maybe<Scalars['String']>;
  invoiceDate?: Maybe<Scalars['DateTime']>;
  invoiceNo?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Items>>;
};

export type ItemApprovedAmountInput = {
  ID?: Maybe<Scalars['String']>;
  approvedAmt?: Maybe<Scalars['Float']>;
};


export type JobTypeDeleteInput = {
  ID: Scalars['String'];
};

export type JobTypeEntity = AuditEntity & {
  __typename?: 'JobTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  marketRate: Scalars['String'];
  worker?: Maybe<Array<WorkerEntity>>;
  commonStatus: CommonStatus;
};

export type JobTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
  marketRate?: Maybe<Scalars['String']>;
};

export type LedgerCheckingSummary = {
  __typename?: 'LedgerCheckingSummary';
  isApprovable?: Maybe<Scalars['Boolean']>;
  hasNextLevel?: Maybe<Scalars['Boolean']>;
  hasGuideline?: Maybe<Scalars['Boolean']>;
  validFlow?: Maybe<Scalars['Boolean']>;
};

export type LedgerInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  docAmt?: Maybe<Scalars['Float']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  doStatus?: Maybe<DoStatus>;
  allocationStatus?: Maybe<AllocationStatus>;
};

export type LedgerPdfInput = {
  refID: Scalars['String'];
  refTable: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  file: Array<Scalars['Upload']>;
};

export type ListEntityRoleUserAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  roleUserIDs: Array<RoleUserInput>;
};

export type LocalBankEntity = AuditEntity & {
  __typename?: 'LocalBankEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  countryID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  code: Scalars['String'];
  address: Address;
  contactNo: Scalars['String'];
  faxNo?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
};

export type LocalBankInput = {
  ID?: Maybe<Scalars['String']>;
  countryID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  contactNo?: Maybe<Scalars['String']>;
  faxNo?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<Scalars['String']>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  user: UserResponse;
};

export enum LogoPosition {
  Left = 'LEFT',
  Center = 'CENTER',
  Right = 'RIGHT'
}

export type MaintenanceCostReturnInput = {
  returnDate?: Maybe<Scalars['String']>;
  returnRemarks?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  cost?: Maybe<Scalars['Float']>;
  returnQty?: Maybe<Scalars['Float']>;
};

export type MaintenanceEntity = AuditEntity & {
  __typename?: 'MaintenanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  plantMachineryID: Scalars['String'];
  plantMachinery?: Maybe<PlantMachineryEntity>;
  workshopID: Scalars['String'];
  workshop?: Maybe<WorkshopEntity>;
  assignmentID?: Maybe<Scalars['String']>;
  assignment?: Maybe<AssignmentEntity>;
  assignedQty?: Maybe<Scalars['Float']>;
  outstandingQty?: Maybe<Scalars['Float']>;
  assignmentDate: Scalars['DateTime'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  returnDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  usageReading?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  returnRemarks?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  trackingLog?: Maybe<Array<Scalars['JSON']>>;
  maintenanceStatus: AsgMainStatus;
};

export type MaintenanceInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  plantMachineryID: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  usageReading: Scalars['Float'];
  cost: Scalars['Float'];
  assignedQty?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  workshopID?: Maybe<Scalars['String']>;
  assignmentID?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type MiscExpenseCancellationInput = {
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
};

export type MiscExpenseEntity = AuditEntity & {
  __typename?: 'MiscExpenseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  costCategoryID: Scalars['String'];
  costCategory?: Maybe<CostCategoryEntity>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  docRef: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  docDate: Scalars['String'];
  transactionDate?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  status: MiscExpenseStatus;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
};

export type MiscExpenseInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  costCategoryID: Scalars['String'];
  docRef: Scalars['String'];
  baseAmt: Scalars['Float'];
  docAmt: Scalars['Float'];
  taxSchemeID?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<MiscExpenseStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export enum MiscExpenseStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED'
}

export type MonthPeriod = {
  __typename?: 'MonthPeriod';
  month?: Maybe<Scalars['DateTime']>;
  outstandingAmt?: Maybe<Scalars['Float']>;
  dueAmt?: Maybe<Scalars['Float']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  completionPerc: Scalars['Boolean'];
  updateCompletionPerc: Scalars['Boolean'];
  updateBQ: BqEntity;
  deleteBQ: Scalars['Boolean'];
  createBQ: Scalars['Boolean'];
  createBQWbsAssignment: BqWbsAssignmentEntity;
  updateBQWbsAssignment: BqWbsAssignmentEntity;
  deleteBQWbsAssignment: Scalars['Boolean'];
  assignBqWbsAssignment: Scalars['Boolean'];
  unassignBqWbsAssignment: Scalars['Boolean'];
  createBillInterest: BillInterestEntity;
  updateBillInterest: BillInterestEntity;
  createDocReminder: DocReminderEntity;
  updateDocReminder: DocReminderEntity;
  cancelPaymentReminder: Scalars['Boolean'];
  createPaymentReminder: Scalars['Boolean'];
  createCustomerReminder: Scalars['Boolean'];
  createContract: ContractEntity;
  deleteContractAllAccess: Scalars['Boolean'];
  updateContract: Scalars['Boolean'];
  insertContacts: Scalars['Boolean'];
  actionOnContractStatus: Scalars['Boolean'];
  createContractExtensionDate: Scalars['Boolean'];
  createContractCertificate: Scalars['Boolean'];
  createContractGuarantee: ContractGuaranteeEntity;
  updateContractGuarantee: ContractGuaranteeEntity;
  createContractInsurance: ContractInsuranceEntity;
  updateContractInsurance: ContractInsuranceEntity;
  cancelContractGuarantee: Scalars['Boolean'];
  cancelContractInsurance: Scalars['Boolean'];
  updateProjectBudget: ProjectBudgetEntity;
  deleteProjectBudget: Scalars['Boolean'];
  addOriginalBudget: Scalars['Boolean'];
  addRevisedBudget: Scalars['Boolean'];
  createContractVO: ContractVoEntity;
  updateContractVO: ContractVoEntity;
  actionOnVOStatus: Scalars['Boolean'];
  deleteContractVO: Scalars['Boolean'];
  createContractClaim: ContractClaimEntity;
  updateContractClaim: ContractClaimEntity;
  deleteContractClaim: Scalars['Boolean'];
  submitRetentionClaim: ContractClaimEntity;
  approveRetentionClaim: ContractClaimEntity;
  submitClaim: ContractClaimEntity;
  approveClaim: Scalars['JSON'];
  updateClaimDetails: Scalars['Boolean'];
  rejectClaim: Scalars['Boolean'];
  cancelClaim: Scalars['Boolean'];
  createContractAdvance: ContractAdvanceEntity;
  updateContractAdvance: ContractAdvanceEntity;
  deleteContractAdvance: Scalars['Boolean'];
  cancelContractAdvance: Scalars['Boolean'];
  createContractReceipt: ContractReceiptEntity;
  updateContractReceipt: ContractReceiptEntity;
  deleteContractReceipt: Scalars['Boolean'];
  createContractRefund: ContractRefundEntity;
  updateContractRefund: ContractRefundEntity;
  deleteContractRefund: Scalars['Boolean'];
  cancelContractRefund: Scalars['Boolean'];
  makeContractRefund: ContractRefundEntity;
  cancelContractReceipt: Scalars['Boolean'];
  createContractMiscInvoice: ContractMiscInvoiceEntity;
  updateContractMiscInvoice: ContractMiscInvoiceEntity;
  deleteContractMiscInvoice: Scalars['Boolean'];
  cancelContractMiscInvoice: Scalars['Boolean'];
  createContractDN: ContractDnEntity;
  updateContractDN: ContractDnEntity;
  deleteContractDN: Scalars['Boolean'];
  cancelContractDN: Scalars['Boolean'];
  createContractCN: ContractCnEntity;
  updateContractCN: ContractCnEntity;
  deleteContractCN: Scalars['Boolean'];
  cancelContractCN: Scalars['Boolean'];
  createClientDN: ClientDnEntity;
  updateClientDN: ClientDnEntity;
  deleteClientDN: Scalars['Boolean'];
  cancelClientDN: Scalars['Boolean'];
  createClientCN: ClientCnEntity;
  updateClientCN: ClientCnEntity;
  deleteClientCN: Scalars['Boolean'];
  cancelClientCN: Scalars['Boolean'];
  makeContractAllocation: Scalars['Boolean'];
  resetContractAllocation: Scalars['Boolean'];
  createCostCode: Scalars['Boolean'];
  createCustomerCompanyAssignment: CustomerCompanyAssignmentEntity;
  updateCustomerCompanyAssignment: CustomerCompanyAssignmentEntity;
  assignCustomerCompany: Scalars['Boolean'];
  unassignCustomerCompany: Scalars['Boolean'];
  assignCustomerWithCompany: Scalars['Boolean'];
  unassignCustomerWithCompany: Scalars['Boolean'];
  createCustomer: CustomerEntity;
  updateCustomer: CustomerEntity;
  createPlatformCustomer: CustomerEntity;
  updatePlatformCustomer: CustomerEntity;
  deleteCustomer: Scalars['Boolean'];
  addCustomerContact: Scalars['Boolean'];
  deletePlatformCustomerProfile: Scalars['Boolean'];
  customerImportValidation: Scalars['JSON'];
  createCustomerImport: Scalars['Boolean'];
  createFollowUp: FollowUpEntity;
  updateFollowUp: FollowUpEntity;
  deleteCBExport: Scalars['Boolean'];
  exportCBLedger: Scalars['Boolean'];
  deleteGLExport: Scalars['Boolean'];
  exportGLLedger: Scalars['Boolean'];
  updateGLExport: Scalars['Boolean'];
  createGLAssociation: GlAssociationEntity;
  updateGLAssociation: GlAssociationEntity;
  createGLTransaction: GlTransactionEntity;
  updateGLTransaction: GlTransactionEntity;
  createGLInterfaceMaster: GlInterfaceMasterEntity;
  updateGLInterfaceMaster: GlInterfaceMasterEntity;
  deleteGLInterfaceMaster: Scalars['Boolean'];
  createGLInterfaceDetail: GlInterfaceDetailEntity;
  updateGLInterfaceDetail: GlInterfaceDetailEntity;
  deleteGLInterfaceDetail: Scalars['Boolean'];
  createGLTaxDynamic: GlTaxDynamicEntity;
  updateGLTaxDynamic: GlTaxDynamicEntity;
  deleteGLTaxDynamic: Scalars['Boolean'];
  createGLAdjustmentDynamic: GlAdjustmentDynamicEntity;
  updateGLAdjustmentDynamic: GlAdjustmentDynamicEntity;
  deleteGLAdjustmentDynamic: Scalars['Boolean'];
  cancelGLDynamicSetup: Scalars['Boolean'];
  saveGLAccountCode: Scalars['Boolean'];
  createGLInterfaceData: Scalars['Boolean'];
  insertNewGL: Scalars['Boolean'];
  deleteGLTransaction: Scalars['Boolean'];
  updateDepartmentGLAccountCode: Scalars['Boolean'];
  createGuaranteeType: GuaranteeTypeEntity;
  updateGuaranteeType: GuaranteeTypeEntity;
  deleteGuaranteeType: Scalars['Boolean'];
  createInsuranceType: InsuranceTypeEntity;
  updateInsuranceType: InsuranceTypeEntity;
  cancelGuaranteeType: Scalars['Boolean'];
  activateGuaranteeType: Scalars['Boolean'];
  cancelInsuranceType: Scalars['Boolean'];
  activateInsuranceType: Scalars['Boolean'];
  deleteInsuranceType: Scalars['Boolean'];
  createMiscExpense: Scalars['Boolean'];
  updateMiscExpense: Scalars['Boolean'];
  cancelMiscExpense: Scalars['Boolean'];
  deleteMiscExpense: Scalars['Boolean'];
  createCostCenter: CostCenterEntity;
  updateCostCenter: CostCenterEntity;
  deleteCostCenter: Scalars['Boolean'];
  cancelCostCenter: Scalars['Boolean'];
  createCurrencyExchange: CurrencyExchangeEntity;
  updateCurrencyExchange: Scalars['Boolean'];
  deleteCurrencyExchange: Scalars['Boolean'];
  createConTaxType: ConTaxTypeEntity;
  updateConTaxType: ConTaxTypeEntity;
  deleteConTaxType: Scalars['Boolean'];
  activateConTaxType: Scalars['Boolean'];
  createConTaxScheme: ConTaxSchemeEntity;
  updateConTaxScheme: ConTaxSchemeEntity;
  deleteConTaxScheme: Scalars['Boolean'];
  activateConTaxScheme: Scalars['Boolean'];
  createConTaxEffectiveDate: ConTaxEffectiveDateEntity;
  updateConTaxEffectiveDate: ConTaxEffectiveDateEntity;
  deleteConTaxEffectiveDate: Scalars['Boolean'];
  activateConTaxEffectiveDate: Scalars['Boolean'];
  insertConTax: ConTaxSchemeEntity;
  updateConTax: Scalars['Boolean'];
  deleteTaxScheme: Scalars['Boolean'];
  createProfitRecogFormula: ProfitRecogFormulaEntity;
  updateProfitRecogFormula: ProfitRecogFormulaEntity;
  deleteProfitRecogFormula: Scalars['Boolean'];
  createProfitRecogParameter: ProfitRecogParameterEntity;
  updateProfitRecogParameter: Scalars['Boolean'];
  createProfitRecogAssignment: ProfitRecogAssignmentEntity;
  updateProfitRecogAssignment: ProfitRecogAssignmentEntity;
  createProfitRecogGeneration: ProfitRecogGenerationEntity;
  updateProfitRecogGeneration: ProfitRecogGenerationEntity;
  createProfitRecogGenParamAssignment: ProfitRecogGenParamAssgmtEntity;
  updateProfitRecogGenParamAssignment: ProfitRecogGenParamAssgmtEntity;
  createProfitGeneration: ProfitRecogGenerationEntity;
  updateProfitGeneration: ProfitRecogGenerationEntity;
  updateProfitRecogFormulaAssignment: Scalars['Boolean'];
  createProfitRecogGLAccountCode: ProfitRecogGlAccountCodeEntity;
  updateProfitRecogGLAccountCode: ProfitRecogGlAccountCodeEntity;
  createProfitRecogGLJournal: ProfitRecogGlJournalEntity;
  updateProfitRecogGLJournal: ProfitRecogGlJournalEntity;
  createProjectExpense: ProjectExpenseEntity;
  updateProjectExpense: Scalars['Boolean'];
  deleteProjectExpense: Scalars['Boolean'];
  cancelProjectExpense: Scalars['Boolean'];
  rejectProjectExpense: Scalars['Boolean'];
  createProjectOpportunityAttachment: ProjectOpportunityAttachmentEntity;
  updateProjectOpportunityAttachment: ProjectOpportunityAttachmentEntity;
  deleteProjectOpportunityAttachment: Scalars['Boolean'];
  createProjectOpportunity: ProjectOpportunityEntity;
  updateProjectOpportunity: ProjectOpportunityEntity;
  deleteProjectOpportunity: Scalars['Boolean'];
  createProjectOpportunityActivityLogs: Scalars['Boolean'];
  createProjectOpportunitySubmission: ProjectOpportunitySubmissionEntity;
  updateProjectOpportunitySubmission: ProjectOpportunitySubmissionEntity;
  deleteProjectOpportunitySubmission: Scalars['Boolean'];
  createWBSBudgetExcel: Scalars['Boolean'];
  createBudget: WbsBudgetEntity;
  updateBudget: WbsBudgetEntity;
  submitBudget: WbsBudgetEntity;
  actionOnBudgetStatus: Scalars['Boolean'];
  createBudgetRevision: WbsBudgetEntity;
  copyBudgetContract: Scalars['Boolean'];
  deleteBudget: Scalars['Boolean'];
  switchBudget: Scalars['Boolean'];
  createWbsBudgetDetail: WbsBudgetDetailEntity;
  updateWbsBudgetDetail: WbsBudgetDetailEntity;
  deleteBudgetDetail: Scalars['Boolean'];
  createBudgetDetail: WbsBudgetDetailEntity;
  updateBudgetDetail: Scalars['Boolean'];
  updateBudgetSchedule: Scalars['Boolean'];
  updateWbsDayworkCostRevenue: Scalars['Boolean'];
  createWbsBudgetTransfer: WbsBudgetTransferHeaderEntity;
  updateWbsBudgetTransfer: Scalars['Boolean'];
  deleteBudgetTransfer: Scalars['Boolean'];
  actionOnBudgetTransferStatus: Scalars['Boolean'];
  createWBS: WbsEntity;
  updateWBS: Scalars['Boolean'];
  deleteWBS: Scalars['Boolean'];
  moveSequenceWBS: Scalars['Boolean'];
  defaultWBS: Scalars['Boolean'];
  executeWBSImportStoreProcedure: Scalars['Boolean'];
  WBSImport: Scalars['Boolean'];
  WBSImportValidation: Scalars['JSON'];
  createWbsSchedule: WbsScheduleEntity;
  updateWbsSchedule: WbsScheduleEntity;
  createWBSUtilizedBudget: WbsUtilizedBudgetEntity;
  updateWBSUtilizedBudget: WbsUtilizedBudgetEntity;
  deleteWBSUtilizedBudget: Scalars['Boolean'];
  actionOnUtilizedBudgetStatus: Scalars['Boolean'];
  insertPermissionTable: Scalars['Boolean'];
  insertSitePermissionTable: Scalars['Boolean'];
  CreateRolePermission: Scalars['Boolean'];
  UpdateRolePermission: Scalars['Boolean'];
  alertPolicySetup: Scalars['Boolean'];
  updateAlertSqlCode: Scalars['JSON'];
  deleteExistPDF: Scalars['Boolean'];
  deleteDocuments: Scalars['Boolean'];
  uploadPDFPO: Scalars['Boolean'];
  uploadPDFDocument: Array<DocumentsEntity>;
  shareAttachment?: Maybe<ShareAttachmentEntity>;
  createContractNews: ContractNewsEntity;
  updateContractNews: ContractNewsEntity;
  deleteContractNews: Scalars['Boolean'];
  createContractNewsRead: ContractNewsReadEntity;
  updateContractNewsRead: ContractNewsReadEntity;
  deleteContractNewsRead: Scalars['Boolean'];
  deleteCollectionActivity: Scalars['Boolean'];
  createCollectionActivity: Scalars['Boolean'];
  deleteColActivity: Scalars['Boolean'];
  updateCollectionActivity: Scalars['Boolean'];
  createMultipleCollectionActivity: Scalars['Boolean'];
  updateCollectionStatus: Scalars['Boolean'];
  patchNewDocNum: Scalars['Boolean'];
  createDocNumRunningNumber: Scalars['Boolean'];
  generateDocumentNumberSetup: Scalars['Boolean'];
  resetGeneralRunningNumber: Scalars['Boolean'];
  resetContractRunningNumber: Scalars['Boolean'];
  contractRunNumChecker: Scalars['Boolean'];
  createContractRunNum: Scalars['Boolean'];
  deleteDocRunningNumber: Scalars['Boolean'];
  createCompany: Scalars['Boolean'];
  createRawCompany: Scalars['Boolean'];
  updateCompany: Scalars['Boolean'];
  updateRawCompany: Scalars['Boolean'];
  deleteCompany: Scalars['Boolean'];
  deleteRawCompany: Scalars['Boolean'];
  updateContact: ContactEntity;
  addContractContact: Scalars['Boolean'];
  addSubcontractContact: Scalars['Boolean'];
  deleteSubcontractData: Scalars['Boolean'];
  deleteSiteData: Scalars['Boolean'];
  deleteContractData: Scalars['Boolean'];
  deleteAssociateData: Scalars['Boolean'];
  deleteLedgersWorkFlow: Scalars['Boolean'];
  deleteSpecificLedgers: Scalars['Boolean'];
  deletePOData: Scalars['Boolean'];
  deleteClientLedgersData: Scalars['Boolean'];
  deleteSubconLedgersData: Scalars['Boolean'];
  deleteSupplierLedgersData: Scalars['Boolean'];
  deleteCostData: Scalars['Boolean'];
  deletePolicyData: Scalars['Boolean'];
  deleteNotificationData: Scalars['Boolean'];
  deleteWorkFlowData: Scalars['Boolean'];
  updateContractData: Scalars['Boolean'];
  updateSubcontractData: Scalars['Boolean'];
  updateClientData: Scalars['Boolean'];
  updateSubconData: Scalars['Boolean'];
  updateSupplierData: Scalars['Boolean'];
  updatePOData: Scalars['Boolean'];
  updateLedgersData: Scalars['Boolean'];
  rejectLedgerTable: Scalars['Boolean'];
  resetRolePermission: Scalars['Boolean'];
  removeSuperUserACL: Scalars['Boolean'];
  patchEditableAllocation: Scalars['Boolean'];
  patchContractAllocation: Scalars['Boolean'];
  patchSupplierAllocation: Scalars['Boolean'];
  patchContractAllocationIDs: Scalars['Boolean'];
  patchRejClaimData: Scalars['Boolean'];
  patchAdjustmentDocRefData: Scalars['Boolean'];
  patchClaimBaseDocAmtData: Scalars['Boolean'];
  patchCancelledAllocClaimData: Scalars['Boolean'];
  patchCNDNClaimData: Scalars['Boolean'];
  patchRetentionClaimReject: Scalars['Boolean'];
  patchRetentionClaimData: Scalars['Boolean'];
  patchSAccountData: Scalars['JSON'];
  patchDocumentsData: Scalars['Boolean'];
  patchCompanyImage: Scalars['Boolean'];
  patchContractConSubconData: Scalars['Boolean'];
  patchRealConDateData: Scalars['Boolean'];
  patchConDateData: Scalars['Boolean'];
  patchVODateData: Scalars['Boolean'];
  patchGuarInsDateData: Scalars['Boolean'];
  insertNewContractBudget: Scalars['Boolean'];
  patchContractClaimAllocation: Scalars['Boolean'];
  patchContractCompany: Scalars['Boolean'];
  patchCompanyInAccount: Scalars['JSON'];
  patchNullDocNoExpenses: Scalars['JSON'];
  patchSiteDocumentData: Scalars['Boolean'];
  patchCostCategoryData: Scalars['Boolean'];
  patchDueDateNullData: Scalars['JSON'];
  patchInvoiceData: Scalars['Boolean'];
  patchNullTrxDateData: Scalars['Boolean'];
  removeAllocData: Scalars['Boolean'];
  patchPlantStatusData: Scalars['Boolean'];
  patchProjectExpense: Scalars['Boolean'];
  patchNullGRTNItemData: Scalars['Boolean'];
  patchNullPOItemData: Scalars['Boolean'];
  patchNullPOSiteData: Scalars['Boolean'];
  patchRfqItemSubmissionIsSelected: Scalars['Boolean'];
  patchDOItemDeliveredQty: Scalars['Boolean'];
  patchDocDueDateNullData: Scalars['Boolean'];
  patchExpectedDateNullData: Scalars['Boolean'];
  patchGRTNTrxDateNullData: Scalars['Boolean'];
  patchMainSiteData: Scalars['Boolean'];
  insertLongLatData: Scalars['Boolean'];
  insertSiteInventoryData: Scalars['Boolean'];
  patchNullDocFolderData: Scalars['Boolean'];
  patchEmptyWorkflow: Scalars['Boolean'];
  patchNullSubconTenderData: Scalars['Boolean'];
  patchSubconCostCategory: Scalars['Boolean'];
  patchWorkerSiteAssignment: Scalars['Boolean'];
  patchSupplierTypeData: Scalars['Boolean'];
  patchSupplierWithSupplierType: Scalars['Boolean'];
  patchWbsCostItemDetail: Scalars['Boolean'];
  patchWbsCostDetail: Scalars['Boolean'];
  patchWbsSchedule: Scalars['Boolean'];
  createDocumentFolder: Scalars['Boolean'];
  updateDocumentFolder: Scalars['Boolean'];
  deleteDocumentFolder: Scalars['Boolean'];
  implementBaseUOM: Scalars['Boolean'];
  extraSystemAdminSetup: Scalars['Boolean'];
  extraGeneralSettingSetup: Scalars['Boolean'];
  extraDBSetup: Scalars['Boolean'];
  extraGLMasterSetup: Scalars['Boolean'];
  extraGLTransactionSetup: Scalars['Boolean'];
  extraGLGenSettingSetup: Scalars['Boolean'];
  platformContactSetup: Scalars['Boolean'];
  initialCurrencySetup: Scalars['Boolean'];
  initialDatabaseSetup: Scalars['Boolean'];
  initialMasterFileSetup: Scalars['Boolean'];
  deleteMasterFile: Scalars['Boolean'];
  initialSiteMasterFileSetup: Scalars['Boolean'];
  deleteSiteMasterFile: Scalars['Boolean'];
  initialGeneralSettingSetup: Scalars['Boolean'];
  deleteGenSetting: Scalars['Boolean'];
  initialGLInterfaceSetup: Scalars['Boolean'];
  initialDocNumSetup: Scalars['Boolean'];
  generateGLMasterFileSetup: Scalars['Boolean'];
  deleteGLMasterFileSetup: Scalars['Boolean'];
  generateGLInitMasterSetup: Scalars['Boolean'];
  deleteGLInitMasterSetup: Scalars['Boolean'];
  initialProfitRecogParameter: Scalars['Boolean'];
  createBatchPaymentDetail: BatchPaymentDetailEntity;
  updateBatchPaymentDetail: BatchPaymentDetailEntity;
  deleteBatchPaymentDetail: Scalars['Boolean'];
  cancelBatchPaymentDetail: Scalars['Boolean'];
  createBatchPaymentHeader: BatchPaymentHeaderEntity;
  updateBatchPaymentHeader: BatchPaymentHeaderEntity;
  deleteBatchPaymentHeader: Scalars['Boolean'];
  cancelBatchPaymentHeader: Scalars['Boolean'];
  submitBatchPayment: Scalars['Boolean'];
  confirmBatchPayment: Scalars['JSON'];
  payBatchPayment: Scalars['JSON'];
  createSiteMaterialPolicy: SiteMaterialPolicyEntity;
  updateSiteMaterialPolicy: SiteMaterialPolicyEntity;
  createSiteMaterialContractPolicy: SiteMaterialContractPolicyEntity;
  updateSiteMaterialContractPolicy: SiteMaterialContractPolicyEntity;
  createMaterialPolicy: Scalars['Boolean'];
  createMaterialContractPolicy: Scalars['Boolean'];
  accountSubscriptionInfo: Scalars['Boolean'];
  createTaxMasterFile: Scalars['Boolean'];
  editTaxMasterFile: Scalars['Boolean'];
  deleteTaxMasterFile: Scalars['Boolean'];
  deactivateTaxMasterFile: Scalars['Boolean'];
  activateTaxMasterFile: Scalars['Boolean'];
  createSuperUser: UserEntity;
  createContractNotification: ContractNotificationEntity;
  updateContractNotification: ContractNotificationEntity;
  deleteContractNotification: Scalars['Boolean'];
  readNotification: Scalars['Boolean'];
  markAsReadNoti: Scalars['Boolean'];
  deleteNoti: Scalars['Boolean'];
  patchWorkDeskData: Scalars['Boolean'];
  CreateContractRole: Scalars['Boolean'];
  UpdateContractRole: Scalars['Boolean'];
  CreateSiteRole: Scalars['Boolean'];
  UpdateSiteRole: Scalars['Boolean'];
  DeleteRolePermission: Scalars['Boolean'];
  CreateEntityRoleUser: Scalars['Boolean'];
  CreateListEntityRoleUser: Scalars['Boolean'];
  createAdjustmentType: AdjustmentTypeEntity;
  updateAdjustmentType: AdjustmentTypeEntity;
  deleteAdjustmentType: Scalars['Boolean'];
  createAlert: AlertEntity;
  updateAlert: AlertEntity;
  deleteAlert: Scalars['Boolean'];
  updateAlertAssignment: Scalars['JSON'];
  cancelApprovalPolicy: Scalars['Boolean'];
  cancelApprovalPolicyAssignment: Scalars['Boolean'];
  createApprovalPolicy: ApprovalPolicyEntity;
  updateApprovalPolicy: ApprovalPolicyEntity;
  createApprovalPolicyAssignment: ApprovalPolicyEntity;
  updateApprovalPolicyAssignment: ApprovalPolicyAssignmentEntity;
  deleteApprovalPolicy: Scalars['Boolean'];
  deleteApprovalPolicyAssignment: Scalars['Boolean'];
  deleteMultipleApprovalPolicy: Scalars['Boolean'];
  deleteMultipleApprovalPolicyAssignment: Scalars['Boolean'];
  createBankAccount: BankAccountEntity;
  updateBankAccount: BankAccountEntity;
  deleteBankAccount: Scalars['Boolean'];
  activateBankAccount: Scalars['Boolean'];
  cancelBankAccount: Scalars['Boolean'];
  deleteCalendar: Scalars['Boolean'];
  cancelCalendar: Scalars['Boolean'];
  activateCalendar: Scalars['Boolean'];
  createCalendar: Scalars['Boolean'];
  updateCalendar: Scalars['Boolean'];
  createCalendarPHPolicy: CalendarPhPolicyEntity;
  updateCalendarPHPolicy: CalendarPhPolicyEntity;
  deleteCalendarPHPolicy: Scalars['Boolean'];
  cancelCalendarPHPolicy: Scalars['Boolean'];
  activateCalendarPHPolicy: Scalars['Boolean'];
  createCalendarWeekendPolicy: CalendarWeekendPolicyEntity;
  updateCalendarWeekendPolicy: CalendarWeekendPolicyEntity;
  deleteCalendarWeekendPolicy: Scalars['Boolean'];
  cancelCalendarWeekendPolicy: Scalars['Boolean'];
  activateCalendarWeekendPolicy: Scalars['Boolean'];
  uploadCompanyLogo: Scalars['Boolean'];
  uploadISOLogo: Scalars['Boolean'];
  uploadCompanyISOLogo: Scalars['Boolean'];
  updateWatermarkCompany: Scalars['Boolean'];
  toggleCompanySignature: Scalars['Boolean'];
  deleteCostCategory: Scalars['Boolean'];
  cancelCostCategory: Scalars['Boolean'];
  activateCostCategory: Scalars['Boolean'];
  /** ensure to pass the costClass in input */
  createBatchCostCategory: Scalars['Boolean'];
  /** ensure to pass the costClass in input */
  createBatchCostCategoryWithoutItem: Scalars['Boolean'];
  createCostCategory: CostCategoryEntity;
  updateCostCategory: Scalars['Boolean'];
  deleteCostCategoryCostItem: Scalars['Boolean'];
  addCostCategory: CostCategoryEntity;
  editCostCategory: Scalars['Boolean'];
  deleteTreeCostCategory: Scalars['Boolean'];
  costCategoryImportValidation: Scalars['JSON'];
  costCategoryImport: Scalars['Boolean'];
  deleteCostItem: Scalars['Boolean'];
  activateCostItem: Scalars['Boolean'];
  /** Create Individual as it will overwrite the previous daywork */
  createCostItem: CostItemEntity;
  /** Update Individual as it will overwrite the previous daywork */
  updateCostItem: CostItemEntity;
  /** Create batch */
  createBatchCostItem: Scalars['Boolean'];
  cancelCostItem: Scalars['Boolean'];
  costItemImport: Scalars['Boolean'];
  costItemImportValidation: Scalars['JSON'];
  createCurrencySetup: CurrencyNoteEntity;
  updateCurrencySetup: CurrencyNoteEntity;
  deleteCurrencySetup: Scalars['Boolean'];
  addDigitalDocuments: Scalars['Boolean'];
  deleteDigitalDocuments: Scalars['Boolean'];
  updateNumberFormat: ConNumberFormatEntity;
  createDocType: ConDocTypeEntity;
  updateDocType: ConDocTypeEntity;
  createRunningNumberSetup: ConRunningNumberSetupEntity;
  updateRunningNumberSetup: ConRunningNumberSetupEntity;
  inactiveDocType: Scalars['Boolean'];
  editNumberFormat: Scalars['Boolean'];
  updateDocNumSetup: Scalars['Boolean'];
  createDocNumHeader: DocNumHeaderEntity;
  updateDocNumHeader: DocNumHeaderEntity;
  createDocNumDetail: DocNumDetailEntity;
  updateDocNumDetail: DocNumDetailEntity;
  createDocumentNumberHeader: Scalars['Boolean'];
  updateDocumentNumberHeader: Scalars['Boolean'];
  updateSystemAdminDocNum: Scalars['Boolean'];
  createHolidayType: HolidayTypeEntity;
  updateHolidayType: HolidayTypeEntity;
  deleteHolidayType: Scalars['Boolean'];
  cancelHolidayType: Scalars['Boolean'];
  activateHolidayType: Scalars['Boolean'];
  createJobType: JobTypeEntity;
  updateJobType: JobTypeEntity;
  deleteJobType: Scalars['Boolean'];
  cancelJobType: Scalars['Boolean'];
  activateJobType: Scalars['Boolean'];
  createPlatformSuperUser: PlatformUserEntity;
  createPlatformUser: PlatformUserEntity;
  updatePlatformUser: Scalars['Boolean'];
  createPlatformPassword: Scalars['Boolean'];
  createPlatformPasswordTest: Scalars['Boolean'];
  activatePlatformUser: Scalars['Boolean'];
  platformLogin: PlatformLoginResponse;
  changePlatformPassword: Scalars['Boolean'];
  platformLogout: Scalars['Boolean'];
  resetPlatformPassword: Scalars['Boolean'];
  uploadProfilePicPlatform: DocumentsEntity;
  forgotPlatformPassword: Scalars['Boolean'];
  createPolicyGuideline: Scalars['Boolean'];
  updatePolicyGuideline: Scalars['Boolean'];
  createPolicyAmountGuideline: Scalars['Boolean'];
  updatePolicyAmountGuideline: Scalars['Boolean'];
  deletePolicyAmountGuideline: Scalars['Boolean'];
  createNotificationGuideline: Scalars['Boolean'];
  updateNotificationGuideline: Scalars['Boolean'];
  createPublicHoliday: PublicHolidayEntity;
  updatePublicHoliday: PublicHolidayEntity;
  deletePublicHoliday: Scalars['Boolean'];
  cancelPublicHoliday: Scalars['Boolean'];
  activatePublicHoliday: Scalars['Boolean'];
  deleteRevenueCategory: Scalars['Boolean'];
  cancelRevenueCategory: Scalars['Boolean'];
  activateRevenueCategory: Scalars['Boolean'];
  /** Create Individual as it will overwrite the previous daywork */
  createRevenueCategory: RevenueCategoryEntity;
  /** Update Individual as it will overwrite the previous daywork */
  updateRevenueCategory: RevenueCategoryEntity;
  /** Create batch */
  createBatchRevenueCategory: Scalars['Boolean'];
  revenueCategoryImportValidation: Scalars['JSON'];
  revenueCategoryImport: Scalars['Boolean'];
  goodReceiveNoteRPA: Array<GoodReceiveNoteRpaResult>;
  invoiceRPA: Array<InvoiceRpaResult>;
  deliveryOrderRPA: DorpaResult;
  updateSAccount: SAccountEntity;
  createSiteMaterial: SiteMaterialEntity;
  updateSiteMaterial: SiteMaterialEntity;
  deleteSiteMaterial: Scalars['Boolean'];
  cancelSiteMaterial: Scalars['Boolean'];
  activateSiteMaterial: Scalars['Boolean'];
  createBulkSiteMaterial: Scalars['Boolean'];
  createSiteWorkers: WorkerEntity;
  updateSiteWorkers: WorkerEntity;
  deleteSiteWorkers: Scalars['Boolean'];
  createSubcontractorType: SubcontractorTypeEntity;
  updateSubcontractorType: SubcontractorTypeEntity;
  deleteSubcontractorType: Scalars['Boolean'];
  cancelSubcontractorType: Scalars['Boolean'];
  activateSubcontractorType: Scalars['Boolean'];
  createSubcontractorTypeFdw: Scalars['Boolean'];
  createSupplierType: SupplierTypeEntity;
  updateSupplierType: SupplierTypeEntity;
  deleteSupplierType: Scalars['Boolean'];
  cancelSupplierType: Scalars['Boolean'];
  activateSupplierType: Scalars['Boolean'];
  createSupplierTypeFdw: Scalars['Boolean'];
  updateUOM: UomEntity;
  deleteUOM: Scalars['Boolean'];
  cancelUOM: Scalars['Boolean'];
  activateUOM: Scalars['Boolean'];
  createUOM: UomEntity;
  createBatchUOM: Scalars['Boolean'];
  uomImport: Scalars['Boolean'];
  uomImportValidation: Scalars['JSON'];
  createUOMExchange: Scalars['Boolean'];
  updateUOMExchange: Scalars['Boolean'];
  deleteUOMExchange: Scalars['Boolean'];
  createUserCompanyAssignment: UserCompanyAssignmentEntity;
  updateUserCompanyAssignment: UserCompanyAssignmentEntity;
  assignUserCompany: Scalars['Boolean'];
  unassignUserCompany: Scalars['Boolean'];
  assignUserWithCompany: Scalars['Boolean'];
  unassignUserWithCompany: Scalars['Boolean'];
  createUser: UserEntity;
  createUserEmail: UserEntity;
  sendInvitationEmail: Scalars['Boolean'];
  resendInvitation: UserEntity;
  updateUser: UserEntity;
  updateUserWithPassword: UserEntity;
  createPassword: Scalars['Boolean'];
  createPasswordTest: Scalars['Boolean'];
  activateUser: Scalars['Boolean'];
  unblockUser: Scalars['Boolean'];
  changePasswordSU: Scalars['Boolean'];
  login: LoginResponse;
  changePassword: Scalars['Boolean'];
  logout: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  totpSecret: Scalars['String'];
  totpValidate: Scalars['Boolean'];
  uploadProfilePic: Scalars['Boolean'];
  forgotPassword: Scalars['Boolean'];
  updateLastEntity: Scalars['Boolean'];
  createVideoCam: VideoCamEntity;
  updateVideoCam: VideoCamEntity;
  deleteVideoCam: Scalars['Boolean'];
  cancelVideoCam: Scalars['Boolean'];
  activateVideoCam: Scalars['Boolean'];
  createWagesRatePolicy: WagesRatePolicyEntity;
  deleteWagesRatePolicy: Scalars['Boolean'];
  cancelWagesRatePolicy: Scalars['Boolean'];
  activateWagesRatePolicy: Scalars['Boolean'];
  updateWagesRatePolicy: Scalars['Boolean'];
  createContractToDo: ContractToDoEntity;
  updateContractToDo: ContractToDoEntity;
  deleteContractToDo: Scalars['Boolean'];
  createNotification: Scalars['Boolean'];
  createToDo: Scalars['Boolean'];
  approveToDo: Scalars['Boolean'];
  rejectToDo: Scalars['Boolean'];
  deleteWorker: Scalars['Boolean'];
  cancelWorker: Scalars['Boolean'];
  createWorker: WorkerEntity;
  updateWorker: WorkerEntity;
  createBulkWorker: Scalars['Boolean'];
  updateWorkerAttendance: WorkerAttendanceEntity;
  deleteWorkerAttendance: Scalars['Boolean'];
  cancelWorkerAttendance: Scalars['Boolean'];
  createWorkerAttendance: WorkerAttendanceEntity;
  calculateWorkerWage: Scalars['Boolean'];
  createBatchWorkerAttendance: Scalars['Boolean'];
  approveRejectWorkDesk: Scalars['Boolean'];
  createNotificationWorkflow: Scalars['JSON'];
  testEmail: Scalars['Boolean'];
  updateWorkingHourPolicy: WorkingHourPolicyEntity;
  deleteWorkingHourPolicy: Scalars['Boolean'];
  cancelWorkingHourPolicy: Scalars['Boolean'];
  activateWorkingHourPolicy: Scalars['Boolean'];
  createWorkingHourPolicy: WorkingHourPolicyEntity;
  updateAssignment: AssignmentEntity;
  createAssignment: Scalars['Boolean'];
  returnFromAssignment: Scalars['Boolean'];
  cancelAssignment: Scalars['Boolean'];
  createFuelDischarge: Scalars['Boolean'];
  updateStatusFuelDischarge: Scalars['Boolean'];
  updateFuelDischarge: Scalars['Boolean'];
  deleteFuelDischarge: Scalars['Boolean'];
  createFuelMeterReading: FuelMeterReadingEntity;
  updateFuelMeterReading: FuelMeterReadingEntity;
  deleteFuelMeterReading: Scalars['Boolean'];
  createFuelSupply: Scalars['Boolean'];
  updateStatusFuelSupply: Scalars['Boolean'];
  updateFuelSupply: Scalars['Boolean'];
  deleteFuelSupply: Scalars['Boolean'];
  actionOnFuelSupplyStatus: Scalars['Boolean'];
  deleteFuelTankSetup: Scalars['Boolean'];
  createFuelTankSetup: Scalars['Boolean'];
  updateFuelTankSetup: Scalars['Boolean'];
  createMaintenance: Scalars['Boolean'];
  updateMaintenance: Scalars['Boolean'];
  cancelMaintenanceCost: Scalars['Boolean'];
  returnFromMaintenance: Scalars['Boolean'];
  createWorkshop: WorkshopEntity;
  updateWorkshop: WorkshopEntity;
  createPNMRental: PnmRentalEntity;
  updatePNMRental: PnmRentalEntity;
  createPlantMachinery: Scalars['Boolean'];
  updatePlantMachinery: Scalars['Boolean'];
  updateDO: Scalars['Boolean'];
  actionOnDoStatus: Scalars['Boolean'];
  cancelCloseDO: Scalars['Boolean'];
  deleteDO: Scalars['Boolean'];
  rejectDO: Scalars['Boolean'];
  submitDO: Scalars['Boolean'];
  packDO: Scalars['Boolean'];
  shipDO: Scalars['Boolean'];
  submitGRTN: Scalars['Boolean'];
  oldCreateUpdateGRTN: Scalars['Boolean'];
  createUpdateGRTN: Scalars['Boolean'];
  approveGRTN: Scalars['Boolean'];
  rejectGRTN: Scalars['Boolean'];
  deleteGRTN: Scalars['Boolean'];
  cancelCloseGRTN: Scalars['Boolean'];
  createNegotiatedSupply: Scalars['Boolean'];
  updateNegotiatedSupply: Scalars['Boolean'];
  cancelNegotiatedSupply: Scalars['Boolean'];
  deleteNegoSupply: Scalars['Boolean'];
  createPO: Scalars['JSON'];
  createDirectPO: Scalars['JSON'];
  updateDirectPO: Scalars['JSON'];
  actionOnPOStatus: Scalars['JSON'];
  cancelClosePO: Scalars['Boolean'];
  deletePO: Scalars['Boolean'];
  purchaseBudgetApproval: Scalars['Boolean'];
  updateWbsPoItem: Scalars['Boolean'];
  createPurchaseReq: PurchaseReqEntity;
  updatePurchaseRequest: Scalars['JSON'];
  actionOnPRStatus: Scalars['JSON'];
  deletePR: Scalars['Boolean'];
  cancelClosePR: Scalars['Boolean'];
  submitEvaluationSelection: Scalars['Boolean'];
  createRFQ: Scalars['Boolean'];
  updateRFQ: Scalars['Boolean'];
  rejectRFQ: Scalars['Boolean'];
  cancelCloseRFQ: Scalars['Boolean'];
  deleteRFQ: Scalars['Boolean'];
  updateRFQSupplierInvitation: RfqSupplierInvitationEntity;
  deleteRFQSupplierInvitation: Scalars['Boolean'];
  inviteSupplier: Scalars['Boolean'];
  uninviteSupplier: Scalars['Boolean'];
  deleteRFQSupplierSubmission: Scalars['Boolean'];
  activeRfqSubmission: Scalars['Boolean'];
  submitRFQQuote: Scalars['Boolean'];
  createSupplierCompanyAssignment: SupplierCompanyAssignmentEntity;
  updateSupplierCompanyAssignment: SupplierCompanyAssignmentEntity;
  assignSupplierCompany: Scalars['Boolean'];
  unassignSupplierCompany: Scalars['Boolean'];
  assignSupplierWithCompany: Scalars['Boolean'];
  unassignSupplierWithCompany: Scalars['Boolean'];
  createPlatformSupplier: SupplierEntity;
  updatePlatformSupplier: SupplierEntity;
  deleteSupplier: Scalars['Boolean'];
  createSupplierCCategory: SupplierCCategoryEntity;
  updateSupplierCCategory: SupplierCCategoryEntity;
  deleteSupplierCCategory: Scalars['Boolean'];
  addSupplierContact: Scalars['Boolean'];
  createBulkSupplier: Scalars['Boolean'];
  addCCategoryInSupplier: Scalars['Boolean'];
  addSupplierInCCategory: Scalars['Boolean'];
  deleteSupplierInCCategory: Scalars['Boolean'];
  supplierImportValidation: Scalars['JSON'];
  createSupplierImport: Scalars['Boolean'];
  createAPInvoice: ApInvoiceEntity;
  updateAPInvoice: ApInvoiceEntity;
  deleteAPInvoice: Scalars['Boolean'];
  createAPInvoiceWithoutDO: ApInvoiceEntity;
  updateAPInvoiceWithoutDO: ApInvoiceEntity;
  deleteAPInvoiceWithoutDO: Scalars['Boolean'];
  createSupplierInvoice: Scalars['Boolean'];
  createSupplierInvoiceV2: Scalars['Boolean'];
  updateSupplierInvoiceV2: Scalars['Boolean'];
  updateSupplierInvoice: Scalars['Boolean'];
  deleteSupplierInvoice: Scalars['Boolean'];
  cancelSupplierInvoice: Scalars['Boolean'];
  createAPAdvance: ApAdvanceEntity;
  updateAPAdvance: ApAdvanceEntity;
  deleteAPAdvance: Scalars['Boolean'];
  createAPPayment: ApPaymentEntity;
  updateAPPayment: ApPaymentEntity;
  deleteAPPayment: Scalars['Boolean'];
  createAPRefund: ApRefundEntity;
  updateAPRefund: ApRefundEntity;
  deleteAPRefund: Scalars['Boolean'];
  makeSupplierPayment: ApPaymentEntity;
  cancelSupplierDeposit: Scalars['Boolean'];
  cancelSupplierPayment: Scalars['Boolean'];
  cancelSupplierRefund: Scalars['Boolean'];
  createAPDebitNote: ApDebitNoteEntity;
  updateAPDebitNote: ApDebitNoteEntity;
  deleteAPDebitNote: Scalars['Boolean'];
  createAPCreditNote: ApCreditNoteEntity;
  updateAPCreditNote: ApCreditNoteEntity;
  deleteAPCreditNote: Scalars['Boolean'];
  createDNtoSupplier: DNtoSupplierEntity;
  updateDNtoSupplier: DNtoSupplierEntity;
  deleteDNtoSupplier: Scalars['Boolean'];
  createCNtoSupplier: CNtoSupplierEntity;
  updateCNtoSupplier: CNtoSupplierEntity;
  deleteCNtoSupplier: Scalars['Boolean'];
  makeSupplierAllocation: Scalars['Boolean'];
  resetSupplierAllocation: Scalars['Boolean'];
  cancelSupplierDN: Scalars['Boolean'];
  cancelSupplierCN: Scalars['Boolean'];
  cancelDNtoSupplier: Scalars['Boolean'];
  cancelCNtoSupplier: Scalars['Boolean'];
  updateSubcontractorInvitation: SubcontractorInvitationEntity;
  deleteSubcontractorInvitation: Scalars['Boolean'];
  inviteSubcontractor: Scalars['Boolean'];
  unInviteSubcontractor: Scalars['Boolean'];
  unInviteSubcontractors: Scalars['Boolean'];
  createSubconRFQSubmission: SubconRfqSubmissionEntity;
  updateSubconRFQSubmission: SubconRfqSubmissionEntity;
  deleteSubconRFQSubmission: Scalars['Boolean'];
  createSubcontractTender: SubcontractTenderEntity;
  updateSubcontractTender: SubcontractTenderEntity;
  deleteSubcontractTender: Scalars['Boolean'];
  tenderToSubconGeneration: Scalars['Boolean'];
  createSubcontractGuarantee: SubcontractGuaranteeEntity;
  updateSubcontractGuarantee: SubcontractGuaranteeEntity;
  deleteSubcontractGuarantee: Scalars['Boolean'];
  createSubcontractInsurance: SubcontractInsuranceEntity;
  updateSubcontractInsurance: SubcontractInsuranceEntity;
  deleteSubcontractInsurance: Scalars['Boolean'];
  cancelSubcontractGuarantee: Scalars['Boolean'];
  cancelSubcontractInsurance: Scalars['Boolean'];
  createSubcontract: SubcontractEntity;
  updateSubcontract: Scalars['Boolean'];
  actionOnSubcontractStatus: Scalars['Boolean'];
  createSubcontractExtensionDate: Scalars['Boolean'];
  createSubcontractCertificate: Scalars['Boolean'];
  actionOnSubcontractStatusWithTrade: Scalars['Boolean'];
  createSubcontractorCompanyAssignment: SubcontractorCompanyAssignmentEntity;
  updateSubcontractorCompanyAssignment: SubcontractorCompanyAssignmentEntity;
  assignSubconCompany: Scalars['Boolean'];
  unassignSubconCompany: Scalars['Boolean'];
  assignSubconWithCompany: Scalars['Boolean'];
  unassignSubconWithCompany: Scalars['Boolean'];
  createSubcontractVO: SubcontractVoEntity;
  updateSubcontractVO: SubcontractVoEntity;
  deleteSubcontractVO: Scalars['Boolean'];
  actionOnSubconVOStatus: Scalars['Boolean'];
  createSubcontractCostWbs: SubcontractCostWbsEntity;
  updateSubcontractCostWbs: SubcontractCostWbsEntity;
  deleteSubcontractCostWbs: Scalars['Boolean'];
  updateSubcontractLedgers: Scalars['Boolean'];
  createSubcontractClaim: SubcontractClaimEntity;
  updateSubcontractClaim: SubcontractClaimEntity;
  deleteSubcontractClaim: Scalars['Boolean'];
  submitSubconRetentionClaim: SubcontractClaimEntity;
  approveSubconRetentionClaim: SubcontractClaimEntity;
  submitSubconClaim: SubcontractClaimEntity;
  approveSubconClaim: Scalars['JSON'];
  updateSubconClaimDetails: Scalars['Boolean'];
  rejectSubconClaim: Scalars['Boolean'];
  cancelSubconClaim: Scalars['Boolean'];
  createSubcontractAdvance: SubcontractAdvanceEntity;
  updateSubcontractAdvance: SubcontractAdvanceEntity;
  deleteSubcontractAdvance: Scalars['Boolean'];
  createSubcontractPayment: SubcontractPaymentEntity;
  updateSubcontractPayment: SubcontractPaymentEntity;
  deleteSubcontractPayment: Scalars['Boolean'];
  createSubcontractRefund: SubcontractRefundEntity;
  updateSubcontractRefund: SubcontractRefundEntity;
  deleteSubcontractRefund: Scalars['Boolean'];
  makeSubconPayment: SubcontractPaymentEntity;
  cancelSubconAdvance: Scalars['Boolean'];
  cancelSubconPayment: Scalars['Boolean'];
  cancelSubconRefund: Scalars['Boolean'];
  createSubcontractMiscInvoice: SubcontractMiscInvoiceEntity;
  updateSubcontractMiscInvoice: SubcontractMiscInvoiceEntity;
  deleteSubcontractMiscInvoice: Scalars['Boolean'];
  createSubcontractDN: SubcontractDnEntity;
  updateSubcontractDN: SubcontractDnEntity;
  deleteSubcontractDN: Scalars['Boolean'];
  createSubcontractCN: SubcontractCnEntity;
  updateSubcontractCN: SubcontractCnEntity;
  deleteSubcontractCN: Scalars['Boolean'];
  createDNtoSubcon: DNtoSubconEntity;
  updateDNtoSubcon: DNtoSubconEntity;
  deleteDNtoSubcon: Scalars['Boolean'];
  createCNtoSubcon: CNtoSubconEntity;
  updateCNtoSubcon: CNtoSubconEntity;
  deleteCNtoSubcon: Scalars['Boolean'];
  makeSubconAllocation: Scalars['Boolean'];
  resetSubcontractAllocation: Scalars['Boolean'];
  cancelSubcontractMiscInvoice: Scalars['Boolean'];
  cancelSubconDN: Scalars['Boolean'];
  cancelSubconCN: Scalars['Boolean'];
  cancelDNtoSubcon: Scalars['Boolean'];
  cancelCNtoSubcon: Scalars['Boolean'];
  createPlatformSubcontractor: SubcontractorEntity;
  updatePlatformSubcontractor: SubcontractorEntity;
  deleteSubcontractor: Scalars['Boolean'];
  createSubcontractorCCategory: SubcontractorCCategoryEntity;
  updateSubcontractorCCategory: SubcontractorCCategoryEntity;
  addSubcontractorContact: Scalars['Boolean'];
  createSubcontractorAndPackage: SubcontractorEntity;
  createBulkSubcon: Scalars['Boolean'];
  addSubcontractorCCategory: Scalars['Boolean'];
  createSubcontractorSetup: Scalars['Boolean'];
  updateSubcontractorSetup: Scalars['Boolean'];
  deleteSubcontractorSetup: Scalars['Boolean'];
  subcontractorImportValidation: Scalars['JSON'];
  createSubcontractorImport: Scalars['Boolean'];
  createSite: Scalars['Boolean'];
  updateSite: Scalars['Boolean'];
  deleteSite: Scalars['Boolean'];
  activateSite: Scalars['Boolean'];
  cancelSite: Scalars['Boolean'];
  insertUpdateSite: Scalars['Boolean'];
  deleteProjectSite: Scalars['Boolean'];
  createSiteImage: SiteImageEntity;
  updateSiteImage: SiteImageEntity;
  deleteSiteImage: Scalars['Boolean'];
  cancelSiteImage: Scalars['Boolean'];
};


export type MutationCompletionPercArgs = {
  input: CompletionInput;
  contractID: Scalars['String'];
};


export type MutationUpdateCompletionPercArgs = {
  completionPerc: Scalars['String'];
  contractID: Scalars['String'];
};


export type MutationUpdateBqArgs = {
  input: BqInput;
};


export type MutationDeleteBqArgs = {
  input: BqInput;
};


export type MutationCreateBqArgs = {
  input: Array<BqInput>;
  contractID: Scalars['String'];
};


export type MutationCreateBqWbsAssignmentArgs = {
  input: BqWbsAssignmentInput;
};


export type MutationUpdateBqWbsAssignmentArgs = {
  input: BqWbsAssignmentInput;
};


export type MutationDeleteBqWbsAssignmentArgs = {
  input: BqWbsAssignmentInput;
};


export type MutationAssignBqWbsAssignmentArgs = {
  bqID: Array<Scalars['String']>;
  wbsID: Scalars['String'];
};


export type MutationUnassignBqWbsAssignmentArgs = {
  bqID: Array<Scalars['String']>;
  wbsID: Scalars['String'];
};


export type MutationCreateBillInterestArgs = {
  input: BillInterestInput;
};


export type MutationUpdateBillInterestArgs = {
  input: BillInterestInput;
};


export type MutationCreateDocReminderArgs = {
  input: DocReminderInput;
};


export type MutationUpdateDocReminderArgs = {
  input: DocReminderInput;
};


export type MutationCancelPaymentReminderArgs = {
  ID: Scalars['String'];
};


export type MutationCreatePaymentReminderArgs = {
  docReminder: Array<DocReminderInput>;
  input: PaymentReminderInput;
};


export type MutationCreateCustomerReminderArgs = {
  inputReminder: CustomerReminderInput;
};


export type MutationCreateContractArgs = {
  siteInput: Array<SiteInput>;
  input: ContractInput;
};


export type MutationDeleteContractAllAccessArgs = {
  ID: Scalars['String'];
};


export type MutationUpdateContractArgs = {
  input: ContractInput;
};


export type MutationInsertContactsArgs = {
  contactInput: Array<ContactPersonInput>;
  maincon: Scalars['Boolean'];
  ID: Scalars['String'];
};


export type MutationActionOnContractStatusArgs = {
  input: ContractActionInput;
  ID: Scalars['String'];
};


export type MutationCreateContractExtensionDateArgs = {
  input: ContractExtensionInput;
  ID: Scalars['String'];
};


export type MutationCreateContractCertificateArgs = {
  input: ContractCertificateInput;
  ID: Scalars['String'];
};


export type MutationCreateContractGuaranteeArgs = {
  input: ContractGuaranteeInput;
};


export type MutationUpdateContractGuaranteeArgs = {
  input: ContractGuaranteeInput;
};


export type MutationCreateContractInsuranceArgs = {
  input: ContractInsuranceInput;
};


export type MutationUpdateContractInsuranceArgs = {
  input: ContractInsuranceInput;
};


export type MutationCancelContractGuaranteeArgs = {
  input: ContractGuaranteeCancellationInput;
};


export type MutationCancelContractInsuranceArgs = {
  input: ContractInsuranceCancellationInput;
};


export type MutationUpdateProjectBudgetArgs = {
  input: ProjectBudgetInput;
};


export type MutationDeleteProjectBudgetArgs = {
  input: ProjectBudgetDeleteInput;
};


export type MutationAddOriginalBudgetArgs = {
  budgetInput: Array<ProjectBudgetInput>;
  contractID: Scalars['String'];
};


export type MutationAddRevisedBudgetArgs = {
  file?: Maybe<Array<Scalars['Upload']>>;
  budgetAmtInput: BudgetAmtInput;
  contractID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationCreateContractVoArgs = {
  voDetails?: Maybe<Array<ContractVoDetailsInput>>;
  input: ContractVoInput;
};


export type MutationUpdateContractVoArgs = {
  voDetails?: Maybe<Array<ContractVoDetailsInput>>;
  input: ContractVoInput;
};


export type MutationActionOnVoStatusArgs = {
  itemApprovedAmount?: Maybe<Array<VoItemApprovedAmountInput>>;
  input: ContractVoActionInput;
  ID: Scalars['String'];
};


export type MutationDeleteContractVoArgs = {
  ID: Scalars['String'];
};


export type MutationCreateContractClaimArgs = {
  input: ContractClaimInput;
};


export type MutationUpdateContractClaimArgs = {
  input: ContractClaimInput;
};


export type MutationDeleteContractClaimArgs = {
  ID: Scalars['String'];
};


export type MutationSubmitRetentionClaimArgs = {
  input: ClaimRetentionInput;
};


export type MutationApproveRetentionClaimArgs = {
  input: ClaimRetentionInput;
};


export type MutationSubmitClaimArgs = {
  voRevenueWbsAlloc?: Maybe<Array<ContractClaimVoRevenueWbsAllocInput>>;
  voAlloc?: Maybe<Array<ContractClaimVoAllocInput>>;
  claimAlloc?: Maybe<Array<ContractClaimAllocInput>>;
  deductionLedger?: Maybe<Array<LedgerInput>>;
  input: ContractClaimInput;
};


export type MutationApproveClaimArgs = {
  voRevenueWbsAlloc?: Maybe<Array<ContractClaimVoRevenueWbsAllocInput>>;
  voAlloc?: Maybe<Array<ContractClaimVoAllocInput>>;
  claimAlloc?: Maybe<Array<ContractClaimAllocInput>>;
  input: ContractClaimInput;
};


export type MutationUpdateClaimDetailsArgs = {
  input: ClaimDetailsInput;
  ID: Scalars['String'];
};


export type MutationRejectClaimArgs = {
  retentionClaim?: Maybe<Scalars['Boolean']>;
  input: RejectInput;
  ID: Scalars['String'];
};


export type MutationCancelClaimArgs = {
  retentionClaim?: Maybe<Scalars['Boolean']>;
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateContractAdvanceArgs = {
  input: ContractReceiptInput;
};


export type MutationUpdateContractAdvanceArgs = {
  input: ContractReceiptInput;
};


export type MutationDeleteContractAdvanceArgs = {
  ID: Scalars['String'];
};


export type MutationCancelContractAdvanceArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateContractReceiptArgs = {
  input: ContractReceiptInput;
};


export type MutationUpdateContractReceiptArgs = {
  input: ContractReceiptInput;
};


export type MutationDeleteContractReceiptArgs = {
  ID: Scalars['String'];
};


export type MutationCreateContractRefundArgs = {
  input: ContractReceiptInput;
};


export type MutationUpdateContractRefundArgs = {
  input: ContractReceiptInput;
};


export type MutationDeleteContractRefundArgs = {
  ID: Scalars['String'];
};


export type MutationCancelContractRefundArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationMakeContractRefundArgs = {
  ledger: Array<LedgerInput>;
  input: ContractReceiptInput;
};


export type MutationCancelContractReceiptArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateContractMiscInvoiceArgs = {
  input: ContractTransactionWithItemInput;
};


export type MutationUpdateContractMiscInvoiceArgs = {
  input: ContractTransactionWithItemInput;
};


export type MutationDeleteContractMiscInvoiceArgs = {
  ID: Scalars['String'];
};


export type MutationCancelContractMiscInvoiceArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateContractDnArgs = {
  input: ContractTransactionWithItemInput;
};


export type MutationUpdateContractDnArgs = {
  input: ContractTransactionWithItemInput;
};


export type MutationDeleteContractDnArgs = {
  ID: Scalars['String'];
};


export type MutationCancelContractDnArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateContractCnArgs = {
  input: ContractTransactionWithItemInput;
};


export type MutationUpdateContractCnArgs = {
  input: ContractTransactionWithItemInput;
};


export type MutationDeleteContractCnArgs = {
  ID: Scalars['String'];
};


export type MutationCancelContractCnArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateClientDnArgs = {
  input: ContractTransactionWithItemInput;
};


export type MutationUpdateClientDnArgs = {
  input: ContractTransactionWithItemInput;
};


export type MutationDeleteClientDnArgs = {
  ID: Scalars['String'];
};


export type MutationCancelClientDnArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateClientCnArgs = {
  input: ContractTransactionWithItemInput;
};


export type MutationUpdateClientCnArgs = {
  input: ContractTransactionWithItemInput;
};


export type MutationDeleteClientCnArgs = {
  ID: Scalars['String'];
};


export type MutationCancelClientCnArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationMakeContractAllocationArgs = {
  ledger: Array<LedgerInput>;
};


export type MutationResetContractAllocationArgs = {
  receiptPayment?: Maybe<Scalars['Boolean']>;
  creditID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
};


export type MutationCreateCostCodeArgs = {
  input: CostCodeInput;
};


export type MutationCreateCustomerCompanyAssignmentArgs = {
  input: CustomerCompanyAssignmentInput;
};


export type MutationUpdateCustomerCompanyAssignmentArgs = {
  input: CustomerCompanyAssignmentInput;
};


export type MutationAssignCustomerCompanyArgs = {
  companyID: Array<Scalars['String']>;
  customerID: Scalars['String'];
};


export type MutationUnassignCustomerCompanyArgs = {
  companyID: Array<Scalars['String']>;
  customerID: Scalars['String'];
};


export type MutationAssignCustomerWithCompanyArgs = {
  customerID: Array<Scalars['String']>;
  companyID: Scalars['String'];
};


export type MutationUnassignCustomerWithCompanyArgs = {
  customerID: Array<Scalars['String']>;
  companyID: Scalars['String'];
};


export type MutationCreateCustomerArgs = {
  input: CustomerInput;
};


export type MutationUpdateCustomerArgs = {
  input: CustomerInput;
};


export type MutationCreatePlatformCustomerArgs = {
  input: CustomerInput;
};


export type MutationUpdatePlatformCustomerArgs = {
  input: CustomerInput;
};


export type MutationDeleteCustomerArgs = {
  input: CustomerDeleteInput;
};


export type MutationAddCustomerContactArgs = {
  contactInput: Array<ContactPersonInput>;
  platformAccess: Scalars['Boolean'];
  ID: Scalars['String'];
};


export type MutationDeletePlatformCustomerProfileArgs = {
  ID: Scalars['String'];
};


export type MutationCustomerImportValidationArgs = {
  input: Array<CustomerImportInput>;
};


export type MutationCreateCustomerImportArgs = {
  batchID: Scalars['String'];
};


export type MutationCreateFollowUpArgs = {
  input: FollowUpInput;
};


export type MutationUpdateFollowUpArgs = {
  input: FollowUpInput;
};


export type MutationDeleteCbExportArgs = {
  input: CbExportDeleteInput;
};


export type MutationExportCbLedgerArgs = {
  ledgers: Array<CbLedgerInput>;
  input: CbExportInput;
};


export type MutationDeleteGlExportArgs = {
  input: GlExportDeleteInput;
};


export type MutationExportGlLedgerArgs = {
  ledgers: Array<GlLedgerInput>;
  input: GlExportInput;
};


export type MutationUpdateGlExportArgs = {
  input: UpdateGlExportInput;
};


export type MutationCreateGlAssociationArgs = {
  input: GlAssociationInput;
};


export type MutationUpdateGlAssociationArgs = {
  input: GlAssociationInput;
};


export type MutationCreateGlTransactionArgs = {
  input: GlTransactionInput;
};


export type MutationUpdateGlTransactionArgs = {
  input: GlTransactionInput;
};


export type MutationCreateGlInterfaceMasterArgs = {
  input: GlInterfaceMasterInput;
};


export type MutationUpdateGlInterfaceMasterArgs = {
  input: GlInterfaceMasterInput;
};


export type MutationDeleteGlInterfaceMasterArgs = {
  input: GlInterfaceSetupDeleteInput;
};


export type MutationCreateGlInterfaceDetailArgs = {
  input: GlInterfaceDetailInput;
};


export type MutationUpdateGlInterfaceDetailArgs = {
  input: GlInterfaceDetailInput;
};


export type MutationDeleteGlInterfaceDetailArgs = {
  input: GlInterfaceSetupDeleteInput;
};


export type MutationCreateGlTaxDynamicArgs = {
  input: GlTaxDynamicInput;
};


export type MutationUpdateGlTaxDynamicArgs = {
  input: GlTaxDynamicInput;
};


export type MutationDeleteGlTaxDynamicArgs = {
  input: GlInterfaceSetupDeleteInput;
};


export type MutationCreateGlAdjustmentDynamicArgs = {
  input: GlAdjustmentDynamicInput;
};


export type MutationUpdateGlAdjustmentDynamicArgs = {
  input: GlAdjustmentDynamicInput;
};


export type MutationDeleteGlAdjustmentDynamicArgs = {
  input: GlInterfaceSetupDeleteInput;
};


export type MutationCancelGlDynamicSetupArgs = {
  input?: Maybe<GlInterfaceDynamicInput>;
  ID: Scalars['String'];
};


export type MutationSaveGlAccountCodeArgs = {
  glInterfaceInput: Array<GlInterfaceDynamicInput>;
  accountType: Scalars['String'];
};


export type MutationCreateGlInterfaceDataArgs = {
  input: Array<GlInterfaceDetailInput>;
  accountType: Scalars['String'];
};


export type MutationInsertNewGlArgs = {
  creditAccountCode2?: Maybe<Scalars['String']>;
  creditAccountCode1?: Maybe<Scalars['String']>;
  debitAccountCode2?: Maybe<Scalars['String']>;
  debitAccountCode1?: Maybe<Scalars['String']>;
  index: Scalars['Float'];
  transactionName: Scalars['String'];
  associateName: Scalars['String'];
};


export type MutationDeleteGlTransactionArgs = {
  transactionName: Scalars['String'];
  associationName: Scalars['String'];
};


export type MutationUpdateDepartmentGlAccountCodeArgs = {
  input: Array<DepartmentGlInterfaceInput>;
};


export type MutationCreateGuaranteeTypeArgs = {
  input: GuarInsTypeInput;
};


export type MutationUpdateGuaranteeTypeArgs = {
  input: GuarInsTypeInput;
};


export type MutationDeleteGuaranteeTypeArgs = {
  input: GuarInsTypeDeleteInput;
};


export type MutationCreateInsuranceTypeArgs = {
  input: GuarInsTypeInput;
};


export type MutationUpdateInsuranceTypeArgs = {
  input: GuarInsTypeInput;
};


export type MutationCancelGuaranteeTypeArgs = {
  input: GuarInsTypeInput;
};


export type MutationActivateGuaranteeTypeArgs = {
  input: GuarInsTypeInput;
};


export type MutationCancelInsuranceTypeArgs = {
  input: GuarInsTypeInput;
};


export type MutationActivateInsuranceTypeArgs = {
  input: GuarInsTypeInput;
};


export type MutationDeleteInsuranceTypeArgs = {
  input: GuarInsTypeDeleteInput;
};


export type MutationCreateMiscExpenseArgs = {
  input: MiscExpenseInput;
};


export type MutationUpdateMiscExpenseArgs = {
  input: MiscExpenseInput;
};


export type MutationCancelMiscExpenseArgs = {
  input: MiscExpenseCancellationInput;
  ID: Scalars['String'];
};


export type MutationDeleteMiscExpenseArgs = {
  ID: Scalars['String'];
};


export type MutationCreateCostCenterArgs = {
  input: CostCenterInput;
};


export type MutationUpdateCostCenterArgs = {
  input: CostCenterInput;
};


export type MutationDeleteCostCenterArgs = {
  input: CostCenterDeleteInput;
};


export type MutationCancelCostCenterArgs = {
  ID: Scalars['String'];
};


export type MutationCreateCurrencyExchangeArgs = {
  input: CurrencyExchangeInput;
};


export type MutationUpdateCurrencyExchangeArgs = {
  input: CurrencyExchangeInput;
};


export type MutationDeleteCurrencyExchangeArgs = {
  input: CurrencyExchangeInput;
};


export type MutationCreateConTaxTypeArgs = {
  input: ConTaxTypeInput;
};


export type MutationUpdateConTaxTypeArgs = {
  input: ConTaxTypeInput;
};


export type MutationDeleteConTaxTypeArgs = {
  input: ConTaxTypeInput;
};


export type MutationActivateConTaxTypeArgs = {
  input: ConTaxTypeInput;
};


export type MutationCreateConTaxSchemeArgs = {
  input: ConTaxSchemeInput;
};


export type MutationUpdateConTaxSchemeArgs = {
  input: ConTaxSchemeInput;
};


export type MutationDeleteConTaxSchemeArgs = {
  input: ConTaxSchemeInput;
};


export type MutationActivateConTaxSchemeArgs = {
  input: ConTaxSchemeInput;
};


export type MutationCreateConTaxEffectiveDateArgs = {
  input: ConTaxEffectiveDateInput;
};


export type MutationUpdateConTaxEffectiveDateArgs = {
  input: ConTaxEffectiveDateInput;
};


export type MutationDeleteConTaxEffectiveDateArgs = {
  input: ConTaxEffectiveDateInput;
};


export type MutationActivateConTaxEffectiveDateArgs = {
  input: ConTaxEffectiveDateInput;
};


export type MutationInsertConTaxArgs = {
  taxEffectiveInput: Array<TaxEffectiveInput>;
  conTaxSchemeInput: ConTaxSchemeInput;
};


export type MutationUpdateConTaxArgs = {
  taxEffectiveInput: Array<TaxEffectiveInput>;
  conTaxSchemeInput: ConTaxSchemeInput;
};


export type MutationDeleteTaxSchemeArgs = {
  ID: Scalars['String'];
};


export type MutationCreateProfitRecogFormulaArgs = {
  input: ProfitRecogFormulaInput;
};


export type MutationUpdateProfitRecogFormulaArgs = {
  input: ProfitRecogFormulaInput;
};


export type MutationDeleteProfitRecogFormulaArgs = {
  input: ProfitRecogFormulaDeleteInput;
};


export type MutationCreateProfitRecogParameterArgs = {
  input: ProfitRecogParameterInput;
};


export type MutationUpdateProfitRecogParameterArgs = {
  name: Scalars['String'];
};


export type MutationCreateProfitRecogAssignmentArgs = {
  input: ProfitRecogAssignmentInput;
};


export type MutationUpdateProfitRecogAssignmentArgs = {
  input: ProfitRecogAssignmentInput;
};


export type MutationCreateProfitRecogGenerationArgs = {
  input: ProfitRecogGenerationInput;
};


export type MutationUpdateProfitRecogGenerationArgs = {
  input: ProfitRecogGenerationInput;
};


export type MutationCreateProfitRecogGenParamAssignmentArgs = {
  input: ProfitRecogGenParamAssgmtInput;
};


export type MutationUpdateProfitRecogGenParamAssignmentArgs = {
  input: ProfitRecogGenParamAssgmtInput;
};


export type MutationCreateProfitGenerationArgs = {
  input: ProfitRecogGenerationInput;
};


export type MutationUpdateProfitGenerationArgs = {
  profitRecogStatus: ProfitRecogStatus;
  profitRecogGenerationID: Scalars['String'];
  input: ProfitRecogGenerationInput;
};


export type MutationUpdateProfitRecogFormulaAssignmentArgs = {
  input: UpdateProfitRecogFormulaInput;
};


export type MutationCreateProfitRecogGlAccountCodeArgs = {
  input: ProfitRecogGlAccountCodeInput;
};


export type MutationUpdateProfitRecogGlAccountCodeArgs = {
  input: ProfitRecogGlAccountCodeInput;
};


export type MutationCreateProfitRecogGlJournalArgs = {
  input: ProfitRecogGlJournalInput;
};


export type MutationUpdateProfitRecogGlJournalArgs = {
  input: ProfitRecogGlJournalInput;
};


export type MutationCreateProjectExpenseArgs = {
  itemInput?: Maybe<Array<ProjectExpenseItemInput>>;
  input: ProjectExpenseInput;
};


export type MutationUpdateProjectExpenseArgs = {
  itemInput?: Maybe<Array<ProjectExpenseItemInput>>;
  input: ProjectExpenseInput;
};


export type MutationDeleteProjectExpenseArgs = {
  ID: Scalars['String'];
};


export type MutationCancelProjectExpenseArgs = {
  input: ProjectExpenseCancelInput;
  ID: Scalars['String'];
};


export type MutationRejectProjectExpenseArgs = {
  input: ProjectExpenseRejectInput;
  ID: Scalars['String'];
};


export type MutationCreateProjectOpportunityAttachmentArgs = {
  input: ProjectOpportunityAttachmentInput;
};


export type MutationUpdateProjectOpportunityAttachmentArgs = {
  input: ProjectOpportunityAttachmentInput;
};


export type MutationDeleteProjectOpportunityAttachmentArgs = {
  input: ProjectOpportunityAttachmentDeleteInput;
};


export type MutationCreateProjectOpportunityArgs = {
  input: ProjectOpportunityInput;
};


export type MutationUpdateProjectOpportunityArgs = {
  input: ProjectOpportunityInput;
};


export type MutationDeleteProjectOpportunityArgs = {
  input: ProjectOpportunityDeleteInput;
};


export type MutationCreateProjectOpportunityActivityLogsArgs = {
  input: ProjectOpportunityActivityLogInput;
  projectOpportunityID: Scalars['String'];
};


export type MutationCreateProjectOpportunitySubmissionArgs = {
  input: ProjectOpportunitySubmissionInput;
};


export type MutationUpdateProjectOpportunitySubmissionArgs = {
  input: ProjectOpportunitySubmissionInput;
};


export type MutationDeleteProjectOpportunitySubmissionArgs = {
  input: ProjectOpportunitySubmissionDeleteInput;
};


export type MutationCreateWbsBudgetExcelArgs = {
  contractID: Scalars['String'];
  WBSAndBudgetExcelInput: Array<WbsAndBudgetExcelInput>;
};


export type MutationCreateBudgetArgs = {
  input: WbsBudgetInput;
};


export type MutationUpdateBudgetArgs = {
  input: WbsBudgetInput;
};


export type MutationSubmitBudgetArgs = {
  input: WbsBudgetSubmitApproveInput;
};


export type MutationActionOnBudgetStatusArgs = {
  input: WbsBudgetActionInput;
  ID: Scalars['String'];
};


export type MutationCreateBudgetRevisionArgs = {
  input: WbsBudgetRevisionInput;
};


export type MutationCopyBudgetContractArgs = {
  wbsIDs: Array<Scalars['String']>;
  input: CopyBudgetInput;
};


export type MutationDeleteBudgetArgs = {
  ID: Scalars['String'];
};


export type MutationSwitchBudgetArgs = {
  selectedBudgetID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationCreateWbsBudgetDetailArgs = {
  input: WbsBudgetDetailInput;
};


export type MutationUpdateWbsBudgetDetailArgs = {
  input: WbsBudgetDetailInput;
};


export type MutationDeleteBudgetDetailArgs = {
  IDs: Array<Scalars['String']>;
};


export type MutationCreateBudgetDetailArgs = {
  input: WbsBudgetDetailInput;
};


export type MutationUpdateBudgetDetailArgs = {
  input: WbsBudgetDetailInput;
};


export type MutationUpdateBudgetScheduleArgs = {
  costAmts: Array<Scalars['Float']>;
  IDs: Array<Scalars['String']>;
  wbsBudgetDetailID: Scalars['String'];
};


export type MutationUpdateWbsDayworkCostRevenueArgs = {
  input: WbsDayworkInput;
};


export type MutationCreateWbsBudgetTransferArgs = {
  itemInput?: Maybe<Array<WbsBudgetTransferItemInput>>;
  input: WbsBudgetTransferInput;
};


export type MutationUpdateWbsBudgetTransferArgs = {
  itemInput?: Maybe<Array<WbsBudgetTransferItemInput>>;
  input: WbsBudgetTransferInput;
};


export type MutationDeleteBudgetTransferArgs = {
  ID: Scalars['String'];
};


export type MutationActionOnBudgetTransferStatusArgs = {
  input: WbsBudgetTransferActionInput;
  ID: Scalars['String'];
};


export type MutationCreateWbsArgs = {
  input: WbsInput;
};


export type MutationUpdateWbsArgs = {
  input: WbsInput;
};


export type MutationDeleteWbsArgs = {
  ID: Scalars['String'];
};


export type MutationMoveSequenceWbsArgs = {
  moveDown?: Maybe<Scalars['Boolean']>;
  ID: Scalars['String'];
};


export type MutationDefaultWbsArgs = {
  isDefault?: Maybe<Scalars['Boolean']>;
  contractID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationExecuteWbsImportStoreProcedureArgs = {
  batchID: Scalars['String'];
};


export type MutationWbsImportArgs = {
  batchID: Scalars['String'];
  contractID: Scalars['String'];
};


export type MutationWbsImportValidationArgs = {
  WBSImportInput: Array<WbsImportInput>;
  contractID: Scalars['String'];
};


export type MutationCreateWbsScheduleArgs = {
  input: WbsScheduleInput;
};


export type MutationUpdateWbsScheduleArgs = {
  input: WbsScheduleInput;
};


export type MutationCreateWbsUtilizedBudgetArgs = {
  input: WbsUtilizedBudgetInput;
};


export type MutationUpdateWbsUtilizedBudgetArgs = {
  input: WbsUtilizedBudgetInput;
};


export type MutationDeleteWbsUtilizedBudgetArgs = {
  input: WbsUtilizedBudgetDeleteInput;
};


export type MutationActionOnUtilizedBudgetStatusArgs = {
  input: WbsUtilizedBudgetActionInput;
  ID: Scalars['String'];
};


export type MutationCreateRolePermissionArgs = {
  permissionArr: Array<ContractPermission>;
  input: RoleInput;
};


export type MutationUpdateRolePermissionArgs = {
  permissionArr: Array<ContractPermission>;
  input: RoleInput;
};


export type MutationUpdateAlertSqlCodeArgs = {
  allCode?: Maybe<Scalars['Boolean']>;
  startCode?: Maybe<Scalars['String']>;
  arrCode?: Maybe<Array<Scalars['String']>>;
};


export type MutationDeleteExistPdfArgs = {
  description?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
};


export type MutationDeleteDocumentsArgs = {
  IDs?: Maybe<Array<Scalars['String']>>;
};


export type MutationUploadPdfpoArgs = {
  input: LedgerPdfInput;
};


export type MutationUploadPdfDocumentArgs = {
  input: LedgerPdfInput;
};


export type MutationShareAttachmentArgs = {
  file: Scalars['Upload'];
  contractID?: Maybe<Scalars['String']>;
};


export type MutationCreateContractNewsArgs = {
  input: ContractNewsInput;
};


export type MutationUpdateContractNewsArgs = {
  input: ContractNewsInput;
};


export type MutationDeleteContractNewsArgs = {
  input: ContractNewsInput;
};


export type MutationCreateContractNewsReadArgs = {
  input: ContractNewsReadInput;
};


export type MutationUpdateContractNewsReadArgs = {
  input: ContractNewsReadInput;
};


export type MutationDeleteContractNewsReadArgs = {
  input: ContractNewsReadInput;
};


export type MutationDeleteCollectionActivityArgs = {
  input: CollectionActivityDeleteId;
};


export type MutationCreateCollectionActivityArgs = {
  input: CollectionActivityInput;
};


export type MutationDeleteColActivityArgs = {
  collectionActivityID: Scalars['String'];
};


export type MutationUpdateCollectionActivityArgs = {
  input: CollectionActivityInput;
};


export type MutationCreateMultipleCollectionActivityArgs = {
  input: Array<CollectionActivityInput>;
};


export type MutationUpdateCollectionStatusArgs = {
  input: CollectionActivityStatusInput;
};


export type MutationPatchNewDocNumArgs = {
  docCode: ContractDocNum;
};


export type MutationCreateDocNumRunningNumberArgs = {
  docCode: ContractDocNum;
  accountID: Scalars['String'];
};


export type MutationGenerateDocumentNumberSetupArgs = {
  companyID: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationResetGeneralRunningNumberArgs = {
  accountID: Scalars['String'];
};


export type MutationResetContractRunningNumberArgs = {
  accountID: Scalars['String'];
};


export type MutationContractRunNumCheckerArgs = {
  contractID: Scalars['String'];
};


export type MutationCreateContractRunNumArgs = {
  maincon: Scalars['Boolean'];
  contractID: Scalars['String'];
};


export type MutationDeleteDocRunningNumberArgs = {
  accountID: Scalars['String'];
};


export type MutationCreateCompanyArgs = {
  input: CompanyInput;
};


export type MutationCreateRawCompanyArgs = {
  input: CompanyInput;
};


export type MutationUpdateCompanyArgs = {
  input: CompanyInput;
};


export type MutationUpdateRawCompanyArgs = {
  input: CompanyInput;
};


export type MutationDeleteCompanyArgs = {
  CompanyID: Scalars['String'];
};


export type MutationDeleteRawCompanyArgs = {
  CompanyID: Scalars['String'];
};


export type MutationUpdateContactArgs = {
  input: ContactInput;
};


export type MutationAddContractContactArgs = {
  input: ContactPersonInput;
  contractID: Scalars['String'];
};


export type MutationAddSubcontractContactArgs = {
  contactInput: Array<ContactPersonInput>;
  ID: Scalars['String'];
};


export type MutationDeleteSubcontractDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteSiteDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteContractDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteAssociateDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteLedgersWorkFlowArgs = {
  itemName?: Maybe<Scalars['String']>;
  tableName: Scalars['String'];
  accountID: Scalars['String'];
  docRefID: Scalars['String'];
};


export type MutationDeleteSpecificLedgersArgs = {
  itemName?: Maybe<Scalars['String']>;
  tableName: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationDeletePoDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteClientLedgersDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteSubconLedgersDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteSupplierLedgersDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteCostDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeletePolicyDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteNotificationDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteWorkFlowDataArgs = {
  accountID: Scalars['String'];
};


export type MutationUpdateContractDataArgs = {
  andWhereOutput?: Maybe<Scalars['String']>;
  andWhereColumn?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type MutationUpdateSubcontractDataArgs = {
  andWhereOutput?: Maybe<Scalars['String']>;
  andWhereColumn?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type MutationUpdateClientDataArgs = {
  andWhereOutput?: Maybe<Scalars['String']>;
  andWhereColumn?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type MutationUpdateSubconDataArgs = {
  andWhereOutput?: Maybe<Scalars['String']>;
  andWhereColumn?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type MutationUpdateSupplierDataArgs = {
  andWhereOutput?: Maybe<Scalars['String']>;
  andWhereColumn?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type MutationUpdatePoDataArgs = {
  andWhereOutput?: Maybe<Scalars['String']>;
  andWhereColumn?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type MutationUpdateLedgersDataArgs = {
  andWhereOutput?: Maybe<Scalars['String']>;
  andWhereColumn?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type MutationRejectLedgerTableArgs = {
  andWhereOutput?: Maybe<Scalars['String']>;
  andWhereColumn?: Maybe<Scalars['String']>;
  columnStatus: Scalars['String'];
  tableName: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationResetRolePermissionArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationRemoveSuperUserAclArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationPatchEditableAllocationArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchContractAllocationArgs = {
  accountID?: Maybe<Scalars['String']>;
  maincon: Scalars['Boolean'];
};


export type MutationPatchSupplierAllocationArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationPatchContractAllocationIDsArgs = {
  accountID?: Maybe<Scalars['String']>;
  maincon: Scalars['Boolean'];
};


export type MutationPatchRejClaimDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchAdjustmentDocRefDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchClaimBaseDocAmtDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchCancelledAllocClaimDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchCndnClaimDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchRetentionClaimRejectArgs = {
  accountID?: Maybe<Scalars['String']>;
  tableName: Scalars['String'];
};


export type MutationPatchRetentionClaimDataArgs = {
  tableName: Scalars['String'];
};


export type MutationPatchDocumentsDataArgs = {
  insertData: Scalars['Boolean'];
  includeLogo: Scalars['Boolean'];
  accountIDs: Array<Scalars['String']>;
};


export type MutationPatchCompanyImageArgs = {
  accountIDs?: Maybe<Array<Scalars['String']>>;
};


export type MutationPatchContractConSubconDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchRealConDateDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchConDateDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchVoDateDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchGuarInsDateDataArgs = {
  guarantee: Scalars['Boolean'];
  maincon: Scalars['Boolean'];
};


export type MutationPatchCompanyInAccountArgs = {
  deleteDoc: Scalars['Boolean'];
  type: Scalars['String'];
};


export type MutationPatchNullDocNoExpensesArgs = {
  accountID?: Maybe<Scalars['String']>;
  filter: Scalars['String'];
};


export type MutationPatchCostCategoryDataArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationPatchDueDateNullDataArgs = {
  accountID?: Maybe<Scalars['String']>;
  associate: Scalars['String'];
};


export type MutationPatchNullTrxDateDataArgs = {
  tableName: Scalars['String'];
};


export type MutationRemoveAllocDataArgs = {
  confirmDelete: Scalars['Boolean'];
  tableName: Scalars['String'];
};


export type MutationPatchPlantStatusDataArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationPatchProjectExpenseArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationPatchMainSiteDataArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationInsertLongLatDataArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationInsertSiteInventoryDataArgs = {
  siteID: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationPatchEmptyWorkflowArgs = {
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  IDs: Array<Scalars['String']>;
  refTable: Scalars['String'];
};


export type MutationCreateDocumentFolderArgs = {
  input: DocumentFolderInput;
};


export type MutationUpdateDocumentFolderArgs = {
  input: DocumentFolderInput;
};


export type MutationDeleteDocumentFolderArgs = {
  ID: Scalars['String'];
};


export type MutationExtraSystemAdminSetupArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationExtraGeneralSettingSetupArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationExtraGlGenSettingSetupArgs = {
  accountID: Scalars['String'];
};


export type MutationPlatformContactSetupArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationInitialCurrencySetupArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationInitialMasterFileSetupArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteMasterFileArgs = {
  accountID: Scalars['String'];
};


export type MutationInitialSiteMasterFileSetupArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteSiteMasterFileArgs = {
  accountID: Scalars['String'];
};


export type MutationInitialGeneralSettingSetupArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteGenSettingArgs = {
  accountID: Scalars['String'];
};


export type MutationInitialGlInterfaceSetupArgs = {
  accountID: Scalars['String'];
};


export type MutationInitialDocNumSetupArgs = {
  companyID: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationCreateBatchPaymentDetailArgs = {
  input: BatchPaymentDetailInput;
};


export type MutationUpdateBatchPaymentDetailArgs = {
  input: BatchPaymentDetailInput;
};


export type MutationDeleteBatchPaymentDetailArgs = {
  input: BatchPaymentDetailDeleteInput;
};


export type MutationCancelBatchPaymentDetailArgs = {
  input: BatchPaymentDetailDeleteInput;
};


export type MutationCreateBatchPaymentHeaderArgs = {
  input: BatchPaymentHeaderInput;
};


export type MutationUpdateBatchPaymentHeaderArgs = {
  input: BatchPaymentHeaderInput;
};


export type MutationDeleteBatchPaymentHeaderArgs = {
  input: BatchPaymentHeaderDeleteInput;
};


export type MutationCancelBatchPaymentHeaderArgs = {
  input: BatchPaymentHeaderDeleteInput;
};


export type MutationSubmitBatchPaymentArgs = {
  detailInput: Array<BatchPaymentDetailInput>;
  headerInput: BatchPaymentHeaderInput;
};


export type MutationConfirmBatchPaymentArgs = {
  detailInput: Array<ApprovePaymentDetailInput>;
  companyID?: Maybe<Scalars['String']>;
  headerID: Scalars['String'];
};


export type MutationPayBatchPaymentArgs = {
  detailIDs: Array<Scalars['String']>;
  headerID: Scalars['String'];
};


export type MutationCreateSiteMaterialPolicyArgs = {
  input: SiteMaterialPolicyInput;
};


export type MutationUpdateSiteMaterialPolicyArgs = {
  input: SiteMaterialPolicyInput;
};


export type MutationCreateSiteMaterialContractPolicyArgs = {
  input: SiteMaterialContractPolicyInput;
};


export type MutationUpdateSiteMaterialContractPolicyArgs = {
  input: SiteMaterialContractPolicyInput;
};


export type MutationCreateMaterialPolicyArgs = {
  procurementSubscriber: Scalars['Boolean'];
  materialIDs: Array<SiteMaterialPolicyInput>;
};


export type MutationCreateMaterialContractPolicyArgs = {
  contractID: Scalars['String'];
  procurementSubscriber: Scalars['Boolean'];
  materialIDs: Array<SiteMaterialPolicyInput>;
};


export type MutationCreateTaxMasterFileArgs = {
  input: TaxInput;
};


export type MutationEditTaxMasterFileArgs = {
  input: EditTaxInput;
};


export type MutationDeleteTaxMasterFileArgs = {
  deleteInput: Array<DeleteTaxInput>;
  taxId: Scalars['String'];
};


export type MutationDeactivateTaxMasterFileArgs = {
  taxId: Scalars['String'];
};


export type MutationActivateTaxMasterFileArgs = {
  taxId: Scalars['String'];
};


export type MutationCreateSuperUserArgs = {
  accountID: Scalars['String'];
  input: UserInput;
};


export type MutationCreateContractNotificationArgs = {
  input: ContractNotificationInput;
};


export type MutationUpdateContractNotificationArgs = {
  input: ContractNotificationInput;
};


export type MutationDeleteContractNotificationArgs = {
  input: ContractNotificationInput;
};


export type MutationReadNotificationArgs = {
  ID?: Maybe<Scalars['String']>;
};


export type MutationMarkAsReadNotiArgs = {
  IDs?: Maybe<Array<Scalars['String']>>;
};


export type MutationDeleteNotiArgs = {
  IDs?: Maybe<Array<Scalars['String']>>;
};


export type MutationPatchWorkDeskDataArgs = {
  tableName: Scalars['String'];
};


export type MutationCreateContractRoleArgs = {
  permissionArr: Array<ContPermission>;
  input: RoleInput;
};


export type MutationUpdateContractRoleArgs = {
  permissionArr: Array<ContPermission>;
  input: RoleInput;
};


export type MutationCreateSiteRoleArgs = {
  permissionArr: Array<SitePermission>;
  input: RoleInput;
};


export type MutationUpdateSiteRoleArgs = {
  permissionArr: Array<SitePermission>;
  input: RoleInput;
};


export type MutationDeleteRolePermissionArgs = {
  forceDelete?: Maybe<Scalars['Boolean']>;
  roleID: Scalars['String'];
};


export type MutationCreateEntityRoleUserArgs = {
  input: EntityRoleUserAsgInput;
};


export type MutationCreateListEntityRoleUserArgs = {
  roleUserIDs: Array<RoleUserInput>;
  contractID: Scalars['String'];
};


export type MutationCreateAdjustmentTypeArgs = {
  input: AdjustmentTypeInput;
};


export type MutationUpdateAdjustmentTypeArgs = {
  input: AdjustmentTypeInput;
};


export type MutationDeleteAdjustmentTypeArgs = {
  input: AdjustmentTypeInput;
};


export type MutationCreateAlertArgs = {
  input: AlertInput;
};


export type MutationUpdateAlertArgs = {
  input: AlertInput;
};


export type MutationDeleteAlertArgs = {
  input: AlertInput;
};


export type MutationUpdateAlertAssignmentArgs = {
  input: Array<AlertAssignmentInput>;
  isFromAdmin?: Maybe<Scalars['Boolean']>;
};


export type MutationCancelApprovalPolicyArgs = {
  input: ApprovalPolicyDeleteInput;
};


export type MutationCancelApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyDeleteInput;
};


export type MutationCreateApprovalPolicyArgs = {
  input: ApprovalPolicyInput;
};


export type MutationUpdateApprovalPolicyArgs = {
  input: ApprovalPolicyInput;
};


export type MutationCreateApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyAssignmentInput;
};


export type MutationUpdateApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyAssignmentInput;
};


export type MutationDeleteApprovalPolicyArgs = {
  input: ApprovalPolicyDeleteInput;
};


export type MutationDeleteApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyAssignmentDeleteInput;
};


export type MutationDeleteMultipleApprovalPolicyArgs = {
  input: ApprovalPolicyDeleteInput;
};


export type MutationDeleteMultipleApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyAssignmentDeleteInput;
};


export type MutationCreateBankAccountArgs = {
  input: BankAccountInput;
};


export type MutationUpdateBankAccountArgs = {
  input: BankAccountInput;
};


export type MutationDeleteBankAccountArgs = {
  input: BankAccountDeleteInput;
};


export type MutationActivateBankAccountArgs = {
  input: BankAccountDeleteInput;
};


export type MutationCancelBankAccountArgs = {
  input?: Maybe<BankAccountCancellationInput>;
  ID: Scalars['String'];
};


export type MutationDeleteCalendarArgs = {
  input: CalendarDeleteInput;
};


export type MutationCancelCalendarArgs = {
  input: CalendarDeleteInput;
};


export type MutationActivateCalendarArgs = {
  input: CalendarDeleteInput;
};


export type MutationCreateCalendarArgs = {
  publicHolidayID: Array<Scalars['String']>;
  input: CalendarInput;
};


export type MutationUpdateCalendarArgs = {
  publicHolidayID: Array<Scalars['String']>;
  input: CalendarInput;
};


export type MutationCreateCalendarPhPolicyArgs = {
  input: CalendarPhPolicyInput;
};


export type MutationUpdateCalendarPhPolicyArgs = {
  input: CalendarPhPolicyInput;
};


export type MutationDeleteCalendarPhPolicyArgs = {
  input: CalendarPhPolicyDeleteInput;
};


export type MutationCancelCalendarPhPolicyArgs = {
  input: CalendarPhPolicyDeleteInput;
};


export type MutationActivateCalendarPhPolicyArgs = {
  input: CalendarPhPolicyDeleteInput;
};


export type MutationCreateCalendarWeekendPolicyArgs = {
  input: CalendarWeekendPolicyInput;
};


export type MutationUpdateCalendarWeekendPolicyArgs = {
  input: CalendarWeekendPolicyInput;
};


export type MutationDeleteCalendarWeekendPolicyArgs = {
  input: CalendarWeekendPolicyDeleteInput;
};


export type MutationCancelCalendarWeekendPolicyArgs = {
  input: CalendarWeekendPolicyDeleteInput;
};


export type MutationActivateCalendarWeekendPolicyArgs = {
  input: CalendarWeekendPolicyDeleteInput;
};


export type MutationUploadCompanyLogoArgs = {
  file?: Maybe<Scalars['Upload']>;
};


export type MutationUploadIsoLogoArgs = {
  file?: Maybe<Scalars['Upload']>;
};


export type MutationUploadCompanyIsoLogoArgs = {
  file?: Maybe<Scalars['Upload']>;
  companyID: Scalars['String'];
};


export type MutationUpdateWatermarkCompanyArgs = {
  companyID: Scalars['String'];
  input: CompanyLogoInput;
};


export type MutationToggleCompanySignatureArgs = {
  input: Array<CompanySignatureInput>;
  companyID: Scalars['String'];
};


export type MutationDeleteCostCategoryArgs = {
  input: CostCategoryUpdateInput;
};


export type MutationCancelCostCategoryArgs = {
  ID: Scalars['String'];
};


export type MutationActivateCostCategoryArgs = {
  input: CostCategoryUpdateInput;
};


export type MutationCreateBatchCostCategoryArgs = {
  input: Array<CostCategoryInput>;
};


export type MutationCreateBatchCostCategoryWithoutItemArgs = {
  input: Array<TreeCostCategoryInput>;
};


export type MutationCreateCostCategoryArgs = {
  input: CostCategoryInput;
};


export type MutationUpdateCostCategoryArgs = {
  input: CostCategoryInput;
};


export type MutationDeleteCostCategoryCostItemArgs = {
  ID: Scalars['String'];
};


export type MutationAddCostCategoryArgs = {
  input: TreeCostCategoryInput;
};


export type MutationEditCostCategoryArgs = {
  input: TreeCostCategoryInput;
};


export type MutationDeleteTreeCostCategoryArgs = {
  ID: Scalars['String'];
};


export type MutationCostCategoryImportValidationArgs = {
  CostCategoryImportInput: Array<CostCategoryImportInput>;
};


export type MutationCostCategoryImportArgs = {
  batchID: Scalars['String'];
};


export type MutationDeleteCostItemArgs = {
  input: CostItemDeleteInput;
};


export type MutationActivateCostItemArgs = {
  input: CostItemDeleteInput;
};


export type MutationCreateCostItemArgs = {
  input: CostItemInput;
};


export type MutationUpdateCostItemArgs = {
  input: CostItemInput;
};


export type MutationCreateBatchCostItemArgs = {
  input: Array<CostItemInput>;
  costCategoryID: Scalars['String'];
};


export type MutationCancelCostItemArgs = {
  ID: Scalars['String'];
};


export type MutationCostItemImportArgs = {
  batchID: Scalars['String'];
};


export type MutationCostItemImportValidationArgs = {
  CostItemImportInput: Array<CostItemImportInput>;
};


export type MutationCreateCurrencySetupArgs = {
  input: CurrencySetupInput;
};


export type MutationUpdateCurrencySetupArgs = {
  input: CurrencySetupInput;
};


export type MutationDeleteCurrencySetupArgs = {
  input: CurrencySetupDeleteInput;
};


export type MutationAddDigitalDocumentsArgs = {
  input: DigitalDocumentInput;
};


export type MutationDeleteDigitalDocumentsArgs = {
  ID: Scalars['String'];
};


export type MutationUpdateNumberFormatArgs = {
  input: NumberFormatInput;
};


export type MutationCreateDocTypeArgs = {
  input: DocTypeInput;
};


export type MutationUpdateDocTypeArgs = {
  input: DocTypeInput;
};


export type MutationCreateRunningNumberSetupArgs = {
  input: RunningNumberSetupInput;
};


export type MutationUpdateRunningNumberSetupArgs = {
  input: RunningNumberSetupInput;
};


export type MutationInactiveDocTypeArgs = {
  ID: Scalars['String'];
};


export type MutationEditNumberFormatArgs = {
  input: NumberFormatInput;
};


export type MutationUpdateDocNumSetupArgs = {
  input: DocNumInput;
  ID: Scalars['String'];
};


export type MutationCreateDocNumHeaderArgs = {
  input: DocNumHeaderInput;
};


export type MutationUpdateDocNumHeaderArgs = {
  input: DocNumHeaderInput;
};


export type MutationCreateDocNumDetailArgs = {
  input: DocNumDetailInput;
};


export type MutationUpdateDocNumDetailArgs = {
  input: DocNumDetailInput;
};


export type MutationCreateDocumentNumberHeaderArgs = {
  DocNumDetailInput: Array<DocNumDetailInput>;
  DocNumHeaderInput: DocNumHeaderInput;
};


export type MutationUpdateDocumentNumberHeaderArgs = {
  DocNumDetailInput: Array<DocNumDetailInput>;
  DocNumHeaderInput: DocNumHeaderInput;
};


export type MutationUpdateSystemAdminDocNumArgs = {
  DocNumDetailInput: Array<DocNumDetailInput>;
  DocNumHeaderInput: DocNumHeaderInput;
};


export type MutationCreateHolidayTypeArgs = {
  input: HolidayTypeInput;
};


export type MutationUpdateHolidayTypeArgs = {
  input: HolidayTypeInput;
};


export type MutationDeleteHolidayTypeArgs = {
  input: HolidayTypeDeleteInput;
};


export type MutationCancelHolidayTypeArgs = {
  input: HolidayTypeDeleteInput;
};


export type MutationActivateHolidayTypeArgs = {
  input: HolidayTypeDeleteInput;
};


export type MutationCreateJobTypeArgs = {
  input: JobTypeInput;
};


export type MutationUpdateJobTypeArgs = {
  input: JobTypeInput;
};


export type MutationDeleteJobTypeArgs = {
  input: JobTypeDeleteInput;
};


export type MutationCancelJobTypeArgs = {
  input: JobTypeDeleteInput;
};


export type MutationActivateJobTypeArgs = {
  input: JobTypeDeleteInput;
};


export type MutationCreatePlatformSuperUserArgs = {
  softwareName: Scalars['String'];
  input: UserInput;
};


export type MutationCreatePlatformUserArgs = {
  softwareCode: SoftwareCode;
  input: UserInput;
};


export type MutationUpdatePlatformUserArgs = {
  input: UserInput;
};


export type MutationCreatePlatformPasswordArgs = {
  input: PasswordInfo;
};


export type MutationCreatePlatformPasswordTestArgs = {
  input: PasswordInfo;
};


export type MutationActivatePlatformUserArgs = {
  token: Scalars['String'];
};


export type MutationPlatformLoginArgs = {
  softwareCode: SoftwareCode;
  password: Scalars['String'];
  loginId: Scalars['String'];
};


export type MutationChangePlatformPasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationResetPlatformPasswordArgs = {
  accountID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationUploadProfilePicPlatformArgs = {
  file?: Maybe<Scalars['Upload']>;
  userID: Scalars['String'];
};


export type MutationForgotPlatformPasswordArgs = {
  email: Scalars['String'];
};


export type MutationCreatePolicyGuidelineArgs = {
  roleApprovalIDs: Array<PolicyAssignmentInput>;
  input: PolicyGuidelineInput;
};


export type MutationUpdatePolicyGuidelineArgs = {
  input: Array<PolicyAssignmentInput>;
  docRefTable: Scalars['String'];
};


export type MutationCreatePolicyAmountGuidelineArgs = {
  notiRoleUserIDs: Array<NotificationGuidelineInput>;
  roleApprovalIDs: Array<PolicyAssignmentInput>;
  input: PolicyGuidelineInput;
};


export type MutationUpdatePolicyAmountGuidelineArgs = {
  notiRoleUserIDs: Array<NotificationGuidelineInput>;
  roleApprovalIDs: Array<PolicyAssignmentInput>;
  input: PolicyGuidelineInput;
  ID: Scalars['String'];
};


export type MutationDeletePolicyAmountGuidelineArgs = {
  ID: Scalars['String'];
};


export type MutationCreateNotificationGuidelineArgs = {
  input: Array<NotificationGuidelineInput>;
  docRefTable: Scalars['String'];
};


export type MutationUpdateNotificationGuidelineArgs = {
  input: Array<NotificationGuidelineInput>;
  ID: Scalars['String'];
};


export type MutationCreatePublicHolidayArgs = {
  input: PublicHolidayInput;
};


export type MutationUpdatePublicHolidayArgs = {
  input: PublicHolidayInput;
};


export type MutationDeletePublicHolidayArgs = {
  input: PublicHolidayDeleteInput;
};


export type MutationCancelPublicHolidayArgs = {
  input: PublicHolidayDeleteInput;
};


export type MutationActivatePublicHolidayArgs = {
  input: PublicHolidayDeleteInput;
};


export type MutationDeleteRevenueCategoryArgs = {
  input: RevenueCategoryDeleteInput;
};


export type MutationCancelRevenueCategoryArgs = {
  input: RevenueCategoryUpdateInput;
};


export type MutationActivateRevenueCategoryArgs = {
  input: RevenueCategoryUpdateInput;
};


export type MutationCreateRevenueCategoryArgs = {
  input: RevenueCategoryInput;
};


export type MutationUpdateRevenueCategoryArgs = {
  input: RevenueCategoryInput;
};


export type MutationCreateBatchRevenueCategoryArgs = {
  input: Array<RevenueCategoryInput>;
};


export type MutationRevenueCategoryImportValidationArgs = {
  RevenueCategoryImportInput: Array<RevenueCategoryImportInput>;
};


export type MutationRevenueCategoryImportArgs = {
  batchID: Scalars['String'];
};


export type MutationGoodReceiveNoteRpaArgs = {
  files: Array<Scalars['Upload']>;
  siteID?: Maybe<Scalars['String']>;
};


export type MutationInvoiceRpaArgs = {
  contractID: Scalars['String'];
  files: Array<Scalars['Upload']>;
};


export type MutationDeliveryOrderRpaArgs = {
  contractID: Scalars['String'];
  files: Scalars['Upload'];
};


export type MutationUpdateSAccountArgs = {
  input: SAccountInput;
};


export type MutationCreateSiteMaterialArgs = {
  input: SiteMaterialInput;
};


export type MutationUpdateSiteMaterialArgs = {
  input: SiteMaterialInput;
};


export type MutationDeleteSiteMaterialArgs = {
  input: SiteMaterialDeleteInput;
};


export type MutationCancelSiteMaterialArgs = {
  input: SiteMaterialDeleteInput;
};


export type MutationActivateSiteMaterialArgs = {
  input: SiteMaterialDeleteInput;
};


export type MutationCreateBulkSiteMaterialArgs = {
  input: Array<SiteMaterialInput>;
};


export type MutationCreateSiteWorkersArgs = {
  input: SiteWorkersInput;
};


export type MutationUpdateSiteWorkersArgs = {
  input: SiteWorkersInput;
};


export type MutationDeleteSiteWorkersArgs = {
  input: SiteWorkersInput;
};


export type MutationCreateSubcontractorTypeArgs = {
  input: SubcontractorTypeInput;
};


export type MutationUpdateSubcontractorTypeArgs = {
  input: SubcontractorTypeInput;
};


export type MutationDeleteSubcontractorTypeArgs = {
  input: SubcontractorTypeDeleteInput;
};


export type MutationCancelSubcontractorTypeArgs = {
  input: SubcontractorTypeInput;
};


export type MutationActivateSubcontractorTypeArgs = {
  input: SubcontractorTypeInput;
};


export type MutationCreateSubcontractorTypeFdwArgs = {
  ID: Scalars['String'];
};


export type MutationCreateSupplierTypeArgs = {
  input: SupplierTypeInput;
};


export type MutationUpdateSupplierTypeArgs = {
  input: SupplierTypeInput;
};


export type MutationDeleteSupplierTypeArgs = {
  input: SupplierTypeDeleteInput;
};


export type MutationCancelSupplierTypeArgs = {
  input: SupplierTypeInput;
};


export type MutationActivateSupplierTypeArgs = {
  input: SupplierTypeInput;
};


export type MutationCreateSupplierTypeFdwArgs = {
  ID: Scalars['String'];
};


export type MutationUpdateUomArgs = {
  input: UomInput;
};


export type MutationDeleteUomArgs = {
  input: UomDeleteInput;
};


export type MutationCancelUomArgs = {
  input: UomDeleteInput;
};


export type MutationActivateUomArgs = {
  input: UomDeleteInput;
};


export type MutationCreateUomArgs = {
  input: UomInput;
};


export type MutationCreateBatchUomArgs = {
  input: Array<UomInput>;
};


export type MutationUomImportArgs = {
  batchID: Scalars['String'];
};


export type MutationUomImportValidationArgs = {
  input: Array<UomImportInput>;
};


export type MutationCreateUomExchangeArgs = {
  input: UomExchangeInput;
};


export type MutationUpdateUomExchangeArgs = {
  input: UomExchangeInput;
};


export type MutationDeleteUomExchangeArgs = {
  ID: Scalars['String'];
};


export type MutationCreateUserCompanyAssignmentArgs = {
  input: UserCompanyAssignmentInput;
};


export type MutationUpdateUserCompanyAssignmentArgs = {
  input: UserCompanyAssignmentInput;
};


export type MutationAssignUserCompanyArgs = {
  companyIDs: Array<Scalars['String']>;
  userID: Scalars['String'];
};


export type MutationUnassignUserCompanyArgs = {
  companyIDs: Array<Scalars['String']>;
  userID: Scalars['String'];
};


export type MutationAssignUserWithCompanyArgs = {
  userID: Array<Scalars['String']>;
  companyID: Scalars['String'];
};


export type MutationUnassignUserWithCompanyArgs = {
  userID: Array<Scalars['String']>;
  companyID: Scalars['String'];
};


export type MutationCreateUserArgs = {
  input: UserInput;
};


export type MutationCreateUserEmailArgs = {
  input: UserInput;
};


export type MutationSendInvitationEmailArgs = {
  accountID?: Maybe<Scalars['String']>;
  userIDs?: Maybe<Array<Scalars['String']>>;
};


export type MutationResendInvitationArgs = {
  ID: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  input: UserInput;
};


export type MutationUpdateUserWithPasswordArgs = {
  input: UserInput;
};


export type MutationCreatePasswordArgs = {
  input: PasswordInfo;
};


export type MutationCreatePasswordTestArgs = {
  input: PasswordInfo;
};


export type MutationActivateUserArgs = {
  token: Scalars['String'];
};


export type MutationUnblockUserArgs = {
  input: PasswordInfo;
};


export type MutationChangePasswordSuArgs = {
  input: PasswordInfo;
};


export type MutationLoginArgs = {
  siteAccess?: Maybe<Scalars['Boolean']>;
  password: Scalars['String'];
  loginId: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationLogoutArgs = {
  entityID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type MutationResetPasswordArgs = {
  accountID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationTotpValidateArgs = {
  token: Scalars['String'];
};


export type MutationUploadProfilePicArgs = {
  file?: Maybe<Scalars['Upload']>;
  userID: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationUpdateLastEntityArgs = {
  companyID: Scalars['String'];
};


export type MutationCreateVideoCamArgs = {
  input: VideoCamInput;
};


export type MutationUpdateVideoCamArgs = {
  input: VideoCamInput;
};


export type MutationDeleteVideoCamArgs = {
  input: VideoCamDeleteInput;
};


export type MutationCancelVideoCamArgs = {
  input: VideoCamDeleteInput;
};


export type MutationActivateVideoCamArgs = {
  input: VideoCamDeleteInput;
};


export type MutationCreateWagesRatePolicyArgs = {
  input: WagesRatePolicyInput;
};


export type MutationDeleteWagesRatePolicyArgs = {
  input: WagesRatePolicyDeleteInput;
};


export type MutationCancelWagesRatePolicyArgs = {
  input: WagesRatePolicyDeleteInput;
};


export type MutationActivateWagesRatePolicyArgs = {
  input: WagesRatePolicyDeleteInput;
};


export type MutationUpdateWagesRatePolicyArgs = {
  holTypeRateIDs: Array<HolidayTypeRateInput>;
};


export type MutationCreateContractToDoArgs = {
  input: ContractToDoInput;
};


export type MutationUpdateContractToDoArgs = {
  input: ContractToDoInput;
};


export type MutationDeleteContractToDoArgs = {
  input: ContractToDoInput;
};


export type MutationCreateNotificationArgs = {
  body?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
  docRefID: Scalars['String'];
};


export type MutationCreateToDoArgs = {
  docNo: Scalars['String'];
  input: LedgerInput;
  docRefTable: Scalars['String'];
  docRefID: Scalars['String'];
};


export type MutationApproveToDoArgs = {
  IDs: Array<Scalars['String']>;
};


export type MutationRejectToDoArgs = {
  remark: Scalars['String'];
  IDs: Array<Scalars['String']>;
};


export type MutationDeleteWorkerArgs = {
  input: WorkerDeleteInput;
};


export type MutationCancelWorkerArgs = {
  input: WorkerDeleteInput;
};


export type MutationCreateWorkerArgs = {
  siteID: Array<Scalars['String']>;
  input: WorkerInput;
};


export type MutationUpdateWorkerArgs = {
  siteID: Array<Scalars['String']>;
  input: WorkerInput;
};


export type MutationCreateBulkWorkerArgs = {
  input: Array<WorkerInput>;
};


export type MutationUpdateWorkerAttendanceArgs = {
  input: WorkerAttendanceInput;
};


export type MutationDeleteWorkerAttendanceArgs = {
  input: WorkerAttendanceDeleteInput;
};


export type MutationCancelWorkerAttendanceArgs = {
  input: WorkerAttendanceDeleteInput;
};


export type MutationCreateWorkerAttendanceArgs = {
  input: WorkerAttendanceInput;
};


export type MutationCreateBatchWorkerAttendanceArgs = {
  input: Array<BatchWorkAttendanceInput>;
};


export type MutationApproveRejectWorkDeskArgs = {
  workDeskInput: Array<WorkDeskInput>;
};


export type MutationCreateNotificationWorkflowArgs = {
  body?: Maybe<Scalars['String']>;
  workflowID?: Maybe<Scalars['String']>;
  docRefID: Scalars['String'];
  docRefTable: Scalars['String'];
};


export type MutationTestEmailArgs = {
  includeImage: Scalars['Boolean'];
};


export type MutationUpdateWorkingHourPolicyArgs = {
  input: WorkingHourPolicyInput;
};


export type MutationDeleteWorkingHourPolicyArgs = {
  input: WorkingHourPolicyDeleteInput;
};


export type MutationCancelWorkingHourPolicyArgs = {
  input: WorkingHourPolicyDeleteInput;
};


export type MutationActivateWorkingHourPolicyArgs = {
  input: WorkingHourPolicyDeleteInput;
};


export type MutationCreateWorkingHourPolicyArgs = {
  input: WorkingHourPolicyInput;
};


export type MutationUpdateAssignmentArgs = {
  input: AssignmentInput;
};


export type MutationCreateAssignmentArgs = {
  input: AssignmentInput;
};


export type MutationReturnFromAssignmentArgs = {
  input: AssignmentReturnInput;
  plantMachineryID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationCancelAssignmentArgs = {
  input: CancelInput;
  plantMachineryID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationCreateFuelDischargeArgs = {
  input: FuelDischargeInput;
};


export type MutationUpdateStatusFuelDischargeArgs = {
  input: FuelDischargeUpdateStatus;
  IDs: Array<Scalars['String']>;
};


export type MutationUpdateFuelDischargeArgs = {
  input: FuelDischargeInput;
};


export type MutationDeleteFuelDischargeArgs = {
  ID: Scalars['String'];
};


export type MutationCreateFuelMeterReadingArgs = {
  input: FuelMeterReadingInput;
};


export type MutationUpdateFuelMeterReadingArgs = {
  input: FuelMeterReadingInput;
};


export type MutationDeleteFuelMeterReadingArgs = {
  input: FuelMeterReadingDeleteInput;
};


export type MutationCreateFuelSupplyArgs = {
  input: FuelSupplyInput;
};


export type MutationUpdateStatusFuelSupplyArgs = {
  input: FuelSupplyUpdateStatus;
};


export type MutationUpdateFuelSupplyArgs = {
  input: FuelSupplyInput;
};


export type MutationDeleteFuelSupplyArgs = {
  ID: Scalars['String'];
};


export type MutationActionOnFuelSupplyStatusArgs = {
  input: FuelSupplyActionInput;
  IDs: Array<Scalars['String']>;
};


export type MutationDeleteFuelTankSetupArgs = {
  input: FuelTankSetupDeleteInput;
};


export type MutationCreateFuelTankSetupArgs = {
  input: FuelTankSetupInput;
};


export type MutationUpdateFuelTankSetupArgs = {
  input: FuelTankSetupInput;
};


export type MutationCreateMaintenanceArgs = {
  assignmentID?: Maybe<Scalars['String']>;
  input: MaintenanceInput;
};


export type MutationUpdateMaintenanceArgs = {
  input: MaintenanceInput;
  assignmentID?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};


export type MutationCancelMaintenanceCostArgs = {
  input: CancelInput;
  plantMachineryID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationReturnFromMaintenanceArgs = {
  input: MaintenanceCostReturnInput;
  plantMachineryID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationCreateWorkshopArgs = {
  input: WorkshopInput;
};


export type MutationUpdateWorkshopArgs = {
  input: WorkshopInput;
};


export type MutationCreatePnmRentalArgs = {
  input: PnmRentalInput;
};


export type MutationUpdatePnmRentalArgs = {
  input: PnmRentalInput;
};


export type MutationCreatePlantMachineryArgs = {
  input: PlantMachineryInput;
};


export type MutationUpdatePlantMachineryArgs = {
  input: PlantMachineryInput;
};


export type MutationUpdateDoArgs = {
  doItemInput: Array<DoItemInput>;
  doInput: UpdateDoInput;
  ID?: Maybe<Scalars['String']>;
};


export type MutationActionOnDoStatusArgs = {
  doItemInput: Array<DoActionItemInput>;
  ID: Scalars['String'];
};


export type MutationCancelCloseDoArgs = {
  input: DoActionInput;
  ID: Scalars['String'];
};


export type MutationDeleteDoArgs = {
  doID: Scalars['String'];
};


export type MutationRejectDoArgs = {
  rejectInput?: Maybe<DoRejectInput>;
  ID: Scalars['String'];
};


export type MutationSubmitDoArgs = {
  doItemInput: Array<DoItemInput>;
  doInput: DoInput;
  ID?: Maybe<Scalars['String']>;
};


export type MutationPackDoArgs = {
  packedInput?: Maybe<DoPackedInput>;
  ID: Scalars['String'];
};


export type MutationShipDoArgs = {
  shippedInput?: Maybe<DoShippedInput>;
  ID: Scalars['String'];
};


export type MutationSubmitGrtnArgs = {
  grtnItemInput: Array<GrtnItemInput>;
  input: GoodReturnNoteInput;
  ID?: Maybe<Scalars['String']>;
};


export type MutationOldCreateUpdateGrtnArgs = {
  grtnItemInput: Array<GrtnItemInput>;
  grtnInput: GoodReturnNoteInput;
  ID?: Maybe<Scalars['String']>;
};


export type MutationCreateUpdateGrtnArgs = {
  grtnItemInput: Array<GrtnItemInput>;
  grtnInput: GoodReturnNoteInput;
  ID?: Maybe<Scalars['String']>;
};


export type MutationApproveGrtnArgs = {
  grtnItemInput: Array<GrtnItemInput>;
  ID: Scalars['String'];
};


export type MutationRejectGrtnArgs = {
  rejectInput?: Maybe<GrtnRejectInput>;
  ID: Scalars['String'];
};


export type MutationDeleteGrtnArgs = {
  ID: Scalars['String'];
};


export type MutationCancelCloseGrtnArgs = {
  input: GrtnActionInput;
  ID: Scalars['String'];
};


export type MutationCreateNegotiatedSupplyArgs = {
  negoItemInput: Array<NegotiatedSupplyItemInput>;
  negoSupplyInput: NegotiatedSupplyInput;
};


export type MutationUpdateNegotiatedSupplyArgs = {
  negoItemInput: Array<NegotiatedSupplyItemInput>;
  negoSupplyInput: NegotiatedSupplyInput;
  ID: Scalars['String'];
};


export type MutationCancelNegotiatedSupplyArgs = {
  input: NegoSupplyCancellationInput;
  ID: Scalars['String'];
};


export type MutationDeleteNegoSupplyArgs = {
  ID: Scalars['String'];
};


export type MutationCreatePoArgs = {
  poInput: Array<PurchaseOrderInput>;
};


export type MutationCreateDirectPoArgs = {
  poItemInput?: Maybe<Array<PoItemInput>>;
  input: DirectPoInput;
};


export type MutationUpdateDirectPoArgs = {
  poItemInput?: Maybe<Array<PoItemInput>>;
  input: DirectPoInput;
  ID: Scalars['String'];
};


export type MutationActionOnPoStatusArgs = {
  input: PurchaseActionInput;
  ID: Scalars['String'];
};


export type MutationCancelClosePoArgs = {
  input: PurchaseActionInput;
  ID: Scalars['String'];
};


export type MutationDeletePoArgs = {
  ID: Scalars['String'];
};


export type MutationPurchaseBudgetApprovalArgs = {
  input: PurchaseBudgetInput;
  IDs: Array<Scalars['String']>;
};


export type MutationCreatePurchaseReqArgs = {
  prItemListInput: Array<PrItemInput>;
  purchaseReqInput: PurchaseReqInput;
};


export type MutationUpdatePurchaseRequestArgs = {
  prItemListInput: Array<PrItemInput>;
  purchaseReqInput: PurchaseReqInput;
  ID: Scalars['String'];
};


export type MutationActionOnPrStatusArgs = {
  input: PurchaseActionInput;
  ID: Scalars['String'];
};


export type MutationDeletePrArgs = {
  ID: Scalars['String'];
};


export type MutationCancelClosePrArgs = {
  input: PurchaseActionInput;
  ID: Scalars['String'];
};


export type MutationSubmitEvaluationSelectionArgs = {
  evalSelectInput: Array<EvalSelectInput>;
  rfqInput: RfqEvalInput;
};


export type MutationCreateRfqArgs = {
  rfqItemInput: Array<RfqItemInput>;
  rfqInput: RfqInput;
};


export type MutationUpdateRfqArgs = {
  rfqItemInput: Array<RfqItemInput>;
  rfqInput: RfqInput;
  ID: Scalars['String'];
};


export type MutationRejectRfqArgs = {
  rfqRejectInput: RfqRejectInput;
  ID: Scalars['String'];
};


export type MutationCancelCloseRfqArgs = {
  input: RfqActionInput;
  ID: Scalars['String'];
};


export type MutationDeleteRfqArgs = {
  ID: Scalars['String'];
};


export type MutationUpdateRfqSupplierInvitationArgs = {
  input: RfqSupplierInvitationInput;
};


export type MutationDeleteRfqSupplierInvitationArgs = {
  input: RfqSupplierInvitationInput;
};


export type MutationInviteSupplierArgs = {
  supplierIDs: Array<Scalars['String']>;
  rfqID: Scalars['String'];
};


export type MutationUninviteSupplierArgs = {
  supplierID: Scalars['String'];
  rfqID: Scalars['String'];
};


export type MutationDeleteRfqSupplierSubmissionArgs = {
  ID: Scalars['String'];
};


export type MutationActiveRfqSubmissionArgs = {
  ID: Scalars['String'];
};


export type MutationSubmitRfqQuoteArgs = {
  input: RfqSubmissionInput;
};


export type MutationCreateSupplierCompanyAssignmentArgs = {
  input: SupplierCompanyAssignmentInput;
};


export type MutationUpdateSupplierCompanyAssignmentArgs = {
  input: SupplierCompanyAssignmentInput;
};


export type MutationAssignSupplierCompanyArgs = {
  companyID: Array<Scalars['String']>;
  supplierID: Scalars['String'];
};


export type MutationUnassignSupplierCompanyArgs = {
  companyID: Array<Scalars['String']>;
  supplierID: Scalars['String'];
};


export type MutationAssignSupplierWithCompanyArgs = {
  supplierID: Array<Scalars['String']>;
  companyID: Scalars['String'];
};


export type MutationUnassignSupplierWithCompanyArgs = {
  supplierID: Array<Scalars['String']>;
  companyID: Scalars['String'];
};


export type MutationCreatePlatformSupplierArgs = {
  input: SupplierInput;
};


export type MutationUpdatePlatformSupplierArgs = {
  input: SupplierInput;
};


export type MutationDeleteSupplierArgs = {
  input: SupplierDeleteInput;
};


export type MutationCreateSupplierCCategoryArgs = {
  input: SupplierCCategoryInput;
};


export type MutationUpdateSupplierCCategoryArgs = {
  input: SupplierCCategoryInput;
};


export type MutationDeleteSupplierCCategoryArgs = {
  input: SupplierCCategoryInput;
};


export type MutationAddSupplierContactArgs = {
  contactInput: Array<ContactPersonInput>;
  platformAccess: Scalars['Boolean'];
  ID: Scalars['String'];
};


export type MutationCreateBulkSupplierArgs = {
  supplierInput: Array<BulkSupplierInput>;
};


export type MutationAddCCategoryInSupplierArgs = {
  costCategoryID: Array<Scalars['String']>;
  supplierID: Scalars['String'];
};


export type MutationAddSupplierInCCategoryArgs = {
  supplierID: Array<Scalars['String']>;
  costCategoryID: Scalars['String'];
};


export type MutationDeleteSupplierInCCategoryArgs = {
  supplierID: Array<Scalars['String']>;
  costCategoryID: Scalars['String'];
};


export type MutationSupplierImportValidationArgs = {
  input: Array<SupplierImportInput>;
};


export type MutationCreateSupplierImportArgs = {
  batchID: Scalars['String'];
};


export type MutationCreateApInvoiceArgs = {
  input: ApTransactionInput;
};


export type MutationUpdateApInvoiceArgs = {
  input: ApTransactionInput;
};


export type MutationDeleteApInvoiceArgs = {
  ID: Scalars['String'];
};


export type MutationCreateApInvoiceWithoutDoArgs = {
  input: ApTransactionInput;
};


export type MutationUpdateApInvoiceWithoutDoArgs = {
  input: ApTransactionInput;
};


export type MutationDeleteApInvoiceWithoutDoArgs = {
  ID: Scalars['String'];
};


export type MutationCreateSupplierInvoiceArgs = {
  invDoItemMatchInput: Array<ApInvoiceItemInput>;
  ledger: Array<LedgerInput>;
  invoiceInput: ApInvoiceInput;
};


export type MutationCreateSupplierInvoiceV2Args = {
  isWithDO: Scalars['Boolean'];
  APInvoiceItemInput: Array<ApInvoiceItemInput>;
  ledger: Array<LedgerInput>;
  invoiceInput: ApInvoiceInput;
};


export type MutationUpdateSupplierInvoiceV2Args = {
  isWithDO: Scalars['Boolean'];
  APInvoiceItemInput: Array<ApInvoiceItemInput>;
  ledger: Array<LedgerInput>;
  invoiceInput: ApInvoiceInput;
};


export type MutationUpdateSupplierInvoiceArgs = {
  invDoItemMatchInput: Array<ApInvoiceItemInput>;
  ledger: Array<LedgerInput>;
  invoiceInput: ApInvoiceInput;
};


export type MutationDeleteSupplierInvoiceArgs = {
  ID: Scalars['String'];
};


export type MutationCancelSupplierInvoiceArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateApAdvanceArgs = {
  input: ApPaymentInput;
};


export type MutationUpdateApAdvanceArgs = {
  input: ApPaymentInput;
};


export type MutationDeleteApAdvanceArgs = {
  ID: Scalars['String'];
};


export type MutationCreateApPaymentArgs = {
  input: ApPaymentInput;
};


export type MutationUpdateApPaymentArgs = {
  input: ApPaymentInput;
};


export type MutationDeleteApPaymentArgs = {
  ID: Scalars['String'];
};


export type MutationCreateApRefundArgs = {
  input: ApPaymentInput;
};


export type MutationUpdateApRefundArgs = {
  input: ApPaymentInput;
};


export type MutationDeleteApRefundArgs = {
  ID: Scalars['String'];
};


export type MutationMakeSupplierPaymentArgs = {
  ledger: Array<LedgerInput>;
  input: ApPaymentInput;
};


export type MutationCancelSupplierDepositArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelSupplierPaymentArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelSupplierRefundArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateApDebitNoteArgs = {
  input: ApTransactionInput;
};


export type MutationUpdateApDebitNoteArgs = {
  input: ApTransactionInput;
};


export type MutationDeleteApDebitNoteArgs = {
  ID: Scalars['String'];
};


export type MutationCreateApCreditNoteArgs = {
  input: ApTransactionInput;
};


export type MutationUpdateApCreditNoteArgs = {
  input: ApTransactionInput;
};


export type MutationDeleteApCreditNoteArgs = {
  ID: Scalars['String'];
};


export type MutationCreateDNtoSupplierArgs = {
  input: ApTransactionInput;
};


export type MutationUpdateDNtoSupplierArgs = {
  input: ApTransactionInput;
};


export type MutationDeleteDNtoSupplierArgs = {
  ID: Scalars['String'];
};


export type MutationCreateCNtoSupplierArgs = {
  input: ApTransactionInput;
};


export type MutationUpdateCNtoSupplierArgs = {
  input: ApTransactionInput;
};


export type MutationDeleteCNtoSupplierArgs = {
  ID: Scalars['String'];
};


export type MutationMakeSupplierAllocationArgs = {
  ledger: Array<LedgerInput>;
};


export type MutationResetSupplierAllocationArgs = {
  creditID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
};


export type MutationCancelSupplierDnArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelSupplierCnArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelDNtoSupplierArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelCNtoSupplierArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationUpdateSubcontractorInvitationArgs = {
  input: SubcontractorInvitationInput;
};


export type MutationDeleteSubcontractorInvitationArgs = {
  input: SubcontractorInvitationInput;
};


export type MutationInviteSubcontractorArgs = {
  subcontractorIDs: Array<Scalars['String']>;
  subcontractTenderID: Scalars['String'];
};


export type MutationUnInviteSubcontractorArgs = {
  subcontractTenderID: Scalars['String'];
  subcontractorID: Scalars['String'];
};


export type MutationUnInviteSubcontractorsArgs = {
  subcontractorIDs: Array<Scalars['String']>;
  subcontractTenderID: Scalars['String'];
};


export type MutationCreateSubconRfqSubmissionArgs = {
  input: SubconRfqSubmissionInput;
};


export type MutationUpdateSubconRfqSubmissionArgs = {
  input: SubconRfqSubmissionInput;
};


export type MutationDeleteSubconRfqSubmissionArgs = {
  input: SubconRfqSubmissionInput;
};


export type MutationCreateSubcontractTenderArgs = {
  input: SubcontractTenderInput;
};


export type MutationUpdateSubcontractTenderArgs = {
  input: SubcontractTenderInput;
};


export type MutationDeleteSubcontractTenderArgs = {
  input: SubcontractTenderInput;
};


export type MutationTenderToSubconGenerationArgs = {
  input: SubconTenderGenerationInput;
  ID: Scalars['String'];
};


export type MutationCreateSubcontractGuaranteeArgs = {
  input: SubcontractGuaranteeInput;
};


export type MutationUpdateSubcontractGuaranteeArgs = {
  input: SubcontractGuaranteeInput;
};


export type MutationDeleteSubcontractGuaranteeArgs = {
  input: SubcontractGuaranteeInsuranceDeleteInput;
};


export type MutationCreateSubcontractInsuranceArgs = {
  input: SubcontractInsuranceInput;
};


export type MutationUpdateSubcontractInsuranceArgs = {
  input: SubcontractInsuranceInput;
};


export type MutationDeleteSubcontractInsuranceArgs = {
  input: SubcontractGuaranteeInsuranceDeleteInput;
};


export type MutationCancelSubcontractGuaranteeArgs = {
  input: SubcontractGuaranteeCancellationInput;
};


export type MutationCancelSubcontractInsuranceArgs = {
  input: SubcontractInsuranceCancellationInput;
};


export type MutationCreateSubcontractArgs = {
  wbsInput?: Maybe<Array<SubcontractCostWbsInput>>;
  input: SubcontractInput;
};


export type MutationUpdateSubcontractArgs = {
  wbsInput?: Maybe<Array<SubcontractCostWbsInput>>;
  input: SubcontractInput;
};


export type MutationActionOnSubcontractStatusArgs = {
  input: SubcontractActionInput;
  ID: Scalars['String'];
};


export type MutationCreateSubcontractExtensionDateArgs = {
  input: SubcontractExtensionInput;
  ID: Scalars['String'];
};


export type MutationCreateSubcontractCertificateArgs = {
  input: SubcontractCertificateInput;
  ID: Scalars['String'];
};


export type MutationActionOnSubcontractStatusWithTradeArgs = {
  input: SubcontractApproveRejectInput;
  ID: Scalars['String'];
};


export type MutationCreateSubcontractorCompanyAssignmentArgs = {
  input: SubcontractorCompanyAssignmentInput;
};


export type MutationUpdateSubcontractorCompanyAssignmentArgs = {
  input: SubcontractorCompanyAssignmentInput;
};


export type MutationAssignSubconCompanyArgs = {
  companyID: Array<Scalars['String']>;
  subcontractorID: Scalars['String'];
};


export type MutationUnassignSubconCompanyArgs = {
  companyID: Array<Scalars['String']>;
  subcontractorID: Scalars['String'];
};


export type MutationAssignSubconWithCompanyArgs = {
  subcontractorID: Array<Scalars['String']>;
  companyID: Scalars['String'];
};


export type MutationUnassignSubconWithCompanyArgs = {
  subcontractorID: Array<Scalars['String']>;
  companyID: Scalars['String'];
};


export type MutationCreateSubcontractVoArgs = {
  voDetails?: Maybe<Array<SubconVoDetailsInput>>;
  input: SubcontractVoInput;
};


export type MutationUpdateSubcontractVoArgs = {
  voDetails?: Maybe<Array<SubconVoDetailsInput>>;
  input: SubcontractVoInput;
};


export type MutationDeleteSubcontractVoArgs = {
  ID: Scalars['String'];
};


export type MutationActionOnSubconVoStatusArgs = {
  itemApprovedAmount?: Maybe<Array<ItemApprovedAmountInput>>;
  input?: Maybe<SubcontractVoActionInput>;
  ID: Scalars['String'];
};


export type MutationCreateSubcontractCostWbsArgs = {
  input: SubcontractWbsInput;
};


export type MutationUpdateSubcontractCostWbsArgs = {
  input: SubcontractWbsInput;
};


export type MutationDeleteSubcontractCostWbsArgs = {
  input: SubcontractWbsDeleteInput;
};


export type MutationUpdateSubcontractLedgersArgs = {
  tableName: Scalars['String'];
};


export type MutationCreateSubcontractClaimArgs = {
  input: SubcontractClaimInput;
};


export type MutationUpdateSubcontractClaimArgs = {
  input: SubcontractClaimInput;
};


export type MutationDeleteSubcontractClaimArgs = {
  ID: Scalars['String'];
};


export type MutationSubmitSubconRetentionClaimArgs = {
  input: SubconClaimRetentionInput;
};


export type MutationApproveSubconRetentionClaimArgs = {
  input: SubconClaimRetentionInput;
};


export type MutationSubmitSubconClaimArgs = {
  voCostWbsAlloc?: Maybe<Array<SubconClaimVoCostWbsAllocInput>>;
  voAlloc?: Maybe<Array<SubconClaimVoAllocInput>>;
  claimAlloc?: Maybe<Array<SubconClaimAllocInput>>;
  deductionLedger?: Maybe<Array<LedgerInput>>;
  input: SubcontractClaimInput;
};


export type MutationApproveSubconClaimArgs = {
  voCostWbsAlloc?: Maybe<Array<SubconClaimVoCostWbsAllocInput>>;
  voAlloc?: Maybe<Array<SubconClaimVoAllocInput>>;
  claimAlloc?: Maybe<Array<SubconClaimAllocInput>>;
  input: SubcontractClaimInput;
};


export type MutationUpdateSubconClaimDetailsArgs = {
  input: ClaimDetailsInput;
  ID: Scalars['String'];
};


export type MutationRejectSubconClaimArgs = {
  retentionClaim?: Maybe<Scalars['Boolean']>;
  input: RejectInput;
  ID: Scalars['String'];
};


export type MutationCancelSubconClaimArgs = {
  retentionClaim?: Maybe<Scalars['Boolean']>;
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateSubcontractAdvanceArgs = {
  input: SubcontractPaymentInput;
};


export type MutationUpdateSubcontractAdvanceArgs = {
  input: SubcontractPaymentInput;
};


export type MutationDeleteSubcontractAdvanceArgs = {
  ID: Scalars['String'];
};


export type MutationCreateSubcontractPaymentArgs = {
  input: SubcontractPaymentInput;
};


export type MutationUpdateSubcontractPaymentArgs = {
  input: SubcontractPaymentInput;
};


export type MutationDeleteSubcontractPaymentArgs = {
  ID: Scalars['String'];
};


export type MutationCreateSubcontractRefundArgs = {
  input: SubcontractPaymentInput;
};


export type MutationUpdateSubcontractRefundArgs = {
  input: SubcontractPaymentInput;
};


export type MutationDeleteSubcontractRefundArgs = {
  ID: Scalars['String'];
};


export type MutationMakeSubconPaymentArgs = {
  ledger: Array<LedgerInput>;
  input: SubcontractPaymentInput;
};


export type MutationCancelSubconAdvanceArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelSubconPaymentArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelSubconRefundArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateSubcontractMiscInvoiceArgs = {
  input: SubcontractTransactionWithGlItemInput;
};


export type MutationUpdateSubcontractMiscInvoiceArgs = {
  input: SubcontractTransactionWithGlItemInput;
};


export type MutationDeleteSubcontractMiscInvoiceArgs = {
  ID: Scalars['String'];
};


export type MutationCreateSubcontractDnArgs = {
  input: SubcontractTransactionWithGlItemInput;
};


export type MutationUpdateSubcontractDnArgs = {
  input: SubcontractTransactionWithGlItemInput;
};


export type MutationDeleteSubcontractDnArgs = {
  ID: Scalars['String'];
};


export type MutationCreateSubcontractCnArgs = {
  input: SubcontractTransactionWithGlItemInput;
};


export type MutationUpdateSubcontractCnArgs = {
  input: SubcontractTransactionWithGlItemInput;
};


export type MutationDeleteSubcontractCnArgs = {
  ID: Scalars['String'];
};


export type MutationCreateDNtoSubconArgs = {
  input: SubcontractTransactionWithGlItemInput;
};


export type MutationUpdateDNtoSubconArgs = {
  input: SubcontractTransactionWithGlItemInput;
};


export type MutationDeleteDNtoSubconArgs = {
  ID: Scalars['String'];
};


export type MutationCreateCNtoSubconArgs = {
  input: SubcontractTransactionWithGlItemInput;
};


export type MutationUpdateCNtoSubconArgs = {
  input: SubcontractTransactionWithGlItemInput;
};


export type MutationDeleteCNtoSubconArgs = {
  ID: Scalars['String'];
};


export type MutationMakeSubconAllocationArgs = {
  ledger: Array<LedgerInput>;
};


export type MutationResetSubcontractAllocationArgs = {
  receiptPayment?: Maybe<Scalars['Boolean']>;
  creditID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
};


export type MutationCancelSubcontractMiscInvoiceArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelSubconDnArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelSubconCnArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelDNtoSubconArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelCNtoSubconArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreatePlatformSubcontractorArgs = {
  input: SubcontractorInput;
};


export type MutationUpdatePlatformSubcontractorArgs = {
  input: SubcontractorInput;
};


export type MutationDeleteSubcontractorArgs = {
  input: SubcontractorDeleteId;
};


export type MutationCreateSubcontractorCCategoryArgs = {
  input: SubcontractorCCategoryInput;
};


export type MutationUpdateSubcontractorCCategoryArgs = {
  input: SubcontractorCCategoryInput;
};


export type MutationAddSubcontractorContactArgs = {
  contactInput: Array<ContactPersonInput>;
  platformAccess: Scalars['Boolean'];
  ID: Scalars['String'];
};


export type MutationCreateSubcontractorAndPackageArgs = {
  costCategoryID: Array<Scalars['String']>;
  subcontractorInput: SubcontractorInput;
};


export type MutationCreateBulkSubconArgs = {
  subcontractorInput: Array<BulkSubcontractorInput>;
};


export type MutationAddSubcontractorCCategoryArgs = {
  costCategoryID: Array<Scalars['String']>;
  subcontractorID: Scalars['String'];
};


export type MutationCreateSubcontractorSetupArgs = {
  contactInput: Array<ContactPersonInput>;
  costCategoryID: Array<Scalars['String']>;
  input: SubcontractorHeaderInput;
};


export type MutationUpdateSubcontractorSetupArgs = {
  contactInput: Array<ContactPersonInput>;
  costCategoryID: Array<Scalars['String']>;
  input: SubcontractorHeaderInput;
};


export type MutationDeleteSubcontractorSetupArgs = {
  ID: Scalars['String'];
};


export type MutationSubcontractorImportValidationArgs = {
  input: Array<SubcontractorImportInput>;
};


export type MutationCreateSubcontractorImportArgs = {
  batchID: Scalars['String'];
};


export type MutationCreateSiteArgs = {
  siteInput: Array<SiteInput>;
  input: ProjectHeaderInput;
};


export type MutationUpdateSiteArgs = {
  siteInput: Array<SiteInput>;
  input: ProjectHeaderInput;
};


export type MutationDeleteSiteArgs = {
  input: SiteDeleteInput;
};


export type MutationActivateSiteArgs = {
  input: SiteDeleteInput;
};


export type MutationCancelSiteArgs = {
  input: SiteDeleteInput;
};


export type MutationInsertUpdateSiteArgs = {
  siteInput: Array<SiteInput>;
  contractID: Scalars['String'];
};


export type MutationDeleteProjectSiteArgs = {
  ID: Scalars['String'];
};


export type MutationCreateSiteImageArgs = {
  input: SiteImageInput;
};


export type MutationUpdateSiteImageArgs = {
  input: SiteImageInput;
};


export type MutationDeleteSiteImageArgs = {
  input: SiteImageDeleteInput;
};


export type MutationCancelSiteImageArgs = {
  input: SiteImageDeleteInput;
};

export enum Nationality {
  Afghan = 'Afghan',
  Albanian = 'Albanian',
  Algerian = 'Algerian',
  American = 'American',
  Argentine = 'Argentine',
  Argentinian = 'Argentinian',
  Australian = 'Australian',
  Austrian = 'Austrian',
  Bangladeshi = 'Bangladeshi',
  Batswana = 'Batswana',
  Belgian = 'Belgian',
  Bolivian = 'Bolivian',
  Brazilian = 'Brazilian',
  British = 'British',
  Bulgarian = 'Bulgarian',
  Burmese = 'Burmese',
  Cambodian = 'Cambodian',
  Cameroonian = 'Cameroonian',
  Canadian = 'Canadian',
  Chilean = 'Chilean',
  Chinese = 'Chinese',
  Colombian = 'Colombian',
  CostaRican = 'Costa_Rican',
  Croatian = 'Croatian',
  Cuban = 'Cuban',
  Czech = 'Czech',
  Danish = 'Danish',
  Dominican = 'Dominican',
  Dutch = 'Dutch',
  Ecuadorian = 'Ecuadorian',
  Egyptian = 'Egyptian',
  Emirati = 'Emirati',
  English = 'English',
  Estonian = 'Estonian',
  Ethiopian = 'Ethiopian',
  Fijian = 'Fijian',
  Finnish = 'Finnish',
  French = 'French',
  German = 'German',
  Ghanaian = 'Ghanaian',
  Greek = 'Greek',
  Guatemalan = 'Guatemalan',
  Haitian = 'Haitian',
  Honduran = 'Honduran',
  Hungarian = 'Hungarian',
  Icelandic = 'Icelandic',
  Indian = 'Indian',
  Indonesian = 'Indonesian',
  Iranian = 'Iranian',
  Iraqi = 'Iraqi',
  Irish = 'Irish',
  Israeli = 'Israeli',
  Italian = 'Italian',
  Jamaican = 'Jamaican',
  Japanese = 'Japanese',
  Jordanian = 'Jordanian',
  Kenyan = 'Kenyan',
  Korean = 'Korean',
  Kuwaiti = 'Kuwaiti',
  Lao = 'Lao',
  Latvian = 'Latvian',
  Lebanese = 'Lebanese',
  Libyan = 'Libyan',
  Lithuanian = 'Lithuanian',
  Malagasy = 'Malagasy',
  Malaysian = 'Malaysian',
  Malian = 'Malian',
  Maltese = 'Maltese',
  Mexican = 'Mexican',
  Mongolian = 'Mongolian',
  Moroccan = 'Moroccan',
  Mozambican = 'Mozambican',
  Namibian = 'Namibian',
  Nepalese = 'Nepalese',
  NewZealand = 'New_Zealand',
  Nicaraguan = 'Nicaraguan',
  Nigerian = 'Nigerian',
  Norwegian = 'Norwegian',
  Pakistani = 'Pakistani',
  Panamanian = 'Panamanian',
  Paraguayan = 'Paraguayan',
  Peruvian = 'Peruvian',
  Philippine = 'Philippine',
  Polish = 'Polish',
  Portuguese = 'Portuguese',
  Romanian = 'Romanian',
  Russian = 'Russian',
  Salvadorian = 'Salvadorian',
  Saudi = 'Saudi',
  Scottish = 'Scottish',
  Senegalese = 'Senegalese',
  Serbian = 'Serbian',
  Singaporean = 'Singaporean',
  Slovak = 'Slovak',
  SouthAfrican = 'South_African',
  Spanish = 'Spanish',
  SriLankan = 'Sri_Lankan',
  Sudanese = 'Sudanese',
  Swedish = 'Swedish',
  Swiss = 'Swiss',
  Syrian = 'Syrian',
  Taiwanese = 'Taiwanese',
  Tajikistani = 'Tajikistani',
  Thai = 'Thai',
  Tongan = 'Tongan',
  Tunisian = 'Tunisian',
  Turkish = 'Turkish',
  Ukrainian = 'Ukrainian',
  Uruguayan = 'Uruguayan',
  Venezuelan = 'Venezuelan',
  Vietnamese = 'Vietnamese',
  Welsh = 'Welsh',
  Zambian = 'Zambian',
  Zimbabwean = 'Zimbabwean'
}

export type NationalityTypes = {
  __typename?: 'NationalityTypes';
  nationality: Nationality;
};

export type NegoSupplyCancellationInput = {
  cancellationRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type NegotiatedSupplyEntity = AuditEntity & {
  __typename?: 'NegotiatedSupplyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  supplierID: Scalars['String'];
  supplier?: Maybe<SupplierEntity>;
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  docNo: Scalars['String'];
  negoSupplyDate: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  status: PurchaseStatus;
  negoSupplyItem?: Maybe<Array<NegotiatedSupplyItemEntity>>;
};

export type NegotiatedSupplyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  supplierID: Scalars['String'];
  contractID: Scalars['String'];
  docNo: Scalars['String'];
  negoSupplyDate: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type NegotiatedSupplyItemEntity = AuditEntity & {
  __typename?: 'NegotiatedSupplyItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  negotiatedSupplyID: Scalars['String'];
  costItemID: Scalars['String'];
  uomID: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  balanceQty?: Maybe<Scalars['Float']>;
  unitPrice: Scalars['Float'];
  costItem?: Maybe<CostItemEntity>;
  poItem?: Maybe<Array<PoItemEntity>>;
  UOM?: Maybe<UomEntity>;
  negoSupply?: Maybe<NegotiatedSupplyEntity>;
  poItemApproved: Array<PoItemEntity>;
  uomExchange?: Maybe<Array<UomExchange>>;
};

export type NegotiatedSupplyItemInput = {
  ID?: Maybe<Scalars['String']>;
  costItemID: Scalars['String'];
  uomID: Scalars['String'];
  quantity: Scalars['Float'];
  unitPrice: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
};

export type NotiRoleUserAssignmentEntity = AuditEntity & {
  __typename?: 'NotiRoleUserAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  notiPolicyID: Scalars['String'];
  roleID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  notification?: Maybe<NotificationGuidelineEntity>;
};

export type NotificationGuidelineEntity = AuditEntity & {
  __typename?: 'NotificationGuidelineEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRefTable: Scalars['String'];
  commonStatus: CommonStatus;
  policyID?: Maybe<Scalars['String']>;
  notiRoleUsers?: Maybe<Array<NotiRoleUserAssignmentEntity>>;
  /** CustomFieldResolver */
  roles: Scalars['JSON'];
};

export type NotificationGuidelineInput = {
  roleID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
};

export type NumberFormatInput = {
  softwareID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  documentDivider?: Maybe<Scalars['String']>;
  prefixExtra?: Maybe<Scalars['String']>;
  docTypeID: Scalars['String'];
  numberFormat: Scalars['String'];
  numberLength: Scalars['Float'];
};

export enum NumericFormatter {
  Lumpsum = 'LUMPSUM',
  Thousands = 'THOUSANDS',
  Millions = 'MILLIONS',
  Billions = 'BILLIONS'
}

export type Os = {
  __typename?: 'OS';
  amt?: Maybe<Scalars['Float']>;
  baseAmt?: Maybe<Scalars['Float']>;
};

export type PnmCategoryWorkshopEntity = AuditEntity & {
  __typename?: 'PNMCategoryWorkshopEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  costCategoryID?: Maybe<Scalars['String']>;
  costCategory: CostCategoryEntity;
  workshopID?: Maybe<Scalars['String']>;
  workshop: WorkshopEntity;
};

export type PnmCostCat = {
  __typename?: 'PNMCostCat';
  quantity?: Maybe<Scalars['Boolean']>;
  roadTax?: Maybe<Scalars['Boolean']>;
  warranty?: Maybe<Scalars['Boolean']>;
  insurance?: Maybe<Scalars['Boolean']>;
};

export type PnmCostCatInput = {
  quantity?: Maybe<Scalars['Boolean']>;
  roadTax?: Maybe<Scalars['Boolean']>;
  warranty?: Maybe<Scalars['Boolean']>;
  insurance?: Maybe<Scalars['Boolean']>;
};

export type PnmRentalEntity = AuditEntity & {
  __typename?: 'PNMRentalEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  costCategoryID: Scalars['String'];
  costCategory?: Maybe<CostCategoryEntity>;
  supplierID?: Maybe<Scalars['String']>;
  supplier?: Maybe<SupplierEntity>;
  companyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  regNo?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  cost?: Maybe<Scalars['Float']>;
  rentalRate: RateUomType;
  returnDate?: Maybe<Scalars['String']>;
  returnRemarks?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  requireFuelSupply: Scalars['Boolean'];
  plantMachineryStatus: PlantMachineryStatus;
  company?: Maybe<SubscriptionCompanyType>;
  fuelTotalSupply?: Maybe<Scalars['Float']>;
  fuelTotalDischarge?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  previousFuelSupplyDischarge?: Maybe<Scalars['JSON']>;
  tankTable: Scalars['String'];
  /** CustomFieldResolver */
  fuelMeterReading?: Maybe<Array<FuelMeterReadingEntity>>;
  /** CustomFieldResolver */
  fuelSupply?: Maybe<Array<FuelSupplyEntity>>;
  /** CustomFieldResolver */
  fuelDischarge?: Maybe<Array<FuelDischargeEntity>>;
  /** CustomFieldResolver */
  rentalTankSummary?: Maybe<Scalars['JSON']>;
};


export type PnmRentalEntityRentalTankSummaryArgs = {
  companyID: Scalars['String'];
};

export type PnmRentalInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  companyID: Scalars['String'];
  costCategoryID: Scalars['String'];
  supplierID: Scalars['String'];
  name: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  cost: Scalars['Float'];
  regNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  returnRemarks?: Maybe<Scalars['String']>;
  returnDate?: Maybe<Scalars['String']>;
  requireFuelSupply?: Maybe<Scalars['Boolean']>;
  rentalRate: RateUomType;
  plantMachineryStatus?: Maybe<PlantMachineryStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type PoBudgetExceptionEntity = AuditEntity & {
  __typename?: 'POBudgetExceptionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  remarks: Scalars['String'];
};

export type PoCancellationInput = {
  supplierID: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  totalAmt: Scalars['Float'];
  instructions?: Maybe<Scalars['String']>;
  poItems: Array<PoItemInput>;
  remarks?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type PoItemEntity = AuditEntity & {
  __typename?: 'POItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  purchaseOrderID: Scalars['String'];
  purchaseOrder?: Maybe<PurchaseOrderEntity>;
  prItemID?: Maybe<Scalars['String']>;
  prItem?: Maybe<PrItemEntity>;
  taxSchemeID: Scalars['String'];
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  costItemID?: Maybe<Scalars['String']>;
  costItem?: Maybe<CostItemEntity>;
  negotiatedSupplyItemID?: Maybe<Scalars['String']>;
  nego?: Maybe<NegotiatedSupplyItemEntity>;
  rfqItemID?: Maybe<Scalars['String']>;
  rfqItem?: Maybe<RfqItemEntity>;
  uomID?: Maybe<Scalars['String']>;
  uom?: Maybe<UomEntity>;
  wbsID?: Maybe<Scalars['String']>;
  wbs?: Maybe<WbsEntity>;
  currencyID?: Maybe<Scalars['String']>;
  orderedQty: Scalars['Float'];
  outstandingQty?: Maybe<Scalars['Float']>;
  expectedDate?: Maybe<Scalars['String']>;
  unitPrice: Scalars['Float'];
  taxAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  discountAmt?: Maybe<Scalars['Float']>;
  markupAmt?: Maybe<Scalars['Float']>;
  markupPerc?: Maybe<Scalars['Float']>;
  totalAmt?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  doItem?: Maybe<Array<DoItemEntity>>;
  /** CustomFieldResolver */
  poItemVariance?: Maybe<Scalars['JSON']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
  uomExchange?: Maybe<Array<PoUomExchange>>;
};


export type PoItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type PoItemInput = {
  createdBy?: Maybe<Scalars['String']>;
  orderedQty: Scalars['Float'];
  unitPrice: Scalars['Float'];
  prItemID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  negotiatedSupplyItemID?: Maybe<Scalars['String']>;
  rfqItemID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  taxAmt?: Maybe<Scalars['Float']>;
  markupAmt?: Maybe<Scalars['Float']>;
  markupPerc?: Maybe<Scalars['Float']>;
  totalAmt?: Maybe<Scalars['Float']>;
  discountAmt?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  expectedDate: Scalars['String'];
};

export type PoItemsInput = {
  doItemID?: Maybe<Scalars['String']>;
  poItemID?: Maybe<Scalars['String']>;
  grtnItemID?: Maybe<Scalars['String']>;
  isInventory?: Maybe<Scalars['Boolean']>;
  acceptedQty: Scalars['Float'];
  deliveredQty: Scalars['Float'];
};

export type PrItemEntity = AuditEntity & {
  __typename?: 'PRItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  purchaseReqID: Scalars['String'];
  purchaseReq?: Maybe<PurchaseReqEntity>;
  costItemID: Scalars['String'];
  costItem?: Maybe<CostItemEntity>;
  uomID: Scalars['String'];
  uom?: Maybe<UomEntity>;
  wbsID?: Maybe<Scalars['String']>;
  wbs?: Maybe<WbsEntity>;
  requestedQty: Scalars['Float'];
  outstandingQty: Scalars['Float'];
  expectedDate?: Maybe<Scalars['String']>;
  markupPerc?: Maybe<Scalars['Float']>;
  negotiatedItemStatus: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  poItem?: Maybe<Array<PoItemEntity>>;
  rfqItem?: Maybe<Array<RfqItemEntity>>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
  uomExchange?: Maybe<Array<UomExchanges>>;
  negoSupplySelected?: Maybe<Scalars['JSON']>;
  rfqSubmissionSelected?: Maybe<Scalars['JSON']>;
};


export type PrItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type PrItemEntityNegoSupplySelectedArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type PrItemEntityRfqSubmissionSelectedArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type PrItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  costItemID: Scalars['String'];
  uomID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  requestedQty: Scalars['Float'];
  markupPerc?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  negotiatedItemStatus?: Maybe<Scalars['Boolean']>;
  expectedDate: Scalars['String'];
};

export type PackedDetail = {
  __typename?: 'PackedDetail';
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  packedByID?: Maybe<Scalars['String']>;
  packedByName?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
};

export type PackedDetailInput = {
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  packedByID?: Maybe<Scalars['String']>;
  packedByName?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
};

export type PaymentReminderEntity = AuditEntity & {
  __typename?: 'PaymentReminderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  reminderDate: Scalars['String'];
  commonStatus: CommonStatus;
  docReminder?: Maybe<Array<DocReminderEntity>>;
  /** CustomFieldResolver */
  payReminderTotal?: Maybe<Scalars['JSON']>;
};

export type PaymentReminderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  customerID?: Maybe<Scalars['String']>;
  reminderDate: Scalars['String'];
};

export type PkkDetails = {
  __typename?: 'PkkDetails';
  registrationNo?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
};

export type PkkDetailsInput = {
  registrationNo?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
};

export type PlantMachineryEntity = AuditEntity & {
  __typename?: 'PlantMachineryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  supplierID?: Maybe<Scalars['String']>;
  supplier?: Maybe<SupplierEntity>;
  costCategoryID: Scalars['String'];
  costCategory?: Maybe<CostCategoryEntity>;
  companyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  regNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  purchaseDate: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  availableQty?: Maybe<Scalars['Float']>;
  serialNo?: Maybe<Scalars['String']>;
  warrantyDate?: Maybe<Scalars['String']>;
  roadTaxDate?: Maybe<Scalars['String']>;
  insuranceDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  plantMachineryStatus: PlantMachineryStatus;
  assignment?: Maybe<Array<AssignmentEntity>>;
  maintenance?: Maybe<Array<MaintenanceEntity>>;
  tank?: Maybe<Array<TankEntity>>;
  company?: Maybe<SubscriptionCompanyType>;
  /** CustomFieldResolver */
  latestActiveAssignment?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  latestActiveMaintenance?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  currentPnmStatus?: Maybe<Scalars['String']>;
  fuelTotalSupply?: Maybe<Scalars['Float']>;
  fuelTotalDischarge?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  previousFuelSupplyDischarge?: Maybe<Scalars['JSON']>;
  tankTable: Scalars['String'];
  /** CustomFieldResolver */
  fuelMeterReading?: Maybe<Array<FuelMeterReadingEntity>>;
  /** CustomFieldResolver */
  fuelSupply?: Maybe<Array<FuelSupplyEntity>>;
  /** CustomFieldResolver */
  fuelDischarge?: Maybe<Array<FuelDischargeEntity>>;
  /** CustomFieldResolver */
  tankSummary?: Maybe<Scalars['JSON']>;
};


export type PlantMachineryEntityTankSummaryArgs = {
  companyID?: Maybe<Scalars['String']>;
};

export type PlantMachineryInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  costCategoryID: Scalars['String'];
  name: Scalars['String'];
  purchaseDate: Scalars['String'];
  companyID: Scalars['String'];
  supplierID?: Maybe<Scalars['String']>;
  regNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  serialNo?: Maybe<Scalars['String']>;
  warrantyDate?: Maybe<Scalars['String']>;
  roadTaxDate?: Maybe<Scalars['String']>;
  insuranceDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  attachment?: Maybe<Array<Scalars['Upload']>>;
  plantMachineryStatus?: Maybe<PlantMachineryStatus>;
};

export enum PlantMachineryStatus {
  Active = 'ACTIVE',
  Assigned = 'ASSIGNED',
  PartiallyAssigned = 'PARTIALLY_ASSIGNED',
  Maintenance = 'MAINTENANCE',
  OutOfOrder = 'OUT_OF_ORDER',
  Returned = 'RETURNED',
  InTransaction = 'IN_TRANSACTION'
}

export type PlatformLoginResponse = {
  __typename?: 'PlatformLoginResponse';
  accessToken: Scalars['String'];
  user: PlatformUserResponse;
};

export type PlatformUserEntity = AuditEntity & {
  __typename?: 'PlatformUserEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  associateID: Scalars['String'];
  contactID: Scalars['String'];
  contactDetail: ContactEntity;
  userName: Scalars['String'];
  confirmed: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  softwareCode: Scalars['String'];
  loginAttempt: Scalars['Float'];
  blockDuration: Scalars['Float'];
  blockCount: Scalars['Float'];
  loginTs?: Maybe<Scalars['DateTime']>;
  deviceIP?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
};

export type PlatformUserResponse = {
  __typename?: 'PlatformUserResponse';
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  userName: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  confirmed: Scalars['Boolean'];
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  deviceIP?: Maybe<Scalars['String']>;
  loginTs?: Maybe<Scalars['DateTime']>;
  blockDuration?: Maybe<Scalars['Float']>;
  blockCount?: Maybe<Scalars['Float']>;
  loginAttempt?: Maybe<Scalars['Float']>;
  commonStatus: CommonStatus;
};

export type PolicyAssignmentInput = {
  roleID: Scalars['String'];
  approvalLevel: ApprovalLevel;
};

export type PolicyGuidelineEntity = AuditEntity & {
  __typename?: 'PolicyGuidelineEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  docRefTable: Scalars['String'];
  commonStatus: CommonStatus;
  policyRoles: Array<PolicyRoleAssignmentEntity>;
};

export type PolicyGuidelineInput = {
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  docRefTable: Scalars['String'];
};

export type PolicyRoleAssignmentEntity = AuditEntity & {
  __typename?: 'PolicyRoleAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  policyID: Scalars['String'];
  roleID: Scalars['String'];
  approvalLevel: ApprovalLevel;
  policy?: Maybe<PolicyGuidelineEntity>;
};

export enum PostStatus {
  Draft = 'DRAFT',
  Posted = 'POSTED'
}

export type PricingSupplierEntity = AuditEntity & {
  __typename?: 'PricingSupplierEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID: Scalars['String'];
  supplierID: Scalars['String'];
  price: Scalars['Float'];
  supplier?: Maybe<SupplierEntity>;
  costItem: CostItemEntity;
};

export type Product = {
  __typename?: 'Product';
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type ProfitRecogAssignmentEntity = AuditEntity & {
  __typename?: 'ProfitRecogAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  profitRecogFormulaID: Scalars['String'];
  commonStatus: CommonStatus;
  profitRecogFormula?: Maybe<ProfitRecogFormulaEntity>;
  contract?: Maybe<ContractEntity>;
};

export type ProfitRecogAssignmentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type ProfitRecogFormulaDeleteInput = {
  ID: Scalars['String'];
};

export type ProfitRecogFormulaEntity = AuditEntity & {
  __typename?: 'ProfitRecogFormulaEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  title: Scalars['String'];
  percOfCompletion: Scalars['String'];
  cost: Scalars['String'];
  revenue: Scalars['String'];
  commonStatus: CommonStatus;
  profitRecogAssgmt?: Maybe<Array<ProfitRecogAssignmentEntity>>;
  contract?: Maybe<Array<ContractEntity>>;
};

export type ProfitRecogFormulaInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  title: Scalars['String'];
  percOfCompletion: Scalars['String'];
  cost: Scalars['String'];
  revenue: Scalars['String'];
};

export type ProfitRecogGlAccountCodeEntity = AuditEntity & {
  __typename?: 'ProfitRecogGLAccountCodeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  wipCostRecognized: Scalars['String'];
  wipBillingRecognized: Scalars['String'];
  revenuePL: Scalars['String'];
  costPL: Scalars['String'];
};

export type ProfitRecogGlAccountCodeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  wipCostRecognized?: Maybe<Scalars['String']>;
  wipBillingRecognized?: Maybe<Scalars['String']>;
  revenuePL?: Maybe<Scalars['String']>;
  costPL?: Maybe<Scalars['String']>;
};

export type ProfitRecogGlJournalEntity = AuditEntity & {
  __typename?: 'ProfitRecogGLJournalEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  profitRecogGenerationID: Scalars['String'];
  wipCostRecAccCode: Scalars['String'];
  wipBillingRecAccCode: Scalars['String'];
  revenuePLAccCode: Scalars['String'];
  costPLAccCode: Scalars['String'];
  profitRecogGeneration: ProfitRecogGenerationEntity;
};

export type ProfitRecogGlJournalInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type ProfitRecogGenParamAssgmtEntity = AuditEntity & {
  __typename?: 'ProfitRecogGenParamAssgmtEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  profitRecogGenerationID: Scalars['String'];
  profitRecogParameterID: Scalars['String'];
  amount: Scalars['Float'];
  profitRecogParameter: ProfitRecogParameterEntity;
  profitRecogGeneration: ProfitRecogGenerationEntity;
};

export type ProfitRecogGenParamAssgmtInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type ProfitRecogGenerationEntity = AuditEntity & {
  __typename?: 'ProfitRecogGenerationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  docNo: Scalars['String'];
  docDate: Scalars['String'];
  remarks: Scalars['String'];
  percOfCompletion: Scalars['Float'];
  cost: Scalars['Float'];
  revenue: Scalars['Float'];
  profitRecogStatus: ProfitRecogStatus;
  profitRecogGLJournal: Array<ProfitRecogGlJournalEntity>;
  profitRecogGenParamAssgmt: Array<ProfitRecogGenParamAssgmtEntity>;
  contract?: Maybe<ContractEntity>;
};

export type ProfitRecogGenerationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  docDate: Scalars['String'];
  remarks: Scalars['String'];
  percOfCompletion: Scalars['Float'];
  cost: Scalars['Float'];
  revenue: Scalars['Float'];
  profitRecogStatus?: Maybe<ProfitRecogStatus>;
};

export type ProfitRecogParameterEntity = AuditEntity & {
  __typename?: 'ProfitRecogParameterEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  sqlScript: Scalars['String'];
  commonStatus: CommonStatus;
  profitRecogGenParamAssgmt: Array<ProfitRecogGenParamAssgmtEntity>;
};

export type ProfitRecogParameterInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export enum ProfitRecogStatus {
  Active = 'ACTIVE',
  Recognized = 'RECOGNIZED',
  Cancelled = 'CANCELLED'
}

export type ProjectBudgetDeleteInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ProjectBudgetEntity = AuditEntity & {
  __typename?: 'ProjectBudgetEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract: ContractEntity;
  costCategoryID: Scalars['String'];
  costCateg: CostCategoryEntity;
  budgetAmt?: Maybe<Array<Scalars['JSON']>>;
};

export type ProjectBudgetInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  costCategoryID: Scalars['String'];
  budgetAmt?: Maybe<Array<BudgetAmtInput>>;
};

export type ProjectCostingDetails = {
  __typename?: 'ProjectCostingDetails';
  subContractCommittedCost?: Maybe<Scalars['Float']>;
  materialCommittedCostAmt?: Maybe<Scalars['Float']>;
  plantCommittedCostAmt?: Maybe<Scalars['Float']>;
  labourCommittedCostAmt?: Maybe<Scalars['Float']>;
  miscCommittedCostAmt?: Maybe<Scalars['Float']>;
  balancedToSpendLabour?: Maybe<Scalars['Float']>;
  balancedToSpendMaterial?: Maybe<Scalars['Float']>;
  balancedToSpendMisc?: Maybe<Scalars['Float']>;
  balancedToSpendPlant?: Maybe<Scalars['Float']>;
  balancedToSpendSubcon?: Maybe<Scalars['Float']>;
  CommitedActualCostLabour?: Maybe<Scalars['Float']>;
  CommitedActualCostMaterial?: Maybe<Scalars['Float']>;
  CommitedActualCostMisc?: Maybe<Scalars['Float']>;
  CommitedActualCostPlant?: Maybe<Scalars['Float']>;
  originalBudgetSubcontractAmt?: Maybe<Scalars['Float']>;
  originalBudgetPlantAmt?: Maybe<Scalars['Float']>;
  originalBudgetMiscAmt?: Maybe<Scalars['Float']>;
  originalBudgetMaterialAmt?: Maybe<Scalars['Float']>;
  originalBudgetLabourAmt?: Maybe<Scalars['Float']>;
  revisedBudgetSubcontractAmt?: Maybe<Scalars['Float']>;
  revisedBudgetPlantAmt?: Maybe<Scalars['Float']>;
  revisedBudgetMiscAmt?: Maybe<Scalars['Float']>;
  revisedBudgetMaterialAmt?: Maybe<Scalars['Float']>;
  revisedBudgetLabourAmt?: Maybe<Scalars['Float']>;
};

export type ProjectCostingEntity = AuditEntity & {
  __typename?: 'ProjectCostingEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  projectSum: Scalars['Float'];
  balanceToSpend: Scalars['Float'];
  projectCostAmt: Scalars['Float'];
};

export type ProjectExpenseCancelInput = {
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
};

export type ProjectExpenseEntity = AuditEntity & {
  __typename?: 'ProjectExpenseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  docRef: Scalars['String'];
  docDate: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxAmt: Scalars['Float'];
  cancellationDate?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  status: MiscExpenseStatus;
  projectExpenseItem?: Maybe<Array<ProjectExpenseItemEntity>>;
};

export type ProjectExpenseInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  docRef: Scalars['String'];
  docDate: Scalars['String'];
  transactionDate: Scalars['String'];
  docAmt: Scalars['Float'];
  taxAmt: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ProjectExpenseItemEntity = AuditEntity & {
  __typename?: 'ProjectExpenseItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  projectExpenseID: Scalars['String'];
  projectExpense?: Maybe<ProjectExpenseEntity>;
  wbsID: Scalars['String'];
  wbs?: Maybe<WbsEntity>;
  costCategoryID?: Maybe<Scalars['String']>;
  costCategory?: Maybe<CostCategoryEntity>;
  costItemID?: Maybe<Scalars['String']>;
  costItem?: Maybe<CostItemEntity>;
  taxSchemeID: Scalars['String'];
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
};

export type ProjectExpenseItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  projectExpenseID?: Maybe<Scalars['String']>;
  wbsID: Scalars['String'];
  costClass?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  taxSchemeID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmt: Scalars['Float'];
  docAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
};

export type ProjectExpenseRejectInput = {
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
};

export type ProjectHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  description: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  registrationNo?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
};

export type ProjectOpportunityActivityLog = {
  __typename?: 'ProjectOpportunityActivityLog';
  personInChargeID?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  remarks?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
};

export type ProjectOpportunityActivityLogInput = {
  personInChargeID?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  remarks?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
};

export type ProjectOpportunityAttachmentDeleteInput = {
  ID: Scalars['String'];
};

export type ProjectOpportunityAttachmentEntity = AuditEntity & {
  __typename?: 'ProjectOpportunityAttachmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  projectOpportunityID: Scalars['String'];
  projectOpportunity?: Maybe<ProjectOpportunityEntity>;
  title: Scalars['String'];
  date: Scalars['String'];
};

export type ProjectOpportunityAttachmentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  projectOpportunityID: Scalars['String'];
  title: Scalars['String'];
  date: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ProjectOpportunityDeleteInput = {
  ID: Scalars['String'];
};

export type ProjectOpportunityEntity = AuditEntity & {
  __typename?: 'ProjectOpportunityEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  customerID: Scalars['String'];
  customer?: Maybe<CustomerEntity>;
  title: Scalars['String'];
  description: Scalars['String'];
  location: Scalars['String'];
  amount: Scalars['Float'];
  personInChargeID: Scalars['String'];
  closingDateTime: Scalars['DateTime'];
  remarks: Scalars['String'];
  activityLog?: Maybe<Array<Scalars['JSON']>>;
  status: ProjectOpportunityStatus;
  projectOpportunitySubmission?: Maybe<Array<ProjectOpportunitySubmissionEntity>>;
  projectOpportunityAttachment?: Maybe<Array<ProjectOpportunityAttachmentEntity>>;
  personInCharge?: Maybe<UserEntity>;
};

export type ProjectOpportunityInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  customerID: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  location: Scalars['String'];
  personInChargeID?: Maybe<Scalars['String']>;
  closingDateTime: Scalars['DateTime'];
  amount: Scalars['Float'];
  remarks: Scalars['String'];
  status?: Maybe<ProjectOpportunityStatus>;
};

export enum ProjectOpportunityStatus {
  Open = 'OPEN',
  Submitted = 'SUBMITTED',
  Signed = 'SIGNED',
  Lost = 'LOST'
}

export type ProjectOpportunitySubmissionDeleteInput = {
  ID: Scalars['String'];
};

export type ProjectOpportunitySubmissionEntity = AuditEntity & {
  __typename?: 'ProjectOpportunitySubmissionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  projectOpportunityID: Scalars['String'];
  projectOpportunity?: Maybe<ProjectOpportunityEntity>;
  submissionDate: Scalars['String'];
  tenderAmt?: Maybe<Scalars['Float']>;
  remarks: Scalars['String'];
};

export type ProjectOpportunitySubmissionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  projectOpportunityID: Scalars['String'];
  submissionDate: Scalars['String'];
  tenderAmt?: Maybe<Scalars['Float']>;
  remarks: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ProjectPlDetails = {
  __typename?: 'ProjectPLDetails';
  doSumPerCC?: Maybe<Scalars['JSON']>;
  progressClaimListing?: Maybe<Scalars['JSON']>;
  subconClaimListing?: Maybe<Scalars['JSON']>;
  materialDOListing?: Maybe<Scalars['JSON']>;
  labourDOListing?: Maybe<Scalars['JSON']>;
  plantDOListing?: Maybe<Scalars['JSON']>;
  miscDOListing?: Maybe<Scalars['JSON']>;
  voSum?: Maybe<Scalars['Float']>;
  origContractSum?: Maybe<Scalars['Float']>;
  revisedContractSum?: Maybe<Scalars['Float']>;
  totalProgressClaim?: Maybe<Scalars['Float']>;
  totalClaimSum?: Maybe<Scalars['Float']>;
  grossTotal?: Maybe<Scalars['Float']>;
  subcontractClaimSum?: Maybe<Scalars['Float']>;
  totalSubconSum?: Maybe<Scalars['Float']>;
};

export type ProjectPlanningEntity = AuditEntity & {
  __typename?: 'ProjectPlanningEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  projectSum: Scalars['Float'];
  totalIncome: Scalars['Float'];
  totalCost: Scalars['Float'];
};

export type PublicHolidayDeleteInput = {
  ID: Scalars['String'];
};

export type PublicHolidayEntity = AuditEntity & {
  __typename?: 'PublicHolidayEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  calendarConnection?: Maybe<Array<CalendarPhPolicyEntity>>;
  commonStatus: CommonStatus;
};

export type PublicHolidayInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
};

export type PurchaseActionInput = {
  remarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  purchaseStatus: PurchaseStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type PurchaseBudgetInput = {
  remarks: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type PurchaseOrderEntity = AuditEntity & {
  __typename?: 'PurchaseOrderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  siteID?: Maybe<Scalars['String']>;
  deliveryAddress?: Maybe<SiteEntity>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontract?: Maybe<SubcontractEntity>;
  supplierID: Scalars['String'];
  supplier?: Maybe<SupplierEntity>;
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  contactPerson?: Maybe<ContactPerson>;
  exchangeRate: Scalars['Float'];
  totalAmt: Scalars['Float'];
  taxAmt?: Maybe<Scalars['Float']>;
  markupAmt?: Maybe<Scalars['Float']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  expectedDate?: Maybe<Scalars['String']>;
  creditTerm: Scalars['Float'];
  instructions?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  cancelCloseRemark?: Maybe<Scalars['String']>;
  prGenerated: Scalars['Boolean'];
  submissionComment?: Maybe<Scalars['String']>;
  purchaseType: PurchaseType;
  purchaseStatus: PurchaseStatus;
  posConnection?: Maybe<Array<DoItemEntity>>;
  poItem?: Maybe<Array<PoItemEntity>>;
  toDo: Array<ContractToDoEntity>;
  /** CustomFieldResolver */
  upToDateAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  estimatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  deliveryStatus?: Maybe<Scalars['JSON']>;
};


export type PurchaseOrderEntityUpToDateAmtArgs = {
  taxInclusive?: Maybe<Scalars['Boolean']>;
};


export type PurchaseOrderEntityEstimatedAmtArgs = {
  taxInclusive?: Maybe<Scalars['Boolean']>;
};

export type PurchaseOrderInput = {
  supplierID: Scalars['String'];
  totalAmt: Scalars['Float'];
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  purchaseReqID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  expectedDate?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  prGenerated?: Maybe<Scalars['Boolean']>;
  purchaseStatus?: Maybe<PurchaseStatus>;
  purchaseType?: Maybe<PurchaseType>;
  contactPerson?: Maybe<Scalars['JSON']>;
  address?: Maybe<Scalars['JSON']>;
  poItems: Array<PoItemInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type PurchaseReqEntity = AuditEntity & {
  __typename?: 'PurchaseReqEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  contract?: Maybe<ContractEntity>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontract?: Maybe<SubcontractEntity>;
  siteID?: Maybe<Scalars['String']>;
  deliveryAddress?: Maybe<SiteEntity>;
  remarks?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  purchaseType: PurchaseType;
  submittedDate?: Maybe<Scalars['String']>;
  prDate?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  expectedDate: Scalars['String'];
  rejectionDate?: Maybe<Scalars['String']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  cancelCloseRemark?: Maybe<Scalars['String']>;
  requestedBy: Scalars['String'];
  contactPerson: Scalars['JSON'];
  instructions: Scalars['String'];
  submissionComment?: Maybe<Scalars['String']>;
  purchaseStatus: PurchaseStatus;
  prItem?: Maybe<Array<PrItemEntity>>;
  rfq?: Maybe<Array<RfqEntity>>;
  toDo: Array<ContractToDoEntity>;
  purchaseRFQItem: Array<PrItemEntity>;
  /** CustomFieldResolver */
  isOutstanding?: Maybe<Scalars['Boolean']>;
};

export type PurchaseReqInput = {
  purchaseType: PurchaseType;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  expectedDate: Scalars['String'];
  siteID?: Maybe<Scalars['String']>;
  contactPerson: ContactPersonInput;
  creditTerm?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  instructions: Scalars['String'];
  submissionComment?: Maybe<Scalars['String']>;
  requestedBy: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
  purchaseStatus?: Maybe<PurchaseStatus>;
};

export enum PurchaseStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Cancelled = 'CANCELLED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Closed = 'CLOSED',
  Pending = 'PENDING',
  Pendingclose = 'PENDINGCLOSE'
}

export enum PurchaseType {
  Main = 'MAIN',
  Sub = 'SUB'
}

export type PurchaseWbsBudgetAllocationInput = {
  prItemID: Scalars['String'];
  wbsID: Scalars['String'];
};

export type QualificationChecklistEntity = AuditEntity & {
  __typename?: 'QualificationChecklistEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  includeAttachment: Scalars['Boolean'];
  qualificationChecklistItem?: Maybe<Array<QualificationChecklistItemEntity>>;
};

export type QualificationChecklistItemEntity = AuditEntity & {
  __typename?: 'QualificationChecklistItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  qualificationChecklistID: Scalars['String'];
  qualificationChecklist?: Maybe<QualificationChecklistEntity>;
  fieldTypeID: Scalars['String'];
  fieldType?: Maybe<FieldTypeEntity>;
  title: Scalars['String'];
  isCompulsory: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  negoItemInfo: Scalars['JSON'];
  negoItemCount: Scalars['Float'];
  prInfoCount: Scalars['JSON'];
  lastPRDate?: Maybe<Scalars['String']>;
  lastPRDateOB?: Maybe<Scalars['String']>;
  rfqInfo: Scalars['JSON'];
  rfqCount: Scalars['Float'];
  lastRFQDate?: Maybe<Scalars['String']>;
  poInfoCount: Scalars['JSON'];
  lastPODate?: Maybe<Scalars['String']>;
  lastPODateOB?: Maybe<Scalars['String']>;
  doCount: Scalars['Float'];
  lastDODate?: Maybe<Scalars['String']>;
  grtnCount: Scalars['Float'];
  lastGRTNDate?: Maybe<Scalars['String']>;
  getContractPLAdjustmentTotal: Scalars['Float'];
  getSubcontractPLAdjustmentTotal: Scalars['Float'];
  getSupplierPLAdjustmentTotal: Scalars['Float'];
  getSubcontractPLAdjustmentStatusTotal: Scalars['Float'];
  getContractPLAdjustmentStatusTotal: Scalars['Float'];
  getSupplierPLAdjustmentStatusTotal: Scalars['Float'];
  getAdjustmentByCostClass: Scalars['JSON'];
  budgetingCalculation?: Maybe<Scalars['JSON']>;
  costingCalculation?: Maybe<Scalars['JSON']>;
  getAdvanceClient: Scalars['JSON'];
  getPaidSubconSupplierAmount: Scalars['JSON'];
  outstandingReceivableFromClientListing?: Maybe<Scalars['JSON']>;
  outstandingPayableSubconListing?: Maybe<Scalars['JSON']>;
  outstandingPayableSupplierListing?: Maybe<Scalars['JSON']>;
  paidSubconSupplierListing: Scalars['JSON'];
  advanceClient: AdvanceFromClient;
  receivedFromClient: Scalars['JSON'];
  paidToSubCon: Scalars['JSON'];
  paidToSupplier: Scalars['JSON'];
  advancesOutflowAmt: Scalars['JSON'];
  miscExpense: Scalars['JSON'];
  osReceivableFromClient: Array<ContractClaimEntity>;
  clientLastReceipt?: Maybe<Scalars['DateTime']>;
  subconLastPayment?: Maybe<Scalars['DateTime']>;
  receivableClientLastPayment?: Maybe<Scalars['DateTime']>;
  outstandingAmtClient?: Maybe<Scalars['JSON']>;
  paidSubconSupplierAmount: Scalars['JSON'];
  OSReceivableFromClientListing: Scalars['JSON'];
  subconOSPayableAmount: Scalars['JSON'];
  OSPayableToSubconListing: Scalars['JSON'];
  supplierLastPayment?: Maybe<Scalars['DateTime']>;
  supplierOSPayableAmount: Scalars['JSON'];
  OSPayableToSupplierListing: Scalars['JSON'];
  outstandingReceivableFromClient?: Maybe<Scalars['JSON']>;
  outstandingPayableSubcon?: Maybe<Scalars['JSON']>;
  outstandingPayableSupplier?: Maybe<Scalars['JSON']>;
  associateCalculation: AssociatedDetails;
  getProjectCostingDetail: Scalars['JSON'];
  getCostingCalculation: Scalars['JSON'];
  costCategCount: Scalars['JSON'];
  adjustmentByCostClass: Scalars['JSON'];
  adjustmentItemByCostCateg: Scalars['JSON'];
  APInvoiceWoDOByCostItem: Scalars['JSON'];
  getProjectCostingAmt: Scalars['JSON'];
  getAdjustmentItemByCostCateg: Scalars['JSON'];
  getCostCategCount: Scalars['JSON'];
  getPOsByCategory: Array<PurchaseOrderEntity>;
  getGRTNsByCategoryDO: Array<GoodReturnNoteEntity>;
  getDOsByCategory: Array<DoEntity>;
  getAPInvoiceWoDOByCostItem: Scalars['JSON'];
  getPCYear: Array<Scalars['Float']>;
  getReportYear: Array<Scalars['Float']>;
  getCommCostAmt: Scalars['JSON'];
  financialStatus: FinancialStatusDetails;
  getWbsParentChildrens: Scalars['JSON'];
  projectLedgerWbsRevenueSummary: Scalars['JSON'];
  projectLedgerWbsRetentionSummary: Scalars['JSON'];
  projectLedgerWbsCostSummary: Scalars['JSON'];
  getCostCategoryReport: Array<CostCategoryEntity>;
  getCostItemReport: Array<CostItemEntity>;
  projectPL: ProjectPlDetails;
  getBQ: Array<BqEntity>;
  getCheckingValidationBQ: Scalars['JSON'];
  getBQWbsParentChild: Array<BqEntity>;
  getBQParentChild: Array<BqEntity>;
  getBQWbsAssignment: Array<BqWbsAssignmentEntity>;
  getContractCalendarInfo: Scalars['JSON'];
  getSubconCalendarInfo: Scalars['JSON'];
  getPPCalendarInfo: Scalars['JSON'];
  getBillInterest: Array<BillInterestEntity>;
  getStatementAcctMonths: Scalars['JSON'];
  getClaimRecListByMonth: Scalars['JSON'];
  getCustContractIDs: Scalars['JSON'];
  getClientDebitDocListing: Scalars['JSON'];
  getClientDebitDocEInvoice: Scalars['JSON'];
  getPaymentReminder: Array<PaymentReminderEntity>;
  getDocReminder: Scalars['JSON'];
  getPaymentReminderClaim: Scalars['JSON'];
  getPaymentReminderByCompany: Scalars['JSON'];
  getDebitDoc: Scalars['JSON'];
  getPaymentReminderConCust: Scalars['JSON'];
  getDocReminderDetails: Scalars['JSON'];
  getPaymentReminderLastDate: Scalars['JSON'];
  getPrintReminder: Scalars['JSON'];
  getCustomerReminder: Array<CustomerReminderEntity>;
  getContract: Array<ContractEntity>;
  getCustomContract: Array<ContractEntity>;
  contractVoCalculation: Scalars['JSON'];
  maxRetention: Scalars['Float'];
  claimChecking: Scalars['Boolean'];
  voChecking: Scalars['Boolean'];
  retentionChecking: Scalars['Boolean'];
  validateContractStatus?: Maybe<Scalars['Boolean']>;
  getContractWithoutPerm: Array<ContractEntity>;
  getContractEntityWithAccount: Array<ContractEntity>;
  getContractWithAccount: Scalars['JSON'];
  getContractTitleWithAccount: Scalars['JSON'];
  getContractWithActiveDO: Array<ContractEntity>;
  checkWbsExist: Scalars['Boolean'];
  getUserRoleAssigned: Array<RoleEntity>;
  getContractGuarantee: Array<ContractGuaranteeEntity>;
  getContractInsurance: Array<ContractInsuranceEntity>;
  getProjectBudget: Array<ProjectBudgetEntity>;
  deleteBudgetChecking: Scalars['JSON'];
  budgetCalculation?: Maybe<Scalars['JSON']>;
  getContractVO: Array<ContractVoEntity>;
  getVOHealthIndex: VoHealthIndex;
  getContractAllocation: Array<ContractAllocationEntity>;
  getContractAdvanceAllocation: Scalars['JSON'];
  getContractReceiptAllocation: Scalars['JSON'];
  getContractRefundAllocation: Scalars['JSON'];
  getClaimAllocationLedgers: Scalars['JSON'];
  getContractRetentionClaimAllocation: Scalars['JSON'];
  getContractRetClaimAllocation: Scalars['JSON'];
  getContractCNAllocation: Scalars['JSON'];
  getContractDNAllocation: Scalars['JSON'];
  getContractMiscInvoiceAllocation: Scalars['JSON'];
  getClientCNAllocation: Scalars['JSON'];
  getClientDNAllocation: Scalars['JSON'];
  getContractClaim: Array<ContractClaimEntity>;
  getContractClaimCount: Scalars['Float'];
  getRetentionClaim: Array<ContractClaimEntity>;
  fetchUnallocatedClaimRetention: Scalars['JSON'];
  getProgressClaim: Array<ContractClaimEntity>;
  getContractAdvance: Array<ContractAdvanceEntity>;
  getContractAdvanceCount: Scalars['Float'];
  getContractReceipt: Array<ContractReceiptEntity>;
  getContractReceiptCount: Scalars['Float'];
  getContractRefund: Array<ContractRefundEntity>;
  getContractRefundCount: Scalars['Float'];
  getContractMiscInvoice: Array<ContractMiscInvoiceEntity>;
  getContractMiscInvoiceCount: Scalars['Float'];
  getContractDN: Array<ContractDnEntity>;
  getContractDNCount: Scalars['Float'];
  getContractCN: Array<ContractCnEntity>;
  getContractCNCount: Scalars['Float'];
  getClientDN: Array<ClientDnEntity>;
  getClientDNCount: Scalars['Float'];
  getClientCN: Array<ClientCnEntity>;
  getClientCNCount: Scalars['Float'];
  getCNRevenue: Array<ContractCnEntity>;
  getCostCode: Array<CostCodeEntity>;
  getCustomerCompanyAssignment: Array<CustomerCompanyAssignmentEntity>;
  getCustomerByCompany: Array<CustomerEntity>;
  getCustomer: Array<CustomerEntity>;
  getClientAccounts: Array<CustomerEntity>;
  getFollowUp: Array<FollowUpEntity>;
  getCBExport: Array<CbExportEntity>;
  getContractCB?: Maybe<Scalars['JSON']>;
  getContractCBInfo?: Maybe<Scalars['JSON']>;
  getGLTaxType: Array<Scalars['JSON']>;
  getGLAdjustmentType: Array<Scalars['JSON']>;
  getGLExport: Array<GlExportEntity>;
  GLMonths: Array<GlMonth>;
  getContractGL?: Maybe<Scalars['JSON']>;
  getSubconGL?: Maybe<Scalars['JSON']>;
  getSupplierGL?: Maybe<Scalars['JSON']>;
  getPurchaseGL?: Maybe<Scalars['JSON']>;
  getPOBGL?: Maybe<Scalars['JSON']>;
  getGLClaimDetailLedger: Scalars['JSON'];
  getGLDetailLedger: Scalars['JSON'];
  getGRTNDetailLedger: Scalars['JSON'];
  getDODetailLedger: Array<DoEntity>;
  getBatchGLDetailLedger: Scalars['JSON'];
  checkedGLEditable: Array<Scalars['String']>;
  getGLSetupFile: Scalars['JSON'];
  getGLAssociationFile: Scalars['JSON'];
  getGLTransactionFile: Scalars['JSON'];
  getGLInterfaceMaster: Array<GlInterfaceMasterEntity>;
  getGLInterfaceDetail: Array<GlInterfaceDetailEntity>;
  getGLTaxDynamic: Array<GlTaxDynamicEntity>;
  getGLAdjustmentDynamic: Array<GlAdjustmentDynamicEntity>;
  getGroupBiAccountReceivableSummary?: Maybe<Scalars['JSON']>;
  getGroupBiAccountReceivableDialog: Scalars['JSON'];
  getGroupBiCashFlowSummary: Scalars['JSON'];
  getGroupBiCashFlowDialog: Scalars['JSON'];
  getGroupBiKeyApiSummary: Scalars['JSON'];
  getGroupBiKeyApiDialogSummary: Scalars['JSON'];
  getGroupBiKeyKpiClaim: Scalars['JSON'];
  getGroupBiKeyKpiCollection: Scalars['JSON'];
  getGroupBiProjectCostingSummary: Scalars['JSON'];
  getGroupBIProfitLostSummary: Scalars['JSON'];
  getGroupBiSite: Scalars['JSON'];
  getGroupBISiteGallery: Scalars['JSON'];
  getGroupBiVoSummary?: Maybe<GroupBizVo>;
  getGroupBizVO?: Maybe<Scalars['JSON']>;
  getGroupBizVoSubmenu?: Maybe<Scalars['JSON']>;
  getGuaranteeType: Array<GuaranteeTypeEntity>;
  getInsuranceType: Array<InsuranceTypeEntity>;
  getMiscExpense: Array<MiscExpenseEntity>;
  getMiscExpenseCount: Scalars['Float'];
  miscExpenseBudgetChecking: BudgetInfo;
  getCostCenter: Array<CostCenterEntity>;
  getCurrencyExchange: Array<CurrencyExchangeEntity>;
  getCurrency: Array<CurrencyEntity>;
  getConTaxScheme: Array<ConTaxSchemeEntity>;
  getConTaxSchemeWithPerm: Array<ConTaxSchemeEntity>;
  getConTaxEffectiveDate: Array<ConTaxEffectiveDateEntity>;
  getConTaxType: Array<ConTaxTypeEntity>;
  verifyTaxType: Scalars['String'];
  getTaxCodeGLExport: Scalars['JSON'];
  getFilterTaxCode: Scalars['JSON'];
  getProfitRecogFormula: Array<ProfitRecogFormulaEntity>;
  getProfitRecogParameter: Array<ProfitRecogParameterEntity>;
  getProfitRecogAssignment: Array<ProfitRecogAssignmentEntity>;
  getProfitRecogGeneration: Scalars['JSON'];
  getProfitRecogGenParamAssignment: Array<ProfitRecogGenParamAssgmtEntity>;
  getContractListingProfitRecog: Scalars['JSON'];
  profitRecognitionCalculation: Scalars['JSON'];
  C1_actualCost: Scalars['Float'];
  C2_budgetCost: Scalars['Float'];
  C3_actualRevenue: Scalars['Float'];
  C4_reviseContractSum: Scalars['Float'];
  C5_totalPreviousRecognisedRevenue: Scalars['Float'];
  C6_totalPreviousRecognisedCost: Scalars['Float'];
  C7_percOfCompletion: Scalars['Float'];
  getProfitRecognition: Array<ProfitRecogFormulaEntity>;
  infoLineProfitRecog: Scalars['JSON'];
  getProfitRecogGenerationListing: Scalars['JSON'];
  getProfitRecogGLAccountCode: Array<ProfitRecogGlAccountCodeEntity>;
  getProfitRecogGLJournal: Array<ProfitRecogGlJournalEntity>;
  getProjectExpense: Array<ProjectExpenseEntity>;
  getProjectExpenseItem: Array<ProjectExpenseItemEntity>;
  getProjectExpenseCount: Scalars['Float'];
  getProjectOpportunityAttachment: Array<ProjectOpportunityAttachmentEntity>;
  getProjectOpportunity: Array<ProjectOpportunityEntity>;
  getProjectOpportunitySubmission: Array<ProjectOpportunitySubmissionEntity>;
  getCheckingValidationWBSBudget: Scalars['JSON'];
  getWbsBudget: Array<WbsBudgetEntity>;
  getBudget: Array<WbsBudgetEntity>;
  checkWBSBudgetApproved: Scalars['Boolean'];
  getWbsBudgetDetail: Array<WbsBudgetDetailEntity>;
  getWbsBudgetDetailScheduling: Array<WbsBudgetDetailScheduleEntity>;
  getWbsDayworkCostRevenue: Array<WbsDayworkCostRevenueEntity>;
  getTotalCostRevenue: Scalars['JSON'];
  getCostWBSBudgetDetail: Scalars['JSON'];
  getRevenueWBSBudgetDetail: Scalars['JSON'];
  getWbsBudgetDetailByClass: Scalars['JSON'];
  checkWbsBudgetDetail: WbsBudgetDetailEntity;
  checkMultipleWbsBudgetDetail: Scalars['JSON'];
  checkSubcontractMultipleWbsBudgetDetail: Scalars['JSON'];
  checkExistCostRevenue: Scalars['Boolean'];
  getCostCategoryWbsBudget: Scalars['JSON'];
  getWBSBudgetTransferHeader: Array<WbsBudgetTransferHeaderEntity>;
  getWBSBudgetTransferDetail: Array<WbsBudgetTransferDetailEntity>;
  getWBSParentChild: Scalars['JSON'];
  WBSBudgetTransferResource: Scalars['JSON'];
  getWbsBudgetTransfer: Scalars['JSON'];
  getBudgetTransferCostCategoryBalance: Scalars['JSON'];
  getWBS: Array<WbsEntity>;
  getWbsTotalCostRevenue: Scalars['JSON'];
  getWbsCostAllocationByClass: Scalars['JSON'];
  getMultipleWBS: Array<WbsEntity>;
  getWbsSchedule: Array<WbsScheduleEntity>;
  getWBSUtilizedBudget: Scalars['JSON'];
  getCostCategoryFromWbs: Scalars['JSON'];
  getDrawerPermission: Scalars['JSON'];
  getSiteDrawerPermission: Scalars['JSON'];
  Check: Scalars['Boolean'];
  UserEntities: Array<Scalars['String']>;
  testPermission: Scalars['String'];
  siteTestPermission: Scalars['String'];
  testConPermission: Scalars['String'];
  testSampleEnum: Scalars['String'];
  checkDocExist?: Maybe<Array<DocumentsEntity>>;
  DocumentListing?: Maybe<Array<DocumentsEntity>>;
  cmgdAttachment?: Maybe<Array<DocumentsEntity>>;
  totalDigitalDoc: Scalars['Float'];
  auditLogDetails: Scalars['JSON'];
  getCashFlowLineChartData: Scalars['JSON'];
  getCashFlowGraphData: Scalars['JSON'];
  getContractNews: Array<ContractNewsEntity>;
  getContractNewsRead: Array<ContractNewsReadEntity>;
  getCollectionActivity: Array<CollectionActivityEntity>;
  getCompanyHOR: Array<CompanyEntity>;
  getCompany: Array<CompanyEntity>;
  getRawCompany: Array<SubscriptionCompanyType>;
  getSubscriptionCurrency: Array<SubscriptionCurrencyType>;
  getContact: Array<ContactEntity>;
  getAdjustmentType: Array<AdjustmentTypeEntity>;
  getDocumentFolder?: Maybe<Array<DocumentFolderEntity>>;
  totalDocFolder: Scalars['Float'];
  getLocalBank: Array<LocalBankEntity>;
  getProfitRecogFormulaScript: Scalars['String'];
  getDate: Scalars['JSON'];
  submittedRetClaimChecking: Scalars['JSON'];
  submittedClaimChecking?: Maybe<Scalars['JSON']>;
  approvedClaimChecking: ClaimSummary;
  cancellableClaims: Array<Scalars['String']>;
  approvableLedgerChecking: LedgerCheckingSummary;
  getBatchPaymentDetail: Array<BatchPaymentDetailEntity>;
  getBatchPaymentDetailbySupplier: Scalars['JSON'];
  getBatchPaymentHeader: Array<BatchPaymentHeaderEntity>;
  getBatchPaymentbyStatus: Array<BatchPaymentHeaderEntity>;
  getBatchPaymentbyStatusCount: Scalars['Float'];
  getContractLedgers: Scalars['JSON'];
  getSubcontractorLedgers: Scalars['JSON'];
  getSupplierLedgers: Scalars['JSON'];
  getSiteMaterialPolicy: Array<SiteMaterialPolicyEntity>;
  getSiteMaterialContractPolicy: Array<SiteMaterialContractPolicyEntity>;
  getDefaultMaterialPolicyIDs: Array<Scalars['String']>;
  getSiteMaterialPolicyChecker: Scalars['JSON'];
  calendarTypeDate?: Maybe<Scalars['DateTime']>;
  holidayTypeDate?: Maybe<Scalars['DateTime']>;
  jobTypeDate?: Maybe<Scalars['DateTime']>;
  projectSiteDate?: Maybe<Scalars['DateTime']>;
  publicHolidayDate?: Maybe<Scalars['DateTime']>;
  siteMaterialDate?: Maybe<Scalars['DateTime']>;
  wagesRatePolicyDate?: Maybe<Scalars['DateTime']>;
  workingHourPolicyDate?: Maybe<Scalars['DateTime']>;
  videoCamDate?: Maybe<Scalars['DateTime']>;
  subconSetupDate?: Maybe<Scalars['DateTime']>;
  workerProfileDate?: Maybe<Scalars['DateTime']>;
  siteMaterialPolicyDate?: Maybe<Scalars['DateTime']>;
  associateMenu: Scalars['JSON'];
  totalClients: Scalars['Float'];
  pendingCollectionActivity?: Maybe<Scalars['JSON']>;
  paymentReminderSummary?: Maybe<Scalars['JSON']>;
  clientNotifications: Scalars['JSON'];
  totalDebitClient: Scalars['Float'];
  totalCreditClient: Scalars['Float'];
  totalOutstandingClients: Scalars['Float'];
  totalOsClientInvoices: ClientSoaInfo;
  totalOutstandingAmtClients?: Maybe<Scalars['Float']>;
  supplierProfileDate?: Maybe<Scalars['DateTime']>;
  PrefSupplierProductDate?: Maybe<Scalars['DateTime']>;
  SupplierAccDate?: Maybe<Scalars['DateTime']>;
  contractInfo: ContractInfo;
  contractGuaranteeInfo: Scalars['JSON'];
  contractInsuranceInfo: Scalars['JSON'];
  submittedVONotification: Scalars['Float'];
  contractVO: ContractVo;
  outstandingAdvances: Array<ContractAdvanceEntity>;
  outstandingClaims: Array<ContractClaimEntity>;
  outstandingMiscInvs: Array<ContractMiscInvoiceEntity>;
  retention: RetentionInfo;
  lastSiteProgressDate?: Maybe<Scalars['DateTime']>;
  wbsBudgetInfo?: Maybe<Scalars['JSON']>;
  wbsBudgetRevisionInfo?: Maybe<Scalars['JSON']>;
  utilizedBudgetInfo?: Maybe<Scalars['JSON']>;
  budgetTransferInfo?: Maybe<Scalars['JSON']>;
  lastBqImportDate?: Maybe<Scalars['DateTime']>;
  bqAssignmentInfo?: Maybe<Scalars['JSON']>;
  miscExpInfo?: Maybe<Scalars['JSON']>;
  lastMiscExpDate?: Maybe<Scalars['DateTime']>;
  lastExtensionDate?: Maybe<Scalars['String']>;
  contractClosureDate?: Maybe<Scalars['DateTime']>;
  subcontractInfo: SubontractInfo;
  subconGuaranteeInfo: Scalars['JSON'];
  subconInsuranceInfo: Scalars['JSON'];
  submittedVOSubconNotification: Scalars['Float'];
  subcontractVO: SubcontractVo;
  subconOutstandingAdvances: Array<SubcontractAdvanceEntity>;
  subconOutstandingClaims: Array<SubcontractClaimEntity>;
  subconLastExtensionDate?: Maybe<Scalars['String']>;
  subconClosureDate?: Maybe<Scalars['DateTime']>;
  latestDefectDate?: Maybe<Scalars['DateTime']>;
  latestAssignmentDate?: Maybe<Scalars['DateTime']>;
  siteWorkerAttendanceInfo: Scalars['JSON'];
  MOSCount: Scalars['Float'];
  glExportDate?: Maybe<Scalars['DateTime']>;
  cbExportDate?: Maybe<Scalars['DateTime']>;
  costCategoryDates?: Maybe<Scalars['JSON']>;
  costItemDates?: Maybe<Scalars['JSON']>;
  costCategoryDate?: Maybe<Scalars['DateTime']>;
  costItemDate?: Maybe<Scalars['DateTime']>;
  revenueCategoryDate?: Maybe<Scalars['DateTime']>;
  guaranteeTypeDate?: Maybe<Scalars['DateTime']>;
  insuranceTypeDate?: Maybe<Scalars['DateTime']>;
  workshopDate?: Maybe<Scalars['DateTime']>;
  paymentReminderDate?: Maybe<Scalars['DateTime']>;
  approvalPolicyDate?: Maybe<Scalars['DateTime']>;
  approvalPolicyWithContractDate?: Maybe<Scalars['DateTime']>;
  supplierTypeDate?: Maybe<Scalars['DateTime']>;
  subcontractorTypeDate?: Maybe<Scalars['DateTime']>;
  glAccountCodeDate?: Maybe<Scalars['DateTime']>;
  clientSetupDate?: Maybe<Scalars['DateTime']>;
  supplierSetupDate?: Maybe<Scalars['DateTime']>;
  clientAccDate?: Maybe<Scalars['DateTime']>;
  subconAccDate?: Maybe<Scalars['DateTime']>;
  supplierAccDate?: Maybe<Scalars['DateTime']>;
  purchaseAccDate?: Maybe<Scalars['DateTime']>;
  pobAccDate?: Maybe<Scalars['DateTime']>;
  bankAccDate?: Maybe<Scalars['DateTime']>;
  taxAccDate?: Maybe<Scalars['DateTime']>;
  tradeAccDate?: Maybe<Scalars['DateTime']>;
  materialAccDate?: Maybe<Scalars['DateTime']>;
  plantAccDate?: Maybe<Scalars['DateTime']>;
  labourAccDate?: Maybe<Scalars['DateTime']>;
  miscAccDate?: Maybe<Scalars['DateTime']>;
  departmentDate?: Maybe<Scalars['DateTime']>;
  journalAccDate?: Maybe<Scalars['DateTime']>;
  userDate: Scalars['DateTime'];
  roleDate?: Maybe<Scalars['DateTime']>;
  conAuthDate?: Maybe<Scalars['DateTime']>;
  conAuthCount: Scalars['Float'];
  docNumDate?: Maybe<Scalars['DateTime']>;
  bankAccountDate?: Maybe<Scalars['DateTime']>;
  taxSchemeDate?: Maybe<Scalars['DateTime']>;
  uomDate?: Maybe<Scalars['DateTime']>;
  uomConversionDate?: Maybe<Scalars['DateTime']>;
  companyDate?: Maybe<Scalars['DateTime']>;
  policyDate?: Maybe<Scalars['DateTime']>;
  notificationDate?: Maybe<Scalars['DateTime']>;
  costCenterDate?: Maybe<Scalars['DateTime']>;
  currencyExchangeDate?: Maybe<Scalars['DateTime']>;
  currencySetupDate?: Maybe<Scalars['DateTime']>;
  activeClientCount: Scalars['Float'];
  clientCount: Scalars['JSON'];
  contractCount: Scalars['JSON'];
  activeContractCount: Scalars['Float'];
  activeSiteCount: Scalars['Float'];
  subcontractCount: Scalars['JSON'];
  activeSubcontractCount: Scalars['Float'];
  activeSubcontractTenderCount: Scalars['Float'];
  subcontractorCount: Scalars['JSON'];
  activeSubcontractorCount: Scalars['Float'];
  supplierCount: Scalars['JSON'];
  activeSupplierCount: Scalars['Float'];
  activePlantMachineryCount: Scalars['Float'];
  lastPurchaseDate?: Maybe<Scalars['DateTime']>;
  projectPurchaseCount?: Maybe<Scalars['JSON']>;
  fuelInfo: Scalars['JSON'];
  ownedPNMInfo: Scalars['JSON'];
  rentedPNMInfo: Scalars['JSON'];
  assignmentRecord: Scalars['Float'];
  maintenanceRecord: Scalars['Float'];
  fuelTankCount: Scalars['JSON'];
  clientProfileDate: Scalars['String'];
  submittedCusAVNotification: Scalars['Float'];
  advancesInfo?: Maybe<Scalars['JSON']>;
  claimsInfo?: Maybe<Scalars['JSON']>;
  submittedClaimNotification: Scalars['Float'];
  progressClaimsInfo?: Maybe<Scalars['JSON']>;
  submittedRetClaimNotification: Scalars['Float'];
  retentionClaimsInfo?: Maybe<Scalars['JSON']>;
  submittedCNNotification: Scalars['Float'];
  CNInfo?: Maybe<Scalars['JSON']>;
  submittedDNNotification: Scalars['Float'];
  DNInfo?: Maybe<Scalars['JSON']>;
  submittedMiscInvNotification: Scalars['Float'];
  miscInvInfo?: Maybe<Scalars['JSON']>;
  submittedClientDNNotification: Scalars['Float'];
  clientDNInfo?: Maybe<Scalars['JSON']>;
  submittedClientCNNotification: Scalars['Float'];
  clientCNInfo?: Maybe<Scalars['JSON']>;
  submittedReceiptNotification: Scalars['Float'];
  receiptsInfo?: Maybe<Scalars['JSON']>;
  submittedRefundNotification: Scalars['Float'];
  refundsInfo?: Maybe<Scalars['JSON']>;
  outstandingClient?: Maybe<Scalars['JSON']>;
  unallocatedClient?: Maybe<Scalars['JSON']>;
  conClientProfileDate: Scalars['DateTime'];
  conAdvancesInfo?: Maybe<Scalars['JSON']>;
  conClaimsInfo?: Maybe<Scalars['JSON']>;
  conDNInfo?: Maybe<Scalars['JSON']>;
  conCNInfo?: Maybe<Scalars['JSON']>;
  conReceiptsInfo?: Maybe<Scalars['JSON']>;
  conRefundsInfo?: Maybe<Scalars['JSON']>;
  conClientDNInfo?: Maybe<Scalars['JSON']>;
  conOutstandingClient?: Maybe<Scalars['JSON']>;
  subconProfileDate?: Maybe<Scalars['String']>;
  subconLedgersInfo?: Maybe<Scalars['JSON']>;
  submittedSubconAVNotification: Scalars['Float'];
  subconAdvancesInfo?: Maybe<Scalars['JSON']>;
  subconClaimsInfo?: Maybe<Scalars['JSON']>;
  submittedSubconClaimNotification: Scalars['Float'];
  subconProgressClaimsInfo?: Maybe<Scalars['JSON']>;
  submittedSubconRetClaimNotification: Scalars['Float'];
  subconRetentionClaimsInfo?: Maybe<Scalars['JSON']>;
  submittedSubconMiscInvNotification: Scalars['Float'];
  subconMiscInvInfo?: Maybe<Scalars['JSON']>;
  submittedSubconDNNotification: Scalars['Float'];
  subconDNInfo?: Maybe<Scalars['JSON']>;
  submittedSubconCNNotification: Scalars['Float'];
  subconCNInfo?: Maybe<Scalars['JSON']>;
  submittedPaymentNotification: Scalars['Float'];
  subconPaymentInfo?: Maybe<Scalars['JSON']>;
  submittedSubconRefundNotification: Scalars['Float'];
  subconRefundsInfo?: Maybe<Scalars['JSON']>;
  submittedDNtoSubconNotification: Scalars['Float'];
  DNtoSubconInfo?: Maybe<Scalars['JSON']>;
  submittedCNtoSubconNotification: Scalars['Float'];
  CNtoSubconInfo?: Maybe<Scalars['JSON']>;
  outstandingSubcon?: Maybe<Scalars['JSON']>;
  unallocatedSubcon?: Maybe<Scalars['JSON']>;
  suppProfileDate: Scalars['DateTime'];
  submittedDepositNotification: Scalars['Float'];
  supplierDepositsInfo?: Maybe<Scalars['JSON']>;
  submittedSupplierInvNotification: Scalars['Float'];
  submittedSupplierInvWoDoNotification: Scalars['Float'];
  supplierInvInfo?: Maybe<Scalars['JSON']>;
  supplierInvWoDoInfo?: Maybe<Scalars['JSON']>;
  submittedSupplierDNNotification: Scalars['Float'];
  supplierDNInfo?: Maybe<Scalars['JSON']>;
  submittedSupplierCNNotification: Scalars['Float'];
  supplierCNInfo?: Maybe<Scalars['JSON']>;
  submittedDNToSupplierNotification: Scalars['Float'];
  DNtoSupplierInfo?: Maybe<Scalars['JSON']>;
  submittedCNToSupplierNotification: Scalars['Float'];
  CNtoSupplierInfo?: Maybe<Scalars['JSON']>;
  submittedSupplierPaymentNotification: Scalars['Float'];
  supplierPaymentsInfo?: Maybe<Scalars['JSON']>;
  supplierBatchPaymentsInfo?: Maybe<Scalars['JSON']>;
  submittedSupplierRefundNotification: Scalars['Float'];
  supplierRefundsInfo?: Maybe<Scalars['JSON']>;
  outstandingSupplier?: Maybe<Scalars['JSON']>;
  unallocatedSupplier?: Maybe<Scalars['JSON']>;
  lastStockMovementDate?: Maybe<Scalars['DateTime']>;
  totalSiteCount: Scalars['Float'];
  totalStockIn: Scalars['Float'];
  totalStockOut: Scalars['Float'];
  totalDefect: Scalars['Float'];
  totalSiteSurveillance: Scalars['Float'];
  todayTotalAttendance: Scalars['JSON'];
  workerCount?: Maybe<Scalars['Float']>;
  lastGRNDate?: Maybe<Scalars['DateTime']>;
  stockMovementCount: Scalars['JSON'];
  lastStockMovementDates?: Maybe<Scalars['JSON']>;
  siteImagesCount: Scalars['JSON'];
  videoCamCount: Scalars['Float'];
  workerAttendanceCount?: Maybe<Scalars['JSON']>;
  getDailyRegisteredWorkHours?: Maybe<Scalars['JSON']>;
  getDailyWagesVerification?: Maybe<Scalars['JSON']>;
  getWagesBook?: Maybe<Scalars['JSON']>;
  wageCycleClosureInfo?: Maybe<Scalars['JSON']>;
  getTax: TaxEntity;
  getTaxListing: Array<TaxListingOutput>;
  getTaxSchemeTypeListing: Array<TaxSchemeTypeListingOutput>;
  getTaxSchemeTypeSortBy: Array<TaxSchemeTypeSortByOutput>;
  getTaxScheme: Array<TaxSchemeEntity>;
  getTaxSchemeWithPerm: Array<TaxSchemeEntity>;
  getTaxSchemeDetail: Array<TaxSchemeDetailEntity>;
  getTaxSchemeDetailWithPerm: Array<TaxSchemeDetailEntity>;
  getTaxSchemeDetailItem: Array<TaxSchemeDetailItemEntity>;
  getTaxSchemeDetailItemWithPerm: Array<TaxSchemeDetailEntity>;
  getContractNotification: Array<ContractNotificationEntity>;
  getNotificationListing: Array<Scalars['JSON']>;
  getStandardParameter: Array<Scalars['JSON']>;
  testImagePath: Scalars['JSON'];
  getRole: Array<RoleEntity>;
  getRoleSetup: Array<RoleEntity>;
  getEntityRoleUserAssignment: Array<RoleUserEntAssignmentEntity>;
  getPolicyRole: Array<RoleEntity>;
  userRoleIDs: Array<UserRoleId>;
  getContractPermission: Scalars['JSON'];
  getSitePermission: Scalars['JSON'];
  getRolePermission: Scalars['JSON'];
  getUsersRoleContract: Scalars['JSON'];
  getRoleMatrix: Scalars['JSON'];
  getRoleMatrixByIds: Scalars['JSON'];
  getRoleByIds: Array<RoleEntity>;
  getAlert: Array<AlertEntity>;
  getAlertList: Array<AlertEntity>;
  getShowAlertSQL: Scalars['JSON'];
  getAlertAssignment: Array<AlertAssignmentEntity>;
  getApprovalPolicyGuideline: Array<ApprovalPolicyEntity>;
  getApprovalPolicyAssignmentGuideline: Array<ApprovalPolicyAssignmentEntity>;
  getApprovalPolicy?: Maybe<Array<ApprovalPolicyEntity>>;
  getApprovalPolicyAssignment?: Maybe<Array<ApprovalPolicyAssignmentEntity>>;
  getBankAccount: Array<BankAccountEntity>;
  getBankAccountWithPerm: Array<BankAccountEntity>;
  getCalendar: Array<CalendarEntity>;
  getCalendarPHPolicy: Array<CalendarPhPolicyEntity>;
  getCalendarWeekendPolicy: Array<CalendarWeekendPolicyEntity>;
  logoView?: Maybe<DocumentsEntity>;
  WatermarkView: DocumentsEntity;
  getCostCategory: Array<CostCategoryEntity>;
  getActivePNMCostCategory: Array<CostCategoryEntity>;
  getCostCategoryByClass: Scalars['JSON'];
  getMapCostCategory: Scalars['JSON'];
  getParentChildrenCostCategory: Scalars['JSON'];
  getCostCategoryTree: Scalars['JSON'];
  getSubcontractTradeCostCategory: Scalars['JSON'];
  getNonSelectedCostCategory: Scalars['JSON'];
  getCostItem: Array<CostItemEntity>;
  getCostItemCount: Scalars['Float'];
  getCostItemBySupplier: Array<CostItemEntity>;
  getItemByClass: Array<CostItemEntity>;
  getCostItemByClasses: Array<CostItemEntity>;
  getCostItemPagination: Array<CostItemEntity>;
  getCostCategoryItem: Scalars['JSON'];
  getCostItemByWbs: Scalars['JSON'];
  getMapCostItem: Scalars['JSON'];
  getCostItemByWbsIDs: Scalars['JSON'];
  checkingBudgetQty: Scalars['JSON'];
  getCurrencySetup: Array<CurrencyNoteEntity>;
  getDigitalDocument: Scalars['JSON'];
  getTableDocuments: Array<DigitalDocuments>;
  totalDigitalDocuments: Scalars['JSON'];
  getNumberFormat: Array<ConNumberFormatEntity>;
  getConDocType: Array<ConDocTypeEntity>;
  getDocumentType: Array<ConDocTypeEntity>;
  getDocType: Array<Scalars['JSON']>;
  fetchNextDocNum: Scalars['String'];
  fetchMultipleDocNum: Scalars['JSON'];
  getRunningNumberSetupWithLock: Scalars['Float'];
  /** if true, runningNum is editable */
  runningNumChecking: Scalars['Boolean'];
  projMiscExpRunNumChecking: Scalars['Boolean'];
  getDocNumHeader: Array<DocNumHeaderEntity>;
  getDocNumDetail: Array<DocNumDetailEntity>;
  getSysAdminDocType: Array<Scalars['JSON']>;
  getDocumentNumberHeaderAndDetail: Array<DocNumDetailEntity>;
  getClassificationCode: Array<EInvoiceClassificationEntity>;
  getClassificationCodeCount: Scalars['Float'];
  getGeneralTin: Array<EInvoiceGeneralTinEntity>;
  getGeneralTinCount: Scalars['Float'];
  getMsicCode: Array<EInvoiceMsicEntity>;
  getMsicCodeCount: Scalars['Float'];
  getHolidayType: Array<HolidayTypeEntity>;
  getJobType: Array<JobTypeEntity>;
  loggedInPlatformUserProfile: PlatformUserEntity;
  activePlatformUser: Scalars['Float'];
  isPlatformUserValid: Scalars['Boolean'];
  getPolicyGuideLine: Array<PolicyGuidelineEntity>;
  getPolicyRoleAssignment: Array<PolicyRoleAssignmentEntity>;
  getNotificationGuideLine: Array<NotificationGuidelineEntity>;
  getNotiRoleUserAssignment: Array<NotiRoleUserAssignmentEntity>;
  getPublicHoliday: Array<PublicHolidayEntity>;
  getPublicHolidayByStateYear: Scalars['JSON'];
  getRevenueCategory: Array<RevenueCategoryEntity>;
  getRevenueCategoryCount: Scalars['Float'];
  getSAccount: Array<SAccountEntity>;
  getSiteMaterial: Array<SiteMaterialEntity>;
  getSiteWorkers: Array<WorkerEntity>;
  getSubcontractorType: Array<SubcontractorTypeEntity>;
  getSupplierType: Array<SupplierTypeEntity>;
  getUOM: Array<UomEntity>;
  getUOMWithPerm: Array<UomEntity>;
  getUOMCount: Scalars['Float'];
  getUOMWithPermCount: Scalars['Float'];
  getUOMExchange: Array<UomExchangeEntity>;
  getUOMExchangeWithPerm: Array<UomExchangeEntity>;
  getUomExchangeData: Scalars['JSON'];
  getUOMExchangeList: Scalars['JSON'];
  getUOMExchangeListByWbs: Scalars['JSON'];
  getUserCompanyAssignment: Array<UserCompanyAssignmentEntity>;
  submittedByLedger: Scalars['String'];
  loggedInUserProfile: UserEntity;
  activeUser: Scalars['Float'];
  getUsersByAccount: Array<UserEntity>;
  getUser: UserEntity;
  getUsersbyIds: Array<UserEntity>;
  isUserValid: Scalars['Boolean'];
  getUsersBySoftware: Array<UserEntity>;
  getUsersByAccountAndSoftware: Array<UserEntity>;
  getSuperUsers: Array<UserEntity>;
  getVideoCam: Array<VideoCamEntity>;
  getWagesRatePolicy: Array<WagesRatePolicyEntity>;
  getContractToDo: Array<ContractToDoEntity>;
  getToDoListing?: Maybe<Array<Scalars['JSON']>>;
  workDeskCount: Scalars['Float'];
  toDoCount: Scalars['Float'];
  notificationCount: Scalars['Float'];
  getWorker: Array<WorkerEntity>;
  getWorkerSiteAssignment: Array<WorkerSiteAssignmentEntity>;
  getWorkerFromSite: Array<WorkerEntity>;
  checkingWageType: Scalars['Boolean'];
  getWorkerAttendance: Array<WorkerAttendanceEntity>;
  checkWorkFlow: Scalars['JSON'];
  getToDoWorkflowSummary: Scalars['JSON'];
  getToDoAllPendingDocs: Scalars['JSON'];
  policyChecking?: Maybe<Scalars['JSON']>;
  policyCheckerAPPayment?: Maybe<Scalars['JSON']>;
  getNotificationWorkflowListing: Scalars['JSON'];
  getApprovalLog?: Maybe<Array<Scalars['JSON']>>;
  getAllApprovalLog?: Maybe<Array<Scalars['JSON']>>;
  approvableLedgerWFChecking: Scalars['JSON'];
  getWorkingHourPolicy: Array<WorkingHourPolicyEntity>;
  getHORAssignment: Array<AssignmentEntity>;
  getAssignmentCount: Scalars['Float'];
  getAssignment: Array<AssignmentEntity>;
  getFuelDischarge: Array<FuelDischargeEntity>;
  getFuelDischargeCount: Scalars['Float'];
  getFuelBalance: Scalars['Float'];
  getFuelMeterReading: Array<FuelMeterReadingEntity>;
  getFuelMeterReadingCount: Scalars['Float'];
  fuelMeterReadingSummary: Scalars['JSON'];
  getFuelSupply: Array<FuelSupplyEntity>;
  getFuelSupplyCount: Scalars['Float'];
  getFuelTankSetup: Array<TankEntity>;
  getFuelTankSetupCount: Scalars['Float'];
  getHORMaintenance: Array<MaintenanceEntity>;
  getMaintenanceCount: Scalars['Float'];
  getMaintenance: Array<MaintenanceEntity>;
  getPlantMachinery: Array<PlantMachineryEntity>;
  getWorkshop: Array<WorkshopEntity>;
  getWorkshopForm: Array<WorkshopEntity>;
  getPNMRental: Array<PnmRentalEntity>;
  getPNMRentalCount: Scalars['Float'];
  getPNMByStatus: Array<PlantMachineryEntity>;
  getPNMStatus: Scalars['Boolean'];
  getTank: Array<PlantMachineryEntity>;
  getRentalTank: Array<PnmRentalEntity>;
  getSupplyBy: Scalars['JSON'];
  getSupplyDischargeTo: Scalars['JSON'];
  getContractFuel: Scalars['JSON'];
  getDO: Array<DoEntity>;
  getDOItem: Array<DoItemEntity>;
  getDOCount: Scalars['Float'];
  getPOs: Array<PurchaseOrderEntity>;
  getGRTNs: Array<GoodReturnNoteEntity>;
  totalDOAmt: Scalars['Float'];
  getSupplierByDOStatus: Array<SupplierEntity>;
  checkingCancelCloseDO: Scalars['Boolean'];
  getGRTNBySupplier: Array<GoodReturnNoteEntity>;
  getGoodReturnNote: Array<GoodReturnNoteEntity>;
  getGoodReturnNoteItem: Array<GoodReturnNoteItemEntity>;
  getGoodReturnNoteCount: Scalars['Float'];
  getNegotiatedSupply: Array<NegotiatedSupplyEntity>;
  getNegotiatedSupplyItem: Array<NegotiatedSupplyItemEntity>;
  getNegotiatedSupplyCount: Scalars['Float'];
  verifyNegoSupplyStatus: Scalars['Boolean'];
  verifyNegoSupplyItem: Scalars['JSON'];
  getPurchaseOrder: Array<PurchaseOrderEntity>;
  getPOItem: Array<PoItemEntity>;
  getPurchaseOrderCount: Scalars['Float'];
  getPOByStatus: Array<PurchaseOrderEntity>;
  purchaseBudget: Scalars['JSON'];
  purchaseBudgetAllocation: Scalars['JSON'];
  purchaseWbsBudgetAllocation: Scalars['JSON'];
  getSupplierQuotes: Scalars['JSON'];
  closePOChecking: Scalars['Boolean'];
  getPOBySupplier: Array<PurchaseOrderEntity>;
  getSiteBySupplier: Array<SiteEntity>;
  getSupplierByPOStatus: Array<SupplierEntity>;
  getPOContractName: Scalars['JSON'];
  getPOList: Scalars['JSON'];
  getPONo: Scalars['JSON'];
  getPOCategory: Scalars['JSON'];
  getPOItemByCategory: Scalars['JSON'];
  checkingCancelClosePO: Array<Scalars['String']>;
  getPOCostItemList: Scalars['JSON'];
  getPOSupplierList: Scalars['JSON'];
  getPurchaseReq: Array<PurchaseReqEntity>;
  getPurchaseReqCount: Scalars['Float'];
  getPRForBudgetByOne: PurchaseReqEntity;
  GetPRItemsbyIDs: Array<PrItemInfo>;
  getOutstandingPR: Array<PurchaseReqEntity>;
  getOutstandingPRItems: Array<PrItemEntity>;
  checkingCancelClosePR: Array<Scalars['String']>;
  getRFQ: Array<RfqEntity>;
  getRFQItem: Array<RfqItemEntity>;
  getRFQCount: Scalars['Float'];
  getSuggestedSupplier: Array<SupplierEntity>;
  getRFQSupplierInvited: Array<RfqEntity>;
  getRFQSupplierInvitation: Array<RfqSupplierInvitationEntity>;
  getInvitedSupplier?: Maybe<Scalars['JSON']>;
  getRFQSupplierSubmission: Array<RfqSupplierSubmissionEntity>;
  getPRItemByPRIDForRFQ: Array<Scalars['JSON']>;
  getPurchaseReqWithPR: Array<PurchaseReqEntity>;
  getQuotedRFQ: Array<RfqSupplierSubmissionEntity>;
  getSupplierCompanyAssignment: Array<SupplierCompanyAssignmentEntity>;
  getSupplierByCompany: Array<SupplierEntity>;
  getSupplier: Array<SupplierEntity>;
  getSupplierCCategory: Array<SupplierCCategoryEntity>;
  getMultiSupplier: Array<SupplierEntity>;
  getActiveSupplier: Scalars['JSON'];
  getSupplierCostCategory: Scalars['JSON'];
  getSupplierDepositAllocation: Scalars['JSON'];
  getSupplierPaymentAllocation: Scalars['JSON'];
  getDNtoSupplierAllocation: Scalars['JSON'];
  getCNtoSupplierAllocation: Scalars['JSON'];
  getSupplierCNAllocation: Scalars['JSON'];
  getSupplierDNAllocation: Scalars['JSON'];
  getSupplierInvoiceAllocation: Scalars['JSON'];
  getInvoice: Array<ApInvoiceEntity>;
  getAPInvoice: Array<ApInvoiceEntity>;
  getAPInvoiceWoDO: Array<ApInvoiceEntity>;
  getDOforInvoice?: Maybe<Array<DoEntity>>;
  getAPAdvance: Array<ApAdvanceEntity>;
  getAPAdvanceCount: Scalars['Float'];
  getAPPayment: Array<ApPaymentEntity>;
  getAPPaymentCount: Scalars['Float'];
  getAPRefund: Array<ApRefundEntity>;
  getAPRefundCount: Scalars['Float'];
  getSupplierPaymentByContract: Scalars['JSON'];
  getAPDebitNote: Array<ApDebitNoteEntity>;
  getAPDebitNoteCount: Scalars['Float'];
  getAPCreditNote: Array<ApCreditNoteEntity>;
  getAPCreditNoteCount: Scalars['Float'];
  getDNtoSupplier: Array<DNtoSupplierEntity>;
  getDNtoSupplierCount: Scalars['Float'];
  getCNtoSupplier: Array<CNtoSupplierEntity>;
  getCNtoSupplierCount: Scalars['Float'];
  getSupplierCreditDocEInvoice: Scalars['JSON'];
  getSubcontractorInvitation: Array<SubcontractorInvitationEntity>;
  getSuggestedSubcontractor: Array<SubcontractorEntity>;
  getInvitedSubcontractor?: Maybe<Scalars['JSON']>;
  getSubconRFQSubmission: Array<SubconRfqSubmissionEntity>;
  getSubcontractTender: Array<SubcontractTenderEntity>;
  getSubcontractGuarantee: Array<SubcontractGuaranteeEntity>;
  getSubcontractInsurance: Array<SubcontractInsuranceEntity>;
  getSubcontract: Array<SubcontractEntity>;
  getSubcontractRelated: Array<SubcontractEntity>;
  getSubcontractWithTrade: Array<SubcontractCostWbsEntity>;
  getSubcontractWithAccount: Scalars['JSON'];
  getSubcontractTitleWithAccount: Scalars['JSON'];
  subconBudgetChecking: BudgetInfo;
  subcontractVoCalculation: Scalars['JSON'];
  getSubcontractorCompanyAssignment: Array<SubcontractorCompanyAssignmentEntity>;
  getSubcontractorByCompany: Array<SubcontractorEntity>;
  getSubcontractVO: Array<SubcontractVoEntity>;
  getSubconVOTradeCostCategory: Scalars['JSON'];
  subcontractVOListing: Scalars['JSON'];
  getSubcontractCostWbs: Array<SubcontractCostWbsEntity>;
  getSubconAdvanceAllocation: Scalars['JSON'];
  getSubconPaymentAllocation: Scalars['JSON'];
  getSubconRefundAllocation: Scalars['JSON'];
  getSubconClaimAllocationLedgers: Scalars['JSON'];
  getSubcontractRetentionClaimAllocation: Scalars['JSON'];
  getSubconRetClaimAllocation: Scalars['JSON'];
  getSubconCNAllocation: Scalars['JSON'];
  getSubconDNAllocation: Scalars['JSON'];
  getDNtoSubconAllocation: Scalars['JSON'];
  getCNtoSubconAllocation: Scalars['JSON'];
  getSubcontractClaim: Array<SubcontractClaimEntity>;
  getSubcontractClaimCount: Scalars['Float'];
  getSubconRetentionClaim: Array<SubcontractClaimEntity>;
  getSubconProgressClaim: Array<SubcontractClaimEntity>;
  getSubcontractAdvance: Array<SubcontractAdvanceEntity>;
  getSubcontractAdvanceCount: Scalars['Float'];
  getSubcontractPayment: Array<SubcontractPaymentEntity>;
  getSubcontractPaymentCount: Scalars['Float'];
  getSubcontractRefund: Array<SubcontractRefundEntity>;
  getSubcontractRefundCount: Scalars['Float'];
  getSubcontractMiscInvoice: Array<SubcontractMiscInvoiceEntity>;
  getSubcontractMiscInvoiceCount: Scalars['Float'];
  getSubcontractDN: Array<SubcontractDnEntity>;
  getSubcontractDNCount: Scalars['Float'];
  getSubcontractCN: Array<SubcontractCnEntity>;
  getSubcontractCNCount: Scalars['Float'];
  getDNtoSubcon: Array<DNtoSubconEntity>;
  getDNtoSubconCount: Scalars['Float'];
  getCNtoSubcon: Array<CNtoSubconEntity>;
  getCNtoSubconCount: Scalars['Float'];
  getDNItemsByDNID: Scalars['JSON'];
  getDNtoSubconRevenue: Array<DNtoSubconEntity>;
  getSubconCreditDocEInvoice: Scalars['JSON'];
  getSubcontractor: Array<SubcontractorEntity>;
  getSubcontractorCCategory: Array<SubcontractorCCategoryEntity>;
  getSubcontractorCostCategory: Scalars['JSON'];
  getCostCategoryBySubcon: Scalars['JSON'];
  getSubcontractorAccessibleContract: Scalars['JSON'];
  getProjectSite: Array<ContractEntity>;
  getSite: Array<SiteEntity>;
  verifySite: Scalars['String'];
  getSiteWithAccount: Scalars['JSON'];
  getSiteNameWithAccount: Scalars['JSON'];
  getSiteImage: Array<SiteImageEntity>;
};


export type QueryNegoItemInfoArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryNegoItemCountArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryPrInfoCountArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastPrDateArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastPrDateObArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryRfqInfoArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryRfqCountArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastRfqDateArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryPoInfoCountArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastPoDateArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastPoDateObArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryDoCountArgs = {
  supplierID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastDoDateArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGrtnCountArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastGrtnDateArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetContractPlAdjustmentTotalArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractPlAdjustmentTotalArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplierPlAdjustmentTotalArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractPlAdjustmentStatusTotalArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetContractPlAdjustmentStatusTotalArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplierPlAdjustmentStatusTotalArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetAdjustmentByCostClassArgs = {
  contractID: Scalars['String'];
};


export type QueryBudgetingCalculationArgs = {
  contractID: Scalars['String'];
};


export type QueryCostingCalculationArgs = {
  contractID: Scalars['String'];
};


export type QueryGetAdvanceClientArgs = {
  contractID: Scalars['String'];
};


export type QueryGetPaidSubconSupplierAmountArgs = {
  contractID: Scalars['String'];
};


export type QueryOutstandingReceivableFromClientListingArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryOutstandingPayableSubconListingArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryOutstandingPayableSupplierListingArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryPaidSubconSupplierListingArgs = {
  contractID: Scalars['String'];
};


export type QueryAdvanceClientArgs = {
  contractID: Scalars['String'];
};


export type QueryReceivedFromClientArgs = {
  contractID: Scalars['String'];
};


export type QueryPaidToSubConArgs = {
  contractID: Scalars['String'];
};


export type QueryPaidToSupplierArgs = {
  contractID: Scalars['String'];
};


export type QueryAdvancesOutflowAmtArgs = {
  contractID: Scalars['String'];
};


export type QueryMiscExpenseArgs = {
  contractID: Scalars['String'];
};


export type QueryOsReceivableFromClientArgs = {
  contractID: Scalars['String'];
};


export type QueryClientLastReceiptArgs = {
  contractID: Scalars['String'];
};


export type QuerySubconLastPaymentArgs = {
  contractID: Scalars['String'];
};


export type QueryReceivableClientLastPaymentArgs = {
  contractID: Scalars['String'];
};


export type QueryOutstandingAmtClientArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryPaidSubconSupplierAmountArgs = {
  contractID: Scalars['String'];
};


export type QueryOsReceivableFromClientListingArgs = {
  contractID: Scalars['String'];
};


export type QuerySubconOsPayableAmountArgs = {
  contractID: Scalars['String'];
};


export type QueryOsPayableToSubconListingArgs = {
  contractID: Scalars['String'];
};


export type QuerySupplierLastPaymentArgs = {
  contractID: Scalars['String'];
};


export type QuerySupplierOsPayableAmountArgs = {
  contractID: Scalars['String'];
};


export type QueryOsPayableToSupplierListingArgs = {
  contractID: Scalars['String'];
};


export type QueryOutstandingReceivableFromClientArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryOutstandingPayableSubconArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryOutstandingPayableSupplierArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryAssociateCalculationArgs = {
  contractID: Scalars['String'];
};


export type QueryGetProjectCostingDetailArgs = {
  costClass: CostClass;
  contractID: Scalars['String'];
};


export type QueryGetCostingCalculationArgs = {
  contractID: Scalars['String'];
};


export type QueryCostCategCountArgs = {
  contractID: Scalars['String'];
};


export type QueryAdjustmentByCostClassArgs = {
  contractID: Scalars['String'];
};


export type QueryAdjustmentItemByCostCategArgs = {
  costCategoryID: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryApInvoiceWoDoByCostItemArgs = {
  costCategoryID: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryGetProjectCostingAmtArgs = {
  contractID: Scalars['String'];
};


export type QueryGetAdjustmentItemByCostCategArgs = {
  costCategoryID: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryGetCostCategCountArgs = {
  contractID: Scalars['String'];
};


export type QueryGetPOsByCategoryArgs = {
  status?: Maybe<PurchaseStatus>;
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
};


export type QueryGetGrtNsByCategoryDoArgs = {
  doStatus?: Maybe<DoStatus>;
  purchaseOrderID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
};


export type QueryGetDOsByCategoryArgs = {
  doStatus?: Maybe<DoStatus>;
  purchaseOrderID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
};


export type QueryGetApInvoiceWoDoByCostItemArgs = {
  costCategoryID: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryGetPcYearArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetReportYearArgs = {
  endYear?: Maybe<Scalars['Float']>;
  startYear?: Maybe<Scalars['Float']>;
};


export type QueryGetCommCostAmtArgs = {
  subcontract?: Maybe<Scalars['Boolean']>;
  costCategoryID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryFinancialStatusArgs = {
  contractID: Scalars['String'];
};


export type QueryGetWbsParentChildrensArgs = {
  wbsID?: Maybe<Array<Scalars['String']>>;
  contractID?: Maybe<Array<Scalars['String']>>;
  companyID: Scalars['String'];
};


export type QueryProjectLedgerWbsRevenueSummaryArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  trxEndDate?: Maybe<Scalars['String']>;
  trxStartDate?: Maybe<Scalars['String']>;
  costItem?: Maybe<Array<Scalars['String']>>;
  revenueID?: Maybe<Array<Scalars['String']>>;
  wbsID?: Maybe<Array<Scalars['String']>>;
  contractID?: Maybe<Array<Scalars['String']>>;
  companyID: Scalars['String'];
};


export type QueryProjectLedgerWbsRetentionSummaryArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  trxEndDate?: Maybe<Scalars['String']>;
  trxStartDate?: Maybe<Scalars['String']>;
  revenueID?: Maybe<Array<Scalars['String']>>;
  wbsID?: Maybe<Array<Scalars['String']>>;
  contractID?: Maybe<Array<Scalars['String']>>;
  companyID: Scalars['String'];
};


export type QueryProjectLedgerWbsCostSummaryArgs = {
  costClass?: Maybe<Array<Scalars['String']>>;
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  trxEndDate?: Maybe<Scalars['String']>;
  trxStartDate?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Array<Scalars['String']>>;
  costCategoryID?: Maybe<Array<Scalars['String']>>;
  wbsID?: Maybe<Array<Scalars['String']>>;
  contractID?: Maybe<Array<Scalars['String']>>;
  companyID: Scalars['String'];
};


export type QueryGetCostCategoryReportArgs = {
  costClass?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetCostItemReportArgs = {
  costCategoryID?: Maybe<Array<Scalars['String']>>;
};


export type QueryProjectPlArgs = {
  contractID: Scalars['String'];
};


export type QueryGetBqArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  isLastNode?: Maybe<Scalars['Boolean']>;
};


export type QueryGetCheckingValidationBqArgs = {
  BQExcelInput: Array<BqExcelInput>;
  contractID: Scalars['String'];
};


export type QueryGetBqWbsParentChildArgs = {
  wbsID: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryGetBqParentChildArgs = {
  contractID: Scalars['String'];
};


export type QueryGetBqWbsAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetContractCalendarInfoArgs = {
  currDate?: Maybe<Scalars['DateTime']>;
};


export type QueryGetSubconCalendarInfoArgs = {
  currDate?: Maybe<Scalars['DateTime']>;
};


export type QueryGetPpCalendarInfoArgs = {
  currDate?: Maybe<Scalars['DateTime']>;
};


export type QueryGetBillInterestArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  customerID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  docID?: Maybe<Scalars['String']>;
};


export type QueryGetStatementAcctMonthsArgs = {
  ID: Scalars['String'];
};


export type QueryGetClaimRecListByMonthArgs = {
  ID: Scalars['String'];
  month: Scalars['DateTime'];
};


export type QueryGetCustContractIDsArgs = {
  customerID?: Maybe<Scalars['String']>;
};


export type QueryGetClientDebitDocListingArgs = {
  endPeriod: Scalars['Float'];
  startPeriod: Scalars['Float'];
  customerID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetClientDebitDocEInvoiceArgs = {
  customerID?: Maybe<Scalars['String']>;
  isEinvoice?: Maybe<Scalars['Boolean']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetPaymentReminderArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetDocReminderArgs = {
  customerID?: Maybe<Scalars['String']>;
  paymentReminderID: Scalars['String'];
};


export type QueryGetPaymentReminderClaimArgs = {
  paymentReminderID: Scalars['String'];
};


export type QueryGetPaymentReminderByCompanyArgs = {
  companyID?: Maybe<Scalars['String']>;
  paymentReminderID?: Maybe<Scalars['String']>;
};


export type QueryGetDebitDocArgs = {
  date: Scalars['DateTime'];
  companyID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetPaymentReminderConCustArgs = {
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetDocReminderDetailsArgs = {
  companyID?: Maybe<Scalars['String']>;
  paymentReminderID: Scalars['String'];
};


export type QueryGetPaymentReminderLastDateArgs = {
  customerID?: Maybe<Scalars['String']>;
};


export type QueryGetPrintReminderArgs = {
  customerID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  paymentReminderID: Scalars['String'];
};


export type QueryGetCustomerReminderArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetContractArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['String']>;
  contractSum?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
};


export type QueryGetCustomContractArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['String']>;
  contractSum?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
};


export type QueryContractVoCalculationArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryMaxRetentionArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryClaimCheckingArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryVoCheckingArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryRetentionCheckingArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryValidateContractStatusArgs = {
  contractID: Scalars['String'];
};


export type QueryGetContractWithoutPermArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['String']>;
  contractSum?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
};


export type QueryGetContractEntityWithAccountArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['String']>;
  contractSum?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetContractWithAccountArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['String']>;
  contractSum?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetContractTitleWithAccountArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['String']>;
  contractSum?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetContractWithActiveDoArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['String']>;
  contractSum?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryCheckWbsExistArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetContractGuaranteeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetContractInsuranceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetProjectBudgetArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryDeleteBudgetCheckingArgs = {
  contractID: Scalars['String'];
  costCategoryIDs: Array<Scalars['String']>;
};


export type QueryBudgetCalculationArgs = {
  contractID: Scalars['String'];
};


export type QueryGetContractVoArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  VOStatus?: Maybe<VoStatus>;
};


export type QueryGetVoHealthIndexArgs = {
  contractID: Scalars['String'];
};


export type QueryGetContractAllocationArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  debitID?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  debitRefTable?: Maybe<Scalars['String']>;
};


export type QueryGetContractAdvanceAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetContractReceiptAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetContractRefundAllocationArgs = {
  receiptStatus?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
};


export type QueryGetClaimAllocationLedgersArgs = {
  contractID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
};


export type QueryGetContractRetentionClaimAllocationArgs = {
  contractID?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
};


export type QueryGetContractRetClaimAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetContractCnAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetContractDnAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetContractMiscInvoiceAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetClientCnAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetClientDnAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetContractClaimArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetContractClaimCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  isRetention?: Maybe<Scalars['Boolean']>;
};


export type QueryGetRetentionClaimArgs = {
  searchValue?: Maybe<Scalars['String']>;
  offSet?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  customerID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryFetchUnallocatedClaimRetentionArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetProgressClaimArgs = {
  searchValue?: Maybe<Scalars['String']>;
  offSet?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  customerID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetContractAdvanceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetContractAdvanceCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetContractReceiptArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetContractReceiptCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetContractRefundArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetContractRefundCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetContractMiscInvoiceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetContractMiscInvoiceCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetContractDnArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetContractDnCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetContractCnArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetContractCnCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetClientDnArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetClientDnCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetClientCnArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetClientCnCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetCnRevenueArgs = {
  transactionStatus?: Maybe<TransactionStatus>;
  contractID?: Maybe<Scalars['String']>;
  withRev?: Maybe<Scalars['Boolean']>;
};


export type QueryGetCostCodeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  parentCostCodeID?: Maybe<Scalars['String']>;
  isLastNode?: Maybe<Scalars['Boolean']>;
};


export type QueryGetCustomerCompanyAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryGetCustomerByCompanyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  associatedStatus?: Maybe<AssociatedStatus>;
  withoutCompany?: Maybe<Scalars['Boolean']>;
};


export type QueryGetCustomerArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  taxTypeID?: Maybe<Scalars['String']>;
  associatedStatus?: Maybe<AssociatedStatus>;
};


export type QueryGetClientAccountsArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  taxTypeID?: Maybe<Scalars['String']>;
  associatedStatus?: Maybe<AssociatedStatus>;
};


export type QueryGetFollowUpArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryGetCbExportArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
};


export type QueryGetContractCbArgs = {
  companyID?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};


export type QueryGetContractCbInfoArgs = {
  cbExportID: Scalars['String'];
};


export type QueryGetGlExportArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
};


export type QueryGlMonthsArgs = {
  contractID: Scalars['String'];
};


export type QueryGetContractGlArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  glExportID?: Maybe<Scalars['String']>;
};


export type QueryGetSubconGlArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  glExportID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplierGlArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  glExportID?: Maybe<Scalars['String']>;
};


export type QueryGetPurchaseGlArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  glExportID?: Maybe<Scalars['String']>;
};


export type QueryGetPobglArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  glExportID?: Maybe<Scalars['String']>;
};


export type QueryGetGlClaimDetailLedgerArgs = {
  joinTable?: Maybe<Scalars['String']>;
  claimColumn?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
  IDs: Array<Scalars['String']>;
};


export type QueryGetGlDetailLedgerArgs = {
  joinTable?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
  IDs: Array<Scalars['String']>;
};


export type QueryGetGrtnDetailLedgerArgs = {
  IDs: Array<Scalars['String']>;
};


export type QueryGetDoDetailLedgerArgs = {
  IDs: Array<Scalars['String']>;
};


export type QueryGetBatchGlDetailLedgerArgs = {
  batchInput: Array<GlDetailLedgerBatchInput>;
};


export type QueryCheckedGlEditableArgs = {
  type: Scalars['String'];
};


export type QueryGetGlSetupFileArgs = {
  accountType: Scalars['String'];
};


export type QueryGetGlAssociationFileArgs = {
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetGlTransactionFileArgs = {
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetGlInterfaceMasterArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  associationType?: Maybe<AssociationType>;
  transactionType?: Maybe<TransactionType>;
};


export type QueryGetGlInterfaceDetailArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  glInterfaceMasterID?: Maybe<Scalars['String']>;
};


export type QueryGetGlTaxDynamicArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  refID?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
};


export type QueryGetGlAdjustmentDynamicArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  refID?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
};


export type QueryGetGroupBiAccountReceivableDialogArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetGroupBiKeyApiSummaryArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetGroupBiKeyApiDialogSummaryArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetGroupBiKeyKpiClaimArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetGroupBiKeyKpiCollectionArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetGroupBiSiteGalleryArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetGuaranteeTypeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetInsuranceTypeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetMiscExpenseArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  status?: Maybe<MiscExpenseStatus>;
};


export type QueryGetMiscExpenseCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  status?: Maybe<MiscExpenseStatus>;
};


export type QueryMiscExpenseBudgetCheckingArgs = {
  amount: Scalars['Float'];
  contractID: Scalars['String'];
  costCategoryID: Scalars['String'];
};


export type QueryGetCostCenterArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  status?: Maybe<CostCenterType>;
};


export type QueryGetCurrencyExchangeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  fromCurrencyID?: Maybe<Scalars['String']>;
  toCurrencyID?: Maybe<Scalars['String']>;
};


export type QueryGetCurrencyArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  format?: Maybe<NumericFormatter>;
};


export type QueryGetConTaxSchemeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  taxTypeID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  taxClass?: Maybe<TaxClass>;
};


export type QueryGetConTaxSchemeWithPermArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  taxTypeID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  taxClass?: Maybe<TaxClass>;
};


export type QueryGetConTaxEffectiveDateArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  claimable?: Maybe<Scalars['Boolean']>;
};


export type QueryGetConTaxTypeArgs = {
  commonStatus?: Maybe<CommonStatus>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryVerifyTaxTypeArgs = {
  taxSchemeID: Scalars['String'];
};


export type QueryGetTaxCodeGlExportArgs = {
  glExportID?: Maybe<Scalars['String']>;
};


export type QueryGetFilterTaxCodeArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetProfitRecogFormulaArgs = {
  commonStatus?: Maybe<CommonStatus>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetProfitRecogParameterArgs = {
  commonStatus?: Maybe<CommonStatus>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetProfitRecogAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
};


export type QueryGetProfitRecogGenerationArgs = {
  contractID: Scalars['String'];
};


export type QueryGetProfitRecogGenParamAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
};


export type QueryGetContractListingProfitRecogArgs = {
  companyID: Scalars['String'];
};


export type QueryProfitRecognitionCalculationArgs = {
  inputDate: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryC1_ActualCostArgs = {
  inputDate: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryC2_BudgetCostArgs = {
  inputDate?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
};


export type QueryC3_ActualRevenueArgs = {
  inputDate: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryC4_ReviseContractSumArgs = {
  inputDate: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryC5_TotalPreviousRecognisedRevenueArgs = {
  inputDate: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryC6_TotalPreviousRecognisedCostArgs = {
  inputDate: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryC7_PercOfCompletionArgs = {
  inputDate: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryGetProfitRecognitionArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryInfoLineProfitRecogArgs = {
  contractID: Scalars['String'];
};


export type QueryGetProfitRecogGenerationListingArgs = {
  contractID: Scalars['String'];
};


export type QueryGetProfitRecogGlAccountCodeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
};


export type QueryGetProfitRecogGlJournalArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
};


export type QueryGetProjectExpenseArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};


export type QueryGetProjectExpenseItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  projectExpenseID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
};


export type QueryGetProjectExpenseCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};


export type QueryGetProjectOpportunityAttachmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  projectOpportunityID?: Maybe<Scalars['String']>;
};


export type QueryGetProjectOpportunityArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  ProjectOpportunityStatus?: Maybe<ProjectOpportunityStatus>;
};


export type QueryGetProjectOpportunitySubmissionArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  projectOpportunityID?: Maybe<Scalars['String']>;
};


export type QueryGetCheckingValidationWbsBudgetArgs = {
  WBSAndBudgetExcelInput: Array<WbsAndBudgetExcelInput>;
  contractID: Scalars['String'];
};


export type QueryGetWbsBudgetArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Float']>;
};


export type QueryGetBudgetArgs = {
  version?: Maybe<Scalars['String']>;
  budgetStatus?: Maybe<BudgetStatus>;
  contractID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryCheckWbsBudgetApprovedArgs = {
  contractID: Scalars['String'];
};


export type QueryGetWbsBudgetDetailArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  revenueCategoryID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetWbsBudgetDetailSchedulingArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  revenueCategoryID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetWbsDayworkCostRevenueArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  revenueCategoryID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  costItemID?: Maybe<Scalars['String']>;
};


export type QueryGetTotalCostRevenueArgs = {
  wbsID: Scalars['String'];
  budgetID: Scalars['String'];
};


export type QueryGetCostWbsBudgetDetailArgs = {
  wbsID: Scalars['String'];
  budgetID: Scalars['String'];
};


export type QueryGetRevenueWbsBudgetDetailArgs = {
  budgetID: Scalars['String'];
  wbsID: Scalars['String'];
};


export type QueryGetWbsBudgetDetailByClassArgs = {
  originalBudget?: Maybe<Scalars['Boolean']>;
  costClass: Scalars['String'];
  budgetID: Scalars['String'];
  wbsID: Scalars['String'];
};


export type QueryCheckWbsBudgetDetailArgs = {
  costID?: Maybe<Scalars['String']>;
  wbsID: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryCheckMultipleWbsBudgetDetailArgs = {
  input: Array<CheckMultipleWbsBudgetDetailInput>;
  docRefID?: Maybe<Scalars['String']>;
  itemChecker: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryCheckSubcontractMultipleWbsBudgetDetailArgs = {
  input: Array<CheckMultipleWbsBudgetDetailInput>;
  subcontractID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
};


export type QueryCheckExistCostRevenueArgs = {
  wbsID: Scalars['String'];
  budgetID: Scalars['String'];
};


export type QueryGetCostCategoryWbsBudgetArgs = {
  isCategory?: Maybe<Scalars['Boolean']>;
  contractID: Scalars['String'];
  wbsID: Scalars['String'];
};


export type QueryGetWbsBudgetTransferHeaderArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetWbsBudgetTransferDetailArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  wbsBudgetTransferHeaderID?: Maybe<Scalars['String']>;
};


export type QueryGetWbsParentChildArgs = {
  isDefault?: Maybe<Scalars['Boolean']>;
  selectedID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
};


export type QueryWbsBudgetTransferResourceArgs = {
  wbsID: Scalars['String'];
};


export type QueryGetWbsBudgetTransferArgs = {
  wbsBudgetTransferID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
};


export type QueryGetBudgetTransferCostCategoryBalanceArgs = {
  itemInput: Array<WbsBudgetTransferCostCategoryBalanceFilterArgs>;
  contractID: Scalars['String'];
};


export type QueryGetWbsArgs = {
  childrenOnly?: Maybe<Scalars['Boolean']>;
  parentOnly?: Maybe<Scalars['Boolean']>;
  isLastNode?: Maybe<Scalars['Boolean']>;
  contractID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetWbsTotalCostRevenueArgs = {
  budgetID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
};


export type QueryGetWbsCostAllocationByClassArgs = {
  wbsID: Scalars['String'];
  budgetID: Scalars['String'];
};


export type QueryGetMultipleWbsArgs = {
  contractIDs?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetWbsScheduleArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['Float']>;
};


export type QueryGetWbsUtilizedBudgetArgs = {
  contractID: Scalars['String'];
};


export type QueryGetCostCategoryFromWbsArgs = {
  itemOnly?: Maybe<Scalars['Boolean']>;
  selectedID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
  wbsID: Scalars['String'];
};


export type QueryGetDrawerPermissionArgs = {
  pageMode?: Maybe<Scalars['String']>;
};


export type QueryGetSiteDrawerPermissionArgs = {
  pageMode?: Maybe<Scalars['String']>;
};


export type QueryCheckArgs = {
  permIds: Array<Scalars['Float']>;
  userID: Scalars['String'];
};


export type QueryUserEntitiesArgs = {
  userID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
};


export type QueryTestPermissionArgs = {
  conPerm: ContractPermission;
};


export type QuerySiteTestPermissionArgs = {
  sitePerm: SitePermission;
};


export type QueryTestConPermissionArgs = {
  contPerm: ContPermission;
};


export type QueryTestSampleEnumArgs = {
  SampleEnum: SampleEnum;
};


export type QueryCheckDocExistArgs = {
  refID?: Maybe<Scalars['String']>;
};


export type QueryDocumentListingArgs = {
  hasDescription?: Maybe<Scalars['Boolean']>;
  notDescription?: Maybe<Scalars['String']>;
  documentFolderID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
};


export type QueryCmgdAttachmentArgs = {
  refID?: Maybe<Scalars['String']>;
};


export type QueryTotalDigitalDocArgs = {
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  documentFolderID?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
};


export type QueryAuditLogDetailsArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  fieldID: Scalars['String'];
  tableName: Scalars['String'];
};


export type QueryGetCashFlowLineChartDataArgs = {
  contractID: Scalars['String'];
};


export type QueryGetCashFlowGraphDataArgs = {
  contractID: Scalars['String'];
};


export type QueryGetContractNewsArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  postStatus?: Maybe<PostStatus>;
};


export type QueryGetContractNewsReadArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  newsID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
};


export type QueryGetCollectionActivityArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryGetCompanyHorArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  ParentCompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<Scalars['String']>;
};


export type QueryGetCompanyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  ParentCompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetRawCompanyArgs = {
  limit?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetSubscriptionCurrencyArgs = {
  limit?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetContactArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
};


export type QueryGetAdjustmentTypeArgs = {
  commonStatus?: Maybe<CommonStatus>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetDocumentFolderArgs = {
  siteID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryTotalDocFolderArgs = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetLocalBankArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  countryID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<Scalars['String']>;
};


export type QueryGetProfitRecogFormulaScriptArgs = {
  name: Scalars['String'];
};


export type QuerySubmittedRetClaimCheckingArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedClaimCheckingArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryApprovedClaimCheckingArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryCancellableClaimsArgs = {
  identifier: Scalars['String'];
};


export type QueryApprovableLedgerCheckingArgs = {
  docAmt?: Maybe<Scalars['Float']>;
  docRefTable: Scalars['String'];
  docRefID?: Maybe<Scalars['String']>;
};


export type QueryGetBatchPaymentDetailArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetBatchPaymentDetailbySupplierArgs = {
  companyID?: Maybe<Scalars['String']>;
  batchPaymentHeaderID?: Maybe<Scalars['String']>;
};


export type QueryGetBatchPaymentHeaderArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetBatchPaymentbyStatusArgs = {
  searchValue?: Maybe<Scalars['String']>;
  offSet?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  StatusArr?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  batchPaymentID?: Maybe<Scalars['String']>;
};


export type QueryGetBatchPaymentbyStatusCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  StatusArr?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetContractLedgersArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractorLedgersArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplierLedgersArgs = {
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteMaterialPolicyArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteMaterialContractPolicyArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
};


export type QueryGetDefaultMaterialPolicyIDsArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteMaterialPolicyCheckerArgs = {
  category: Scalars['String'];
  costItemID?: Maybe<Scalars['String']>;
  siteID: Scalars['String'];
};


export type QueryAssociateMenuArgs = {
  companyID?: Maybe<Scalars['String']>;
  associate: Scalars['String'];
};


export type QueryPendingCollectionActivityArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryPaymentReminderSummaryArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryTotalDebitClientArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryTotalCreditClientArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryTotalOutstandingClientsArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryTotalOsClientInvoicesArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryTotalOutstandingAmtClientsArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QuerySupplierProfileDateArgs = {
  ID: Scalars['String'];
};


export type QueryPrefSupplierProductDateArgs = {
  supplierID: Scalars['String'];
};


export type QuerySupplierAccDateArgs = {
  supplierID: Scalars['String'];
};


export type QueryContractInfoArgs = {
  contractID: Scalars['String'];
};


export type QueryContractGuaranteeInfoArgs = {
  contractID: Scalars['String'];
};


export type QueryContractInsuranceInfoArgs = {
  contractID: Scalars['String'];
};


export type QuerySubmittedVoNotificationArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryContractVoArgs = {
  contractID: Scalars['String'];
};


export type QueryOutstandingAdvancesArgs = {
  contractID: Scalars['String'];
};


export type QueryOutstandingClaimsArgs = {
  contractID: Scalars['String'];
};


export type QueryOutstandingMiscInvsArgs = {
  contractID: Scalars['String'];
};


export type QueryRetentionArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastSiteProgressDateArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryWbsBudgetInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryWbsBudgetRevisionInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryUtilizedBudgetInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryBudgetTransferInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastBqImportDateArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryBqAssignmentInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryMiscExpInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastMiscExpDateArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastExtensionDateArgs = {
  contractID: Scalars['String'];
};


export type QueryContractClosureDateArgs = {
  contractID: Scalars['String'];
};


export type QuerySubcontractInfoArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubconGuaranteeInfoArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubconInsuranceInfoArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubmittedVoSubconNotificationArgs = {
  subcontractID?: Maybe<Scalars['String']>;
};


export type QuerySubcontractVoArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubconOutstandingAdvancesArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubconOutstandingClaimsArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubconLastExtensionDateArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubconClosureDateArgs = {
  subcontractID: Scalars['String'];
};


export type QueryLatestDefectDateArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryLatestAssignmentDateArgs = {
  defectID?: Maybe<Scalars['String']>;
};


export type QuerySiteWorkerAttendanceInfoArgs = {
  siteID: Scalars['String'];
};


export type QueryMosCountArgs = {
  siteID: Scalars['String'];
};


export type QueryApprovalPolicyDateArgs = {
  parameter: Scalars['String'];
};


export type QueryApprovalPolicyWithContractDateArgs = {
  contractID: Scalars['String'];
  parameter: Scalars['String'];
};


export type QueryConAuthCountArgs = {
  contractID: Scalars['String'];
};


export type QueryDocNumDateArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryBankAccountDateArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryActiveClientCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryClientCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryContractCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryActiveContractCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryActiveSiteCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QuerySubcontractCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QuerySubcontractorCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryActiveSubcontractorCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QuerySupplierCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryActiveSupplierCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryActivePlantMachineryCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryProjectPurchaseCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryFuelInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryOwnedPnmInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryRentedPnmInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryAssignmentRecordArgs = {
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryMaintenanceRecordArgs = {
  workshopID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryFuelTankCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryClientProfileDateArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedCusAvNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryAdvancesInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryClaimsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedClaimNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryProgressClaimsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedRetClaimNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryRetentionClaimsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedCnNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryCnInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedDnNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryDnInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedMiscInvNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryMiscInvInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedClientDnNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryClientDnInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedClientCnNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryClientCnInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedReceiptNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryReceiptsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedRefundNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryRefundsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryOutstandingClientArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryUnallocatedClientArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryConClientProfileDateArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConAdvancesInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConClaimsInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConDnInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConCnInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConReceiptsInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConRefundsInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConClientDnInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConOutstandingClientArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QuerySubconProfileDateArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconLedgersInfoArgs = {
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconAvNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconAdvancesInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconClaimsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconClaimNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconProgressClaimsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconRetClaimNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconRetentionClaimsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconMiscInvNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconMiscInvInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconDnNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconDnInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconCnNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconCnInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedPaymentNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconPaymentInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconRefundNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconRefundsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedDNtoSubconNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QueryDNtoSubconInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedCNtoSubconNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QueryCNtoSubconInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QueryOutstandingSubconArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QueryUnallocatedSubconArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySuppProfileDateArgs = {
  supplierID: Scalars['String'];
};


export type QuerySubmittedDepositNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierDepositsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSupplierInvNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSupplierInvWoDoNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierInvInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierInvWoDoInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSupplierDnNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierDnInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSupplierCnNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierCnInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedDnToSupplierNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryDNtoSupplierInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedCnToSupplierNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryCNtoSupplierInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSupplierPaymentNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierPaymentsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierBatchPaymentsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSupplierRefundNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierRefundsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryOutstandingSupplierArgs = {
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryUnallocatedSupplierArgs = {
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryTotalStockInArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryTotalStockOutArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryTotalDefectArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryTotalSiteSurveillanceArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryTodayTotalAttendanceArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryWorkerCountArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryLastGrnDateArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryStockMovementCountArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryLastStockMovementDatesArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QuerySiteImagesCountArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryVideoCamCountArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryWorkerAttendanceCountArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetDailyRegisteredWorkHoursArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetDailyWagesVerificationArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetWagesBookArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryWageCycleClosureInfoArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetTaxArgs = {
  taxID: Scalars['String'];
};


export type QueryGetTaxSchemeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  isExclusive?: Maybe<Scalars['Boolean']>;
};


export type QueryGetTaxSchemeWithPermArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  isExclusive?: Maybe<Scalars['Boolean']>;
};


export type QueryGetTaxSchemeDetailArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  effectiveDate?: Maybe<Scalars['DateTime']>;
};


export type QueryGetTaxSchemeDetailWithPermArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  effectiveDate?: Maybe<Scalars['DateTime']>;
};


export type QueryGetTaxSchemeDetailItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  taxSchemeDetailID?: Maybe<Scalars['String']>;
  taxID?: Maybe<Scalars['String']>;
  seqNo?: Maybe<Scalars['Float']>;
};


export type QueryGetTaxSchemeDetailItemWithPermArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  taxSchemeDetailID?: Maybe<Scalars['String']>;
  taxID?: Maybe<Scalars['String']>;
  seqNo?: Maybe<Scalars['Float']>;
};


export type QueryGetContractNotificationArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  senderID?: Maybe<Scalars['String']>;
  recipientID?: Maybe<Scalars['String']>;
  readStatus?: Maybe<ReadStatus>;
  docRefTable?: Maybe<Scalars['String']>;
  docRefID?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['DateTime']>;
};


export type QueryGetNotificationListingArgs = {
  unread?: Maybe<Scalars['Boolean']>;
};


export type QueryGetRoleArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  softwareCode?: Maybe<SoftwareCode>;
};


export type QueryGetRoleSetupArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  softwareCode?: Maybe<SoftwareCode>;
};


export type QueryGetEntityRoleUserAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['String']>;
};


export type QueryUserRoleIDsArgs = {
  contractID: Scalars['String'];
};


export type QueryGetRolePermissionArgs = {
  siteManagement?: Maybe<Scalars['Boolean']>;
};


export type QueryGetUsersRoleContractArgs = {
  contractID: Scalars['String'];
  roleID: Scalars['String'];
};


export type QueryGetRoleMatrixArgs = {
  roleID?: Maybe<Scalars['String']>;
};


export type QueryGetRoleMatrixByIdsArgs = {
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  softwareCode?: Maybe<Scalars['String']>;
  roleID?: Maybe<Scalars['String']>;
};


export type QueryGetRoleByIdsArgs = {
  softwareCode?: Maybe<Scalars['String']>;
  roleIDs?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetAlertArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};


export type QueryGetAlertListArgs = {
  commonStatus?: Maybe<CommonStatus>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetShowAlertSqlArgs = {
  ID: Scalars['String'];
};


export type QueryGetAlertAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  reminder?: Maybe<Scalars['String']>;
  alertID?: Maybe<Scalars['String']>;
};


export type QueryGetApprovalPolicyGuidelineArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
};


export type QueryGetApprovalPolicyAssignmentGuidelineArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  approvalPolicyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
};


export type QueryGetApprovalPolicyArgs = {
  category: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetApprovalPolicyAssignmentArgs = {
  contractID: Scalars['String'];
  category: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetBankAccountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<BankAccountStatus>;
};


export type QueryGetBankAccountWithPermArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<BankAccountStatus>;
};


export type QueryGetCalendarArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
};


export type QueryGetCalendarPhPolicyArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  calendarID?: Maybe<Scalars['String']>;
  publicHolidayID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetCalendarWeekendPolicyArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryLogoViewArgs = {
  description?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
};


export type QueryWatermarkViewArgs = {
  opacity?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
};


export type QueryGetCostCategoryArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  isLastNode?: Maybe<Scalars['Boolean']>;
  costClass?: Maybe<CostClass>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetActivePnmCostCategoryArgs = {
  companyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetMapCostCategoryArgs = {
  costClass?: Maybe<CostClass>;
  materialOnly?: Maybe<Scalars['Boolean']>;
};


export type QueryGetParentChildrenCostCategoryArgs = {
  cost?: Maybe<Scalars['String']>;
  costClass?: Maybe<CostClass>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetCostCategoryTreeArgs = {
  costClass?: Maybe<CostClass>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetSubcontractTradeCostCategoryArgs = {
  wbsID: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryGetNonSelectedCostCategoryArgs = {
  CommonStatus?: Maybe<CommonStatus>;
  wbsID: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetCostItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  isInventory?: Maybe<Scalars['Boolean']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetCostItemCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  isInventory?: Maybe<Scalars['Boolean']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetCostItemBySupplierArgs = {
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  supplierID: Scalars['String'];
};


export type QueryGetItemByClassArgs = {
  isInventory?: Maybe<Scalars['Boolean']>;
  costClass: CostClass;
};


export type QueryGetCostItemByClassesArgs = {
  isInventory?: Maybe<Scalars['Boolean']>;
  costClass: Array<CostClass>;
};


export type QueryGetCostItemPaginationArgs = {
  limit?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  costClass?: Maybe<CostClass>;
  isInventory?: Maybe<Scalars['Boolean']>;
  costCategoryID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetCostCategoryItemArgs = {
  costClass?: Maybe<CostClass>;
  itemOnly?: Maybe<Scalars['Boolean']>;
};


export type QueryGetCostItemByWbsArgs = {
  contractID: Scalars['String'];
  wbsID: Scalars['String'];
};


export type QueryGetCostItemByWbsIDsArgs = {
  wbsIDs: Array<Scalars['String']>;
  contractID: Scalars['String'];
};


export type QueryCheckingBudgetQtyArgs = {
  wbsID: Scalars['String'];
  uomID: Scalars['String'];
  costItemID: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryGetCurrencySetupArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  format?: Maybe<NumericFormatter>;
};


export type QueryGetDigitalDocumentArgs = {
  type: Scalars['String'];
  associateID: Scalars['String'];
};


export type QueryGetTableDocumentsArgs = {
  description?: Maybe<Scalars['String']>;
  refTable: Scalars['String'];
  associateID: Scalars['String'];
  refID: Array<Scalars['String']>;
};


export type QueryTotalDigitalDocumentsArgs = {
  type?: Maybe<Scalars['String']>;
  associateID: Scalars['String'];
};


export type QueryGetNumberFormatArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  docTypeID?: Maybe<Scalars['String']>;
};


export type QueryGetConDocTypeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetDocumentTypeArgs = {
  commonStatus?: Maybe<CommonStatus>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetDocTypeArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryFetchNextDocNumArgs = {
  claimOnly?: Maybe<Scalars['String']>;
  consubconID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  docCode: ContractDocNum;
};


export type QueryFetchMultipleDocNumArgs = {
  supplierIDs?: Maybe<Array<Scalars['String']>>;
  numOfDocs?: Maybe<Scalars['Float']>;
  companyID?: Maybe<Scalars['String']>;
  docCode: ContractDocNum;
};


export type QueryGetRunningNumberSetupWithLockArgs = {
  contractID?: Maybe<Scalars['String']>;
  numberFormatID: Scalars['String'];
};


export type QueryRunningNumCheckingArgs = {
  companyID?: Maybe<Scalars['String']>;
  docCode: ContractDocNum;
};


export type QueryProjMiscExpRunNumCheckingArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetDocNumHeaderArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  docTypeID?: Maybe<Scalars['String']>;
};


export type QueryGetDocNumDetailArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetDocumentNumberHeaderAndDetailArgs = {
  contractID: Scalars['String'];
};


export type QueryGetClassificationCodeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  classificationCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};


export type QueryGetClassificationCodeCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryGetGeneralTinArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  tinParty?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
};


export type QueryGetGeneralTinCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryGetMsicCodeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  msicCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  categoryReference?: Maybe<Scalars['String']>;
};


export type QueryGetMsicCodeCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryGetHolidayTypeArgs = {
  commonStatus?: Maybe<CommonStatus>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetJobTypeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryIsPlatformUserValidArgs = {
  userID: Scalars['String'];
};


export type QueryGetPolicyGuideLineArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
};


export type QueryGetPolicyRoleAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  policyID?: Maybe<Scalars['String']>;
  roleID?: Maybe<Scalars['String']>;
};


export type QueryGetNotificationGuideLineArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  policyID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
};


export type QueryGetNotiRoleUserAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  notiPolicyID?: Maybe<Scalars['String']>;
  roleID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
};


export type QueryGetPublicHolidayArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
};


export type QueryGetPublicHolidayByStateYearArgs = {
  year: Scalars['String'];
};


export type QueryGetRevenueCategoryArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetRevenueCategoryCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetSAccountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteMaterialArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetSiteWorkersArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetSubcontractorTypeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  glAccountCode?: Maybe<Scalars['String']>;
  accrualAccountCode?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetSupplierTypeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  glAccountCode?: Maybe<Scalars['String']>;
  accrualAccountCode?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetUomArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetUomWithPermArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetUomCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetUomWithPermCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetUomExchangeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  fromUomID?: Maybe<Scalars['String']>;
  toUomID?: Maybe<Scalars['String']>;
};


export type QueryGetUomExchangeWithPermArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  fromUomID?: Maybe<Scalars['String']>;
  toUomID?: Maybe<Scalars['String']>;
};


export type QueryGetUomExchangeListArgs = {
  uomID: Scalars['String'];
};


export type QueryGetUomExchangeListByWbsArgs = {
  uomIDs: Array<Scalars['String']>;
};


export type QueryGetUserCompanyAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedByLedgerArgs = {
  docRefID?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
};


export type QueryGetUsersByAccountArgs = {
  accountID: Scalars['String'];
};


export type QueryGetUserArgs = {
  ID: Scalars['String'];
};


export type QueryGetUsersbyIdsArgs = {
  userIDs: Array<Scalars['String']>;
};


export type QueryIsUserValidArgs = {
  userID: Scalars['String'];
};


export type QueryGetUsersByAccountAndSoftwareArgs = {
  superUserBool?: Maybe<UserSelection>;
  status?: Maybe<CommonStatus>;
};


export type QueryGetVideoCamArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetWagesRatePolicyArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetContractToDoArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  docRefID?: Maybe<Scalars['String']>;
};


export type QueryGetToDoListingArgs = {
  approved?: Maybe<Scalars['Boolean']>;
};


export type QueryGetWorkerArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetWorkerSiteAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  workerID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetWorkerFromSiteArgs = {
  siteID: Scalars['String'];
};


export type QueryCheckingWageTypeArgs = {
  workerID?: Maybe<Scalars['String']>;
};


export type QueryGetWorkerAttendanceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  workDate?: Maybe<Scalars['String']>;
};


export type QueryCheckWorkFlowArgs = {
  stockMovement?: Maybe<Scalars['String']>;
  itemName?: Maybe<Scalars['String']>;
  tableName: Scalars['String'];
  accountID: Scalars['String'];
  docRefID: Scalars['String'];
};


export type QueryGetToDoWorkflowSummaryArgs = {
  length?: Maybe<Scalars['Boolean']>;
};


export type QueryGetToDoAllPendingDocsArgs = {
  category: Scalars['String'];
};


export type QueryPolicyCheckingArgs = {
  contractID: Scalars['String'];
  category: Scalars['String'];
};


export type QueryPolicyCheckerApPaymentArgs = {
  category: Scalars['String'];
};


export type QueryGetNotificationWorkflowListingArgs = {
  length?: Maybe<Scalars['Boolean']>;
  unread?: Maybe<Scalars['Boolean']>;
};


export type QueryGetApprovalLogArgs = {
  refID: Scalars['String'];
};


export type QueryGetAllApprovalLogArgs = {
  refIDs: Array<Scalars['String']>;
};


export type QueryApprovableLedgerWfCheckingArgs = {
  input: CheckingInput;
};


export type QueryGetWorkingHourPolicyArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetHorAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  plantMachineryID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  assignmentStatus?: Maybe<AsgMainStatus>;
};


export type QueryGetAssignmentCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  plantMachineryID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  assignmentStatus?: Maybe<AsgMainStatus>;
};


export type QueryGetAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  assignmentStatus?: Maybe<AsgMainStatus>;
  offSet?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  plantMachineryID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetFuelDischargeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  tankID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  approvalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetFuelDischargeCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  tankID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  approvalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetFuelBalanceArgs = {
  tankID: Scalars['String'];
};


export type QueryGetFuelMeterReadingArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  tankID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetFuelMeterReadingCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  tankID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryFuelMeterReadingSummaryArgs = {
  tankID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
  date?: Maybe<Scalars['String']>;
};


export type QueryGetFuelSupplyArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  tankID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['Upload']>;
  approvalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetFuelSupplyCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  tankID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  approvalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetFuelTankSetupArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  plantMachineryID?: Maybe<Scalars['String']>;
  tankType?: Maybe<TankType>;
};


export type QueryGetFuelTankSetupCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  plantMachineryID?: Maybe<Scalars['String']>;
  tankType?: Maybe<TankType>;
};


export type QueryGetHorMaintenanceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  plantMachineryID?: Maybe<Scalars['String']>;
  maintenanceStatus?: Maybe<AsgMainStatus>;
  workshopID?: Maybe<Scalars['String']>;
};


export type QueryGetMaintenanceCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  plantMachineryID?: Maybe<Scalars['String']>;
  maintenanceStatus?: Maybe<AsgMainStatus>;
  workshopID?: Maybe<Scalars['String']>;
};


export type QueryGetMaintenanceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  maintenanceStatus?: Maybe<AsgMainStatus>;
  offSet?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  plantMachineryID?: Maybe<Scalars['String']>;
  workshopID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetPlantMachineryArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  plantMachineryStatus?: Maybe<PlantMachineryStatus>;
};


export type QueryGetWorkshopArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  workshopStatus?: Maybe<WorkshopStatus>;
};


export type QueryGetWorkshopFormArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  workshopStatus?: Maybe<WorkshopStatus>;
};


export type QueryGetPnmRentalArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetPnmRentalCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetPnmByStatusArgs = {
  costCategoryID: Scalars['String'];
  status: Array<PlantMachineryStatus>;
};


export type QueryGetPnmStatusArgs = {
  plantMachineryID: Scalars['String'];
};


export type QueryGetTankArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  companyID: Scalars['String'];
  hasMeterReading?: Maybe<Scalars['Boolean']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetRentalTankArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  companyID: Scalars['String'];
  hasMeterReading?: Maybe<Scalars['Boolean']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplyByArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  companyID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplyDischargeToArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  companyID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetContractFuelArgs = {
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetDoArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  doStatus?: Maybe<DoStatus>;
  siteID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
};


export type QueryGetDoItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetDoCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  doStatus?: Maybe<DoStatus>;
  siteID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
};


export type QueryGetPOsArgs = {
  docDate?: Maybe<Scalars['DateTime']>;
  siteID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryGetGrtNsArgs = {
  docDate?: Maybe<Scalars['DateTime']>;
  siteID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryTotalDoAmtArgs = {
  doID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplierByDoStatusArgs = {
  doStatus: DoStatus;
  contractID: Scalars['String'];
};


export type QueryCheckingCancelCloseDoArgs = {
  ID: Scalars['String'];
};


export type QueryGetGrtnBySupplierArgs = {
  siteID: Scalars['String'];
};


export type QueryGetGoodReturnNoteArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  supplierID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  grtnStatus?: Maybe<PurchaseStatus>;
};


export type QueryGetGoodReturnNoteItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryGetGoodReturnNoteCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  grtnStatus?: Maybe<PurchaseStatus>;
};


export type QueryGetNegotiatedSupplyArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  status?: Maybe<PurchaseStatus>;
};


export type QueryGetNegotiatedSupplyItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  negotiatedSupplyID?: Maybe<Scalars['String']>;
};


export type QueryGetNegotiatedSupplyCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  status?: Maybe<PurchaseStatus>;
};


export type QueryVerifyNegoSupplyStatusArgs = {
  contractID: Scalars['String'];
};


export type QueryVerifyNegoSupplyItemArgs = {
  costItemID: Scalars['String'];
  supplierID: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryGetPurchaseOrderArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  prGenerated?: Maybe<Scalars['Boolean']>;
  purchaseType?: Maybe<PurchaseType>;
  purchaseStatus?: Maybe<PurchaseStatus>;
};


export type QueryGetPoItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  purchaseOrderID?: Maybe<Scalars['String']>;
  prItemID?: Maybe<Scalars['String']>;
  negotiatedSupplyItemID?: Maybe<Scalars['String']>;
};


export type QueryGetPurchaseOrderCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  prGenerated?: Maybe<Scalars['Boolean']>;
  purchaseType?: Maybe<PurchaseType>;
  purchaseStatus?: Maybe<PurchaseStatus>;
};


export type QueryGetPoByStatusArgs = {
  purchaseType?: Maybe<PurchaseType>;
  statusArr: Array<PurchaseStatus>;
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  prGenerated?: Maybe<Scalars['Boolean']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryPurchaseBudgetArgs = {
  contractID: Scalars['String'];
  prInfo: Array<PrInfo>;
};


export type QueryPurchaseBudgetAllocationArgs = {
  contractID: Scalars['String'];
  prItemIDs: Array<Scalars['String']>;
};


export type QueryPurchaseWbsBudgetAllocationArgs = {
  contractID: Scalars['String'];
  input: Array<PurchaseWbsBudgetAllocationInput>;
};


export type QueryGetSupplierQuotesArgs = {
  prItemID: Scalars['String'];
  contractID: Scalars['String'];
  costItemID: Scalars['String'];
};


export type QueryClosePoCheckingArgs = {
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetPoBySupplierArgs = {
  siteID: Scalars['String'];
};


export type QueryGetSupplierByPoStatusArgs = {
  purchaseStatus: PurchaseStatus;
  contractID: Scalars['String'];
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryGetPoContractNameArgs = {
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetPoListArgs = {
  status?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetPoNoArgs = {
  isStatus?: Maybe<Scalars['Boolean']>;
  supplierID?: Maybe<Array<Scalars['String']>>;
  contractID?: Maybe<Array<Scalars['String']>>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetPoCategoryArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetPoItemByCategoryArgs = {
  costCategoryID?: Maybe<Scalars['String']>;
};


export type QueryCheckingCancelClosePoArgs = {
  ID: Scalars['String'];
};


export type QueryGetPoCostItemListArgs = {
  supplierID?: Maybe<Array<Scalars['String']>>;
  contractID?: Maybe<Array<Scalars['String']>>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetPoSupplierListArgs = {
  contractID?: Maybe<Array<Scalars['String']>>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetPurchaseReqArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  prItemID?: Maybe<Scalars['String']>;
  purchaseStatus?: Maybe<PurchaseStatus>;
  purchaseType?: Maybe<PurchaseType>;
};


export type QueryGetPurchaseReqCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  prItemID?: Maybe<Scalars['String']>;
  purchaseStatus?: Maybe<PurchaseStatus>;
  purchaseType?: Maybe<PurchaseType>;
};


export type QueryGetPrForBudgetByOneArgs = {
  contractID: Scalars['String'];
  purchaseType: PurchaseType;
  ID: Scalars['String'];
};


export type QueryGetPrItemsbyIDsArgs = {
  poID?: Maybe<Scalars['String']>;
  IDs: Array<Scalars['String']>;
};


export type QueryGetOutstandingPrArgs = {
  purchaseType: PurchaseType;
  contractID: Scalars['String'];
};


export type QueryGetOutstandingPrItemsArgs = {
  supplierID?: Maybe<Scalars['String']>;
  purchaseType: PurchaseType;
  contractID: Scalars['String'];
};


export type QueryCheckingCancelClosePrArgs = {
  ID: Scalars['String'];
};


export type QueryGetRfqArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  purchaseReqID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  rfqStatus?: Maybe<RfqStatus>;
};


export type QueryGetRfqItemArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  rfqID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  prItemID?: Maybe<Scalars['String']>;
};


export type QueryGetRfqCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  purchaseReqID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  rfqStatus?: Maybe<RfqStatus>;
};


export type QueryGetSuggestedSupplierArgs = {
  rfqID: Scalars['String'];
};


export type QueryGetRfqSupplierInvitedArgs = {
  rfqStatus?: Maybe<Scalars['String']>;
  supplierID: Scalars['String'];
};


export type QueryGetRfqSupplierInvitationArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetInvitedSupplierArgs = {
  contractID: Scalars['String'];
  rfqID: Scalars['String'];
};


export type QueryGetRfqSupplierSubmissionArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  rfqID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryGetPrItemByPridForRfqArgs = {
  contractID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryGetPurchaseReqWithPrArgs = {
  contractID: Scalars['String'];
};


export type QueryGetQuotedRfqArgs = {
  supplierID: Scalars['String'];
  rfqID: Scalars['String'];
};


export type QueryGetSupplierCompanyAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplierByCompanyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  associatedStatus?: Maybe<AssociatedStatus>;
  withoutCompany?: Maybe<Scalars['Boolean']>;
};


export type QueryGetSupplierArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  creditorAC?: Maybe<Scalars['String']>;
  associatedStatus?: Maybe<AssociatedStatus>;
};


export type QueryGetSupplierCCategoryArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  supplierID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
};


export type QueryGetMultiSupplierArgs = {
  supplierIDs: Array<Scalars['String']>;
};


export type QueryGetSupplierCostCategoryArgs = {
  costCategoryID: Scalars['String'];
};


export type QueryGetSupplierDepositAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetSupplierPaymentAllocationArgs = {
  receiptStatus?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetDNtoSupplierAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetCNtoSupplierAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetSupplierCnAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetSupplierDnAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetSupplierInvoiceAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetInvoiceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetApInvoiceArgs = {
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  transactionStatus?: Maybe<TransactionStatus>;
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetApInvoiceWoDoArgs = {
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  transactionStatus?: Maybe<TransactionStatus>;
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetDOforInvoiceArgs = {
  formMode?: Maybe<Scalars['String']>;
  invoiceID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
  supplierID: Scalars['String'];
};


export type QueryGetApAdvanceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetApAdvanceCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetApPaymentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetApPaymentCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetApRefundArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetApRefundCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetSupplierPaymentByContractArgs = {
  contractID: Scalars['String'];
};


export type QueryGetApDebitNoteArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetApDebitNoteCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetApCreditNoteArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetApCreditNoteCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetDNtoSupplierArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetDNtoSupplierCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetCNtoSupplierArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetCNtoSupplierCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetSupplierCreditDocEInvoiceArgs = {
  isSelfBilled?: Maybe<Scalars['Boolean']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractorInvitationArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractTenderID?: Maybe<Scalars['String']>;
};


export type QueryGetSuggestedSubcontractorArgs = {
  subcontractTenderID: Scalars['String'];
};


export type QueryGetInvitedSubcontractorArgs = {
  contractID: Scalars['String'];
  subcontractTenderID: Scalars['String'];
};


export type QueryGetSubconRfqSubmissionArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractorInvitationID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractTenderID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractTenderArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractGuaranteeArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractInsuranceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractSum?: Maybe<Scalars['Float']>;
  costCategoryID?: Maybe<Scalars['String']>;
  subcontractStatus?: Maybe<ContractStatus>;
};


export type QueryGetSubcontractRelatedArgs = {
  subcontractID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractWithTradeArgs = {
  costCategoryID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetSubcontractTitleWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QuerySubconBudgetCheckingArgs = {
  amount: Scalars['Float'];
  contractID: Scalars['String'];
  costCategoryID: Scalars['String'];
};


export type QuerySubcontractVoCalculationArgs = {
  subcontractID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractorCompanyAssignmentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractorByCompanyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  associatedStatus?: Maybe<AssociatedStatus>;
  withoutCompany?: Maybe<Scalars['Boolean']>;
};


export type QueryGetSubcontractVoArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  VOStatus?: Maybe<VoStatus>;
};


export type QueryGetSubconVoTradeCostCategoryArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
};


export type QuerySubcontractVoListingArgs = {
  contractID: Scalars['String'];
};


export type QueryGetSubcontractCostWbsArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
};


export type QueryGetSubconAdvanceAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetSubconPaymentAllocationArgs = {
  receiptStatus?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
};


export type QueryGetSubconRefundAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetSubconClaimAllocationLedgersArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractRetentionClaimAllocationArgs = {
  contractID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
};


export type QueryGetSubconRetClaimAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetSubconCnAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetSubconDnAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetDNtoSubconAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetCNtoSubconAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetSubcontractClaimArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetSubcontractClaimCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  isRetention?: Maybe<Scalars['Boolean']>;
};


export type QueryGetSubconRetentionClaimArgs = {
  searchValue?: Maybe<Scalars['String']>;
  offSet?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetSubconProgressClaimArgs = {
  searchValue?: Maybe<Scalars['String']>;
  offSet?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractAdvanceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetSubcontractAdvanceCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetSubcontractPaymentArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetSubcontractPaymentCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetSubcontractRefundArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetSubcontractRefundCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetSubcontractMiscInvoiceArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetSubcontractMiscInvoiceCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetSubcontractDnArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetSubcontractDnCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetSubcontractCnArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetSubcontractCnCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetDNtoSubconArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetDNtoSubconCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetCNtoSubconArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetCNtoSubconCountArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetDnItemsByDnidArgs = {
  dnID: Scalars['String'];
};


export type QueryGetDNtoSubconRevenueArgs = {
  transactionStatus?: Maybe<TransactionStatus>;
  contractID?: Maybe<Scalars['String']>;
  withRev?: Maybe<Scalars['Boolean']>;
};


export type QueryGetSubconCreditDocEInvoiceArgs = {
  isSelfBilled?: Maybe<Scalars['Boolean']>;
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractorArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  associatedStatus?: Maybe<AssociatedStatus>;
};


export type QueryGetSubcontractorCCategoryArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  subcontractorID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractorCostCategoryArgs = {
  costCategoryID: Scalars['String'];
};


export type QueryGetCostCategoryBySubconArgs = {
  subcontractorID: Scalars['String'];
};


export type QueryGetSubcontractorAccessibleContractArgs = {
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetProjectSiteArgs = {
  siteID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  mainSite?: Maybe<Scalars['Boolean']>;
};


export type QueryVerifySiteArgs = {
  siteID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetSiteNameWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetSiteImageArgs = {
  searchValue?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};

export enum QuotationStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Inactive = 'INACTIVE'
}

export type RfqActionInput = {
  remarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  rfqStatus: RfqStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type RfqEntity = AuditEntity & {
  __typename?: 'RFQEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  purchaseReqID: Scalars['String'];
  purchaseReq?: Maybe<PurchaseReqEntity>;
  description: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docDate: Scalars['String'];
  expectedDate: Scalars['String'];
  rejectionDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  cancelCloseRemark?: Maybe<Scalars['String']>;
  rfqStatus: RfqStatus;
  rfqItem?: Maybe<Array<RfqItemEntity>>;
  invitedSupplier?: Maybe<Array<RfqSupplierInvitationEntity>>;
  rfqSupplierSubmission?: Maybe<Array<RfqSupplierSubmissionEntity>>;
  supplierRFQInvite: Scalars['JSON'];
  supplier: Array<SupplierEntity>;
  /** CustomFieldResolver */
  totalRfqSubmission?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  submittedSupplierQuotes?: Maybe<Scalars['Float']>;
};


export type RfqEntitySupplierRfqInviteArgs = {
  supplierID?: Maybe<Scalars['String']>;
};


export type RfqEntitySubmittedSupplierQuotesArgs = {
  supplierID: Scalars['String'];
};

export type RfqEvalInput = {
  ID?: Maybe<Scalars['String']>;
  rfqStatus?: Maybe<RfqStatus>;
};

export type RfqInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  purchaseReqID: Scalars['String'];
  description: Scalars['String'];
  expectedDate: Scalars['String'];
  companyID?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  rfqStatus?: Maybe<RfqStatus>;
};

export type RfqItemEntity = AuditEntity & {
  __typename?: 'RFQItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  rfqID: Scalars['String'];
  rfq?: Maybe<RfqEntity>;
  prItemID: Scalars['String'];
  prItem?: Maybe<PrItemEntity>;
  uomID: Scalars['String'];
  uom?: Maybe<UomEntity>;
  quantity: Scalars['Float'];
  rfqSubmissionItem?: Maybe<Array<RfqSubmissionItemEntity>>;
  poItem?: Maybe<Array<PoItemEntity>>;
  rfqItemSubmissionSelected?: Maybe<Array<RfqItemSubmissionSelectedEntity>>;
  /** CustomFieldResolver */
  supplierSelected?: Maybe<Scalars['Boolean']>;
};


export type RfqItemEntitySupplierSelectedArgs = {
  supplierID?: Maybe<Scalars['String']>;
};

export type RfqItemInput = {
  ID?: Maybe<Scalars['String']>;
  prItemID: Scalars['String'];
  uomID: Scalars['String'];
  quantity: Scalars['Float'];
};

export type RfqItemSubmissionSelectedEntity = AuditEntity & {
  __typename?: 'RFQItemSubmissionSelectedEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  rfqItemID: Scalars['String'];
  rfqItem?: Maybe<RfqItemEntity>;
  rfqSubmissionItemID: Scalars['String'];
  rfqSubmissionItem?: Maybe<RfqSubmissionItemEntity>;
};

export type RfqRejectInput = {
  rejectionDate: Scalars['String'];
  remarks: Scalars['String'];
};

export enum RfqStatus {
  Draft = 'DRAFT',
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Cancelled = 'CANCELLED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Pending = 'PENDING'
}

export type RfqSubmissionInput = {
  ID?: Maybe<Scalars['String']>;
  rfqID: Scalars['String'];
  supplierID: Scalars['String'];
  quotationNo: Scalars['String'];
  docAmt: Scalars['Float'];
  creditTerm: Scalars['Float'];
  submittedDate: Scalars['String'];
  effectiveDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  quotationStatus: QuotationStatus;
  purchaseStatus?: Maybe<PurchaseStatus>;
  rfqSubmissionItem: Array<RfqSubmissionItemInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type RfqSubmissionItemEntity = AuditEntity & {
  __typename?: 'RFQSubmissionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  rfqSupplierSubmissionID: Scalars['String'];
  rfqSubmission: RfqSupplierSubmissionEntity;
  rfqItemID: Scalars['String'];
  rfqItem?: Maybe<RfqItemEntity>;
  unitPrice: Scalars['Float'];
  isSelected: Scalars['Boolean'];
  remarks?: Maybe<Scalars['String']>;
  rfqItemSubmissionSelected?: Maybe<Array<RfqItemSubmissionSelectedEntity>>;
  /** CustomFieldResolver */
  cheapest?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  selected?: Maybe<Scalars['JSON']>;
};


export type RfqSubmissionItemEntityCheapestArgs = {
  rfqID?: Maybe<Scalars['String']>;
};

export type RfqSubmissionItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  rfqItemID: Scalars['String'];
  unitPrice: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  purchaseStatus?: Maybe<PurchaseStatus>;
};

export type RfqSupplierInvitationEntity = AuditEntity & {
  __typename?: 'RFQSupplierInvitationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  supplierID: Scalars['String'];
  supplier: SupplierEntity;
  rfqID: Scalars['String'];
  rfq?: Maybe<RfqEntity>;
};

export type RfqSupplierInvitationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  rfqID: Scalars['String'];
};

export type RfqSupplierSubmissionEntity = AuditEntity & {
  __typename?: 'RFQSupplierSubmissionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  rfqID: Scalars['String'];
  rfq?: Maybe<RfqEntity>;
  supplierID: Scalars['String'];
  supplier: SupplierEntity;
  quotationNo: Scalars['String'];
  docAmt: Scalars['Float'];
  creditTerm: Scalars['Float'];
  submittedDate: Scalars['String'];
  effectiveDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  quotationStatus: QuotationStatus;
  rfqSubmissionItem: Array<RfqSubmissionItemEntity>;
};

export enum RateUomType {
  Hourly = 'HOURLY',
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Lumpsum = 'LUMPSUM'
}

export enum RatingType {
  Gold = 'GOLD',
  Diamond = 'DIAMOND',
  Platinum = 'PLATINUM'
}

export enum ReadStatus {
  Seen = 'SEEN',
  Read = 'READ',
  Unread = 'UNREAD'
}

export enum ReceiptStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
  Pendingclose = 'PENDINGCLOSE'
}

export enum RefTable {
  Contract = 'CONTRACT',
  Client = 'CLIENT',
  Subcontract = 'SUBCONTRACT',
  Supplier = 'SUPPLIER',
  NegotiatedSupply = 'NEGOTIATED_SUPPLY',
  Miscexpense = 'MISCEXPENSE'
}

export type RejectInput = {
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
};

export enum Relationship {
  Supervisor = 'Supervisor',
  Spouse = 'Spouse',
  Family = 'Family',
  Friends = 'Friends'
}

export type RelationshipTypes = {
  __typename?: 'RelationshipTypes';
  relationship: Relationship;
};

export type ReminderDetail = {
  __typename?: 'ReminderDetail';
  reminderSeq?: Maybe<Scalars['Float']>;
  numOfReminder?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

export type ReminderDetailInput = {
  reminderSeq?: Maybe<Scalars['Float']>;
  numOfReminder?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

export enum ReminderFor {
  AllBill = 'ALL_BILL',
  ProgressClaim = 'PROGRESS_CLAIM'
}

export type ReportDevEntity = {
  __typename?: 'ReportDevEntity';
  ID: Scalars['String'];
  ParentID: Scalars['String'];
  ReportName: Scalars['String'];
  FilePath?: Maybe<Scalars['String']>;
  IsSystem?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Module?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

export type RetentionInfo = {
  __typename?: 'RetentionInfo';
  retentionSum?: Maybe<Scalars['Float']>;
  releasedSum?: Maybe<Scalars['Float']>;
  retentionBalance?: Maybe<Scalars['Float']>;
};

export type ReturnInput = {
  returnDate?: Maybe<Scalars['String']>;
  returnRemarks?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  cost?: Maybe<Scalars['Float']>;
  returnQty?: Maybe<Scalars['Float']>;
};

export type RevEInvoiceAdjItemEntity = AuditEntity & {
  __typename?: 'RevEInvoiceAdjItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  revenueCategoryID: Scalars['String'];
};

export type RevenueCategoryDeleteInput = {
  ID: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type RevenueCategoryEntity = AuditEntity & {
  __typename?: 'RevenueCategoryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
  isDaywork: Scalars['Boolean'];
  glAccountCode?: Maybe<Scalars['String']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  eInvoiceIntegrated: Scalars['Boolean'];
  commonStatus: CommonStatus;
  wbsBudgetDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  contractAccount?: Maybe<Array<ContractAccountEntity>>;
  contractVORevenueWbs?: Maybe<Array<ContractVoRevenueWbsEntity>>;
  contractClaimAllocation?: Maybe<Array<ContractClaimAllocationEntity>>;
  contractDNItem?: Maybe<Array<ContractDnItemEntity>>;
  contractCNItem?: Maybe<Array<ContractCnItemEntity>>;
  contractMiscInvoiceItem?: Maybe<Array<ContractMiscInvoiceItemEntity>>;
  DNToSubconItem?: Maybe<Array<DNtoSubconItemEntity>>;
  CNToSubconItem?: Maybe<Array<CNtoSubconItemEntity>>;
  wbsDaywork?: Maybe<Array<WbsDayworkCostRevenueEntity>>;
  classificationCode?: Maybe<EInvoiceClassificationEntity>;
};

export type RevenueCategoryImportInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  sequence?: Maybe<Scalars['Float']>;
  revenueName?: Maybe<Scalars['String']>;
  tax?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
};

export type RevenueCategoryInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  taxSchemeID: Scalars['String'];
  name: Scalars['String'];
  creditTerm: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  glAccountCode?: Maybe<Scalars['String']>;
  isDaywork?: Maybe<Scalars['Boolean']>;
  eInvoiceIntegrated?: Maybe<Scalars['Boolean']>;
};

export type RevenueCategoryUpdateInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type RoleEntity = AuditEntity & {
  __typename?: 'RoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  softwareCode: SoftwareCode;
  rolePerm: Array<RolePermissionAssignmentEntity>;
  conRolePerm: Array<RolePermissionAssignmentEntity>;
  siteRolePerm: Array<RolePermissionAssignmentEntity>;
  /** CustomFieldResolver */
  assignedUserEntity?: Maybe<Scalars['JSON']>;
};

export type RoleInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
};

export type RolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'RolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
  accountID?: Maybe<Scalars['String']>;
  permName?: Maybe<Scalars['String']>;
};

export type RoleUserEntAssignmentEntity = AuditEntity & {
  __typename?: 'RoleUserEntAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
  accountID: Scalars['String'];
  userID: Scalars['String'];
};

export type RoleUserInput = {
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type SAccountEntity = AuditEntity & {
  __typename?: 'SAccountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  companyCode?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  currencyName?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  watermarkImplementation: Scalars['Boolean'];
  isoImplementation: Scalars['Boolean'];
  watermarkWording?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['JSON']>;
  logoPosition: LogoPosition;
  isoLogoPosition: LogoPosition;
};

export type SAccountInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  companyCode?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  currencyName?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  watermarkWording?: Maybe<Scalars['String']>;
  isoImplementation?: Maybe<Scalars['Boolean']>;
  watermarkImplementation?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Scalars['JSON']>;
  logoPosition?: Maybe<LogoPosition>;
  isoLogoPosition?: Maybe<LogoPosition>;
};

export type SafetyChecklistEntity = AuditEntity & {
  __typename?: 'SafetyChecklistEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  site?: Maybe<SiteEntity>;
  title: Scalars['String'];
  nextScheduleDate: Scalars['String'];
  endDate: Scalars['String'];
  isActive: Scalars['Boolean'];
  frequency: Frequency;
  checklistCategoryID: Scalars['String'];
  checklistCategory?: Maybe<ChecklistCategoryEntity>;
  commonStatus: CommonStatus;
  siteSafety?: Maybe<Array<SiteSafetyEntity>>;
  safetyChecklistItem?: Maybe<Array<SafetyChecklistItemEntity>>;
};

export type SafetyChecklistItemEntity = AuditEntity & {
  __typename?: 'SafetyChecklistItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  safetyChecklistID: Scalars['String'];
  safetyChecklist?: Maybe<SafetyChecklistEntity>;
  title: Scalars['String'];
  notes: Scalars['String'];
  siteSafetyItem?: Maybe<Array<SiteSafetyItemEntity>>;
};

export enum SampleEnum {
  Demo = 'DEMO',
  Testing = 'TESTING'
}

export type ShareAttachmentEntity = AuditEntity & {
  __typename?: 'ShareAttachmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  bucketFileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
};

export type ShippedDetail = {
  __typename?: 'ShippedDetail';
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  shippedByID?: Maybe<Scalars['String']>;
  shippedByName?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
};

export type ShippedDetailInput = {
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  shippedByID?: Maybe<Scalars['String']>;
  shippedByName?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
};

export type SiteDeleteInput = {
  ID: Scalars['String'];
};

export type SiteDiaryEntity = AuditEntity & {
  __typename?: 'SiteDiaryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  diaryTypeID: Scalars['String'];
  diaryType?: Maybe<DiaryTypeEntity>;
  diaryDate: Scalars['String'];
  subject: Scalars['String'];
  description: Scalars['String'];
  location: Scalars['String'];
  approvalStatus: ApprovalStatus;
  assignedToID?: Maybe<Scalars['String']>;
  instructionComment?: Maybe<Scalars['String']>;
  targetDate?: Maybe<Scalars['DateTime']>;
  closingDate?: Maybe<Scalars['String']>;
  closingRemark?: Maybe<Scalars['String']>;
};

export type SiteEntity = AuditEntity & {
  __typename?: 'SiteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  stateID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  address: Scalars['JSON'];
  contactNo?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  mainSite: Scalars['Boolean'];
  stockMovement?: Maybe<Array<StockMovementEntity>>;
  workerSiteAssignment?: Maybe<Array<WorkerSiteAssignmentEntity>>;
  siteStockLedger?: Maybe<Array<SiteStockLedgerEntity>>;
  purchaseReq?: Maybe<Array<PurchaseReqEntity>>;
  purchaseOrder?: Maybe<Array<PurchaseOrderEntity>>;
  subcontract?: Maybe<Array<SubcontractEntity>>;
  siteImage?: Maybe<Array<SiteImageEntity>>;
  videoCam?: Maybe<Array<VideoCamEntity>>;
  wageCycle?: Maybe<Array<WageCycleEntity>>;
  deliveryOrder?: Maybe<Array<DoEntity>>;
  workerWage?: Maybe<Array<WorkerWageEntity>>;
  grn?: Maybe<Array<GrnEntity>>;
  defect?: Maybe<Array<DefectEntity>>;
  goodReturnNote?: Maybe<Array<GoodReturnNoteEntity>>;
  assignment?: Maybe<Array<AssignmentEntity>>;
  documentFolder?: Maybe<Array<DocumentFolderEntity>>;
  siteSafety?: Maybe<Array<SiteSafetyEntity>>;
  safetyChecklist?: Maybe<Array<SafetyChecklistEntity>>;
  /** CustomFieldResolver */
  picture?: Maybe<Scalars['Float']>;
  worker: Array<WorkerEntity>;
};

export type SiteImageDeleteInput = {
  ID: Scalars['String'];
};

export type SiteImageEntity = AuditEntity & {
  __typename?: 'SiteImageEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  title: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  site?: Maybe<SiteEntity>;
};

export type SiteImageInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  siteID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  balanceQty?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type SiteInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  stateID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  mainSite?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressInput>;
};

export type SiteMaterialContractPolicyEntity = AuditEntity & {
  __typename?: 'SiteMaterialContractPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteMaterialPolicyID: Scalars['String'];
  siteMaterialPolicy?: Maybe<SiteMaterialPolicyEntity>;
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  costItemID?: Maybe<Scalars['String']>;
  costItem?: Maybe<CostItemEntity>;
  siteMaterialID?: Maybe<Scalars['String']>;
  siteMaterial?: Maybe<SiteMaterialEntity>;
  commonStatus: CommonStatus;
};

export type SiteMaterialContractPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
};

export type SiteMaterialDeleteInput = {
  ID: Scalars['String'];
};

export type SiteMaterialEntity = AuditEntity & {
  __typename?: 'SiteMaterialEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  uomID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  stockCode?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  uom?: Maybe<UomEntity>;
  stockMovement?: Maybe<Array<StockMovementEntity>>;
  grnItem?: Maybe<Array<GrnItemEntity>>;
  siteMaterialPolicy?: Maybe<Array<SiteMaterialPolicyEntity>>;
  siteMaterialContractPolicy?: Maybe<Array<SiteMaterialContractPolicyEntity>>;
};

export type SiteMaterialInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  siteID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['String']>;
  stockCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type SiteMaterialPolicyEntity = AuditEntity & {
  __typename?: 'SiteMaterialPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID?: Maybe<Scalars['String']>;
  costItem?: Maybe<CostItemEntity>;
  siteMaterialID?: Maybe<Scalars['String']>;
  siteMaterial?: Maybe<SiteMaterialEntity>;
  commonStatus: CommonStatus;
  siteMaterialContractPolicy?: Maybe<Array<SiteMaterialContractPolicyEntity>>;
};

export type SiteMaterialPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  costItemID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
};

export enum SitePermission {
  SiteMgmtIcon = 'SITE_MGMT__________ICON',
  DefectMgmtIcon = 'DEFECT_MGMT__________ICON',
  DefectMgmtView = 'DEFECT_MGMT__________VIEW',
  DefectMgmtCreate = 'DEFECT_MGMT__________CREATE',
  DefectMgmtUpdate = 'DEFECT_MGMT__________UPDATE',
  DefectMgmtActivityLogCreate = 'DEFECT_MGMT_ACTIVITY_LOG_______CREATE',
  DefectMgmtActivityLogView = 'DEFECT_MGMT_ACTIVITY_LOG_______VIEW',
  DefectMgmtActivityLogUpdate = 'DEFECT_MGMT_ACTIVITY_LOG_______UPDATE',
  DefectMgmtDefectAssignmentView = 'DEFECT_MGMT_DEFECT_ASSIGNMENT_______VIEW',
  DefectMgmtDefectAssignmentCreate = 'DEFECT_MGMT_DEFECT_ASSIGNMENT_______CREATE',
  DefectMgmtGeneralSettingsIcon = 'DEFECT_MGMT_GENERAL_SETTINGS_______ICON',
  DefectMgmtGeneralSettingsSubcontractPackageView = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____VIEW',
  DefectMgmtGeneralSettingsSubcontractPackageCreate = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____CREATE',
  DefectMgmtGeneralSettingsSubcontractPackageUpdate = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____UPDATE',
  DefectMgmtGeneralSettingsSubcontractPackageDelete = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____DELETE',
  DefectMgmtGeneralSettingsSubcontractPackageDownload = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____DOWNLOAD',
  DefectMgmtGeneralSettingsSubcontractPackageUpload = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____UPLOAD',
  SiteAttendanceIcon = 'SITE_ATTENDANCE__________ICON',
  SiteAttendanceView = 'SITE_ATTENDANCE__________VIEW',
  SiteAttendanceWorkerProfileCreate = 'SITE_ATTENDANCE_WORKER_PROFILE_______CREATE',
  SiteAttendanceWorkerProfileView = 'SITE_ATTENDANCE_WORKER_PROFILE_______VIEW',
  SiteAttendanceWorkerProfileUpdate = 'SITE_ATTENDANCE_WORKER_PROFILE_______UPDATE',
  SiteAttendanceWorkerProfileDelete = 'SITE_ATTENDANCE_WORKER_PROFILE_______DELETE',
  SiteAttendanceWorkerProfileDownload = 'SITE_ATTENDANCE_WORKER_PROFILE_______DOWNLOAD',
  SiteAttendanceWorkerProfileUpload = 'SITE_ATTENDANCE_WORKER_PROFILE_______UPLOAD',
  SiteAttendanceWorkerAttendanceCreate = 'SITE_ATTENDANCE_WORKER_ATTENDANCE_______CREATE',
  SiteAttendanceWorkerAttendanceView = 'SITE_ATTENDANCE_WORKER_ATTENDANCE_______VIEW',
  SiteAttendanceWorkerAttendanceExport = 'SITE_ATTENDANCE_WORKER_ATTENDANCE_______EXPORT',
  SiteAttendanceGeneralSettingsIcon = 'SITE_ATTENDANCE_GENERAL_SETTINGS_______ICON',
  SiteAttendanceGeneralSettingsPublicHolidayCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CREATE',
  SiteAttendanceGeneralSettingsPublicHolidayView = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____VIEW',
  SiteAttendanceGeneralSettingsPublicHolidayUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____UPDATE',
  SiteAttendanceGeneralSettingsPublicHolidayDelete = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____DELETE',
  SiteAttendanceGeneralSettingsPublicHolidayCancel = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CANCEL',
  SiteAttendanceGeneralSettingsPublicHolidayActive = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____ACTIVE',
  SiteAttendanceGeneralSettingsCalendarPolicyCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_CALENDAR_POLICY____CREATE',
  SiteAttendanceGeneralSettingsCalendarPolicyView = 'SITE_ATTENDANCE_GENERAL_SETTINGS_CALENDAR_POLICY____VIEW',
  SiteAttendanceGeneralSettingsCalendarPolicyUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_CALENDAR_POLICY____UPDATE',
  SiteAttendanceGeneralSettingsWorkingHoursPolicyCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_WORKING_HOURS_POLICY____CREATE',
  SiteAttendanceGeneralSettingsWorkingHoursPolicyUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_WORKING_HOURS_POLICY____UPDATE',
  SiteAttendanceGeneralSettingsJobTypeCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____CREATE',
  SiteAttendanceGeneralSettingsJobTypeView = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____VIEW',
  SiteAttendanceGeneralSettingsJobTypeUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____UPDATE',
  SiteAttendanceGeneralSettingsJobTypeDelete = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____DELETE',
  SiteAttendanceGeneralSettingsJobTypeCancel = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____CANCEL',
  SiteAttendanceGeneralSettingsJobTypeActive = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____ACTIVE',
  SiteWagesIcon = 'SITE_WAGES__________ICON',
  SiteWagesWorkerAttendanceView = 'SITE_WAGES_WORKER_ATTENDANCE__________VIEW',
  SiteWagesWorkerAttendanceCreate = 'SITE_WAGES_WORKER_ATTENDANCE__________CREATE',
  SiteWagesView = 'SITE_WAGES__________VIEW',
  SiteWagesDailyRegisteredWorkHoursCreate = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______CREATE',
  SiteWagesDailyRegisteredWorkHoursView = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______VIEW',
  SiteWagesDailyRegisteredWorkHoursUpdate = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______UPDATE',
  SiteWagesDailyRegisteredWorkHoursDelete = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______DELETE',
  SiteWagesDailyWagesVerificationCreate = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______CREATE',
  SiteWagesDailyWagesVerificationView = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______VIEW',
  SiteWagesDailyWagesVerificationUpdate = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______UPDATE',
  SiteWagesDailyWagesVerificationDelete = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______DELETE',
  SiteWagesWagesBookView = 'SITE_WAGES_WAGES_BOOK_______VIEW',
  SiteWagesWagesBookCreate = 'SITE_WAGES_WAGES_BOOK_______CREATE',
  SiteWagesWagesBookUpdate = 'SITE_WAGES_WAGES_BOOK_______UPDATE',
  SiteWagesWagesBookDelete = 'SITE_WAGES_WAGES_BOOK_______DELETE',
  SiteWagesWageCycleClosureCreate = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______CREATE',
  SiteWagesWageCycleClosureView = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______VIEW',
  SiteWagesWageCycleClosureUpdate = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______UPDATE',
  SiteWagesWageCycleClosureDelete = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______DELETE',
  SiteWagesGeneralSettingsIcon = 'SITE_WAGES_GENERAL_SETTINGS_______ICON',
  SiteWagesGeneralSettingsPublicHolidayCreate = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CREATE',
  SiteWagesGeneralSettingsPublicHolidayView = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____VIEW',
  SiteWagesGeneralSettingsPublicHolidayUpdate = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____UPDATE',
  SiteWagesGeneralSettingsPublicHolidayDelete = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____DELETE',
  SiteWagesGeneralSettingsPublicHolidayCancel = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CANCEL',
  SiteWagesGeneralSettingsPublicHolidayActive = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____ACTIVE',
  SiteWagesGeneralSettingsCalendarPolicyCreate = 'SITE_WAGES_GENERAL_SETTINGS_CALENDAR_POLICY____CREATE',
  SiteWagesGeneralSettingsCalendarPolicyView = 'SITE_WAGES_GENERAL_SETTINGS_CALENDAR_POLICY____VIEW',
  SiteWagesGeneralSettingsCalendarPolicyUpdate = 'SITE_WAGES_GENERAL_SETTINGS_CALENDAR_POLICY____UPDATE',
  SiteWagesGeneralSettingsWorkingHoursPolicyCreate = 'SITE_WAGES_GENERAL_SETTINGS_WORKING_HOURS_POLICY____CREATE',
  SiteWagesGeneralSettingsWorkingHoursPolicyUpdate = 'SITE_WAGES_GENERAL_SETTINGS_WORKING_HOURS_POLICY____UPDATE',
  SiteWagesGeneralSettingsWagesRatePolicyCreate = 'SITE_WAGES_GENERAL_SETTINGS_WAGES_RATE_POLICY____CREATE',
  SiteWagesGeneralSettingsWagesRatePolicyView = 'SITE_WAGES_GENERAL_SETTINGS_WAGES_RATE_POLICY____VIEW',
  SiteWagesGeneralSettingsWagesRatePolicyUpdate = 'SITE_WAGES_GENERAL_SETTINGS_WAGES_RATE_POLICY____UPDATE',
  SiteInventoryIcon = 'SITE_INVENTORY__________ICON',
  SiteInventoryView = 'SITE_INVENTORY__________VIEW',
  SiteInventoryGoodReceiveNoteCreate = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______CREATE',
  SiteInventoryGoodReceiveNoteView = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______VIEW',
  SiteInventoryGoodReceiveNoteUpdate = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______UPDATE',
  SiteInventoryGoodReceiveNoteApproveReject = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______APPROVE_REJECT',
  SiteInventoryGoodReceiveNoteCancel = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______CANCEL',
  SiteInventoryGoodReceiveNoteDelete = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______DELETE',
  SiteInventoryStockIssueCreate = 'SITE_INVENTORY_STOCK_ISSUE_______CREATE',
  SiteInventoryStockIssueView = 'SITE_INVENTORY_STOCK_ISSUE_______VIEW',
  SiteInventoryStockIssueUpdate = 'SITE_INVENTORY_STOCK_ISSUE_______UPDATE',
  SiteInventoryStockIssueDelete = 'SITE_INVENTORY_STOCK_ISSUE_______DELETE',
  SiteInventoryStockIssueDownload = 'SITE_INVENTORY_STOCK_ISSUE_______DOWNLOAD',
  SiteInventoryStockIssueApproveReject = 'SITE_INVENTORY_STOCK_ISSUE_______APPROVE_REJECT',
  SiteInventoryStockIssueCancel = 'SITE_INVENTORY_STOCK_ISSUE_______CANCEL',
  SiteInventoryStockTransferInCreate = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______CREATE',
  SiteInventoryStockTransferInView = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______VIEW',
  SiteInventoryStockTransferInUpdate = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______UPDATE',
  SiteInventoryStockTransferInDelete = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______DELETE',
  SiteInventoryStockTransferInDownload = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______DOWNLOAD',
  SiteInventoryStockTransferInApproveReject = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______APPROVE_REJECT',
  SiteInventoryStockTransferInCancel = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______CANCEL',
  SiteInventoryStockTransferOutCreate = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______CREATE',
  SiteInventoryStockTransferOutView = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______VIEW',
  SiteInventoryStockTransferOutUpdate = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______UPDATE',
  SiteInventoryStockTransferOutDelete = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______DELETE',
  SiteInventoryStockTransferOutDownload = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______DOWNLOAD',
  SiteInventoryStockTransferOutApproveReject = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______APPROVE_REJECT',
  SiteInventoryStockTransferOutCancel = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______CANCEL',
  SiteInventoryStockAdjustmentCreate = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______CREATE',
  SiteInventoryStockAdjustmentView = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______VIEW',
  SiteInventoryStockAdjustmentUpdate = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______UPDATE',
  SiteInventoryStockAdjustmentDelete = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______DELETE',
  SiteInventoryStockAdjustmentDownload = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______DOWNLOAD',
  SiteInventoryStockAdjustmentApproveReject = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______APPROVE_REJECT',
  SiteInventoryStockAdjustmentCancel = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______CANCEL',
  SiteInventoryStockMovementCreate = 'SITE_INVENTORY_STOCK_MOVEMENT_______CREATE',
  SiteInventoryStockMovementView = 'SITE_INVENTORY_STOCK_MOVEMENT_______VIEW',
  SiteInventoryStockMovementUpdate = 'SITE_INVENTORY_STOCK_MOVEMENT_______UPDATE',
  SiteInventoryStockMovementDelete = 'SITE_INVENTORY_STOCK_MOVEMENT_______DELETE',
  SiteInventoryStockUsageAnalysisCreate = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______CREATE',
  SiteInventoryStockUsageAnalysisView = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______VIEW',
  SiteInventoryStockUsageAnalysisUpdate = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______UPDATE',
  SiteInventoryStockUsageAnalysisDelete = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______DELETE',
  SiteInventoryStockUsageAnalysisExport = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______EXPORT',
  SiteInventoryDigitalReportingView = 'SITE_INVENTORY_DIGITAL_REPORTING_______VIEW',
  SiteInventoryDigitalReportingStockMovementLedgerReportView = 'SITE_INVENTORY_DIGITAL_REPORTING_STOCK_MOVEMENT_LEDGER_REPORT____VIEW',
  SiteInventoryGeneralSettingsIcon = 'SITE_INVENTORY_GENERAL_SETTINGS_______ICON',
  SiteInventoryGeneralSettingsSiteMaterialCreate = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____CREATE',
  SiteInventoryGeneralSettingsSiteMaterialView = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____VIEW',
  SiteInventoryGeneralSettingsSiteMaterialUpdate = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____UPDATE',
  SiteInventoryGeneralSettingsSiteMaterialDelete = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____DELETE',
  SiteInventoryGeneralSettingsSiteMaterialCancel = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____CANCEL',
  SiteInventoryGeneralSettingsSiteMaterialActive = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____ACTIVE',
  SiteInventoryGeneralSettingsSiteMaterialDownload = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____DOWNLOAD',
  SiteInventoryGeneralSettingsSiteMaterialUpload = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____UPLOAD',
  SiteInventoryContractSettingsIcon = 'SITE_INVENTORY_CONTRACT_SETTINGS_______ICON',
  SiteSurveillanceIcon = 'SITE_SURVEILLANCE__________ICON',
  SiteSurveillanceView = 'SITE_SURVEILLANCE__________VIEW',
  SiteSurveillanceSiteImagesCreate = 'SITE_SURVEILLANCE_SITE_IMAGES_______CREATE',
  SiteSurveillanceSiteImagesView = 'SITE_SURVEILLANCE_SITE_IMAGES_______VIEW',
  SiteSurveillanceSiteImagesUpdate = 'SITE_SURVEILLANCE_SITE_IMAGES_______UPDATE',
  SiteSurveillanceSiteImagesDelete = 'SITE_SURVEILLANCE_SITE_IMAGES_______DELETE',
  SiteSurveillanceLiveVideocamView = 'SITE_SURVEILLANCE_LIVE_VIDEOCAM_______VIEW',
  SiteSurveillanceGeneralSettingsIcon = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_______ICON',
  SiteSurveillanceGeneralSettingsVideoCamSetupCreate = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____CREATE',
  SiteSurveillanceGeneralSettingsVideoCamSetupView = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____VIEW',
  SiteSurveillanceGeneralSettingsVideoCamSetupUpdate = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____UPDATE',
  SiteSurveillanceGeneralSettingsVideoCamSetupDelete = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____DELETE',
  BusinessInsightIcon = 'BUSINESS_INSIGHT__________ICON',
  BusinessInsightView = 'BUSINESS_INSIGHT__________VIEW',
  BusinessInsightDefectStatusAnalysisView = 'BUSINESS_INSIGHT_DEFECT_STATUS_ANALYSIS__________VIEW',
  BusinessInsightDefectStatusAnalysisExport = 'BUSINESS_INSIGHT_DEFECT_STATUS_ANALYSIS__________EXPORT',
  BusinessInsightDefectTradeAnalysisView = 'BUSINESS_INSIGHT_DEFECT_TRADE_ANALYSIS__________VIEW',
  BusinessInsightDefectTradeAnalysisExport = 'BUSINESS_INSIGHT_DEFECT_TRADE_ANALYSIS__________EXPORT',
  BusinessInsightDefectSubconAnalysisView = 'BUSINESS_INSIGHT_DEFECT_SUBCON_ANALYSIS__________VIEW',
  BusinessInsightDefectSubconAnalysisExport = 'BUSINESS_INSIGHT_DEFECT_SUBCON_ANALYSIS__________EXPORT',
  BusinessInsightDefectsLodgedOverTimeView = 'BUSINESS_INSIGHT_DEFECTS_LODGED_OVER_TIME__________VIEW',
  BusinessInsightDefectsLodgedOverTimeExport = 'BUSINESS_INSIGHT_DEFECTS_LODGED_OVER_TIME__________EXPORT',
  BusinessInsightGeneralSettingsIcon = 'BUSINESS_INSIGHT_GENERAL_SETTINGS__________ICON',
  SiteInventorySiteUsageComparisonCreate = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______CREATE',
  SiteInventorySiteUsageComparisonView = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______VIEW',
  SiteInventorySiteUsageComparisonUpdate = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______UPDATE',
  SiteInventorySiteUsageComparisonDelete = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______DELETE',
  SiteInventorySiteUsageComparisonExport = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______EXPORT',
  SiteInventorySiteWorkerAttendanceTrackingView = 'SITE_INVENTORY_SITE_WORKER_ATTENDANCE_TRACKING_______VIEW',
  SiteInventorySiteWorkerAttendanceTrackingExport = 'SITE_INVENTORY_SITE_WORKER_ATTENDANCE_TRACKING_______EXPORT',
  SiteInventoryStockUsageDailyAnalysisCreate = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______CREATE',
  SiteInventoryStockUsageDailyAnalysisView = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______VIEW',
  SiteInventoryStockUsageDailyAnalysisUpdate = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______UPDATE',
  SiteInventoryStockUsageDailyAnalysisDelete = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______DELETE',
  SiteInventoryStockUsageDailyAnalysisExport = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______EXPORT',
  SiteInventoryStockUsageMonthlyAnalysisCreate = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______CREATE',
  SiteInventoryStockUsageMonthlyAnalysisView = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______VIEW',
  SiteInventoryStockUsageMonthlyAnalysisUpdate = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______UPDATE',
  SiteInventoryStockUsageMonthlyAnalysisDelete = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______DELETE',
  SiteInventoryStockUsageMonthlyAnalysisExport = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______EXPORT',
  SiteInventoryStockMoveInOutAnalysisCreate = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______CREATE',
  SiteInventoryStockMoveInOutAnalysisView = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______VIEW',
  SiteInventoryStockMoveInOutAnalysisUpdate = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______UPDATE',
  SiteInventoryStockMoveInOutAnalysisDelete = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______DELETE',
  SiteInventoryStockMoveInOutAnalysisExport = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______EXPORT',
  SiteInventorySiteUsageAnalysisCreate = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______CREATE',
  SiteInventorySiteUsageAnalysisView = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______VIEW',
  SiteInventorySiteUsageAnalysisUpdate = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______UPDATE',
  SiteInventorySiteUsageAnalysisDelete = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______DELETE',
  SiteInventorySiteUsageAnalysisExport = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______EXPORT',
  SiteSafetyIcon = 'SITE_SAFETY__________ICON',
  SiteSafetyCreate = 'SITE_SAFETY__________CREATE',
  SiteSafetyView = 'SITE_SAFETY__________VIEW',
  ChecklistCategoryIcon = 'CHECKLIST_CATEGORY__________ICON',
  ChecklistCategoryView = 'CHECKLIST_CATEGORY__________VIEW',
  ChecklistCategoryCreate = 'CHECKLIST_CATEGORY__________CREATE',
  ChecklistCategoryDelete = 'CHECKLIST_CATEGORY__________DELETE',
  SafetyChecklistIcon = 'SAFETY_CHECKLIST__________ICON',
  SafetyChecklistView = 'SAFETY_CHECKLIST__________VIEW',
  SafetyChecklistCreate = 'SAFETY_CHECKLIST__________CREATE',
  SafetyChecklistDelete = 'SAFETY_CHECKLIST__________DELETE',
  DigitalDocumentIcon = 'DIGITAL_DOCUMENT__________ICON',
  DigitalDocumentView = 'DIGITAL_DOCUMENT__________VIEW',
  DigitalDocumentCreate = 'DIGITAL_DOCUMENT__________CREATE',
  DigitalDocumentDelete = 'DIGITAL_DOCUMENT__________DELETE',
  DigitalDocumentGeneralSettingsIcon = 'DIGITAL_DOCUMENT_GENERAL_SETTINGS__________ICON',
  SystemAdminIcon = 'SYSTEM_ADMIN__________ICON',
  SystemAdminView = 'SYSTEM_ADMIN__________VIEW',
  SystemAdminProjectSiteView = 'SYSTEM_ADMIN_PROJECT_SITE_______VIEW',
  SystemAdminProjectSiteCreate = 'SYSTEM_ADMIN_PROJECT_SITE_______CREATE',
  SystemAdminProjectSiteUpdate = 'SYSTEM_ADMIN_PROJECT_SITE_______UPDATE',
  SystemAdminProjectSiteDelete = 'SYSTEM_ADMIN_PROJECT_SITE_______DELETE',
  SystemAdminSubconSetupView = 'SYSTEM_ADMIN_SUBCON_SETUP_______VIEW',
  SystemAdminSubconSetupCreate = 'SYSTEM_ADMIN_SUBCON_SETUP_______CREATE',
  SystemAdminSubconSetupUpdate = 'SYSTEM_ADMIN_SUBCON_SETUP_______UPDATE',
  SystemAdminSubconSetupDelete = 'SYSTEM_ADMIN_SUBCON_SETUP_______DELETE',
  SystemAdminTaxSetupView = 'SYSTEM_ADMIN_TAX_SETUP_______VIEW',
  SystemAdminTaxSetupCreate = 'SYSTEM_ADMIN_TAX_SETUP_______CREATE',
  SystemAdminTaxSetupUpdate = 'SYSTEM_ADMIN_TAX_SETUP_______UPDATE',
  SystemAdminTaxSetupDelete = 'SYSTEM_ADMIN_TAX_SETUP_______DELETE',
  SystemAdminTaxSetupActive = 'SYSTEM_ADMIN_TAX_SETUP_______ACTIVE',
  SystemAdminTaxSetupInactive = 'SYSTEM_ADMIN_TAX_SETUP_______INACTIVE',
  SystemAdminTaxSetupViewHistory = 'SYSTEM_ADMIN_TAX_SETUP_______VIEW_HISTORY',
  SystemAdminUomSetupView = 'SYSTEM_ADMIN_UOM_SETUP_______VIEW',
  SystemAdminUomSetupCreate = 'SYSTEM_ADMIN_UOM_SETUP_______CREATE',
  SystemAdminUomSetupUpdate = 'SYSTEM_ADMIN_UOM_SETUP_______UPDATE',
  SystemAdminUomSetupDelete = 'SYSTEM_ADMIN_UOM_SETUP_______DELETE',
  SystemAdminUomSetupActive = 'SYSTEM_ADMIN_UOM_SETUP_______ACTIVE',
  SystemAdminUomSetupInactive = 'SYSTEM_ADMIN_UOM_SETUP_______INACTIVE',
  SystemAdminUomSetupDownload = 'SYSTEM_ADMIN_UOM_SETUP_______DOWNLOAD',
  SystemAdminUomSetupUpload = 'SYSTEM_ADMIN_UOM_SETUP_______UPLOAD',
  SystemAdminUomConversionView = 'SYSTEM_ADMIN_UOM_CONVERSION_______VIEW',
  SystemAdminUomConversionCreate = 'SYSTEM_ADMIN_UOM_CONVERSION_______CREATE',
  SystemAdminUomConversionUpdate = 'SYSTEM_ADMIN_UOM_CONVERSION_______UPDATE',
  SystemAdminUomConversionDelete = 'SYSTEM_ADMIN_UOM_CONVERSION_______DELETE',
  SystemAdminCompanySetupView = 'SYSTEM_ADMIN_COMPANY_SETUP_______VIEW',
  SystemAdminCompanySetupUpdate = 'SYSTEM_ADMIN_COMPANY_SETUP_______UPDATE',
  SystemAdminCompanySetupWatermarkLogoSetupView = 'SYSTEM_ADMIN_COMPANY_SETUP_WATERMARK_LOGO_SETUP____VIEW',
  SystemAdminContractSettingsView = 'SYSTEM_ADMIN_CONTRACT_SETTINGS_______VIEW',
  GeneralSettingsIcon = 'GENERAL_SETTINGS__________ICON',
  GeneralSettingsSubcontractTradeView = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____VIEW',
  GeneralSettingsSubcontractTradeCreate = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____CREATE',
  GeneralSettingsSubcontractTradeUpdate = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPDATE',
  GeneralSettingsSubcontractTradeInactive = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____INACTIVE',
  GeneralSettingsSubcontractTradeActive = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____ACTIVE',
  GeneralSettingsSubcontractTradeDelete = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____DELETE',
  GeneralSettingsSubcontractTradeDownload = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____DOWNLOAD',
  GeneralSettingsSubcontractTradeUpload = 'GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPLOAD',
  GeneralSettingsPublicHolidayView = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____VIEW',
  GeneralSettingsPublicHolidayCreate = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____CREATE',
  GeneralSettingsPublicHolidayUpdate = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____UPDATE',
  GeneralSettingsPublicHolidayInactive = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____INACTIVE',
  GeneralSettingsPublicHolidayActive = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____ACTIVE',
  GeneralSettingsPublicHolidayDelete = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____DELETE',
  GeneralSettingsPublicHolidayCancel = 'GENERAL_SETTINGS_PUBLIC_HOLIDAY____CANCEL',
  GeneralSettingsCalendarPolicyView = 'GENERAL_SETTINGS_CALENDAR_POLICY____VIEW',
  GeneralSettingsCalendarPolicyCreate = 'GENERAL_SETTINGS_CALENDAR_POLICY____CREATE',
  GeneralSettingsCalendarPolicyUpdate = 'GENERAL_SETTINGS_CALENDAR_POLICY____UPDATE',
  GeneralSettingsWorkingHoursPolicyView = 'GENERAL_SETTINGS_WORKING_HOURS_POLICY____VIEW',
  GeneralSettingsWorkingHoursPolicyUpdate = 'GENERAL_SETTINGS_WORKING_HOURS_POLICY____UPDATE',
  GeneralSettingsJobTypeView = 'GENERAL_SETTINGS_JOB_TYPE____VIEW',
  GeneralSettingsJobTypeCreate = 'GENERAL_SETTINGS_JOB_TYPE____CREATE',
  GeneralSettingsJobTypeUpdate = 'GENERAL_SETTINGS_JOB_TYPE____UPDATE',
  GeneralSettingsJobTypeInactive = 'GENERAL_SETTINGS_JOB_TYPE____INACTIVE',
  GeneralSettingsJobTypeActive = 'GENERAL_SETTINGS_JOB_TYPE____ACTIVE',
  GeneralSettingsJobTypeCancel = 'GENERAL_SETTINGS_JOB_TYPE____CANCEL',
  GeneralSettingsJobTypeDelete = 'GENERAL_SETTINGS_JOB_TYPE____DELETE',
  GeneralSettingsWagesRatePolicyUpdate = 'GENERAL_SETTINGS_WAGES_RATE_POLICY____UPDATE',
  GeneralSettingsWagesRatePolicyView = 'GENERAL_SETTINGS_WAGES_RATE_POLICY____VIEW',
  GeneralSettingsWorkerProfileView = 'GENERAL_SETTINGS_WORKER_PROFILE____VIEW',
  GeneralSettingsWorkerProfileCreate = 'GENERAL_SETTINGS_WORKER_PROFILE____CREATE',
  GeneralSettingsWorkerProfileUpdate = 'GENERAL_SETTINGS_WORKER_PROFILE____UPDATE',
  GeneralSettingsBuildingMaterialView = 'GENERAL_SETTINGS_BUILDING_MATERIAL____VIEW',
  GeneralSettingsBuildingMaterialCreate = 'GENERAL_SETTINGS_BUILDING_MATERIAL____CREATE',
  GeneralSettingsBuildingMaterialUpdate = 'GENERAL_SETTINGS_BUILDING_MATERIAL____UPDATE',
  GeneralSettingsBuildingMaterialInactive = 'GENERAL_SETTINGS_BUILDING_MATERIAL____INACTIVE',
  GeneralSettingsBuildingMaterialActive = 'GENERAL_SETTINGS_BUILDING_MATERIAL____ACTIVE',
  GeneralSettingsBuildingMaterialDelete = 'GENERAL_SETTINGS_BUILDING_MATERIAL____DELETE',
  GeneralSettingsBuildingMaterialDownload = 'GENERAL_SETTINGS_BUILDING_MATERIAL____DOWNLOAD',
  GeneralSettingsBuildingMaterialUpload = 'GENERAL_SETTINGS_BUILDING_MATERIAL____UPLOAD',
  GeneralSettingsSiteBuildingMaterialView = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____VIEW',
  GeneralSettingsSiteBuildingMaterialCreate = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____CREATE',
  GeneralSettingsSiteBuildingMaterialUpdate = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____UPDATE',
  GeneralSettingsSiteBuildingMaterialInactive = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____INACTIVE',
  GeneralSettingsSiteBuildingMaterialActive = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____ACTIVE',
  GeneralSettingsSiteBuildingMaterialDelete = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____DELETE',
  GeneralSettingsSiteBuildingMaterialDownload = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____DOWNLOAD',
  GeneralSettingsSiteBuildingMaterialUpload = 'GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____UPLOAD',
  GeneralSettingsSiteMaterialPolicyView = 'GENERAL_SETTINGS_SITE_MATERIAL_POLICY____VIEW',
  GeneralSettingsSiteMaterialPolicyUpdate = 'GENERAL_SETTINGS_SITE_MATERIAL_POLICY____UPDATE',
  GeneralSettingsStandardApprovalPolicyView = 'GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY_______VIEW',
  GeneralSettingsStandardApprovalPolicyUpdate = 'GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY____UPDATE',
  GeneralSettingsStandardApprovalPolicyCreate = 'GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY____CREATE',
  GeneralSettingsVideoCamSetupView = 'GENERAL_SETTINGS_VIDEO_CAM_SETUP____VIEW',
  GeneralSettingsVideoCamSetupCreate = 'GENERAL_SETTINGS_VIDEO_CAM_SETUP____CREATE',
  GeneralSettingsVideoCamSetupUpdate = 'GENERAL_SETTINGS_VIDEO_CAM_SETUP____UPDATE',
  GeneralSettingsVideoCamSetupDelete = 'GENERAL_SETTINGS_VIDEO_CAM_SETUP____DELETE',
  AccessSecurityIcon = 'ACCESS_SECURITY__________ICON',
  AccessSecurityView = 'ACCESS_SECURITY__________VIEW',
  AccessSecurityUserView = 'ACCESS_SECURITY_USER_______VIEW',
  AccessSecurityUserCreate = 'ACCESS_SECURITY_USER_______CREATE',
  AccessSecurityUserUpdate = 'ACCESS_SECURITY_USER_______UPDATE',
  AccessSecurityUserChangePassword = 'ACCESS_SECURITY_USER_______CHANGE_PASSWORD',
  AccessSecurityUserUnblock = 'ACCESS_SECURITY_USER_______UNBLOCK',
  AccessSecurityUserAssign = 'ACCESS_SECURITY_USER_______ASSIGN',
  AccessSecurityRolesView = 'ACCESS_SECURITY_ROLES_______VIEW',
  AccessSecurityRolesCreate = 'ACCESS_SECURITY_ROLES_______CREATE',
  AccessSecurityRolesUpdate = 'ACCESS_SECURITY_ROLES_______UPDATE',
  AccessSecurityRolesDelete = 'ACCESS_SECURITY_ROLES_______DELETE',
  AccessSecurityDigitalReportView = 'ACCESS_SECURITY_DIGITAL_REPORT_______VIEW',
  AccessSecurityProjectRoleAuthView = 'ACCESS_SECURITY_PROJECT_ROLE_AUTH____VIEW',
  AccessSecurityProjectRoleAuthCreate = 'ACCESS_SECURITY_PROJECT_ROLE_AUTH____CREATE',
  AccessSecurityProjectRoleAuthUpdate = 'ACCESS_SECURITY_PROJECT_ROLE_AUTH____UPDATE',
  CommonSettingsIcon = 'COMMON_SETTINGS__________ICON',
  CommonSettingsTaxSetupView = 'COMMON_SETTINGS_TAX_SETUP_______VIEW',
  CommonSettingsTaxSetupCreate = 'COMMON_SETTINGS_TAX_SETUP_______CREATE',
  CommonSettingsTaxSetupUpdate = 'COMMON_SETTINGS_TAX_SETUP_______UPDATE',
  CommonSettingsTaxSetupDelete = 'COMMON_SETTINGS_TAX_SETUP_______DELETE',
  CommonSettingsTaxSetupInactive = 'COMMON_SETTINGS_TAX_SETUP_______INACTIVE',
  CommonSettingsTaxSetupActive = 'COMMON_SETTINGS_TAX_SETUP_______ACTIVE',
  CommonSettingsTaxSetupViewHistory = 'COMMON_SETTINGS_TAX_SETUP_______VIEW_HISTORY',
  CommonSettingsUomSetupView = 'COMMON_SETTINGS_UOM_SETUP_______VIEW',
  CommonSettingsUomSetupCreate = 'COMMON_SETTINGS_UOM_SETUP_______CREATE',
  CommonSettingsUomSetupUpdate = 'COMMON_SETTINGS_UOM_SETUP_______UPDATE',
  CommonSettingsUomSetupDelete = 'COMMON_SETTINGS_UOM_SETUP_______DELETE',
  CommonSettingsUomSetupInactive = 'COMMON_SETTINGS_UOM_SETUP_______INACTIVE',
  CommonSettingsUomSetupActive = 'COMMON_SETTINGS_UOM_SETUP_______ACTIVE',
  CommonSettingsUomSetupDownload = 'COMMON_SETTINGS_UOM_SETUP_______DOWNLOAD',
  CommonSettingsUomSetupUpload = 'COMMON_SETTINGS_UOM_SETUP_______UPLOAD',
  CommonSettingsUomConversionSetupView = 'COMMON_SETTINGS_UOM_CONVERSION_SETUP_______VIEW',
  CommonSettingsUomConversionSetupUpdate = 'COMMON_SETTINGS_UOM_CONVERSION_SETUP_______UPDATE',
  CommonSettingsUomConversionSetupCreate = 'COMMON_SETTINGS_UOM_CONVERSION_SETUP_______CREATE',
  CommonSettingsUomConversionSetupDelete = 'COMMON_SETTINGS_UOM_CONVERSION_SETUP_______DELETE'
}

export type SitePermissionEntity = {
  __typename?: 'SitePermissionEntity';
  ID: Scalars['String'];
  permissionName: Scalars['String'];
  permissionID: Scalars['Float'];
};

export type SiteProgressEntity = AuditEntity & {
  __typename?: 'SiteProgressEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  siteID?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  contract?: Maybe<ContractEntity>;
  /** CustomFieldResolver */
  imagesInfo?: Maybe<Scalars['JSON']>;
};

export type SiteSafetyEntity = AuditEntity & {
  __typename?: 'SiteSafetyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  site?: Maybe<SiteEntity>;
  safetyChecklistID: Scalars['String'];
  safetyChecklist?: Maybe<SafetyChecklistEntity>;
  docDate: Scalars['String'];
  assignedToID?: Maybe<Scalars['String']>;
  status: SiteSafetyStatus;
  siteSafetyItem?: Maybe<Array<SiteSafetyItemEntity>>;
  assignedTo?: Maybe<UserEntity>;
};

export type SiteSafetyItemEntity = AuditEntity & {
  __typename?: 'SiteSafetyItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteSafetyID: Scalars['String'];
  siteSafety?: Maybe<SiteSafetyEntity>;
  safetyChecklistItemID: Scalars['String'];
  safetyChecklistItem?: Maybe<SafetyChecklistItemEntity>;
  isChecked: Scalars['Boolean'];
  remarks?: Maybe<Scalars['String']>;
  followUp?: Maybe<Scalars['Boolean']>;
  diaryTypeID?: Maybe<Scalars['String']>;
  diaryType?: Maybe<DiaryTypeEntity>;
  location?: Maybe<Scalars['String']>;
};

export enum SiteSafetyStatus {
  Open = 'OPEN',
  Closed = 'CLOSED'
}

export type SiteStockLedgerEntity = AuditEntity & {
  __typename?: 'SiteStockLedgerEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  costItemID: Scalars['String'];
  uomID: Scalars['String'];
  quantity: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  trackingDate: Scalars['String'];
  trxType: TrxType;
  costItem?: Maybe<CostItemEntity>;
  uom?: Maybe<UomEntity>;
  site?: Maybe<SiteEntity>;
};

export type SiteSubordinateAssignmentEntity = AuditEntity & {
  __typename?: 'SiteSubordinateAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  assignedTo: Scalars['String'];
  dateAssigned: Scalars['DateTime'];
  status: Scalars['String'];
  targetDate: Scalars['DateTime'];
  location: Scalars['String'];
};

export type SiteToDoEntity = AuditEntity & {
  __typename?: 'SiteToDoEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  notes: Scalars['String'];
  status: Scalars['String'];
  closingDate: Scalars['DateTime'];
  closingRemark: Scalars['String'];
  followUp: Scalars['Boolean'];
  diaryType?: Maybe<Scalars['String']>;
  location: Scalars['String'];
};

export type SiteVideoEntity = AuditEntity & {
  __typename?: 'SiteVideoEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  videoCamID: Scalars['String'];
};

export type SiteWorkersInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  siteID: Scalars['String'];
  contactNo: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  timeIn?: Maybe<Scalars['DateTime']>;
  timeOut?: Maybe<Scalars['DateTime']>;
  nationality?: Maybe<Nationality>;
  emergencyContact?: Maybe<Scalars['JSON']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export enum SoftwareCode {
  Contract = 'CONTRACT',
  Site = 'SITE',
  Client = 'CLIENT',
  Subcontractor = 'SUBCONTRACTOR',
  Supplier = 'SUPPLIER',
  Hr = 'HR',
  Property = 'PROPERTY',
  Rent = 'RENT',
  Hotel = 'HOTEL',
  Account = 'ACCOUNT',
  Ifca = 'IFCA',
  Subscription = 'SUBSCRIPTION',
  User = 'USER',
  Tenant = 'TENANT',
  LeaseTodo = 'LEASE_TODO',
  LeaseOperation = 'LEASE_OPERATION',
  LeaseSales = 'LEASE_SALES',
  Menu = 'MENU',
  PosCustomer = 'POS_CUSTOMER',
  Probation = 'PROBATION',
  ReportDesigner = 'REPORT_DESIGNER'
}

export type StandardAuditEntity = {
  created_ts: Scalars['DateTime'];
  created_by?: Maybe<Scalars['String']>;
  mod_ts: Scalars['DateTime'];
  mod_by?: Maybe<Scalars['String']>;
};

export type StandardCoreBaseEntity = AuditEntity & {
  __typename?: 'StandardCoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export type StockMovementEntity = AuditEntity & {
  __typename?: 'StockMovementEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  site?: Maybe<SiteEntity>;
  doID?: Maybe<Scalars['String']>;
  do?: Maybe<DoEntity>;
  grnID?: Maybe<Scalars['String']>;
  grn?: Maybe<GrnEntity>;
  grtnID?: Maybe<Scalars['String']>;
  grtn?: Maybe<GoodReturnNoteEntity>;
  docDate?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
  siteMaterial?: Maybe<SiteMaterialEntity>;
  costItemID?: Maybe<Scalars['String']>;
  costItem?: Maybe<CostItemEntity>;
  uomID: Scalars['String'];
  uom?: Maybe<UomEntity>;
  baseUomID?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  rejectionRemark?: Maybe<Scalars['String']>;
  cancellationRemark?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  stockMovementStatus: StockMovementStatus;
  stockMovementType: StockMovementType;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  workFlow?: Maybe<Array<WorkFlowEntity>>;
};

export enum StockMovementStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED'
}

export enum StockMovementType {
  Received = 'RECEIVED',
  Returned = 'RETURNED',
  Issue = 'ISSUE',
  TransferIn = 'TRANSFER_IN',
  TransferOut = 'TRANSFER_OUT',
  Adjustment = 'ADJUSTMENT'
}

export type SubconAdjustmentTransaction = AuditEntity & {
  __typename?: 'SubconAdjustmentTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
};

export type SubconClaimAllocInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  subcontractCostWbsID: Scalars['String'];
  currentSubmission: Scalars['Float'];
  subcontractClaimID?: Maybe<Scalars['String']>;
};

export type SubconClaimRetentionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docDate: Scalars['String'];
  retentionAmt: Scalars['Float'];
  taxSchemeID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmt: Scalars['Float'];
  docDueDate?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
  docNo?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};

export type SubconClaimVoAllocInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  subcontractVOID: Scalars['String'];
  currentSubmission: Scalars['Float'];
  subcontractClaimID?: Maybe<Scalars['String']>;
};

export type SubconClaimVoCostWbsAllocInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  subcontractVOCostWbsID: Scalars['String'];
  currentSubmission: Scalars['Float'];
  subcontractClaimID?: Maybe<Scalars['String']>;
};

export type SubconCoreTransaction = AuditEntity & {
  __typename?: 'SubconCoreTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractID: Scalars['String'];
};

export type SubconEInvoiceAdjustmentTransaction = AuditEntity & {
  __typename?: 'SubconEInvoiceAdjustmentTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
};

export type SubconEInvoiceClaimTransaction = AuditEntity & {
  __typename?: 'SubconEInvoiceClaimTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  claimDocNo?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  claimAmt?: Maybe<Scalars['Float']>;
  sequenceNo?: Maybe<Scalars['Float']>;
  revisedNo?: Maybe<Scalars['Float']>;
  submittedDate: Scalars['String'];
  voDisplayAmt?: Maybe<Scalars['Float']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  balanceAdvanceAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  balanceDeductionAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  claimVOLog?: Maybe<Array<Scalars['JSON']>>;
  isRetention: Scalars['Boolean'];
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractID: Scalars['String'];
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
};

export type SubconItemTaxCoreTransaction = AuditEntity & {
  __typename?: 'SubconItemTaxCoreTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  subcontractID: Scalars['String'];
};

export type SubconRfqSubmissionEntity = AuditEntity & {
  __typename?: 'SubconRFQSubmissionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  subcontractTenderID: Scalars['String'];
  subcontractTender?: Maybe<SubcontractTenderEntity>;
  subcontractorID: Scalars['String'];
  subcontractor?: Maybe<SubcontractorEntity>;
  submissionDate: Scalars['String'];
  quotationNo: Scalars['String'];
  quotationAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
};

export type SubconRfqSubmissionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  subcontractorInvitationID?: Maybe<Scalars['String']>;
  submissionDate?: Maybe<Scalars['String']>;
  quotationAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractTenderID?: Maybe<Scalars['String']>;
  quotationNo?: Maybe<Scalars['String']>;
};

export type SubconTenderGenerationInput = {
  subcontractorID: Scalars['String'];
  subcontractDate: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  taxSchemeID: Scalars['String'];
  creditTerm: Scalars['Float'];
  subcontractSum: Scalars['Float'];
  progressRetentionPerc: Scalars['Float'];
  maxRetentionPerc: Scalars['Float'];
  file?: Maybe<Array<Scalars['Upload']>>;
};

export enum SubconTenderStatus {
  Active = 'ACTIVE',
  Approved = 'APPROVED',
  Closed = 'CLOSED'
}

export type SubconVoDetailsInput = {
  ID?: Maybe<Scalars['String']>;
  costCategoryID: Scalars['String'];
  wbsID: Scalars['String'];
  submissionAmt: Scalars['Float'];
  subcontractCostWbsID: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
};

export type SubcontractActionInput = {
  closureRemarks?: Maybe<Scalars['String']>;
  closureDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractStatus: ContractStatus;
};

export type SubcontractAdvanceEntity = AuditEntity & {
  __typename?: 'SubcontractAdvanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
  isRecoupment: Scalars['Boolean'];
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontractor?: Maybe<SubcontractorEntity>;
  subcontract?: Maybe<SubcontractEntity>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type SubcontractAdvanceEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type SubcontractAllocationEntity = AuditEntity & {
  __typename?: 'SubcontractAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  allocationStatus: AllocationStatus;
  nonEditable: Scalars['Boolean'];
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
};

export type SubcontractApproveRejectInput = {
  subcontractStatus: ContractStatus;
  contractID: Scalars['String'];
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
};

export type SubcontractCnEntity = AuditEntity & {
  __typename?: 'SubcontractCNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  subcontractCNItem?: Maybe<Array<SubcontractCnItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  transactionType?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type SubcontractCnEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type SubcontractCnItemEntity = AuditEntity & {
  __typename?: 'SubcontractCNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID: Scalars['String'];
  subcontractCNID: Scalars['String'];
  subcontractCN?: Maybe<SubcontractCnEntity>;
  costItem?: Maybe<CostItemEntity>;
  wbs?: Maybe<WbsEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  subcontractCNTaxItem?: Maybe<Array<SubcontractCnTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type SubcontractCnItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type SubcontractCnTaxItemEntity = AuditEntity & {
  __typename?: 'SubcontractCNTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  subcontractID: Scalars['String'];
  subcontractCNItemID: Scalars['String'];
  subcontractCNItem?: Maybe<SubcontractCnItemEntity>;
};

export type SubcontractCertificateInput = {
  cmgdDate?: Maybe<Scalars['String']>;
  cpcDate?: Maybe<Scalars['String']>;
  dlpDate?: Maybe<Scalars['String']>;
  currentSubmittedStatus?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type SubcontractClaimAllocationEntity = AuditEntity & {
  __typename?: 'SubcontractClaimAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  allocationStatus: AllocationStatus;
  subcontractClaimID: Scalars['String'];
  subcontractClaim?: Maybe<SubcontractClaimEntity>;
  subcontractCostWbsID: Scalars['String'];
  subcontractCostWbs?: Maybe<SubcontractCostWbsEntity>;
  classificationCodeID?: Maybe<Scalars['String']>;
};

export type SubcontractClaimEntity = AuditEntity & {
  __typename?: 'SubcontractClaimEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  claimDocNo?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  claimAmt?: Maybe<Scalars['Float']>;
  sequenceNo?: Maybe<Scalars['Float']>;
  revisedNo?: Maybe<Scalars['Float']>;
  submittedDate: Scalars['String'];
  voDisplayAmt?: Maybe<Scalars['Float']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  balanceAdvanceAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  balanceDeductionAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  claimVOLog?: Maybe<Array<Scalars['JSON']>>;
  isRetention: Scalars['Boolean'];
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractID: Scalars['String'];
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  contract?: Maybe<ContractEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  /** CustomFieldResolver */
  revenueAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  outstandingCertifiedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  subcontractClaimAllocation?: Maybe<Array<SubcontractClaimAllocationEntity>>;
  subcontractClaimVOCostWbsAllocation?: Maybe<Array<SubcontractClaimVoCostWbsAllocationEntity>>;
  subcontractClaimVOAllocation?: Maybe<Array<SubcontractClaimVoAllocationEntity>>;
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  claimDetailCostWBS?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  transactionType?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  claimWithDeduction?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  nonEditableAllocation?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  outstandingClaimCertified?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  grossAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  adjustmentInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type SubcontractClaimEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};


export type SubcontractClaimEntityNonEditableAllocationArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type SubcontractClaimInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  remarks?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['String']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentAdvBalance?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  currentDeductionBalance?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  voDisplayAmt?: Maybe<Scalars['Float']>;
  subcontractorID: Scalars['String'];
  subcontractID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
};

export type SubcontractClaimVoAllocationEntity = AuditEntity & {
  __typename?: 'SubcontractClaimVOAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  allocationStatus: AllocationStatus;
  subcontractClaimID: Scalars['String'];
  subcontractClaim?: Maybe<SubcontractClaimEntity>;
  subcontractVOID: Scalars['String'];
  subcontractVO?: Maybe<SubcontractVoEntity>;
};

export type SubcontractClaimVoCostWbsAllocationEntity = AuditEntity & {
  __typename?: 'SubcontractClaimVOCostWbsAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  allocationStatus: AllocationStatus;
  subcontractClaimID: Scalars['String'];
  subcontractClaim?: Maybe<SubcontractClaimEntity>;
  subcontractVOCostWbsID: Scalars['String'];
  subcontractVOCostWbs?: Maybe<SubcontractVoCostWbsEntity>;
};

export type SubcontractCostWbsEntity = AuditEntity & {
  __typename?: 'SubcontractCostWbsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  subcontractID: Scalars['String'];
  /** CustomFieldResolver */
  claimToDateWithTrade?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  costCategoryID: Scalars['String'];
  costCategory?: Maybe<CostCategoryEntity>;
  wbsID?: Maybe<Scalars['String']>;
  wbs?: Maybe<WbsEntity>;
  taxSchemeID: Scalars['String'];
  taxDetail?: Maybe<ConTaxSchemeEntity>;
  tradeAmt: Scalars['Float'];
  subcontractVOCostWbs?: Maybe<Array<SubcontractVoCostWbsEntity>>;
  subcontractClaimAllocation?: Maybe<Array<SubcontractClaimAllocationEntity>>;
  /** CustomFieldResolver */
  previousAmt?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  revisedTradeAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  tradeVoAmt?: Maybe<Scalars['Float']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type SubcontractCostWbsEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type SubcontractCostWbsInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  costCategoryID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  tradeAmt: Scalars['Float'];
  wbsID?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
};

export type SubcontractDnEntity = AuditEntity & {
  __typename?: 'SubcontractDNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  eInvoiceDocRefInvoiceID?: Maybe<Scalars['String']>;
  subcontractDNItem?: Maybe<Array<SubcontractDnItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  transactionType?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type SubcontractDnEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type SubcontractDnItemEntity = AuditEntity & {
  __typename?: 'SubcontractDNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID: Scalars['String'];
  subcontractDNID: Scalars['String'];
  subcontractDN?: Maybe<SubcontractDnEntity>;
  costItem?: Maybe<CostItemEntity>;
  wbs?: Maybe<WbsEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  subcontractDNTaxItem?: Maybe<Array<SubcontractDnTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type SubcontractDnItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type SubcontractDnTaxItemEntity = AuditEntity & {
  __typename?: 'SubcontractDNTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  subcontractID: Scalars['String'];
  subcontractDNItemID: Scalars['String'];
  subcontractDNItem?: Maybe<SubcontractDnItemEntity>;
};

export type SubcontractEntity = AuditEntity & {
  __typename?: 'SubcontractEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  progressRetentionPerc: Scalars['Float'];
  maxRetentionPerc: Scalars['Float'];
  ladAmt?: Maybe<Scalars['Float']>;
  ladRate?: Maybe<RateUomType>;
  targetProfitPerc?: Maybe<Scalars['Float']>;
  cmgdDate?: Maybe<Scalars['String']>;
  cpcDate?: Maybe<Scalars['String']>;
  dlpDate?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
  extendedDate?: Maybe<Scalars['String']>;
  extensionDate?: Maybe<Scalars['JSON']>;
  closureDate?: Maybe<Scalars['String']>;
  closureRemarks?: Maybe<Scalars['String']>;
  completionPerc?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Array<Scalars['JSON']>>;
  contractID: Scalars['String'];
  mainContractDetail?: Maybe<ContractEntity>;
  subcontractorID: Scalars['String'];
  subcontractorDetail?: Maybe<SubcontractorEntity>;
  costCategoryID?: Maybe<Scalars['String']>;
  tradeDetail?: Maybe<CostCategoryEntity>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxDetail?: Maybe<ConTaxSchemeEntity>;
  siteID?: Maybe<Scalars['String']>;
  site?: Maybe<SiteEntity>;
  subcontractTenderID?: Maybe<Scalars['String']>;
  subcontractTender?: Maybe<SubcontractTenderEntity>;
  subcontractNo: Scalars['String'];
  subcontractDate: Scalars['String'];
  subcontractSum: Scalars['Float'];
  nominatedSC: Scalars['Boolean'];
  multipleTrade: Scalars['Boolean'];
  submissionComment?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  maxRetentionSum?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  claimToDate?: Maybe<Scalars['Float']>;
  bankDetails?: Maybe<Scalars['JSON']>;
  subcontractStatus: ContractStatus;
  subcontractCostWbs?: Maybe<Array<SubcontractCostWbsEntity>>;
  purchaseOrder?: Maybe<Array<PurchaseOrderEntity>>;
  VO?: Maybe<Array<SubcontractVoEntity>>;
  purchaseReq?: Maybe<Array<PurchaseReqEntity>>;
  subcontractClaim?: Maybe<Array<SubcontractClaimEntity>>;
  subcontractMiscInvoice?: Maybe<Array<SubcontractMiscInvoiceEntity>>;
  subcontractDN?: Maybe<Array<SubcontractDnEntity>>;
  subcontractCN?: Maybe<Array<SubcontractCnEntity>>;
  dnToSubcon?: Maybe<Array<DNtoSubconEntity>>;
  cnToSubcon?: Maybe<Array<CNtoSubconEntity>>;
  payment?: Maybe<Array<SubcontractPaymentEntity>>;
  advance?: Maybe<Array<SubcontractAdvanceEntity>>;
  refund?: Maybe<Array<SubcontractRefundEntity>>;
  /** CustomFieldResolver */
  latestRunNum?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  docNumChecking?: Maybe<Scalars['JSON']>;
  contact?: Maybe<Array<ContactEntity>>;
  /** CustomFieldResolver */
  subcontractVOSum?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  revisedSubcontractSum?: Maybe<Scalars['Float']>;
  trade: Array<CostCategoryEntity>;
};

export type SubcontractExtensionInput = {
  extensionDate?: Maybe<ExtensionDateInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type SubcontractGuaranteeCancellationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  remarks?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractID?: Maybe<Scalars['String']>;
};

export type SubcontractGuaranteeEntity = AuditEntity & {
  __typename?: 'SubcontractGuaranteeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  guaranteeTypeID: Scalars['String'];
  collateralAmt: Scalars['Float'];
  guaranteeStatus: GrntInsStatus;
  subcontractID: Scalars['String'];
  guaranteeType?: Maybe<GuaranteeTypeEntity>;
};

export type SubcontractGuaranteeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  issuer: Scalars['String'];
  description: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractID: Scalars['String'];
  guaranteeTypeID: Scalars['String'];
  collateralAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  guaranteeStatus?: Maybe<GrntInsStatus>;
};

export type SubcontractGuaranteeInsuranceDeleteInput = {
  ID: Scalars['String'];
};

export type SubcontractInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  title: Scalars['String'];
  description: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  progressRetentionPerc: Scalars['Float'];
  maxRetentionPerc: Scalars['Float'];
  creditTerm?: Maybe<Scalars['Float']>;
  ladAmt?: Maybe<Scalars['Float']>;
  ladRate?: Maybe<RateUomType>;
  claimRunNum?: Maybe<Scalars['Float']>;
  claimPrefix?: Maybe<Scalars['String']>;
  voRunNum?: Maybe<Scalars['Float']>;
  voPrefix?: Maybe<Scalars['String']>;
  targetProfitPerc?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Array<ContactPersonInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractNo: Scalars['String'];
  subcontractDate: Scalars['String'];
  nominatedSC: Scalars['Boolean'];
  siteID?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
  modTs?: Maybe<Scalars['DateTime']>;
  subcontractSum?: Maybe<Scalars['Float']>;
  costCategoryID?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  subcontractStatus?: Maybe<ContractStatus>;
  LADFile?: Maybe<Array<Scalars['Upload']>>;
  SubconTradeFile?: Maybe<Array<Scalars['Upload']>>;
};

export type SubcontractInsuranceCancellationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  remarks?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractID?: Maybe<Scalars['String']>;
};

export type SubcontractInsuranceEntity = AuditEntity & {
  __typename?: 'SubcontractInsuranceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  insuranceTypeID: Scalars['String'];
  insuranceStatus: GrntInsStatus;
  subcontractID: Scalars['String'];
  insuranceType?: Maybe<InsuranceTypeEntity>;
};

export type SubcontractInsuranceInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  issuer: Scalars['String'];
  description: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractID: Scalars['String'];
  insuranceTypeID: Scalars['String'];
  insuranceStatus?: Maybe<GrntInsStatus>;
  remarks?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
};

export type SubcontractMiscInvoiceEntity = AuditEntity & {
  __typename?: 'SubcontractMiscInvoiceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractID: Scalars['String'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  subcontractMiscInvoiceItem?: Maybe<Array<SubcontractMiscInvoiceItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  transactionType?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
  /** CustomFieldResolver */
  isDaywork?: Maybe<Scalars['Boolean']>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type SubcontractMiscInvoiceEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type SubcontractMiscInvoiceItemEntity = AuditEntity & {
  __typename?: 'SubcontractMiscInvoiceItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  costItemID: Scalars['String'];
  subcontractMiscInvoiceID: Scalars['String'];
  subcontractMiscInvoice?: Maybe<SubcontractMiscInvoiceEntity>;
  wbs?: Maybe<WbsEntity>;
  costItem?: Maybe<CostItemEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  subcontractMiscInvoiceTaxItem?: Maybe<Array<SubcontractMiscInvoiceTaxItemEntity>>;
  /** CustomFieldResolver */
  costCategoryID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  costClass?: Maybe<Scalars['String']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type SubcontractMiscInvoiceItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type SubcontractMiscInvoiceTaxItemEntity = AuditEntity & {
  __typename?: 'SubcontractMiscInvoiceTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  contractID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmount: Scalars['Float'];
  trxDate?: Maybe<Scalars['DateTime']>;
  subcontractID: Scalars['String'];
  subcontractMiscInvoiceItemID: Scalars['String'];
  subcontractMiscInvoiceItem?: Maybe<SubcontractMiscInvoiceItemEntity>;
};

export type SubcontractPaymentEntity = AuditEntity & {
  __typename?: 'SubcontractPaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
  paymentTo: Scalars['String'];
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type SubcontractPaymentEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type SubcontractPaymentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt: Scalars['Float'];
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  bankAccountID: Scalars['String'];
  receiptStatus: ReceiptStatus;
  rejectedDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<BankDetailsInput>;
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  paymentTo?: Maybe<Scalars['String']>;
};

export type SubcontractRefundEntity = AuditEntity & {
  __typename?: 'SubcontractRefundEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type SubcontractRefundEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type SubcontractRetentionAllocationEntity = AuditEntity & {
  __typename?: 'SubcontractRetentionAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  allocationStatus: AllocationStatus;
};

export type SubcontractRetentionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  submittedDate: Scalars['String'];
  retentionAmt: Scalars['Float'];
  taxSchemeID: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractID: Scalars['String'];
  subcontractorID: Scalars['String'];
};

export type SubcontractTenderEntity = AuditEntity & {
  __typename?: 'SubcontractTenderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  siteID: Scalars['String'];
  costCategoryID: Scalars['String'];
  subcontractID?: Maybe<Scalars['String']>;
  refNo: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  budgetAmt: Scalars['Float'];
  tenderDate: Scalars['String'];
  startDate: Scalars['String'];
  startTime: Scalars['String'];
  closeDate: Scalars['String'];
  endTime: Scalars['String'];
  subconTenderStatus: SubconTenderStatus;
  costCategory: CostCategoryEntity;
  subconInvitation?: Maybe<Array<SubcontractorInvitationEntity>>;
  mainContractDetail?: Maybe<ContractEntity>;
  subconRfqSubmission?: Maybe<Array<SubconRfqSubmissionEntity>>;
  subcontract?: Maybe<Array<SubcontractEntity>>;
};

export type SubcontractTenderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  refNo?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  budgetAmt?: Maybe<Scalars['Float']>;
  tenderDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  closeDate?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type SubcontractTransactionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  subcontractID: Scalars['String'];
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
};

export type SubcontractTransactionWithGlItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  subcontractID: Scalars['String'];
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  chargeToRevenue?: Maybe<Scalars['Boolean']>;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  isEInvoice?: Maybe<Scalars['Boolean']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  eInvoiceDocRefInvoiceID?: Maybe<Scalars['String']>;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  glItem?: Maybe<Array<GlItemInput>>;
};

export type SubcontractVo = {
  __typename?: 'SubcontractVO';
  voSum?: Maybe<Scalars['Float']>;
  voOmission?: Maybe<Scalars['Float']>;
  voSumCount?: Maybe<Scalars['Float']>;
  voOmissionCount?: Maybe<Scalars['Float']>;
  totalVoAmt?: Maybe<Scalars['Float']>;
  voSubconCount?: Maybe<Scalars['Float']>;
};

export type SubcontractVoActionInput = {
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  docDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  VOStatus: VoStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type SubcontractVoCostWbsEntity = AuditEntity & {
  __typename?: 'SubcontractVOCostWbsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  subcontractVOID: Scalars['String'];
  subcontractVO?: Maybe<SubcontractVoEntity>;
  subcontractCostWbsID: Scalars['String'];
  subcontractCostWbs?: Maybe<SubcontractCostWbsEntity>;
  submissionAmt?: Maybe<Scalars['Float']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  subcontractClaimVOCostWbsAllocation?: Maybe<Array<SubcontractClaimVoCostWbsAllocationEntity>>;
  /** CustomFieldResolver */
  previousTradeVoAmt?: Maybe<Scalars['JSON']>;
};

export type SubcontractVoDeleteInput = {
  ID?: Maybe<Scalars['String']>;
};

export type SubcontractVoEntity = AuditEntity & {
  __typename?: 'SubcontractVOEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef: Scalars['String'];
  rejectedDate?: Maybe<Scalars['String']>;
  submittedDate: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  architectNo: Scalars['String'];
  engineeringNo: Scalars['String'];
  VOType: VoType;
  VOStatus: VoStatus;
  submissionAmt?: Maybe<Scalars['Float']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
  subcontract?: Maybe<SubcontractEntity>;
  subcontractVOCostWbs?: Maybe<Array<SubcontractVoCostWbsEntity>>;
  subcontractClaimVOAllocation?: Maybe<Array<SubcontractClaimVoAllocationEntity>>;
  toDo: Array<ContractToDoEntity>;
  /** CustomFieldResolver */
  subcontractClaimVOAlloc?: Maybe<Scalars['JSON']>;
};

export type SubcontractVoInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  description: Scalars['String'];
  architectNo: Scalars['String'];
  engineeringNo: Scalars['String'];
  submissionAmt: Scalars['Float'];
  submittedDate: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  VOType?: Maybe<VoType>;
  VOStatus: VoStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractID: Scalars['String'];
  contractID: Scalars['String'];
};

export type SubcontractWbsDeleteInput = {
  ID: Scalars['String'];
};

export type SubcontractWbsInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  subcontractID: Scalars['String'];
  costCategoryID: Scalars['String'];
  wbsID: Scalars['String'];
  tradeAmt: Scalars['Float'];
  submissionComment: Scalars['String'];
  submissionDate: Scalars['String'];
  taxSchemeID: Scalars['String'];
};

export type SubcontractorCCategoryEntity = AuditEntity & {
  __typename?: 'SubcontractorCCategoryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
  subcontractorID: Scalars['String'];
  subcontractor?: Maybe<SubcontractorEntity>;
  costCategoryID: Scalars['String'];
  costCategory: CostCategoryEntity;
  subconPackagesData: Array<Scalars['JSON']>;
};

export type SubcontractorCCategoryInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  subcontractorID?: Maybe<Scalars['String']>;
  costCategoryID: Scalars['String'];
};

export type SubcontractorCompanyAssignmentEntity = AuditEntity & {
  __typename?: 'SubcontractorCompanyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  subcontractorID: Scalars['String'];
  subcontractor?: Maybe<SubcontractorEntity>;
};

export type SubcontractorCompanyAssignmentInput = {
  subcontractorID: Scalars['String'];
  companyID: Scalars['String'];
};

export type SubcontractorDeleteId = {
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};

export type SubcontractorEntity = AuditEntity & {
  __typename?: 'SubcontractorEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  tinNo?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  address: Scalars['JSON'];
  platformAccess: Scalars['Boolean'];
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  associatedStatus: AssociatedStatus;
  eInvIntegrated: Scalars['Boolean'];
  eInvoiceType: EInvoiceType;
  taxDetail?: Maybe<ConTaxSchemeEntity>;
  subcontractorTypeID?: Maybe<Scalars['String']>;
  subcontractorType?: Maybe<SubcontractorTypeEntity>;
  nominatedSC: Scalars['Boolean'];
  paymentTo: Scalars['String'];
  creditorAC?: Maybe<Scalars['String']>;
  statusRemarks?: Maybe<Scalars['String']>;
  ratingType: RatingType;
  cidbDetails: Scalars['JSON'];
  pkkDetails?: Maybe<Scalars['JSON']>;
  bankDetails?: Maybe<Scalars['JSON']>;
  contactPerson: Array<ContactEntity>;
  subcontractorPackage?: Maybe<Array<SubcontractorCCategoryEntity>>;
  subcontract?: Maybe<Array<SubcontractEntity>>;
  subconClaim: Array<SubcontractClaimEntity>;
  subconAdvance?: Maybe<Array<SubcontractAdvanceEntity>>;
  defect: Array<DefectManagementEntity>;
  subconInvitation?: Maybe<Array<SubcontractorInvitationEntity>>;
  subconRfqSubmission?: Maybe<Array<SubconRfqSubmissionEntity>>;
  defectAssignment?: Maybe<Array<DefectAssignmentEntity>>;
  subcontractClaim?: Maybe<Array<SubcontractClaimEntity>>;
  subcontractorMiscInvoice?: Maybe<Array<SubcontractMiscInvoiceEntity>>;
  subcontractorDN?: Maybe<Array<SubcontractDnEntity>>;
  subcontractorCN?: Maybe<Array<SubcontractCnEntity>>;
  dnToSubcontractor?: Maybe<Array<DNtoSubconEntity>>;
  cnToSubcontractor?: Maybe<Array<CNtoSubconEntity>>;
  payment?: Maybe<Array<SubcontractPaymentEntity>>;
  refund?: Maybe<Array<SubcontractRefundEntity>>;
  subcontractorCompanyAssignment?: Maybe<Array<SubcontractorCompanyAssignmentEntity>>;
  isSelfBilled: Scalars['Boolean'];
  msicCodeID?: Maybe<Scalars['String']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  selfBilledCostCategoryID?: Maybe<Scalars['String']>;
  selfBilledCostCategory?: Maybe<CostCategoryEntity>;
  /** CustomFieldResolver */
  consubconDropdown?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  overdueInvInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  overdueInvInfoNoBound?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  overdueAdvInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  activeSubcontract?: Maybe<Scalars['JSON']>;
  classificationCode?: Maybe<EInvoiceClassificationEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
};


export type SubcontractorEntityOverdueInvInfoNoBoundArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type SubcontractorEntityOverdueAdvInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type SubcontractorEntityActiveSubcontractArgs = {
  companyID?: Maybe<Scalars['String']>;
};

export type SubcontractorHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  subcontractorName?: Maybe<Scalars['String']>;
  regNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  emailAddr?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
};

export type SubcontractorImportInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  tinNo?: Maybe<Scalars['String']>;
  eInvIntegrated?: Maybe<Scalars['Boolean']>;
  eInvoiceType?: Maybe<EInvoiceType>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressInput>;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<Array<ContactInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
  attachment?: Maybe<Array<Scalars['Upload']>>;
  nominatedSC: Scalars['Boolean'];
  eftPayment: Scalars['Boolean'];
  paymentTo?: Maybe<Scalars['String']>;
  tax?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  subcontractTrade?: Maybe<Scalars['String']>;
  contactName: Scalars['String'];
  contactDesignation?: Maybe<Scalars['String']>;
  contactEmail: Scalars['String'];
  contactMobileNo: Scalars['String'];
  subconType?: Maybe<Scalars['String']>;
  ratingType?: Maybe<RatingType>;
  cidbDetails: CidbDetailsInput;
  pkkDetails?: Maybe<PkkDetailsInput>;
  bankDetails?: Maybe<BankDetailsInput>;
};

export type SubcontractorInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  tinNo?: Maybe<Scalars['String']>;
  eInvIntegrated?: Maybe<Scalars['Boolean']>;
  eInvoiceType?: Maybe<EInvoiceType>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<Array<ContactInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
  attachment?: Maybe<Array<Scalars['Upload']>>;
  ratingType: RatingType;
  cidbDetails: CidbDetailsInput;
  paymentTo?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Array<Scalars['String']>>;
  pkkDetails?: Maybe<PkkDetailsInput>;
  statusRemarks?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<BankDetailsInput>;
  nominatedSC: Scalars['Boolean'];
  creditorAC?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  subcontractorTypeID?: Maybe<Scalars['String']>;
  isSelfBilled?: Maybe<Scalars['Boolean']>;
  msicCodeID?: Maybe<Scalars['String']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  selfBilledCostCategoryID?: Maybe<Scalars['String']>;
};

export type SubcontractorInvitationEntity = AuditEntity & {
  __typename?: 'SubcontractorInvitationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  subcontractorID: Scalars['String'];
  subcontractTenderID: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  subconTender?: Maybe<SubcontractTenderEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  /** CustomFieldResolver */
  RFQInfo: Scalars['JSON'];
};

export type SubcontractorInvitationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  subcontractTenderID: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type SubcontractorSubconAccPayment = AuditEntity & {
  __typename?: 'SubcontractorSubconAccPayment';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
};

export type SubcontractorTypeDeleteInput = {
  ID: Scalars['String'];
};

export type SubcontractorTypeEntity = AuditEntity & {
  __typename?: 'SubcontractorTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  description: Scalars['String'];
  glAccountCode?: Maybe<Scalars['String']>;
  accrualAccountCode?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  subcontractor?: Maybe<Array<SubcontractorEntity>>;
};

export type SubcontractorTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  description?: Maybe<Scalars['String']>;
  glAccountCode?: Maybe<Scalars['String']>;
  accrualAccountCode?: Maybe<Scalars['String']>;
};

export type SubontractInfo = {
  __typename?: 'SubontractInfo';
  subcontractSum?: Maybe<Scalars['Float']>;
  subcontractDate?: Maybe<Scalars['DateTime']>;
};

export type SubscriptionCompanyType = {
  __typename?: 'SubscriptionCompanyType';
  CompanyID?: Maybe<Scalars['String']>;
  ParentCompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  BaseCurrencyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  SSTNo?: Maybe<Scalars['String']>;
  GSTNo?: Maybe<Scalars['String']>;
  GSTExpiryDate?: Maybe<Scalars['String']>;
  Image?: Maybe<Scalars['String']>;
  ImageUrl?: Maybe<Scalars['String']>;
  ImageBucketFile?: Maybe<Scalars['String']>;
  WebsiteUrl?: Maybe<Scalars['String']>;
  RegionID?: Maybe<Scalars['String']>;
  ImageLogoPosition?: Maybe<Scalars['String']>;
  WatermarkImplementation?: Maybe<Scalars['Boolean']>;
  WatermarkWording?: Maybe<Scalars['String']>;
  IsoImplementation?: Maybe<Scalars['Boolean']>;
  IsoLogoPosition?: Maybe<Scalars['String']>;
  CompanyTax?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<Scalars['String']>;
  Address?: Maybe<Address>;
  createdBy?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
  modTs?: Maybe<Scalars['String']>;
};

export type SubscriptionCurrencyType = {
  __typename?: 'SubscriptionCurrencyType';
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  symbol?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  modTs?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
};

export type SupplierAccPaymentEntity = AuditEntity & {
  __typename?: 'SupplierAccPaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
};

export type SupplierAccTransaction = AuditEntity & {
  __typename?: 'SupplierAccTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  supplierID: Scalars['String'];
  contractID: Scalars['String'];
};

export type SupplierAdjustmentTransaction = AuditEntity & {
  __typename?: 'SupplierAdjustmentTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  supplierID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
};

export type SupplierCCategoryEntity = AuditEntity & {
  __typename?: 'SupplierCCategoryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
  supplierID: Scalars['String'];
  costCategoryID: Scalars['String'];
  supplier?: Maybe<SupplierEntity>;
  costCategory?: Maybe<CostCategoryEntity>;
};

export type SupplierCCategoryInput = {
  supplierID: Scalars['String'];
  costCategoryID: Scalars['String'];
};

export type SupplierCompanyAssignmentEntity = AuditEntity & {
  __typename?: 'SupplierCompanyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  supplierID: Scalars['String'];
  supplier?: Maybe<SupplierEntity>;
};

export type SupplierCompanyAssignmentInput = {
  supplierID: Scalars['String'];
  companyID: Scalars['String'];
};

export type SupplierDeleteInput = {
  ID: Scalars['String'];
  companyID?: Maybe<Scalars['String']>;
};

export type SupplierEInvoiceAdjustmentTransaction = AuditEntity & {
  __typename?: 'SupplierEInvoiceAdjustmentTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  isEInvoice: Scalars['Boolean'];
  eInvoiceStatus?: Maybe<Scalars['String']>;
  eInvoiceSentDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedSendReason?: Maybe<Scalars['String']>;
  eInvoiceFailedValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceValidationDate?: Maybe<Scalars['DateTime']>;
  eInvoiceFailedValidationReason?: Maybe<Scalars['String']>;
  eInvoiceCancelledBy?: Maybe<Scalars['String']>;
  eInvoiceCancelledDate?: Maybe<Scalars['DateTime']>;
  eInvoiceCancelledReason?: Maybe<Scalars['String']>;
  eInvoiceRejectedRequestDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedConfirmBy?: Maybe<Scalars['String']>;
  eInvoiceRejectedConfirmDate?: Maybe<Scalars['DateTime']>;
  eInvoiceRejectedReason?: Maybe<Scalars['String']>;
  eInvoiceQrUrl?: Maybe<Scalars['String']>;
  eInvoiceIrbmUniqueID?: Maybe<Scalars['String']>;
  eInvoiceDirectSend?: Maybe<Scalars['Boolean']>;
  eInvoiceConsolUniqueID?: Maybe<Scalars['String']>;
  eInvoiceMsicCodeID?: Maybe<Scalars['String']>;
  eInvoiceSelfBilled?: Maybe<Scalars['Boolean']>;
  supplierID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
};

export type SupplierEntity = AuditEntity & {
  __typename?: 'SupplierEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  tinNo?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  address: Scalars['JSON'];
  platformAccess: Scalars['Boolean'];
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  associatedStatus: AssociatedStatus;
  eInvIntegrated: Scalars['Boolean'];
  eInvoiceType: EInvoiceType;
  taxDetail?: Maybe<ConTaxSchemeEntity>;
  supplierTypeID?: Maybe<Scalars['String']>;
  supplierType?: Maybe<SupplierTypeEntity>;
  creditorAC?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<Scalars['JSON']>;
  ratingType: RatingType;
  costConnection?: Maybe<Array<SupplierCCategoryEntity>>;
  costItemConnection?: Maybe<Array<PricingSupplierEntity>>;
  purchaseOrder?: Maybe<Array<PurchaseOrderEntity>>;
  SupplierProduct?: Maybe<Array<SupplierCCategoryEntity>>;
  supplierItems?: Maybe<Array<PricingSupplierEntity>>;
  deliveryOrder?: Maybe<Array<DoEntity>>;
  negotiatedSupplyItem?: Maybe<Array<NegotiatedSupplyEntity>>;
  plantMachinery?: Maybe<Array<PlantMachineryEntity>>;
  contactPerson: Array<ContactEntity>;
  rfqSubmission: Array<RfqSupplierSubmissionEntity>;
  invitedRFQ: Array<RfqSupplierInvitationEntity>;
  supplierInv?: Maybe<Array<ApInvoiceEntity>>;
  supplierDN?: Maybe<Array<ApDebitNoteEntity>>;
  supplierCN?: Maybe<Array<ApCreditNoteEntity>>;
  supplierDNS?: Maybe<Array<DNtoSupplierEntity>>;
  cnToSupplier?: Maybe<Array<CNtoSupplierEntity>>;
  supplierAdvance?: Maybe<Array<ApPaymentEntity>>;
  supplierPayment?: Maybe<Array<ApPaymentEntity>>;
  supplierRefund?: Maybe<Array<ApRefundEntity>>;
  pnmRental?: Maybe<Array<PnmRentalEntity>>;
  goodReturnNote?: Maybe<Array<GoodReturnNoteEntity>>;
  supplierCompanyAssignment?: Maybe<Array<SupplierCompanyAssignmentEntity>>;
  isSelfBilled: Scalars['Boolean'];
  msicCodeID?: Maybe<Scalars['String']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  selfBilledCostCategoryID?: Maybe<Scalars['String']>;
  selfBilledCostCategory?: Maybe<CostCategoryEntity>;
  costCategory: Array<CostCategoryEntity>;
  /** CustomFieldResolver */
  overdueInvInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  outstandingInfo?: Maybe<Scalars['JSON']>;
  classificationCode?: Maybe<EInvoiceClassificationEntity>;
  msicCode?: Maybe<EInvoiceMsicEntity>;
  latestSubmittedQuotation?: Maybe<RfqSupplierSubmissionEntity>;
  rfq: Array<RfqEntity>;
  /** CustomFieldResolver */
  indelibleAccount?: Maybe<Scalars['Boolean']>;
};


export type SupplierEntityRfqSubmissionArgs = {
  rfqID?: Maybe<Scalars['String']>;
};


export type SupplierEntityOverdueInvInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type SupplierEntityOutstandingInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type SupplierEntityLatestSubmittedQuotationArgs = {
  rfqID?: Maybe<Scalars['String']>;
};

export type SupplierImportInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  tinNo?: Maybe<Scalars['String']>;
  eInvIntegrated?: Maybe<Scalars['Boolean']>;
  eInvoiceType?: Maybe<EInvoiceType>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<Array<ContactInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
  attachment?: Maybe<Array<Scalars['Upload']>>;
  eftPayment: Scalars['Boolean'];
  contactName: Scalars['String'];
  contactDesignation?: Maybe<Scalars['String']>;
  contactEmail: Scalars['String'];
  contactMobileNo: Scalars['String'];
  suppType?: Maybe<Scalars['String']>;
  tax?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  ratingType?: Maybe<RatingType>;
  bankDetails?: Maybe<BankDetailsInput>;
};

export type SupplierInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  tinNo?: Maybe<Scalars['String']>;
  eInvIntegrated?: Maybe<Scalars['Boolean']>;
  eInvoiceType?: Maybe<EInvoiceType>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<Array<ContactInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
  attachment?: Maybe<Array<Scalars['Upload']>>;
  bankDetails?: Maybe<BankDetailsInput>;
  ratingType: RatingType;
  taxSchemeID: Scalars['String'];
  associateID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  supplierTypeID?: Maybe<Scalars['String']>;
  isSelfBilled?: Maybe<Scalars['Boolean']>;
  msicCodeID?: Maybe<Scalars['String']>;
  classificationCodeID?: Maybe<Scalars['String']>;
  selfBilledCostCategoryID?: Maybe<Scalars['String']>;
};

export type SupplierTypeDeleteInput = {
  ID: Scalars['String'];
};

export type SupplierTypeEntity = AuditEntity & {
  __typename?: 'SupplierTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  description: Scalars['String'];
  glAccountCode?: Maybe<Scalars['String']>;
  accrualAccountCode?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  supplier?: Maybe<Array<SupplierEntity>>;
};

export type SupplierTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  description?: Maybe<Scalars['String']>;
  glAccountCode?: Maybe<Scalars['String']>;
  accrualAccountCode?: Maybe<Scalars['String']>;
};

export type TankEntity = AuditEntity & {
  __typename?: 'TankEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  tankType: TankType;
  name: Scalars['String'];
  regNo: Scalars['String'];
  plantMachineryID?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  fuelConsumptionLowerLimit: Scalars['Float'];
  fuelConsumptionUpperLimit: Scalars['Float'];
  fuelConsumptionMethod: FuelConsumptionMethod;
  serialNo?: Maybe<Scalars['String']>;
  purchaseDate?: Maybe<Scalars['String']>;
  warrantyDate?: Maybe<Scalars['String']>;
  plantMachinery?: Maybe<PlantMachineryEntity>;
};

export type TankMachinery = PlantMachineryEntity | PnmRentalEntity;

export type TankObjectType = {
  __typename?: 'TankObjectType';
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  regNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
};

export enum TankType {
  NoTank = 'NO_TANK',
  FixedTank = 'FIXED_TANK',
  MobileTank = 'MOBILE_TANK'
}

export enum TaxCategory {
  Inclusive = 'INCLUSIVE',
  Exclusive = 'EXCLUSIVE'
}

export enum TaxClass {
  Input = 'INPUT',
  Output = 'OUTPUT'
}

export type TaxDetail = {
  id?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  accountId?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  effectiveDate: Scalars['DateTime'];
  rate: Scalars['Float'];
};

export type TaxDetails = {
  __typename?: 'TaxDetails';
  taxRefNo?: Maybe<Scalars['String']>;
  taxExpDate?: Maybe<Scalars['DateTime']>;
};

export type TaxDetailsInput = {
  taxRefNo?: Maybe<Scalars['String']>;
  taxExpDate?: Maybe<Scalars['DateTime']>;
};

export type TaxEffective = {
  __typename?: 'TaxEffective';
  ID?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  modBy?: Maybe<Scalars['String']>;
};

export type TaxEffectiveInput = {
  ID?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  modBy?: Maybe<Scalars['String']>;
};

export type TaxInput = {
  id?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  code: Scalars['String'];
  description: Scalars['String'];
  classType: Scalars['String'];
  isClaimable: Scalars['Boolean'];
  schemeTypeId: Scalars['String'];
  taxDetail: Array<TaxDetail>;
};

export type TaxListingOutput = {
  __typename?: 'TaxListingOutput';
  tax_id: Scalars['String'];
  code: Scalars['String'];
  description: Scalars['String'];
  class_type: Scalars['String'];
  tax_status: Scalars['String'];
  effective_date: Scalars['DateTime'];
  rate: Scalars['String'];
  scheme_type_id: Scalars['String'];
  createdTs: Scalars['DateTime'];
};

export type TaxObject = {
  __typename?: 'TaxObject';
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxDate?: Maybe<Scalars['DateTime']>;
};

export type TaxSchemeTypeListingOutput = {
  __typename?: 'TaxSchemeTypeListingOutput';
  id: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  modTs: Scalars['DateTime'];
};

export type TaxSchemeTypeSortByOutput = {
  __typename?: 'TaxSchemeTypeSortByOutput';
  id: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  modTs: Scalars['DateTime'];
  count: Scalars['Float'];
};

export type TestServiceInput = {
  ID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  docAmt?: Maybe<Scalars['Float']>;
  inputStatus?: Maybe<WorkFlowStatus>;
};

export enum ToDoStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Submit = 'SUBMIT',
  Cancelled = 'CANCELLED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Pending = 'PENDING',
  Closed = 'CLOSED'
}

export enum ToDoType {
  ArClaim = 'AR_CLAIM',
  ArCn = 'AR_CN',
  ArDn = 'AR_DN',
  ArMiscInv = 'AR_MISC_INV',
  ArPayment = 'AR_PAYMENT',
  ArReceipt = 'AR_RECEIPT',
  ApClaim = 'AP_CLAIM',
  ApCn = 'AP_CN',
  ApDn = 'AP_DN',
  ApMiscInv = 'AP_MISC_INV',
  ApPayment = 'AP_PAYMENT',
  ApReceipt = 'AP_RECEIPT',
  Po = 'PO',
  Pr = 'PR',
  Rfq = 'RFQ',
  Grn = 'GRN',
  Grtn = 'GRTN',
  Vo = 'VO',
  Svo = 'SVO',
  Subcontract = 'SUBCONTRACT',
  Df = 'DF',
  Budget = 'BUDGET',
  UtilizeBudget = 'UTILIZE_BUDGET',
  TransferBudget = 'TRANSFER_BUDGET',
  Default = 'DEFAULT',
  Rental = 'RENTAL',
  Assignment = 'ASSIGNMENT',
  Maintenance = 'MAINTENANCE'
}

export type TotpSecretTyes = {
  __typename?: 'TotpSecretTyes';
  is2FA?: Maybe<Scalars['Boolean']>;
  totpSecret?: Maybe<Scalars['String']>;
  dataURL?: Maybe<Scalars['String']>;
  otpURL?: Maybe<Scalars['String']>;
};

export type TrackingLog = {
  __typename?: 'TrackingLog';
  cost?: Maybe<Scalars['Float']>;
  returnQty?: Maybe<Scalars['Float']>;
  returnDate?: Maybe<Scalars['DateTime']>;
  returnRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['DateTime']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  trackingLogStatus?: Maybe<Scalars['String']>;
};

export type TrackingLogInput = {
  cost?: Maybe<Scalars['Float']>;
  returnQty?: Maybe<Scalars['Float']>;
  returnDate?: Maybe<Scalars['DateTime']>;
  returnRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['DateTime']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  trackingLogStatus?: Maybe<Scalars['String']>;
};

export enum TransactionStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Retention = 'RETENTION',
  Pending = 'PENDING',
  Pendingclose = 'PENDINGCLOSE'
}

export enum TransactionType {
  Advances = 'ADVANCES',
  AdvanceRecoupment = 'ADVANCE_RECOUPMENT',
  Claims = 'CLAIMS',
  ClaimsRetention = 'CLAIMS_RETENTION',
  ClaimsMos = 'CLAIMS_MOS',
  ClaimsDaywork = 'CLAIMS_DAYWORK',
  ClaimReversal = 'CLAIM_REVERSAL',
  RetentionRelease = 'RETENTION_RELEASE',
  DebitNote = 'DEBIT_NOTE',
  CreditNote = 'CREDIT_NOTE',
  Receipt = 'RECEIPT',
  Refunds = 'REFUNDS',
  ClientDebitNotes = 'CLIENT_DEBIT_NOTES'
}

export type TreeCostCategoryInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
  costItems?: Maybe<Array<CostItems>>;
  costClass?: Maybe<CostClass>;
  tankType?: Maybe<TankType>;
  pnmCostCat?: Maybe<PnmCostCatInput>;
  fuelConsumption?: Maybe<FuelConsumptionInput>;
  parentCostCategoryID?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['String']>;
  classificationCodeID?: Maybe<Scalars['String']>;
};

export enum TrxType {
  Grn = 'GRN',
  IssueOut = 'ISSUE_OUT',
  TransferOut = 'TRANSFER_OUT',
  TransferIn = 'TRANSFER_IN',
  Wastage = 'WASTAGE'
}

export type Uom = {
  __typename?: 'UOM';
  uomID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type UomDeleteInput = {
  ID: Scalars['String'];
};

export type UomEntity = AuditEntity & {
  __typename?: 'UOMEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  commonStatus: CommonStatus;
  poItem?: Maybe<Array<PoItemEntity>>;
  costItem?: Maybe<Array<CostItemEntity>>;
  prItem?: Maybe<Array<PrItemEntity>>;
  rfqItem?: Maybe<Array<RfqItemEntity>>;
  negoItem?: Maybe<Array<NegotiatedSupplyItemEntity>>;
  siteStockLedger?: Maybe<Array<SiteStockLedgerEntity>>;
  siteMaterial?: Maybe<Array<SiteMaterialEntity>>;
  stockMovement?: Maybe<Array<StockMovementEntity>>;
  grnItem?: Maybe<Array<GrnItemEntity>>;
  wbsBudgetDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  bq?: Maybe<Array<BqEntity>>;
};

export type UomImportInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  newUOM?: Maybe<Scalars['Boolean']>;
  newName?: Maybe<Scalars['String']>;
  newCode?: Maybe<Scalars['String']>;
};

export type UomInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type UomExchangeEntity = AuditEntity & {
  __typename?: 'UomExchangeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  fromUomID: Scalars['String'];
  toUomID: Scalars['String'];
  fromAmt: Scalars['Float'];
  toAmt: Scalars['Float'];
  exchangeRate: Scalars['Float'];
  uomLog?: Maybe<Array<Scalars['JSON']>>;
  fromUOM: UomEntity;
  toUOM: UomEntity;
};

export type UomExchangeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  fromUomID?: Maybe<Scalars['String']>;
  toUomID?: Maybe<Scalars['String']>;
  fromAmt?: Maybe<Scalars['Float']>;
  toAmt?: Maybe<Scalars['Float']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  uomLog?: Maybe<Array<ExchangeLogInput>>;
};

export type UpdateAuditInput = {
  modBy?: Maybe<Scalars['String']>;
};

export type UpdateGlExportInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  transactionDate: Scalars['String'];
};

export type UpdateProfitRecogFormulaInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  profitRecogFormulaID: Scalars['String'];
};

export type UpdateSubcontractorInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
  paymentTo?: Maybe<Scalars['String']>;
  registrationNo?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  nominatedSC: Scalars['Boolean'];
  ratingType?: Maybe<RatingType>;
  cidbDetails?: Maybe<CidbDetailsInput>;
  pkkDetails?: Maybe<PkkDetailsInput>;
  bankDetails?: Maybe<BankDetailsInput>;
  address?: Maybe<AddressInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type UpdateUserInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  contactNo: Scalars['String'];
  email: Scalars['String'];
  hashed?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  superUser?: Maybe<Scalars['Boolean']>;
  department?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  commonStatus?: Maybe<CommonStatus>;
  confirmedPassword: Scalars['String'];
};


export type UserCompanyAssignmentEntity = AuditEntity & {
  __typename?: 'UserCompanyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  userID: Scalars['String'];
};

export type UserCompanyAssignmentInput = {
  userID: Scalars['String'];
  companyID: Scalars['String'];
};

export type UserEntity = AuditEntity & {
  __typename?: 'UserEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  userName: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  is2FA?: Maybe<Scalars['Boolean']>;
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  softwareCode: Scalars['String'];
  loginAttempt: Scalars['Float'];
  blockDuration: Scalars['Float'];
  blockCount: Scalars['Float'];
  loginTs?: Maybe<Scalars['DateTime']>;
  deviceIP?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
};

export type UserInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  contactNo: Scalars['String'];
  email: Scalars['String'];
  hashed?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  superUser?: Maybe<Scalars['Boolean']>;
  department?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  commonStatus?: Maybe<CommonStatus>;
};

export type UserListInput = {
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UserResponse = AuditEntity & {
  __typename?: 'UserResponse';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  userName: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  confirmed: Scalars['Boolean'];
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  commonStatus: CommonStatus;
  password?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  deviceIP?: Maybe<Scalars['String']>;
  loginTs?: Maybe<Scalars['DateTime']>;
  blockDuration?: Maybe<Scalars['Float']>;
  blockCount?: Maybe<Scalars['Float']>;
  loginAttempt?: Maybe<Scalars['Float']>;
  is2FA?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
  errors?: Maybe<Array<FieldError>>;
};

export enum UserSelection {
  All = 'ALL',
  Superuser = 'SUPERUSER',
  Nonsuper = 'NONSUPER'
}

export type Vo = {
  __typename?: 'VO';
  voSum?: Maybe<Scalars['Float']>;
  voOmission?: Maybe<Scalars['Float']>;
  contractSum?: Maybe<Scalars['Float']>;
  revisedContractSum?: Maybe<Scalars['Float']>;
  totalVoAmt?: Maybe<Scalars['Float']>;
};

export type VoHealthIndex = {
  __typename?: 'VOHealthIndex';
  conVOOmission?: Maybe<Scalars['Float']>;
  conVOSum?: Maybe<Scalars['Float']>;
  conVOPendingOmission?: Maybe<Scalars['Float']>;
  conVOPendingSum?: Maybe<Scalars['Float']>;
  subVOOmission?: Maybe<Scalars['Float']>;
  subVOSum?: Maybe<Scalars['Float']>;
  subVOPendingOmission?: Maybe<Scalars['Float']>;
  subVOPendingSum?: Maybe<Scalars['Float']>;
  totalVO?: Maybe<Scalars['Float']>;
  totalPendingVO?: Maybe<Scalars['Float']>;
  totalSubVO?: Maybe<Scalars['Float']>;
  totalPendingSubVO?: Maybe<Scalars['Float']>;
  totalVOOmission?: Maybe<Scalars['Float']>;
  totalVOSum?: Maybe<Scalars['Float']>;
};

export type VoItemApprovedAmountInput = {
  ID?: Maybe<Scalars['String']>;
  approvedAmt?: Maybe<Scalars['Float']>;
};

export enum VoStatus {
  Active = 'ACTIVE',
  Approved = 'APPROVED',
  Cancel = 'CANCEL',
  Partial = 'PARTIAL',
  Submit = 'SUBMIT',
  Rejected = 'REJECTED',
  Close = 'CLOSE',
  Complete = 'COMPLETE',
  Pending = 'PENDING'
}

export enum VoType {
  Addition = 'ADDITION',
  Omission = 'OMISSION'
}

export type VideoCamDeleteInput = {
  ID: Scalars['String'];
};

export type VideoCamEntity = AuditEntity & {
  __typename?: 'VideoCamEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  name: Scalars['String'];
  networkConnection: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  site?: Maybe<SiteEntity>;
};

export type VideoCamInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  siteID: Scalars['String'];
  name: Scalars['String'];
  networkConnection: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type VoOmission = {
  __typename?: 'VoOmission';
  omission: Scalars['Float'];
};

export type VoSum = {
  __typename?: 'VoSUM';
  sum?: Maybe<Scalars['Float']>;
};

export type WbsAndBudgetExcelInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  WBSCode?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['String']>;
  Cost?: Maybe<Scalars['String']>;
  RevenueCategory?: Maybe<Scalars['String']>;
  CostItem?: Maybe<Scalars['String']>;
  CreditTerm?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  UOM?: Maybe<Scalars['String']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  RowNo?: Maybe<Scalars['Float']>;
  BudgetAmt?: Maybe<Scalars['Float']>;
  RevenueAmount?: Maybe<Scalars['Float']>;
};

export type WbsBudgetDetailInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  creditTerm: Scalars['Float'];
  budgetAmt: Scalars['Float'];
  wbsID: Scalars['String'];
  budgetID: Scalars['String'];
  budgetType: BudgetType;
  uomID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  revenueCategoryID?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
};

export type WbsBudgetTransferActionInput = {
  closureRemarks?: Maybe<Scalars['String']>;
  closureDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  budgetStatus: Scalars['String'];
  contractID: Scalars['String'];
};

export type WbsBudgetTransferDetailEntity = AuditEntity & {
  __typename?: 'WBSBudgetTransferDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  wbsBudgetTransferHeaderID: Scalars['String'];
  wbsBudgetTransferHeader: WbsBudgetTransferHeaderEntity;
  fromCostCategoryID?: Maybe<Scalars['String']>;
  fromCostCategory?: Maybe<CostCategoryEntity>;
  toCostCategoryID?: Maybe<Scalars['String']>;
  toCostCategory?: Maybe<CostCategoryEntity>;
  fromCostItemID?: Maybe<Scalars['String']>;
  fromCostItem?: Maybe<CostItemEntity>;
  toCostItemID?: Maybe<Scalars['String']>;
  toCostItem?: Maybe<CostItemEntity>;
  fromQty: Scalars['Float'];
  toQty: Scalars['Float'];
  transferQty: Scalars['Float'];
  transferAmt: Scalars['Float'];
  remarks: Scalars['String'];
};

export type WbsBudgetTransferEntity = AuditEntity & {
  __typename?: 'WBSBudgetTransferEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract: ContractEntity;
  fromWbsID: Scalars['String'];
  fromWbs: WbsEntity;
  toWbsID: Scalars['String'];
  toWbs: WbsEntity;
  fromCostCategoryID: Scalars['String'];
  fromCostCategory: CostCategoryEntity;
  toCostCategoryID: Scalars['String'];
  toCostCategory: CostCategoryEntity;
  transferDate: Scalars['String'];
  transferQty: Scalars['Float'];
  transferAmt: Scalars['Float'];
  remarks: Scalars['String'];
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  budgetStatus: BudgetStatus;
};

export type WbsBudgetTransferHeaderEntity = AuditEntity & {
  __typename?: 'WBSBudgetTransferHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  contract: ContractEntity;
  fromWbsID: Scalars['String'];
  fromWbs: WbsEntity;
  toWbsID: Scalars['String'];
  toWbs: WbsEntity;
  transferDate: Scalars['String'];
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  budgetStatus: BudgetStatus;
  wbsBudgetTransferDetail?: Maybe<Array<WbsBudgetTransferDetailEntity>>;
};

export type WbsDayworkInput = {
  ID?: Maybe<Scalars['String']>;
  wbsID: Scalars['String'];
  contractID: Scalars['String'];
  revenueCategoryID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
};

export type WbsEntity = AuditEntity & {
  __typename?: 'WBSEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  parentWBSID?: Maybe<Scalars['String']>;
  sequence: Scalars['Float'];
  name: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  isLastNode: Scalars['Boolean'];
  isDefault?: Maybe<Scalars['Boolean']>;
  projectExpenseItem?: Maybe<Array<ProjectExpenseItemEntity>>;
  prItem?: Maybe<Array<PrItemEntity>>;
  poItem?: Maybe<Array<PoItemEntity>>;
  wbsBudgetDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  wbsCostSubcontract?: Maybe<Array<SubcontractCostWbsEntity>>;
  wbsSchedule?: Maybe<Array<WbsScheduleEntity>>;
  wbsUtilizedBudget?: Maybe<Array<WbsUtilizedBudgetEntity>>;
  contractAccount?: Maybe<Array<ContractAccountEntity>>;
  subcontractAccount?: Maybe<Array<ContractAccountEntity>>;
  fromWbsBudgetTransferHeader?: Maybe<Array<WbsBudgetTransferHeaderEntity>>;
  toWbsBudgetTransferHeader?: Maybe<Array<WbsBudgetTransferHeaderEntity>>;
  APCreditNoteItem?: Maybe<Array<ApCreditNoteItemEntity>>;
  APDebitNoteItem?: Maybe<Array<ApDebitNoteItemEntity>>;
  DNtoSupplierItem?: Maybe<Array<DNtoSupplierItemEntity>>;
  CNtoSupplierItem?: Maybe<Array<CNtoSupplierItemEntity>>;
  subcontractDNItem?: Maybe<Array<SubcontractDnItemEntity>>;
  subcontractCNItem?: Maybe<Array<SubcontractCnItemEntity>>;
  DNtoSubconItem?: Maybe<Array<DNtoSubconItemEntity>>;
  CNtoSubconItem?: Maybe<Array<CNtoSubconItemEntity>>;
  APInvoiceItem?: Maybe<Array<ApInvoiceItemEntity>>;
  bqWbsAssignment?: Maybe<Array<BqWbsAssignmentEntity>>;
  contractVORevenueWbs?: Maybe<Array<ContractVoRevenueWbsEntity>>;
  contractClaimAllocation?: Maybe<Array<ContractClaimAllocationEntity>>;
  clientDNItem?: Maybe<Array<ClientDnItemEntity>>;
  clientCNItem?: Maybe<Array<ClientCnItemEntity>>;
  contractDNItem?: Maybe<Array<ContractDnItemEntity>>;
  contractCNItem?: Maybe<Array<ContractCnItemEntity>>;
  contractMiscInvoiceItem?: Maybe<Array<ContractMiscInvoiceItemEntity>>;
  subcontractMiscInvoiceItem?: Maybe<Array<SubcontractMiscInvoiceItemEntity>>;
  wbsDaywork?: Maybe<Array<WbsDayworkCostRevenueEntity>>;
  childrenWbs?: Maybe<Array<WbsEntity>>;
  parentWbs?: Maybe<WbsEntity>;
  budget: Array<WbsBudgetEntity>;
  /** CustomFieldResolver */
  finalSequence?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  originalCost?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  revisedCost?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  actualCost?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  parentName?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  committedCost?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalCost?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  sumContractVORevenue?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  originalRevenue?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  revisedRevenue?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  actualRevenue?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalRevenue?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  anticipatedProfit?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  wbsProfitStatus?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  totalDN?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  originalCostByClass?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  costByClass?: Maybe<Scalars['JSON']>;
  originalRevenueDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  revenueDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  originalCostDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  costDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  latestWbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
  /** CustomFieldResolver */
  bqAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  bqCount?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  wbsParentChildSequence?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  childrenIDs?: Maybe<Array<Scalars['String']>>;
};


export type WbsEntityOriginalCostArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityRevisedCostArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityTotalCostArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityOriginalRevenueArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityRevisedRevenueArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityTotalRevenueArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityAnticipatedProfitArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityWbsProfitStatusArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityOriginalCostByClassArgs = {
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityCostByClassArgs = {
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityOriginalRevenueDetailArgs = {
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityRevenueDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityOriginalCostDetailArgs = {
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityCostDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityLatestWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type WbsImportInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  parentWbsCode?: Maybe<Scalars['String']>;
  wbsCode?: Maybe<Scalars['String']>;
  wbsName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  costClass?: Maybe<Scalars['String']>;
  costName?: Maybe<Scalars['String']>;
  costItem?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  uom?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  budgetAmount?: Maybe<Scalars['Float']>;
  revenueCategory?: Maybe<Scalars['String']>;
  revenueAmount?: Maybe<Scalars['Float']>;
};

export type WbsInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  parentWBSID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  isLastNode?: Maybe<Scalars['Boolean']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  sequence?: Maybe<Scalars['Float']>;
};

export type WbsUtilizedBudgetActionInput = {
  closureRemarks?: Maybe<Scalars['String']>;
  closureDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  budgetStatus: Scalars['String'];
  contractID: Scalars['String'];
};

export type WbsUtilizedBudgetDeleteInput = {
  ID: Scalars['String'];
};

export type WbsUtilizedBudgetInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  wbsID: Scalars['String'];
  costCategoryID: Scalars['String'];
  year?: Maybe<Scalars['Float']>;
  utilizedQty?: Maybe<Scalars['Float']>;
  utilizedAmt?: Maybe<Scalars['Float']>;
  budgetStatus?: Maybe<Scalars['String']>;
};

export type WageCycleDetailEntity = AuditEntity & {
  __typename?: 'WageCycleDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  wageCycleID: Scalars['String'];
  workerID: Scalars['String'];
  wageAmt: Scalars['Float'];
  wageCycle?: Maybe<WageCycleEntity>;
  worker?: Maybe<WorkerEntity>;
};

export type WageCycleEntity = AuditEntity & {
  __typename?: 'WageCycleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  workerWageID?: Maybe<Scalars['String']>;
  closedBy: Scalars['String'];
  docDate: Scalars['String'];
  site?: Maybe<SiteEntity>;
  wageCycleDetail?: Maybe<Array<WageCycleDetailEntity>>;
  workerWage?: Maybe<Array<WorkerWageEntity>>;
};

export enum WageStatus {
  Draft = 'DRAFT',
  Submit = 'SUBMIT',
  Reject = 'REJECT',
  Approved = 'APPROVED'
}

export enum WageType {
  Hourly = 'HOURLY',
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  None = 'NONE'
}

export type WagesRatePolicyDeleteInput = {
  ID: Scalars['String'];
};

export type WagesRatePolicyEntity = AuditEntity & {
  __typename?: 'WagesRatePolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  holidayTypeID: Scalars['String'];
  rate: Scalars['Float'];
  holidayType?: Maybe<HolidayTypeEntity>;
};

export type WagesRatePolicyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  holidayTypeID?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
};

export type WbsBudgetActionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  reason?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  budgetStatus?: Maybe<BudgetStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type WbsBudgetDetailEntity = AuditEntity & {
  __typename?: 'WbsBudgetDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  budgetID: Scalars['String'];
  budget: WbsBudgetEntity;
  wbsID: Scalars['String'];
  wbs: WbsEntity;
  revenueCategoryID?: Maybe<Scalars['String']>;
  revenueCategory?: Maybe<RevenueCategoryEntity>;
  costCategoryID?: Maybe<Scalars['String']>;
  costCategory?: Maybe<CostCategoryEntity>;
  costItemID?: Maybe<Scalars['String']>;
  costItem?: Maybe<CostItemEntity>;
  uomID?: Maybe<Scalars['String']>;
  uom?: Maybe<UomEntity>;
  creditTerm: Scalars['Float'];
  quantity?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  budgetAmt?: Maybe<Scalars['Float']>;
  remark?: Maybe<Scalars['String']>;
  updateSchedule: Scalars['Boolean'];
  budgetType: BudgetType;
  wbsBudgetDetailSchedule?: Maybe<Array<WbsBudgetDetailScheduleEntity>>;
  contractClaimAllocation?: Maybe<Array<ContractClaimAllocationEntity>>;
  contractVORevenueWbs?: Maybe<Array<ContractVoRevenueWbsEntity>>;
  /** CustomFieldResolver */
  trackQty?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  originalAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  previousAmt?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  originalQty?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  reservedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  reservedQty?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  utilisedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  utilisedQty?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  balanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  balanceQty?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  balanceQtyPurchasing?: Maybe<Scalars['Float']>;
  fromCostCategory: Scalars['String'];
  toCostCategory: Scalars['String'];
};


export type WbsBudgetDetailEntityTrackQtyArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityOriginalAmtArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityOriginalQtyArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityReservedAmtArgs = {
  costID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityReservedQtyArgs = {
  costID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityUtilisedAmtArgs = {
  costID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityUtilisedQtyArgs = {
  costID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityBalanceAmtArgs = {
  costID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityBalanceQtyArgs = {
  costID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  prID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityBalanceQtyPurchasingArgs = {
  costID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  poID?: Maybe<Scalars['String']>;
  prID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};

export type WbsBudgetDetailScheduleEntity = AuditEntity & {
  __typename?: 'WbsBudgetDetailScheduleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  wbsBudgetDetailID: Scalars['String'];
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
  wbsScheduleID: Scalars['String'];
  wbsSchedule?: Maybe<WbsScheduleEntity>;
  scheduleAmt: Scalars['Float'];
};

export type WbsBudgetEntity = AuditEntity & {
  __typename?: 'WbsBudgetEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract: ContractEntity;
  copyFromBudgetID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
  version: Scalars['Float'];
  budgetStatus: BudgetStatus;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  budgetWbsDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  copyFromBudget?: Maybe<WbsBudgetEntity>;
  wbs: Array<WbsEntity>;
  totalCostRevenue: Scalars['JSON'];
};


export type WbsBudgetEntityTotalCostRevenueArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type WbsBudgetInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Float']>;
};

export type WbsBudgetRevisionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  copyFromBudgetID: Scalars['String'];
  contractID: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  reason: Scalars['String'];
};

export type WbsBudgetSubmitApproveInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  budgetStatus?: Maybe<BudgetStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type WbsBudgetTransferCostCategoryBalanceFilterArgs = {
  wbsID: Scalars['String'];
  costCategoryID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
};

export type WbsBudgetTransferInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  docRef?: Maybe<Scalars['String']>;
  fromWbsID: Scalars['String'];
  toWbsID: Scalars['String'];
  transferDate: Scalars['String'];
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  budgetStatus?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type WbsBudgetTransferItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  fromCostID: Scalars['String'];
  fromIsCostItem: Scalars['Boolean'];
  toCostID: Scalars['String'];
  toIsCostItem: Scalars['Boolean'];
  fromQty: Scalars['Float'];
  toQty: Scalars['Float'];
  transferAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
};

export type WbsDayworkCostRevenueEntity = AuditEntity & {
  __typename?: 'WbsDayworkCostRevenueEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  wbsID: Scalars['String'];
  wbs?: Maybe<WbsEntity>;
  revenueCategoryID?: Maybe<Scalars['String']>;
  revenueCategory?: Maybe<RevenueCategoryEntity>;
  costCategoryID?: Maybe<Scalars['String']>;
  costCategory?: Maybe<CostCategoryEntity>;
  costItemID?: Maybe<Scalars['String']>;
  costItem?: Maybe<CostItemEntity>;
};

export type WbsScheduleEntity = AuditEntity & {
  __typename?: 'WbsScheduleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  wbsID: Scalars['String'];
  wbs: WbsEntity;
  period: Scalars['Float'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  wbsBudgetDetailSchedule?: Maybe<Array<WbsBudgetDetailScheduleEntity>>;
  /** CustomFieldResolver */
  code?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  budgetDetailScheduleAmt?: Maybe<Scalars['Float']>;
};


export type WbsScheduleEntityBudgetDetailScheduleAmtArgs = {
  wbsBudgetDetailID?: Maybe<Scalars['String']>;
};

export type WbsScheduleInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  wbsID?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['Float']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};

export type WbsUtilizedBudgetEntity = AuditEntity & {
  __typename?: 'WbsUtilizedBudgetEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  wbsID: Scalars['String'];
  wbs?: Maybe<WbsEntity>;
  costCategoryID: Scalars['String'];
  costCategory?: Maybe<CostCategoryEntity>;
  year: Scalars['Float'];
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  utilizedQty?: Maybe<Scalars['Float']>;
  utilizedAmt: Scalars['Float'];
  budgetStatus: BudgetStatus;
};

export type Weekend = {
  __typename?: 'Weekend';
  monday?: Maybe<Scalars['Boolean']>;
  tuesday?: Maybe<Scalars['Boolean']>;
  wednesday?: Maybe<Scalars['Boolean']>;
  thursday?: Maybe<Scalars['Boolean']>;
  friday?: Maybe<Scalars['Boolean']>;
  saturday?: Maybe<Scalars['Boolean']>;
  sunday?: Maybe<Scalars['Boolean']>;
};

export type WeekendInput = {
  monday?: Maybe<Scalars['Boolean']>;
  tuesday?: Maybe<Scalars['Boolean']>;
  wednesday?: Maybe<Scalars['Boolean']>;
  thursday?: Maybe<Scalars['Boolean']>;
  friday?: Maybe<Scalars['Boolean']>;
  saturday?: Maybe<Scalars['Boolean']>;
  sunday?: Maybe<Scalars['Boolean']>;
};

export type WorkDeskInput = {
  ID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  statusName: Scalars['String'];
  refTable: Scalars['String'];
  approvalStatus: WorkFlowStatus;
  approveRejectDate: Scalars['DateTime'];
};

export type WorkFlowApprovedStepEntity = AuditEntity & {
  __typename?: 'WorkFlowApprovedStepEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  workFlowStepID: Scalars['String'];
  roleID?: Maybe<Scalars['String']>;
  approverID?: Maybe<Scalars['String']>;
  approvalDT?: Maybe<Scalars['String']>;
  notifiedDT?: Maybe<Scalars['String']>;
  approvalStatus: WorkFlowStatus;
  statusRemarks: Scalars['String'];
};

export type WorkFlowEntity = AuditEntity & {
  __typename?: 'WorkFlowEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  approvalPolicyAssignmentID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  refID: Scalars['String'];
  refTable: Scalars['String'];
  submitterID: Scalars['String'];
  submitDate: Scalars['String'];
  workFlowStatus: WorkFlowStatus;
  workFlowStep?: Maybe<Array<WorkFlowStepEntity>>;
};

export type WorkFlowInput = {
  accountID?: Maybe<Scalars['String']>;
  approvalPolicyAssignmentID?: Maybe<Scalars['String']>;
  submitterID?: Maybe<Scalars['String']>;
  submitDate?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  workFlowStatus?: Maybe<WorkFlowStatus>;
  createdTs?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  modTs?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
};

export enum WorkFlowStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  Pendingclose = 'PENDINGCLOSE',
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED'
}

export type WorkFlowStepEntity = AuditEntity & {
  __typename?: 'WorkFlowStepEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  workFlowID: Scalars['String'];
  approvalRoleID: Scalars['String'];
  notifiedDT: Scalars['String'];
  stepNo: Scalars['Float'];
  approvalStatus: WorkFlowStatus;
  statusRemarks: Scalars['String'];
  workFlow?: Maybe<WorkFlowEntity>;
};

export type WorkFlowStepInput = {
  workFlowStepID?: Maybe<Scalars['String']>;
  approverID?: Maybe<Scalars['String']>;
  notifiedDT?: Maybe<Scalars['String']>;
  roleID?: Maybe<Scalars['String']>;
  stepNo?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  approvalPolicyAssignmentID?: Maybe<Scalars['String']>;
  subscriptionAccountID?: Maybe<Scalars['String']>;
  userList?: Maybe<Array<UserListInput>>;
  predicate?: Maybe<Scalars['String']>;
  roleName?: Maybe<Scalars['String']>;
};

export type WorkerAdvanceEntity = AuditEntity & {
  __typename?: 'WorkerAdvanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  workerID: Scalars['String'];
  docDate: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  wageCycleID?: Maybe<Scalars['String']>;
  wageStatus: WageStatus;
  worker?: Maybe<WorkerEntity>;
};

export type WorkerAttendanceDeleteInput = {
  ID: Scalars['String'];
};

export type WorkerAttendanceEntity = AuditEntity & {
  __typename?: 'WorkerAttendanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  workDate: Scalars['String'];
  temperature?: Maybe<Scalars['Float']>;
  workerID: Scalars['String'];
  siteID: Scalars['String'];
  timeIn: Scalars['String'];
  timeOut?: Maybe<Scalars['String']>;
  worker?: Maybe<WorkerEntity>;
  workerWage: WorkerWageEntity;
  /** CustomFieldResolver */
  attendanceSummary?: Maybe<Scalars['JSON']>;
};

export type WorkerAttendanceInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  workDate?: Maybe<Scalars['String']>;
  timeIn: Scalars['String'];
  timeOut?: Maybe<Scalars['String']>;
  workerID?: Maybe<Scalars['String']>;
  temperature?: Maybe<Scalars['Float']>;
  siteID?: Maybe<Scalars['String']>;
  wageStatus?: Maybe<WageStatus>;
};

export type WorkerDeleteInput = {
  ID: Scalars['String'];
};

export type WorkerDocuments = {
  __typename?: 'WorkerDocuments';
  fileURL?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  mediaType?: Maybe<Scalars['String']>;
};

export type WorkerEntity = AuditEntity & {
  __typename?: 'WorkerEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID?: Maybe<Scalars['String']>;
  contactID: Scalars['String'];
  jobTypeID: Scalars['String'];
  jobType?: Maybe<JobTypeEntity>;
  identityNo: Scalars['String'];
  nationality: Nationality;
  workPermitNo?: Maybe<Scalars['String']>;
  docExpiryDate?: Maybe<Scalars['String']>;
  address: Scalars['JSON'];
  wageRate: Scalars['Float'];
  wageType: WageType;
  commonStatus: CommonStatus;
  workerAttendance?: Maybe<Array<WorkerAttendanceEntity>>;
  workerAdvance?: Maybe<Array<WorkerAdvanceEntity>>;
  wageCycleDetail?: Maybe<Array<WageCycleDetailEntity>>;
  workerWage?: Maybe<Array<WorkerWageEntity>>;
  contact: ContactEntity;
  attachment?: Maybe<WorkerDocuments>;
  workerSiteAssignment?: Maybe<Array<WorkerSiteAssignmentEntity>>;
  siteResolver: Array<SiteEntity>;
};

export type WorkerHour = {
  __typename?: 'WorkerHour';
  ID: Scalars['String'];
  workerID?: Maybe<Scalars['String']>;
  verifiedHour: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  workerDate?: Maybe<Scalars['DateTime']>;
};

export type WorkerHourInput = {
  ID: Scalars['String'];
  workerID?: Maybe<Scalars['String']>;
  verifiedHour: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  workerDate?: Maybe<Scalars['DateTime']>;
};

export type WorkerInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  jobTypeID?: Maybe<Scalars['String']>;
  identityNo?: Maybe<Scalars['String']>;
  workPermitNo?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  wageRate?: Maybe<Scalars['Float']>;
  docExpiryDate?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  wageType?: Maybe<WageType>;
  nationality?: Maybe<Nationality>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type WorkerSiteAssignmentEntity = AuditEntity & {
  __typename?: 'WorkerSiteAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  workerID: Scalars['String'];
  worker?: Maybe<WorkerEntity>;
  siteID: Scalars['String'];
  site?: Maybe<SiteEntity>;
};

export type WorkerWageCycle = {
  __typename?: 'WorkerWageCycle';
  workerID: Scalars['String'];
  totalWage: Scalars['Float'];
};

export type WorkerWageCycleInput = {
  workerID: Scalars['String'];
  totalWage: Scalars['Float'];
};

export type WorkerWageEntity = AuditEntity & {
  __typename?: 'WorkerWageEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  workerID: Scalars['String'];
  workerAttendanceID?: Maybe<Scalars['String']>;
  workDate: Scalars['String'];
  workHour: Scalars['Float'];
  verifiedHour?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  wageCycleID?: Maybe<Scalars['String']>;
  wageStatus: WageStatus;
  site?: Maybe<SiteEntity>;
  worker?: Maybe<WorkerEntity>;
  workerAttendance?: Maybe<WorkerAttendanceEntity>;
  wageCycle?: Maybe<WageCycleEntity>;
  workerWageHours?: Maybe<Scalars['JSON']>;
  workerWageAmount: Scalars['JSON'];
};


export type WorkerWageEntityWorkerWageAmountArgs = {
  workerDate?: Maybe<Scalars['DateTime']>;
};

export type WorkingHourPolicyDeleteInput = {
  ID: Scalars['String'];
};

export type WorkingHourPolicyEntity = AuditEntity & {
  __typename?: 'WorkingHourPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  holidayTypeID: Scalars['String'];
  shiftStartTime: Scalars['String'];
  shiftEndTime: Scalars['String'];
  halfStartTime: Scalars['String'];
  halfEndTime: Scalars['String'];
  commonStatus: CommonStatus;
  holidayType?: Maybe<HolidayTypeEntity>;
};

export type WorkingHourPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  shiftStartTime?: Maybe<Scalars['String']>;
  shiftEndTime?: Maybe<Scalars['String']>;
  halfStartTime?: Maybe<Scalars['String']>;
  halfEndTime?: Maybe<Scalars['String']>;
};

export type WorkshopEntity = AuditEntity & {
  __typename?: 'WorkshopEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  address: Scalars['JSON'];
  contactPerson: Array<Scalars['JSON']>;
  workshopStatus: WorkshopStatus;
  ratingType: RatingType;
  costConnection?: Maybe<Array<PnmCategoryWorkshopEntity>>;
  maintenance?: Maybe<Array<MaintenanceEntity>>;
};

export type WorkshopInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
  address?: Maybe<AddressInput>;
  contactPerson?: Maybe<Array<ContactPersonInput>>;
  workshopStatus?: Maybe<WorkshopStatus>;
  ratingType: RatingType;
};

export enum WorkshopStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Blacklisted = 'BLACKLISTED'
}

export type AdvanceFromClient = {
  __typename?: 'advanceFromClient';
  advClientListing?: Maybe<Scalars['JSON']>;
  latestAdvClientDate?: Maybe<Scalars['DateTime']>;
  advClientAmt?: Maybe<Scalars['Float']>;
};

export type AssignBqWbsAssignment = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  bqID: Scalars['String'];
};

export type BatchWorkAttendanceInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  workDate?: Maybe<Scalars['String']>;
  timeIn: Scalars['String'];
  timeOut?: Maybe<Scalars['String']>;
  workerID?: Maybe<Scalars['String']>;
  temperature?: Maybe<Scalars['Float']>;
  siteID?: Maybe<Scalars['String']>;
  wageStatus?: Maybe<WageStatus>;
  identityNo?: Maybe<Scalars['String']>;
  totalHour?: Maybe<Scalars['Float']>;
  ot?: Maybe<Scalars['Float']>;
  publicHoliday?: Maybe<Scalars['Float']>;
  weekend?: Maybe<Scalars['Float']>;
};

export type CompletionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  completionPerc: Scalars['Float'];
  contractID?: Maybe<Scalars['String']>;
};

export type DefectLogInput = {
  costCategoryID?: Maybe<Scalars['Float']>;
  contractID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdT?: Maybe<Scalars['DateTime']>;
  defectStatus?: Maybe<DefectStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type EInvoiceClassificationEntity = {
  __typename?: 'eInvoiceClassificationEntity';
  id: Scalars['String'];
  classificationCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type EInvoiceGeneralTinEntity = {
  __typename?: 'eInvoiceGeneralTinEntity';
  id: Scalars['String'];
  tinParty?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  displaySeq?: Maybe<Scalars['Float']>;
};

export type EInvoiceMsicEntity = {
  __typename?: 'eInvoiceMsicEntity';
  id: Scalars['String'];
  msicCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  categoryReference?: Maybe<Scalars['String']>;
  companyMsicAssignment: Array<CompanyMsicAssignmentEntity>;
};

export type Items = {
  __typename?: 'items';
  description?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
};

export type PasswordInfo = {
  ID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  confirmedPassword: Scalars['String'];
};

export type PmStatus = {
  __typename?: 'pmStatus';
  available?: Maybe<Scalars['Float']>;
  assigned?: Maybe<Scalars['Float']>;
  outOfOrder?: Maybe<Scalars['Float']>;
  maintenance?: Maybe<Scalars['Float']>;
};

export type PoUomExchange = {
  __typename?: 'poUOMExchange';
  uomid: Scalars['String'];
  uomname: Scalars['String'];
  uomcode: Scalars['String'];
};

export type PrInfo = {
  prItemID: Scalars['String'];
  totalAmt?: Maybe<Scalars['Float']>;
};

export type PrItemInfo = {
  __typename?: 'prItemInfo';
  prItemID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  purchaseReqID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  uomCode?: Maybe<Scalars['String']>;
  rfqItemID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  negoSupplyItemID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  costItemName?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  markupPerc?: Maybe<Scalars['Float']>;
  requestedQty?: Maybe<Scalars['Float']>;
  outstandingQty?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Scalars['JSON']>;
  address?: Maybe<Scalars['JSON']>;
  prDate?: Maybe<Scalars['DateTime']>;
  docDate?: Maybe<Scalars['DateTime']>;
  expectedDate?: Maybe<Scalars['DateTime']>;
  negotiatedItemStatus?: Maybe<Scalars['Boolean']>;
  supplier?: Maybe<Array<SupplierEntity>>;
};

export type RoleUser = {
  __typename?: 'roleUser';
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type RunningNumberSetupInput = {
  numberFormatID: Scalars['String'];
  nextNumber: Scalars['Float'];
};

export type SubconVo = {
  __typename?: 'subconVO';
  voSum?: Maybe<Scalars['Float']>;
  voOmission?: Maybe<Scalars['Float']>;
  subcontractSum?: Maybe<Scalars['Float']>;
  revisedSubcontractSum?: Maybe<Scalars['Float']>;
  totalVoAmt?: Maybe<Scalars['Float']>;
  totalPendingVo?: Maybe<Scalars['Float']>;
};

export type TaxDetailEntity = {
  __typename?: 'taxDetailEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  taxId: Scalars['String'];
  tax: TaxEntity;
  effectiveDate: Scalars['DateTime'];
  rate: Scalars['Float'];
  commonStatus: Scalars['String'];
};

export type TaxEntity = {
  __typename?: 'taxEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  schemeTypeId: Scalars['String'];
  schemeType: TaxSchemeTypeEntity;
  code: Scalars['String'];
  description: Scalars['String'];
  classType: Scalars['String'];
  isClaimable: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  taxDetail: Array<TaxDetailEntity>;
  taxSchemeDetailItem: Array<TaxSchemeDetailItemEntity>;
};

export type TaxOnSeqInput = {
  taxSchemeDetailItemId: Scalars['String'];
  seqNo: Scalars['Float'];
};

/** JsonType */
export type TaxOnSeqObjects = {
  __typename?: 'taxOnSeqObjects';
  taxSchemeDetailItemId: Scalars['String'];
  seqNo: Scalars['Float'];
};

export type TaxSchemeDetailEntity = {
  __typename?: 'taxSchemeDetailEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  taxSchemeId: Scalars['String'];
  taxScheme: TaxSchemeEntity;
  effectiveDate: Scalars['DateTime'];
  commonStatus: Scalars['String'];
  taxSchemeDetailItems?: Maybe<Array<TaxSchemeDetailItemEntity>>;
};

export type TaxSchemeDetailItemEntity = {
  __typename?: 'taxSchemeDetailItemEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  taxSchemeDetailId: Scalars['String'];
  taxSchemeDetail: TaxSchemeDetailEntity;
  taxId: Scalars['String'];
  tax: TaxEntity;
  computeMethod: Scalars['String'];
  seqNo?: Maybe<Scalars['Float']>;
  taxOnSeq?: Maybe<Array<TaxOnSeqObjects>>;
  commonStatus: Scalars['String'];
};

export type TaxSchemeEntity = {
  __typename?: 'taxSchemeEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isExclusive?: Maybe<Scalars['Boolean']>;
  commonStatus: Scalars['String'];
  taxSchemeDetails?: Maybe<Array<TaxSchemeDetailEntity>>;
};

export type TaxSchemeTypeEntity = {
  __typename?: 'taxSchemeTypeEntity';
  id: Scalars['String'];
  code: Scalars['String'];
  name: Scalars['String'];
  softwareCode: Scalars['String'];
  commonStatus: Scalars['String'];
  tax?: Maybe<Array<TaxEntity>>;
};

export type TotalDoAmt = {
  __typename?: 'totalDOAmt';
  total?: Maybe<Scalars['Float']>;
};

export type UnassignBqWbsAssignment = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  bqID: Scalars['String'];
};

export type UomExchange = {
  __typename?: 'uomExchange';
  uomid: Scalars['String'];
  uomname: Scalars['String'];
  uomcode: Scalars['String'];
};

export type UomExchanges = {
  __typename?: 'uomExchanges';
  uomid: Scalars['String'];
  uomname: Scalars['String'];
  uomcode: Scalars['String'];
};

export type UpdateDoInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  doNo: Scalars['String'];
  contractID: Scalars['String'];
  companyID: Scalars['String'];
  siteID: Scalars['String'];
  supplierID: Scalars['String'];
  docDate: Scalars['String'];
  remarks: Scalars['String'];
  doStatus: DoStatus;
  contactPerson?: Maybe<ContactPersonInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type UserRoleId = {
  __typename?: 'userRoleID';
  roleID: Scalars['String'];
  userID: Scalars['String'];
};

export type LoggedInUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type LoggedInUserProfileQuery = (
  { __typename?: 'Query' }
  & { loggedInUserProfile: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'accountID' | 'name' | 'email' | 'contactNo' | 'department' | 'superUser' | 'userName' | 'jobTitle'>
  ) }
);

export type LoginMutationVariables = Exact<{
  loginId: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'accessToken'>
    & { user: (
      { __typename?: 'UserResponse' }
      & Pick<UserResponse, 'name'>
    ) }
  ) }
);

export type UploadProfilePicMutationVariables = Exact<{
  userID: Scalars['String'];
  file: Scalars['Upload'];
}>;


export type UploadProfilePicMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadProfilePic'>
);

export type ChangePasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePassword'>
);

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotPassword'>
);

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type ActivateUserMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ActivateUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateUser'>
);

export type CreatePasswordMutationVariables = Exact<{
  input: PasswordInfo;
}>;


export type CreatePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPassword'>
);

export type GetSubcontractorQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractorQuery = (
  { __typename?: 'Query' }
  & { getSubcontractor: Array<(
    { __typename?: 'SubcontractorEntity' }
    & Pick<SubcontractorEntity, 'ID' | 'name' | 'email' | 'accountID' | 'registrationNo' | 'associatedStatus' | 'contactNo' | 'creditTerm' | 'currencyID' | 'ratingType' | 'cidbDetails' | 'pkkDetails' | 'statusRemarks' | 'bankDetails' | 'gstRegNo' | 'description' | 'gstExpDate' | 'platformAccess' | 'sstRegNo' | 'address' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'nominatedSC'>
    & { contactPerson: Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'name' | 'designation' | 'email' | 'contactNo' | 'associateID'>
    )>, subconClaim: Array<(
      { __typename?: 'SubcontractClaimEntity' }
      & Pick<SubcontractClaimEntity, 'ID' | 'outstandingAmt'>
    )>, subconAdvance?: Maybe<Array<(
      { __typename?: 'SubcontractAdvanceEntity' }
      & Pick<SubcontractAdvanceEntity, 'ID' | 'outstandingAmt' | 'baseAmt'>
    )>>, subcontract?: Maybe<Array<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'title' | 'subcontractSum'>
    )>> }
  )> }
);

export type GetSubcontractorListQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractorListQuery = (
  { __typename?: 'Query' }
  & { getSubcontractor: Array<(
    { __typename?: 'SubcontractorEntity' }
    & Pick<SubcontractorEntity, 'ID' | 'name'>
  )> }
);

export type CreateSubcontractorImportMutationVariables = Exact<{
  batchID: Scalars['String'];
}>;


export type CreateSubcontractorImportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createSubcontractorImport'>
);

export type SubcontractorImportValidationMutationVariables = Exact<{
  input: Array<SubcontractorImportInput> | SubcontractorImportInput;
}>;


export type SubcontractorImportValidationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'subcontractorImportValidation'>
);

export type CreatePlatformSupplierMutationVariables = Exact<{
  input: SupplierInput;
}>;


export type CreatePlatformSupplierMutation = (
  { __typename?: 'Mutation' }
  & { createPlatformSupplier: (
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'ID' | 'name'>
  ) }
);

export type GetSupplierQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetSupplierQuery = (
  { __typename?: 'Query' }
  & { getSupplier: Array<(
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'ID' | 'name' | 'registrationNo' | 'email' | 'address' | 'currencyID' | 'creditTerm' | 'description' | 'platformAccess' | 'contactNo' | 'ratingType' | 'gstRegNo' | 'gstExpDate' | 'sstRegNo' | 'associatedStatus' | 'bankDetails' | 'accountID'>
    & { contactPerson: Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'name' | 'designation' | 'email' | 'contactNo' | 'associateID'>
    )>, costCategory: Array<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name'>
    )> }
  )> }
);

export type GetSupplierListQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetSupplierListQuery = (
  { __typename?: 'Query' }
  & { getSupplier: Array<(
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'ID' | 'name'>
  )> }
);

export type CreateSupplierCCategoryMutationVariables = Exact<{
  input: SupplierCCategoryInput;
}>;


export type CreateSupplierCCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createSupplierCCategory: (
    { __typename?: 'SupplierCCategoryEntity' }
    & Pick<SupplierCCategoryEntity, 'costCategoryID'>
  ) }
);

export type UpdateSupplierCCategoryMutationVariables = Exact<{
  input: SupplierCCategoryInput;
}>;


export type UpdateSupplierCCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateSupplierCCategory: (
    { __typename?: 'SupplierCCategoryEntity' }
    & Pick<SupplierCCategoryEntity, 'costCategoryID'>
  ) }
);

export type GetSupplierCCategoryQueryVariables = Exact<{
  supplierID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
}>;


export type GetSupplierCCategoryQuery = (
  { __typename?: 'Query' }
  & { getSupplierCCategory: Array<(
    { __typename?: 'SupplierCCategoryEntity' }
    & Pick<SupplierCCategoryEntity, 'supplierID' | 'costCategoryID'>
  )> }
);

export type AddCCategoryInSupplierMutationVariables = Exact<{
  supplierID: Scalars['String'];
  costCategoryID: Array<Scalars['String']> | Scalars['String'];
}>;


export type AddCCategoryInSupplierMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addCCategoryInSupplier'>
);

export type GetSupplierExecutiveSummaryQueryVariables = Exact<{
  supplierID: Scalars['String'];
}>;


export type GetSupplierExecutiveSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierProfileDate' | 'PrefSupplierProductDate' | 'SupplierAccDate'>
);

export type GetSupplierTitleQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetSupplierTitleQuery = (
  { __typename?: 'Query' }
  & { getSupplier: Array<(
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'ID' | 'name'>
  )> }
);

export type GetActiveSupplierQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActiveSupplierQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getActiveSupplier'>
);

export type CreateSupplierImportMutationVariables = Exact<{
  batchID: Scalars['String'];
}>;


export type CreateSupplierImportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createSupplierImport'>
);

export type SupplierImportValidationMutationVariables = Exact<{
  input: Array<SupplierImportInput> | SupplierImportInput;
}>;


export type SupplierImportValidationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'supplierImportValidation'>
);

export type GetCompanyNameQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetCompanyNameQuery = (
  { __typename?: 'Query' }
  & { getCompany: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CompanyID' | 'Name' | 'Description' | 'CompanyRegNo' | 'Image' | 'ImageUrl'>
  )> }
);

export type GetAllCompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCompanyQuery = (
  { __typename?: 'Query' }
  & { getCompany: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CompanyID' | 'ParentCompanyID' | 'AccountID' | 'BaseCurrencyID' | 'Name' | 'Description' | 'CompanyRegNo' | 'Code' | 'ContactNo' | 'Email' | 'SSTNo' | 'GSTNo' | 'GSTExpiryDate' | 'Image' | 'ImageUrl' | 'ImageBucketFile' | 'WebsiteUrl' | 'RegionID' | 'ImageLogoPosition' | 'WatermarkImplementation' | 'WatermarkWording' | 'IsoImplementation' | 'IsoLogoPosition' | 'CompanyTax' | 'RecordStatus' | 'createdBy' | 'createdTs' | 'modBy' | 'modTs'>
    & { Address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'ID' | 'address' | 'address2' | 'siteName' | 'country' | 'city' | 'district' | 'state' | 'postCode' | 'postCodeName' | 'AddressType'>
    )> }
  )> }
);

export type UpdateCompanyMutationVariables = Exact<{
  input: CompanyInput;
}>;


export type UpdateCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCompany'>
);

export type UploadCompanyIsoLogoMutationVariables = Exact<{
  file?: Maybe<Scalars['Upload']>;
  companyID: Scalars['String'];
}>;


export type UploadCompanyIsoLogoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadCompanyISOLogo'>
);

export type UpdateWatermarkCompanyMutationVariables = Exact<{
  input: CompanyLogoInput;
  companyID: Scalars['String'];
}>;


export type UpdateWatermarkCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateWatermarkCompany'>
);

export type GetLocalBankQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  countryID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetLocalBankQuery = (
  { __typename?: 'Query' }
  & { getLocalBank: Array<(
    { __typename?: 'LocalBankEntity' }
    & Pick<LocalBankEntity, 'ID' | 'countryID' | 'name' | 'code' | 'contactNo' | 'faxNo' | 'website' | 'logo' | 'commonStatus'>
    & { address: (
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'district' | 'state' | 'postCode' | 'country'>
    ) }
  )> }
);

export type GetContractQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
}>;


export type GetContractQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'accountID' | 'contractNo' | 'customerID' | 'description' | 'contractDate' | 'contractSum' | 'voSum' | 'revisedContractSum' | 'taxSchemeID' | 'startDate' | 'endDate' | 'progressRetentionPerc' | 'maxRetentionPerc' | 'targetProfitPerc' | 'cmgdDate' | 'cpcDate' | 'dlpDate' | 'ladAmt' | 'ladRate' | 'creditTerm' | 'latestRunNum' | 'docNumChecking' | 'contractPOCounts' | 'contractStatus' | 'subcontractSummary' | 'contactPerson' | 'closureRemarks' | 'closureDate' | 'modBy' | 'extensionDate'>
    & { site?: Maybe<Array<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'name' | 'contractID' | 'address' | 'ID'>
    )>>, customerDetail?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'name' | 'address' | 'contactNo'>
      & { contactPerson: Array<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ID' | 'name' | 'designation' | 'email' | 'contactNo'>
      )> }
    )>, subcontractTenderDetail?: Maybe<Array<(
      { __typename?: 'SubcontractTenderEntity' }
      & Pick<SubcontractTenderEntity, 'ID' | 'title'>
    )>>, subcontractDetail?: Maybe<Array<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'title' | 'accountID' | 'contractID' | 'subcontractorID' | 'costCategoryID' | 'subcontractNo' | 'description' | 'subcontractDate' | 'taxSchemeID' | 'subcontractSum' | 'revisedSubcontractSum' | 'startDate' | 'endDate' | 'progressRetentionPerc' | 'maxRetentionPerc' | 'targetProfitPerc' | 'creditTerm' | 'subcontractStatus'>
      & { subcontractorDetail?: Maybe<(
        { __typename?: 'SubcontractorEntity' }
        & Pick<SubcontractorEntity, 'ID' | 'name' | 'associatedStatus'>
      )> }
    )>>, taxDetail?: Maybe<(
      { __typename?: 'ConTaxSchemeEntity' }
      & Pick<ConTaxSchemeEntity, 'ID' | 'code' | 'description' | 'taxCategory' | 'taxTypeID' | 'taxClass' | 'createdTs' | 'commonStatus'>
      & { latestTax?: Maybe<(
        { __typename?: 'TaxObject' }
        & Pick<TaxObject, 'taxRate' | 'taxDate'>
      )>, taxEffective?: Maybe<Array<(
        { __typename?: 'ConTaxEffectiveDateEntity' }
        & Pick<ConTaxEffectiveDateEntity, 'date' | 'taxRate' | 'createdTs'>
      )>> }
    )> }
  )> }
);

export type GetContractSubmenuQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetContractSubmenuQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'accountID' | 'contractNo' | 'customerID'>
  )>, getAllContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'accountID' | 'contractNo' | 'customerID'>
  )> }
);

export type CreateContractMutationVariables = Exact<{
  input: ContractInput;
  siteInput: Array<SiteInput> | SiteInput;
}>;


export type CreateContractMutation = (
  { __typename?: 'Mutation' }
  & { createContract: (
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'contractNo' | 'title' | 'description' | 'contractSum' | 'taxSchemeID' | 'startDate' | 'endDate' | 'progressRetentionPerc' | 'maxRetentionPerc' | 'cmgdDate' | 'cpcDate' | 'creditTerm' | 'customerID' | 'accountID' | 'contractDate'>
  ) }
);

export type UpdateContractMutationVariables = Exact<{
  input: ContractInput;
}>;


export type UpdateContractMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateContract'>
);

export type GetContractGuaranteeQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetContractGuaranteeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'contractGuaranteeInfo'>
  & { getContractGuarantee: Array<(
    { __typename?: 'ContractGuaranteeEntity' }
    & Pick<ContractGuaranteeEntity, 'ID' | 'modBy' | 'accountID' | 'contractID' | 'docRef' | 'issuer' | 'guaranteeTypeID' | 'description' | 'docAmt' | 'startDate' | 'expiryDate' | 'collateralAmt' | 'remarks' | 'cancellationDate' | 'guaranteeStatus'>
    & { guaranteeType?: Maybe<(
      { __typename?: 'GuaranteeTypeEntity' }
      & Pick<GuaranteeTypeEntity, 'name'>
    )> }
  )> }
);

export type CreateContractGuaranteeMutationVariables = Exact<{
  input: ContractGuaranteeInput;
}>;


export type CreateContractGuaranteeMutation = (
  { __typename?: 'Mutation' }
  & { createContractGuarantee: (
    { __typename?: 'ContractGuaranteeEntity' }
    & Pick<ContractGuaranteeEntity, 'ID'>
  ) }
);

export type UpdateContractGuaranteeMutationVariables = Exact<{
  input: ContractGuaranteeInput;
}>;


export type UpdateContractGuaranteeMutation = (
  { __typename?: 'Mutation' }
  & { updateContractGuarantee: (
    { __typename?: 'ContractGuaranteeEntity' }
    & Pick<ContractGuaranteeEntity, 'ID'>
  ) }
);

export type GetContractInsuranceQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
}>;


export type GetContractInsuranceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'contractInsuranceInfo'>
  & { getContractInsurance: Array<(
    { __typename?: 'ContractInsuranceEntity' }
    & Pick<ContractInsuranceEntity, 'ID' | 'modBy' | 'accountID' | 'contractID' | 'docRef' | 'issuer' | 'insuranceTypeID' | 'description' | 'docAmt' | 'startDate' | 'expiryDate' | 'remarks' | 'cancellationDate' | 'insuranceStatus'>
    & { insuranceType?: Maybe<(
      { __typename?: 'InsuranceTypeEntity' }
      & Pick<InsuranceTypeEntity, 'name'>
    )> }
  )> }
);

export type CreateContractInsuranceMutationVariables = Exact<{
  input: ContractInsuranceInput;
}>;


export type CreateContractInsuranceMutation = (
  { __typename?: 'Mutation' }
  & { createContractInsurance: (
    { __typename?: 'ContractInsuranceEntity' }
    & Pick<ContractInsuranceEntity, 'ID'>
  ) }
);

export type UpdateContractInsuranceMutationVariables = Exact<{
  input: ContractInsuranceInput;
}>;


export type UpdateContractInsuranceMutation = (
  { __typename?: 'Mutation' }
  & { updateContractInsurance: (
    { __typename?: 'ContractInsuranceEntity' }
    & Pick<ContractInsuranceEntity, 'ID'>
  ) }
);

export type ActionOnContractStatusMutationVariables = Exact<{
  input: ContractActionInput;
  ID: Scalars['String'];
}>;


export type ActionOnContractStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'actionOnContractStatus'>
);

export type CreateContractExtensionDateMutationVariables = Exact<{
  input: ContractExtensionInput;
  ID: Scalars['String'];
}>;


export type CreateContractExtensionDateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createContractExtensionDate'>
);

export type CancelContractGuaranteeMutationVariables = Exact<{
  input: ContractGuaranteeCancellationInput;
}>;


export type CancelContractGuaranteeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelContractGuarantee'>
);

export type CancelContractInsuranceMutationVariables = Exact<{
  input: ContractInsuranceCancellationInput;
}>;


export type CancelContractInsuranceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelContractInsurance'>
);

export type GetContractSummaryQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetContractSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'contractGuaranteeInfo' | 'contractInsuranceInfo' | 'submittedVONotification' | 'lastExtensionDate' | 'contractClosureDate' | 'contractVoCalculation' | 'lastMiscExpDate'>
  & { contractInfo: (
    { __typename?: 'ContractInfo' }
    & Pick<ContractInfo, 'contractSum' | 'contractDate'>
  ), contractVO: (
    { __typename?: 'ContractVO' }
    & Pick<ContractVo, 'voSum' | 'voOmission' | 'voSumCount' | 'voOmissionCount' | 'voClientCount' | 'voSubconCount' | 'totalVoAmt'>
  ), outstandingAdvances: Array<(
    { __typename?: 'ContractAdvanceEntity' }
    & Pick<ContractAdvanceEntity, 'docNo' | 'docDate' | 'docAmt' | 'baseAmt' | 'outstandingAmt'>
  )>, outstandingClaims: Array<(
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'docNo' | 'docDate' | 'docAmt' | 'baseAmt' | 'outstandingAmt'>
  )>, retention: (
    { __typename?: 'RetentionInfo' }
    & Pick<RetentionInfo, 'retentionSum' | 'retentionBalance' | 'releasedSum'>
  ) }
);

export type CreateContractCertificateMutationVariables = Exact<{
  input: ContractCertificateInput;
  ID: Scalars['String'];
}>;


export type CreateContractCertificateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createContractCertificate'>
);

export type GetConSubconRetentionQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
}>;


export type GetConSubconRetentionQuery = (
  { __typename?: 'Query' }
  & { getRetentionClaim: Array<(
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'ID' | 'isGlExport' | 'accountID' | 'balanceAdvanceAmt' | 'balanceDeductionAmt' | 'balanceRetentionAmt' | 'baseAmt' | 'cancellationDate' | 'cancellationRemarks' | 'claimDocNo' | 'contractID' | 'createdBy' | 'createdTs' | 'currencyID' | 'currentAdvRecoupmentAmt' | 'currentCertifiedAmt' | 'currentDayWorkAmt' | 'currentDeductionAmt' | 'currentMosAmt' | 'currentVOClaimAmt' | 'customerID' | 'description' | 'docAmt' | 'docDate' | 'docDueDate' | 'docNo' | 'docRef' | 'docRefID' | 'docRefTable' | 'exchangeRate' | 'localBaseAmt' | 'localDocAmt' | 'localTaxAmt' | 'modBy' | 'modTs' | 'previousCertifiedAmt' | 'previousMosAmt' | 'previousVOClaimAmt' | 'rejectionDate' | 'rejectionRemarks' | 'retentionAmt' | 'revisedNo' | 'sequenceNo' | 'submittedCertifiedAmt' | 'submittedDate' | 'submittedDayWorkAmt' | 'submittedDeductionAmt' | 'submittedMosAmt' | 'submittedRetentionAmt' | 'submittedVOClaimAmt' | 'taxAmt' | 'taxRate' | 'taxSchemeID' | 'transactionDate' | 'transactionStatus' | 'voDisplayAmt'>
    & { toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'approvalLevel' | 'body' | 'createdBy' | 'createdTs' | 'docRefAmt' | 'docRefID' | 'docRefTable' | 'modBy' | 'modTs' | 'notifiedDate' | 'subject' | 'submitterID' | 'toDoStatus'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'approverID' | 'createdBy' | 'createdTs' | 'logDate' | 'modBy' | 'modTs' | 'notifiedDate' | 'remarks' | 'toDoID' | 'toDoStatus'>
      )>> }
    )> }
  )>, retention: (
    { __typename?: 'RetentionInfo' }
    & Pick<RetentionInfo, 'retentionSum' | 'retentionBalance' | 'releasedSum'>
  ) }
);

export type GetContractExtensionQueryVariables = Exact<{
  ID: Scalars['String'];
  customerID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetContractExtensionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'lastExtensionDate'>
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'accountID' | 'contractNo' | 'customerID' | 'description' | 'contractDate' | 'contractSum' | 'taxSchemeID' | 'startDate' | 'endDate' | 'creditTerm' | 'contractStatus' | 'extensionDate'>
    & { customerDetail?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'name' | 'contactNo'>
      & { contactPerson: Array<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ID' | 'name' | 'designation' | 'email' | 'contactNo'>
      )> }
    )> }
  )> }
);

export type RetentionCheckingQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
}>;


export type RetentionCheckingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'retentionChecking'>
);

export type GetContractTitleQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetContractTitleQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'description' | 'contractNo' | 'contractStatus' | 'department'>
  )> }
);

export type InsertUpdateSiteMutationVariables = Exact<{
  siteInput: Array<SiteInput> | SiteInput;
  contractID: Scalars['String'];
}>;


export type InsertUpdateSiteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'insertUpdateSite'>
);

export type AssociateCheckingQueryVariables = Exact<{
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type AssociateCheckingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'claimChecking' | 'voChecking'>
);

export type GetMiscExpenseQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetMiscExpenseQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'lastMiscExpDate'>
  & { getMiscExpense: Array<(
    { __typename?: 'MiscExpenseEntity' }
    & Pick<MiscExpenseEntity, 'ID' | 'status' | 'accountID' | 'contractID' | 'costCategoryID' | 'docRef' | 'docDate' | 'description' | 'docAmt' | 'baseAmt' | 'taxRate' | 'taxAmt' | 'taxSchemeID'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'title'>
    )>, costCategory?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'name' | 'costClass'>
    )> }
  )> }
);

export type CreateMiscExpenseMutationVariables = Exact<{
  input: MiscExpenseInput;
}>;


export type CreateMiscExpenseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createMiscExpense'>
);

export type UpdateMiscExpenseMutationVariables = Exact<{
  input: MiscExpenseInput;
}>;


export type UpdateMiscExpenseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateMiscExpense'>
);

export type CancelMiscExpenseMutationVariables = Exact<{
  input: MiscExpenseCancellationInput;
  ID: Scalars['String'];
}>;


export type CancelMiscExpenseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelMiscExpense'>
);

export type GetSubcontractQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractStatus?: Maybe<ContractStatus>;
}>;


export type GetSubcontractQuery = (
  { __typename?: 'Query' }
  & { getSubcontract: Array<(
    { __typename?: 'SubcontractEntity' }
    & Pick<SubcontractEntity, 'ID' | 'title' | 'accountID' | 'contractID' | 'subcontractorID' | 'costCategoryID' | 'subcontractNo' | 'description' | 'subcontractDate' | 'taxSchemeID' | 'subcontractSum' | 'revisedSubcontractSum' | 'startDate' | 'endDate' | 'progressRetentionPerc' | 'maxRetentionPerc' | 'maxRetentionSum' | 'targetProfitPerc' | 'cmgdDate' | 'cpcDate' | 'dlpDate' | 'latestRunNum' | 'docNumChecking' | 'ladAmt' | 'ladRate' | 'creditTerm' | 'subcontractStatus' | 'contactPerson' | 'extensionDate' | 'closureRemarks' | 'closureDate' | 'modBy' | 'nominatedSC'>
    & { site?: Maybe<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'name' | 'contractID' | 'address'>
    )>, subcontractorDetail?: Maybe<(
      { __typename?: 'SubcontractorEntity' }
      & Pick<SubcontractorEntity, 'name' | 'ID' | 'address' | 'contactNo'>
    )>, mainContractDetail?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'title' | 'ID'>
    )>, VO?: Maybe<Array<(
      { __typename?: 'SubcontractVOEntity' }
      & Pick<SubcontractVoEntity, 'submissionAmt' | 'approvedAmt'>
    )>>, taxDetail?: Maybe<(
      { __typename?: 'ConTaxSchemeEntity' }
      & Pick<ConTaxSchemeEntity, 'ID' | 'code' | 'description' | 'taxCategory' | 'taxTypeID' | 'taxClass' | 'createdTs' | 'commonStatus'>
      & { latestTax?: Maybe<(
        { __typename?: 'TaxObject' }
        & Pick<TaxObject, 'taxRate' | 'taxDate'>
      )>, taxEffective?: Maybe<Array<(
        { __typename?: 'ConTaxEffectiveDateEntity' }
        & Pick<ConTaxEffectiveDateEntity, 'date' | 'taxRate' | 'createdTs'>
      )>> }
    )>, tradeDetail?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name'>
    )> }
  )> }
);

export type CreateSubcontractMutationVariables = Exact<{
  input: SubcontractInput;
}>;


export type CreateSubcontractMutation = (
  { __typename?: 'Mutation' }
  & { createSubcontract: (
    { __typename?: 'SubcontractEntity' }
    & Pick<SubcontractEntity, 'ID' | 'title'>
  ) }
);

export type UpdateSubcontractMutationVariables = Exact<{
  input: SubcontractInput;
}>;


export type UpdateSubcontractMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateSubcontract'>
);

export type GetSubcontractGuaranteeQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
}>;


export type GetSubcontractGuaranteeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconGuaranteeInfo'>
  & { getSubcontractGuarantee: Array<(
    { __typename?: 'SubcontractGuaranteeEntity' }
    & Pick<SubcontractGuaranteeEntity, 'ID' | 'modBy' | 'accountID' | 'subcontractID' | 'docRef' | 'guaranteeTypeID' | 'description' | 'docAmt' | 'startDate' | 'expiryDate' | 'issuer' | 'collateralAmt' | 'remarks' | 'cancellationDate' | 'guaranteeStatus'>
    & { guaranteeType?: Maybe<(
      { __typename?: 'GuaranteeTypeEntity' }
      & Pick<GuaranteeTypeEntity, 'name'>
    )> }
  )> }
);

export type CreateSubcontractGuaranteeMutationVariables = Exact<{
  input: SubcontractGuaranteeInput;
}>;


export type CreateSubcontractGuaranteeMutation = (
  { __typename?: 'Mutation' }
  & { createSubcontractGuarantee: (
    { __typename?: 'SubcontractGuaranteeEntity' }
    & Pick<SubcontractGuaranteeEntity, 'ID'>
  ) }
);

export type UpdateSubcontractGuaranteeMutationVariables = Exact<{
  input: SubcontractGuaranteeInput;
}>;


export type UpdateSubcontractGuaranteeMutation = (
  { __typename?: 'Mutation' }
  & { updateSubcontractGuarantee: (
    { __typename?: 'SubcontractGuaranteeEntity' }
    & Pick<SubcontractGuaranteeEntity, 'ID'>
  ) }
);

export type GetSubcontractInsuranceQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
}>;


export type GetSubcontractInsuranceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconInsuranceInfo'>
  & { getSubcontractInsurance: Array<(
    { __typename?: 'SubcontractInsuranceEntity' }
    & Pick<SubcontractInsuranceEntity, 'ID' | 'modBy' | 'accountID' | 'subcontractID' | 'docRef' | 'insuranceTypeID' | 'description' | 'docAmt' | 'startDate' | 'expiryDate' | 'issuer' | 'remarks' | 'cancellationDate' | 'insuranceStatus'>
    & { insuranceType?: Maybe<(
      { __typename?: 'InsuranceTypeEntity' }
      & Pick<InsuranceTypeEntity, 'name'>
    )> }
  )> }
);

export type CreateSubcontractInsuranceMutationVariables = Exact<{
  input: SubcontractInsuranceInput;
}>;


export type CreateSubcontractInsuranceMutation = (
  { __typename?: 'Mutation' }
  & { createSubcontractInsurance: (
    { __typename?: 'SubcontractInsuranceEntity' }
    & Pick<SubcontractInsuranceEntity, 'ID'>
  ) }
);

export type UpdateSubcontractInsuranceMutationVariables = Exact<{
  input: SubcontractInsuranceInput;
}>;


export type UpdateSubcontractInsuranceMutation = (
  { __typename?: 'Mutation' }
  & { updateSubcontractInsurance: (
    { __typename?: 'SubcontractInsuranceEntity' }
    & Pick<SubcontractInsuranceEntity, 'ID'>
  ) }
);

export type CancelSubcontractGuaranteeMutationVariables = Exact<{
  input: SubcontractGuaranteeCancellationInput;
}>;


export type CancelSubcontractGuaranteeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSubcontractGuarantee'>
);

export type CancelSubcontractInsuranceMutationVariables = Exact<{
  input: SubcontractInsuranceCancellationInput;
}>;


export type CancelSubcontractInsuranceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSubcontractInsurance'>
);

export type GetSubcontractSummaryQueryVariables = Exact<{
  subcontractID: Scalars['String'];
}>;


export type GetSubcontractSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconGuaranteeInfo' | 'subconInsuranceInfo' | 'submittedVOSubconNotification' | 'subconLastExtensionDate' | 'subconClosureDate'>
  & { subcontractInfo: (
    { __typename?: 'SubontractInfo' }
    & Pick<SubontractInfo, 'subcontractSum' | 'subcontractDate'>
  ), subcontractVO: (
    { __typename?: 'SubcontractVO' }
    & Pick<SubcontractVo, 'voSum' | 'voOmission' | 'voSumCount' | 'voOmissionCount' | 'totalVoAmt'>
  ), subconOutstandingAdvances: Array<(
    { __typename?: 'SubcontractAdvanceEntity' }
    & Pick<SubcontractAdvanceEntity, 'docNo' | 'docDate' | 'docAmt' | 'baseAmt' | 'outstandingAmt'>
  )>, subconOutstandingClaims: Array<(
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'docNo' | 'docDate' | 'docAmt' | 'baseAmt' | 'outstandingAmt'>
  )>, retention: (
    { __typename?: 'RetentionInfo' }
    & Pick<RetentionInfo, 'retentionSum' | 'retentionBalance' | 'releasedSum'>
  ) }
);

export type GetIpcSummaryQueryVariables = Exact<{
  subcontractID: Scalars['String'];
}>;


export type GetIpcSummaryQuery = (
  { __typename?: 'Query' }
  & { retention: (
    { __typename?: 'RetentionInfo' }
    & Pick<RetentionInfo, 'retentionSum' | 'retentionBalance' | 'releasedSum'>
  ) }
);

export type ActionOnSubcontractStatusMutationVariables = Exact<{
  input: SubcontractActionInput;
  ID: Scalars['String'];
}>;


export type ActionOnSubcontractStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'actionOnSubcontractStatus'>
);

export type CreateSubcontractExtensionDateMutationVariables = Exact<{
  input: SubcontractExtensionInput;
  ID: Scalars['String'];
}>;


export type CreateSubcontractExtensionDateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createSubcontractExtensionDate'>
);

export type CreateSubcontractCertificateMutationVariables = Exact<{
  input: SubcontractCertificateInput;
  ID: Scalars['String'];
}>;


export type CreateSubcontractCertificateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createSubcontractCertificate'>
);

export type GetSubcontractExtensionQueryVariables = Exact<{
  ID: Scalars['String'];
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractExtensionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconLastExtensionDate'>
  & { getSubcontract: Array<(
    { __typename?: 'SubcontractEntity' }
    & Pick<SubcontractEntity, 'ID' | 'title' | 'subcontractNo' | 'description' | 'subcontractDate' | 'subcontractSum' | 'taxSchemeID' | 'startDate' | 'endDate' | 'subcontractStatus' | 'extensionDate'>
    & { mainContractDetail?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'title' | 'ID'>
    )> }
  )> }
);

export type GetSubcontractTitleQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractTitleQuery = (
  { __typename?: 'Query' }
  & { getSubcontract: Array<(
    { __typename?: 'SubcontractEntity' }
    & Pick<SubcontractEntity, 'ID' | 'title'>
  )> }
);

export type GetSubcontractRelatedQueryVariables = Exact<{
  subcontractID?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractRelatedQuery = (
  { __typename?: 'Query' }
  & { getSubcontractRelated: Array<(
    { __typename?: 'SubcontractEntity' }
    & Pick<SubcontractEntity, 'ID' | 'title'>
  )> }
);

export type SubconBudgetCheckingQueryVariables = Exact<{
  amount: Scalars['Float'];
  contractID: Scalars['String'];
  costCategoryID: Scalars['String'];
}>;


export type SubconBudgetCheckingQuery = (
  { __typename?: 'Query' }
  & { subconBudgetChecking: (
    { __typename?: 'BudgetInfo' }
    & Pick<BudgetInfo, 'isExceeded' | 'isAllocated'>
  ) }
);

export type GetContractVoQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetContractVoQuery = (
  { __typename?: 'Query' }
  & { getContractVO: Array<(
    { __typename?: 'ContractVOEntity' }
    & Pick<ContractVoEntity, 'ID' | 'accountID' | 'contractID' | 'docNo' | 'docRef' | 'startDate' | 'endDate' | 'description' | 'architectNo' | 'engineeringNo' | 'VOType' | 'VOStatus' | 'submissionAmt' | 'approvedAmt' | 'submittedDate' | 'rejectedDate' | 'docDate' | 'remarks'>
    & { contractVORevenueWbs?: Maybe<Array<(
      { __typename?: 'ContractVORevenueWbsEntity' }
      & Pick<ContractVoRevenueWbsEntity, 'ID' | 'previousRevenueVOAmt' | 'contractVOID' | 'wbsID' | 'revenueCategoryID' | 'submissionAmt' | 'approvedAmt'>
      & { wbsBudgetDetail?: Maybe<(
        { __typename?: 'WbsBudgetDetailEntity' }
        & Pick<WbsBudgetDetailEntity, 'ID' | 'wbsID' | 'budgetAmt' | 'revenueCategoryID'>
        & { revenueCategory?: Maybe<(
          { __typename?: 'RevenueCategoryEntity' }
          & Pick<RevenueCategoryEntity, 'ID' | 'name'>
        )> }
      )> }
    )>>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type CreateContractVoMutationVariables = Exact<{
  input: ContractVoInput;
  voDetails?: Maybe<Array<ContractVoDetailsInput> | ContractVoDetailsInput>;
}>;


export type CreateContractVoMutation = (
  { __typename?: 'Mutation' }
  & { createContractVO: (
    { __typename?: 'ContractVOEntity' }
    & Pick<ContractVoEntity, 'ID'>
  ) }
);

export type UpdateContractVoMutationVariables = Exact<{
  input: ContractVoInput;
  voDetails?: Maybe<Array<ContractVoDetailsInput> | ContractVoDetailsInput>;
}>;


export type UpdateContractVoMutation = (
  { __typename?: 'Mutation' }
  & { updateContractVO: (
    { __typename?: 'ContractVOEntity' }
    & Pick<ContractVoEntity, 'ID'>
  ) }
);

export type ActionOnVoStatusMutationVariables = Exact<{
  input: ContractVoActionInput;
  ID: Scalars['String'];
  itemApprovedAmount?: Maybe<Array<VoItemApprovedAmountInput> | VoItemApprovedAmountInput>;
}>;


export type ActionOnVoStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'actionOnVOStatus'>
);

export type GetContractVoSummaryQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetContractVoSummaryQuery = (
  { __typename?: 'Query' }
  & { contractVO: (
    { __typename?: 'ContractVO' }
    & Pick<ContractVo, 'voSum' | 'voOmission' | 'voSumCount' | 'voOmissionCount' | 'totalVoAmt'>
  ) }
);

export type GetSubcontractVoQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractVoQuery = (
  { __typename?: 'Query' }
  & { getSubcontractVO: Array<(
    { __typename?: 'SubcontractVOEntity' }
    & Pick<SubcontractVoEntity, 'ID' | 'accountID' | 'subcontractID' | 'docNo' | 'docRef' | 'startDate' | 'endDate' | 'description' | 'architectNo' | 'engineeringNo' | 'VOType' | 'VOStatus' | 'submissionAmt' | 'approvedAmt' | 'remarks' | 'submittedDate' | 'rejectedDate' | 'docDate'>
    & { toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type ActionOnSubconVoStatusMutationVariables = Exact<{
  input: SubcontractVoActionInput;
  ID: Scalars['String'];
}>;


export type ActionOnSubconVoStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'actionOnSubconVOStatus'>
);

export type GetSubcontractVoSummaryQueryVariables = Exact<{
  subcontractID: Scalars['String'];
}>;


export type GetSubcontractVoSummaryQuery = (
  { __typename?: 'Query' }
  & { subcontractVO: (
    { __typename?: 'SubcontractVO' }
    & Pick<SubcontractVo, 'voSum' | 'voOmission' | 'voSumCount' | 'voOmissionCount' | 'totalVoAmt'>
  ) }
);

export type GetClientVoQueryVariables = Exact<{
  contractID: Scalars['String'];
  voStatus: VoStatus;
}>;


export type GetClientVoQuery = (
  { __typename?: 'Query' }
  & { getContractVO: Array<(
    { __typename?: 'ContractVOEntity' }
    & Pick<ContractVoEntity, 'approvedAmt' | 'submissionAmt' | 'VOType' | 'docNo' | 'docRef' | 'submittedDate' | 'docDate' | 'description'>
  )> }
);

export type GetSubconVoQueryVariables = Exact<{
  subcontractID: Scalars['String'];
  voStatus: VoStatus;
}>;


export type GetSubconVoQuery = (
  { __typename?: 'Query' }
  & { getSubcontractVO: Array<(
    { __typename?: 'SubcontractVOEntity' }
    & Pick<SubcontractVoEntity, 'approvedAmt' | 'submissionAmt' | 'VOType' | 'docNo' | 'docRef' | 'submittedDate' | 'docDate' | 'description'>
  )> }
);

export type DocumentListingQueryVariables = Exact<{
  refID?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  notDescription?: Maybe<Scalars['String']>;
  hasDescription?: Maybe<Scalars['Boolean']>;
}>;


export type DocumentListingQuery = (
  { __typename?: 'Query' }
  & { DocumentListing?: Maybe<Array<(
    { __typename?: 'DocumentsEntity' }
    & Pick<DocumentsEntity, 'ID' | 'fileName' | 'fileURL' | 'mediaType' | 'createdTs' | 'description' | 'refID'>
  )>> }
);

export type GetAdjustmentTypeQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetAdjustmentTypeQuery = (
  { __typename?: 'Query' }
  & { getAdjustmentType: Array<(
    { __typename?: 'AdjustmentTypeEntity' }
    & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    & { glAdjustmentDynamic?: Maybe<Array<(
      { __typename?: 'GLAdjustmentDynamicEntity' }
      & Pick<GlAdjustmentDynamicEntity, 'ID' | 'accountCode'>
    )>> }
  )> }
);

export type GetGlAdjustmentTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGlAdjustmentTypeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGLAdjustmentType'>
);

export type GetGlTaxTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGlTaxTypeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGLTaxType'>
);

export type CreateApprovalPolicyMutationVariables = Exact<{
  input: ApprovalPolicyInput;
}>;


export type CreateApprovalPolicyMutation = (
  { __typename?: 'Mutation' }
  & { createApprovalPolicy: (
    { __typename?: 'ApprovalPolicyEntity' }
    & Pick<ApprovalPolicyEntity, 'ID'>
  ) }
);

export type CreateApprovalPolicyAssignmentMutationVariables = Exact<{
  input: ApprovalPolicyAssignmentInput;
}>;


export type CreateApprovalPolicyAssignmentMutation = (
  { __typename?: 'Mutation' }
  & { createApprovalPolicyAssignment: (
    { __typename?: 'ApprovalPolicyEntity' }
    & Pick<ApprovalPolicyEntity, 'ID'>
  ) }
);

export type UpdateApprovalPolicyMutationVariables = Exact<{
  input: ApprovalPolicyInput;
}>;


export type UpdateApprovalPolicyMutation = (
  { __typename?: 'Mutation' }
  & { updateApprovalPolicy: (
    { __typename?: 'ApprovalPolicyEntity' }
    & Pick<ApprovalPolicyEntity, 'ID'>
  ) }
);

export type UpdateApprovalPolicyAssignmentMutationVariables = Exact<{
  input: ApprovalPolicyAssignmentInput;
}>;


export type UpdateApprovalPolicyAssignmentMutation = (
  { __typename?: 'Mutation' }
  & { updateApprovalPolicyAssignment: (
    { __typename?: 'ApprovalPolicyAssignmentEntity' }
    & Pick<ApprovalPolicyAssignmentEntity, 'ID'>
  ) }
);

export type DeleteApprovalPolicyAssignmentMutationVariables = Exact<{
  input: ApprovalPolicyAssignmentDeleteInput;
}>;


export type DeleteApprovalPolicyAssignmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteApprovalPolicyAssignment'>
);

export type DeleteApprovalPolicyMutationVariables = Exact<{
  input: ApprovalPolicyDeleteInput;
}>;


export type DeleteApprovalPolicyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteApprovalPolicy'>
);

export type GetApprovalPolicyQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  category: Scalars['String'];
}>;


export type GetApprovalPolicyQuery = (
  { __typename?: 'Query' }
  & { getApprovalPolicy?: Maybe<Array<(
    { __typename?: 'ApprovalPolicyEntity' }
    & Pick<ApprovalPolicyEntity, 'ID' | 'approvalList' | 'category' | 'createdTs' | 'modTs' | 'lowerLimit' | 'upperLimit' | 'latestLimit'>
    & { approvalPolicyAssignment?: Maybe<Array<(
      { __typename?: 'ApprovalPolicyAssignmentEntity' }
      & Pick<ApprovalPolicyAssignmentEntity, 'approvalPolicyID' | 'ID' | 'contractID' | 'approvalList' | 'category'>
    )>> }
  )>> }
);

export type GetApprovalPolicyGuidelineQueryVariables = Exact<{
  category?: Maybe<Scalars['String']>;
}>;


export type GetApprovalPolicyGuidelineQuery = (
  { __typename?: 'Query' }
  & { getApprovalPolicyGuideline: Array<(
    { __typename?: 'ApprovalPolicyEntity' }
    & Pick<ApprovalPolicyEntity, 'ID' | 'approvalList' | 'category' | 'createdTs' | 'modTs' | 'lowerLimit' | 'upperLimit'>
    & { approvalPolicyAssignment?: Maybe<Array<(
      { __typename?: 'ApprovalPolicyAssignmentEntity' }
      & Pick<ApprovalPolicyAssignmentEntity, 'approvalPolicyID' | 'ID' | 'contractID' | 'approvalList' | 'category'>
    )>> }
  )> }
);

export type GetApprovalPolicyAssignmentQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  category: Scalars['String'];
  contractID: Scalars['String'];
}>;


export type GetApprovalPolicyAssignmentQuery = (
  { __typename?: 'Query' }
  & { getApprovalPolicyAssignment?: Maybe<Array<(
    { __typename?: 'ApprovalPolicyAssignmentEntity' }
    & Pick<ApprovalPolicyAssignmentEntity, 'ID' | 'contractID' | 'approvalPolicyID' | 'category' | 'approvalList' | 'accountID' | 'createdTs' | 'createdBy' | 'modBy' | 'modTs' | 'latestLimitAssignment' | 'lowerLimit' | 'upperLimit'>
  )>> }
);

export type GetApprovalPolicyAssignmentGuidelineQueryVariables = Exact<{
  category?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetApprovalPolicyAssignmentGuidelineQuery = (
  { __typename?: 'Query' }
  & { getApprovalPolicyAssignmentGuideline: Array<(
    { __typename?: 'ApprovalPolicyAssignmentEntity' }
    & Pick<ApprovalPolicyAssignmentEntity, 'ID' | 'contractID' | 'approvalPolicyID' | 'category' | 'approvalList' | 'accountID' | 'createdTs' | 'createdBy' | 'modBy' | 'modTs'>
  )> }
);

export type GetSiteMaterialMasterQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSiteMaterialMasterQuery = (
  { __typename?: 'Query' }
  & { getSiteMaterialPolicy: Array<(
    { __typename?: 'SiteMaterialPolicyEntity' }
    & Pick<SiteMaterialPolicyEntity, 'ID' | 'createdTs' | 'modTs' | 'costItemID' | 'siteMaterialID'>
    & { costItem?: Maybe<(
      { __typename?: 'CostItemEntity' }
      & Pick<CostItemEntity, 'ID' | 'name'>
    )>, siteMaterial?: Maybe<(
      { __typename?: 'SiteMaterialEntity' }
      & Pick<SiteMaterialEntity, 'ID' | 'name'>
    )> }
  )> }
);

export type GetSiteMaterialContractQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetSiteMaterialContractQuery = (
  { __typename?: 'Query' }
  & { getSiteMaterialContractPolicy: Array<(
    { __typename?: 'SiteMaterialContractPolicyEntity' }
    & Pick<SiteMaterialContractPolicyEntity, 'ID' | 'createdTs' | 'modTs' | 'costItemID' | 'siteMaterialID'>
    & { costItem?: Maybe<(
      { __typename?: 'CostItemEntity' }
      & Pick<CostItemEntity, 'ID' | 'name'>
    )>, siteMaterial?: Maybe<(
      { __typename?: 'SiteMaterialEntity' }
      & Pick<SiteMaterialEntity, 'ID' | 'name'>
    )> }
  )> }
);

export type GetCalendarQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetCalendarQuery = (
  { __typename?: 'Query' }
  & { getCalendar: Array<(
    { __typename?: 'CalendarEntity' }
    & Pick<CalendarEntity, 'ID' | 'state' | 'stateID' | 'year' | 'weekend' | 'isDefault'>
    & { publicHoliday: Array<(
      { __typename?: 'PublicHolidayEntity' }
      & Pick<PublicHolidayEntity, 'ID' | 'name'>
    )> }
  )> }
);

export type CreateCalendarMutationVariables = Exact<{
  input: CalendarInput;
  publicHolidayID: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreateCalendarMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createCalendar'>
);

export type UpdateCalendarMutationVariables = Exact<{
  input: CalendarInput;
  publicHolidayID: Array<Scalars['String']> | Scalars['String'];
}>;


export type UpdateCalendarMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCalendar'>
);

export type CancelCalendarMutationVariables = Exact<{
  input: CalendarDeleteInput;
}>;


export type CancelCalendarMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelCalendar'>
);

export type DeleteCalendarMutationVariables = Exact<{
  input: CalendarDeleteInput;
}>;


export type DeleteCalendarMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCalendar'>
);

export type GetCalendarPhPolicyQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  calendarID?: Maybe<Scalars['String']>;
}>;


export type GetCalendarPhPolicyQuery = (
  { __typename?: 'Query' }
  & { getCalendarPHPolicy: Array<(
    { __typename?: 'CalendarPHPolicyEntity' }
    & Pick<CalendarPhPolicyEntity, 'ID' | 'calendarID' | 'publicHolidayID'>
  )> }
);

export type GetCostCategoryQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  costClass?: Maybe<CostClass>;
  isLastNode?: Maybe<Scalars['Boolean']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
}>;


export type GetCostCategoryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'costCategoryDates'>
  & { getCostCategory: Array<(
    { __typename?: 'CostCategoryEntity' }
    & Pick<CostCategoryEntity, 'ID' | 'name' | 'modTs' | 'accountID' | 'parentCostCategoryID' | 'isLastNode' | 'pnmCostCat' | 'costClass' | 'tankType' | 'fuelConsumption' | 'glAccountCode' | 'commonStatus' | 'childrenIDs' | 'classificationCodeID'>
    & { supplier?: Maybe<Array<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'name' | 'ID'>
    )>>, subcontractor?: Maybe<Array<(
      { __typename?: 'SubcontractorEntity' }
      & Pick<SubcontractorEntity, 'name' | 'ID'>
    )>>, costItem?: Maybe<Array<(
      { __typename?: 'CostItemEntity' }
      & Pick<CostItemEntity, 'ID' | 'name' | 'unitPrice' | 'code' | 'uomID' | 'markupPerc' | 'costCategoryID' | 'variancePerc' | 'onBehalf' | 'isInventory' | 'isInventoryChecking' | 'taxSchemeID'>
      & { UOM?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'ID' | 'name' | 'code'>
      )> }
    )>>, budget: Array<(
      { __typename?: 'ProjectBudgetEntity' }
      & Pick<ProjectBudgetEntity, 'ID' | 'createdTs' | 'budgetAmt'>
    )> }
  )> }
);

export type GetCostCategoryTreeQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  costClass?: Maybe<CostClass>;
  isLastNode?: Maybe<Scalars['Boolean']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
}>;


export type GetCostCategoryTreeQuery = (
  { __typename?: 'Query' }
  & { getCostCategory: Array<(
    { __typename?: 'CostCategoryEntity' }
    & Pick<CostCategoryEntity, 'ID' | 'name' | 'accountID' | 'parentCostCategoryID' | 'classificationCodeID' | 'isLastNode' | 'costClass' | 'commonStatus' | 'childrenIDs'>
    & { costItem?: Maybe<Array<(
      { __typename?: 'CostItemEntity' }
      & Pick<CostItemEntity, 'ID' | 'uomID' | 'costCategoryID' | 'taxSchemeID' | 'name' | 'unitPrice' | 'code' | 'markupPerc' | 'variancePerc' | 'onBehalf' | 'isDaywork' | 'isInventory' | 'isInventoryChecking' | 'commonStatus'>
      & { UOM?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'ID' | 'name' | 'code'>
      )>, costCategory?: Maybe<(
        { __typename?: 'CostCategoryEntity' }
        & Pick<CostCategoryEntity, 'ID' | 'name'>
      )> }
    )>> }
  )> }
);

export type GetCostCategoryByClassQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCostCategoryByClassQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCostCategoryByClass'>
);

export type CreateCostCategoryMutationVariables = Exact<{
  input: CostCategoryInput;
}>;


export type CreateCostCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createCostCategory: (
    { __typename?: 'CostCategoryEntity' }
    & Pick<CostCategoryEntity, 'ID'>
  ) }
);

export type UpdateCostCategoryMutationVariables = Exact<{
  input: CostCategoryInput;
}>;


export type UpdateCostCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCostCategory'>
);

export type DeleteCostCategoryMutationVariables = Exact<{
  input: CostCategoryUpdateInput;
}>;


export type DeleteCostCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCostCategory'>
);

export type DeleteSupplierInCCategoryMutationVariables = Exact<{
  supplierID: Array<Scalars['String']> | Scalars['String'];
  costCategoryID: Scalars['String'];
}>;


export type DeleteSupplierInCCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSupplierInCCategory'>
);

export type AddSupplierInCCategoryMutationVariables = Exact<{
  supplierID: Array<Scalars['String']> | Scalars['String'];
  costCategoryID: Scalars['String'];
}>;


export type AddSupplierInCCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addSupplierInCCategory'>
);

export type CancelCostCategoryMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type CancelCostCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelCostCategory'>
);

export type CreateBatchCostCategoryMutationVariables = Exact<{
  input: Array<CostCategoryInput> | CostCategoryInput;
}>;


export type CreateBatchCostCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createBatchCostCategory'>
);

export type CreateBatchCostCategoryWithoutItemMutationVariables = Exact<{
  input: Array<TreeCostCategoryInput> | TreeCostCategoryInput;
}>;


export type CreateBatchCostCategoryWithoutItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createBatchCostCategoryWithoutItem'>
);

export type ActivateCostCategoryMutationVariables = Exact<{
  input: CostCategoryUpdateInput;
}>;


export type ActivateCostCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateCostCategory'>
);

export type DeleteCostCategoryCostitemMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteCostCategoryCostitemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCostCategoryCostItem'>
);

export type GetSubcontractorCostCategoryQueryVariables = Exact<{
  costCategoryID: Scalars['String'];
}>;


export type GetSubcontractorCostCategoryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSubcontractorCostCategory'>
);

export type GetSupplierCostCategoryQueryVariables = Exact<{
  costCategoryID: Scalars['String'];
}>;


export type GetSupplierCostCategoryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSupplierCostCategory'>
);

export type GetAssignedPlantMachineryQueryVariables = Exact<{
  costCategoryID: Scalars['String'];
}>;


export type GetAssignedPlantMachineryQuery = (
  { __typename?: 'Query' }
  & { getPlantMachinery: Array<(
    { __typename?: 'PlantMachineryEntity' }
    & Pick<PlantMachineryEntity, 'ID' | 'costCategoryID' | 'plantMachineryStatus' | 'regNo' | 'name' | 'remarks' | 'purchaseDate'>
  )> }
);

export type AddCostCategoryMutationVariables = Exact<{
  input: TreeCostCategoryInput;
}>;


export type AddCostCategoryMutation = (
  { __typename?: 'Mutation' }
  & { addCostCategory: (
    { __typename?: 'CostCategoryEntity' }
    & Pick<CostCategoryEntity, 'ID'>
  ) }
);

export type EditCostCategoryMutationVariables = Exact<{
  input: TreeCostCategoryInput;
}>;


export type EditCostCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'editCostCategory'>
);

export type DeleteTreeCostCategoryMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteTreeCostCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTreeCostCategory'>
);

export type CostCategoryImportValidationMutationVariables = Exact<{
  CostCategoryImportInput: Array<CostCategoryImportInput> | CostCategoryImportInput;
}>;


export type CostCategoryImportValidationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'costCategoryImportValidation'>
);

export type CostCategoryImportMutationVariables = Exact<{
  batchID: Scalars['String'];
}>;


export type CostCategoryImportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'costCategoryImport'>
);

export type GetCostCenterQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  status?: Maybe<CostCenterType>;
}>;


export type GetCostCenterQuery = (
  { __typename?: 'Query' }
  & { getCostCenter: Array<(
    { __typename?: 'CostCenterEntity' }
    & Pick<CostCenterEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'ID' | 'accountID' | 'code' | 'description' | 'costCenterType' | 'commonStatus'>
  )> }
);

export type CreateCostCenterMutationVariables = Exact<{
  input: CostCenterInput;
}>;


export type CreateCostCenterMutation = (
  { __typename?: 'Mutation' }
  & { createCostCenter: (
    { __typename?: 'CostCenterEntity' }
    & Pick<CostCenterEntity, 'ID' | 'code' | 'description'>
  ) }
);

export type UpdateCostCenterMutationVariables = Exact<{
  input: CostCenterInput;
}>;


export type UpdateCostCenterMutation = (
  { __typename?: 'Mutation' }
  & { updateCostCenter: (
    { __typename?: 'CostCenterEntity' }
    & Pick<CostCenterEntity, 'ID' | 'code'>
  ) }
);

export type DeleteCostCenterMutationVariables = Exact<{
  ID: CostCenterDeleteInput;
}>;


export type DeleteCostCenterMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCostCenter'>
);

export type GetCostCodeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCostCodeQuery = (
  { __typename?: 'Query' }
  & { getCostCode: Array<(
    { __typename?: 'CostCodeEntity' }
    & Pick<CostCodeEntity, 'ID' | 'parentCostCodeID' | 'code' | 'name' | 'level' | 'isLastNode'>
  )> }
);

export type GetCostItemQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  isInventory?: Maybe<Scalars['Boolean']>;
}>;


export type GetCostItemQuery = (
  { __typename?: 'Query' }
  & { getCostItem: Array<(
    { __typename?: 'CostItemEntity' }
    & Pick<CostItemEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'ID' | 'uomID' | 'accountID' | 'taxSchemeID' | 'costCategoryID' | 'variancePerc' | 'commonStatus' | 'name' | 'code' | 'unitPrice' | 'onBehalf' | 'isDaywork' | 'isInventory' | 'markupPerc'>
    & { UOM?: Maybe<(
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'name' | 'code'>
    )>, costCategory?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name'>
    )> }
  )> }
);

export type GetCostItemWithCostCategoryQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetCostItemWithCostCategoryQuery = (
  { __typename?: 'Query' }
  & { getCostItem: Array<(
    { __typename?: 'CostItemEntity' }
    & Pick<CostItemEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'ID' | 'uomID' | 'accountID' | 'taxSchemeID' | 'costCategoryID' | 'variancePerc' | 'commonStatus' | 'name' | 'code' | 'unitPrice' | 'onBehalf' | 'isDaywork' | 'isInventory' | 'markupPerc'>
    & { UOM?: Maybe<(
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'name' | 'code'>
    )>, costCategory?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name'>
    )> }
  )>, getCostCategory: Array<(
    { __typename?: 'CostCategoryEntity' }
    & Pick<CostCategoryEntity, 'name'>
    & { supplier?: Maybe<Array<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'name' | 'ID'>
    )>> }
  )> }
);

export type CreateCostItemMutationVariables = Exact<{
  input: CostItemInput;
}>;


export type CreateCostItemMutation = (
  { __typename?: 'Mutation' }
  & { createCostItem: (
    { __typename?: 'CostItemEntity' }
    & Pick<CostItemEntity, 'ID' | 'name'>
  ) }
);

export type CancelCostItemMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type CancelCostItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelCostItem'>
);

export type ActivateCostItemMutationVariables = Exact<{
  input: CostItemDeleteInput;
}>;


export type ActivateCostItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateCostItem'>
);

export type DeleteCostItemMutationVariables = Exact<{
  input: CostItemDeleteInput;
}>;


export type DeleteCostItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCostItem'>
);

export type UpdateCostItemMutationVariables = Exact<{
  input: CostItemInput;
}>;


export type UpdateCostItemMutation = (
  { __typename?: 'Mutation' }
  & { updateCostItem: (
    { __typename?: 'CostItemEntity' }
    & Pick<CostItemEntity, 'ID' | 'name'>
  ) }
);

export type CreateBatchCostItemMutationVariables = Exact<{
  input: Array<CostItemInput> | CostItemInput;
  costCategoryID: Scalars['String'];
}>;


export type CreateBatchCostItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createBatchCostItem'>
);

export type GetCostItemBySupplierQueryVariables = Exact<{
  supplierID: Scalars['String'];
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetCostItemBySupplierQuery = (
  { __typename?: 'Query' }
  & { getCostItemBySupplier: Array<(
    { __typename?: 'CostItemEntity' }
    & Pick<CostItemEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'ID' | 'accountID' | 'taxSchemeID' | 'costCategoryID' | 'variancePerc' | 'commonStatus' | 'name' | 'code' | 'unitPrice' | 'onBehalf' | 'markupPerc'>
    & { UOM?: Maybe<(
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'name' | 'code'>
    )>, costCategory?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name'>
    )> }
  )> }
);

export type GetCostItemByWbsQueryVariables = Exact<{
  wbsID: Scalars['String'];
  contractID: Scalars['String'];
}>;


export type GetCostItemByWbsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCostItemByWbs'>
);

export type GetCostItemByWbsIDsQueryVariables = Exact<{
  wbsIDs: Array<Scalars['String']> | Scalars['String'];
  contractID: Scalars['String'];
}>;


export type GetCostItemByWbsIDsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCostItemByWbsIDs'>
);

export type GetCostCategoryItemQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCostCategoryItemQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCostCategoryItem'>
);

export type CostItemImportValidationMutationVariables = Exact<{
  CostItemImportInput: Array<CostItemImportInput> | CostItemImportInput;
}>;


export type CostItemImportValidationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'costItemImportValidation'>
);

export type CostItemImportMutationVariables = Exact<{
  batchID: Scalars['String'];
}>;


export type CostItemImportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'costItemImport'>
);

export type SaveGlAccountCodeMutationVariables = Exact<{
  glInterfaceInput: Array<GlInterfaceDynamicInput> | GlInterfaceDynamicInput;
  accountType: Scalars['String'];
}>;


export type SaveGlAccountCodeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'saveGLAccountCode'>
);

export type GetGlFileSetupQueryVariables = Exact<{
  accountType: Scalars['String'];
}>;


export type GetGlFileSetupQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getGLSetupFile'>
);

export type CreateGlInterfaceDataMutationVariables = Exact<{
  accountType: Scalars['String'];
  input: Array<GlInterfaceDetailInput> | GlInterfaceDetailInput;
}>;


export type CreateGlInterfaceDataMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createGLInterfaceData'>
);

export type CheckedGlEditableQueryVariables = Exact<{
  type: Scalars['String'];
}>;


export type CheckedGlEditableQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'checkedGLEditable'>
);

export type UpdateDepartmentGlAccountCodeMutationVariables = Exact<{
  input: Array<DepartmentGlInterfaceInput> | DepartmentGlInterfaceInput;
}>;


export type UpdateDepartmentGlAccountCodeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDepartmentGLAccountCode'>
);

export type GetGeneralSettingExecutiveSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGeneralSettingExecutiveSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'costCategoryDates' | 'guaranteeTypeDate' | 'insuranceTypeDate' | 'workshopDate' | 'paymentReminderDate' | 'uomDate' | 'calendarTypeDate' | 'holidayTypeDate' | 'jobTypeDate' | 'projectSiteDate' | 'publicHolidayDate' | 'siteMaterialDate' | 'wagesRatePolicyDate' | 'workingHourPolicyDate' | 'videoCamDate'>
);

export type GetGeneralSettingSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGeneralSettingSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'costCategoryDates' | 'guaranteeTypeDate' | 'insuranceTypeDate' | 'workshopDate' | 'paymentReminderDate'>
);

export type GetContractSubcontractGenSettingsSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContractSubcontractGenSettingsSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'revenueCategoryDate' | 'guaranteeTypeDate' | 'insuranceTypeDate'>
);

export type GetClientAccGenSettingsSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetClientAccGenSettingsSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'clientSetupDate' | 'paymentReminderDate'>
);

export type GetSubcontractorGenSettingsSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubcontractorGenSettingsSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subcontractorTypeDate' | 'subconSetupDate'>
);

export type GetSupplierGenSettingsSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSupplierGenSettingsSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierTypeDate' | 'supplierSetupDate'>
);

export type GetPnmGenSettingsSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPnmGenSettingsSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'workshopDate'>
);

export type GetPurchaseCostingGenSettingsSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPurchaseCostingGenSettingsSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'costCategoryDate' | 'costItemDates'>
);

export type GetCostCategoryDatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCostCategoryDatesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'costCategoryDates'>
);

export type GetGlAccountCodeGenSettingsSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGlAccountCodeGenSettingsSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'glAccountCodeDate'>
);

export type GetSiteGeneralSettingSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSiteGeneralSettingSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'costCategoryDates' | 'calendarTypeDate' | 'holidayTypeDate' | 'jobTypeDate' | 'projectSiteDate' | 'publicHolidayDate' | 'siteMaterialDate' | 'wagesRatePolicyDate' | 'workingHourPolicyDate' | 'videoCamDate'>
  & { loggedInUserProfile: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'accountID'>
  ) }
);

export type GetDefectSiteGeneralSettingSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDefectSiteGeneralSettingSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'costCategoryDates'>
);

export type GetAttendanceSiteGeneralSettingSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAttendanceSiteGeneralSettingSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'publicHolidayDate' | 'calendarTypeDate' | 'workingHourPolicyDate' | 'wagesRatePolicyDate' | 'workerProfileDate'>
);

export type GetInventorySiteGeneralSettingSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInventorySiteGeneralSettingSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'siteMaterialPolicyDate'>
);

export type GetSurveillanceSiteGeneralSettingSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSurveillanceSiteGeneralSettingSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'videoCamDate'>
);

export type GetSiteContractSettingSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSiteContractSettingSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'siteMaterialDate' | 'policyDate' | 'videoCamDate'>
);

export type GetGenSetSysAdminSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGenSetSysAdminSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'costCategoryDates' | 'userDate' | 'bankAccountDate' | 'docNumDate' | 'uomDate' | 'taxSchemeDate'>
);

export type GetApprovalPolicyDateQueryVariables = Exact<{
  parameter: Scalars['String'];
}>;


export type GetApprovalPolicyDateQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'approvalPolicyDate'>
);

export type GetGeneralSettingGlInterfaceSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGeneralSettingGlInterfaceSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'clientAccDate' | 'subconAccDate' | 'supplierAccDate' | 'purchaseAccDate' | 'pobAccDate' | 'bankAccDate' | 'taxAccDate' | 'tradeAccDate' | 'materialAccDate' | 'plantAccDate' | 'labourAccDate' | 'miscAccDate' | 'journalAccDate' | 'departmentDate'>
);

export type GetCustomerReminderSettingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetCustomerReminderSettingQuery = (
  { __typename?: 'Query' }
  & { getCustomerReminder: Array<(
    { __typename?: 'CustomerReminderEntity' }
    & Pick<CustomerReminderEntity, 'ID' | 'paymentReminder1' | 'paymentReminder2' | 'paymentReminder3' | 'paymentReminder4' | 'minimumAmt' | 'reminderFor' | 'printedCopy' | 'emailCopy' | 'commonStatus'>
  )> }
);

export type UpdateGuaranteeTypeMutationVariables = Exact<{
  input: GuarInsTypeInput;
}>;


export type UpdateGuaranteeTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateGuaranteeType: (
    { __typename?: 'GuaranteeTypeEntity' }
    & Pick<GuaranteeTypeEntity, 'ID' | 'name'>
  ) }
);

export type CreateGuaranteeTypeMutationVariables = Exact<{
  input: GuarInsTypeInput;
}>;


export type CreateGuaranteeTypeMutation = (
  { __typename?: 'Mutation' }
  & { createGuaranteeType: (
    { __typename?: 'GuaranteeTypeEntity' }
    & Pick<GuaranteeTypeEntity, 'name'>
  ) }
);

export type GetGuaranteeTypeQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
}>;


export type GetGuaranteeTypeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'guaranteeTypeDate'>
  & { getGuaranteeType: Array<(
    { __typename?: 'GuaranteeTypeEntity' }
    & Pick<GuaranteeTypeEntity, 'ID' | 'name' | 'commonStatus' | 'modTs'>
  )> }
);

export type DeleteGuaranteeTypeMutationVariables = Exact<{
  ID: GuarInsTypeDeleteInput;
}>;


export type DeleteGuaranteeTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteGuaranteeType'>
);

export type CancelGuaranteeTypeMutationVariables = Exact<{
  input: GuarInsTypeInput;
}>;


export type CancelGuaranteeTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelGuaranteeType'>
);

export type ActivateGuaranteeTypeMutationVariables = Exact<{
  input: GuarInsTypeInput;
}>;


export type ActivateGuaranteeTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateGuaranteeType'>
);

export type UpdateInsuranceTypeMutationVariables = Exact<{
  input: GuarInsTypeInput;
}>;


export type UpdateInsuranceTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateInsuranceType: (
    { __typename?: 'InsuranceTypeEntity' }
    & Pick<InsuranceTypeEntity, 'ID' | 'name'>
  ) }
);

export type CreateInsuranceTypeMutationVariables = Exact<{
  input: GuarInsTypeInput;
}>;


export type CreateInsuranceTypeMutation = (
  { __typename?: 'Mutation' }
  & { createInsuranceType: (
    { __typename?: 'InsuranceTypeEntity' }
    & Pick<InsuranceTypeEntity, 'name'>
  ) }
);

export type GetInsuranceTypeQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
}>;


export type GetInsuranceTypeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'insuranceTypeDate'>
  & { getInsuranceType: Array<(
    { __typename?: 'InsuranceTypeEntity' }
    & Pick<InsuranceTypeEntity, 'ID' | 'name' | 'commonStatus' | 'modTs'>
  )> }
);

export type DeleteInsuranceTypeMutationVariables = Exact<{
  ID: GuarInsTypeDeleteInput;
}>;


export type DeleteInsuranceTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteInsuranceType'>
);

export type CancelInsuranceTypeMutationVariables = Exact<{
  input: GuarInsTypeInput;
}>;


export type CancelInsuranceTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelInsuranceType'>
);

export type ActivateInsuranceTypeMutationVariables = Exact<{
  input: GuarInsTypeInput;
}>;


export type ActivateInsuranceTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateInsuranceType'>
);

export type GetJobTypeQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetJobTypeQuery = (
  { __typename?: 'Query' }
  & { getJobType: Array<(
    { __typename?: 'JobTypeEntity' }
    & Pick<JobTypeEntity, 'ID' | 'name' | 'marketRate' | 'commonStatus'>
  )> }
);

export type CreateJobTypeMutationVariables = Exact<{
  input: JobTypeInput;
}>;


export type CreateJobTypeMutation = (
  { __typename?: 'Mutation' }
  & { createJobType: (
    { __typename?: 'JobTypeEntity' }
    & Pick<JobTypeEntity, 'ID'>
  ) }
);

export type CancelJobTypeMutationVariables = Exact<{
  input: JobTypeDeleteInput;
}>;


export type CancelJobTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelJobType'>
);

export type UpdateJobTypeMutationVariables = Exact<{
  input: JobTypeInput;
}>;


export type UpdateJobTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateJobType: (
    { __typename?: 'JobTypeEntity' }
    & Pick<JobTypeEntity, 'ID'>
  ) }
);

export type DeleteJobTypeMutationVariables = Exact<{
  input: JobTypeDeleteInput;
}>;


export type DeleteJobTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteJobType'>
);

export type ActivateJobTypeMutationVariables = Exact<{
  input: JobTypeDeleteInput;
}>;


export type ActivateJobTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateJobType'>
);

export type GetCostItemByClassesQueryVariables = Exact<{
  costClass: Array<CostClass> | CostClass;
  isInventory?: Maybe<Scalars['Boolean']>;
}>;


export type GetCostItemByClassesQuery = (
  { __typename?: 'Query' }
  & { getCostItemByClasses: Array<(
    { __typename?: 'CostItemEntity' }
    & Pick<CostItemEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'ID' | 'uomID' | 'accountID' | 'taxSchemeID' | 'costCategoryID' | 'variancePerc' | 'commonStatus' | 'name' | 'code' | 'unitPrice' | 'onBehalf' | 'markupPerc'>
    & { UOM?: Maybe<(
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'name' | 'code'>
    )>, costCategory?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name'>
    )> }
  )> }
);

export type GetCostItemPaginationQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  costClass?: Maybe<CostClass>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetCostItemPaginationQuery = (
  { __typename?: 'Query' }
  & { getCostItemPagination: Array<(
    { __typename?: 'CostItemEntity' }
    & Pick<CostItemEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'ID' | 'uomID' | 'accountID' | 'taxSchemeID' | 'costCategoryID' | 'variancePerc' | 'commonStatus' | 'name' | 'code' | 'unitPrice' | 'onBehalf' | 'isInventory' | 'markupPerc'>
    & { UOM?: Maybe<(
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'name' | 'code'>
    )>, costCategory?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name'>
    )> }
  )> }
);

export type GetProfitRecogGlAccountCodeQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
}>;


export type GetProfitRecogGlAccountCodeQuery = (
  { __typename?: 'Query' }
  & { getProfitRecogGLAccountCode: Array<(
    { __typename?: 'ProfitRecogGLAccountCodeEntity' }
    & Pick<ProfitRecogGlAccountCodeEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'ID' | 'accountID' | 'wipCostRecognized' | 'wipBillingRecognized' | 'revenuePL' | 'costPL'>
  )> }
);

export type CreateProfitRecogGlAccountCodeMutationVariables = Exact<{
  input: ProfitRecogGlAccountCodeInput;
}>;


export type CreateProfitRecogGlAccountCodeMutation = (
  { __typename?: 'Mutation' }
  & { createProfitRecogGLAccountCode: (
    { __typename?: 'ProfitRecogGLAccountCodeEntity' }
    & Pick<ProfitRecogGlAccountCodeEntity, 'ID'>
  ) }
);

export type UpdateProfitRecogGlAccountCodeMutationVariables = Exact<{
  input: ProfitRecogGlAccountCodeInput;
}>;


export type UpdateProfitRecogGlAccountCodeMutation = (
  { __typename?: 'Mutation' }
  & { updateProfitRecogGLAccountCode: (
    { __typename?: 'ProfitRecogGLAccountCodeEntity' }
    & Pick<ProfitRecogGlAccountCodeEntity, 'ID'>
  ) }
);

export type GetSiteQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetSiteQuery = (
  { __typename?: 'Query' }
  & { getSite: Array<(
    { __typename?: 'SiteEntity' }
    & Pick<SiteEntity, 'ID' | 'accountID' | 'contractID' | 'name' | 'address' | 'contactNo'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'title'>
      & { customerDetail?: Maybe<(
        { __typename?: 'CustomerEntity' }
        & Pick<CustomerEntity, 'name'>
      )> }
    )> }
  )> }
);

export type GetSiteTitleQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetSiteTitleQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'totalSiteCount'>
  & { getSite: Array<(
    { __typename?: 'SiteEntity' }
    & Pick<SiteEntity, 'ID' | 'accountID' | 'contractID' | 'name'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )> }
  )> }
);

export type GetProjectSiteQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
}>;


export type GetProjectSiteQuery = (
  { __typename?: 'Query' }
  & { getProjectSite: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'description' | 'startDate' | 'endDate' | 'contractStatus'>
    & { customerDetail?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'name' | 'address' | 'contactNo' | 'registrationNo'>
    )>, site?: Maybe<Array<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'stateID' | 'name' | 'address'>
    )>> }
  )> }
);

export type CreateSiteMutationVariables = Exact<{
  siteInput: Array<SiteInput> | SiteInput;
  input: ProjectHeaderInput;
}>;


export type CreateSiteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createSite'>
);

export type CancelSiteMutationVariables = Exact<{
  input: SiteDeleteInput;
}>;


export type CancelSiteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSite'>
);

export type UpdateSiteMutationVariables = Exact<{
  siteInput: Array<SiteInput> | SiteInput;
  input: ProjectHeaderInput;
}>;


export type UpdateSiteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateSite'>
);

export type DeleteProjectSiteMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteProjectSiteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteProjectSite'>
);

export type GetPublicHolidayQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetPublicHolidayQuery = (
  { __typename?: 'Query' }
  & { getPublicHoliday: Array<(
    { __typename?: 'PublicHolidayEntity' }
    & Pick<PublicHolidayEntity, 'ID' | 'name' | 'code' | 'docDate' | 'commonStatus'>
  )> }
);

export type CreatePublicHolidayMutationVariables = Exact<{
  input: PublicHolidayInput;
}>;


export type CreatePublicHolidayMutation = (
  { __typename?: 'Mutation' }
  & { createPublicHoliday: (
    { __typename?: 'PublicHolidayEntity' }
    & Pick<PublicHolidayEntity, 'ID'>
  ) }
);

export type CancelPublicHolidayMutationVariables = Exact<{
  input: PublicHolidayDeleteInput;
}>;


export type CancelPublicHolidayMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelPublicHoliday'>
);

export type UpdatePublicHolidayMutationVariables = Exact<{
  input: PublicHolidayInput;
}>;


export type UpdatePublicHolidayMutation = (
  { __typename?: 'Mutation' }
  & { updatePublicHoliday: (
    { __typename?: 'PublicHolidayEntity' }
    & Pick<PublicHolidayEntity, 'ID'>
  ) }
);

export type DeletePublicHolidayMutationVariables = Exact<{
  input: PublicHolidayDeleteInput;
}>;


export type DeletePublicHolidayMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePublicHoliday'>
);

export type ActivatePublicHolidayMutationVariables = Exact<{
  input: PublicHolidayDeleteInput;
}>;


export type ActivatePublicHolidayMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activatePublicHoliday'>
);

export type GetPublicHolidayByStateYearQueryVariables = Exact<{
  year: Scalars['String'];
}>;


export type GetPublicHolidayByStateYearQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPublicHolidayByStateYear'>
);

export type GetCustomerReminderQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
}>;


export type GetCustomerReminderQuery = (
  { __typename?: 'Query' }
  & { getCustomerReminder: Array<(
    { __typename?: 'CustomerReminderEntity' }
    & Pick<CustomerReminderEntity, 'ID' | 'paymentReminder1' | 'paymentReminder2' | 'paymentReminder3' | 'paymentReminder4' | 'minimumAmt' | 'reminderFor' | 'printedCopy' | 'emailCopy' | 'commonStatus'>
  )> }
);

export type CreateCustomerReminderMutationVariables = Exact<{
  inputReminder: CustomerReminderInput;
}>;


export type CreateCustomerReminderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createCustomerReminder'>
);

export type GetRevenueCategoryQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
}>;


export type GetRevenueCategoryQuery = (
  { __typename?: 'Query' }
  & { getRevenueCategory: Array<(
    { __typename?: 'RevenueCategoryEntity' }
    & Pick<RevenueCategoryEntity, 'modTs' | 'ID' | 'name' | 'taxSchemeID' | 'creditTerm' | 'description' | 'isDaywork' | 'commonStatus' | 'classificationCodeID' | 'eInvoiceIntegrated'>
    & { taxScheme?: Maybe<(
      { __typename?: 'ConTaxSchemeEntity' }
      & Pick<ConTaxSchemeEntity, 'ID' | 'code' | 'description'>
      & { latestTax?: Maybe<(
        { __typename?: 'TaxObject' }
        & Pick<TaxObject, 'taxDate' | 'taxRate'>
      )> }
    )>, classificationCode?: Maybe<(
      { __typename?: 'eInvoiceClassificationEntity' }
      & Pick<EInvoiceClassificationEntity, 'id' | 'classificationCode' | 'description'>
    )> }
  )> }
);

export type GetRevenueCategoryListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetRevenueCategoryListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getRevenueCategoryCount'>
  & { getRevenueCategory: Array<(
    { __typename?: 'RevenueCategoryEntity' }
    & Pick<RevenueCategoryEntity, 'ID' | 'name' | 'taxSchemeID' | 'creditTerm' | 'description' | 'isDaywork' | 'commonStatus' | 'classificationCodeID' | 'eInvoiceIntegrated' | 'glAccountCode'>
    & { taxScheme?: Maybe<(
      { __typename?: 'ConTaxSchemeEntity' }
      & Pick<ConTaxSchemeEntity, 'ID' | 'code' | 'description'>
      & { latestTax?: Maybe<(
        { __typename?: 'TaxObject' }
        & Pick<TaxObject, 'taxDate' | 'taxRate'>
      )> }
    )>, classificationCode?: Maybe<(
      { __typename?: 'eInvoiceClassificationEntity' }
      & Pick<EInvoiceClassificationEntity, 'id' | 'classificationCode' | 'description'>
    )> }
  )> }
);

export type CreateRevenueCategoryMutationVariables = Exact<{
  input: RevenueCategoryInput;
}>;


export type CreateRevenueCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createRevenueCategory: (
    { __typename?: 'RevenueCategoryEntity' }
    & Pick<RevenueCategoryEntity, 'name' | 'taxSchemeID' | 'creditTerm'>
  ) }
);

export type UpdateRevenueCategoryMutationVariables = Exact<{
  input: RevenueCategoryInput;
}>;


export type UpdateRevenueCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateRevenueCategory: (
    { __typename?: 'RevenueCategoryEntity' }
    & Pick<RevenueCategoryEntity, 'name' | 'taxSchemeID'>
  ) }
);

export type DeleteRevenueCategoryMutationVariables = Exact<{
  input: RevenueCategoryDeleteInput;
}>;


export type DeleteRevenueCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteRevenueCategory'>
);

export type CancelRevenueCategoryMutationVariables = Exact<{
  input: RevenueCategoryUpdateInput;
}>;


export type CancelRevenueCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelRevenueCategory'>
);

export type ActivateRevenueCategoryMutationVariables = Exact<{
  input: RevenueCategoryUpdateInput;
}>;


export type ActivateRevenueCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateRevenueCategory'>
);

export type CreateBatchRevenueCategoryMutationVariables = Exact<{
  input: Array<RevenueCategoryInput> | RevenueCategoryInput;
}>;


export type CreateBatchRevenueCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createBatchRevenueCategory'>
);

export type RevenueCategoryImportValidationMutationVariables = Exact<{
  RevenueCategoryImportInput: Array<RevenueCategoryImportInput> | RevenueCategoryImportInput;
}>;


export type RevenueCategoryImportValidationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'revenueCategoryImportValidation'>
);

export type RevenueCategoryImportMutationVariables = Exact<{
  batchID: Scalars['String'];
}>;


export type RevenueCategoryImportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'revenueCategoryImport'>
);

export type GetSiteMaterialQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetSiteMaterialQuery = (
  { __typename?: 'Query' }
  & { getSiteMaterial: Array<(
    { __typename?: 'SiteMaterialEntity' }
    & Pick<SiteMaterialEntity, 'ID' | 'name' | 'stockCode' | 'uomID'>
    & { uom?: Maybe<(
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'code' | 'name'>
    )>, grnItem?: Maybe<Array<(
      { __typename?: 'GRNItemEntity' }
      & Pick<GrnItemEntity, 'ID'>
    )>>, stockMovement?: Maybe<Array<(
      { __typename?: 'StockMovementEntity' }
      & Pick<StockMovementEntity, 'ID'>
    )>> }
  )> }
);

export type CreateSiteMaterialMutationVariables = Exact<{
  input: SiteMaterialInput;
}>;


export type CreateSiteMaterialMutation = (
  { __typename?: 'Mutation' }
  & { createSiteMaterial: (
    { __typename?: 'SiteMaterialEntity' }
    & Pick<SiteMaterialEntity, 'ID' | 'name'>
    & { uom?: Maybe<(
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'name'>
    )> }
  ) }
);

export type DeleteSiteMaterialMutationVariables = Exact<{
  input: SiteMaterialDeleteInput;
}>;


export type DeleteSiteMaterialMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSiteMaterial'>
);

export type CancelSiteMaterialMutationVariables = Exact<{
  input: SiteMaterialDeleteInput;
}>;


export type CancelSiteMaterialMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSiteMaterial'>
);

export type UpdateSiteMaterialMutationVariables = Exact<{
  input: SiteMaterialInput;
}>;


export type UpdateSiteMaterialMutation = (
  { __typename?: 'Mutation' }
  & { updateSiteMaterial: (
    { __typename?: 'SiteMaterialEntity' }
    & Pick<SiteMaterialEntity, 'ID'>
  ) }
);

export type CreateBulkSiteMaterialMutationVariables = Exact<{
  input: Array<SiteMaterialInput> | SiteMaterialInput;
}>;


export type CreateBulkSiteMaterialMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createBulkSiteMaterial'>
);

export type CreateMaterialPolicyMutationVariables = Exact<{
  procurementSubscriber: Scalars['Boolean'];
  materialIDs: Array<SiteMaterialPolicyInput> | SiteMaterialPolicyInput;
}>;


export type CreateMaterialPolicyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createMaterialPolicy'>
);

export type CreateMaterialContractPolicyMutationVariables = Exact<{
  procurementSubscriber: Scalars['Boolean'];
  materialIDs: Array<SiteMaterialPolicyInput> | SiteMaterialPolicyInput;
  contractID: Scalars['String'];
}>;


export type CreateMaterialContractPolicyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createMaterialContractPolicy'>
);

export type GetDefaultMaterialPolicyIDsQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetDefaultMaterialPolicyIDsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDefaultMaterialPolicyIDs'>
);

export type CreateSiteWorkersMutationVariables = Exact<{
  input: SiteWorkersInput;
}>;


export type CreateSiteWorkersMutation = (
  { __typename?: 'Mutation' }
  & { createSiteWorkers: (
    { __typename?: 'WorkerEntity' }
    & Pick<WorkerEntity, 'ID'>
  ) }
);

export type UpdateSiteWorkersMutationVariables = Exact<{
  input: SiteWorkersInput;
}>;


export type UpdateSiteWorkersMutation = (
  { __typename?: 'Mutation' }
  & { updateSiteWorkers: (
    { __typename?: 'WorkerEntity' }
    & Pick<WorkerEntity, 'ID'>
  ) }
);

export type CreateSubcontractorTypeMutationVariables = Exact<{
  input: SubcontractorTypeInput;
}>;


export type CreateSubcontractorTypeMutation = (
  { __typename?: 'Mutation' }
  & { createSubcontractorType: (
    { __typename?: 'SubcontractorTypeEntity' }
    & Pick<SubcontractorTypeEntity, 'ID'>
  ) }
);

export type UpdateSubcontractorTypeMutationVariables = Exact<{
  input: SubcontractorTypeInput;
}>;


export type UpdateSubcontractorTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateSubcontractorType: (
    { __typename?: 'SubcontractorTypeEntity' }
    & Pick<SubcontractorTypeEntity, 'ID'>
  ) }
);

export type GetSubcontractorTypeQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
}>;


export type GetSubcontractorTypeQuery = (
  { __typename?: 'Query' }
  & { getSubcontractorType: Array<(
    { __typename?: 'SubcontractorTypeEntity' }
    & Pick<SubcontractorTypeEntity, 'ID' | 'description' | 'glAccountCode' | 'accrualAccountCode' | 'commonStatus' | 'modTs'>
  )> }
);

export type DeleteSubcontractorTypeMutationVariables = Exact<{
  ID: SubcontractorTypeDeleteInput;
}>;


export type DeleteSubcontractorTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSubcontractorType'>
);

export type CancelSubcontractorTypeMutationVariables = Exact<{
  input: SubcontractorTypeInput;
}>;


export type CancelSubcontractorTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSubcontractorType'>
);

export type ActivateSubcontractorTypeMutationVariables = Exact<{
  input: SubcontractorTypeInput;
}>;


export type ActivateSubcontractorTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateSubcontractorType'>
);

export type UpdateSupplierTypeMutationVariables = Exact<{
  input: SupplierTypeInput;
}>;


export type UpdateSupplierTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateSupplierType: (
    { __typename?: 'SupplierTypeEntity' }
    & Pick<SupplierTypeEntity, 'ID'>
  ) }
);

export type CreateSupplierTypeMutationVariables = Exact<{
  input: SupplierTypeInput;
}>;


export type CreateSupplierTypeMutation = (
  { __typename?: 'Mutation' }
  & { createSupplierType: (
    { __typename?: 'SupplierTypeEntity' }
    & Pick<SupplierTypeEntity, 'ID'>
  ) }
);

export type GetSupplierTypeQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
}>;


export type GetSupplierTypeQuery = (
  { __typename?: 'Query' }
  & { getSupplierType: Array<(
    { __typename?: 'SupplierTypeEntity' }
    & Pick<SupplierTypeEntity, 'ID' | 'description' | 'glAccountCode' | 'accrualAccountCode' | 'commonStatus' | 'modTs'>
  )> }
);

export type DeleteSupplierTypeMutationVariables = Exact<{
  ID: SupplierTypeDeleteInput;
}>;


export type DeleteSupplierTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSupplierType'>
);

export type CancelSupplierTypeMutationVariables = Exact<{
  input: SupplierTypeInput;
}>;


export type CancelSupplierTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSupplierType'>
);

export type ActivateSupplierTypeMutationVariables = Exact<{
  input: SupplierTypeInput;
}>;


export type ActivateSupplierTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateSupplierType'>
);

export type GetVideoCamQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetVideoCamQuery = (
  { __typename?: 'Query' }
  & { getVideoCam: Array<(
    { __typename?: 'VideoCamEntity' }
    & Pick<VideoCamEntity, 'ID' | 'name' | 'siteID' | 'networkConnection' | 'userName' | 'password'>
  )> }
);

export type CreateVideoCamMutationVariables = Exact<{
  input: VideoCamInput;
}>;


export type CreateVideoCamMutation = (
  { __typename?: 'Mutation' }
  & { createVideoCam: (
    { __typename?: 'VideoCamEntity' }
    & Pick<VideoCamEntity, 'ID'>
  ) }
);

export type CancelVideoCamMutationVariables = Exact<{
  input: VideoCamDeleteInput;
}>;


export type CancelVideoCamMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelVideoCam'>
);

export type UpdateVideoCamMutationVariables = Exact<{
  input: VideoCamInput;
}>;


export type UpdateVideoCamMutation = (
  { __typename?: 'Mutation' }
  & { updateVideoCam: (
    { __typename?: 'VideoCamEntity' }
    & Pick<VideoCamEntity, 'ID'>
  ) }
);

export type DeleteVideoCamMutationVariables = Exact<{
  input: VideoCamDeleteInput;
}>;


export type DeleteVideoCamMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteVideoCam'>
);

export type GetHolidayTypeQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetHolidayTypeQuery = (
  { __typename?: 'Query' }
  & { getHolidayType: Array<(
    { __typename?: 'HolidayTypeEntity' }
    & Pick<HolidayTypeEntity, 'ID' | 'name' | 'createdTs'>
    & { wagesRatePolicy?: Maybe<Array<(
      { __typename?: 'WagesRatePolicyEntity' }
      & Pick<WagesRatePolicyEntity, 'rate'>
    )>> }
  )> }
);

export type GetWagesRatePolicyQueryVariables = Exact<{
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetWagesRatePolicyQuery = (
  { __typename?: 'Query' }
  & { getWagesRatePolicy: Array<(
    { __typename?: 'WagesRatePolicyEntity' }
    & Pick<WagesRatePolicyEntity, 'ID' | 'holidayTypeID' | 'modTs' | 'rate'>
    & { holidayType?: Maybe<(
      { __typename?: 'HolidayTypeEntity' }
      & Pick<HolidayTypeEntity, 'name'>
    )> }
  )> }
);

export type UpdateHolidayTypeMutationVariables = Exact<{
  input: HolidayTypeInput;
}>;


export type UpdateHolidayTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateHolidayType: (
    { __typename?: 'HolidayTypeEntity' }
    & Pick<HolidayTypeEntity, 'ID'>
  ) }
);

export type UpdateWagesRatePolicyMutationVariables = Exact<{
  holTypeRateIDs: Array<HolidayTypeRateInput> | HolidayTypeRateInput;
}>;


export type UpdateWagesRatePolicyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateWagesRatePolicy'>
);

export type GetWorkerQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
}>;


export type GetWorkerQuery = (
  { __typename?: 'Query' }
  & { getWorker: Array<(
    { __typename?: 'WorkerEntity' }
    & Pick<WorkerEntity, 'ID' | 'commonStatus' | 'address' | 'wageRate' | 'wageType' | 'workPermitNo' | 'nationality' | 'identityNo' | 'docExpiryDate' | 'jobTypeID'>
    & { jobType?: Maybe<(
      { __typename?: 'JobTypeEntity' }
      & Pick<JobTypeEntity, 'ID' | 'name'>
    )>, contact: (
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'name' | 'email' | 'contactNo'>
    ), attachment?: Maybe<(
      { __typename?: 'WorkerDocuments' }
      & Pick<WorkerDocuments, 'fileURL' | 'fileName' | 'mediaType'>
    )> }
  )> }
);

export type CheckingWageTypeQueryVariables = Exact<{
  workerID?: Maybe<Scalars['String']>;
}>;


export type CheckingWageTypeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'checkingWageType'>
);

export type GetWorkerSiteAssignmentQueryVariables = Exact<{
  workerID: Scalars['String'];
}>;


export type GetWorkerSiteAssignmentQuery = (
  { __typename?: 'Query' }
  & { getWorkerSiteAssignment: Array<(
    { __typename?: 'WorkerSiteAssignmentEntity' }
    & Pick<WorkerSiteAssignmentEntity, 'siteID'>
  )> }
);

export type CreateWorkerProfileMutationVariables = Exact<{
  input: WorkerInput;
  siteID: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreateWorkerProfileMutation = (
  { __typename?: 'Mutation' }
  & { createWorker: (
    { __typename?: 'WorkerEntity' }
    & Pick<WorkerEntity, 'ID'>
  ) }
);

export type UpdateWorkerProfileMutationVariables = Exact<{
  input: WorkerInput;
  siteID: Array<Scalars['String']> | Scalars['String'];
}>;


export type UpdateWorkerProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateWorker: (
    { __typename?: 'WorkerEntity' }
    & Pick<WorkerEntity, 'ID'>
  ) }
);

export type DeleteWorkerProfileMutationVariables = Exact<{
  input: WorkerDeleteInput;
}>;


export type DeleteWorkerProfileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteWorker'>
);

export type CreateBulkWorkerMutationVariables = Exact<{
  input: Array<WorkerInput> | WorkerInput;
}>;


export type CreateBulkWorkerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createBulkWorker'>
);

export type GetWorkingHourPolicyQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
}>;


export type GetWorkingHourPolicyQuery = (
  { __typename?: 'Query' }
  & { getWorkingHourPolicy: Array<(
    { __typename?: 'WorkingHourPolicyEntity' }
    & Pick<WorkingHourPolicyEntity, 'ID' | 'shiftStartTime' | 'shiftEndTime' | 'halfStartTime' | 'halfEndTime'>
  )> }
);

export type CreateWorkingHourPolicyMutationVariables = Exact<{
  input: WorkingHourPolicyInput;
}>;


export type CreateWorkingHourPolicyMutation = (
  { __typename?: 'Mutation' }
  & { createWorkingHourPolicy: (
    { __typename?: 'WorkingHourPolicyEntity' }
    & Pick<WorkingHourPolicyEntity, 'ID'>
  ) }
);

export type UpdateWorkingHourPolicyMutationVariables = Exact<{
  input: WorkingHourPolicyInput;
}>;


export type UpdateWorkingHourPolicyMutation = (
  { __typename?: 'Mutation' }
  & { updateWorkingHourPolicy: (
    { __typename?: 'WorkingHourPolicyEntity' }
    & Pick<WorkingHourPolicyEntity, 'ID'>
  ) }
);

export type GetWorkshopQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  workshopStatus?: Maybe<WorkshopStatus>;
}>;


export type GetWorkshopQuery = (
  { __typename?: 'Query' }
  & { getWorkshop: Array<(
    { __typename?: 'WorkshopEntity' }
    & Pick<WorkshopEntity, 'ID' | 'name' | 'modTs' | 'accountID' | 'registrationNo' | 'email' | 'contactNo' | 'description' | 'address' | 'contactPerson' | 'workshopStatus' | 'ratingType'>
  )> }
);

export type CreateWorkshopMutationVariables = Exact<{
  input: WorkshopInput;
}>;


export type CreateWorkshopMutation = (
  { __typename?: 'Mutation' }
  & { createWorkshop: (
    { __typename?: 'WorkshopEntity' }
    & Pick<WorkshopEntity, 'ID'>
  ) }
);

export type UpdateWorkshopMutationVariables = Exact<{
  input: WorkshopInput;
}>;


export type UpdateWorkshopMutation = (
  { __typename?: 'Mutation' }
  & { updateWorkshop: (
    { __typename?: 'WorkshopEntity' }
    & Pick<WorkshopEntity, 'ID'>
  ) }
);

export type GetHomeSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHomeSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'workDeskCount' | 'activeContractCount' | 'activeClientCount' | 'activeSubcontractCount' | 'activeSubcontractorCount' | 'activeSupplierCount' | 'lastMiscExpDate' | 'lastPurchaseDate' | 'getRolePermission' | 'getContractPermission'>
  & { loggedInUserProfile: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'accountID' | 'name' | 'email' | 'contactNo' | 'userName' | 'department' | 'superUser' | 'jobTitle'>
  ) }
);

export type GetAccessSecuritySummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccessSecuritySummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'conAuthDate' | 'userDate' | 'roleDate'>
);

export type GetDrawerPermissionQueryVariables = Exact<{
  pageMode?: Maybe<Scalars['String']>;
}>;


export type GetDrawerPermissionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDrawerPermission'>
);

export type GetRoleMatrixPermQueryVariables = Exact<{
  roleID?: Maybe<Scalars['String']>;
}>;


export type GetRoleMatrixPermQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getRoleMatrix'>
);

export type GetRoleMatrixByIdsQueryVariables = Exact<{
  roleID?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  softwareCode?: Maybe<Scalars['String']>;
}>;


export type GetRoleMatrixByIdsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getRoleMatrixByIds'>
  & { getRoleMatrixTotal: Query['getRoleMatrixByIds'] }
);

export type GetRoleByIdsQueryVariables = Exact<{
  roleIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  softwareCode?: Maybe<Scalars['String']>;
}>;


export type GetRoleByIdsQuery = (
  { __typename?: 'Query' }
  & { getRoleByIds: Array<(
    { __typename?: 'RoleEntity' }
    & Pick<RoleEntity, 'ID' | 'name'>
  )> }
);

export type GetAlertQueryVariables = Exact<{
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetAlertQuery = (
  { __typename?: 'Query' }
  & { getAlert: Array<(
    { __typename?: 'AlertEntity' }
    & Pick<AlertEntity, 'ID' | 'name' | 'description' | 'sqlCode' | 'commonStatus'>
  )> }
);

export type CreateAlertMutationVariables = Exact<{
  input: AlertInput;
}>;


export type CreateAlertMutation = (
  { __typename?: 'Mutation' }
  & { createAlert: (
    { __typename?: 'AlertEntity' }
    & Pick<AlertEntity, 'ID'>
  ) }
);

export type UpdateAlertMutationVariables = Exact<{
  input: AlertInput;
}>;


export type UpdateAlertMutation = (
  { __typename?: 'Mutation' }
  & { updateAlert: (
    { __typename?: 'AlertEntity' }
    & Pick<AlertEntity, 'ID'>
  ) }
);

export type GetAlertListQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
}>;


export type GetAlertListQuery = (
  { __typename?: 'Query' }
  & { getAlertList: Array<(
    { __typename?: 'AlertEntity' }
    & Pick<AlertEntity, 'ID' | 'name' | 'description' | 'sqlCode' | 'commonStatus' | 'defaultFromAdmin' | 'alertCount'>
    & { alertAssignment?: Maybe<Array<(
      { __typename?: 'AlertAssignmentEntity' }
      & Pick<AlertAssignmentEntity, 'ID' | 'accountID' | 'userID' | 'reminder' | 'alertID' | 'isAdminControl'>
    )>> }
  )> }
);

export type GetShowAlertSqlQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type GetShowAlertSqlQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getShowAlertSQL'>
);

export type GetAlertAssignmentQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  alertID?: Maybe<Scalars['String']>;
}>;


export type GetAlertAssignmentQuery = (
  { __typename?: 'Query' }
  & { getAlertAssignment: Array<(
    { __typename?: 'AlertAssignmentEntity' }
    & Pick<AlertAssignmentEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'ID' | 'accountID' | 'alertID' | 'userID' | 'reminder' | 'isAdminControl'>
  )> }
);

export type UpdateAlertAssignmentMutationVariables = Exact<{
  input: Array<AlertAssignmentInput> | AlertAssignmentInput;
  isFromAdmin: Scalars['Boolean'];
}>;


export type UpdateAlertAssignmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateAlertAssignment'>
);

export type GetBankAccountQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  status?: Maybe<BankAccountStatus>;
}>;


export type GetBankAccountQuery = (
  { __typename?: 'Query' }
  & { getBankAccount: Array<(
    { __typename?: 'BankAccountEntity' }
    & Pick<BankAccountEntity, 'ID' | 'status' | 'accountID' | 'name' | 'accountHolder' | 'accountNo' | 'contactNo' | 'branch' | 'address' | 'swiftCode' | 'contactPerson' | 'glAccountCode'>
  )> }
);

export type GetBankAccountWithPermQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  status?: Maybe<BankAccountStatus>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetBankAccountWithPermQuery = (
  { __typename?: 'Query' }
  & { getBankAccountWithPerm: Array<(
    { __typename?: 'BankAccountEntity' }
    & Pick<BankAccountEntity, 'ID' | 'status' | 'accountID' | 'name' | 'accountHolder' | 'accountNo' | 'contactNo' | 'branch' | 'address' | 'swiftCode' | 'contactPerson' | 'glAccountCode'>
  )> }
);

export type CreateBankAccountMutationVariables = Exact<{
  input: BankAccountInput;
}>;


export type CreateBankAccountMutation = (
  { __typename?: 'Mutation' }
  & { createBankAccount: (
    { __typename?: 'BankAccountEntity' }
    & Pick<BankAccountEntity, 'ID'>
  ) }
);

export type UpdateBankAccountMutationVariables = Exact<{
  input: BankAccountInput;
}>;


export type UpdateBankAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateBankAccount: (
    { __typename?: 'BankAccountEntity' }
    & Pick<BankAccountEntity, 'ID'>
  ) }
);

export type CancelBankAccountMutationVariables = Exact<{
  input: BankAccountCancellationInput;
  ID: Scalars['String'];
}>;


export type CancelBankAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelBankAccount'>
);

export type ActivateBankAccountMutationVariables = Exact<{
  input: BankAccountDeleteInput;
}>;


export type ActivateBankAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateBankAccount'>
);

export type DeleteBankAccountMutationVariables = Exact<{
  input: BankAccountDeleteInput;
}>;


export type DeleteBankAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBankAccount'>
);

export type GetCurrencySetupQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  format?: Maybe<NumericFormatter>;
}>;


export type GetCurrencySetupQuery = (
  { __typename?: 'Query' }
  & { getCurrencySetup: Array<(
    { __typename?: 'CurrencyNoteEntity' }
    & Pick<CurrencyNoteEntity, 'accountID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'ID' | 'name' | 'code' | 'symbol' | 'format'>
  )> }
);

export type CreateCurrencySetupMutationVariables = Exact<{
  input: CurrencySetupInput;
}>;


export type CreateCurrencySetupMutation = (
  { __typename?: 'Mutation' }
  & { createCurrencySetup: (
    { __typename?: 'CurrencyNoteEntity' }
    & Pick<CurrencyNoteEntity, 'ID'>
  ) }
);

export type UpdateCurrencySetupMutationVariables = Exact<{
  input: CurrencySetupInput;
}>;


export type UpdateCurrencySetupMutation = (
  { __typename?: 'Mutation' }
  & { updateCurrencySetup: (
    { __typename?: 'CurrencyNoteEntity' }
    & Pick<CurrencyNoteEntity, 'ID'>
  ) }
);

export type DeleteCurrencySetupMutationVariables = Exact<{
  input: CurrencySetupDeleteInput;
}>;


export type DeleteCurrencySetupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCurrencySetup'>
);

export type UpdateNumberFormatMutationVariables = Exact<{
  input: NumberFormatInput;
}>;


export type UpdateNumberFormatMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'editNumberFormat'>
);

export type UpdateDocNumSetupMutationVariables = Exact<{
  ID: Scalars['String'];
  input: DocNumInput;
}>;


export type UpdateDocNumSetupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDocNumSetup'>
);

export type GetDocTypeQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetDocTypeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDocType'>
);

export type GetNumberFormatQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  docTypeID?: Maybe<Scalars['String']>;
}>;


export type GetNumberFormatQuery = (
  { __typename?: 'Query' }
  & { getNumberFormat: Array<(
    { __typename?: 'ConNumberFormatEntity' }
    & Pick<ConNumberFormatEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'accountID' | 'docTypeID' | 'numberFormat' | 'numberLength' | 'prefix' | 'prefixExtra' | 'commonStatus' | 'softwareCode' | 'documentDivider' | 'contractID'>
    & { docType: (
      { __typename?: 'ConDocTypeEntity' }
      & Pick<ConDocTypeEntity, 'docName'>
    ) }
  )> }
);

export type RunningNumCheckingQueryVariables = Exact<{
  docCode: ContractDocNum;
  companyID?: Maybe<Scalars['String']>;
}>;


export type RunningNumCheckingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'runningNumChecking'>
);

export type ProjMiscExpRunNumCheckingQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
}>;


export type ProjMiscExpRunNumCheckingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'projMiscExpRunNumChecking'>
);

export type GetSysAdminDocTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSysAdminDocTypeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSysAdminDocType'>
);

export type GetDocNumHeaderQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  docTypeID?: Maybe<Scalars['String']>;
}>;


export type GetDocNumHeaderQuery = (
  { __typename?: 'Query' }
  & { getDocNumHeader: Array<(
    { __typename?: 'DocNumHeaderEntity' }
    & Pick<DocNumHeaderEntity, 'ID' | 'accountID' | 'contractID' | 'docTypeID' | 'sampleOutput'>
    & { docNumDetail: Array<(
      { __typename?: 'DocNumDetailEntity' }
      & Pick<DocNumDetailEntity, 'sequence' | 'formatType' | 'formatValue' | 'startFrom' | 'nextNumber' | 'docNumHeaderID'>
    )> }
  )> }
);

export type UpdateSystemAdminDocNumMutationVariables = Exact<{
  DocNumHeaderInput: DocNumHeaderInput;
  DocNumDetailInput: Array<DocNumDetailInput> | DocNumDetailInput;
}>;


export type UpdateSystemAdminDocNumMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateSystemAdminDocNum'>
);

export type GetClassificationCodeQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetClassificationCodeQuery = (
  { __typename?: 'Query' }
  & { getClassificationCode: Array<(
    { __typename?: 'eInvoiceClassificationEntity' }
    & Pick<EInvoiceClassificationEntity, 'id' | 'classificationCode' | 'description'>
  )> }
);

export type GetClassificationCodeListingQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetClassificationCodeListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getClassificationCodeCount'>
  & { getClassificationCode: Array<(
    { __typename?: 'eInvoiceClassificationEntity' }
    & Pick<EInvoiceClassificationEntity, 'id' | 'classificationCode' | 'description'>
  )> }
);

export type GetMsicCodeQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetMsicCodeQuery = (
  { __typename?: 'Query' }
  & { getMsicCode: Array<(
    { __typename?: 'eInvoiceMsicEntity' }
    & Pick<EInvoiceMsicEntity, 'id' | 'msicCode' | 'description' | 'categoryReference'>
  )> }
);

export type GetMsicCodeListingQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetMsicCodeListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getMsicCodeCount'>
  & { getMsicCode: Array<(
    { __typename?: 'eInvoiceMsicEntity' }
    & Pick<EInvoiceMsicEntity, 'id' | 'msicCode' | 'description' | 'categoryReference'>
  )> }
);

export type UploadCompanyLogoMutationVariables = Exact<{
  file?: Maybe<Scalars['Upload']>;
}>;


export type UploadCompanyLogoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadCompanyLogo'>
);

export type UploadIsoLogoMutationVariables = Exact<{
  file?: Maybe<Scalars['Upload']>;
}>;


export type UploadIsoLogoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadISOLogo'>
);

export type LogoViewQueryVariables = Exact<{
  refID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}>;


export type LogoViewQuery = (
  { __typename?: 'Query' }
  & { logoView?: Maybe<(
    { __typename?: 'DocumentsEntity' }
    & Pick<DocumentsEntity, 'fileName' | 'fileURL' | 'mediaType'>
  )> }
);

export type GetStandardParameterQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStandardParameterQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getStandardParameter'>
);

export type CreatePolicyGuidelineMutationVariables = Exact<{
  roleApprovalIDs: Array<PolicyAssignmentInput> | PolicyAssignmentInput;
  input: PolicyGuidelineInput;
}>;


export type CreatePolicyGuidelineMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPolicyGuideline'>
);

export type UpdatePolicyGuidelineMutationVariables = Exact<{
  input: Array<PolicyAssignmentInput> | PolicyAssignmentInput;
  docRefTable: Scalars['String'];
}>;


export type UpdatePolicyGuidelineMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePolicyGuideline'>
);

export type CreatePolicyAmountGuidelineMutationVariables = Exact<{
  notiRoleUserIDs: Array<NotificationGuidelineInput> | NotificationGuidelineInput;
  roleApprovalIDs: Array<PolicyAssignmentInput> | PolicyAssignmentInput;
  input: PolicyGuidelineInput;
}>;


export type CreatePolicyAmountGuidelineMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPolicyAmountGuideline'>
);

export type GetPolicyGuideLineQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
}>;


export type GetPolicyGuideLineQuery = (
  { __typename?: 'Query' }
  & { getPolicyGuideLine: Array<(
    { __typename?: 'PolicyGuidelineEntity' }
    & Pick<PolicyGuidelineEntity, 'ID' | 'accountID' | 'commonStatus' | 'docRefTable' | 'upperLimit' | 'lowerLimit'>
    & { policyRoles: Array<(
      { __typename?: 'PolicyRoleAssignmentEntity' }
      & Pick<PolicyRoleAssignmentEntity, 'ID' | 'policyID' | 'approvalLevel' | 'roleID'>
    )> }
  )> }
);

export type GetPolicyGuideLineShorterQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
}>;


export type GetPolicyGuideLineShorterQuery = (
  { __typename?: 'Query' }
  & { getPolicyGuideLine: Array<(
    { __typename?: 'PolicyGuidelineEntity' }
    & Pick<PolicyGuidelineEntity, 'ID' | 'commonStatus' | 'docRefTable'>
    & { policyRoles: Array<(
      { __typename?: 'PolicyRoleAssignmentEntity' }
      & Pick<PolicyRoleAssignmentEntity, 'approvalLevel' | 'roleID'>
    )> }
  )> }
);

export type DeletePolicyAmountGuidelineMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeletePolicyAmountGuidelineMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePolicyAmountGuideline'>
);

export type UpdatePolicyAmountGuidelineMutationVariables = Exact<{
  ID: Scalars['String'];
  roleApprovalIDs: Array<PolicyAssignmentInput> | PolicyAssignmentInput;
  notiRoleUserIDs: Array<NotificationGuidelineInput> | NotificationGuidelineInput;
  input: PolicyGuidelineInput;
}>;


export type UpdatePolicyAmountGuidelineMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePolicyAmountGuideline'>
);

export type CreateNotificationGuidelineMutationVariables = Exact<{
  input: Array<NotificationGuidelineInput> | NotificationGuidelineInput;
  docRefTable: Scalars['String'];
}>;


export type CreateNotificationGuidelineMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createNotificationGuideline'>
);

export type UpdateNotificationGuidelineMutationVariables = Exact<{
  input: Array<NotificationGuidelineInput> | NotificationGuidelineInput;
  ID: Scalars['String'];
}>;


export type UpdateNotificationGuidelineMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateNotificationGuideline'>
);

export type GetNotificationGuideLineQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  policyID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
}>;


export type GetNotificationGuideLineQuery = (
  { __typename?: 'Query' }
  & { getNotificationGuideLine: Array<(
    { __typename?: 'NotificationGuidelineEntity' }
    & Pick<NotificationGuidelineEntity, 'ID' | 'accountID' | 'commonStatus' | 'docRefTable' | 'roles'>
    & { notiRoleUsers?: Maybe<Array<(
      { __typename?: 'NotiRoleUserAssignmentEntity' }
      & Pick<NotiRoleUserAssignmentEntity, 'ID' | 'accountID' | 'notiPolicyID' | 'roleID' | 'userID'>
    )>> }
  )> }
);

export type UserRoleIDsQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type UserRoleIDsQuery = (
  { __typename?: 'Query' }
  & { userRoleIDs: Array<(
    { __typename?: 'userRoleID' }
    & Pick<UserRoleId, 'roleID' | 'userID'>
  )> }
);

export type CreateEntityRoleUserMutationVariables = Exact<{
  input: EntityRoleUserAsgInput;
}>;


export type CreateEntityRoleUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateEntityRoleUser'>
);

export type CreateListEntityRoleUserMutationVariables = Exact<{
  contractID: Scalars['String'];
  roleUserIDs: Array<RoleUserInput> | RoleUserInput;
}>;


export type CreateListEntityRoleUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateListEntityRoleUser'>
);

export type GetContractListForProjectAndRoleAuthQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  softwareCode?: Maybe<Scalars['String']>;
}>;


export type GetContractListForProjectAndRoleAuthQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'contractDate' | 'contractRolesUsersAssigned'>
  )>, getEntityRoleUserAssignment: Array<(
    { __typename?: 'RoleUserEntAssignmentEntity' }
    & Pick<RoleUserEntAssignmentEntity, 'entityID' | 'userID' | 'roleID'>
  )> }
);

export type GetContractWithTotalQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  softwareCode?: Maybe<Scalars['String']>;
}>;


export type GetContractWithTotalQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'contractDate' | 'contractRolesUsersAssigned'>
  )> }
);

export type GetUserRoleListingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserRoleListingQuery = (
  { __typename?: 'Query' }
  & { getRole: Array<(
    { __typename?: 'RoleEntity' }
    & Pick<RoleEntity, 'ID' | 'name'>
  )>, getUsersByAccountAndSoftware: Array<(
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'name'>
  )> }
);

export type GetEntityRoleUserAssignmentQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['String']>;
}>;


export type GetEntityRoleUserAssignmentQuery = (
  { __typename?: 'Query' }
  & { getEntityRoleUserAssignment: Array<(
    { __typename?: 'RoleUserEntAssignmentEntity' }
    & Pick<RoleUserEntAssignmentEntity, 'userID' | 'roleID'>
  )> }
);

export type GetUsersRoleContractQueryVariables = Exact<{
  roleID: Scalars['String'];
  contractID: Scalars['String'];
}>;


export type GetUsersRoleContractQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUsersRoleContract'>
);

export type TestPermissionQueryVariables = Exact<{
  conPerm: ContractPermission;
}>;


export type TestPermissionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'testPermission'>
);

export type TestConPermissionQueryVariables = Exact<{
  contPerm: ContPermission;
}>;


export type TestConPermissionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'testConPermission'>
);

export type SiteTestPermissionQueryVariables = Exact<{
  sitePerm: SitePermission;
}>;


export type SiteTestPermissionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'siteTestPermission'>
);

export type CreateRolePermissionMutationVariables = Exact<{
  input: RoleInput;
  permissionArr: Array<ContractPermission> | ContractPermission;
}>;


export type CreateRolePermissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateRolePermission'>
);

export type UpdateRolePermissionMutationVariables = Exact<{
  input: RoleInput;
  permissionArr: Array<ContractPermission> | ContractPermission;
}>;


export type UpdateRolePermissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateRolePermission'>
);

export type CreateContractRoleMutationVariables = Exact<{
  input: RoleInput;
  permissionArr: Array<ContPermission> | ContPermission;
}>;


export type CreateContractRoleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateContractRole'>
);

export type UpdateContractRoleMutationVariables = Exact<{
  input: RoleInput;
  permissionArr: Array<ContPermission> | ContPermission;
}>;


export type UpdateContractRoleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateContractRole'>
);

export type CreateSiteRoleMutationVariables = Exact<{
  input: RoleInput;
  permissionArr: Array<SitePermission> | SitePermission;
}>;


export type CreateSiteRoleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateSiteRole'>
);

export type UpdateSiteRoleMutationVariables = Exact<{
  input: RoleInput;
  permissionArr: Array<SitePermission> | SitePermission;
}>;


export type UpdateSiteRoleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateSiteRole'>
);

export type GetRoleQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  softwareCode?: Maybe<SoftwareCode>;
  orderByAsc?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
}>;


export type GetRoleQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'roleDate'>
  & { getRole: Array<(
    { __typename?: 'RoleEntity' }
    & Pick<RoleEntity, 'ID' | 'name' | 'softwareCode' | 'assignedUserEntity'>
    & { conRolePerm: Array<(
      { __typename?: 'RolePermissionAssignmentEntity' }
      & Pick<RolePermissionAssignmentEntity, 'ID' | 'permName' | 'permissionID'>
    )>, rolePerm: Array<(
      { __typename?: 'RolePermissionAssignmentEntity' }
      & Pick<RolePermissionAssignmentEntity, 'permissionID' | 'roleID' | 'permName'>
    )>, siteRolePerm: Array<(
      { __typename?: 'RolePermissionAssignmentEntity' }
      & Pick<RolePermissionAssignmentEntity, 'ID' | 'permName' | 'permissionID'>
    )> }
  )> }
);

export type GetPolicyRolesQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  softwareCode?: Maybe<SoftwareCode>;
}>;


export type GetPolicyRolesQuery = (
  { __typename?: 'Query' }
  & { getRole: Array<(
    { __typename?: 'RoleEntity' }
    & Pick<RoleEntity, 'ID' | 'name'>
  )>, getPolicyRole: Array<(
    { __typename?: 'RoleEntity' }
    & Pick<RoleEntity, 'ID' | 'name'>
  )> }
);

export type GetRoleNameQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  softwareCode?: Maybe<SoftwareCode>;
  take?: Maybe<Scalars['Float']>;
}>;


export type GetRoleNameQuery = (
  { __typename?: 'Query' }
  & { getRole: Array<(
    { __typename?: 'RoleEntity' }
    & Pick<RoleEntity, 'ID' | 'name'>
  )> }
);

export type DeleteRolePermissionMutationVariables = Exact<{
  roleID: Scalars['String'];
  forceDelete?: Maybe<Scalars['Boolean']>;
}>;


export type DeleteRolePermissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteRolePermission'>
);

export type GetSAccountQueryVariables = Exact<{
  accountID?: Maybe<Scalars['String']>;
}>;


export type GetSAccountQuery = (
  { __typename?: 'Query' }
  & { getSAccount: Array<(
    { __typename?: 'SAccountEntity' }
    & Pick<SAccountEntity, 'ID' | 'companyCode' | 'currencyCode' | 'companyName' | 'currencyName' | 'gstRegNo' | 'gstExpDate' | 'sstRegNo' | 'logoPosition' | 'watermarkImplementation' | 'watermarkWording' | 'isoLogoPosition' | 'isoImplementation' | 'address'>
  )> }
);

export type UpdateSAccountMutationVariables = Exact<{
  input: SAccountInput;
}>;


export type UpdateSAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateSAccount: (
    { __typename?: 'SAccountEntity' }
    & Pick<SAccountEntity, 'ID' | 'watermarkWording' | 'logoPosition' | 'watermarkImplementation' | 'gstRegNo' | 'gstExpDate' | 'sstRegNo' | 'isoImplementation' | 'isoLogoPosition'>
  ) }
);

export type GetSubcontractorSetupQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractorSetupQuery = (
  { __typename?: 'Query' }
  & { getSubcontractor: Array<(
    { __typename?: 'SubcontractorEntity' }
    & Pick<SubcontractorEntity, 'ID' | 'name' | 'email' | 'accountID' | 'registrationNo' | 'contactNo' | 'creditTerm' | 'ratingType' | 'cidbDetails' | 'description' | 'address' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'nominatedSC' | 'overdueAdvInfo' | 'overdueInvInfo' | 'overdueInvInfoNoBound' | 'associatedStatus' | 'activeSubcontract'>
    & { contactPerson: Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'name' | 'designation' | 'email' | 'contactNo' | 'associateID'>
    )> }
  )> }
);

export type CreateSubcontractorSetupMutationVariables = Exact<{
  input: SubcontractorHeaderInput;
  costCategoryID: Array<Scalars['String']> | Scalars['String'];
  contactInput: Array<ContactPersonInput> | ContactPersonInput;
}>;


export type CreateSubcontractorSetupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createSubcontractorSetup'>
);

export type UpdateSubcontractorSetupMutationVariables = Exact<{
  input: SubcontractorHeaderInput;
  costCategoryID: Array<Scalars['String']> | Scalars['String'];
  contactInput: Array<ContactPersonInput> | ContactPersonInput;
}>;


export type UpdateSubcontractorSetupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateSubcontractorSetup'>
);

export type DeleteSubcontractorSetupMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteSubcontractorSetupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSubcontractorSetup'>
);

export type GetSubcontractorAssocListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractorAssocListingQuery = (
  { __typename?: 'Query' }
  & { getSubcontractor: Array<(
    { __typename?: 'SubcontractorEntity' }
    & Pick<SubcontractorEntity, 'ID' | 'name' | 'overdueAdvInfo' | 'overdueInvInfo' | 'activeSubcontract'>
  )> }
);

export type GetSubcontractorCCategoryQueryVariables = Exact<{
  subcontractorID?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractorCCategoryQuery = (
  { __typename?: 'Query' }
  & { getSubcontractorCCategory: Array<(
    { __typename?: 'SubcontractorCCategoryEntity' }
    & Pick<SubcontractorCCategoryEntity, 'subcontractorID' | 'costCategoryID'>
  )> }
);

export type GetOutstandingSubconClaimDnQueryVariables = Exact<{
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
}>;


export type GetOutstandingSubconClaimDnQuery = (
  { __typename?: 'Query' }
  & { getSubcontractClaim: Array<(
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'ID' | 'claimDocNo' | 'docNo' | 'docDate' | 'description' | 'outstandingAmt' | 'outstandingCertifiedAmt' | 'transactionStatus'>
  )>, getSubcontractDN: Array<(
    { __typename?: 'SubcontractDNEntity' }
    & Pick<SubcontractDnEntity, 'ID' | 'docNo' | 'docDate' | 'description' | 'outstandingAmt' | 'transactionStatus'>
  )> }
);

export type GetSystemAdminSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSystemAdminSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'conAuthDate' | 'costCategoryDates' | 'userDate' | 'roleDate' | 'uomDate' | 'bankAccountDate' | 'taxSchemeDate' | 'docNumDate' | 'uomConversionDate' | 'companyDate' | 'policyDate' | 'notificationDate' | 'costCenterDate' | 'currencyExchangeDate' | 'currencySetupDate' | 'projectSiteDate' | 'subconSetupDate'>
  & { loggedInUserProfile: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'accountID'>
  ) }
);

export type GetCommonSettingSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCommonSettingSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'currencySetupDate' | 'notificationDate' | 'taxSchemeDate' | 'uomDate' | 'uomConversionDate'>
);

export type GetSiteSystemAdminSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSiteSystemAdminSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'projectSiteDate' | 'subconSetupDate' | 'taxSchemeDate' | 'uomDate' | 'uomConversionDate' | 'companyDate' | 'policyDate'>
  & { loggedInUserProfile: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'accountID'>
  ) }
);

export type GetTaxSchemeQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetTaxSchemeQuery = (
  { __typename?: 'Query' }
  & { getTaxScheme: Array<(
    { __typename?: 'taxSchemeEntity' }
    & Pick<TaxSchemeEntity, 'id' | 'name' | 'description' | 'commonStatus' | 'isExclusive'>
  )> }
);

export type UpdateConTaxTypeMutationVariables = Exact<{
  input: ConTaxTypeInput;
}>;


export type UpdateConTaxTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateConTaxType: (
    { __typename?: 'ConTaxTypeEntity' }
    & Pick<ConTaxTypeEntity, 'ID' | 'code' | 'description'>
  ) }
);

export type CreateConTaxTypeMutationVariables = Exact<{
  input: ConTaxTypeInput;
}>;


export type CreateConTaxTypeMutation = (
  { __typename?: 'Mutation' }
  & { createConTaxType: (
    { __typename?: 'ConTaxTypeEntity' }
    & Pick<ConTaxTypeEntity, 'ID'>
  ) }
);

export type GetConTaxTypeQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
}>;


export type GetConTaxTypeQuery = (
  { __typename?: 'Query' }
  & { getConTaxType: Array<(
    { __typename?: 'ConTaxTypeEntity' }
    & Pick<ConTaxTypeEntity, 'createdTs' | 'ID' | 'code' | 'commonStatus' | 'description'>
    & { glTaxDynamic?: Maybe<Array<(
      { __typename?: 'GLTaxDynamicEntity' }
      & Pick<GlTaxDynamicEntity, 'ID' | 'accountCode'>
    )>> }
  )> }
);

export type DeleteConTaxTypeMutationVariables = Exact<{
  input: ConTaxTypeInput;
}>;


export type DeleteConTaxTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteConTaxType'>
);

export type CreateConTaxSchemeMutationVariables = Exact<{
  input: ConTaxSchemeInput;
}>;


export type CreateConTaxSchemeMutation = (
  { __typename?: 'Mutation' }
  & { createConTaxScheme: (
    { __typename?: 'ConTaxSchemeEntity' }
    & Pick<ConTaxSchemeEntity, 'ID'>
  ) }
);

export type UpdateConTaxSchemeMutationVariables = Exact<{
  input: ConTaxSchemeInput;
}>;


export type UpdateConTaxSchemeMutation = (
  { __typename?: 'Mutation' }
  & { updateConTaxScheme: (
    { __typename?: 'ConTaxSchemeEntity' }
    & Pick<ConTaxSchemeEntity, 'ID'>
  ) }
);

export type DeleteConTaxSchemeMutationVariables = Exact<{
  input: ConTaxSchemeInput;
}>;


export type DeleteConTaxSchemeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteConTaxScheme'>
);

export type CreateConTaxEffectiveDateMutationVariables = Exact<{
  input: ConTaxEffectiveDateInput;
}>;


export type CreateConTaxEffectiveDateMutation = (
  { __typename?: 'Mutation' }
  & { createConTaxEffectiveDate: (
    { __typename?: 'ConTaxEffectiveDateEntity' }
    & Pick<ConTaxEffectiveDateEntity, 'ID'>
  ) }
);

export type GetConTaxSchemeQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  taxTypeID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  taxClass?: Maybe<TaxClass>;
  orderByAsc?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
}>;


export type GetConTaxSchemeQuery = (
  { __typename?: 'Query' }
  & { getConTaxScheme: Array<(
    { __typename?: 'ConTaxSchemeEntity' }
    & Pick<ConTaxSchemeEntity, 'createdTs' | 'ID' | 'taxTypeID' | 'code' | 'description' | 'taxCategory' | 'taxClass' | 'isClaimable' | 'commonStatus'>
    & { taxType?: Maybe<(
      { __typename?: 'ConTaxTypeEntity' }
      & Pick<ConTaxTypeEntity, 'ID' | 'code'>
    )>, latestTax?: Maybe<(
      { __typename?: 'TaxObject' }
      & Pick<TaxObject, 'taxRate' | 'taxDate'>
    )>, taxEffective?: Maybe<Array<(
      { __typename?: 'ConTaxEffectiveDateEntity' }
      & Pick<ConTaxEffectiveDateEntity, 'date' | 'taxRate' | 'createdTs' | 'createdBy'>
    )>> }
  )> }
);

export type GetConTaxSchemeWithPermQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  taxTypeID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  taxClass?: Maybe<TaxClass>;
  commonStatus?: Maybe<CommonStatus>;
}>;


export type GetConTaxSchemeWithPermQuery = (
  { __typename?: 'Query' }
  & { getConTaxSchemeWithPerm: Array<(
    { __typename?: 'ConTaxSchemeEntity' }
    & Pick<ConTaxSchemeEntity, 'ID' | 'code' | 'description' | 'taxCategory' | 'taxTypeID' | 'taxClass' | 'createdTs' | 'commonStatus' | 'isClaimable'>
    & { latestTax?: Maybe<(
      { __typename?: 'TaxObject' }
      & Pick<TaxObject, 'taxRate' | 'taxDate'>
    )>, taxEffective?: Maybe<Array<(
      { __typename?: 'ConTaxEffectiveDateEntity' }
      & Pick<ConTaxEffectiveDateEntity, 'ID' | 'date' | 'taxRate' | 'createdTs' | 'createdBy' | 'modBy'>
    )>> }
  )> }
);

export type GetConTaxSchemeCodeQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  taxTypeID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
}>;


export type GetConTaxSchemeCodeQuery = (
  { __typename?: 'Query' }
  & { getConTaxScheme: Array<(
    { __typename?: 'ConTaxSchemeEntity' }
    & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
  )> }
);

export type GetConTaxEffectiveDateQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetConTaxEffectiveDateQuery = (
  { __typename?: 'Query' }
  & { getConTaxEffectiveDate: Array<(
    { __typename?: 'ConTaxEffectiveDateEntity' }
    & Pick<ConTaxEffectiveDateEntity, 'ID' | 'taxSchemeID' | 'date' | 'taxRate'>
  )> }
);

export type DeleteTaxSchemeMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteTaxSchemeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTaxScheme'>
);

export type InsertConTaxMutationVariables = Exact<{
  TaxEffectiveInput: Array<TaxEffectiveInput> | TaxEffectiveInput;
  ConTaxSchemeInput: ConTaxSchemeInput;
}>;


export type InsertConTaxMutation = (
  { __typename?: 'Mutation' }
  & { insertConTax: (
    { __typename?: 'ConTaxSchemeEntity' }
    & Pick<ConTaxSchemeEntity, 'ID'>
  ) }
);

export type UpdateConTaxSchemeWithEffectiveDateMutationVariables = Exact<{
  TaxEffectiveInput: Array<TaxEffectiveInput> | TaxEffectiveInput;
  ConTaxSchemeInput: ConTaxSchemeInput;
}>;


export type UpdateConTaxSchemeWithEffectiveDateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateConTax'>
);

export type ActivateConTaxSchemeMutationVariables = Exact<{
  input: ConTaxSchemeInput;
}>;


export type ActivateConTaxSchemeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateConTaxScheme'>
);

export type GetFilterTaxCodeQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetFilterTaxCodeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getFilterTaxCode'>
);

export type GetTaxSchemeTypeListingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTaxSchemeTypeListingQuery = (
  { __typename?: 'Query' }
  & { getTaxSchemeTypeListing: Array<(
    { __typename?: 'TaxSchemeTypeListingOutput' }
    & Pick<TaxSchemeTypeListingOutput, 'id' | 'name'>
  )> }
);

export type GetTaxSchemeTypeSortByQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTaxSchemeTypeSortByQuery = (
  { __typename?: 'Query' }
  & { getTaxSchemeTypeSortBy: Array<(
    { __typename?: 'TaxSchemeTypeSortByOutput' }
    & Pick<TaxSchemeTypeSortByOutput, 'id' | 'name' | 'code' | 'count'>
  )> }
);

export type GetTaxListingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTaxListingQuery = (
  { __typename?: 'Query' }
  & { getTaxListing: Array<(
    { __typename?: 'TaxListingOutput' }
    & Pick<TaxListingOutput, 'tax_id' | 'code' | 'description' | 'class_type' | 'tax_status' | 'effective_date' | 'rate' | 'scheme_type_id' | 'createdTs'>
  )> }
);

export type GetTaxQueryVariables = Exact<{
  taxID: Scalars['String'];
}>;


export type GetTaxQuery = (
  { __typename?: 'Query' }
  & { getTax: (
    { __typename?: 'taxEntity' }
    & Pick<TaxEntity, 'id' | 'accountId' | 'schemeTypeId' | 'code' | 'description' | 'classType' | 'isClaimable' | 'commonStatus'>
    & { taxDetail: Array<(
      { __typename?: 'taxDetailEntity' }
      & Pick<TaxDetailEntity, 'id' | 'accountId' | 'taxId' | 'effectiveDate' | 'rate' | 'commonStatus'>
    )> }
  ) }
);

export type CreateTaxMasterFileMutationVariables = Exact<{
  input: TaxInput;
}>;


export type CreateTaxMasterFileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createTaxMasterFile'>
);

export type EditTaxMasterFileMutationVariables = Exact<{
  input: EditTaxInput;
}>;


export type EditTaxMasterFileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'editTaxMasterFile'>
);

export type DeleteTaxMasterFileMutationVariables = Exact<{
  deleteInput: Array<DeleteTaxInput> | DeleteTaxInput;
  taxId: Scalars['String'];
}>;


export type DeleteTaxMasterFileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTaxMasterFile'>
);

export type ActivateTaxMasterFileMutationVariables = Exact<{
  taxId: Scalars['String'];
}>;


export type ActivateTaxMasterFileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateTaxMasterFile'>
);

export type DeactivateTaxMasterFileMutationVariables = Exact<{
  taxId: Scalars['String'];
}>;


export type DeactivateTaxMasterFileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deactivateTaxMasterFile'>
);

export type GetUomQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
}>;


export type GetUomQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'uomDate'>
  & { getUOM: Array<(
    { __typename?: 'UOMEntity' }
    & Pick<UomEntity, 'ID' | 'name' | 'code' | 'commonStatus'>
  )> }
);

export type GetUomListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  orderByAsc?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  searchValue?: Maybe<Scalars['String']>;
}>;


export type GetUomListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'uomDate' | 'getUOMCount'>
  & { getUOM: Array<(
    { __typename?: 'UOMEntity' }
    & Pick<UomEntity, 'ID' | 'accountID' | 'name' | 'code' | 'commonStatus'>
  )> }
);

export type GetUomWithPermListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  orderByAsc?: Maybe<Scalars['String']>;
  searchValue?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
}>;


export type GetUomWithPermListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUOMWithPermCount'>
  & { getUOMWithPerm: Array<(
    { __typename?: 'UOMEntity' }
    & Pick<UomEntity, 'ID' | 'code' | 'name' | 'commonStatus'>
  )> }
);

export type CreateUomMutationVariables = Exact<{
  input: UomInput;
}>;


export type CreateUomMutation = (
  { __typename?: 'Mutation' }
  & { createUOM: (
    { __typename?: 'UOMEntity' }
    & Pick<UomEntity, 'name' | 'code'>
  ) }
);

export type CreateBatchUomMutationVariables = Exact<{
  input: Array<UomInput> | UomInput;
}>;


export type CreateBatchUomMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createBatchUOM'>
);

export type UpdateUomMutationVariables = Exact<{
  input: UomInput;
}>;


export type UpdateUomMutation = (
  { __typename?: 'Mutation' }
  & { updateUOM: (
    { __typename?: 'UOMEntity' }
    & Pick<UomEntity, 'ID' | 'name' | 'code'>
  ) }
);

export type DeleteUomMutationVariables = Exact<{
  ID: UomDeleteInput;
}>;


export type DeleteUomMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUOM'>
);

export type CancelUomMutationVariables = Exact<{
  input: UomDeleteInput;
}>;


export type CancelUomMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelUOM'>
);

export type ActivateUomMutationVariables = Exact<{
  input: UomDeleteInput;
}>;


export type ActivateUomMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateUOM'>
);

export type UomImportMutationVariables = Exact<{
  batchID: Scalars['String'];
}>;


export type UomImportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uomImport'>
);

export type UomImportValidationMutationVariables = Exact<{
  input: Array<UomImportInput> | UomImportInput;
}>;


export type UomImportValidationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uomImportValidation'>
);

export type GetUomExchangeQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  fromUomID?: Maybe<Scalars['String']>;
  toUomID?: Maybe<Scalars['String']>;
}>;


export type GetUomExchangeQuery = (
  { __typename?: 'Query' }
  & { getUOMExchange: Array<(
    { __typename?: 'UomExchangeEntity' }
    & Pick<UomExchangeEntity, 'ID' | 'fromAmt' | 'toAmt' | 'exchangeRate' | 'fromUomID' | 'toUomID'>
    & { fromUOM: (
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'name' | 'code'>
    ), toUOM: (
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'name' | 'code'>
    ) }
  )> }
);

export type CreateUomExchangeMutationVariables = Exact<{
  input: UomExchangeInput;
}>;


export type CreateUomExchangeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createUOMExchange'>
);

export type UpdateUomExchangeMutationVariables = Exact<{
  input: UomExchangeInput;
}>;


export type UpdateUomExchangeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUOMExchange'>
);

export type DeleteUomExchangeMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteUomExchangeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUOMExchange'>
);

export type GetUomExchangeDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUomExchangeDataQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUomExchangeData'>
);

export type GetUomExchangeListByWbsQueryVariables = Exact<{
  uomIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetUomExchangeListByWbsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUOMExchangeListByWbs'>
);

export type GetUserQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'name' | 'department' | 'email' | 'jobTitle' | 'contactNo' | 'superUser' | 'commonStatus' | 'userName'>
  ) }
);

export type GetUsersByAccountAndSoftwareQueryVariables = Exact<{
  superUserBool?: Maybe<UserSelection>;
  status?: Maybe<CommonStatus>;
}>;


export type GetUsersByAccountAndSoftwareQuery = (
  { __typename?: 'Query' }
  & { getUsersByAccountAndSoftware: Array<(
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'name' | 'department' | 'email' | 'contactNo' | 'superUser' | 'commonStatus' | 'jobTitle' | 'userName' | 'confirmed'>
  )> }
);

export type GetUserEmailCheckerQueryVariables = Exact<{
  superUserBool?: Maybe<UserSelection>;
  status?: Maybe<CommonStatus>;
}>;


export type GetUserEmailCheckerQuery = (
  { __typename?: 'Query' }
  & { getUsersByAccountAndSoftware: Array<(
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'email' | 'userName' | 'confirmed'>
  )> }
);

export type GetUserCompanyAssignmentQueryVariables = Exact<{
  userID?: Maybe<Scalars['String']>;
}>;


export type GetUserCompanyAssignmentQuery = (
  { __typename?: 'Query' }
  & { getUserCompanyAssignment: Array<(
    { __typename?: 'UserCompanyAssignmentEntity' }
    & Pick<UserCompanyAssignmentEntity, 'ID' | 'companyID' | 'userID'>
  )> }
);

export type CreateUserMutationVariables = Exact<{
  input: UserInput;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'email'>
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  input: UserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID'>
  ) }
);

export type UnblockUserMutationVariables = Exact<{
  input: PasswordInfo;
}>;


export type UnblockUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unblockUser'>
);

export type ResendInvitationMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type ResendInvitationMutation = (
  { __typename?: 'Mutation' }
  & { resendInvitation: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'name' | 'email'>
  ) }
);

export type ChangePasswordSuMutationVariables = Exact<{
  input: PasswordInfo;
}>;


export type ChangePasswordSuMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePasswordSU'>
);

export type UpdateUserWithPasswordMutationVariables = Exact<{
  input: UserInput;
}>;


export type UpdateUserWithPasswordMutation = (
  { __typename?: 'Mutation' }
  & { updateUserWithPassword: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID'>
  ) }
);

export type AssignUserCompanyMutationVariables = Exact<{
  userID: Scalars['String'];
  companyIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type AssignUserCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'assignUserCompany'>
);

export type UnassignUserCompanyMutationVariables = Exact<{
  userID: Scalars['String'];
  companyIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type UnassignUserCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unassignUserCompany'>
);

export type UpdateLastEntityMutationVariables = Exact<{
  companyID: Scalars['String'];
}>;


export type UpdateLastEntityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateLastEntity'>
);

export type WatermarkViewQueryVariables = Exact<{
  refID?: Maybe<Scalars['String']>;
  opacity?: Maybe<Scalars['String']>;
}>;


export type WatermarkViewQuery = (
  { __typename?: 'Query' }
  & { WatermarkView: (
    { __typename?: 'DocumentsEntity' }
    & Pick<DocumentsEntity, 'fileName' | 'fileURL' | 'description'>
  ) }
);

export type GetCompanyQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetCompanyQuery = (
  { __typename?: 'Query' }
  & { getCompany: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'createdTs' | 'modTs' | 'CompanyID' | 'Name' | 'Code' | 'CompanyTax' | 'Description' | 'CompanyRegNo' | 'ContactNo' | 'Email' | 'SSTNo' | 'GSTNo' | 'GSTExpiryDate' | 'Image' | 'ImageUrl' | 'BaseCurrencyID' | 'ImageLogoPosition' | 'WatermarkImplementation' | 'WatermarkWording' | 'IsoLogoPosition' | 'IsoImplementation' | 'RecordStatus'>
    & { Address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'address2' | 'country' | 'city' | 'district' | 'state' | 'postCode'>
    )> }
  )> }
);

export type GetCompanyWatermarkQueryVariables = Exact<{
  companyID?: Maybe<Scalars['String']>;
}>;


export type GetCompanyWatermarkQuery = (
  { __typename?: 'Query' }
  & { getCompany: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CompanyID' | 'Name' | 'WatermarkWording' | 'WatermarkImplementation'>
  )> }
);

export type GetToDoListingQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
}>;


export type GetToDoListingQuery = (
  { __typename?: 'Query' }
  & { getSubconProgressClaim: Array<(
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'ID' | 'isGlExport' | 'accountID' | 'balanceAdvanceAmt' | 'balanceDeductionAmt' | 'balanceRetentionAmt' | 'baseAmt' | 'cancellationDate' | 'cancellationRemarks' | 'claimDocNo' | 'contractID' | 'createdBy' | 'createdTs' | 'currencyID' | 'currentAdvRecoupmentAmt' | 'currentCertifiedAmt' | 'currentDayWorkAmt' | 'currentDeductionAmt' | 'currentMosAmt' | 'currentVOClaimAmt' | 'description' | 'docAmt' | 'docDate' | 'docDueDate' | 'docNo' | 'docRef' | 'docRefID' | 'docRefTable' | 'exchangeRate' | 'localBaseAmt' | 'localDocAmt' | 'localTaxAmt' | 'modBy' | 'modTs' | 'previousCertifiedAmt' | 'previousMosAmt' | 'previousVOClaimAmt' | 'rejectionDate' | 'rejectionRemarks' | 'retentionAmt' | 'revisedNo' | 'sequenceNo' | 'subcontractID' | 'subcontractorID' | 'submittedCertifiedAmt' | 'submittedDate' | 'submittedDayWorkAmt' | 'submittedDeductionAmt' | 'submittedMosAmt' | 'submittedRetentionAmt' | 'submittedVOClaimAmt' | 'taxAmt' | 'taxRate' | 'taxSchemeID' | 'transactionDate' | 'transactionStatus' | 'voDisplayAmt'>
    & { toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'approvalLevel' | 'body' | 'createdBy' | 'createdTs' | 'docRefAmt' | 'docRefID' | 'docRefTable' | 'modBy' | 'modTs' | 'notifiedDate' | 'subject' | 'submitterID' | 'toDoStatus'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'approverID' | 'createdBy' | 'createdTs' | 'logDate' | 'modBy' | 'modTs' | 'notifiedDate' | 'remarks' | 'toDoID' | 'toDoStatus'>
      )>> }
    )> }
  )>, getProgressClaim: Array<(
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'ID' | 'isGlExport' | 'accountID' | 'balanceAdvanceAmt' | 'balanceDeductionAmt' | 'balanceRetentionAmt' | 'baseAmt' | 'cancellationRemarks' | 'cancellationDate' | 'claimDocNo' | 'contractID' | 'createdBy' | 'createdTs' | 'currencyID' | 'currentAdvRecoupmentAmt' | 'currentCertifiedAmt' | 'currentDayWorkAmt' | 'currentDeductionAmt' | 'currentMosAmt' | 'customerID' | 'currentVOClaimAmt' | 'description' | 'docAmt' | 'docDate' | 'docDueDate' | 'docNo' | 'docRef' | 'docRefID' | 'docRefTable' | 'exchangeRate' | 'localBaseAmt' | 'localDocAmt' | 'localTaxAmt' | 'modBy' | 'modTs' | 'previousCertifiedAmt' | 'previousMosAmt' | 'previousVOClaimAmt' | 'rejectionDate' | 'rejectionRemarks' | 'retentionAmt' | 'revisedNo' | 'sequenceNo' | 'submittedCertifiedAmt' | 'submittedDate' | 'submittedDayWorkAmt' | 'submittedDeductionAmt' | 'submittedMosAmt' | 'submittedRetentionAmt' | 'submittedVOClaimAmt' | 'taxAmt' | 'taxRate' | 'taxSchemeID' | 'transactionDate' | 'transactionStatus' | 'voDisplayAmt'>
    & { contractClaimAllocation?: Maybe<Array<(
      { __typename?: 'ContractClaimAllocationEntity' }
      & Pick<ContractClaimAllocationEntity, 'ID' | 'previousCertifiedAmt' | 'submittedCertifiedAmt' | 'currentCertifiedAmt' | 'contractClaimID' | 'wbsBudgetDetailID' | 'wbsID' | 'revenueCategoryID'>
    )>>, contractClaimVOAllocation?: Maybe<Array<(
      { __typename?: 'ContractClaimVOAllocationEntity' }
      & Pick<ContractClaimVoAllocationEntity, 'ID' | 'contractVOID' | 'currentVOClaimAmt' | 'submittedVOClaimAmt' | 'previousVOClaimAmt'>
    )>>, contractClaimVORevenueWbsAllocation?: Maybe<Array<(
      { __typename?: 'ContractClaimVORevenueWbsAllocationEntity' }
      & Pick<ContractClaimVoRevenueWbsAllocationEntity, 'ID' | 'contractVORevenueWbsID' | 'currentVOClaimAmt' | 'submittedVOClaimAmt' | 'previousVOClaimAmt'>
    )>>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'approvalLevel' | 'body' | 'createdBy' | 'createdTs' | 'docRefAmt' | 'docRefID' | 'docRefTable' | 'modBy' | 'modTs' | 'notifiedDate' | 'subject' | 'submitterID' | 'toDoStatus'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'approverID' | 'createdBy' | 'createdTs' | 'logDate' | 'modBy' | 'modTs' | 'notifiedDate' | 'remarks' | 'toDoID' | 'toDoStatus'>
      )>> }
    )> }
  )>, getPurchaseReq: Array<(
    { __typename?: 'PurchaseReqEntity' }
    & Pick<PurchaseReqEntity, 'ID' | 'contractID' | 'subcontractID' | 'docNo' | 'prDate' | 'expectedDate' | 'instructions' | 'purchaseStatus'>
  )>, getPurchaseOrder: Array<(
    { __typename?: 'PurchaseOrderEntity' }
    & Pick<PurchaseOrderEntity, 'ID' | 'accountID' | 'contractID' | 'subcontractID' | 'docDate' | 'instructions' | 'totalAmt' | 'purchaseStatus'>
  )>, getSubcontractPayment: Array<(
    { __typename?: 'SubcontractPaymentEntity' }
    & Pick<SubcontractPaymentEntity, 'ID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'bankAccountID' | 'bankDetails' | 'receiptStatus' | 'subcontractorID' | 'contractID' | 'subcontractID' | 'outstandingAmt'>
  )>, getAPPayment: Array<(
    { __typename?: 'APPaymentEntity' }
    & Pick<ApPaymentEntity, 'ID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'bankAccountID' | 'bankDetails' | 'receiptStatus' | 'supplierID' | 'outstandingAmt'>
  )>, getContractReceipt: Array<(
    { __typename?: 'ContractReceiptEntity' }
    & Pick<ContractReceiptEntity, 'ID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'accountID' | 'bankAccountID' | 'bankDetails' | 'receiptStatus' | 'customerID' | 'contractID' | 'outstandingAmt'>
  )>, getContractVO: Array<(
    { __typename?: 'ContractVOEntity' }
    & Pick<ContractVoEntity, 'ID' | 'accountID' | 'contractID' | 'docNo' | 'docRef' | 'startDate' | 'endDate' | 'description' | 'architectNo' | 'engineeringNo' | 'VOType' | 'VOStatus' | 'submissionAmt' | 'approvedAmt' | 'docDate' | 'remarks'>
  )>, getSubcontractVO: Array<(
    { __typename?: 'SubcontractVOEntity' }
    & Pick<SubcontractVoEntity, 'ID' | 'accountID' | 'subcontractID' | 'docNo' | 'docRef' | 'startDate' | 'endDate' | 'description' | 'architectNo' | 'engineeringNo' | 'VOType' | 'VOStatus' | 'submissionAmt' | 'approvedAmt' | 'remarks' | 'docDate'>
  )> }
);

export type CreateContractNewsMutationVariables = Exact<{
  input: ContractNewsInput;
}>;


export type CreateContractNewsMutation = (
  { __typename?: 'Mutation' }
  & { createContractNews: (
    { __typename?: 'ContractNewsEntity' }
    & Pick<ContractNewsEntity, 'ID'>
  ) }
);

export type UpdateContractNewsMutationVariables = Exact<{
  input: ContractNewsInput;
}>;


export type UpdateContractNewsMutation = (
  { __typename?: 'Mutation' }
  & { updateContractNews: (
    { __typename?: 'ContractNewsEntity' }
    & Pick<ContractNewsEntity, 'ID'>
  ) }
);

export type GetContractNewsQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetContractNewsQuery = (
  { __typename?: 'Query' }
  & { getContractNews: Array<(
    { __typename?: 'ContractNewsEntity' }
    & Pick<ContractNewsEntity, 'ID' | 'title' | 'content' | 'startDate' | 'endDate' | 'postStatus' | 'createdTs' | 'createdBy'>
  )> }
);

export type GetContractNotificationQueryVariables = Exact<{
  docRefTable?: Maybe<Scalars['String']>;
  recipientID?: Maybe<Scalars['String']>;
}>;


export type GetContractNotificationQuery = (
  { __typename?: 'Query' }
  & { getContractNotification: Array<(
    { __typename?: 'ContractNotificationEntity' }
    & Pick<ContractNotificationEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'subject' | 'body' | 'createdTs'>
    & { notificationRecipient?: Maybe<Array<(
      { __typename?: 'ContractNotificationRecipientEntity' }
      & Pick<ContractNotificationRecipientEntity, 'isRead'>
    )>> }
  )> }
);

export type GetContractToDoQueryVariables = Exact<{
  docRefTable?: Maybe<Scalars['String']>;
  docRefID?: Maybe<Scalars['String']>;
}>;


export type GetContractToDoQuery = (
  { __typename?: 'Query' }
  & { getContractToDo: Array<(
    { __typename?: 'ContractToDoEntity' }
    & Pick<ContractToDoEntity, 'ID'>
    & { approvalLog?: Maybe<Array<(
      { __typename?: 'ContractApprovalLogEntity' }
      & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
    )>> }
  )> }
);

export type ToDoListingQueryVariables = Exact<{ [key: string]: never; }>;


export type ToDoListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getToDoListing'>
);

export type ReadNotificationMutationVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type ReadNotificationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'readNotification'>
);

export type ApproveToDoMutationVariables = Exact<{
  IDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type ApproveToDoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'approveToDo'>
);

export type RejectToDoMutationVariables = Exact<{
  IDs: Array<Scalars['String']> | Scalars['String'];
  remark: Scalars['String'];
}>;


export type RejectToDoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectToDo'>
);

export type GetNotificationListingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNotificationListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getNotificationListing'>
);


export const LoggedInUserProfileDocument = gql`
    query loggedInUserProfile {
  loggedInUserProfile {
    ID
    accountID
    name
    email
    contactNo
    department
    superUser
    userName
    jobTitle
  }
}
    `;

/**
 * __useLoggedInUserProfileQuery__
 *
 * To run a query within a React component, call `useLoggedInUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInUserProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>(LoggedInUserProfileDocument, baseOptions);
      }
export function useLoggedInUserProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>(LoggedInUserProfileDocument, baseOptions);
        }
export type LoggedInUserProfileQueryHookResult = ReturnType<typeof useLoggedInUserProfileQuery>;
export type LoggedInUserProfileLazyQueryHookResult = ReturnType<typeof useLoggedInUserProfileLazyQuery>;
export type LoggedInUserProfileQueryResult = ApolloReactCommon.QueryResult<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>;
export const LoginDocument = gql`
    mutation login($loginId: String!, $password: String!) {
  login(loginId: $loginId, password: $password) {
    accessToken
    user {
      name
    }
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      loginId: // value for 'loginId'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const UploadProfilePicDocument = gql`
    mutation uploadProfilePic($userID: String!, $file: Upload!) {
  uploadProfilePic(userID: $userID, file: $file)
}
    `;
export type UploadProfilePicMutationFn = ApolloReactCommon.MutationFunction<UploadProfilePicMutation, UploadProfilePicMutationVariables>;

/**
 * __useUploadProfilePicMutation__
 *
 * To run a mutation, you first call `useUploadProfilePicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProfilePicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProfilePicMutation, { data, loading, error }] = useUploadProfilePicMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadProfilePicMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadProfilePicMutation, UploadProfilePicMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadProfilePicMutation, UploadProfilePicMutationVariables>(UploadProfilePicDocument, baseOptions);
      }
export type UploadProfilePicMutationHookResult = ReturnType<typeof useUploadProfilePicMutation>;
export type UploadProfilePicMutationResult = ApolloReactCommon.MutationResult<UploadProfilePicMutation>;
export type UploadProfilePicMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadProfilePicMutation, UploadProfilePicMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($newPassword: String!, $currentPassword: String!) {
  changePassword(newPassword: $newPassword, currentPassword: $currentPassword)
}
    `;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      currentPassword: // value for 'currentPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;
export type ForgotPasswordMutationFn = ApolloReactCommon.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, baseOptions);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = ApolloReactCommon.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($password: String!, $token: String!) {
  resetPassword(password: $password, token: $token)
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ActivateUserDocument = gql`
    mutation activateUser($token: String!) {
  activateUser(token: $token)
}
    `;
export type ActivateUserMutationFn = ApolloReactCommon.MutationFunction<ActivateUserMutation, ActivateUserMutationVariables>;

/**
 * __useActivateUserMutation__
 *
 * To run a mutation, you first call `useActivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserMutation, { data, loading, error }] = useActivateUserMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useActivateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateUserMutation, ActivateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateUserMutation, ActivateUserMutationVariables>(ActivateUserDocument, baseOptions);
      }
export type ActivateUserMutationHookResult = ReturnType<typeof useActivateUserMutation>;
export type ActivateUserMutationResult = ApolloReactCommon.MutationResult<ActivateUserMutation>;
export type ActivateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateUserMutation, ActivateUserMutationVariables>;
export const CreatePasswordDocument = gql`
    mutation createPassword($input: passwordInfo!) {
  createPassword(input: $input)
}
    `;
export type CreatePasswordMutationFn = ApolloReactCommon.MutationFunction<CreatePasswordMutation, CreatePasswordMutationVariables>;

/**
 * __useCreatePasswordMutation__
 *
 * To run a mutation, you first call `useCreatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPasswordMutation, { data, loading, error }] = useCreatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePasswordMutation, CreatePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePasswordMutation, CreatePasswordMutationVariables>(CreatePasswordDocument, baseOptions);
      }
export type CreatePasswordMutationHookResult = ReturnType<typeof useCreatePasswordMutation>;
export type CreatePasswordMutationResult = ApolloReactCommon.MutationResult<CreatePasswordMutation>;
export type CreatePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePasswordMutation, CreatePasswordMutationVariables>;
export const GetSubcontractorDocument = gql`
    query getSubcontractor($ID: String, $orderByAsc: String) {
  getSubcontractor(ID: $ID, orderByAsc: $orderByAsc) {
    ID
    name
    email
    accountID
    registrationNo
    associatedStatus
    contactNo
    creditTerm
    currencyID
    ratingType
    cidbDetails
    pkkDetails
    statusRemarks
    bankDetails
    gstRegNo
    description
    gstExpDate
    platformAccess
    sstRegNo
    address
    contactPerson {
      ID
      name
      designation
      email
      contactNo
      associateID
    }
    createdTs
    createdBy
    modTs
    modBy
    description
    nominatedSC
    subconClaim {
      ID
      outstandingAmt
    }
    subconAdvance {
      ID
      outstandingAmt
      baseAmt
    }
    subcontract {
      ID
      title
      subcontractSum
    }
  }
}
    `;

/**
 * __useGetSubcontractorQuery__
 *
 * To run a query within a React component, call `useGetSubcontractorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractorQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetSubcontractorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractorQuery, GetSubcontractorQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractorQuery, GetSubcontractorQueryVariables>(GetSubcontractorDocument, baseOptions);
      }
export function useGetSubcontractorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractorQuery, GetSubcontractorQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractorQuery, GetSubcontractorQueryVariables>(GetSubcontractorDocument, baseOptions);
        }
export type GetSubcontractorQueryHookResult = ReturnType<typeof useGetSubcontractorQuery>;
export type GetSubcontractorLazyQueryHookResult = ReturnType<typeof useGetSubcontractorLazyQuery>;
export type GetSubcontractorQueryResult = ApolloReactCommon.QueryResult<GetSubcontractorQuery, GetSubcontractorQueryVariables>;
export const GetSubcontractorListDocument = gql`
    query getSubcontractorList($ID: String, $orderByAsc: String) {
  getSubcontractor(ID: $ID, orderByAsc: $orderByAsc) {
    ID
    name
  }
}
    `;

/**
 * __useGetSubcontractorListQuery__
 *
 * To run a query within a React component, call `useGetSubcontractorListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractorListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractorListQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetSubcontractorListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractorListQuery, GetSubcontractorListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractorListQuery, GetSubcontractorListQueryVariables>(GetSubcontractorListDocument, baseOptions);
      }
export function useGetSubcontractorListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractorListQuery, GetSubcontractorListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractorListQuery, GetSubcontractorListQueryVariables>(GetSubcontractorListDocument, baseOptions);
        }
export type GetSubcontractorListQueryHookResult = ReturnType<typeof useGetSubcontractorListQuery>;
export type GetSubcontractorListLazyQueryHookResult = ReturnType<typeof useGetSubcontractorListLazyQuery>;
export type GetSubcontractorListQueryResult = ApolloReactCommon.QueryResult<GetSubcontractorListQuery, GetSubcontractorListQueryVariables>;
export const CreateSubcontractorImportDocument = gql`
    mutation createSubcontractorImport($batchID: String!) {
  createSubcontractorImport(batchID: $batchID)
}
    `;
export type CreateSubcontractorImportMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractorImportMutation, CreateSubcontractorImportMutationVariables>;

/**
 * __useCreateSubcontractorImportMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractorImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractorImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractorImportMutation, { data, loading, error }] = useCreateSubcontractorImportMutation({
 *   variables: {
 *      batchID: // value for 'batchID'
 *   },
 * });
 */
export function useCreateSubcontractorImportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractorImportMutation, CreateSubcontractorImportMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractorImportMutation, CreateSubcontractorImportMutationVariables>(CreateSubcontractorImportDocument, baseOptions);
      }
export type CreateSubcontractorImportMutationHookResult = ReturnType<typeof useCreateSubcontractorImportMutation>;
export type CreateSubcontractorImportMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractorImportMutation>;
export type CreateSubcontractorImportMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractorImportMutation, CreateSubcontractorImportMutationVariables>;
export const SubcontractorImportValidationDocument = gql`
    mutation subcontractorImportValidation($input: [SubcontractorImportInput!]!) {
  subcontractorImportValidation(input: $input)
}
    `;
export type SubcontractorImportValidationMutationFn = ApolloReactCommon.MutationFunction<SubcontractorImportValidationMutation, SubcontractorImportValidationMutationVariables>;

/**
 * __useSubcontractorImportValidationMutation__
 *
 * To run a mutation, you first call `useSubcontractorImportValidationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubcontractorImportValidationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subcontractorImportValidationMutation, { data, loading, error }] = useSubcontractorImportValidationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubcontractorImportValidationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubcontractorImportValidationMutation, SubcontractorImportValidationMutationVariables>) {
        return ApolloReactHooks.useMutation<SubcontractorImportValidationMutation, SubcontractorImportValidationMutationVariables>(SubcontractorImportValidationDocument, baseOptions);
      }
export type SubcontractorImportValidationMutationHookResult = ReturnType<typeof useSubcontractorImportValidationMutation>;
export type SubcontractorImportValidationMutationResult = ApolloReactCommon.MutationResult<SubcontractorImportValidationMutation>;
export type SubcontractorImportValidationMutationOptions = ApolloReactCommon.BaseMutationOptions<SubcontractorImportValidationMutation, SubcontractorImportValidationMutationVariables>;
export const CreatePlatformSupplierDocument = gql`
    mutation createPlatformSupplier($input: SupplierInput!) {
  createPlatformSupplier(input: $input) {
    ID
    name
  }
}
    `;
export type CreatePlatformSupplierMutationFn = ApolloReactCommon.MutationFunction<CreatePlatformSupplierMutation, CreatePlatformSupplierMutationVariables>;

/**
 * __useCreatePlatformSupplierMutation__
 *
 * To run a mutation, you first call `useCreatePlatformSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlatformSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlatformSupplierMutation, { data, loading, error }] = useCreatePlatformSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlatformSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePlatformSupplierMutation, CreatePlatformSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePlatformSupplierMutation, CreatePlatformSupplierMutationVariables>(CreatePlatformSupplierDocument, baseOptions);
      }
export type CreatePlatformSupplierMutationHookResult = ReturnType<typeof useCreatePlatformSupplierMutation>;
export type CreatePlatformSupplierMutationResult = ApolloReactCommon.MutationResult<CreatePlatformSupplierMutation>;
export type CreatePlatformSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePlatformSupplierMutation, CreatePlatformSupplierMutationVariables>;
export const GetSupplierDocument = gql`
    query getSupplier($ID: String, $orderByAsc: String) {
  getSupplier(ID: $ID, orderByAsc: $orderByAsc) {
    ID
    name
    registrationNo
    email
    address
    currencyID
    creditTerm
    description
    platformAccess
    contactNo
    contactPerson {
      ID
      name
      designation
      email
      contactNo
      associateID
    }
    description
    ratingType
    gstRegNo
    gstExpDate
    sstRegNo
    associatedStatus
    bankDetails
    accountID
    costCategory {
      ID
      name
    }
  }
}
    `;

/**
 * __useGetSupplierQuery__
 *
 * To run a query within a React component, call `useGetSupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetSupplierQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierQuery, GetSupplierQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierQuery, GetSupplierQueryVariables>(GetSupplierDocument, baseOptions);
      }
export function useGetSupplierLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierQuery, GetSupplierQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierQuery, GetSupplierQueryVariables>(GetSupplierDocument, baseOptions);
        }
export type GetSupplierQueryHookResult = ReturnType<typeof useGetSupplierQuery>;
export type GetSupplierLazyQueryHookResult = ReturnType<typeof useGetSupplierLazyQuery>;
export type GetSupplierQueryResult = ApolloReactCommon.QueryResult<GetSupplierQuery, GetSupplierQueryVariables>;
export const GetSupplierListDocument = gql`
    query getSupplierList($ID: String, $orderByAsc: String) {
  getSupplier(ID: $ID, orderByAsc: $orderByAsc) {
    ID
    name
  }
}
    `;

/**
 * __useGetSupplierListQuery__
 *
 * To run a query within a React component, call `useGetSupplierListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierListQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetSupplierListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierListQuery, GetSupplierListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierListQuery, GetSupplierListQueryVariables>(GetSupplierListDocument, baseOptions);
      }
export function useGetSupplierListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierListQuery, GetSupplierListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierListQuery, GetSupplierListQueryVariables>(GetSupplierListDocument, baseOptions);
        }
export type GetSupplierListQueryHookResult = ReturnType<typeof useGetSupplierListQuery>;
export type GetSupplierListLazyQueryHookResult = ReturnType<typeof useGetSupplierListLazyQuery>;
export type GetSupplierListQueryResult = ApolloReactCommon.QueryResult<GetSupplierListQuery, GetSupplierListQueryVariables>;
export const CreateSupplierCCategoryDocument = gql`
    mutation createSupplierCCategory($input: SupplierCCategoryInput!) {
  createSupplierCCategory(input: $input) {
    costCategoryID
  }
}
    `;
export type CreateSupplierCCategoryMutationFn = ApolloReactCommon.MutationFunction<CreateSupplierCCategoryMutation, CreateSupplierCCategoryMutationVariables>;

/**
 * __useCreateSupplierCCategoryMutation__
 *
 * To run a mutation, you first call `useCreateSupplierCCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierCCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplierCCategoryMutation, { data, loading, error }] = useCreateSupplierCCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSupplierCCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSupplierCCategoryMutation, CreateSupplierCCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSupplierCCategoryMutation, CreateSupplierCCategoryMutationVariables>(CreateSupplierCCategoryDocument, baseOptions);
      }
export type CreateSupplierCCategoryMutationHookResult = ReturnType<typeof useCreateSupplierCCategoryMutation>;
export type CreateSupplierCCategoryMutationResult = ApolloReactCommon.MutationResult<CreateSupplierCCategoryMutation>;
export type CreateSupplierCCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSupplierCCategoryMutation, CreateSupplierCCategoryMutationVariables>;
export const UpdateSupplierCCategoryDocument = gql`
    mutation updateSupplierCCategory($input: SupplierCCategoryInput!) {
  updateSupplierCCategory(input: $input) {
    costCategoryID
  }
}
    `;
export type UpdateSupplierCCategoryMutationFn = ApolloReactCommon.MutationFunction<UpdateSupplierCCategoryMutation, UpdateSupplierCCategoryMutationVariables>;

/**
 * __useUpdateSupplierCCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateSupplierCCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierCCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplierCCategoryMutation, { data, loading, error }] = useUpdateSupplierCCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSupplierCCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSupplierCCategoryMutation, UpdateSupplierCCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSupplierCCategoryMutation, UpdateSupplierCCategoryMutationVariables>(UpdateSupplierCCategoryDocument, baseOptions);
      }
export type UpdateSupplierCCategoryMutationHookResult = ReturnType<typeof useUpdateSupplierCCategoryMutation>;
export type UpdateSupplierCCategoryMutationResult = ApolloReactCommon.MutationResult<UpdateSupplierCCategoryMutation>;
export type UpdateSupplierCCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSupplierCCategoryMutation, UpdateSupplierCCategoryMutationVariables>;
export const GetSupplierCCategoryDocument = gql`
    query getSupplierCCategory($supplierID: String, $costCategoryID: String) {
  getSupplierCCategory(supplierID: $supplierID, costCategoryID: $costCategoryID) {
    supplierID
    costCategoryID
  }
}
    `;

/**
 * __useGetSupplierCCategoryQuery__
 *
 * To run a query within a React component, call `useGetSupplierCCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierCCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierCCategoryQuery({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useGetSupplierCCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierCCategoryQuery, GetSupplierCCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierCCategoryQuery, GetSupplierCCategoryQueryVariables>(GetSupplierCCategoryDocument, baseOptions);
      }
export function useGetSupplierCCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierCCategoryQuery, GetSupplierCCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierCCategoryQuery, GetSupplierCCategoryQueryVariables>(GetSupplierCCategoryDocument, baseOptions);
        }
export type GetSupplierCCategoryQueryHookResult = ReturnType<typeof useGetSupplierCCategoryQuery>;
export type GetSupplierCCategoryLazyQueryHookResult = ReturnType<typeof useGetSupplierCCategoryLazyQuery>;
export type GetSupplierCCategoryQueryResult = ApolloReactCommon.QueryResult<GetSupplierCCategoryQuery, GetSupplierCCategoryQueryVariables>;
export const AddCCategoryInSupplierDocument = gql`
    mutation addCCategoryInSupplier($supplierID: String!, $costCategoryID: [String!]!) {
  addCCategoryInSupplier(supplierID: $supplierID, costCategoryID: $costCategoryID)
}
    `;
export type AddCCategoryInSupplierMutationFn = ApolloReactCommon.MutationFunction<AddCCategoryInSupplierMutation, AddCCategoryInSupplierMutationVariables>;

/**
 * __useAddCCategoryInSupplierMutation__
 *
 * To run a mutation, you first call `useAddCCategoryInSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCCategoryInSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCCategoryInSupplierMutation, { data, loading, error }] = useAddCCategoryInSupplierMutation({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useAddCCategoryInSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddCCategoryInSupplierMutation, AddCCategoryInSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<AddCCategoryInSupplierMutation, AddCCategoryInSupplierMutationVariables>(AddCCategoryInSupplierDocument, baseOptions);
      }
export type AddCCategoryInSupplierMutationHookResult = ReturnType<typeof useAddCCategoryInSupplierMutation>;
export type AddCCategoryInSupplierMutationResult = ApolloReactCommon.MutationResult<AddCCategoryInSupplierMutation>;
export type AddCCategoryInSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<AddCCategoryInSupplierMutation, AddCCategoryInSupplierMutationVariables>;
export const GetSupplierExecutiveSummaryDocument = gql`
    query getSupplierExecutiveSummary($supplierID: String!) {
  supplierProfileDate(ID: $supplierID)
  PrefSupplierProductDate(supplierID: $supplierID)
  SupplierAccDate(supplierID: $supplierID)
}
    `;

/**
 * __useGetSupplierExecutiveSummaryQuery__
 *
 * To run a query within a React component, call `useGetSupplierExecutiveSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierExecutiveSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierExecutiveSummaryQuery({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetSupplierExecutiveSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierExecutiveSummaryQuery, GetSupplierExecutiveSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierExecutiveSummaryQuery, GetSupplierExecutiveSummaryQueryVariables>(GetSupplierExecutiveSummaryDocument, baseOptions);
      }
export function useGetSupplierExecutiveSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierExecutiveSummaryQuery, GetSupplierExecutiveSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierExecutiveSummaryQuery, GetSupplierExecutiveSummaryQueryVariables>(GetSupplierExecutiveSummaryDocument, baseOptions);
        }
export type GetSupplierExecutiveSummaryQueryHookResult = ReturnType<typeof useGetSupplierExecutiveSummaryQuery>;
export type GetSupplierExecutiveSummaryLazyQueryHookResult = ReturnType<typeof useGetSupplierExecutiveSummaryLazyQuery>;
export type GetSupplierExecutiveSummaryQueryResult = ApolloReactCommon.QueryResult<GetSupplierExecutiveSummaryQuery, GetSupplierExecutiveSummaryQueryVariables>;
export const GetSupplierTitleDocument = gql`
    query getSupplierTitle($ID: String, $orderByAsc: String) {
  getSupplier(ID: $ID, orderByAsc: $orderByAsc) {
    ID
    name
  }
}
    `;

/**
 * __useGetSupplierTitleQuery__
 *
 * To run a query within a React component, call `useGetSupplierTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierTitleQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetSupplierTitleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierTitleQuery, GetSupplierTitleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierTitleQuery, GetSupplierTitleQueryVariables>(GetSupplierTitleDocument, baseOptions);
      }
export function useGetSupplierTitleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierTitleQuery, GetSupplierTitleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierTitleQuery, GetSupplierTitleQueryVariables>(GetSupplierTitleDocument, baseOptions);
        }
export type GetSupplierTitleQueryHookResult = ReturnType<typeof useGetSupplierTitleQuery>;
export type GetSupplierTitleLazyQueryHookResult = ReturnType<typeof useGetSupplierTitleLazyQuery>;
export type GetSupplierTitleQueryResult = ApolloReactCommon.QueryResult<GetSupplierTitleQuery, GetSupplierTitleQueryVariables>;
export const GetActiveSupplierDocument = gql`
    query getActiveSupplier {
  getActiveSupplier
}
    `;

/**
 * __useGetActiveSupplierQuery__
 *
 * To run a query within a React component, call `useGetActiveSupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveSupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveSupplierQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveSupplierQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetActiveSupplierQuery, GetActiveSupplierQueryVariables>) {
        return ApolloReactHooks.useQuery<GetActiveSupplierQuery, GetActiveSupplierQueryVariables>(GetActiveSupplierDocument, baseOptions);
      }
export function useGetActiveSupplierLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetActiveSupplierQuery, GetActiveSupplierQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetActiveSupplierQuery, GetActiveSupplierQueryVariables>(GetActiveSupplierDocument, baseOptions);
        }
export type GetActiveSupplierQueryHookResult = ReturnType<typeof useGetActiveSupplierQuery>;
export type GetActiveSupplierLazyQueryHookResult = ReturnType<typeof useGetActiveSupplierLazyQuery>;
export type GetActiveSupplierQueryResult = ApolloReactCommon.QueryResult<GetActiveSupplierQuery, GetActiveSupplierQueryVariables>;
export const CreateSupplierImportDocument = gql`
    mutation createSupplierImport($batchID: String!) {
  createSupplierImport(batchID: $batchID)
}
    `;
export type CreateSupplierImportMutationFn = ApolloReactCommon.MutationFunction<CreateSupplierImportMutation, CreateSupplierImportMutationVariables>;

/**
 * __useCreateSupplierImportMutation__
 *
 * To run a mutation, you first call `useCreateSupplierImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplierImportMutation, { data, loading, error }] = useCreateSupplierImportMutation({
 *   variables: {
 *      batchID: // value for 'batchID'
 *   },
 * });
 */
export function useCreateSupplierImportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSupplierImportMutation, CreateSupplierImportMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSupplierImportMutation, CreateSupplierImportMutationVariables>(CreateSupplierImportDocument, baseOptions);
      }
export type CreateSupplierImportMutationHookResult = ReturnType<typeof useCreateSupplierImportMutation>;
export type CreateSupplierImportMutationResult = ApolloReactCommon.MutationResult<CreateSupplierImportMutation>;
export type CreateSupplierImportMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSupplierImportMutation, CreateSupplierImportMutationVariables>;
export const SupplierImportValidationDocument = gql`
    mutation supplierImportValidation($input: [SupplierImportInput!]!) {
  supplierImportValidation(input: $input)
}
    `;
export type SupplierImportValidationMutationFn = ApolloReactCommon.MutationFunction<SupplierImportValidationMutation, SupplierImportValidationMutationVariables>;

/**
 * __useSupplierImportValidationMutation__
 *
 * To run a mutation, you first call `useSupplierImportValidationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSupplierImportValidationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [supplierImportValidationMutation, { data, loading, error }] = useSupplierImportValidationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSupplierImportValidationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SupplierImportValidationMutation, SupplierImportValidationMutationVariables>) {
        return ApolloReactHooks.useMutation<SupplierImportValidationMutation, SupplierImportValidationMutationVariables>(SupplierImportValidationDocument, baseOptions);
      }
export type SupplierImportValidationMutationHookResult = ReturnType<typeof useSupplierImportValidationMutation>;
export type SupplierImportValidationMutationResult = ApolloReactCommon.MutationResult<SupplierImportValidationMutation>;
export type SupplierImportValidationMutationOptions = ApolloReactCommon.BaseMutationOptions<SupplierImportValidationMutation, SupplierImportValidationMutationVariables>;
export const GetCompanyNameDocument = gql`
    query getCompanyName($companyID: String) {
  getCompany(companyID: $companyID) {
    CompanyID
    Name
    Description
    CompanyRegNo
    Image
    ImageUrl
  }
}
    `;

/**
 * __useGetCompanyNameQuery__
 *
 * To run a query within a React component, call `useGetCompanyNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyNameQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetCompanyNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyNameQuery, GetCompanyNameQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyNameQuery, GetCompanyNameQueryVariables>(GetCompanyNameDocument, baseOptions);
      }
export function useGetCompanyNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyNameQuery, GetCompanyNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyNameQuery, GetCompanyNameQueryVariables>(GetCompanyNameDocument, baseOptions);
        }
export type GetCompanyNameQueryHookResult = ReturnType<typeof useGetCompanyNameQuery>;
export type GetCompanyNameLazyQueryHookResult = ReturnType<typeof useGetCompanyNameLazyQuery>;
export type GetCompanyNameQueryResult = ApolloReactCommon.QueryResult<GetCompanyNameQuery, GetCompanyNameQueryVariables>;
export const GetAllCompanyDocument = gql`
    query getAllCompany {
  getCompany {
    CompanyID
    ParentCompanyID
    AccountID
    BaseCurrencyID
    Name
    Description
    CompanyRegNo
    Code
    Address {
      ID
      address
      address2
      siteName
      country
      city
      district
      state
      postCode
      postCodeName
      AddressType
    }
    ContactNo
    Email
    SSTNo
    GSTNo
    GSTExpiryDate
    Image
    ImageUrl
    ImageBucketFile
    WebsiteUrl
    RegionID
    ImageLogoPosition
    WatermarkImplementation
    WatermarkWording
    IsoImplementation
    IsoLogoPosition
    CompanyTax
    RecordStatus
    createdBy
    createdTs
    modBy
    modTs
  }
}
    `;

/**
 * __useGetAllCompanyQuery__
 *
 * To run a query within a React component, call `useGetAllCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllCompanyQuery, GetAllCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllCompanyQuery, GetAllCompanyQueryVariables>(GetAllCompanyDocument, baseOptions);
      }
export function useGetAllCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllCompanyQuery, GetAllCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllCompanyQuery, GetAllCompanyQueryVariables>(GetAllCompanyDocument, baseOptions);
        }
export type GetAllCompanyQueryHookResult = ReturnType<typeof useGetAllCompanyQuery>;
export type GetAllCompanyLazyQueryHookResult = ReturnType<typeof useGetAllCompanyLazyQuery>;
export type GetAllCompanyQueryResult = ApolloReactCommon.QueryResult<GetAllCompanyQuery, GetAllCompanyQueryVariables>;
export const UpdateCompanyDocument = gql`
    mutation updateCompany($input: CompanyInput!) {
  updateCompany(input: $input)
}
    `;
export type UpdateCompanyMutationFn = ApolloReactCommon.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, baseOptions);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = ApolloReactCommon.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const UploadCompanyIsoLogoDocument = gql`
    mutation uploadCompanyISOLogo($file: Upload, $companyID: String!) {
  uploadCompanyISOLogo(file: $file, companyID: $companyID)
}
    `;
export type UploadCompanyIsoLogoMutationFn = ApolloReactCommon.MutationFunction<UploadCompanyIsoLogoMutation, UploadCompanyIsoLogoMutationVariables>;

/**
 * __useUploadCompanyIsoLogoMutation__
 *
 * To run a mutation, you first call `useUploadCompanyIsoLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCompanyIsoLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCompanyIsoLogoMutation, { data, loading, error }] = useUploadCompanyIsoLogoMutation({
 *   variables: {
 *      file: // value for 'file'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useUploadCompanyIsoLogoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadCompanyIsoLogoMutation, UploadCompanyIsoLogoMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadCompanyIsoLogoMutation, UploadCompanyIsoLogoMutationVariables>(UploadCompanyIsoLogoDocument, baseOptions);
      }
export type UploadCompanyIsoLogoMutationHookResult = ReturnType<typeof useUploadCompanyIsoLogoMutation>;
export type UploadCompanyIsoLogoMutationResult = ApolloReactCommon.MutationResult<UploadCompanyIsoLogoMutation>;
export type UploadCompanyIsoLogoMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadCompanyIsoLogoMutation, UploadCompanyIsoLogoMutationVariables>;
export const UpdateWatermarkCompanyDocument = gql`
    mutation updateWatermarkCompany($input: CompanyLogoInput!, $companyID: String!) {
  updateWatermarkCompany(input: $input, companyID: $companyID)
}
    `;
export type UpdateWatermarkCompanyMutationFn = ApolloReactCommon.MutationFunction<UpdateWatermarkCompanyMutation, UpdateWatermarkCompanyMutationVariables>;

/**
 * __useUpdateWatermarkCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateWatermarkCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWatermarkCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWatermarkCompanyMutation, { data, loading, error }] = useUpdateWatermarkCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useUpdateWatermarkCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateWatermarkCompanyMutation, UpdateWatermarkCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateWatermarkCompanyMutation, UpdateWatermarkCompanyMutationVariables>(UpdateWatermarkCompanyDocument, baseOptions);
      }
export type UpdateWatermarkCompanyMutationHookResult = ReturnType<typeof useUpdateWatermarkCompanyMutation>;
export type UpdateWatermarkCompanyMutationResult = ApolloReactCommon.MutationResult<UpdateWatermarkCompanyMutation>;
export type UpdateWatermarkCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateWatermarkCompanyMutation, UpdateWatermarkCompanyMutationVariables>;
export const GetLocalBankDocument = gql`
    query getLocalBank($ID: String, $countryID: String, $orderByAsc: String, $orderByDesc: String) {
  getLocalBank(ID: $ID, countryID: $countryID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    ID
    countryID
    name
    code
    address {
      city
      district
      state
      postCode
      country
    }
    contactNo
    faxNo
    website
    logo
    commonStatus
  }
}
    `;

/**
 * __useGetLocalBankQuery__
 *
 * To run a query within a React component, call `useGetLocalBankQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocalBankQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocalBankQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      countryID: // value for 'countryID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetLocalBankQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLocalBankQuery, GetLocalBankQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLocalBankQuery, GetLocalBankQueryVariables>(GetLocalBankDocument, baseOptions);
      }
export function useGetLocalBankLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLocalBankQuery, GetLocalBankQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLocalBankQuery, GetLocalBankQueryVariables>(GetLocalBankDocument, baseOptions);
        }
export type GetLocalBankQueryHookResult = ReturnType<typeof useGetLocalBankQuery>;
export type GetLocalBankLazyQueryHookResult = ReturnType<typeof useGetLocalBankLazyQuery>;
export type GetLocalBankQueryResult = ApolloReactCommon.QueryResult<GetLocalBankQuery, GetLocalBankQueryVariables>;
export const GetContractDocument = gql`
    query getContract($ID: String, $customerID: String, $orderByAsc: String, $orderByDesc: String, $contractStatus: ContractStatus) {
  getContract(ID: $ID, customerID: $customerID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, contractStatus: $contractStatus) {
    ID
    title
    accountID
    contractNo
    customerID
    description
    site {
      name
      contractID
      address
      ID
    }
    contractDate
    contractSum
    voSum
    revisedContractSum
    taxSchemeID
    startDate
    endDate
    progressRetentionPerc
    maxRetentionPerc
    targetProfitPerc
    cmgdDate
    cpcDate
    dlpDate
    ladAmt
    ladRate
    creditTerm
    latestRunNum
    docNumChecking
    contractPOCounts
    contractStatus
    customerDetail {
      name
      contactPerson {
        ID
        name
        designation
        email
        contactNo
      }
      address
      contactNo
    }
    subcontractTenderDetail {
      ID
      title
    }
    subcontractSummary
    subcontractDetail {
      ID
      title
      accountID
      contractID
      subcontractorID
      costCategoryID
      subcontractNo
      description
      subcontractDate
      taxSchemeID
      subcontractSum
      revisedSubcontractSum
      startDate
      endDate
      progressRetentionPerc
      maxRetentionPerc
      targetProfitPerc
      creditTerm
      subcontractorDetail {
        ID
        name
        associatedStatus
      }
      subcontractStatus
    }
    subcontractSummary
    contactPerson
    closureRemarks
    closureDate
    modBy
    extensionDate
    taxDetail {
      ID
      code
      description
      taxCategory
      taxTypeID
      taxClass
      createdTs
      commonStatus
      latestTax {
        taxRate
        taxDate
      }
      taxEffective {
        date
        taxRate
        createdTs
      }
    }
  }
}
    `;

/**
 * __useGetContractQuery__
 *
 * To run a query within a React component, call `useGetContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      customerID: // value for 'customerID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      contractStatus: // value for 'contractStatus'
 *   },
 * });
 */
export function useGetContractQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractQuery, GetContractQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractQuery, GetContractQueryVariables>(GetContractDocument, baseOptions);
      }
export function useGetContractLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractQuery, GetContractQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractQuery, GetContractQueryVariables>(GetContractDocument, baseOptions);
        }
export type GetContractQueryHookResult = ReturnType<typeof useGetContractQuery>;
export type GetContractLazyQueryHookResult = ReturnType<typeof useGetContractLazyQuery>;
export type GetContractQueryResult = ApolloReactCommon.QueryResult<GetContractQuery, GetContractQueryVariables>;
export const GetContractSubmenuDocument = gql`
    query getContractSubmenu($ID: String) {
  getContract(ID: $ID) {
    ID
    title
    accountID
    contractNo
    customerID
  }
  getAllContract: getContract {
    ID
    title
    accountID
    contractNo
    customerID
  }
}
    `;

/**
 * __useGetContractSubmenuQuery__
 *
 * To run a query within a React component, call `useGetContractSubmenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractSubmenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractSubmenuQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetContractSubmenuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractSubmenuQuery, GetContractSubmenuQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractSubmenuQuery, GetContractSubmenuQueryVariables>(GetContractSubmenuDocument, baseOptions);
      }
export function useGetContractSubmenuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractSubmenuQuery, GetContractSubmenuQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractSubmenuQuery, GetContractSubmenuQueryVariables>(GetContractSubmenuDocument, baseOptions);
        }
export type GetContractSubmenuQueryHookResult = ReturnType<typeof useGetContractSubmenuQuery>;
export type GetContractSubmenuLazyQueryHookResult = ReturnType<typeof useGetContractSubmenuLazyQuery>;
export type GetContractSubmenuQueryResult = ApolloReactCommon.QueryResult<GetContractSubmenuQuery, GetContractSubmenuQueryVariables>;
export const CreateContractDocument = gql`
    mutation createContract($input: ContractInput!, $siteInput: [SiteInput!]!) {
  createContract(input: $input, siteInput: $siteInput) {
    contractNo
    title
    description
    contractSum
    taxSchemeID
    startDate
    endDate
    progressRetentionPerc
    maxRetentionPerc
    cmgdDate
    cpcDate
    creditTerm
    customerID
    accountID
    contractDate
  }
}
    `;
export type CreateContractMutationFn = ApolloReactCommon.MutationFunction<CreateContractMutation, CreateContractMutationVariables>;

/**
 * __useCreateContractMutation__
 *
 * To run a mutation, you first call `useCreateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractMutation, { data, loading, error }] = useCreateContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *      siteInput: // value for 'siteInput'
 *   },
 * });
 */
export function useCreateContractMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractMutation, CreateContractMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractMutation, CreateContractMutationVariables>(CreateContractDocument, baseOptions);
      }
export type CreateContractMutationHookResult = ReturnType<typeof useCreateContractMutation>;
export type CreateContractMutationResult = ApolloReactCommon.MutationResult<CreateContractMutation>;
export type CreateContractMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractMutation, CreateContractMutationVariables>;
export const UpdateContractDocument = gql`
    mutation updateContract($input: ContractInput!) {
  updateContract(input: $input)
}
    `;
export type UpdateContractMutationFn = ApolloReactCommon.MutationFunction<UpdateContractMutation, UpdateContractMutationVariables>;

/**
 * __useUpdateContractMutation__
 *
 * To run a mutation, you first call `useUpdateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractMutation, { data, loading, error }] = useUpdateContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContractMutation, UpdateContractMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContractMutation, UpdateContractMutationVariables>(UpdateContractDocument, baseOptions);
      }
export type UpdateContractMutationHookResult = ReturnType<typeof useUpdateContractMutation>;
export type UpdateContractMutationResult = ApolloReactCommon.MutationResult<UpdateContractMutation>;
export type UpdateContractMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContractMutation, UpdateContractMutationVariables>;
export const GetContractGuaranteeDocument = gql`
    query getContractGuarantee($ID: String, $contractID: String!, $orderByAsc: String) {
  getContractGuarantee(ID: $ID, contractID: $contractID, orderByAsc: $orderByAsc) {
    ID
    modBy
    accountID
    contractID
    docRef
    issuer
    guaranteeTypeID
    description
    docAmt
    startDate
    expiryDate
    collateralAmt
    remarks
    cancellationDate
    guaranteeStatus
    guaranteeType {
      name
    }
  }
  contractGuaranteeInfo(contractID: $contractID)
}
    `;

/**
 * __useGetContractGuaranteeQuery__
 *
 * To run a query within a React component, call `useGetContractGuaranteeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractGuaranteeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractGuaranteeQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetContractGuaranteeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractGuaranteeQuery, GetContractGuaranteeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractGuaranteeQuery, GetContractGuaranteeQueryVariables>(GetContractGuaranteeDocument, baseOptions);
      }
export function useGetContractGuaranteeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractGuaranteeQuery, GetContractGuaranteeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractGuaranteeQuery, GetContractGuaranteeQueryVariables>(GetContractGuaranteeDocument, baseOptions);
        }
export type GetContractGuaranteeQueryHookResult = ReturnType<typeof useGetContractGuaranteeQuery>;
export type GetContractGuaranteeLazyQueryHookResult = ReturnType<typeof useGetContractGuaranteeLazyQuery>;
export type GetContractGuaranteeQueryResult = ApolloReactCommon.QueryResult<GetContractGuaranteeQuery, GetContractGuaranteeQueryVariables>;
export const CreateContractGuaranteeDocument = gql`
    mutation createContractGuarantee($input: ContractGuaranteeInput!) {
  createContractGuarantee(input: $input) {
    ID
  }
}
    `;
export type CreateContractGuaranteeMutationFn = ApolloReactCommon.MutationFunction<CreateContractGuaranteeMutation, CreateContractGuaranteeMutationVariables>;

/**
 * __useCreateContractGuaranteeMutation__
 *
 * To run a mutation, you first call `useCreateContractGuaranteeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractGuaranteeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractGuaranteeMutation, { data, loading, error }] = useCreateContractGuaranteeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractGuaranteeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractGuaranteeMutation, CreateContractGuaranteeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractGuaranteeMutation, CreateContractGuaranteeMutationVariables>(CreateContractGuaranteeDocument, baseOptions);
      }
export type CreateContractGuaranteeMutationHookResult = ReturnType<typeof useCreateContractGuaranteeMutation>;
export type CreateContractGuaranteeMutationResult = ApolloReactCommon.MutationResult<CreateContractGuaranteeMutation>;
export type CreateContractGuaranteeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractGuaranteeMutation, CreateContractGuaranteeMutationVariables>;
export const UpdateContractGuaranteeDocument = gql`
    mutation updateContractGuarantee($input: ContractGuaranteeInput!) {
  updateContractGuarantee(input: $input) {
    ID
  }
}
    `;
export type UpdateContractGuaranteeMutationFn = ApolloReactCommon.MutationFunction<UpdateContractGuaranteeMutation, UpdateContractGuaranteeMutationVariables>;

/**
 * __useUpdateContractGuaranteeMutation__
 *
 * To run a mutation, you first call `useUpdateContractGuaranteeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractGuaranteeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractGuaranteeMutation, { data, loading, error }] = useUpdateContractGuaranteeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractGuaranteeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContractGuaranteeMutation, UpdateContractGuaranteeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContractGuaranteeMutation, UpdateContractGuaranteeMutationVariables>(UpdateContractGuaranteeDocument, baseOptions);
      }
export type UpdateContractGuaranteeMutationHookResult = ReturnType<typeof useUpdateContractGuaranteeMutation>;
export type UpdateContractGuaranteeMutationResult = ApolloReactCommon.MutationResult<UpdateContractGuaranteeMutation>;
export type UpdateContractGuaranteeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContractGuaranteeMutation, UpdateContractGuaranteeMutationVariables>;
export const GetContractInsuranceDocument = gql`
    query getContractInsurance($ID: String, $contractID: String!) {
  getContractInsurance(ID: $ID, contractID: $contractID) {
    ID
    modBy
    accountID
    contractID
    docRef
    issuer
    insuranceTypeID
    description
    docAmt
    startDate
    expiryDate
    remarks
    cancellationDate
    insuranceStatus
    insuranceType {
      name
    }
  }
  contractInsuranceInfo(contractID: $contractID)
}
    `;

/**
 * __useGetContractInsuranceQuery__
 *
 * To run a query within a React component, call `useGetContractInsuranceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractInsuranceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractInsuranceQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetContractInsuranceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractInsuranceQuery, GetContractInsuranceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractInsuranceQuery, GetContractInsuranceQueryVariables>(GetContractInsuranceDocument, baseOptions);
      }
export function useGetContractInsuranceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractInsuranceQuery, GetContractInsuranceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractInsuranceQuery, GetContractInsuranceQueryVariables>(GetContractInsuranceDocument, baseOptions);
        }
export type GetContractInsuranceQueryHookResult = ReturnType<typeof useGetContractInsuranceQuery>;
export type GetContractInsuranceLazyQueryHookResult = ReturnType<typeof useGetContractInsuranceLazyQuery>;
export type GetContractInsuranceQueryResult = ApolloReactCommon.QueryResult<GetContractInsuranceQuery, GetContractInsuranceQueryVariables>;
export const CreateContractInsuranceDocument = gql`
    mutation createContractInsurance($input: ContractInsuranceInput!) {
  createContractInsurance(input: $input) {
    ID
  }
}
    `;
export type CreateContractInsuranceMutationFn = ApolloReactCommon.MutationFunction<CreateContractInsuranceMutation, CreateContractInsuranceMutationVariables>;

/**
 * __useCreateContractInsuranceMutation__
 *
 * To run a mutation, you first call `useCreateContractInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractInsuranceMutation, { data, loading, error }] = useCreateContractInsuranceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractInsuranceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractInsuranceMutation, CreateContractInsuranceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractInsuranceMutation, CreateContractInsuranceMutationVariables>(CreateContractInsuranceDocument, baseOptions);
      }
export type CreateContractInsuranceMutationHookResult = ReturnType<typeof useCreateContractInsuranceMutation>;
export type CreateContractInsuranceMutationResult = ApolloReactCommon.MutationResult<CreateContractInsuranceMutation>;
export type CreateContractInsuranceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractInsuranceMutation, CreateContractInsuranceMutationVariables>;
export const UpdateContractInsuranceDocument = gql`
    mutation updateContractInsurance($input: ContractInsuranceInput!) {
  updateContractInsurance(input: $input) {
    ID
  }
}
    `;
export type UpdateContractInsuranceMutationFn = ApolloReactCommon.MutationFunction<UpdateContractInsuranceMutation, UpdateContractInsuranceMutationVariables>;

/**
 * __useUpdateContractInsuranceMutation__
 *
 * To run a mutation, you first call `useUpdateContractInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractInsuranceMutation, { data, loading, error }] = useUpdateContractInsuranceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractInsuranceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContractInsuranceMutation, UpdateContractInsuranceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContractInsuranceMutation, UpdateContractInsuranceMutationVariables>(UpdateContractInsuranceDocument, baseOptions);
      }
export type UpdateContractInsuranceMutationHookResult = ReturnType<typeof useUpdateContractInsuranceMutation>;
export type UpdateContractInsuranceMutationResult = ApolloReactCommon.MutationResult<UpdateContractInsuranceMutation>;
export type UpdateContractInsuranceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContractInsuranceMutation, UpdateContractInsuranceMutationVariables>;
export const ActionOnContractStatusDocument = gql`
    mutation actionOnContractStatus($input: ContractActionInput!, $ID: String!) {
  actionOnContractStatus(input: $input, ID: $ID)
}
    `;
export type ActionOnContractStatusMutationFn = ApolloReactCommon.MutationFunction<ActionOnContractStatusMutation, ActionOnContractStatusMutationVariables>;

/**
 * __useActionOnContractStatusMutation__
 *
 * To run a mutation, you first call `useActionOnContractStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionOnContractStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionOnContractStatusMutation, { data, loading, error }] = useActionOnContractStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useActionOnContractStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActionOnContractStatusMutation, ActionOnContractStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ActionOnContractStatusMutation, ActionOnContractStatusMutationVariables>(ActionOnContractStatusDocument, baseOptions);
      }
export type ActionOnContractStatusMutationHookResult = ReturnType<typeof useActionOnContractStatusMutation>;
export type ActionOnContractStatusMutationResult = ApolloReactCommon.MutationResult<ActionOnContractStatusMutation>;
export type ActionOnContractStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ActionOnContractStatusMutation, ActionOnContractStatusMutationVariables>;
export const CreateContractExtensionDateDocument = gql`
    mutation createContractExtensionDate($input: ContractExtensionInput!, $ID: String!) {
  createContractExtensionDate(input: $input, ID: $ID)
}
    `;
export type CreateContractExtensionDateMutationFn = ApolloReactCommon.MutationFunction<CreateContractExtensionDateMutation, CreateContractExtensionDateMutationVariables>;

/**
 * __useCreateContractExtensionDateMutation__
 *
 * To run a mutation, you first call `useCreateContractExtensionDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractExtensionDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractExtensionDateMutation, { data, loading, error }] = useCreateContractExtensionDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCreateContractExtensionDateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractExtensionDateMutation, CreateContractExtensionDateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractExtensionDateMutation, CreateContractExtensionDateMutationVariables>(CreateContractExtensionDateDocument, baseOptions);
      }
export type CreateContractExtensionDateMutationHookResult = ReturnType<typeof useCreateContractExtensionDateMutation>;
export type CreateContractExtensionDateMutationResult = ApolloReactCommon.MutationResult<CreateContractExtensionDateMutation>;
export type CreateContractExtensionDateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractExtensionDateMutation, CreateContractExtensionDateMutationVariables>;
export const CancelContractGuaranteeDocument = gql`
    mutation cancelContractGuarantee($input: ContractGuaranteeCancellationInput!) {
  cancelContractGuarantee(input: $input)
}
    `;
export type CancelContractGuaranteeMutationFn = ApolloReactCommon.MutationFunction<CancelContractGuaranteeMutation, CancelContractGuaranteeMutationVariables>;

/**
 * __useCancelContractGuaranteeMutation__
 *
 * To run a mutation, you first call `useCancelContractGuaranteeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelContractGuaranteeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelContractGuaranteeMutation, { data, loading, error }] = useCancelContractGuaranteeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelContractGuaranteeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelContractGuaranteeMutation, CancelContractGuaranteeMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelContractGuaranteeMutation, CancelContractGuaranteeMutationVariables>(CancelContractGuaranteeDocument, baseOptions);
      }
export type CancelContractGuaranteeMutationHookResult = ReturnType<typeof useCancelContractGuaranteeMutation>;
export type CancelContractGuaranteeMutationResult = ApolloReactCommon.MutationResult<CancelContractGuaranteeMutation>;
export type CancelContractGuaranteeMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelContractGuaranteeMutation, CancelContractGuaranteeMutationVariables>;
export const CancelContractInsuranceDocument = gql`
    mutation cancelContractInsurance($input: ContractInsuranceCancellationInput!) {
  cancelContractInsurance(input: $input)
}
    `;
export type CancelContractInsuranceMutationFn = ApolloReactCommon.MutationFunction<CancelContractInsuranceMutation, CancelContractInsuranceMutationVariables>;

/**
 * __useCancelContractInsuranceMutation__
 *
 * To run a mutation, you first call `useCancelContractInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelContractInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelContractInsuranceMutation, { data, loading, error }] = useCancelContractInsuranceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelContractInsuranceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelContractInsuranceMutation, CancelContractInsuranceMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelContractInsuranceMutation, CancelContractInsuranceMutationVariables>(CancelContractInsuranceDocument, baseOptions);
      }
export type CancelContractInsuranceMutationHookResult = ReturnType<typeof useCancelContractInsuranceMutation>;
export type CancelContractInsuranceMutationResult = ApolloReactCommon.MutationResult<CancelContractInsuranceMutation>;
export type CancelContractInsuranceMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelContractInsuranceMutation, CancelContractInsuranceMutationVariables>;
export const GetContractSummaryDocument = gql`
    query getContractSummary($contractID: String!) {
  contractInfo(contractID: $contractID) {
    contractSum
    contractDate
  }
  contractGuaranteeInfo(contractID: $contractID)
  contractInsuranceInfo(contractID: $contractID)
  contractVO(contractID: $contractID) {
    voSum
    voOmission
    voSumCount
    voOmissionCount
    voClientCount
    voSubconCount
    totalVoAmt
  }
  outstandingAdvances(contractID: $contractID) {
    docNo
    docDate
    docAmt
    baseAmt
    outstandingAmt
  }
  outstandingClaims(contractID: $contractID) {
    docNo
    docDate
    docAmt
    baseAmt
    outstandingAmt
  }
  submittedVONotification(contractID: $contractID)
  lastExtensionDate(contractID: $contractID)
  contractClosureDate(contractID: $contractID)
  retention(contractID: $contractID) {
    retentionSum
    retentionBalance
    releasedSum
  }
  contractVoCalculation(contractID: $contractID)
  lastMiscExpDate(contractID: $contractID)
}
    `;

/**
 * __useGetContractSummaryQuery__
 *
 * To run a query within a React component, call `useGetContractSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractSummaryQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetContractSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractSummaryQuery, GetContractSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractSummaryQuery, GetContractSummaryQueryVariables>(GetContractSummaryDocument, baseOptions);
      }
export function useGetContractSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractSummaryQuery, GetContractSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractSummaryQuery, GetContractSummaryQueryVariables>(GetContractSummaryDocument, baseOptions);
        }
export type GetContractSummaryQueryHookResult = ReturnType<typeof useGetContractSummaryQuery>;
export type GetContractSummaryLazyQueryHookResult = ReturnType<typeof useGetContractSummaryLazyQuery>;
export type GetContractSummaryQueryResult = ApolloReactCommon.QueryResult<GetContractSummaryQuery, GetContractSummaryQueryVariables>;
export const CreateContractCertificateDocument = gql`
    mutation createContractCertificate($input: ContractCertificateInput!, $ID: String!) {
  createContractCertificate(input: $input, ID: $ID)
}
    `;
export type CreateContractCertificateMutationFn = ApolloReactCommon.MutationFunction<CreateContractCertificateMutation, CreateContractCertificateMutationVariables>;

/**
 * __useCreateContractCertificateMutation__
 *
 * To run a mutation, you first call `useCreateContractCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractCertificateMutation, { data, loading, error }] = useCreateContractCertificateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCreateContractCertificateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractCertificateMutation, CreateContractCertificateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractCertificateMutation, CreateContractCertificateMutationVariables>(CreateContractCertificateDocument, baseOptions);
      }
export type CreateContractCertificateMutationHookResult = ReturnType<typeof useCreateContractCertificateMutation>;
export type CreateContractCertificateMutationResult = ApolloReactCommon.MutationResult<CreateContractCertificateMutation>;
export type CreateContractCertificateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractCertificateMutation, CreateContractCertificateMutationVariables>;
export const GetConSubconRetentionDocument = gql`
    query getConSubconRetention($contractID: String, $subcontractID: String, $customerID: String, $subcontractorID: String) {
  getRetentionClaim(contractID: $contractID, customerID: $customerID) {
    ID
    isGlExport
    accountID
    balanceAdvanceAmt
    balanceDeductionAmt
    balanceRetentionAmt
    baseAmt
    cancellationDate
    cancellationRemarks
    claimDocNo
    contractID
    createdBy
    createdTs
    currencyID
    currentAdvRecoupmentAmt
    currentCertifiedAmt
    currentDayWorkAmt
    currentDeductionAmt
    currentMosAmt
    currentVOClaimAmt
    customerID
    description
    docAmt
    docDate
    docDueDate
    docNo
    docRef
    docRefID
    docRefTable
    exchangeRate
    localBaseAmt
    localDocAmt
    localTaxAmt
    modBy
    modTs
    previousCertifiedAmt
    previousMosAmt
    previousVOClaimAmt
    rejectionDate
    rejectionRemarks
    retentionAmt
    revisedNo
    sequenceNo
    submittedCertifiedAmt
    submittedDate
    submittedDayWorkAmt
    submittedDeductionAmt
    submittedMosAmt
    submittedRetentionAmt
    submittedVOClaimAmt
    taxAmt
    taxRate
    taxSchemeID
    toDo {
      ID
      accountID
      approvalLevel
      approvalLog {
        ID
        accountID
        approverID
        createdBy
        createdTs
        logDate
        modBy
        modTs
        notifiedDate
        remarks
        toDoID
        toDoStatus
      }
      body
      createdBy
      createdTs
      docRefAmt
      docRefID
      docRefTable
      modBy
      modTs
      notifiedDate
      subject
      submitterID
      toDoStatus
    }
    transactionDate
    transactionStatus
    voDisplayAmt
  }
  retention(contractID: $contractID, subcontractID: $subcontractID) {
    retentionSum
    retentionBalance
    releasedSum
  }
}
    `;

/**
 * __useGetConSubconRetentionQuery__
 *
 * To run a query within a React component, call `useGetConSubconRetentionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConSubconRetentionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConSubconRetentionQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      subcontractID: // value for 'subcontractID'
 *      customerID: // value for 'customerID'
 *      subcontractorID: // value for 'subcontractorID'
 *   },
 * });
 */
export function useGetConSubconRetentionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetConSubconRetentionQuery, GetConSubconRetentionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetConSubconRetentionQuery, GetConSubconRetentionQueryVariables>(GetConSubconRetentionDocument, baseOptions);
      }
export function useGetConSubconRetentionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetConSubconRetentionQuery, GetConSubconRetentionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetConSubconRetentionQuery, GetConSubconRetentionQueryVariables>(GetConSubconRetentionDocument, baseOptions);
        }
export type GetConSubconRetentionQueryHookResult = ReturnType<typeof useGetConSubconRetentionQuery>;
export type GetConSubconRetentionLazyQueryHookResult = ReturnType<typeof useGetConSubconRetentionLazyQuery>;
export type GetConSubconRetentionQueryResult = ApolloReactCommon.QueryResult<GetConSubconRetentionQuery, GetConSubconRetentionQueryVariables>;
export const GetContractExtensionDocument = gql`
    query getContractExtension($ID: String!, $customerID: String, $orderByAsc: String, $orderByDesc: String) {
  getContract(ID: $ID, customerID: $customerID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    ID
    title
    accountID
    contractNo
    customerID
    description
    contractDate
    contractSum
    taxSchemeID
    startDate
    endDate
    creditTerm
    contractStatus
    customerDetail {
      name
      contactPerson {
        ID
        name
        designation
        email
        contactNo
      }
      contactNo
    }
    extensionDate
  }
  lastExtensionDate(contractID: $ID)
}
    `;

/**
 * __useGetContractExtensionQuery__
 *
 * To run a query within a React component, call `useGetContractExtensionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractExtensionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractExtensionQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      customerID: // value for 'customerID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetContractExtensionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractExtensionQuery, GetContractExtensionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractExtensionQuery, GetContractExtensionQueryVariables>(GetContractExtensionDocument, baseOptions);
      }
export function useGetContractExtensionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractExtensionQuery, GetContractExtensionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractExtensionQuery, GetContractExtensionQueryVariables>(GetContractExtensionDocument, baseOptions);
        }
export type GetContractExtensionQueryHookResult = ReturnType<typeof useGetContractExtensionQuery>;
export type GetContractExtensionLazyQueryHookResult = ReturnType<typeof useGetContractExtensionLazyQuery>;
export type GetContractExtensionQueryResult = ApolloReactCommon.QueryResult<GetContractExtensionQuery, GetContractExtensionQueryVariables>;
export const RetentionCheckingDocument = gql`
    query retentionChecking($contractID: String, $subcontractID: String) {
  retentionChecking(contractID: $contractID, subcontractID: $subcontractID)
}
    `;

/**
 * __useRetentionCheckingQuery__
 *
 * To run a query within a React component, call `useRetentionCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetentionCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetentionCheckingQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useRetentionCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RetentionCheckingQuery, RetentionCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<RetentionCheckingQuery, RetentionCheckingQueryVariables>(RetentionCheckingDocument, baseOptions);
      }
export function useRetentionCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RetentionCheckingQuery, RetentionCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RetentionCheckingQuery, RetentionCheckingQueryVariables>(RetentionCheckingDocument, baseOptions);
        }
export type RetentionCheckingQueryHookResult = ReturnType<typeof useRetentionCheckingQuery>;
export type RetentionCheckingLazyQueryHookResult = ReturnType<typeof useRetentionCheckingLazyQuery>;
export type RetentionCheckingQueryResult = ApolloReactCommon.QueryResult<RetentionCheckingQuery, RetentionCheckingQueryVariables>;
export const GetContractTitleDocument = gql`
    query getContractTitle($ID: String, $customerID: String, $companyID: String, $orderByAsc: String, $orderByDesc: String) {
  getContract(ID: $ID, customerID: $customerID, companyID: $companyID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    ID
    title
    description
    contractNo
    contractStatus
    department
  }
}
    `;

/**
 * __useGetContractTitleQuery__
 *
 * To run a query within a React component, call `useGetContractTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractTitleQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      customerID: // value for 'customerID'
 *      companyID: // value for 'companyID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetContractTitleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractTitleQuery, GetContractTitleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractTitleQuery, GetContractTitleQueryVariables>(GetContractTitleDocument, baseOptions);
      }
export function useGetContractTitleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractTitleQuery, GetContractTitleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractTitleQuery, GetContractTitleQueryVariables>(GetContractTitleDocument, baseOptions);
        }
export type GetContractTitleQueryHookResult = ReturnType<typeof useGetContractTitleQuery>;
export type GetContractTitleLazyQueryHookResult = ReturnType<typeof useGetContractTitleLazyQuery>;
export type GetContractTitleQueryResult = ApolloReactCommon.QueryResult<GetContractTitleQuery, GetContractTitleQueryVariables>;
export const InsertUpdateSiteDocument = gql`
    mutation insertUpdateSite($siteInput: [SiteInput!]!, $contractID: String!) {
  insertUpdateSite(siteInput: $siteInput, contractID: $contractID)
}
    `;
export type InsertUpdateSiteMutationFn = ApolloReactCommon.MutationFunction<InsertUpdateSiteMutation, InsertUpdateSiteMutationVariables>;

/**
 * __useInsertUpdateSiteMutation__
 *
 * To run a mutation, you first call `useInsertUpdateSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUpdateSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUpdateSiteMutation, { data, loading, error }] = useInsertUpdateSiteMutation({
 *   variables: {
 *      siteInput: // value for 'siteInput'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useInsertUpdateSiteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertUpdateSiteMutation, InsertUpdateSiteMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertUpdateSiteMutation, InsertUpdateSiteMutationVariables>(InsertUpdateSiteDocument, baseOptions);
      }
export type InsertUpdateSiteMutationHookResult = ReturnType<typeof useInsertUpdateSiteMutation>;
export type InsertUpdateSiteMutationResult = ApolloReactCommon.MutationResult<InsertUpdateSiteMutation>;
export type InsertUpdateSiteMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertUpdateSiteMutation, InsertUpdateSiteMutationVariables>;
export const AssociateCheckingDocument = gql`
    query associateChecking($subcontractID: String, $contractID: String) {
  claimChecking(subcontractID: $subcontractID, contractID: $contractID)
  voChecking(subcontractID: $subcontractID, contractID: $contractID)
}
    `;

/**
 * __useAssociateCheckingQuery__
 *
 * To run a query within a React component, call `useAssociateCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssociateCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssociateCheckingQuery({
 *   variables: {
 *      subcontractID: // value for 'subcontractID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useAssociateCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AssociateCheckingQuery, AssociateCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<AssociateCheckingQuery, AssociateCheckingQueryVariables>(AssociateCheckingDocument, baseOptions);
      }
export function useAssociateCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AssociateCheckingQuery, AssociateCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AssociateCheckingQuery, AssociateCheckingQueryVariables>(AssociateCheckingDocument, baseOptions);
        }
export type AssociateCheckingQueryHookResult = ReturnType<typeof useAssociateCheckingQuery>;
export type AssociateCheckingLazyQueryHookResult = ReturnType<typeof useAssociateCheckingLazyQuery>;
export type AssociateCheckingQueryResult = ApolloReactCommon.QueryResult<AssociateCheckingQuery, AssociateCheckingQueryVariables>;
export const GetMiscExpenseDocument = gql`
    query getMiscExpense($ID: String, $contractID: String) {
  getMiscExpense(ID: $ID, contractID: $contractID) {
    ID
    status
    accountID
    contractID
    costCategoryID
    docRef
    docDate
    description
    docAmt
    baseAmt
    taxRate
    taxAmt
    taxSchemeID
    contract {
      title
    }
    costCategory {
      name
      costClass
    }
  }
  lastMiscExpDate(contractID: $contractID)
}
    `;

/**
 * __useGetMiscExpenseQuery__
 *
 * To run a query within a React component, call `useGetMiscExpenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMiscExpenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMiscExpenseQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetMiscExpenseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMiscExpenseQuery, GetMiscExpenseQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMiscExpenseQuery, GetMiscExpenseQueryVariables>(GetMiscExpenseDocument, baseOptions);
      }
export function useGetMiscExpenseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMiscExpenseQuery, GetMiscExpenseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMiscExpenseQuery, GetMiscExpenseQueryVariables>(GetMiscExpenseDocument, baseOptions);
        }
export type GetMiscExpenseQueryHookResult = ReturnType<typeof useGetMiscExpenseQuery>;
export type GetMiscExpenseLazyQueryHookResult = ReturnType<typeof useGetMiscExpenseLazyQuery>;
export type GetMiscExpenseQueryResult = ApolloReactCommon.QueryResult<GetMiscExpenseQuery, GetMiscExpenseQueryVariables>;
export const CreateMiscExpenseDocument = gql`
    mutation createMiscExpense($input: MiscExpenseInput!) {
  createMiscExpense(input: $input)
}
    `;
export type CreateMiscExpenseMutationFn = ApolloReactCommon.MutationFunction<CreateMiscExpenseMutation, CreateMiscExpenseMutationVariables>;

/**
 * __useCreateMiscExpenseMutation__
 *
 * To run a mutation, you first call `useCreateMiscExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMiscExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMiscExpenseMutation, { data, loading, error }] = useCreateMiscExpenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMiscExpenseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMiscExpenseMutation, CreateMiscExpenseMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMiscExpenseMutation, CreateMiscExpenseMutationVariables>(CreateMiscExpenseDocument, baseOptions);
      }
export type CreateMiscExpenseMutationHookResult = ReturnType<typeof useCreateMiscExpenseMutation>;
export type CreateMiscExpenseMutationResult = ApolloReactCommon.MutationResult<CreateMiscExpenseMutation>;
export type CreateMiscExpenseMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMiscExpenseMutation, CreateMiscExpenseMutationVariables>;
export const UpdateMiscExpenseDocument = gql`
    mutation updateMiscExpense($input: MiscExpenseInput!) {
  updateMiscExpense(input: $input)
}
    `;
export type UpdateMiscExpenseMutationFn = ApolloReactCommon.MutationFunction<UpdateMiscExpenseMutation, UpdateMiscExpenseMutationVariables>;

/**
 * __useUpdateMiscExpenseMutation__
 *
 * To run a mutation, you first call `useUpdateMiscExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMiscExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMiscExpenseMutation, { data, loading, error }] = useUpdateMiscExpenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMiscExpenseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMiscExpenseMutation, UpdateMiscExpenseMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMiscExpenseMutation, UpdateMiscExpenseMutationVariables>(UpdateMiscExpenseDocument, baseOptions);
      }
export type UpdateMiscExpenseMutationHookResult = ReturnType<typeof useUpdateMiscExpenseMutation>;
export type UpdateMiscExpenseMutationResult = ApolloReactCommon.MutationResult<UpdateMiscExpenseMutation>;
export type UpdateMiscExpenseMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMiscExpenseMutation, UpdateMiscExpenseMutationVariables>;
export const CancelMiscExpenseDocument = gql`
    mutation cancelMiscExpense($input: MiscExpenseCancellationInput!, $ID: String!) {
  cancelMiscExpense(input: $input, ID: $ID)
}
    `;
export type CancelMiscExpenseMutationFn = ApolloReactCommon.MutationFunction<CancelMiscExpenseMutation, CancelMiscExpenseMutationVariables>;

/**
 * __useCancelMiscExpenseMutation__
 *
 * To run a mutation, you first call `useCancelMiscExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelMiscExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelMiscExpenseMutation, { data, loading, error }] = useCancelMiscExpenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCancelMiscExpenseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelMiscExpenseMutation, CancelMiscExpenseMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelMiscExpenseMutation, CancelMiscExpenseMutationVariables>(CancelMiscExpenseDocument, baseOptions);
      }
export type CancelMiscExpenseMutationHookResult = ReturnType<typeof useCancelMiscExpenseMutation>;
export type CancelMiscExpenseMutationResult = ApolloReactCommon.MutationResult<CancelMiscExpenseMutation>;
export type CancelMiscExpenseMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelMiscExpenseMutation, CancelMiscExpenseMutationVariables>;
export const GetSubcontractDocument = gql`
    query getSubcontract($ID: String, $subcontractorID: String, $contractID: String, $subcontractStatus: ContractStatus) {
  getSubcontract(ID: $ID, subcontractorID: $subcontractorID, contractID: $contractID, subcontractStatus: $subcontractStatus) {
    ID
    title
    accountID
    contractID
    subcontractorID
    costCategoryID
    subcontractNo
    title
    description
    subcontractDate
    taxSchemeID
    subcontractSum
    revisedSubcontractSum
    startDate
    endDate
    progressRetentionPerc
    maxRetentionPerc
    maxRetentionSum
    revisedSubcontractSum
    targetProfitPerc
    cmgdDate
    cpcDate
    dlpDate
    latestRunNum
    docNumChecking
    ladAmt
    ladRate
    creditTerm
    subcontractStatus
    contactPerson
    site {
      ID
      name
      contractID
      address
    }
    subcontractorDetail {
      name
      ID
      address
      contactNo
    }
    mainContractDetail {
      title
      ID
    }
    extensionDate
    closureRemarks
    closureDate
    modBy
    VO {
      submissionAmt
      approvedAmt
    }
    taxDetail {
      ID
      code
      description
      taxCategory
      taxTypeID
      taxClass
      createdTs
      commonStatus
      latestTax {
        taxRate
        taxDate
      }
      taxEffective {
        date
        taxRate
        createdTs
      }
    }
    tradeDetail {
      ID
      name
    }
    nominatedSC
  }
}
    `;

/**
 * __useGetSubcontractQuery__
 *
 * To run a query within a React component, call `useGetSubcontractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      subcontractorID: // value for 'subcontractorID'
 *      contractID: // value for 'contractID'
 *      subcontractStatus: // value for 'subcontractStatus'
 *   },
 * });
 */
export function useGetSubcontractQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractQuery, GetSubcontractQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractQuery, GetSubcontractQueryVariables>(GetSubcontractDocument, baseOptions);
      }
export function useGetSubcontractLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractQuery, GetSubcontractQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractQuery, GetSubcontractQueryVariables>(GetSubcontractDocument, baseOptions);
        }
export type GetSubcontractQueryHookResult = ReturnType<typeof useGetSubcontractQuery>;
export type GetSubcontractLazyQueryHookResult = ReturnType<typeof useGetSubcontractLazyQuery>;
export type GetSubcontractQueryResult = ApolloReactCommon.QueryResult<GetSubcontractQuery, GetSubcontractQueryVariables>;
export const CreateSubcontractDocument = gql`
    mutation createSubcontract($input: SubcontractInput!) {
  createSubcontract(input: $input) {
    ID
    title
  }
}
    `;
export type CreateSubcontractMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractMutation, CreateSubcontractMutationVariables>;

/**
 * __useCreateSubcontractMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractMutation, { data, loading, error }] = useCreateSubcontractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubcontractMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractMutation, CreateSubcontractMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractMutation, CreateSubcontractMutationVariables>(CreateSubcontractDocument, baseOptions);
      }
export type CreateSubcontractMutationHookResult = ReturnType<typeof useCreateSubcontractMutation>;
export type CreateSubcontractMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractMutation>;
export type CreateSubcontractMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractMutation, CreateSubcontractMutationVariables>;
export const UpdateSubcontractDocument = gql`
    mutation updateSubcontract($input: SubcontractInput!) {
  updateSubcontract(input: $input)
}
    `;
export type UpdateSubcontractMutationFn = ApolloReactCommon.MutationFunction<UpdateSubcontractMutation, UpdateSubcontractMutationVariables>;

/**
 * __useUpdateSubcontractMutation__
 *
 * To run a mutation, you first call `useUpdateSubcontractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubcontractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubcontractMutation, { data, loading, error }] = useUpdateSubcontractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubcontractMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubcontractMutation, UpdateSubcontractMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubcontractMutation, UpdateSubcontractMutationVariables>(UpdateSubcontractDocument, baseOptions);
      }
export type UpdateSubcontractMutationHookResult = ReturnType<typeof useUpdateSubcontractMutation>;
export type UpdateSubcontractMutationResult = ApolloReactCommon.MutationResult<UpdateSubcontractMutation>;
export type UpdateSubcontractMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubcontractMutation, UpdateSubcontractMutationVariables>;
export const GetSubcontractGuaranteeDocument = gql`
    query getSubcontractGuarantee($ID: String, $subcontractID: String!) {
  getSubcontractGuarantee(ID: $ID, subcontractID: $subcontractID) {
    ID
    modBy
    accountID
    subcontractID
    docRef
    guaranteeTypeID
    description
    docAmt
    startDate
    expiryDate
    issuer
    collateralAmt
    remarks
    cancellationDate
    guaranteeStatus
    guaranteeType {
      name
    }
  }
  subconGuaranteeInfo(subcontractID: $subcontractID)
}
    `;

/**
 * __useGetSubcontractGuaranteeQuery__
 *
 * To run a query within a React component, call `useGetSubcontractGuaranteeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractGuaranteeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractGuaranteeQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useGetSubcontractGuaranteeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractGuaranteeQuery, GetSubcontractGuaranteeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractGuaranteeQuery, GetSubcontractGuaranteeQueryVariables>(GetSubcontractGuaranteeDocument, baseOptions);
      }
export function useGetSubcontractGuaranteeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractGuaranteeQuery, GetSubcontractGuaranteeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractGuaranteeQuery, GetSubcontractGuaranteeQueryVariables>(GetSubcontractGuaranteeDocument, baseOptions);
        }
export type GetSubcontractGuaranteeQueryHookResult = ReturnType<typeof useGetSubcontractGuaranteeQuery>;
export type GetSubcontractGuaranteeLazyQueryHookResult = ReturnType<typeof useGetSubcontractGuaranteeLazyQuery>;
export type GetSubcontractGuaranteeQueryResult = ApolloReactCommon.QueryResult<GetSubcontractGuaranteeQuery, GetSubcontractGuaranteeQueryVariables>;
export const CreateSubcontractGuaranteeDocument = gql`
    mutation createSubcontractGuarantee($input: SubcontractGuaranteeInput!) {
  createSubcontractGuarantee(input: $input) {
    ID
  }
}
    `;
export type CreateSubcontractGuaranteeMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractGuaranteeMutation, CreateSubcontractGuaranteeMutationVariables>;

/**
 * __useCreateSubcontractGuaranteeMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractGuaranteeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractGuaranteeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractGuaranteeMutation, { data, loading, error }] = useCreateSubcontractGuaranteeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubcontractGuaranteeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractGuaranteeMutation, CreateSubcontractGuaranteeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractGuaranteeMutation, CreateSubcontractGuaranteeMutationVariables>(CreateSubcontractGuaranteeDocument, baseOptions);
      }
export type CreateSubcontractGuaranteeMutationHookResult = ReturnType<typeof useCreateSubcontractGuaranteeMutation>;
export type CreateSubcontractGuaranteeMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractGuaranteeMutation>;
export type CreateSubcontractGuaranteeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractGuaranteeMutation, CreateSubcontractGuaranteeMutationVariables>;
export const UpdateSubcontractGuaranteeDocument = gql`
    mutation updateSubcontractGuarantee($input: SubcontractGuaranteeInput!) {
  updateSubcontractGuarantee(input: $input) {
    ID
  }
}
    `;
export type UpdateSubcontractGuaranteeMutationFn = ApolloReactCommon.MutationFunction<UpdateSubcontractGuaranteeMutation, UpdateSubcontractGuaranteeMutationVariables>;

/**
 * __useUpdateSubcontractGuaranteeMutation__
 *
 * To run a mutation, you first call `useUpdateSubcontractGuaranteeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubcontractGuaranteeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubcontractGuaranteeMutation, { data, loading, error }] = useUpdateSubcontractGuaranteeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubcontractGuaranteeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubcontractGuaranteeMutation, UpdateSubcontractGuaranteeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubcontractGuaranteeMutation, UpdateSubcontractGuaranteeMutationVariables>(UpdateSubcontractGuaranteeDocument, baseOptions);
      }
export type UpdateSubcontractGuaranteeMutationHookResult = ReturnType<typeof useUpdateSubcontractGuaranteeMutation>;
export type UpdateSubcontractGuaranteeMutationResult = ApolloReactCommon.MutationResult<UpdateSubcontractGuaranteeMutation>;
export type UpdateSubcontractGuaranteeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubcontractGuaranteeMutation, UpdateSubcontractGuaranteeMutationVariables>;
export const GetSubcontractInsuranceDocument = gql`
    query getSubcontractInsurance($ID: String, $subcontractID: String!) {
  getSubcontractInsurance(ID: $ID, subcontractID: $subcontractID) {
    ID
    modBy
    accountID
    subcontractID
    docRef
    insuranceTypeID
    description
    docAmt
    startDate
    expiryDate
    issuer
    remarks
    cancellationDate
    insuranceStatus
    insuranceType {
      name
    }
  }
  subconInsuranceInfo(subcontractID: $subcontractID)
}
    `;

/**
 * __useGetSubcontractInsuranceQuery__
 *
 * To run a query within a React component, call `useGetSubcontractInsuranceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractInsuranceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractInsuranceQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useGetSubcontractInsuranceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractInsuranceQuery, GetSubcontractInsuranceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractInsuranceQuery, GetSubcontractInsuranceQueryVariables>(GetSubcontractInsuranceDocument, baseOptions);
      }
export function useGetSubcontractInsuranceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractInsuranceQuery, GetSubcontractInsuranceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractInsuranceQuery, GetSubcontractInsuranceQueryVariables>(GetSubcontractInsuranceDocument, baseOptions);
        }
export type GetSubcontractInsuranceQueryHookResult = ReturnType<typeof useGetSubcontractInsuranceQuery>;
export type GetSubcontractInsuranceLazyQueryHookResult = ReturnType<typeof useGetSubcontractInsuranceLazyQuery>;
export type GetSubcontractInsuranceQueryResult = ApolloReactCommon.QueryResult<GetSubcontractInsuranceQuery, GetSubcontractInsuranceQueryVariables>;
export const CreateSubcontractInsuranceDocument = gql`
    mutation createSubcontractInsurance($input: SubcontractInsuranceInput!) {
  createSubcontractInsurance(input: $input) {
    ID
  }
}
    `;
export type CreateSubcontractInsuranceMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractInsuranceMutation, CreateSubcontractInsuranceMutationVariables>;

/**
 * __useCreateSubcontractInsuranceMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractInsuranceMutation, { data, loading, error }] = useCreateSubcontractInsuranceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubcontractInsuranceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractInsuranceMutation, CreateSubcontractInsuranceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractInsuranceMutation, CreateSubcontractInsuranceMutationVariables>(CreateSubcontractInsuranceDocument, baseOptions);
      }
export type CreateSubcontractInsuranceMutationHookResult = ReturnType<typeof useCreateSubcontractInsuranceMutation>;
export type CreateSubcontractInsuranceMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractInsuranceMutation>;
export type CreateSubcontractInsuranceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractInsuranceMutation, CreateSubcontractInsuranceMutationVariables>;
export const UpdateSubcontractInsuranceDocument = gql`
    mutation updateSubcontractInsurance($input: SubcontractInsuranceInput!) {
  updateSubcontractInsurance(input: $input) {
    ID
  }
}
    `;
export type UpdateSubcontractInsuranceMutationFn = ApolloReactCommon.MutationFunction<UpdateSubcontractInsuranceMutation, UpdateSubcontractInsuranceMutationVariables>;

/**
 * __useUpdateSubcontractInsuranceMutation__
 *
 * To run a mutation, you first call `useUpdateSubcontractInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubcontractInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubcontractInsuranceMutation, { data, loading, error }] = useUpdateSubcontractInsuranceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubcontractInsuranceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubcontractInsuranceMutation, UpdateSubcontractInsuranceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubcontractInsuranceMutation, UpdateSubcontractInsuranceMutationVariables>(UpdateSubcontractInsuranceDocument, baseOptions);
      }
export type UpdateSubcontractInsuranceMutationHookResult = ReturnType<typeof useUpdateSubcontractInsuranceMutation>;
export type UpdateSubcontractInsuranceMutationResult = ApolloReactCommon.MutationResult<UpdateSubcontractInsuranceMutation>;
export type UpdateSubcontractInsuranceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubcontractInsuranceMutation, UpdateSubcontractInsuranceMutationVariables>;
export const CancelSubcontractGuaranteeDocument = gql`
    mutation cancelSubcontractGuarantee($input: SubcontractGuaranteeCancellationInput!) {
  cancelSubcontractGuarantee(input: $input)
}
    `;
export type CancelSubcontractGuaranteeMutationFn = ApolloReactCommon.MutationFunction<CancelSubcontractGuaranteeMutation, CancelSubcontractGuaranteeMutationVariables>;

/**
 * __useCancelSubcontractGuaranteeMutation__
 *
 * To run a mutation, you first call `useCancelSubcontractGuaranteeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubcontractGuaranteeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubcontractGuaranteeMutation, { data, loading, error }] = useCancelSubcontractGuaranteeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSubcontractGuaranteeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSubcontractGuaranteeMutation, CancelSubcontractGuaranteeMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSubcontractGuaranteeMutation, CancelSubcontractGuaranteeMutationVariables>(CancelSubcontractGuaranteeDocument, baseOptions);
      }
export type CancelSubcontractGuaranteeMutationHookResult = ReturnType<typeof useCancelSubcontractGuaranteeMutation>;
export type CancelSubcontractGuaranteeMutationResult = ApolloReactCommon.MutationResult<CancelSubcontractGuaranteeMutation>;
export type CancelSubcontractGuaranteeMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSubcontractGuaranteeMutation, CancelSubcontractGuaranteeMutationVariables>;
export const CancelSubcontractInsuranceDocument = gql`
    mutation cancelSubcontractInsurance($input: SubcontractInsuranceCancellationInput!) {
  cancelSubcontractInsurance(input: $input)
}
    `;
export type CancelSubcontractInsuranceMutationFn = ApolloReactCommon.MutationFunction<CancelSubcontractInsuranceMutation, CancelSubcontractInsuranceMutationVariables>;

/**
 * __useCancelSubcontractInsuranceMutation__
 *
 * To run a mutation, you first call `useCancelSubcontractInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubcontractInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubcontractInsuranceMutation, { data, loading, error }] = useCancelSubcontractInsuranceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSubcontractInsuranceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSubcontractInsuranceMutation, CancelSubcontractInsuranceMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSubcontractInsuranceMutation, CancelSubcontractInsuranceMutationVariables>(CancelSubcontractInsuranceDocument, baseOptions);
      }
export type CancelSubcontractInsuranceMutationHookResult = ReturnType<typeof useCancelSubcontractInsuranceMutation>;
export type CancelSubcontractInsuranceMutationResult = ApolloReactCommon.MutationResult<CancelSubcontractInsuranceMutation>;
export type CancelSubcontractInsuranceMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSubcontractInsuranceMutation, CancelSubcontractInsuranceMutationVariables>;
export const GetSubcontractSummaryDocument = gql`
    query getSubcontractSummary($subcontractID: String!) {
  subcontractInfo(subcontractID: $subcontractID) {
    subcontractSum
    subcontractDate
  }
  subconGuaranteeInfo(subcontractID: $subcontractID)
  subconInsuranceInfo(subcontractID: $subcontractID)
  submittedVOSubconNotification(subcontractID: $subcontractID)
  subcontractVO(subcontractID: $subcontractID) {
    voSum
    voOmission
    voSumCount
    voOmissionCount
    totalVoAmt
  }
  subconOutstandingAdvances(subcontractID: $subcontractID) {
    docNo
    docDate
    docAmt
    baseAmt
    outstandingAmt
  }
  subconOutstandingClaims(subcontractID: $subcontractID) {
    docNo
    docDate
    docAmt
    baseAmt
    outstandingAmt
  }
  subconLastExtensionDate(subcontractID: $subcontractID)
  subconClosureDate(subcontractID: $subcontractID)
  retention(subcontractID: $subcontractID) {
    retentionSum
    retentionBalance
    releasedSum
  }
}
    `;

/**
 * __useGetSubcontractSummaryQuery__
 *
 * To run a query within a React component, call `useGetSubcontractSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractSummaryQuery({
 *   variables: {
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useGetSubcontractSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractSummaryQuery, GetSubcontractSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractSummaryQuery, GetSubcontractSummaryQueryVariables>(GetSubcontractSummaryDocument, baseOptions);
      }
export function useGetSubcontractSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractSummaryQuery, GetSubcontractSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractSummaryQuery, GetSubcontractSummaryQueryVariables>(GetSubcontractSummaryDocument, baseOptions);
        }
export type GetSubcontractSummaryQueryHookResult = ReturnType<typeof useGetSubcontractSummaryQuery>;
export type GetSubcontractSummaryLazyQueryHookResult = ReturnType<typeof useGetSubcontractSummaryLazyQuery>;
export type GetSubcontractSummaryQueryResult = ApolloReactCommon.QueryResult<GetSubcontractSummaryQuery, GetSubcontractSummaryQueryVariables>;
export const GetIpcSummaryDocument = gql`
    query getIPCSummary($subcontractID: String!) {
  retention(subcontractID: $subcontractID) {
    retentionSum
    retentionBalance
    releasedSum
  }
}
    `;

/**
 * __useGetIpcSummaryQuery__
 *
 * To run a query within a React component, call `useGetIpcSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIpcSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIpcSummaryQuery({
 *   variables: {
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useGetIpcSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetIpcSummaryQuery, GetIpcSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetIpcSummaryQuery, GetIpcSummaryQueryVariables>(GetIpcSummaryDocument, baseOptions);
      }
export function useGetIpcSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetIpcSummaryQuery, GetIpcSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetIpcSummaryQuery, GetIpcSummaryQueryVariables>(GetIpcSummaryDocument, baseOptions);
        }
export type GetIpcSummaryQueryHookResult = ReturnType<typeof useGetIpcSummaryQuery>;
export type GetIpcSummaryLazyQueryHookResult = ReturnType<typeof useGetIpcSummaryLazyQuery>;
export type GetIpcSummaryQueryResult = ApolloReactCommon.QueryResult<GetIpcSummaryQuery, GetIpcSummaryQueryVariables>;
export const ActionOnSubcontractStatusDocument = gql`
    mutation actionOnSubcontractStatus($input: SubcontractActionInput!, $ID: String!) {
  actionOnSubcontractStatus(input: $input, ID: $ID)
}
    `;
export type ActionOnSubcontractStatusMutationFn = ApolloReactCommon.MutationFunction<ActionOnSubcontractStatusMutation, ActionOnSubcontractStatusMutationVariables>;

/**
 * __useActionOnSubcontractStatusMutation__
 *
 * To run a mutation, you first call `useActionOnSubcontractStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionOnSubcontractStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionOnSubcontractStatusMutation, { data, loading, error }] = useActionOnSubcontractStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useActionOnSubcontractStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActionOnSubcontractStatusMutation, ActionOnSubcontractStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ActionOnSubcontractStatusMutation, ActionOnSubcontractStatusMutationVariables>(ActionOnSubcontractStatusDocument, baseOptions);
      }
export type ActionOnSubcontractStatusMutationHookResult = ReturnType<typeof useActionOnSubcontractStatusMutation>;
export type ActionOnSubcontractStatusMutationResult = ApolloReactCommon.MutationResult<ActionOnSubcontractStatusMutation>;
export type ActionOnSubcontractStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ActionOnSubcontractStatusMutation, ActionOnSubcontractStatusMutationVariables>;
export const CreateSubcontractExtensionDateDocument = gql`
    mutation createSubcontractExtensionDate($input: SubcontractExtensionInput!, $ID: String!) {
  createSubcontractExtensionDate(input: $input, ID: $ID)
}
    `;
export type CreateSubcontractExtensionDateMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractExtensionDateMutation, CreateSubcontractExtensionDateMutationVariables>;

/**
 * __useCreateSubcontractExtensionDateMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractExtensionDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractExtensionDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractExtensionDateMutation, { data, loading, error }] = useCreateSubcontractExtensionDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCreateSubcontractExtensionDateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractExtensionDateMutation, CreateSubcontractExtensionDateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractExtensionDateMutation, CreateSubcontractExtensionDateMutationVariables>(CreateSubcontractExtensionDateDocument, baseOptions);
      }
export type CreateSubcontractExtensionDateMutationHookResult = ReturnType<typeof useCreateSubcontractExtensionDateMutation>;
export type CreateSubcontractExtensionDateMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractExtensionDateMutation>;
export type CreateSubcontractExtensionDateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractExtensionDateMutation, CreateSubcontractExtensionDateMutationVariables>;
export const CreateSubcontractCertificateDocument = gql`
    mutation createSubcontractCertificate($input: SubcontractCertificateInput!, $ID: String!) {
  createSubcontractCertificate(input: $input, ID: $ID)
}
    `;
export type CreateSubcontractCertificateMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractCertificateMutation, CreateSubcontractCertificateMutationVariables>;

/**
 * __useCreateSubcontractCertificateMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractCertificateMutation, { data, loading, error }] = useCreateSubcontractCertificateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCreateSubcontractCertificateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractCertificateMutation, CreateSubcontractCertificateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractCertificateMutation, CreateSubcontractCertificateMutationVariables>(CreateSubcontractCertificateDocument, baseOptions);
      }
export type CreateSubcontractCertificateMutationHookResult = ReturnType<typeof useCreateSubcontractCertificateMutation>;
export type CreateSubcontractCertificateMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractCertificateMutation>;
export type CreateSubcontractCertificateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractCertificateMutation, CreateSubcontractCertificateMutationVariables>;
export const GetSubcontractExtensionDocument = gql`
    query getSubcontractExtension($ID: String!, $orderByAsc: String, $orderByDesc: String) {
  getSubcontract(ID: $ID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    ID
    title
    subcontractNo
    description
    subcontractDate
    subcontractSum
    taxSchemeID
    startDate
    endDate
    subcontractStatus
    mainContractDetail {
      title
      ID
    }
    extensionDate
  }
  subconLastExtensionDate(subcontractID: $ID)
}
    `;

/**
 * __useGetSubcontractExtensionQuery__
 *
 * To run a query within a React component, call `useGetSubcontractExtensionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractExtensionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractExtensionQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetSubcontractExtensionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractExtensionQuery, GetSubcontractExtensionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractExtensionQuery, GetSubcontractExtensionQueryVariables>(GetSubcontractExtensionDocument, baseOptions);
      }
export function useGetSubcontractExtensionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractExtensionQuery, GetSubcontractExtensionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractExtensionQuery, GetSubcontractExtensionQueryVariables>(GetSubcontractExtensionDocument, baseOptions);
        }
export type GetSubcontractExtensionQueryHookResult = ReturnType<typeof useGetSubcontractExtensionQuery>;
export type GetSubcontractExtensionLazyQueryHookResult = ReturnType<typeof useGetSubcontractExtensionLazyQuery>;
export type GetSubcontractExtensionQueryResult = ApolloReactCommon.QueryResult<GetSubcontractExtensionQuery, GetSubcontractExtensionQueryVariables>;
export const GetSubcontractTitleDocument = gql`
    query getSubcontractTitle($ID: String, $subcontractorID: String, $contractID: String) {
  getSubcontract(ID: $ID, subcontractorID: $subcontractorID, contractID: $contractID) {
    ID
    title
  }
}
    `;

/**
 * __useGetSubcontractTitleQuery__
 *
 * To run a query within a React component, call `useGetSubcontractTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractTitleQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      subcontractorID: // value for 'subcontractorID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetSubcontractTitleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractTitleQuery, GetSubcontractTitleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractTitleQuery, GetSubcontractTitleQueryVariables>(GetSubcontractTitleDocument, baseOptions);
      }
export function useGetSubcontractTitleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractTitleQuery, GetSubcontractTitleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractTitleQuery, GetSubcontractTitleQueryVariables>(GetSubcontractTitleDocument, baseOptions);
        }
export type GetSubcontractTitleQueryHookResult = ReturnType<typeof useGetSubcontractTitleQuery>;
export type GetSubcontractTitleLazyQueryHookResult = ReturnType<typeof useGetSubcontractTitleLazyQuery>;
export type GetSubcontractTitleQueryResult = ApolloReactCommon.QueryResult<GetSubcontractTitleQuery, GetSubcontractTitleQueryVariables>;
export const GetSubcontractRelatedDocument = gql`
    query getSubcontractRelated($subcontractID: String) {
  getSubcontractRelated(subcontractID: $subcontractID) {
    ID
    title
  }
}
    `;

/**
 * __useGetSubcontractRelatedQuery__
 *
 * To run a query within a React component, call `useGetSubcontractRelatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractRelatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractRelatedQuery({
 *   variables: {
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useGetSubcontractRelatedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractRelatedQuery, GetSubcontractRelatedQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractRelatedQuery, GetSubcontractRelatedQueryVariables>(GetSubcontractRelatedDocument, baseOptions);
      }
export function useGetSubcontractRelatedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractRelatedQuery, GetSubcontractRelatedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractRelatedQuery, GetSubcontractRelatedQueryVariables>(GetSubcontractRelatedDocument, baseOptions);
        }
export type GetSubcontractRelatedQueryHookResult = ReturnType<typeof useGetSubcontractRelatedQuery>;
export type GetSubcontractRelatedLazyQueryHookResult = ReturnType<typeof useGetSubcontractRelatedLazyQuery>;
export type GetSubcontractRelatedQueryResult = ApolloReactCommon.QueryResult<GetSubcontractRelatedQuery, GetSubcontractRelatedQueryVariables>;
export const SubconBudgetCheckingDocument = gql`
    query subconBudgetChecking($amount: Float!, $contractID: String!, $costCategoryID: String!) {
  subconBudgetChecking(amount: $amount, contractID: $contractID, costCategoryID: $costCategoryID) {
    isExceeded
    isAllocated
  }
}
    `;

/**
 * __useSubconBudgetCheckingQuery__
 *
 * To run a query within a React component, call `useSubconBudgetCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubconBudgetCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubconBudgetCheckingQuery({
 *   variables: {
 *      amount: // value for 'amount'
 *      contractID: // value for 'contractID'
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useSubconBudgetCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SubconBudgetCheckingQuery, SubconBudgetCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<SubconBudgetCheckingQuery, SubconBudgetCheckingQueryVariables>(SubconBudgetCheckingDocument, baseOptions);
      }
export function useSubconBudgetCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubconBudgetCheckingQuery, SubconBudgetCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SubconBudgetCheckingQuery, SubconBudgetCheckingQueryVariables>(SubconBudgetCheckingDocument, baseOptions);
        }
export type SubconBudgetCheckingQueryHookResult = ReturnType<typeof useSubconBudgetCheckingQuery>;
export type SubconBudgetCheckingLazyQueryHookResult = ReturnType<typeof useSubconBudgetCheckingLazyQuery>;
export type SubconBudgetCheckingQueryResult = ApolloReactCommon.QueryResult<SubconBudgetCheckingQuery, SubconBudgetCheckingQueryVariables>;
export const GetContractVoDocument = gql`
    query getContractVO($ID: String, $contractID: String, $orderByAsc: String) {
  getContractVO(ID: $ID, contractID: $contractID, orderByAsc: $orderByAsc) {
    ID
    accountID
    contractID
    docNo
    docRef
    startDate
    endDate
    description
    architectNo
    engineeringNo
    VOType
    VOStatus
    submissionAmt
    approvedAmt
    submittedDate
    rejectedDate
    docDate
    remarks
    contractVORevenueWbs {
      ID
      previousRevenueVOAmt
      contractVOID
      wbsID
      revenueCategoryID
      submissionAmt
      approvedAmt
      wbsBudgetDetail {
        ID
        wbsID
        budgetAmt
        revenueCategoryID
        revenueCategory {
          ID
          name
        }
      }
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
}
    `;

/**
 * __useGetContractVoQuery__
 *
 * To run a query within a React component, call `useGetContractVoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractVoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractVoQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetContractVoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractVoQuery, GetContractVoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractVoQuery, GetContractVoQueryVariables>(GetContractVoDocument, baseOptions);
      }
export function useGetContractVoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractVoQuery, GetContractVoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractVoQuery, GetContractVoQueryVariables>(GetContractVoDocument, baseOptions);
        }
export type GetContractVoQueryHookResult = ReturnType<typeof useGetContractVoQuery>;
export type GetContractVoLazyQueryHookResult = ReturnType<typeof useGetContractVoLazyQuery>;
export type GetContractVoQueryResult = ApolloReactCommon.QueryResult<GetContractVoQuery, GetContractVoQueryVariables>;
export const CreateContractVoDocument = gql`
    mutation createContractVO($input: ContractVOInput!, $voDetails: [ContractVODetailsInput!]) {
  createContractVO(input: $input, voDetails: $voDetails) {
    ID
  }
}
    `;
export type CreateContractVoMutationFn = ApolloReactCommon.MutationFunction<CreateContractVoMutation, CreateContractVoMutationVariables>;

/**
 * __useCreateContractVoMutation__
 *
 * To run a mutation, you first call `useCreateContractVoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractVoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractVoMutation, { data, loading, error }] = useCreateContractVoMutation({
 *   variables: {
 *      input: // value for 'input'
 *      voDetails: // value for 'voDetails'
 *   },
 * });
 */
export function useCreateContractVoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractVoMutation, CreateContractVoMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractVoMutation, CreateContractVoMutationVariables>(CreateContractVoDocument, baseOptions);
      }
export type CreateContractVoMutationHookResult = ReturnType<typeof useCreateContractVoMutation>;
export type CreateContractVoMutationResult = ApolloReactCommon.MutationResult<CreateContractVoMutation>;
export type CreateContractVoMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractVoMutation, CreateContractVoMutationVariables>;
export const UpdateContractVoDocument = gql`
    mutation updateContractVO($input: ContractVOInput!, $voDetails: [ContractVODetailsInput!]) {
  updateContractVO(input: $input, voDetails: $voDetails) {
    ID
  }
}
    `;
export type UpdateContractVoMutationFn = ApolloReactCommon.MutationFunction<UpdateContractVoMutation, UpdateContractVoMutationVariables>;

/**
 * __useUpdateContractVoMutation__
 *
 * To run a mutation, you first call `useUpdateContractVoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractVoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractVoMutation, { data, loading, error }] = useUpdateContractVoMutation({
 *   variables: {
 *      input: // value for 'input'
 *      voDetails: // value for 'voDetails'
 *   },
 * });
 */
export function useUpdateContractVoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContractVoMutation, UpdateContractVoMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContractVoMutation, UpdateContractVoMutationVariables>(UpdateContractVoDocument, baseOptions);
      }
export type UpdateContractVoMutationHookResult = ReturnType<typeof useUpdateContractVoMutation>;
export type UpdateContractVoMutationResult = ApolloReactCommon.MutationResult<UpdateContractVoMutation>;
export type UpdateContractVoMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContractVoMutation, UpdateContractVoMutationVariables>;
export const ActionOnVoStatusDocument = gql`
    mutation actionOnVOStatus($input: ContractVOActionInput!, $ID: String!, $itemApprovedAmount: [VOItemApprovedAmountInput!]) {
  actionOnVOStatus(input: $input, ID: $ID, itemApprovedAmount: $itemApprovedAmount)
}
    `;
export type ActionOnVoStatusMutationFn = ApolloReactCommon.MutationFunction<ActionOnVoStatusMutation, ActionOnVoStatusMutationVariables>;

/**
 * __useActionOnVoStatusMutation__
 *
 * To run a mutation, you first call `useActionOnVoStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionOnVoStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionOnVoStatusMutation, { data, loading, error }] = useActionOnVoStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *      itemApprovedAmount: // value for 'itemApprovedAmount'
 *   },
 * });
 */
export function useActionOnVoStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActionOnVoStatusMutation, ActionOnVoStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ActionOnVoStatusMutation, ActionOnVoStatusMutationVariables>(ActionOnVoStatusDocument, baseOptions);
      }
export type ActionOnVoStatusMutationHookResult = ReturnType<typeof useActionOnVoStatusMutation>;
export type ActionOnVoStatusMutationResult = ApolloReactCommon.MutationResult<ActionOnVoStatusMutation>;
export type ActionOnVoStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ActionOnVoStatusMutation, ActionOnVoStatusMutationVariables>;
export const GetContractVoSummaryDocument = gql`
    query getContractVOSummary($contractID: String!) {
  contractVO(contractID: $contractID) {
    voSum
    voOmission
    voSumCount
    voOmissionCount
    totalVoAmt
  }
}
    `;

/**
 * __useGetContractVoSummaryQuery__
 *
 * To run a query within a React component, call `useGetContractVoSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractVoSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractVoSummaryQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetContractVoSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractVoSummaryQuery, GetContractVoSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractVoSummaryQuery, GetContractVoSummaryQueryVariables>(GetContractVoSummaryDocument, baseOptions);
      }
export function useGetContractVoSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractVoSummaryQuery, GetContractVoSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractVoSummaryQuery, GetContractVoSummaryQueryVariables>(GetContractVoSummaryDocument, baseOptions);
        }
export type GetContractVoSummaryQueryHookResult = ReturnType<typeof useGetContractVoSummaryQuery>;
export type GetContractVoSummaryLazyQueryHookResult = ReturnType<typeof useGetContractVoSummaryLazyQuery>;
export type GetContractVoSummaryQueryResult = ApolloReactCommon.QueryResult<GetContractVoSummaryQuery, GetContractVoSummaryQueryVariables>;
export const GetSubcontractVoDocument = gql`
    query getSubcontractVO($ID: String, $subcontractID: String, $orderByAsc: String) {
  getSubcontractVO(ID: $ID, subcontractID: $subcontractID, orderByAsc: $orderByAsc) {
    ID
    accountID
    subcontractID
    docNo
    docRef
    startDate
    endDate
    description
    architectNo
    engineeringNo
    VOType
    VOStatus
    submissionAmt
    approvedAmt
    remarks
    submittedDate
    rejectedDate
    docDate
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
}
    `;

/**
 * __useGetSubcontractVoQuery__
 *
 * To run a query within a React component, call `useGetSubcontractVoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractVoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractVoQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      subcontractID: // value for 'subcontractID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetSubcontractVoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractVoQuery, GetSubcontractVoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractVoQuery, GetSubcontractVoQueryVariables>(GetSubcontractVoDocument, baseOptions);
      }
export function useGetSubcontractVoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractVoQuery, GetSubcontractVoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractVoQuery, GetSubcontractVoQueryVariables>(GetSubcontractVoDocument, baseOptions);
        }
export type GetSubcontractVoQueryHookResult = ReturnType<typeof useGetSubcontractVoQuery>;
export type GetSubcontractVoLazyQueryHookResult = ReturnType<typeof useGetSubcontractVoLazyQuery>;
export type GetSubcontractVoQueryResult = ApolloReactCommon.QueryResult<GetSubcontractVoQuery, GetSubcontractVoQueryVariables>;
export const ActionOnSubconVoStatusDocument = gql`
    mutation actionOnSubconVOStatus($input: SubcontractVOActionInput!, $ID: String!) {
  actionOnSubconVOStatus(input: $input, ID: $ID)
}
    `;
export type ActionOnSubconVoStatusMutationFn = ApolloReactCommon.MutationFunction<ActionOnSubconVoStatusMutation, ActionOnSubconVoStatusMutationVariables>;

/**
 * __useActionOnSubconVoStatusMutation__
 *
 * To run a mutation, you first call `useActionOnSubconVoStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionOnSubconVoStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionOnSubconVoStatusMutation, { data, loading, error }] = useActionOnSubconVoStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useActionOnSubconVoStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActionOnSubconVoStatusMutation, ActionOnSubconVoStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ActionOnSubconVoStatusMutation, ActionOnSubconVoStatusMutationVariables>(ActionOnSubconVoStatusDocument, baseOptions);
      }
export type ActionOnSubconVoStatusMutationHookResult = ReturnType<typeof useActionOnSubconVoStatusMutation>;
export type ActionOnSubconVoStatusMutationResult = ApolloReactCommon.MutationResult<ActionOnSubconVoStatusMutation>;
export type ActionOnSubconVoStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ActionOnSubconVoStatusMutation, ActionOnSubconVoStatusMutationVariables>;
export const GetSubcontractVoSummaryDocument = gql`
    query getSubcontractVOSummary($subcontractID: String!) {
  subcontractVO(subcontractID: $subcontractID) {
    voSum
    voOmission
    voSumCount
    voOmissionCount
    totalVoAmt
  }
}
    `;

/**
 * __useGetSubcontractVoSummaryQuery__
 *
 * To run a query within a React component, call `useGetSubcontractVoSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractVoSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractVoSummaryQuery({
 *   variables: {
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useGetSubcontractVoSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractVoSummaryQuery, GetSubcontractVoSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractVoSummaryQuery, GetSubcontractVoSummaryQueryVariables>(GetSubcontractVoSummaryDocument, baseOptions);
      }
export function useGetSubcontractVoSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractVoSummaryQuery, GetSubcontractVoSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractVoSummaryQuery, GetSubcontractVoSummaryQueryVariables>(GetSubcontractVoSummaryDocument, baseOptions);
        }
export type GetSubcontractVoSummaryQueryHookResult = ReturnType<typeof useGetSubcontractVoSummaryQuery>;
export type GetSubcontractVoSummaryLazyQueryHookResult = ReturnType<typeof useGetSubcontractVoSummaryLazyQuery>;
export type GetSubcontractVoSummaryQueryResult = ApolloReactCommon.QueryResult<GetSubcontractVoSummaryQuery, GetSubcontractVoSummaryQueryVariables>;
export const GetClientVoDocument = gql`
    query getClientVo($contractID: String!, $voStatus: VOStatus!) {
  getContractVO(contractID: $contractID, VOStatus: $voStatus) {
    approvedAmt
    submissionAmt
    VOType
    docNo
    docRef
    submittedDate
    docDate
    description
  }
}
    `;

/**
 * __useGetClientVoQuery__
 *
 * To run a query within a React component, call `useGetClientVoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientVoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientVoQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      voStatus: // value for 'voStatus'
 *   },
 * });
 */
export function useGetClientVoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClientVoQuery, GetClientVoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClientVoQuery, GetClientVoQueryVariables>(GetClientVoDocument, baseOptions);
      }
export function useGetClientVoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientVoQuery, GetClientVoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClientVoQuery, GetClientVoQueryVariables>(GetClientVoDocument, baseOptions);
        }
export type GetClientVoQueryHookResult = ReturnType<typeof useGetClientVoQuery>;
export type GetClientVoLazyQueryHookResult = ReturnType<typeof useGetClientVoLazyQuery>;
export type GetClientVoQueryResult = ApolloReactCommon.QueryResult<GetClientVoQuery, GetClientVoQueryVariables>;
export const GetSubconVoDocument = gql`
    query getSubconVo($subcontractID: String!, $voStatus: VOStatus!) {
  getSubcontractVO(subcontractID: $subcontractID, VOStatus: $voStatus) {
    approvedAmt
    submissionAmt
    VOType
    docNo
    docRef
    submittedDate
    docDate
    description
  }
}
    `;

/**
 * __useGetSubconVoQuery__
 *
 * To run a query within a React component, call `useGetSubconVoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubconVoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubconVoQuery({
 *   variables: {
 *      subcontractID: // value for 'subcontractID'
 *      voStatus: // value for 'voStatus'
 *   },
 * });
 */
export function useGetSubconVoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubconVoQuery, GetSubconVoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubconVoQuery, GetSubconVoQueryVariables>(GetSubconVoDocument, baseOptions);
      }
export function useGetSubconVoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubconVoQuery, GetSubconVoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubconVoQuery, GetSubconVoQueryVariables>(GetSubconVoDocument, baseOptions);
        }
export type GetSubconVoQueryHookResult = ReturnType<typeof useGetSubconVoQuery>;
export type GetSubconVoLazyQueryHookResult = ReturnType<typeof useGetSubconVoLazyQuery>;
export type GetSubconVoQueryResult = ApolloReactCommon.QueryResult<GetSubconVoQuery, GetSubconVoQueryVariables>;
export const DocumentListingDocument = gql`
    query DocumentListing($refID: String, $refTable: String, $description: String, $notDescription: String, $hasDescription: Boolean) {
  DocumentListing(refID: $refID, refTable: $refTable, description: $description, notDescription: $notDescription, hasDescription: $hasDescription) {
    ID
    fileName
    fileURL
    mediaType
    createdTs
    description
    refID
  }
}
    `;

/**
 * __useDocumentListingQuery__
 *
 * To run a query within a React component, call `useDocumentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentListingQuery({
 *   variables: {
 *      refID: // value for 'refID'
 *      refTable: // value for 'refTable'
 *      description: // value for 'description'
 *      notDescription: // value for 'notDescription'
 *      hasDescription: // value for 'hasDescription'
 *   },
 * });
 */
export function useDocumentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DocumentListingQuery, DocumentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<DocumentListingQuery, DocumentListingQueryVariables>(DocumentListingDocument, baseOptions);
      }
export function useDocumentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocumentListingQuery, DocumentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DocumentListingQuery, DocumentListingQueryVariables>(DocumentListingDocument, baseOptions);
        }
export type DocumentListingQueryHookResult = ReturnType<typeof useDocumentListingQuery>;
export type DocumentListingLazyQueryHookResult = ReturnType<typeof useDocumentListingLazyQuery>;
export type DocumentListingQueryResult = ApolloReactCommon.QueryResult<DocumentListingQuery, DocumentListingQueryVariables>;
export const GetAdjustmentTypeDocument = gql`
    query getAdjustmentType($ID: String) {
  getAdjustmentType(ID: $ID) {
    ID
    code
    description
    glAdjustmentDynamic {
      ID
      accountCode
    }
  }
}
    `;

/**
 * __useGetAdjustmentTypeQuery__
 *
 * To run a query within a React component, call `useGetAdjustmentTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdjustmentTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdjustmentTypeQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetAdjustmentTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAdjustmentTypeQuery, GetAdjustmentTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAdjustmentTypeQuery, GetAdjustmentTypeQueryVariables>(GetAdjustmentTypeDocument, baseOptions);
      }
export function useGetAdjustmentTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAdjustmentTypeQuery, GetAdjustmentTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAdjustmentTypeQuery, GetAdjustmentTypeQueryVariables>(GetAdjustmentTypeDocument, baseOptions);
        }
export type GetAdjustmentTypeQueryHookResult = ReturnType<typeof useGetAdjustmentTypeQuery>;
export type GetAdjustmentTypeLazyQueryHookResult = ReturnType<typeof useGetAdjustmentTypeLazyQuery>;
export type GetAdjustmentTypeQueryResult = ApolloReactCommon.QueryResult<GetAdjustmentTypeQuery, GetAdjustmentTypeQueryVariables>;
export const GetGlAdjustmentTypeDocument = gql`
    query getGLAdjustmentType {
  getGLAdjustmentType
}
    `;

/**
 * __useGetGlAdjustmentTypeQuery__
 *
 * To run a query within a React component, call `useGetGlAdjustmentTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlAdjustmentTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlAdjustmentTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGlAdjustmentTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGlAdjustmentTypeQuery, GetGlAdjustmentTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGlAdjustmentTypeQuery, GetGlAdjustmentTypeQueryVariables>(GetGlAdjustmentTypeDocument, baseOptions);
      }
export function useGetGlAdjustmentTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGlAdjustmentTypeQuery, GetGlAdjustmentTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGlAdjustmentTypeQuery, GetGlAdjustmentTypeQueryVariables>(GetGlAdjustmentTypeDocument, baseOptions);
        }
export type GetGlAdjustmentTypeQueryHookResult = ReturnType<typeof useGetGlAdjustmentTypeQuery>;
export type GetGlAdjustmentTypeLazyQueryHookResult = ReturnType<typeof useGetGlAdjustmentTypeLazyQuery>;
export type GetGlAdjustmentTypeQueryResult = ApolloReactCommon.QueryResult<GetGlAdjustmentTypeQuery, GetGlAdjustmentTypeQueryVariables>;
export const GetGlTaxTypeDocument = gql`
    query getGLTaxType {
  getGLTaxType
}
    `;

/**
 * __useGetGlTaxTypeQuery__
 *
 * To run a query within a React component, call `useGetGlTaxTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlTaxTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlTaxTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGlTaxTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGlTaxTypeQuery, GetGlTaxTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGlTaxTypeQuery, GetGlTaxTypeQueryVariables>(GetGlTaxTypeDocument, baseOptions);
      }
export function useGetGlTaxTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGlTaxTypeQuery, GetGlTaxTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGlTaxTypeQuery, GetGlTaxTypeQueryVariables>(GetGlTaxTypeDocument, baseOptions);
        }
export type GetGlTaxTypeQueryHookResult = ReturnType<typeof useGetGlTaxTypeQuery>;
export type GetGlTaxTypeLazyQueryHookResult = ReturnType<typeof useGetGlTaxTypeLazyQuery>;
export type GetGlTaxTypeQueryResult = ApolloReactCommon.QueryResult<GetGlTaxTypeQuery, GetGlTaxTypeQueryVariables>;
export const CreateApprovalPolicyDocument = gql`
    mutation createApprovalPolicy($input: ApprovalPolicyInput!) {
  createApprovalPolicy(input: $input) {
    ID
  }
}
    `;
export type CreateApprovalPolicyMutationFn = ApolloReactCommon.MutationFunction<CreateApprovalPolicyMutation, CreateApprovalPolicyMutationVariables>;

/**
 * __useCreateApprovalPolicyMutation__
 *
 * To run a mutation, you first call `useCreateApprovalPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApprovalPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApprovalPolicyMutation, { data, loading, error }] = useCreateApprovalPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApprovalPolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApprovalPolicyMutation, CreateApprovalPolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApprovalPolicyMutation, CreateApprovalPolicyMutationVariables>(CreateApprovalPolicyDocument, baseOptions);
      }
export type CreateApprovalPolicyMutationHookResult = ReturnType<typeof useCreateApprovalPolicyMutation>;
export type CreateApprovalPolicyMutationResult = ApolloReactCommon.MutationResult<CreateApprovalPolicyMutation>;
export type CreateApprovalPolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApprovalPolicyMutation, CreateApprovalPolicyMutationVariables>;
export const CreateApprovalPolicyAssignmentDocument = gql`
    mutation createApprovalPolicyAssignment($input: ApprovalPolicyAssignmentInput!) {
  createApprovalPolicyAssignment(input: $input) {
    ID
  }
}
    `;
export type CreateApprovalPolicyAssignmentMutationFn = ApolloReactCommon.MutationFunction<CreateApprovalPolicyAssignmentMutation, CreateApprovalPolicyAssignmentMutationVariables>;

/**
 * __useCreateApprovalPolicyAssignmentMutation__
 *
 * To run a mutation, you first call `useCreateApprovalPolicyAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApprovalPolicyAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApprovalPolicyAssignmentMutation, { data, loading, error }] = useCreateApprovalPolicyAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApprovalPolicyAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApprovalPolicyAssignmentMutation, CreateApprovalPolicyAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApprovalPolicyAssignmentMutation, CreateApprovalPolicyAssignmentMutationVariables>(CreateApprovalPolicyAssignmentDocument, baseOptions);
      }
export type CreateApprovalPolicyAssignmentMutationHookResult = ReturnType<typeof useCreateApprovalPolicyAssignmentMutation>;
export type CreateApprovalPolicyAssignmentMutationResult = ApolloReactCommon.MutationResult<CreateApprovalPolicyAssignmentMutation>;
export type CreateApprovalPolicyAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApprovalPolicyAssignmentMutation, CreateApprovalPolicyAssignmentMutationVariables>;
export const UpdateApprovalPolicyDocument = gql`
    mutation updateApprovalPolicy($input: ApprovalPolicyInput!) {
  updateApprovalPolicy(input: $input) {
    ID
  }
}
    `;
export type UpdateApprovalPolicyMutationFn = ApolloReactCommon.MutationFunction<UpdateApprovalPolicyMutation, UpdateApprovalPolicyMutationVariables>;

/**
 * __useUpdateApprovalPolicyMutation__
 *
 * To run a mutation, you first call `useUpdateApprovalPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApprovalPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApprovalPolicyMutation, { data, loading, error }] = useUpdateApprovalPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApprovalPolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApprovalPolicyMutation, UpdateApprovalPolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApprovalPolicyMutation, UpdateApprovalPolicyMutationVariables>(UpdateApprovalPolicyDocument, baseOptions);
      }
export type UpdateApprovalPolicyMutationHookResult = ReturnType<typeof useUpdateApprovalPolicyMutation>;
export type UpdateApprovalPolicyMutationResult = ApolloReactCommon.MutationResult<UpdateApprovalPolicyMutation>;
export type UpdateApprovalPolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApprovalPolicyMutation, UpdateApprovalPolicyMutationVariables>;
export const UpdateApprovalPolicyAssignmentDocument = gql`
    mutation updateApprovalPolicyAssignment($input: ApprovalPolicyAssignmentInput!) {
  updateApprovalPolicyAssignment(input: $input) {
    ID
  }
}
    `;
export type UpdateApprovalPolicyAssignmentMutationFn = ApolloReactCommon.MutationFunction<UpdateApprovalPolicyAssignmentMutation, UpdateApprovalPolicyAssignmentMutationVariables>;

/**
 * __useUpdateApprovalPolicyAssignmentMutation__
 *
 * To run a mutation, you first call `useUpdateApprovalPolicyAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApprovalPolicyAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApprovalPolicyAssignmentMutation, { data, loading, error }] = useUpdateApprovalPolicyAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApprovalPolicyAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApprovalPolicyAssignmentMutation, UpdateApprovalPolicyAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApprovalPolicyAssignmentMutation, UpdateApprovalPolicyAssignmentMutationVariables>(UpdateApprovalPolicyAssignmentDocument, baseOptions);
      }
export type UpdateApprovalPolicyAssignmentMutationHookResult = ReturnType<typeof useUpdateApprovalPolicyAssignmentMutation>;
export type UpdateApprovalPolicyAssignmentMutationResult = ApolloReactCommon.MutationResult<UpdateApprovalPolicyAssignmentMutation>;
export type UpdateApprovalPolicyAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApprovalPolicyAssignmentMutation, UpdateApprovalPolicyAssignmentMutationVariables>;
export const DeleteApprovalPolicyAssignmentDocument = gql`
    mutation deleteApprovalPolicyAssignment($input: ApprovalPolicyAssignmentDeleteInput!) {
  deleteApprovalPolicyAssignment(input: $input)
}
    `;
export type DeleteApprovalPolicyAssignmentMutationFn = ApolloReactCommon.MutationFunction<DeleteApprovalPolicyAssignmentMutation, DeleteApprovalPolicyAssignmentMutationVariables>;

/**
 * __useDeleteApprovalPolicyAssignmentMutation__
 *
 * To run a mutation, you first call `useDeleteApprovalPolicyAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApprovalPolicyAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApprovalPolicyAssignmentMutation, { data, loading, error }] = useDeleteApprovalPolicyAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteApprovalPolicyAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApprovalPolicyAssignmentMutation, DeleteApprovalPolicyAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApprovalPolicyAssignmentMutation, DeleteApprovalPolicyAssignmentMutationVariables>(DeleteApprovalPolicyAssignmentDocument, baseOptions);
      }
export type DeleteApprovalPolicyAssignmentMutationHookResult = ReturnType<typeof useDeleteApprovalPolicyAssignmentMutation>;
export type DeleteApprovalPolicyAssignmentMutationResult = ApolloReactCommon.MutationResult<DeleteApprovalPolicyAssignmentMutation>;
export type DeleteApprovalPolicyAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApprovalPolicyAssignmentMutation, DeleteApprovalPolicyAssignmentMutationVariables>;
export const DeleteApprovalPolicyDocument = gql`
    mutation deleteApprovalPolicy($input: ApprovalPolicyDeleteInput!) {
  deleteApprovalPolicy(input: $input)
}
    `;
export type DeleteApprovalPolicyMutationFn = ApolloReactCommon.MutationFunction<DeleteApprovalPolicyMutation, DeleteApprovalPolicyMutationVariables>;

/**
 * __useDeleteApprovalPolicyMutation__
 *
 * To run a mutation, you first call `useDeleteApprovalPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApprovalPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApprovalPolicyMutation, { data, loading, error }] = useDeleteApprovalPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteApprovalPolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApprovalPolicyMutation, DeleteApprovalPolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApprovalPolicyMutation, DeleteApprovalPolicyMutationVariables>(DeleteApprovalPolicyDocument, baseOptions);
      }
export type DeleteApprovalPolicyMutationHookResult = ReturnType<typeof useDeleteApprovalPolicyMutation>;
export type DeleteApprovalPolicyMutationResult = ApolloReactCommon.MutationResult<DeleteApprovalPolicyMutation>;
export type DeleteApprovalPolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApprovalPolicyMutation, DeleteApprovalPolicyMutationVariables>;
export const GetApprovalPolicyDocument = gql`
    query getApprovalPolicy($ID: String, $category: String!) {
  getApprovalPolicy(ID: $ID, category: $category) {
    ID
    approvalList
    category
    createdTs
    modTs
    lowerLimit
    upperLimit
    latestLimit
    approvalPolicyAssignment {
      approvalPolicyID
      ID
      contractID
      approvalList
      category
    }
  }
}
    `;

/**
 * __useGetApprovalPolicyQuery__
 *
 * To run a query within a React component, call `useGetApprovalPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalPolicyQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useGetApprovalPolicyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApprovalPolicyQuery, GetApprovalPolicyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApprovalPolicyQuery, GetApprovalPolicyQueryVariables>(GetApprovalPolicyDocument, baseOptions);
      }
export function useGetApprovalPolicyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApprovalPolicyQuery, GetApprovalPolicyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApprovalPolicyQuery, GetApprovalPolicyQueryVariables>(GetApprovalPolicyDocument, baseOptions);
        }
export type GetApprovalPolicyQueryHookResult = ReturnType<typeof useGetApprovalPolicyQuery>;
export type GetApprovalPolicyLazyQueryHookResult = ReturnType<typeof useGetApprovalPolicyLazyQuery>;
export type GetApprovalPolicyQueryResult = ApolloReactCommon.QueryResult<GetApprovalPolicyQuery, GetApprovalPolicyQueryVariables>;
export const GetApprovalPolicyGuidelineDocument = gql`
    query getApprovalPolicyGuideline($category: String) {
  getApprovalPolicyGuideline(category: $category) {
    ID
    approvalList
    category
    createdTs
    modTs
    lowerLimit
    upperLimit
    approvalPolicyAssignment {
      approvalPolicyID
      ID
      contractID
      approvalList
      category
    }
  }
}
    `;

/**
 * __useGetApprovalPolicyGuidelineQuery__
 *
 * To run a query within a React component, call `useGetApprovalPolicyGuidelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalPolicyGuidelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalPolicyGuidelineQuery({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useGetApprovalPolicyGuidelineQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApprovalPolicyGuidelineQuery, GetApprovalPolicyGuidelineQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApprovalPolicyGuidelineQuery, GetApprovalPolicyGuidelineQueryVariables>(GetApprovalPolicyGuidelineDocument, baseOptions);
      }
export function useGetApprovalPolicyGuidelineLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApprovalPolicyGuidelineQuery, GetApprovalPolicyGuidelineQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApprovalPolicyGuidelineQuery, GetApprovalPolicyGuidelineQueryVariables>(GetApprovalPolicyGuidelineDocument, baseOptions);
        }
export type GetApprovalPolicyGuidelineQueryHookResult = ReturnType<typeof useGetApprovalPolicyGuidelineQuery>;
export type GetApprovalPolicyGuidelineLazyQueryHookResult = ReturnType<typeof useGetApprovalPolicyGuidelineLazyQuery>;
export type GetApprovalPolicyGuidelineQueryResult = ApolloReactCommon.QueryResult<GetApprovalPolicyGuidelineQuery, GetApprovalPolicyGuidelineQueryVariables>;
export const GetApprovalPolicyAssignmentDocument = gql`
    query getApprovalPolicyAssignment($ID: String, $category: String!, $contractID: String!) {
  getApprovalPolicyAssignment(ID: $ID, category: $category, contractID: $contractID) {
    ID
    contractID
    approvalPolicyID
    category
    approvalList
    accountID
    createdTs
    createdBy
    modBy
    modTs
    latestLimitAssignment
    lowerLimit
    upperLimit
  }
}
    `;

/**
 * __useGetApprovalPolicyAssignmentQuery__
 *
 * To run a query within a React component, call `useGetApprovalPolicyAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalPolicyAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalPolicyAssignmentQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      category: // value for 'category'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetApprovalPolicyAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApprovalPolicyAssignmentQuery, GetApprovalPolicyAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApprovalPolicyAssignmentQuery, GetApprovalPolicyAssignmentQueryVariables>(GetApprovalPolicyAssignmentDocument, baseOptions);
      }
export function useGetApprovalPolicyAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApprovalPolicyAssignmentQuery, GetApprovalPolicyAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApprovalPolicyAssignmentQuery, GetApprovalPolicyAssignmentQueryVariables>(GetApprovalPolicyAssignmentDocument, baseOptions);
        }
export type GetApprovalPolicyAssignmentQueryHookResult = ReturnType<typeof useGetApprovalPolicyAssignmentQuery>;
export type GetApprovalPolicyAssignmentLazyQueryHookResult = ReturnType<typeof useGetApprovalPolicyAssignmentLazyQuery>;
export type GetApprovalPolicyAssignmentQueryResult = ApolloReactCommon.QueryResult<GetApprovalPolicyAssignmentQuery, GetApprovalPolicyAssignmentQueryVariables>;
export const GetApprovalPolicyAssignmentGuidelineDocument = gql`
    query getApprovalPolicyAssignmentGuideline($category: String, $contractID: String) {
  getApprovalPolicyAssignmentGuideline(category: $category, contractID: $contractID) {
    ID
    contractID
    approvalPolicyID
    category
    approvalList
    accountID
    createdTs
    createdBy
    modBy
    modTs
  }
}
    `;

/**
 * __useGetApprovalPolicyAssignmentGuidelineQuery__
 *
 * To run a query within a React component, call `useGetApprovalPolicyAssignmentGuidelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalPolicyAssignmentGuidelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalPolicyAssignmentGuidelineQuery({
 *   variables: {
 *      category: // value for 'category'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetApprovalPolicyAssignmentGuidelineQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApprovalPolicyAssignmentGuidelineQuery, GetApprovalPolicyAssignmentGuidelineQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApprovalPolicyAssignmentGuidelineQuery, GetApprovalPolicyAssignmentGuidelineQueryVariables>(GetApprovalPolicyAssignmentGuidelineDocument, baseOptions);
      }
export function useGetApprovalPolicyAssignmentGuidelineLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApprovalPolicyAssignmentGuidelineQuery, GetApprovalPolicyAssignmentGuidelineQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApprovalPolicyAssignmentGuidelineQuery, GetApprovalPolicyAssignmentGuidelineQueryVariables>(GetApprovalPolicyAssignmentGuidelineDocument, baseOptions);
        }
export type GetApprovalPolicyAssignmentGuidelineQueryHookResult = ReturnType<typeof useGetApprovalPolicyAssignmentGuidelineQuery>;
export type GetApprovalPolicyAssignmentGuidelineLazyQueryHookResult = ReturnType<typeof useGetApprovalPolicyAssignmentGuidelineLazyQuery>;
export type GetApprovalPolicyAssignmentGuidelineQueryResult = ApolloReactCommon.QueryResult<GetApprovalPolicyAssignmentGuidelineQuery, GetApprovalPolicyAssignmentGuidelineQueryVariables>;
export const GetSiteMaterialMasterDocument = gql`
    query getSiteMaterialMaster {
  getSiteMaterialPolicy {
    ID
    createdTs
    modTs
    costItemID
    siteMaterialID
    costItem {
      ID
      name
    }
    siteMaterial {
      ID
      name
    }
  }
}
    `;

/**
 * __useGetSiteMaterialMasterQuery__
 *
 * To run a query within a React component, call `useGetSiteMaterialMasterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteMaterialMasterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteMaterialMasterQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSiteMaterialMasterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteMaterialMasterQuery, GetSiteMaterialMasterQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteMaterialMasterQuery, GetSiteMaterialMasterQueryVariables>(GetSiteMaterialMasterDocument, baseOptions);
      }
export function useGetSiteMaterialMasterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteMaterialMasterQuery, GetSiteMaterialMasterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteMaterialMasterQuery, GetSiteMaterialMasterQueryVariables>(GetSiteMaterialMasterDocument, baseOptions);
        }
export type GetSiteMaterialMasterQueryHookResult = ReturnType<typeof useGetSiteMaterialMasterQuery>;
export type GetSiteMaterialMasterLazyQueryHookResult = ReturnType<typeof useGetSiteMaterialMasterLazyQuery>;
export type GetSiteMaterialMasterQueryResult = ApolloReactCommon.QueryResult<GetSiteMaterialMasterQuery, GetSiteMaterialMasterQueryVariables>;
export const GetSiteMaterialContractDocument = gql`
    query getSiteMaterialContract($contractID: String!) {
  getSiteMaterialContractPolicy(contractID: $contractID) {
    ID
    createdTs
    modTs
    costItemID
    siteMaterialID
    costItem {
      ID
      name
    }
    siteMaterial {
      ID
      name
    }
  }
}
    `;

/**
 * __useGetSiteMaterialContractQuery__
 *
 * To run a query within a React component, call `useGetSiteMaterialContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteMaterialContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteMaterialContractQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetSiteMaterialContractQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteMaterialContractQuery, GetSiteMaterialContractQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteMaterialContractQuery, GetSiteMaterialContractQueryVariables>(GetSiteMaterialContractDocument, baseOptions);
      }
export function useGetSiteMaterialContractLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteMaterialContractQuery, GetSiteMaterialContractQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteMaterialContractQuery, GetSiteMaterialContractQueryVariables>(GetSiteMaterialContractDocument, baseOptions);
        }
export type GetSiteMaterialContractQueryHookResult = ReturnType<typeof useGetSiteMaterialContractQuery>;
export type GetSiteMaterialContractLazyQueryHookResult = ReturnType<typeof useGetSiteMaterialContractLazyQuery>;
export type GetSiteMaterialContractQueryResult = ApolloReactCommon.QueryResult<GetSiteMaterialContractQuery, GetSiteMaterialContractQueryVariables>;
export const GetCalendarDocument = gql`
    query getCalendar($ID: String, $orderByAsc: String, $orderByDesc: String) {
  getCalendar(ID: $ID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    ID
    state
    stateID
    year
    weekend
    isDefault
    publicHoliday {
      ID
      name
    }
  }
}
    `;

/**
 * __useGetCalendarQuery__
 *
 * To run a query within a React component, call `useGetCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCalendarQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetCalendarQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCalendarQuery, GetCalendarQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCalendarQuery, GetCalendarQueryVariables>(GetCalendarDocument, baseOptions);
      }
export function useGetCalendarLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCalendarQuery, GetCalendarQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCalendarQuery, GetCalendarQueryVariables>(GetCalendarDocument, baseOptions);
        }
export type GetCalendarQueryHookResult = ReturnType<typeof useGetCalendarQuery>;
export type GetCalendarLazyQueryHookResult = ReturnType<typeof useGetCalendarLazyQuery>;
export type GetCalendarQueryResult = ApolloReactCommon.QueryResult<GetCalendarQuery, GetCalendarQueryVariables>;
export const CreateCalendarDocument = gql`
    mutation createCalendar($input: CalendarInput!, $publicHolidayID: [String!]!) {
  createCalendar(input: $input, publicHolidayID: $publicHolidayID)
}
    `;
export type CreateCalendarMutationFn = ApolloReactCommon.MutationFunction<CreateCalendarMutation, CreateCalendarMutationVariables>;

/**
 * __useCreateCalendarMutation__
 *
 * To run a mutation, you first call `useCreateCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCalendarMutation, { data, loading, error }] = useCreateCalendarMutation({
 *   variables: {
 *      input: // value for 'input'
 *      publicHolidayID: // value for 'publicHolidayID'
 *   },
 * });
 */
export function useCreateCalendarMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCalendarMutation, CreateCalendarMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCalendarMutation, CreateCalendarMutationVariables>(CreateCalendarDocument, baseOptions);
      }
export type CreateCalendarMutationHookResult = ReturnType<typeof useCreateCalendarMutation>;
export type CreateCalendarMutationResult = ApolloReactCommon.MutationResult<CreateCalendarMutation>;
export type CreateCalendarMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCalendarMutation, CreateCalendarMutationVariables>;
export const UpdateCalendarDocument = gql`
    mutation updateCalendar($input: CalendarInput!, $publicHolidayID: [String!]!) {
  updateCalendar(input: $input, publicHolidayID: $publicHolidayID)
}
    `;
export type UpdateCalendarMutationFn = ApolloReactCommon.MutationFunction<UpdateCalendarMutation, UpdateCalendarMutationVariables>;

/**
 * __useUpdateCalendarMutation__
 *
 * To run a mutation, you first call `useUpdateCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCalendarMutation, { data, loading, error }] = useUpdateCalendarMutation({
 *   variables: {
 *      input: // value for 'input'
 *      publicHolidayID: // value for 'publicHolidayID'
 *   },
 * });
 */
export function useUpdateCalendarMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCalendarMutation, UpdateCalendarMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCalendarMutation, UpdateCalendarMutationVariables>(UpdateCalendarDocument, baseOptions);
      }
export type UpdateCalendarMutationHookResult = ReturnType<typeof useUpdateCalendarMutation>;
export type UpdateCalendarMutationResult = ApolloReactCommon.MutationResult<UpdateCalendarMutation>;
export type UpdateCalendarMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCalendarMutation, UpdateCalendarMutationVariables>;
export const CancelCalendarDocument = gql`
    mutation cancelCalendar($input: CalendarDeleteInput!) {
  cancelCalendar(input: $input)
}
    `;
export type CancelCalendarMutationFn = ApolloReactCommon.MutationFunction<CancelCalendarMutation, CancelCalendarMutationVariables>;

/**
 * __useCancelCalendarMutation__
 *
 * To run a mutation, you first call `useCancelCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCalendarMutation, { data, loading, error }] = useCancelCalendarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelCalendarMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelCalendarMutation, CancelCalendarMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelCalendarMutation, CancelCalendarMutationVariables>(CancelCalendarDocument, baseOptions);
      }
export type CancelCalendarMutationHookResult = ReturnType<typeof useCancelCalendarMutation>;
export type CancelCalendarMutationResult = ApolloReactCommon.MutationResult<CancelCalendarMutation>;
export type CancelCalendarMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelCalendarMutation, CancelCalendarMutationVariables>;
export const DeleteCalendarDocument = gql`
    mutation deleteCalendar($input: CalendarDeleteInput!) {
  deleteCalendar(input: $input)
}
    `;
export type DeleteCalendarMutationFn = ApolloReactCommon.MutationFunction<DeleteCalendarMutation, DeleteCalendarMutationVariables>;

/**
 * __useDeleteCalendarMutation__
 *
 * To run a mutation, you first call `useDeleteCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCalendarMutation, { data, loading, error }] = useDeleteCalendarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCalendarMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCalendarMutation, DeleteCalendarMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCalendarMutation, DeleteCalendarMutationVariables>(DeleteCalendarDocument, baseOptions);
      }
export type DeleteCalendarMutationHookResult = ReturnType<typeof useDeleteCalendarMutation>;
export type DeleteCalendarMutationResult = ApolloReactCommon.MutationResult<DeleteCalendarMutation>;
export type DeleteCalendarMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCalendarMutation, DeleteCalendarMutationVariables>;
export const GetCalendarPhPolicyDocument = gql`
    query getCalendarPHPolicy($ID: String, $calendarID: String) {
  getCalendarPHPolicy(ID: $ID, calendarID: $calendarID) {
    ID
    calendarID
    publicHolidayID
  }
}
    `;

/**
 * __useGetCalendarPhPolicyQuery__
 *
 * To run a query within a React component, call `useGetCalendarPhPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarPhPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCalendarPhPolicyQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      calendarID: // value for 'calendarID'
 *   },
 * });
 */
export function useGetCalendarPhPolicyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCalendarPhPolicyQuery, GetCalendarPhPolicyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCalendarPhPolicyQuery, GetCalendarPhPolicyQueryVariables>(GetCalendarPhPolicyDocument, baseOptions);
      }
export function useGetCalendarPhPolicyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCalendarPhPolicyQuery, GetCalendarPhPolicyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCalendarPhPolicyQuery, GetCalendarPhPolicyQueryVariables>(GetCalendarPhPolicyDocument, baseOptions);
        }
export type GetCalendarPhPolicyQueryHookResult = ReturnType<typeof useGetCalendarPhPolicyQuery>;
export type GetCalendarPhPolicyLazyQueryHookResult = ReturnType<typeof useGetCalendarPhPolicyLazyQuery>;
export type GetCalendarPhPolicyQueryResult = ApolloReactCommon.QueryResult<GetCalendarPhPolicyQuery, GetCalendarPhPolicyQueryVariables>;
export const GetCostCategoryDocument = gql`
    query getCostCategory($ID: String, $contractID: String, $costClass: CostClass, $isLastNode: Boolean, $orderByAsc: String, $orderByDesc: String, $commonStatus: CommonStatus) {
  getCostCategory(ID: $ID, contractID: $contractID, costClass: $costClass, isLastNode: $isLastNode, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, commonStatus: $commonStatus) {
    ID
    name
    modTs
    accountID
    parentCostCategoryID
    isLastNode
    pnmCostCat
    costClass
    tankType
    fuelConsumption
    glAccountCode
    commonStatus
    childrenIDs
    supplier {
      name
      ID
    }
    subcontractor {
      name
      ID
    }
    costItem {
      ID
      name
      unitPrice
      code
      uomID
      markupPerc
      costCategoryID
      variancePerc
      onBehalf
      isInventory
      isInventoryChecking
      UOM {
        ID
        name
        code
      }
      taxSchemeID
    }
    budget {
      ID
      createdTs
      budgetAmt
    }
    classificationCodeID
  }
  costCategoryDates
}
    `;

/**
 * __useGetCostCategoryQuery__
 *
 * To run a query within a React component, call `useGetCostCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCategoryQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      costClass: // value for 'costClass'
 *      isLastNode: // value for 'isLastNode'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      commonStatus: // value for 'commonStatus'
 *   },
 * });
 */
export function useGetCostCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCategoryQuery, GetCostCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCategoryQuery, GetCostCategoryQueryVariables>(GetCostCategoryDocument, baseOptions);
      }
export function useGetCostCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCategoryQuery, GetCostCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCategoryQuery, GetCostCategoryQueryVariables>(GetCostCategoryDocument, baseOptions);
        }
export type GetCostCategoryQueryHookResult = ReturnType<typeof useGetCostCategoryQuery>;
export type GetCostCategoryLazyQueryHookResult = ReturnType<typeof useGetCostCategoryLazyQuery>;
export type GetCostCategoryQueryResult = ApolloReactCommon.QueryResult<GetCostCategoryQuery, GetCostCategoryQueryVariables>;
export const GetCostCategoryTreeDocument = gql`
    query getCostCategoryTree($ID: String, $contractID: String, $costClass: CostClass, $isLastNode: Boolean, $orderByAsc: String, $orderByDesc: String, $commonStatus: CommonStatus) {
  getCostCategory(ID: $ID, contractID: $contractID, costClass: $costClass, isLastNode: $isLastNode, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, commonStatus: $commonStatus) {
    ID
    name
    accountID
    parentCostCategoryID
    classificationCodeID
    isLastNode
    costClass
    commonStatus
    childrenIDs
    costItem {
      ID
      uomID
      UOM {
        ID
        name
        code
      }
      costCategoryID
      costCategory {
        ID
        name
      }
      taxSchemeID
      name
      unitPrice
      code
      markupPerc
      variancePerc
      onBehalf
      isDaywork
      isInventory
      isInventoryChecking
      commonStatus
    }
  }
}
    `;

/**
 * __useGetCostCategoryTreeQuery__
 *
 * To run a query within a React component, call `useGetCostCategoryTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCategoryTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCategoryTreeQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      costClass: // value for 'costClass'
 *      isLastNode: // value for 'isLastNode'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      commonStatus: // value for 'commonStatus'
 *   },
 * });
 */
export function useGetCostCategoryTreeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCategoryTreeQuery, GetCostCategoryTreeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCategoryTreeQuery, GetCostCategoryTreeQueryVariables>(GetCostCategoryTreeDocument, baseOptions);
      }
export function useGetCostCategoryTreeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCategoryTreeQuery, GetCostCategoryTreeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCategoryTreeQuery, GetCostCategoryTreeQueryVariables>(GetCostCategoryTreeDocument, baseOptions);
        }
export type GetCostCategoryTreeQueryHookResult = ReturnType<typeof useGetCostCategoryTreeQuery>;
export type GetCostCategoryTreeLazyQueryHookResult = ReturnType<typeof useGetCostCategoryTreeLazyQuery>;
export type GetCostCategoryTreeQueryResult = ApolloReactCommon.QueryResult<GetCostCategoryTreeQuery, GetCostCategoryTreeQueryVariables>;
export const GetCostCategoryByClassDocument = gql`
    query getCostCategoryByClass {
  getCostCategoryByClass
}
    `;

/**
 * __useGetCostCategoryByClassQuery__
 *
 * To run a query within a React component, call `useGetCostCategoryByClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCategoryByClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCategoryByClassQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCostCategoryByClassQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCategoryByClassQuery, GetCostCategoryByClassQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCategoryByClassQuery, GetCostCategoryByClassQueryVariables>(GetCostCategoryByClassDocument, baseOptions);
      }
export function useGetCostCategoryByClassLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCategoryByClassQuery, GetCostCategoryByClassQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCategoryByClassQuery, GetCostCategoryByClassQueryVariables>(GetCostCategoryByClassDocument, baseOptions);
        }
export type GetCostCategoryByClassQueryHookResult = ReturnType<typeof useGetCostCategoryByClassQuery>;
export type GetCostCategoryByClassLazyQueryHookResult = ReturnType<typeof useGetCostCategoryByClassLazyQuery>;
export type GetCostCategoryByClassQueryResult = ApolloReactCommon.QueryResult<GetCostCategoryByClassQuery, GetCostCategoryByClassQueryVariables>;
export const CreateCostCategoryDocument = gql`
    mutation createCostCategory($input: CostCategoryInput!) {
  createCostCategory(input: $input) {
    ID
  }
}
    `;
export type CreateCostCategoryMutationFn = ApolloReactCommon.MutationFunction<CreateCostCategoryMutation, CreateCostCategoryMutationVariables>;

/**
 * __useCreateCostCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCostCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCostCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCostCategoryMutation, { data, loading, error }] = useCreateCostCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCostCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCostCategoryMutation, CreateCostCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCostCategoryMutation, CreateCostCategoryMutationVariables>(CreateCostCategoryDocument, baseOptions);
      }
export type CreateCostCategoryMutationHookResult = ReturnType<typeof useCreateCostCategoryMutation>;
export type CreateCostCategoryMutationResult = ApolloReactCommon.MutationResult<CreateCostCategoryMutation>;
export type CreateCostCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCostCategoryMutation, CreateCostCategoryMutationVariables>;
export const UpdateCostCategoryDocument = gql`
    mutation updateCostCategory($input: CostCategoryInput!) {
  updateCostCategory(input: $input)
}
    `;
export type UpdateCostCategoryMutationFn = ApolloReactCommon.MutationFunction<UpdateCostCategoryMutation, UpdateCostCategoryMutationVariables>;

/**
 * __useUpdateCostCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCostCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCostCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCostCategoryMutation, { data, loading, error }] = useUpdateCostCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCostCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCostCategoryMutation, UpdateCostCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCostCategoryMutation, UpdateCostCategoryMutationVariables>(UpdateCostCategoryDocument, baseOptions);
      }
export type UpdateCostCategoryMutationHookResult = ReturnType<typeof useUpdateCostCategoryMutation>;
export type UpdateCostCategoryMutationResult = ApolloReactCommon.MutationResult<UpdateCostCategoryMutation>;
export type UpdateCostCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCostCategoryMutation, UpdateCostCategoryMutationVariables>;
export const DeleteCostCategoryDocument = gql`
    mutation deleteCostCategory($input: CostCategoryUpdateInput!) {
  deleteCostCategory(input: $input)
}
    `;
export type DeleteCostCategoryMutationFn = ApolloReactCommon.MutationFunction<DeleteCostCategoryMutation, DeleteCostCategoryMutationVariables>;

/**
 * __useDeleteCostCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCostCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCostCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCostCategoryMutation, { data, loading, error }] = useDeleteCostCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCostCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCostCategoryMutation, DeleteCostCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCostCategoryMutation, DeleteCostCategoryMutationVariables>(DeleteCostCategoryDocument, baseOptions);
      }
export type DeleteCostCategoryMutationHookResult = ReturnType<typeof useDeleteCostCategoryMutation>;
export type DeleteCostCategoryMutationResult = ApolloReactCommon.MutationResult<DeleteCostCategoryMutation>;
export type DeleteCostCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCostCategoryMutation, DeleteCostCategoryMutationVariables>;
export const DeleteSupplierInCCategoryDocument = gql`
    mutation deleteSupplierInCCategory($supplierID: [String!]!, $costCategoryID: String!) {
  deleteSupplierInCCategory(supplierID: $supplierID, costCategoryID: $costCategoryID)
}
    `;
export type DeleteSupplierInCCategoryMutationFn = ApolloReactCommon.MutationFunction<DeleteSupplierInCCategoryMutation, DeleteSupplierInCCategoryMutationVariables>;

/**
 * __useDeleteSupplierInCCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteSupplierInCCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSupplierInCCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSupplierInCCategoryMutation, { data, loading, error }] = useDeleteSupplierInCCategoryMutation({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useDeleteSupplierInCCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSupplierInCCategoryMutation, DeleteSupplierInCCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSupplierInCCategoryMutation, DeleteSupplierInCCategoryMutationVariables>(DeleteSupplierInCCategoryDocument, baseOptions);
      }
export type DeleteSupplierInCCategoryMutationHookResult = ReturnType<typeof useDeleteSupplierInCCategoryMutation>;
export type DeleteSupplierInCCategoryMutationResult = ApolloReactCommon.MutationResult<DeleteSupplierInCCategoryMutation>;
export type DeleteSupplierInCCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSupplierInCCategoryMutation, DeleteSupplierInCCategoryMutationVariables>;
export const AddSupplierInCCategoryDocument = gql`
    mutation addSupplierInCCategory($supplierID: [String!]!, $costCategoryID: String!) {
  addSupplierInCCategory(supplierID: $supplierID, costCategoryID: $costCategoryID)
}
    `;
export type AddSupplierInCCategoryMutationFn = ApolloReactCommon.MutationFunction<AddSupplierInCCategoryMutation, AddSupplierInCCategoryMutationVariables>;

/**
 * __useAddSupplierInCCategoryMutation__
 *
 * To run a mutation, you first call `useAddSupplierInCCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSupplierInCCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSupplierInCCategoryMutation, { data, loading, error }] = useAddSupplierInCCategoryMutation({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useAddSupplierInCCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddSupplierInCCategoryMutation, AddSupplierInCCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<AddSupplierInCCategoryMutation, AddSupplierInCCategoryMutationVariables>(AddSupplierInCCategoryDocument, baseOptions);
      }
export type AddSupplierInCCategoryMutationHookResult = ReturnType<typeof useAddSupplierInCCategoryMutation>;
export type AddSupplierInCCategoryMutationResult = ApolloReactCommon.MutationResult<AddSupplierInCCategoryMutation>;
export type AddSupplierInCCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<AddSupplierInCCategoryMutation, AddSupplierInCCategoryMutationVariables>;
export const CancelCostCategoryDocument = gql`
    mutation cancelCostCategory($ID: String!) {
  cancelCostCategory(ID: $ID)
}
    `;
export type CancelCostCategoryMutationFn = ApolloReactCommon.MutationFunction<CancelCostCategoryMutation, CancelCostCategoryMutationVariables>;

/**
 * __useCancelCostCategoryMutation__
 *
 * To run a mutation, you first call `useCancelCostCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCostCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCostCategoryMutation, { data, loading, error }] = useCancelCostCategoryMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCancelCostCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelCostCategoryMutation, CancelCostCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelCostCategoryMutation, CancelCostCategoryMutationVariables>(CancelCostCategoryDocument, baseOptions);
      }
export type CancelCostCategoryMutationHookResult = ReturnType<typeof useCancelCostCategoryMutation>;
export type CancelCostCategoryMutationResult = ApolloReactCommon.MutationResult<CancelCostCategoryMutation>;
export type CancelCostCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelCostCategoryMutation, CancelCostCategoryMutationVariables>;
export const CreateBatchCostCategoryDocument = gql`
    mutation createBatchCostCategory($input: [CostCategoryInput!]!) {
  createBatchCostCategory(input: $input)
}
    `;
export type CreateBatchCostCategoryMutationFn = ApolloReactCommon.MutationFunction<CreateBatchCostCategoryMutation, CreateBatchCostCategoryMutationVariables>;

/**
 * __useCreateBatchCostCategoryMutation__
 *
 * To run a mutation, you first call `useCreateBatchCostCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBatchCostCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBatchCostCategoryMutation, { data, loading, error }] = useCreateBatchCostCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBatchCostCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBatchCostCategoryMutation, CreateBatchCostCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBatchCostCategoryMutation, CreateBatchCostCategoryMutationVariables>(CreateBatchCostCategoryDocument, baseOptions);
      }
export type CreateBatchCostCategoryMutationHookResult = ReturnType<typeof useCreateBatchCostCategoryMutation>;
export type CreateBatchCostCategoryMutationResult = ApolloReactCommon.MutationResult<CreateBatchCostCategoryMutation>;
export type CreateBatchCostCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBatchCostCategoryMutation, CreateBatchCostCategoryMutationVariables>;
export const CreateBatchCostCategoryWithoutItemDocument = gql`
    mutation createBatchCostCategoryWithoutItem($input: [TreeCostCategoryInput!]!) {
  createBatchCostCategoryWithoutItem(input: $input)
}
    `;
export type CreateBatchCostCategoryWithoutItemMutationFn = ApolloReactCommon.MutationFunction<CreateBatchCostCategoryWithoutItemMutation, CreateBatchCostCategoryWithoutItemMutationVariables>;

/**
 * __useCreateBatchCostCategoryWithoutItemMutation__
 *
 * To run a mutation, you first call `useCreateBatchCostCategoryWithoutItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBatchCostCategoryWithoutItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBatchCostCategoryWithoutItemMutation, { data, loading, error }] = useCreateBatchCostCategoryWithoutItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBatchCostCategoryWithoutItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBatchCostCategoryWithoutItemMutation, CreateBatchCostCategoryWithoutItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBatchCostCategoryWithoutItemMutation, CreateBatchCostCategoryWithoutItemMutationVariables>(CreateBatchCostCategoryWithoutItemDocument, baseOptions);
      }
export type CreateBatchCostCategoryWithoutItemMutationHookResult = ReturnType<typeof useCreateBatchCostCategoryWithoutItemMutation>;
export type CreateBatchCostCategoryWithoutItemMutationResult = ApolloReactCommon.MutationResult<CreateBatchCostCategoryWithoutItemMutation>;
export type CreateBatchCostCategoryWithoutItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBatchCostCategoryWithoutItemMutation, CreateBatchCostCategoryWithoutItemMutationVariables>;
export const ActivateCostCategoryDocument = gql`
    mutation activateCostCategory($input: CostCategoryUpdateInput!) {
  activateCostCategory(input: $input)
}
    `;
export type ActivateCostCategoryMutationFn = ApolloReactCommon.MutationFunction<ActivateCostCategoryMutation, ActivateCostCategoryMutationVariables>;

/**
 * __useActivateCostCategoryMutation__
 *
 * To run a mutation, you first call `useActivateCostCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateCostCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateCostCategoryMutation, { data, loading, error }] = useActivateCostCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateCostCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateCostCategoryMutation, ActivateCostCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateCostCategoryMutation, ActivateCostCategoryMutationVariables>(ActivateCostCategoryDocument, baseOptions);
      }
export type ActivateCostCategoryMutationHookResult = ReturnType<typeof useActivateCostCategoryMutation>;
export type ActivateCostCategoryMutationResult = ApolloReactCommon.MutationResult<ActivateCostCategoryMutation>;
export type ActivateCostCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateCostCategoryMutation, ActivateCostCategoryMutationVariables>;
export const DeleteCostCategoryCostitemDocument = gql`
    mutation deleteCostCategoryCostitem($ID: String!) {
  deleteCostCategoryCostItem(ID: $ID)
}
    `;
export type DeleteCostCategoryCostitemMutationFn = ApolloReactCommon.MutationFunction<DeleteCostCategoryCostitemMutation, DeleteCostCategoryCostitemMutationVariables>;

/**
 * __useDeleteCostCategoryCostitemMutation__
 *
 * To run a mutation, you first call `useDeleteCostCategoryCostitemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCostCategoryCostitemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCostCategoryCostitemMutation, { data, loading, error }] = useDeleteCostCategoryCostitemMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteCostCategoryCostitemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCostCategoryCostitemMutation, DeleteCostCategoryCostitemMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCostCategoryCostitemMutation, DeleteCostCategoryCostitemMutationVariables>(DeleteCostCategoryCostitemDocument, baseOptions);
      }
export type DeleteCostCategoryCostitemMutationHookResult = ReturnType<typeof useDeleteCostCategoryCostitemMutation>;
export type DeleteCostCategoryCostitemMutationResult = ApolloReactCommon.MutationResult<DeleteCostCategoryCostitemMutation>;
export type DeleteCostCategoryCostitemMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCostCategoryCostitemMutation, DeleteCostCategoryCostitemMutationVariables>;
export const GetSubcontractorCostCategoryDocument = gql`
    query getSubcontractorCostCategory($costCategoryID: String!) {
  getSubcontractorCostCategory(costCategoryID: $costCategoryID)
}
    `;

/**
 * __useGetSubcontractorCostCategoryQuery__
 *
 * To run a query within a React component, call `useGetSubcontractorCostCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractorCostCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractorCostCategoryQuery({
 *   variables: {
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useGetSubcontractorCostCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractorCostCategoryQuery, GetSubcontractorCostCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractorCostCategoryQuery, GetSubcontractorCostCategoryQueryVariables>(GetSubcontractorCostCategoryDocument, baseOptions);
      }
export function useGetSubcontractorCostCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractorCostCategoryQuery, GetSubcontractorCostCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractorCostCategoryQuery, GetSubcontractorCostCategoryQueryVariables>(GetSubcontractorCostCategoryDocument, baseOptions);
        }
export type GetSubcontractorCostCategoryQueryHookResult = ReturnType<typeof useGetSubcontractorCostCategoryQuery>;
export type GetSubcontractorCostCategoryLazyQueryHookResult = ReturnType<typeof useGetSubcontractorCostCategoryLazyQuery>;
export type GetSubcontractorCostCategoryQueryResult = ApolloReactCommon.QueryResult<GetSubcontractorCostCategoryQuery, GetSubcontractorCostCategoryQueryVariables>;
export const GetSupplierCostCategoryDocument = gql`
    query getSupplierCostCategory($costCategoryID: String!) {
  getSupplierCostCategory(costCategoryID: $costCategoryID)
}
    `;

/**
 * __useGetSupplierCostCategoryQuery__
 *
 * To run a query within a React component, call `useGetSupplierCostCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierCostCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierCostCategoryQuery({
 *   variables: {
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useGetSupplierCostCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierCostCategoryQuery, GetSupplierCostCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierCostCategoryQuery, GetSupplierCostCategoryQueryVariables>(GetSupplierCostCategoryDocument, baseOptions);
      }
export function useGetSupplierCostCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierCostCategoryQuery, GetSupplierCostCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierCostCategoryQuery, GetSupplierCostCategoryQueryVariables>(GetSupplierCostCategoryDocument, baseOptions);
        }
export type GetSupplierCostCategoryQueryHookResult = ReturnType<typeof useGetSupplierCostCategoryQuery>;
export type GetSupplierCostCategoryLazyQueryHookResult = ReturnType<typeof useGetSupplierCostCategoryLazyQuery>;
export type GetSupplierCostCategoryQueryResult = ApolloReactCommon.QueryResult<GetSupplierCostCategoryQuery, GetSupplierCostCategoryQueryVariables>;
export const GetAssignedPlantMachineryDocument = gql`
    query getAssignedPlantMachinery($costCategoryID: String!) {
  getPlantMachinery(costCategoryID: $costCategoryID) {
    ID
    costCategoryID
    plantMachineryStatus
    regNo
    name
    remarks
    purchaseDate
  }
}
    `;

/**
 * __useGetAssignedPlantMachineryQuery__
 *
 * To run a query within a React component, call `useGetAssignedPlantMachineryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignedPlantMachineryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignedPlantMachineryQuery({
 *   variables: {
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useGetAssignedPlantMachineryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAssignedPlantMachineryQuery, GetAssignedPlantMachineryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAssignedPlantMachineryQuery, GetAssignedPlantMachineryQueryVariables>(GetAssignedPlantMachineryDocument, baseOptions);
      }
export function useGetAssignedPlantMachineryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssignedPlantMachineryQuery, GetAssignedPlantMachineryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAssignedPlantMachineryQuery, GetAssignedPlantMachineryQueryVariables>(GetAssignedPlantMachineryDocument, baseOptions);
        }
export type GetAssignedPlantMachineryQueryHookResult = ReturnType<typeof useGetAssignedPlantMachineryQuery>;
export type GetAssignedPlantMachineryLazyQueryHookResult = ReturnType<typeof useGetAssignedPlantMachineryLazyQuery>;
export type GetAssignedPlantMachineryQueryResult = ApolloReactCommon.QueryResult<GetAssignedPlantMachineryQuery, GetAssignedPlantMachineryQueryVariables>;
export const AddCostCategoryDocument = gql`
    mutation addCostCategory($input: TreeCostCategoryInput!) {
  addCostCategory(input: $input) {
    ID
  }
}
    `;
export type AddCostCategoryMutationFn = ApolloReactCommon.MutationFunction<AddCostCategoryMutation, AddCostCategoryMutationVariables>;

/**
 * __useAddCostCategoryMutation__
 *
 * To run a mutation, you first call `useAddCostCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCostCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCostCategoryMutation, { data, loading, error }] = useAddCostCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCostCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddCostCategoryMutation, AddCostCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<AddCostCategoryMutation, AddCostCategoryMutationVariables>(AddCostCategoryDocument, baseOptions);
      }
export type AddCostCategoryMutationHookResult = ReturnType<typeof useAddCostCategoryMutation>;
export type AddCostCategoryMutationResult = ApolloReactCommon.MutationResult<AddCostCategoryMutation>;
export type AddCostCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<AddCostCategoryMutation, AddCostCategoryMutationVariables>;
export const EditCostCategoryDocument = gql`
    mutation editCostCategory($input: TreeCostCategoryInput!) {
  editCostCategory(input: $input)
}
    `;
export type EditCostCategoryMutationFn = ApolloReactCommon.MutationFunction<EditCostCategoryMutation, EditCostCategoryMutationVariables>;

/**
 * __useEditCostCategoryMutation__
 *
 * To run a mutation, you first call `useEditCostCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCostCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCostCategoryMutation, { data, loading, error }] = useEditCostCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditCostCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditCostCategoryMutation, EditCostCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<EditCostCategoryMutation, EditCostCategoryMutationVariables>(EditCostCategoryDocument, baseOptions);
      }
export type EditCostCategoryMutationHookResult = ReturnType<typeof useEditCostCategoryMutation>;
export type EditCostCategoryMutationResult = ApolloReactCommon.MutationResult<EditCostCategoryMutation>;
export type EditCostCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<EditCostCategoryMutation, EditCostCategoryMutationVariables>;
export const DeleteTreeCostCategoryDocument = gql`
    mutation deleteTreeCostCategory($ID: String!) {
  deleteTreeCostCategory(ID: $ID)
}
    `;
export type DeleteTreeCostCategoryMutationFn = ApolloReactCommon.MutationFunction<DeleteTreeCostCategoryMutation, DeleteTreeCostCategoryMutationVariables>;

/**
 * __useDeleteTreeCostCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteTreeCostCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTreeCostCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTreeCostCategoryMutation, { data, loading, error }] = useDeleteTreeCostCategoryMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteTreeCostCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTreeCostCategoryMutation, DeleteTreeCostCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteTreeCostCategoryMutation, DeleteTreeCostCategoryMutationVariables>(DeleteTreeCostCategoryDocument, baseOptions);
      }
export type DeleteTreeCostCategoryMutationHookResult = ReturnType<typeof useDeleteTreeCostCategoryMutation>;
export type DeleteTreeCostCategoryMutationResult = ApolloReactCommon.MutationResult<DeleteTreeCostCategoryMutation>;
export type DeleteTreeCostCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTreeCostCategoryMutation, DeleteTreeCostCategoryMutationVariables>;
export const CostCategoryImportValidationDocument = gql`
    mutation costCategoryImportValidation($CostCategoryImportInput: [CostCategoryImportInput!]!) {
  costCategoryImportValidation(CostCategoryImportInput: $CostCategoryImportInput)
}
    `;
export type CostCategoryImportValidationMutationFn = ApolloReactCommon.MutationFunction<CostCategoryImportValidationMutation, CostCategoryImportValidationMutationVariables>;

/**
 * __useCostCategoryImportValidationMutation__
 *
 * To run a mutation, you first call `useCostCategoryImportValidationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCostCategoryImportValidationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [costCategoryImportValidationMutation, { data, loading, error }] = useCostCategoryImportValidationMutation({
 *   variables: {
 *      CostCategoryImportInput: // value for 'CostCategoryImportInput'
 *   },
 * });
 */
export function useCostCategoryImportValidationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CostCategoryImportValidationMutation, CostCategoryImportValidationMutationVariables>) {
        return ApolloReactHooks.useMutation<CostCategoryImportValidationMutation, CostCategoryImportValidationMutationVariables>(CostCategoryImportValidationDocument, baseOptions);
      }
export type CostCategoryImportValidationMutationHookResult = ReturnType<typeof useCostCategoryImportValidationMutation>;
export type CostCategoryImportValidationMutationResult = ApolloReactCommon.MutationResult<CostCategoryImportValidationMutation>;
export type CostCategoryImportValidationMutationOptions = ApolloReactCommon.BaseMutationOptions<CostCategoryImportValidationMutation, CostCategoryImportValidationMutationVariables>;
export const CostCategoryImportDocument = gql`
    mutation costCategoryImport($batchID: String!) {
  costCategoryImport(batchID: $batchID)
}
    `;
export type CostCategoryImportMutationFn = ApolloReactCommon.MutationFunction<CostCategoryImportMutation, CostCategoryImportMutationVariables>;

/**
 * __useCostCategoryImportMutation__
 *
 * To run a mutation, you first call `useCostCategoryImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCostCategoryImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [costCategoryImportMutation, { data, loading, error }] = useCostCategoryImportMutation({
 *   variables: {
 *      batchID: // value for 'batchID'
 *   },
 * });
 */
export function useCostCategoryImportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CostCategoryImportMutation, CostCategoryImportMutationVariables>) {
        return ApolloReactHooks.useMutation<CostCategoryImportMutation, CostCategoryImportMutationVariables>(CostCategoryImportDocument, baseOptions);
      }
export type CostCategoryImportMutationHookResult = ReturnType<typeof useCostCategoryImportMutation>;
export type CostCategoryImportMutationResult = ApolloReactCommon.MutationResult<CostCategoryImportMutation>;
export type CostCategoryImportMutationOptions = ApolloReactCommon.BaseMutationOptions<CostCategoryImportMutation, CostCategoryImportMutationVariables>;
export const GetCostCenterDocument = gql`
    query getCostCenter($ID: String, $orderByAsc: String, $orderByDesc: String, $code: String, $status: CostCenterType) {
  getCostCenter(ID: $ID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, status: $status, code: $code) {
    createdTs
    createdBy
    modTs
    modBy
    ID
    accountID
    code
    description
    costCenterType
    commonStatus
  }
}
    `;

/**
 * __useGetCostCenterQuery__
 *
 * To run a query within a React component, call `useGetCostCenterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCenterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCenterQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      code: // value for 'code'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetCostCenterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCenterQuery, GetCostCenterQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCenterQuery, GetCostCenterQueryVariables>(GetCostCenterDocument, baseOptions);
      }
export function useGetCostCenterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCenterQuery, GetCostCenterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCenterQuery, GetCostCenterQueryVariables>(GetCostCenterDocument, baseOptions);
        }
export type GetCostCenterQueryHookResult = ReturnType<typeof useGetCostCenterQuery>;
export type GetCostCenterLazyQueryHookResult = ReturnType<typeof useGetCostCenterLazyQuery>;
export type GetCostCenterQueryResult = ApolloReactCommon.QueryResult<GetCostCenterQuery, GetCostCenterQueryVariables>;
export const CreateCostCenterDocument = gql`
    mutation createCostCenter($input: CostCenterInput!) {
  createCostCenter(input: $input) {
    ID
    code
    description
  }
}
    `;
export type CreateCostCenterMutationFn = ApolloReactCommon.MutationFunction<CreateCostCenterMutation, CreateCostCenterMutationVariables>;

/**
 * __useCreateCostCenterMutation__
 *
 * To run a mutation, you first call `useCreateCostCenterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCostCenterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCostCenterMutation, { data, loading, error }] = useCreateCostCenterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCostCenterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCostCenterMutation, CreateCostCenterMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCostCenterMutation, CreateCostCenterMutationVariables>(CreateCostCenterDocument, baseOptions);
      }
export type CreateCostCenterMutationHookResult = ReturnType<typeof useCreateCostCenterMutation>;
export type CreateCostCenterMutationResult = ApolloReactCommon.MutationResult<CreateCostCenterMutation>;
export type CreateCostCenterMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCostCenterMutation, CreateCostCenterMutationVariables>;
export const UpdateCostCenterDocument = gql`
    mutation updateCostCenter($input: CostCenterInput!) {
  updateCostCenter(input: $input) {
    ID
    code
  }
}
    `;
export type UpdateCostCenterMutationFn = ApolloReactCommon.MutationFunction<UpdateCostCenterMutation, UpdateCostCenterMutationVariables>;

/**
 * __useUpdateCostCenterMutation__
 *
 * To run a mutation, you first call `useUpdateCostCenterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCostCenterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCostCenterMutation, { data, loading, error }] = useUpdateCostCenterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCostCenterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCostCenterMutation, UpdateCostCenterMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCostCenterMutation, UpdateCostCenterMutationVariables>(UpdateCostCenterDocument, baseOptions);
      }
export type UpdateCostCenterMutationHookResult = ReturnType<typeof useUpdateCostCenterMutation>;
export type UpdateCostCenterMutationResult = ApolloReactCommon.MutationResult<UpdateCostCenterMutation>;
export type UpdateCostCenterMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCostCenterMutation, UpdateCostCenterMutationVariables>;
export const DeleteCostCenterDocument = gql`
    mutation deleteCostCenter($ID: CostCenterDeleteInput!) {
  deleteCostCenter(input: $ID)
}
    `;
export type DeleteCostCenterMutationFn = ApolloReactCommon.MutationFunction<DeleteCostCenterMutation, DeleteCostCenterMutationVariables>;

/**
 * __useDeleteCostCenterMutation__
 *
 * To run a mutation, you first call `useDeleteCostCenterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCostCenterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCostCenterMutation, { data, loading, error }] = useDeleteCostCenterMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteCostCenterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCostCenterMutation, DeleteCostCenterMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCostCenterMutation, DeleteCostCenterMutationVariables>(DeleteCostCenterDocument, baseOptions);
      }
export type DeleteCostCenterMutationHookResult = ReturnType<typeof useDeleteCostCenterMutation>;
export type DeleteCostCenterMutationResult = ApolloReactCommon.MutationResult<DeleteCostCenterMutation>;
export type DeleteCostCenterMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCostCenterMutation, DeleteCostCenterMutationVariables>;
export const GetCostCodeDocument = gql`
    query getCostCode {
  getCostCode {
    ID
    parentCostCodeID
    code
    name
    level
    isLastNode
  }
}
    `;

/**
 * __useGetCostCodeQuery__
 *
 * To run a query within a React component, call `useGetCostCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCodeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCostCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCodeQuery, GetCostCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCodeQuery, GetCostCodeQueryVariables>(GetCostCodeDocument, baseOptions);
      }
export function useGetCostCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCodeQuery, GetCostCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCodeQuery, GetCostCodeQueryVariables>(GetCostCodeDocument, baseOptions);
        }
export type GetCostCodeQueryHookResult = ReturnType<typeof useGetCostCodeQuery>;
export type GetCostCodeLazyQueryHookResult = ReturnType<typeof useGetCostCodeLazyQuery>;
export type GetCostCodeQueryResult = ApolloReactCommon.QueryResult<GetCostCodeQuery, GetCostCodeQueryVariables>;
export const GetCostItemDocument = gql`
    query getCostItem($ID: String, $costCategoryID: String, $commonStatus: CommonStatus, $orderByAsc: String, $orderByDesc: String, $isInventory: Boolean) {
  getCostItem(ID: $ID, costCategoryID: $costCategoryID, commonStatus: $commonStatus, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, isInventory: $isInventory) {
    createdTs
    createdBy
    modTs
    modBy
    ID
    uomID
    accountID
    taxSchemeID
    costCategoryID
    variancePerc
    commonStatus
    name
    code
    unitPrice
    onBehalf
    isDaywork
    isInventory
    markupPerc
    UOM {
      ID
      name
      code
    }
    costCategory {
      ID
      name
    }
  }
}
    `;

/**
 * __useGetCostItemQuery__
 *
 * To run a query within a React component, call `useGetCostItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostItemQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      costCategoryID: // value for 'costCategoryID'
 *      commonStatus: // value for 'commonStatus'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      isInventory: // value for 'isInventory'
 *   },
 * });
 */
export function useGetCostItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostItemQuery, GetCostItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostItemQuery, GetCostItemQueryVariables>(GetCostItemDocument, baseOptions);
      }
export function useGetCostItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostItemQuery, GetCostItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostItemQuery, GetCostItemQueryVariables>(GetCostItemDocument, baseOptions);
        }
export type GetCostItemQueryHookResult = ReturnType<typeof useGetCostItemQuery>;
export type GetCostItemLazyQueryHookResult = ReturnType<typeof useGetCostItemLazyQuery>;
export type GetCostItemQueryResult = ApolloReactCommon.QueryResult<GetCostItemQuery, GetCostItemQueryVariables>;
export const GetCostItemWithCostCategoryDocument = gql`
    query getCostItemWithCostCategory($ID: String, $costCategoryID: String, $orderByAsc: String, $orderByDesc: String) {
  getCostItem(ID: $ID, costCategoryID: $costCategoryID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    createdTs
    createdBy
    modTs
    modBy
    ID
    uomID
    accountID
    taxSchemeID
    costCategoryID
    variancePerc
    commonStatus
    name
    code
    unitPrice
    onBehalf
    isDaywork
    isInventory
    markupPerc
    UOM {
      ID
      name
      code
    }
    costCategory {
      ID
      name
    }
  }
  getCostCategory(ID: $costCategoryID) {
    name
    supplier {
      name
      ID
    }
  }
}
    `;

/**
 * __useGetCostItemWithCostCategoryQuery__
 *
 * To run a query within a React component, call `useGetCostItemWithCostCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostItemWithCostCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostItemWithCostCategoryQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      costCategoryID: // value for 'costCategoryID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetCostItemWithCostCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostItemWithCostCategoryQuery, GetCostItemWithCostCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostItemWithCostCategoryQuery, GetCostItemWithCostCategoryQueryVariables>(GetCostItemWithCostCategoryDocument, baseOptions);
      }
export function useGetCostItemWithCostCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostItemWithCostCategoryQuery, GetCostItemWithCostCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostItemWithCostCategoryQuery, GetCostItemWithCostCategoryQueryVariables>(GetCostItemWithCostCategoryDocument, baseOptions);
        }
export type GetCostItemWithCostCategoryQueryHookResult = ReturnType<typeof useGetCostItemWithCostCategoryQuery>;
export type GetCostItemWithCostCategoryLazyQueryHookResult = ReturnType<typeof useGetCostItemWithCostCategoryLazyQuery>;
export type GetCostItemWithCostCategoryQueryResult = ApolloReactCommon.QueryResult<GetCostItemWithCostCategoryQuery, GetCostItemWithCostCategoryQueryVariables>;
export const CreateCostItemDocument = gql`
    mutation createCostItem($input: CostItemInput!) {
  createCostItem(input: $input) {
    ID
    name
  }
}
    `;
export type CreateCostItemMutationFn = ApolloReactCommon.MutationFunction<CreateCostItemMutation, CreateCostItemMutationVariables>;

/**
 * __useCreateCostItemMutation__
 *
 * To run a mutation, you first call `useCreateCostItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCostItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCostItemMutation, { data, loading, error }] = useCreateCostItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCostItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCostItemMutation, CreateCostItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCostItemMutation, CreateCostItemMutationVariables>(CreateCostItemDocument, baseOptions);
      }
export type CreateCostItemMutationHookResult = ReturnType<typeof useCreateCostItemMutation>;
export type CreateCostItemMutationResult = ApolloReactCommon.MutationResult<CreateCostItemMutation>;
export type CreateCostItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCostItemMutation, CreateCostItemMutationVariables>;
export const CancelCostItemDocument = gql`
    mutation cancelCostItem($ID: String!) {
  cancelCostItem(ID: $ID)
}
    `;
export type CancelCostItemMutationFn = ApolloReactCommon.MutationFunction<CancelCostItemMutation, CancelCostItemMutationVariables>;

/**
 * __useCancelCostItemMutation__
 *
 * To run a mutation, you first call `useCancelCostItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCostItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCostItemMutation, { data, loading, error }] = useCancelCostItemMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCancelCostItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelCostItemMutation, CancelCostItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelCostItemMutation, CancelCostItemMutationVariables>(CancelCostItemDocument, baseOptions);
      }
export type CancelCostItemMutationHookResult = ReturnType<typeof useCancelCostItemMutation>;
export type CancelCostItemMutationResult = ApolloReactCommon.MutationResult<CancelCostItemMutation>;
export type CancelCostItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelCostItemMutation, CancelCostItemMutationVariables>;
export const ActivateCostItemDocument = gql`
    mutation activateCostItem($input: CostItemDeleteInput!) {
  activateCostItem(input: $input)
}
    `;
export type ActivateCostItemMutationFn = ApolloReactCommon.MutationFunction<ActivateCostItemMutation, ActivateCostItemMutationVariables>;

/**
 * __useActivateCostItemMutation__
 *
 * To run a mutation, you first call `useActivateCostItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateCostItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateCostItemMutation, { data, loading, error }] = useActivateCostItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateCostItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateCostItemMutation, ActivateCostItemMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateCostItemMutation, ActivateCostItemMutationVariables>(ActivateCostItemDocument, baseOptions);
      }
export type ActivateCostItemMutationHookResult = ReturnType<typeof useActivateCostItemMutation>;
export type ActivateCostItemMutationResult = ApolloReactCommon.MutationResult<ActivateCostItemMutation>;
export type ActivateCostItemMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateCostItemMutation, ActivateCostItemMutationVariables>;
export const DeleteCostItemDocument = gql`
    mutation deleteCostItem($input: CostItemDeleteInput!) {
  deleteCostItem(input: $input)
}
    `;
export type DeleteCostItemMutationFn = ApolloReactCommon.MutationFunction<DeleteCostItemMutation, DeleteCostItemMutationVariables>;

/**
 * __useDeleteCostItemMutation__
 *
 * To run a mutation, you first call `useDeleteCostItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCostItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCostItemMutation, { data, loading, error }] = useDeleteCostItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCostItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCostItemMutation, DeleteCostItemMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCostItemMutation, DeleteCostItemMutationVariables>(DeleteCostItemDocument, baseOptions);
      }
export type DeleteCostItemMutationHookResult = ReturnType<typeof useDeleteCostItemMutation>;
export type DeleteCostItemMutationResult = ApolloReactCommon.MutationResult<DeleteCostItemMutation>;
export type DeleteCostItemMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCostItemMutation, DeleteCostItemMutationVariables>;
export const UpdateCostItemDocument = gql`
    mutation updateCostItem($input: CostItemInput!) {
  updateCostItem(input: $input) {
    ID
    name
  }
}
    `;
export type UpdateCostItemMutationFn = ApolloReactCommon.MutationFunction<UpdateCostItemMutation, UpdateCostItemMutationVariables>;

/**
 * __useUpdateCostItemMutation__
 *
 * To run a mutation, you first call `useUpdateCostItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCostItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCostItemMutation, { data, loading, error }] = useUpdateCostItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCostItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCostItemMutation, UpdateCostItemMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCostItemMutation, UpdateCostItemMutationVariables>(UpdateCostItemDocument, baseOptions);
      }
export type UpdateCostItemMutationHookResult = ReturnType<typeof useUpdateCostItemMutation>;
export type UpdateCostItemMutationResult = ApolloReactCommon.MutationResult<UpdateCostItemMutation>;
export type UpdateCostItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCostItemMutation, UpdateCostItemMutationVariables>;
export const CreateBatchCostItemDocument = gql`
    mutation createBatchCostItem($input: [CostItemInput!]!, $costCategoryID: String!) {
  createBatchCostItem(input: $input, costCategoryID: $costCategoryID)
}
    `;
export type CreateBatchCostItemMutationFn = ApolloReactCommon.MutationFunction<CreateBatchCostItemMutation, CreateBatchCostItemMutationVariables>;

/**
 * __useCreateBatchCostItemMutation__
 *
 * To run a mutation, you first call `useCreateBatchCostItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBatchCostItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBatchCostItemMutation, { data, loading, error }] = useCreateBatchCostItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useCreateBatchCostItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBatchCostItemMutation, CreateBatchCostItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBatchCostItemMutation, CreateBatchCostItemMutationVariables>(CreateBatchCostItemDocument, baseOptions);
      }
export type CreateBatchCostItemMutationHookResult = ReturnType<typeof useCreateBatchCostItemMutation>;
export type CreateBatchCostItemMutationResult = ApolloReactCommon.MutationResult<CreateBatchCostItemMutation>;
export type CreateBatchCostItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBatchCostItemMutation, CreateBatchCostItemMutationVariables>;
export const GetCostItemBySupplierDocument = gql`
    query getCostItemBySupplier($supplierID: String!, $take: Float, $skip: Float, $searchValue: String) {
  getCostItemBySupplier(supplierID: $supplierID, take: $take, skip: $skip, searchValue: $searchValue) {
    createdTs
    createdBy
    modTs
    modBy
    ID
    accountID
    taxSchemeID
    costCategoryID
    variancePerc
    commonStatus
    name
    code
    unitPrice
    onBehalf
    markupPerc
    UOM {
      ID
      name
      code
    }
    costCategory {
      ID
      name
    }
  }
}
    `;

/**
 * __useGetCostItemBySupplierQuery__
 *
 * To run a query within a React component, call `useGetCostItemBySupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostItemBySupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostItemBySupplierQuery({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetCostItemBySupplierQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostItemBySupplierQuery, GetCostItemBySupplierQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostItemBySupplierQuery, GetCostItemBySupplierQueryVariables>(GetCostItemBySupplierDocument, baseOptions);
      }
export function useGetCostItemBySupplierLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostItemBySupplierQuery, GetCostItemBySupplierQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostItemBySupplierQuery, GetCostItemBySupplierQueryVariables>(GetCostItemBySupplierDocument, baseOptions);
        }
export type GetCostItemBySupplierQueryHookResult = ReturnType<typeof useGetCostItemBySupplierQuery>;
export type GetCostItemBySupplierLazyQueryHookResult = ReturnType<typeof useGetCostItemBySupplierLazyQuery>;
export type GetCostItemBySupplierQueryResult = ApolloReactCommon.QueryResult<GetCostItemBySupplierQuery, GetCostItemBySupplierQueryVariables>;
export const GetCostItemByWbsDocument = gql`
    query getCostItemByWbs($wbsID: String!, $contractID: String!) {
  getCostItemByWbs(wbsID: $wbsID, contractID: $contractID)
}
    `;

/**
 * __useGetCostItemByWbsQuery__
 *
 * To run a query within a React component, call `useGetCostItemByWbsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostItemByWbsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostItemByWbsQuery({
 *   variables: {
 *      wbsID: // value for 'wbsID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetCostItemByWbsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostItemByWbsQuery, GetCostItemByWbsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostItemByWbsQuery, GetCostItemByWbsQueryVariables>(GetCostItemByWbsDocument, baseOptions);
      }
export function useGetCostItemByWbsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostItemByWbsQuery, GetCostItemByWbsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostItemByWbsQuery, GetCostItemByWbsQueryVariables>(GetCostItemByWbsDocument, baseOptions);
        }
export type GetCostItemByWbsQueryHookResult = ReturnType<typeof useGetCostItemByWbsQuery>;
export type GetCostItemByWbsLazyQueryHookResult = ReturnType<typeof useGetCostItemByWbsLazyQuery>;
export type GetCostItemByWbsQueryResult = ApolloReactCommon.QueryResult<GetCostItemByWbsQuery, GetCostItemByWbsQueryVariables>;
export const GetCostItemByWbsIDsDocument = gql`
    query getCostItemByWbsIDs($wbsIDs: [String!]!, $contractID: String!) {
  getCostItemByWbsIDs(wbsIDs: $wbsIDs, contractID: $contractID)
}
    `;

/**
 * __useGetCostItemByWbsIDsQuery__
 *
 * To run a query within a React component, call `useGetCostItemByWbsIDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostItemByWbsIDsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostItemByWbsIDsQuery({
 *   variables: {
 *      wbsIDs: // value for 'wbsIDs'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetCostItemByWbsIDsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostItemByWbsIDsQuery, GetCostItemByWbsIDsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostItemByWbsIDsQuery, GetCostItemByWbsIDsQueryVariables>(GetCostItemByWbsIDsDocument, baseOptions);
      }
export function useGetCostItemByWbsIDsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostItemByWbsIDsQuery, GetCostItemByWbsIDsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostItemByWbsIDsQuery, GetCostItemByWbsIDsQueryVariables>(GetCostItemByWbsIDsDocument, baseOptions);
        }
export type GetCostItemByWbsIDsQueryHookResult = ReturnType<typeof useGetCostItemByWbsIDsQuery>;
export type GetCostItemByWbsIDsLazyQueryHookResult = ReturnType<typeof useGetCostItemByWbsIDsLazyQuery>;
export type GetCostItemByWbsIDsQueryResult = ApolloReactCommon.QueryResult<GetCostItemByWbsIDsQuery, GetCostItemByWbsIDsQueryVariables>;
export const GetCostCategoryItemDocument = gql`
    query getCostCategoryItem {
  getCostCategoryItem
}
    `;

/**
 * __useGetCostCategoryItemQuery__
 *
 * To run a query within a React component, call `useGetCostCategoryItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCategoryItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCategoryItemQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCostCategoryItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCategoryItemQuery, GetCostCategoryItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCategoryItemQuery, GetCostCategoryItemQueryVariables>(GetCostCategoryItemDocument, baseOptions);
      }
export function useGetCostCategoryItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCategoryItemQuery, GetCostCategoryItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCategoryItemQuery, GetCostCategoryItemQueryVariables>(GetCostCategoryItemDocument, baseOptions);
        }
export type GetCostCategoryItemQueryHookResult = ReturnType<typeof useGetCostCategoryItemQuery>;
export type GetCostCategoryItemLazyQueryHookResult = ReturnType<typeof useGetCostCategoryItemLazyQuery>;
export type GetCostCategoryItemQueryResult = ApolloReactCommon.QueryResult<GetCostCategoryItemQuery, GetCostCategoryItemQueryVariables>;
export const CostItemImportValidationDocument = gql`
    mutation costItemImportValidation($CostItemImportInput: [CostItemImportInput!]!) {
  costItemImportValidation(CostItemImportInput: $CostItemImportInput)
}
    `;
export type CostItemImportValidationMutationFn = ApolloReactCommon.MutationFunction<CostItemImportValidationMutation, CostItemImportValidationMutationVariables>;

/**
 * __useCostItemImportValidationMutation__
 *
 * To run a mutation, you first call `useCostItemImportValidationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCostItemImportValidationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [costItemImportValidationMutation, { data, loading, error }] = useCostItemImportValidationMutation({
 *   variables: {
 *      CostItemImportInput: // value for 'CostItemImportInput'
 *   },
 * });
 */
export function useCostItemImportValidationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CostItemImportValidationMutation, CostItemImportValidationMutationVariables>) {
        return ApolloReactHooks.useMutation<CostItemImportValidationMutation, CostItemImportValidationMutationVariables>(CostItemImportValidationDocument, baseOptions);
      }
export type CostItemImportValidationMutationHookResult = ReturnType<typeof useCostItemImportValidationMutation>;
export type CostItemImportValidationMutationResult = ApolloReactCommon.MutationResult<CostItemImportValidationMutation>;
export type CostItemImportValidationMutationOptions = ApolloReactCommon.BaseMutationOptions<CostItemImportValidationMutation, CostItemImportValidationMutationVariables>;
export const CostItemImportDocument = gql`
    mutation costItemImport($batchID: String!) {
  costItemImport(batchID: $batchID)
}
    `;
export type CostItemImportMutationFn = ApolloReactCommon.MutationFunction<CostItemImportMutation, CostItemImportMutationVariables>;

/**
 * __useCostItemImportMutation__
 *
 * To run a mutation, you first call `useCostItemImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCostItemImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [costItemImportMutation, { data, loading, error }] = useCostItemImportMutation({
 *   variables: {
 *      batchID: // value for 'batchID'
 *   },
 * });
 */
export function useCostItemImportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CostItemImportMutation, CostItemImportMutationVariables>) {
        return ApolloReactHooks.useMutation<CostItemImportMutation, CostItemImportMutationVariables>(CostItemImportDocument, baseOptions);
      }
export type CostItemImportMutationHookResult = ReturnType<typeof useCostItemImportMutation>;
export type CostItemImportMutationResult = ApolloReactCommon.MutationResult<CostItemImportMutation>;
export type CostItemImportMutationOptions = ApolloReactCommon.BaseMutationOptions<CostItemImportMutation, CostItemImportMutationVariables>;
export const SaveGlAccountCodeDocument = gql`
    mutation saveGLAccountCode($glInterfaceInput: [GLInterfaceDynamicInput!]!, $accountType: String!) {
  saveGLAccountCode(glInterfaceInput: $glInterfaceInput, accountType: $accountType)
}
    `;
export type SaveGlAccountCodeMutationFn = ApolloReactCommon.MutationFunction<SaveGlAccountCodeMutation, SaveGlAccountCodeMutationVariables>;

/**
 * __useSaveGlAccountCodeMutation__
 *
 * To run a mutation, you first call `useSaveGlAccountCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveGlAccountCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveGlAccountCodeMutation, { data, loading, error }] = useSaveGlAccountCodeMutation({
 *   variables: {
 *      glInterfaceInput: // value for 'glInterfaceInput'
 *      accountType: // value for 'accountType'
 *   },
 * });
 */
export function useSaveGlAccountCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveGlAccountCodeMutation, SaveGlAccountCodeMutationVariables>) {
        return ApolloReactHooks.useMutation<SaveGlAccountCodeMutation, SaveGlAccountCodeMutationVariables>(SaveGlAccountCodeDocument, baseOptions);
      }
export type SaveGlAccountCodeMutationHookResult = ReturnType<typeof useSaveGlAccountCodeMutation>;
export type SaveGlAccountCodeMutationResult = ApolloReactCommon.MutationResult<SaveGlAccountCodeMutation>;
export type SaveGlAccountCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveGlAccountCodeMutation, SaveGlAccountCodeMutationVariables>;
export const GetGlFileSetupDocument = gql`
    query getGLFileSetup($accountType: String!) {
  getGLSetupFile(accountType: $accountType)
}
    `;

/**
 * __useGetGlFileSetupQuery__
 *
 * To run a query within a React component, call `useGetGlFileSetupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlFileSetupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlFileSetupQuery({
 *   variables: {
 *      accountType: // value for 'accountType'
 *   },
 * });
 */
export function useGetGlFileSetupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGlFileSetupQuery, GetGlFileSetupQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGlFileSetupQuery, GetGlFileSetupQueryVariables>(GetGlFileSetupDocument, baseOptions);
      }
export function useGetGlFileSetupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGlFileSetupQuery, GetGlFileSetupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGlFileSetupQuery, GetGlFileSetupQueryVariables>(GetGlFileSetupDocument, baseOptions);
        }
export type GetGlFileSetupQueryHookResult = ReturnType<typeof useGetGlFileSetupQuery>;
export type GetGlFileSetupLazyQueryHookResult = ReturnType<typeof useGetGlFileSetupLazyQuery>;
export type GetGlFileSetupQueryResult = ApolloReactCommon.QueryResult<GetGlFileSetupQuery, GetGlFileSetupQueryVariables>;
export const CreateGlInterfaceDataDocument = gql`
    mutation createGLInterfaceData($accountType: String!, $input: [GLInterfaceDetailInput!]!) {
  createGLInterfaceData(accountType: $accountType, input: $input)
}
    `;
export type CreateGlInterfaceDataMutationFn = ApolloReactCommon.MutationFunction<CreateGlInterfaceDataMutation, CreateGlInterfaceDataMutationVariables>;

/**
 * __useCreateGlInterfaceDataMutation__
 *
 * To run a mutation, you first call `useCreateGlInterfaceDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGlInterfaceDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGlInterfaceDataMutation, { data, loading, error }] = useCreateGlInterfaceDataMutation({
 *   variables: {
 *      accountType: // value for 'accountType'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGlInterfaceDataMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGlInterfaceDataMutation, CreateGlInterfaceDataMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGlInterfaceDataMutation, CreateGlInterfaceDataMutationVariables>(CreateGlInterfaceDataDocument, baseOptions);
      }
export type CreateGlInterfaceDataMutationHookResult = ReturnType<typeof useCreateGlInterfaceDataMutation>;
export type CreateGlInterfaceDataMutationResult = ApolloReactCommon.MutationResult<CreateGlInterfaceDataMutation>;
export type CreateGlInterfaceDataMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateGlInterfaceDataMutation, CreateGlInterfaceDataMutationVariables>;
export const CheckedGlEditableDocument = gql`
    query checkedGLEditable($type: String!) {
  checkedGLEditable(type: $type)
}
    `;

/**
 * __useCheckedGlEditableQuery__
 *
 * To run a query within a React component, call `useCheckedGlEditableQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckedGlEditableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckedGlEditableQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCheckedGlEditableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckedGlEditableQuery, CheckedGlEditableQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckedGlEditableQuery, CheckedGlEditableQueryVariables>(CheckedGlEditableDocument, baseOptions);
      }
export function useCheckedGlEditableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckedGlEditableQuery, CheckedGlEditableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckedGlEditableQuery, CheckedGlEditableQueryVariables>(CheckedGlEditableDocument, baseOptions);
        }
export type CheckedGlEditableQueryHookResult = ReturnType<typeof useCheckedGlEditableQuery>;
export type CheckedGlEditableLazyQueryHookResult = ReturnType<typeof useCheckedGlEditableLazyQuery>;
export type CheckedGlEditableQueryResult = ApolloReactCommon.QueryResult<CheckedGlEditableQuery, CheckedGlEditableQueryVariables>;
export const UpdateDepartmentGlAccountCodeDocument = gql`
    mutation updateDepartmentGLAccountCode($input: [DepartmentGLInterfaceInput!]!) {
  updateDepartmentGLAccountCode(input: $input)
}
    `;
export type UpdateDepartmentGlAccountCodeMutationFn = ApolloReactCommon.MutationFunction<UpdateDepartmentGlAccountCodeMutation, UpdateDepartmentGlAccountCodeMutationVariables>;

/**
 * __useUpdateDepartmentGlAccountCodeMutation__
 *
 * To run a mutation, you first call `useUpdateDepartmentGlAccountCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDepartmentGlAccountCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDepartmentGlAccountCodeMutation, { data, loading, error }] = useUpdateDepartmentGlAccountCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDepartmentGlAccountCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDepartmentGlAccountCodeMutation, UpdateDepartmentGlAccountCodeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDepartmentGlAccountCodeMutation, UpdateDepartmentGlAccountCodeMutationVariables>(UpdateDepartmentGlAccountCodeDocument, baseOptions);
      }
export type UpdateDepartmentGlAccountCodeMutationHookResult = ReturnType<typeof useUpdateDepartmentGlAccountCodeMutation>;
export type UpdateDepartmentGlAccountCodeMutationResult = ApolloReactCommon.MutationResult<UpdateDepartmentGlAccountCodeMutation>;
export type UpdateDepartmentGlAccountCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDepartmentGlAccountCodeMutation, UpdateDepartmentGlAccountCodeMutationVariables>;
export const GetGeneralSettingExecutiveSummaryDocument = gql`
    query getGeneralSettingExecutiveSummary {
  costCategoryDates
  guaranteeTypeDate
  insuranceTypeDate
  workshopDate
  paymentReminderDate
  uomDate
  calendarTypeDate
  holidayTypeDate
  jobTypeDate
  projectSiteDate
  publicHolidayDate
  siteMaterialDate
  wagesRatePolicyDate
  workingHourPolicyDate
  videoCamDate
}
    `;

/**
 * __useGetGeneralSettingExecutiveSummaryQuery__
 *
 * To run a query within a React component, call `useGetGeneralSettingExecutiveSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralSettingExecutiveSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralSettingExecutiveSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGeneralSettingExecutiveSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGeneralSettingExecutiveSummaryQuery, GetGeneralSettingExecutiveSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGeneralSettingExecutiveSummaryQuery, GetGeneralSettingExecutiveSummaryQueryVariables>(GetGeneralSettingExecutiveSummaryDocument, baseOptions);
      }
export function useGetGeneralSettingExecutiveSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGeneralSettingExecutiveSummaryQuery, GetGeneralSettingExecutiveSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGeneralSettingExecutiveSummaryQuery, GetGeneralSettingExecutiveSummaryQueryVariables>(GetGeneralSettingExecutiveSummaryDocument, baseOptions);
        }
export type GetGeneralSettingExecutiveSummaryQueryHookResult = ReturnType<typeof useGetGeneralSettingExecutiveSummaryQuery>;
export type GetGeneralSettingExecutiveSummaryLazyQueryHookResult = ReturnType<typeof useGetGeneralSettingExecutiveSummaryLazyQuery>;
export type GetGeneralSettingExecutiveSummaryQueryResult = ApolloReactCommon.QueryResult<GetGeneralSettingExecutiveSummaryQuery, GetGeneralSettingExecutiveSummaryQueryVariables>;
export const GetGeneralSettingSummaryDocument = gql`
    query getGeneralSettingSummary {
  costCategoryDates
  guaranteeTypeDate
  insuranceTypeDate
  workshopDate
  paymentReminderDate
}
    `;

/**
 * __useGetGeneralSettingSummaryQuery__
 *
 * To run a query within a React component, call `useGetGeneralSettingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralSettingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralSettingSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGeneralSettingSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGeneralSettingSummaryQuery, GetGeneralSettingSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGeneralSettingSummaryQuery, GetGeneralSettingSummaryQueryVariables>(GetGeneralSettingSummaryDocument, baseOptions);
      }
export function useGetGeneralSettingSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGeneralSettingSummaryQuery, GetGeneralSettingSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGeneralSettingSummaryQuery, GetGeneralSettingSummaryQueryVariables>(GetGeneralSettingSummaryDocument, baseOptions);
        }
export type GetGeneralSettingSummaryQueryHookResult = ReturnType<typeof useGetGeneralSettingSummaryQuery>;
export type GetGeneralSettingSummaryLazyQueryHookResult = ReturnType<typeof useGetGeneralSettingSummaryLazyQuery>;
export type GetGeneralSettingSummaryQueryResult = ApolloReactCommon.QueryResult<GetGeneralSettingSummaryQuery, GetGeneralSettingSummaryQueryVariables>;
export const GetContractSubcontractGenSettingsSummaryDocument = gql`
    query getContractSubcontractGenSettingsSummary {
  revenueCategoryDate
  guaranteeTypeDate
  insuranceTypeDate
}
    `;

/**
 * __useGetContractSubcontractGenSettingsSummaryQuery__
 *
 * To run a query within a React component, call `useGetContractSubcontractGenSettingsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractSubcontractGenSettingsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractSubcontractGenSettingsSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContractSubcontractGenSettingsSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractSubcontractGenSettingsSummaryQuery, GetContractSubcontractGenSettingsSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractSubcontractGenSettingsSummaryQuery, GetContractSubcontractGenSettingsSummaryQueryVariables>(GetContractSubcontractGenSettingsSummaryDocument, baseOptions);
      }
export function useGetContractSubcontractGenSettingsSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractSubcontractGenSettingsSummaryQuery, GetContractSubcontractGenSettingsSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractSubcontractGenSettingsSummaryQuery, GetContractSubcontractGenSettingsSummaryQueryVariables>(GetContractSubcontractGenSettingsSummaryDocument, baseOptions);
        }
export type GetContractSubcontractGenSettingsSummaryQueryHookResult = ReturnType<typeof useGetContractSubcontractGenSettingsSummaryQuery>;
export type GetContractSubcontractGenSettingsSummaryLazyQueryHookResult = ReturnType<typeof useGetContractSubcontractGenSettingsSummaryLazyQuery>;
export type GetContractSubcontractGenSettingsSummaryQueryResult = ApolloReactCommon.QueryResult<GetContractSubcontractGenSettingsSummaryQuery, GetContractSubcontractGenSettingsSummaryQueryVariables>;
export const GetClientAccGenSettingsSummaryDocument = gql`
    query getClientAccGenSettingsSummary {
  clientSetupDate
  paymentReminderDate
}
    `;

/**
 * __useGetClientAccGenSettingsSummaryQuery__
 *
 * To run a query within a React component, call `useGetClientAccGenSettingsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientAccGenSettingsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientAccGenSettingsSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClientAccGenSettingsSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClientAccGenSettingsSummaryQuery, GetClientAccGenSettingsSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClientAccGenSettingsSummaryQuery, GetClientAccGenSettingsSummaryQueryVariables>(GetClientAccGenSettingsSummaryDocument, baseOptions);
      }
export function useGetClientAccGenSettingsSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientAccGenSettingsSummaryQuery, GetClientAccGenSettingsSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClientAccGenSettingsSummaryQuery, GetClientAccGenSettingsSummaryQueryVariables>(GetClientAccGenSettingsSummaryDocument, baseOptions);
        }
export type GetClientAccGenSettingsSummaryQueryHookResult = ReturnType<typeof useGetClientAccGenSettingsSummaryQuery>;
export type GetClientAccGenSettingsSummaryLazyQueryHookResult = ReturnType<typeof useGetClientAccGenSettingsSummaryLazyQuery>;
export type GetClientAccGenSettingsSummaryQueryResult = ApolloReactCommon.QueryResult<GetClientAccGenSettingsSummaryQuery, GetClientAccGenSettingsSummaryQueryVariables>;
export const GetSubcontractorGenSettingsSummaryDocument = gql`
    query getSubcontractorGenSettingsSummary {
  subcontractorTypeDate
  subconSetupDate
}
    `;

/**
 * __useGetSubcontractorGenSettingsSummaryQuery__
 *
 * To run a query within a React component, call `useGetSubcontractorGenSettingsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractorGenSettingsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractorGenSettingsSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubcontractorGenSettingsSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractorGenSettingsSummaryQuery, GetSubcontractorGenSettingsSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractorGenSettingsSummaryQuery, GetSubcontractorGenSettingsSummaryQueryVariables>(GetSubcontractorGenSettingsSummaryDocument, baseOptions);
      }
export function useGetSubcontractorGenSettingsSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractorGenSettingsSummaryQuery, GetSubcontractorGenSettingsSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractorGenSettingsSummaryQuery, GetSubcontractorGenSettingsSummaryQueryVariables>(GetSubcontractorGenSettingsSummaryDocument, baseOptions);
        }
export type GetSubcontractorGenSettingsSummaryQueryHookResult = ReturnType<typeof useGetSubcontractorGenSettingsSummaryQuery>;
export type GetSubcontractorGenSettingsSummaryLazyQueryHookResult = ReturnType<typeof useGetSubcontractorGenSettingsSummaryLazyQuery>;
export type GetSubcontractorGenSettingsSummaryQueryResult = ApolloReactCommon.QueryResult<GetSubcontractorGenSettingsSummaryQuery, GetSubcontractorGenSettingsSummaryQueryVariables>;
export const GetSupplierGenSettingsSummaryDocument = gql`
    query getSupplierGenSettingsSummary {
  supplierTypeDate
  supplierSetupDate
}
    `;

/**
 * __useGetSupplierGenSettingsSummaryQuery__
 *
 * To run a query within a React component, call `useGetSupplierGenSettingsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierGenSettingsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierGenSettingsSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSupplierGenSettingsSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierGenSettingsSummaryQuery, GetSupplierGenSettingsSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierGenSettingsSummaryQuery, GetSupplierGenSettingsSummaryQueryVariables>(GetSupplierGenSettingsSummaryDocument, baseOptions);
      }
export function useGetSupplierGenSettingsSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierGenSettingsSummaryQuery, GetSupplierGenSettingsSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierGenSettingsSummaryQuery, GetSupplierGenSettingsSummaryQueryVariables>(GetSupplierGenSettingsSummaryDocument, baseOptions);
        }
export type GetSupplierGenSettingsSummaryQueryHookResult = ReturnType<typeof useGetSupplierGenSettingsSummaryQuery>;
export type GetSupplierGenSettingsSummaryLazyQueryHookResult = ReturnType<typeof useGetSupplierGenSettingsSummaryLazyQuery>;
export type GetSupplierGenSettingsSummaryQueryResult = ApolloReactCommon.QueryResult<GetSupplierGenSettingsSummaryQuery, GetSupplierGenSettingsSummaryQueryVariables>;
export const GetPnmGenSettingsSummaryDocument = gql`
    query getPNMGenSettingsSummary {
  workshopDate
}
    `;

/**
 * __useGetPnmGenSettingsSummaryQuery__
 *
 * To run a query within a React component, call `useGetPnmGenSettingsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPnmGenSettingsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPnmGenSettingsSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPnmGenSettingsSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPnmGenSettingsSummaryQuery, GetPnmGenSettingsSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPnmGenSettingsSummaryQuery, GetPnmGenSettingsSummaryQueryVariables>(GetPnmGenSettingsSummaryDocument, baseOptions);
      }
export function useGetPnmGenSettingsSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPnmGenSettingsSummaryQuery, GetPnmGenSettingsSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPnmGenSettingsSummaryQuery, GetPnmGenSettingsSummaryQueryVariables>(GetPnmGenSettingsSummaryDocument, baseOptions);
        }
export type GetPnmGenSettingsSummaryQueryHookResult = ReturnType<typeof useGetPnmGenSettingsSummaryQuery>;
export type GetPnmGenSettingsSummaryLazyQueryHookResult = ReturnType<typeof useGetPnmGenSettingsSummaryLazyQuery>;
export type GetPnmGenSettingsSummaryQueryResult = ApolloReactCommon.QueryResult<GetPnmGenSettingsSummaryQuery, GetPnmGenSettingsSummaryQueryVariables>;
export const GetPurchaseCostingGenSettingsSummaryDocument = gql`
    query getPurchaseCostingGenSettingsSummary {
  costCategoryDate
  costItemDates
}
    `;

/**
 * __useGetPurchaseCostingGenSettingsSummaryQuery__
 *
 * To run a query within a React component, call `useGetPurchaseCostingGenSettingsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseCostingGenSettingsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseCostingGenSettingsSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPurchaseCostingGenSettingsSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPurchaseCostingGenSettingsSummaryQuery, GetPurchaseCostingGenSettingsSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPurchaseCostingGenSettingsSummaryQuery, GetPurchaseCostingGenSettingsSummaryQueryVariables>(GetPurchaseCostingGenSettingsSummaryDocument, baseOptions);
      }
export function useGetPurchaseCostingGenSettingsSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPurchaseCostingGenSettingsSummaryQuery, GetPurchaseCostingGenSettingsSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPurchaseCostingGenSettingsSummaryQuery, GetPurchaseCostingGenSettingsSummaryQueryVariables>(GetPurchaseCostingGenSettingsSummaryDocument, baseOptions);
        }
export type GetPurchaseCostingGenSettingsSummaryQueryHookResult = ReturnType<typeof useGetPurchaseCostingGenSettingsSummaryQuery>;
export type GetPurchaseCostingGenSettingsSummaryLazyQueryHookResult = ReturnType<typeof useGetPurchaseCostingGenSettingsSummaryLazyQuery>;
export type GetPurchaseCostingGenSettingsSummaryQueryResult = ApolloReactCommon.QueryResult<GetPurchaseCostingGenSettingsSummaryQuery, GetPurchaseCostingGenSettingsSummaryQueryVariables>;
export const GetCostCategoryDatesDocument = gql`
    query getCostCategoryDates {
  costCategoryDates
}
    `;

/**
 * __useGetCostCategoryDatesQuery__
 *
 * To run a query within a React component, call `useGetCostCategoryDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCategoryDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCategoryDatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCostCategoryDatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCategoryDatesQuery, GetCostCategoryDatesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCategoryDatesQuery, GetCostCategoryDatesQueryVariables>(GetCostCategoryDatesDocument, baseOptions);
      }
export function useGetCostCategoryDatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCategoryDatesQuery, GetCostCategoryDatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCategoryDatesQuery, GetCostCategoryDatesQueryVariables>(GetCostCategoryDatesDocument, baseOptions);
        }
export type GetCostCategoryDatesQueryHookResult = ReturnType<typeof useGetCostCategoryDatesQuery>;
export type GetCostCategoryDatesLazyQueryHookResult = ReturnType<typeof useGetCostCategoryDatesLazyQuery>;
export type GetCostCategoryDatesQueryResult = ApolloReactCommon.QueryResult<GetCostCategoryDatesQuery, GetCostCategoryDatesQueryVariables>;
export const GetGlAccountCodeGenSettingsSummaryDocument = gql`
    query getGLAccountCodeGenSettingsSummary {
  glAccountCodeDate
}
    `;

/**
 * __useGetGlAccountCodeGenSettingsSummaryQuery__
 *
 * To run a query within a React component, call `useGetGlAccountCodeGenSettingsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlAccountCodeGenSettingsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlAccountCodeGenSettingsSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGlAccountCodeGenSettingsSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGlAccountCodeGenSettingsSummaryQuery, GetGlAccountCodeGenSettingsSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGlAccountCodeGenSettingsSummaryQuery, GetGlAccountCodeGenSettingsSummaryQueryVariables>(GetGlAccountCodeGenSettingsSummaryDocument, baseOptions);
      }
export function useGetGlAccountCodeGenSettingsSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGlAccountCodeGenSettingsSummaryQuery, GetGlAccountCodeGenSettingsSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGlAccountCodeGenSettingsSummaryQuery, GetGlAccountCodeGenSettingsSummaryQueryVariables>(GetGlAccountCodeGenSettingsSummaryDocument, baseOptions);
        }
export type GetGlAccountCodeGenSettingsSummaryQueryHookResult = ReturnType<typeof useGetGlAccountCodeGenSettingsSummaryQuery>;
export type GetGlAccountCodeGenSettingsSummaryLazyQueryHookResult = ReturnType<typeof useGetGlAccountCodeGenSettingsSummaryLazyQuery>;
export type GetGlAccountCodeGenSettingsSummaryQueryResult = ApolloReactCommon.QueryResult<GetGlAccountCodeGenSettingsSummaryQuery, GetGlAccountCodeGenSettingsSummaryQueryVariables>;
export const GetSiteGeneralSettingSummaryDocument = gql`
    query getSiteGeneralSettingSummary {
  loggedInUserProfile {
    ID
    accountID
  }
  costCategoryDates
  calendarTypeDate
  holidayTypeDate
  jobTypeDate
  projectSiteDate
  publicHolidayDate
  siteMaterialDate
  wagesRatePolicyDate
  workingHourPolicyDate
  videoCamDate
}
    `;

/**
 * __useGetSiteGeneralSettingSummaryQuery__
 *
 * To run a query within a React component, call `useGetSiteGeneralSettingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteGeneralSettingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteGeneralSettingSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSiteGeneralSettingSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteGeneralSettingSummaryQuery, GetSiteGeneralSettingSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteGeneralSettingSummaryQuery, GetSiteGeneralSettingSummaryQueryVariables>(GetSiteGeneralSettingSummaryDocument, baseOptions);
      }
export function useGetSiteGeneralSettingSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteGeneralSettingSummaryQuery, GetSiteGeneralSettingSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteGeneralSettingSummaryQuery, GetSiteGeneralSettingSummaryQueryVariables>(GetSiteGeneralSettingSummaryDocument, baseOptions);
        }
export type GetSiteGeneralSettingSummaryQueryHookResult = ReturnType<typeof useGetSiteGeneralSettingSummaryQuery>;
export type GetSiteGeneralSettingSummaryLazyQueryHookResult = ReturnType<typeof useGetSiteGeneralSettingSummaryLazyQuery>;
export type GetSiteGeneralSettingSummaryQueryResult = ApolloReactCommon.QueryResult<GetSiteGeneralSettingSummaryQuery, GetSiteGeneralSettingSummaryQueryVariables>;
export const GetDefectSiteGeneralSettingSummaryDocument = gql`
    query getDefectSiteGeneralSettingSummary {
  costCategoryDates
}
    `;

/**
 * __useGetDefectSiteGeneralSettingSummaryQuery__
 *
 * To run a query within a React component, call `useGetDefectSiteGeneralSettingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefectSiteGeneralSettingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefectSiteGeneralSettingSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDefectSiteGeneralSettingSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDefectSiteGeneralSettingSummaryQuery, GetDefectSiteGeneralSettingSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDefectSiteGeneralSettingSummaryQuery, GetDefectSiteGeneralSettingSummaryQueryVariables>(GetDefectSiteGeneralSettingSummaryDocument, baseOptions);
      }
export function useGetDefectSiteGeneralSettingSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDefectSiteGeneralSettingSummaryQuery, GetDefectSiteGeneralSettingSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDefectSiteGeneralSettingSummaryQuery, GetDefectSiteGeneralSettingSummaryQueryVariables>(GetDefectSiteGeneralSettingSummaryDocument, baseOptions);
        }
export type GetDefectSiteGeneralSettingSummaryQueryHookResult = ReturnType<typeof useGetDefectSiteGeneralSettingSummaryQuery>;
export type GetDefectSiteGeneralSettingSummaryLazyQueryHookResult = ReturnType<typeof useGetDefectSiteGeneralSettingSummaryLazyQuery>;
export type GetDefectSiteGeneralSettingSummaryQueryResult = ApolloReactCommon.QueryResult<GetDefectSiteGeneralSettingSummaryQuery, GetDefectSiteGeneralSettingSummaryQueryVariables>;
export const GetAttendanceSiteGeneralSettingSummaryDocument = gql`
    query getAttendanceSiteGeneralSettingSummary {
  publicHolidayDate
  calendarTypeDate
  workingHourPolicyDate
  wagesRatePolicyDate
  workerProfileDate
}
    `;

/**
 * __useGetAttendanceSiteGeneralSettingSummaryQuery__
 *
 * To run a query within a React component, call `useGetAttendanceSiteGeneralSettingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttendanceSiteGeneralSettingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttendanceSiteGeneralSettingSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAttendanceSiteGeneralSettingSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAttendanceSiteGeneralSettingSummaryQuery, GetAttendanceSiteGeneralSettingSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAttendanceSiteGeneralSettingSummaryQuery, GetAttendanceSiteGeneralSettingSummaryQueryVariables>(GetAttendanceSiteGeneralSettingSummaryDocument, baseOptions);
      }
export function useGetAttendanceSiteGeneralSettingSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAttendanceSiteGeneralSettingSummaryQuery, GetAttendanceSiteGeneralSettingSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAttendanceSiteGeneralSettingSummaryQuery, GetAttendanceSiteGeneralSettingSummaryQueryVariables>(GetAttendanceSiteGeneralSettingSummaryDocument, baseOptions);
        }
export type GetAttendanceSiteGeneralSettingSummaryQueryHookResult = ReturnType<typeof useGetAttendanceSiteGeneralSettingSummaryQuery>;
export type GetAttendanceSiteGeneralSettingSummaryLazyQueryHookResult = ReturnType<typeof useGetAttendanceSiteGeneralSettingSummaryLazyQuery>;
export type GetAttendanceSiteGeneralSettingSummaryQueryResult = ApolloReactCommon.QueryResult<GetAttendanceSiteGeneralSettingSummaryQuery, GetAttendanceSiteGeneralSettingSummaryQueryVariables>;
export const GetInventorySiteGeneralSettingSummaryDocument = gql`
    query getInventorySiteGeneralSettingSummary {
  siteMaterialPolicyDate
}
    `;

/**
 * __useGetInventorySiteGeneralSettingSummaryQuery__
 *
 * To run a query within a React component, call `useGetInventorySiteGeneralSettingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInventorySiteGeneralSettingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInventorySiteGeneralSettingSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInventorySiteGeneralSettingSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInventorySiteGeneralSettingSummaryQuery, GetInventorySiteGeneralSettingSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInventorySiteGeneralSettingSummaryQuery, GetInventorySiteGeneralSettingSummaryQueryVariables>(GetInventorySiteGeneralSettingSummaryDocument, baseOptions);
      }
export function useGetInventorySiteGeneralSettingSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInventorySiteGeneralSettingSummaryQuery, GetInventorySiteGeneralSettingSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInventorySiteGeneralSettingSummaryQuery, GetInventorySiteGeneralSettingSummaryQueryVariables>(GetInventorySiteGeneralSettingSummaryDocument, baseOptions);
        }
export type GetInventorySiteGeneralSettingSummaryQueryHookResult = ReturnType<typeof useGetInventorySiteGeneralSettingSummaryQuery>;
export type GetInventorySiteGeneralSettingSummaryLazyQueryHookResult = ReturnType<typeof useGetInventorySiteGeneralSettingSummaryLazyQuery>;
export type GetInventorySiteGeneralSettingSummaryQueryResult = ApolloReactCommon.QueryResult<GetInventorySiteGeneralSettingSummaryQuery, GetInventorySiteGeneralSettingSummaryQueryVariables>;
export const GetSurveillanceSiteGeneralSettingSummaryDocument = gql`
    query getSurveillanceSiteGeneralSettingSummary {
  videoCamDate
}
    `;

/**
 * __useGetSurveillanceSiteGeneralSettingSummaryQuery__
 *
 * To run a query within a React component, call `useGetSurveillanceSiteGeneralSettingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveillanceSiteGeneralSettingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveillanceSiteGeneralSettingSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSurveillanceSiteGeneralSettingSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSurveillanceSiteGeneralSettingSummaryQuery, GetSurveillanceSiteGeneralSettingSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSurveillanceSiteGeneralSettingSummaryQuery, GetSurveillanceSiteGeneralSettingSummaryQueryVariables>(GetSurveillanceSiteGeneralSettingSummaryDocument, baseOptions);
      }
export function useGetSurveillanceSiteGeneralSettingSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSurveillanceSiteGeneralSettingSummaryQuery, GetSurveillanceSiteGeneralSettingSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSurveillanceSiteGeneralSettingSummaryQuery, GetSurveillanceSiteGeneralSettingSummaryQueryVariables>(GetSurveillanceSiteGeneralSettingSummaryDocument, baseOptions);
        }
export type GetSurveillanceSiteGeneralSettingSummaryQueryHookResult = ReturnType<typeof useGetSurveillanceSiteGeneralSettingSummaryQuery>;
export type GetSurveillanceSiteGeneralSettingSummaryLazyQueryHookResult = ReturnType<typeof useGetSurveillanceSiteGeneralSettingSummaryLazyQuery>;
export type GetSurveillanceSiteGeneralSettingSummaryQueryResult = ApolloReactCommon.QueryResult<GetSurveillanceSiteGeneralSettingSummaryQuery, GetSurveillanceSiteGeneralSettingSummaryQueryVariables>;
export const GetSiteContractSettingSummaryDocument = gql`
    query getSiteContractSettingSummary {
  siteMaterialDate
  policyDate
  videoCamDate
}
    `;

/**
 * __useGetSiteContractSettingSummaryQuery__
 *
 * To run a query within a React component, call `useGetSiteContractSettingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteContractSettingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteContractSettingSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSiteContractSettingSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteContractSettingSummaryQuery, GetSiteContractSettingSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteContractSettingSummaryQuery, GetSiteContractSettingSummaryQueryVariables>(GetSiteContractSettingSummaryDocument, baseOptions);
      }
export function useGetSiteContractSettingSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteContractSettingSummaryQuery, GetSiteContractSettingSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteContractSettingSummaryQuery, GetSiteContractSettingSummaryQueryVariables>(GetSiteContractSettingSummaryDocument, baseOptions);
        }
export type GetSiteContractSettingSummaryQueryHookResult = ReturnType<typeof useGetSiteContractSettingSummaryQuery>;
export type GetSiteContractSettingSummaryLazyQueryHookResult = ReturnType<typeof useGetSiteContractSettingSummaryLazyQuery>;
export type GetSiteContractSettingSummaryQueryResult = ApolloReactCommon.QueryResult<GetSiteContractSettingSummaryQuery, GetSiteContractSettingSummaryQueryVariables>;
export const GetGenSetSysAdminSummaryDocument = gql`
    query getGenSetSysAdminSummary {
  costCategoryDates
  userDate
  bankAccountDate
  docNumDate
  uomDate
  taxSchemeDate
}
    `;

/**
 * __useGetGenSetSysAdminSummaryQuery__
 *
 * To run a query within a React component, call `useGetGenSetSysAdminSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGenSetSysAdminSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGenSetSysAdminSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGenSetSysAdminSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGenSetSysAdminSummaryQuery, GetGenSetSysAdminSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGenSetSysAdminSummaryQuery, GetGenSetSysAdminSummaryQueryVariables>(GetGenSetSysAdminSummaryDocument, baseOptions);
      }
export function useGetGenSetSysAdminSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGenSetSysAdminSummaryQuery, GetGenSetSysAdminSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGenSetSysAdminSummaryQuery, GetGenSetSysAdminSummaryQueryVariables>(GetGenSetSysAdminSummaryDocument, baseOptions);
        }
export type GetGenSetSysAdminSummaryQueryHookResult = ReturnType<typeof useGetGenSetSysAdminSummaryQuery>;
export type GetGenSetSysAdminSummaryLazyQueryHookResult = ReturnType<typeof useGetGenSetSysAdminSummaryLazyQuery>;
export type GetGenSetSysAdminSummaryQueryResult = ApolloReactCommon.QueryResult<GetGenSetSysAdminSummaryQuery, GetGenSetSysAdminSummaryQueryVariables>;
export const GetApprovalPolicyDateDocument = gql`
    query getApprovalPolicyDate($parameter: String!) {
  approvalPolicyDate(parameter: $parameter)
}
    `;

/**
 * __useGetApprovalPolicyDateQuery__
 *
 * To run a query within a React component, call `useGetApprovalPolicyDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalPolicyDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalPolicyDateQuery({
 *   variables: {
 *      parameter: // value for 'parameter'
 *   },
 * });
 */
export function useGetApprovalPolicyDateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApprovalPolicyDateQuery, GetApprovalPolicyDateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApprovalPolicyDateQuery, GetApprovalPolicyDateQueryVariables>(GetApprovalPolicyDateDocument, baseOptions);
      }
export function useGetApprovalPolicyDateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApprovalPolicyDateQuery, GetApprovalPolicyDateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApprovalPolicyDateQuery, GetApprovalPolicyDateQueryVariables>(GetApprovalPolicyDateDocument, baseOptions);
        }
export type GetApprovalPolicyDateQueryHookResult = ReturnType<typeof useGetApprovalPolicyDateQuery>;
export type GetApprovalPolicyDateLazyQueryHookResult = ReturnType<typeof useGetApprovalPolicyDateLazyQuery>;
export type GetApprovalPolicyDateQueryResult = ApolloReactCommon.QueryResult<GetApprovalPolicyDateQuery, GetApprovalPolicyDateQueryVariables>;
export const GetGeneralSettingGlInterfaceSummaryDocument = gql`
    query getGeneralSettingGLInterfaceSummary {
  clientAccDate
  subconAccDate
  supplierAccDate
  purchaseAccDate
  pobAccDate
  bankAccDate
  taxAccDate
  tradeAccDate
  materialAccDate
  plantAccDate
  labourAccDate
  miscAccDate
  journalAccDate
  departmentDate
}
    `;

/**
 * __useGetGeneralSettingGlInterfaceSummaryQuery__
 *
 * To run a query within a React component, call `useGetGeneralSettingGlInterfaceSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralSettingGlInterfaceSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralSettingGlInterfaceSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGeneralSettingGlInterfaceSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGeneralSettingGlInterfaceSummaryQuery, GetGeneralSettingGlInterfaceSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGeneralSettingGlInterfaceSummaryQuery, GetGeneralSettingGlInterfaceSummaryQueryVariables>(GetGeneralSettingGlInterfaceSummaryDocument, baseOptions);
      }
export function useGetGeneralSettingGlInterfaceSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGeneralSettingGlInterfaceSummaryQuery, GetGeneralSettingGlInterfaceSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGeneralSettingGlInterfaceSummaryQuery, GetGeneralSettingGlInterfaceSummaryQueryVariables>(GetGeneralSettingGlInterfaceSummaryDocument, baseOptions);
        }
export type GetGeneralSettingGlInterfaceSummaryQueryHookResult = ReturnType<typeof useGetGeneralSettingGlInterfaceSummaryQuery>;
export type GetGeneralSettingGlInterfaceSummaryLazyQueryHookResult = ReturnType<typeof useGetGeneralSettingGlInterfaceSummaryLazyQuery>;
export type GetGeneralSettingGlInterfaceSummaryQueryResult = ApolloReactCommon.QueryResult<GetGeneralSettingGlInterfaceSummaryQuery, GetGeneralSettingGlInterfaceSummaryQueryVariables>;
export const GetCustomerReminderSettingDocument = gql`
    query getCustomerReminderSetting($ID: String) {
  getCustomerReminder(ID: $ID) {
    ID
    paymentReminder1
    paymentReminder2
    paymentReminder3
    paymentReminder4
    minimumAmt
    reminderFor
    printedCopy
    emailCopy
    commonStatus
  }
}
    `;

/**
 * __useGetCustomerReminderSettingQuery__
 *
 * To run a query within a React component, call `useGetCustomerReminderSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerReminderSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerReminderSettingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetCustomerReminderSettingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomerReminderSettingQuery, GetCustomerReminderSettingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomerReminderSettingQuery, GetCustomerReminderSettingQueryVariables>(GetCustomerReminderSettingDocument, baseOptions);
      }
export function useGetCustomerReminderSettingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerReminderSettingQuery, GetCustomerReminderSettingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomerReminderSettingQuery, GetCustomerReminderSettingQueryVariables>(GetCustomerReminderSettingDocument, baseOptions);
        }
export type GetCustomerReminderSettingQueryHookResult = ReturnType<typeof useGetCustomerReminderSettingQuery>;
export type GetCustomerReminderSettingLazyQueryHookResult = ReturnType<typeof useGetCustomerReminderSettingLazyQuery>;
export type GetCustomerReminderSettingQueryResult = ApolloReactCommon.QueryResult<GetCustomerReminderSettingQuery, GetCustomerReminderSettingQueryVariables>;
export const UpdateGuaranteeTypeDocument = gql`
    mutation updateGuaranteeType($input: GuarInsTypeInput!) {
  updateGuaranteeType(input: $input) {
    ID
    name
  }
}
    `;
export type UpdateGuaranteeTypeMutationFn = ApolloReactCommon.MutationFunction<UpdateGuaranteeTypeMutation, UpdateGuaranteeTypeMutationVariables>;

/**
 * __useUpdateGuaranteeTypeMutation__
 *
 * To run a mutation, you first call `useUpdateGuaranteeTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGuaranteeTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGuaranteeTypeMutation, { data, loading, error }] = useUpdateGuaranteeTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGuaranteeTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateGuaranteeTypeMutation, UpdateGuaranteeTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateGuaranteeTypeMutation, UpdateGuaranteeTypeMutationVariables>(UpdateGuaranteeTypeDocument, baseOptions);
      }
export type UpdateGuaranteeTypeMutationHookResult = ReturnType<typeof useUpdateGuaranteeTypeMutation>;
export type UpdateGuaranteeTypeMutationResult = ApolloReactCommon.MutationResult<UpdateGuaranteeTypeMutation>;
export type UpdateGuaranteeTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateGuaranteeTypeMutation, UpdateGuaranteeTypeMutationVariables>;
export const CreateGuaranteeTypeDocument = gql`
    mutation createGuaranteeType($input: GuarInsTypeInput!) {
  createGuaranteeType(input: $input) {
    name
  }
}
    `;
export type CreateGuaranteeTypeMutationFn = ApolloReactCommon.MutationFunction<CreateGuaranteeTypeMutation, CreateGuaranteeTypeMutationVariables>;

/**
 * __useCreateGuaranteeTypeMutation__
 *
 * To run a mutation, you first call `useCreateGuaranteeTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGuaranteeTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGuaranteeTypeMutation, { data, loading, error }] = useCreateGuaranteeTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGuaranteeTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGuaranteeTypeMutation, CreateGuaranteeTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGuaranteeTypeMutation, CreateGuaranteeTypeMutationVariables>(CreateGuaranteeTypeDocument, baseOptions);
      }
export type CreateGuaranteeTypeMutationHookResult = ReturnType<typeof useCreateGuaranteeTypeMutation>;
export type CreateGuaranteeTypeMutationResult = ApolloReactCommon.MutationResult<CreateGuaranteeTypeMutation>;
export type CreateGuaranteeTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateGuaranteeTypeMutation, CreateGuaranteeTypeMutationVariables>;
export const GetGuaranteeTypeDocument = gql`
    query getGuaranteeType($ID: String, $orderByAsc: String, $orderByDesc: String, $commonStatus: CommonStatus) {
  getGuaranteeType(ID: $ID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, commonStatus: $commonStatus) {
    ID
    name
    commonStatus
    modTs
  }
  guaranteeTypeDate
}
    `;

/**
 * __useGetGuaranteeTypeQuery__
 *
 * To run a query within a React component, call `useGetGuaranteeTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGuaranteeTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGuaranteeTypeQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      commonStatus: // value for 'commonStatus'
 *   },
 * });
 */
export function useGetGuaranteeTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGuaranteeTypeQuery, GetGuaranteeTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGuaranteeTypeQuery, GetGuaranteeTypeQueryVariables>(GetGuaranteeTypeDocument, baseOptions);
      }
export function useGetGuaranteeTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGuaranteeTypeQuery, GetGuaranteeTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGuaranteeTypeQuery, GetGuaranteeTypeQueryVariables>(GetGuaranteeTypeDocument, baseOptions);
        }
export type GetGuaranteeTypeQueryHookResult = ReturnType<typeof useGetGuaranteeTypeQuery>;
export type GetGuaranteeTypeLazyQueryHookResult = ReturnType<typeof useGetGuaranteeTypeLazyQuery>;
export type GetGuaranteeTypeQueryResult = ApolloReactCommon.QueryResult<GetGuaranteeTypeQuery, GetGuaranteeTypeQueryVariables>;
export const DeleteGuaranteeTypeDocument = gql`
    mutation deleteGuaranteeType($ID: GuarInsTypeDeleteInput!) {
  deleteGuaranteeType(input: $ID)
}
    `;
export type DeleteGuaranteeTypeMutationFn = ApolloReactCommon.MutationFunction<DeleteGuaranteeTypeMutation, DeleteGuaranteeTypeMutationVariables>;

/**
 * __useDeleteGuaranteeTypeMutation__
 *
 * To run a mutation, you first call `useDeleteGuaranteeTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGuaranteeTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGuaranteeTypeMutation, { data, loading, error }] = useDeleteGuaranteeTypeMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteGuaranteeTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteGuaranteeTypeMutation, DeleteGuaranteeTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteGuaranteeTypeMutation, DeleteGuaranteeTypeMutationVariables>(DeleteGuaranteeTypeDocument, baseOptions);
      }
export type DeleteGuaranteeTypeMutationHookResult = ReturnType<typeof useDeleteGuaranteeTypeMutation>;
export type DeleteGuaranteeTypeMutationResult = ApolloReactCommon.MutationResult<DeleteGuaranteeTypeMutation>;
export type DeleteGuaranteeTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteGuaranteeTypeMutation, DeleteGuaranteeTypeMutationVariables>;
export const CancelGuaranteeTypeDocument = gql`
    mutation cancelGuaranteeType($input: GuarInsTypeInput!) {
  cancelGuaranteeType(input: $input)
}
    `;
export type CancelGuaranteeTypeMutationFn = ApolloReactCommon.MutationFunction<CancelGuaranteeTypeMutation, CancelGuaranteeTypeMutationVariables>;

/**
 * __useCancelGuaranteeTypeMutation__
 *
 * To run a mutation, you first call `useCancelGuaranteeTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelGuaranteeTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelGuaranteeTypeMutation, { data, loading, error }] = useCancelGuaranteeTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelGuaranteeTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelGuaranteeTypeMutation, CancelGuaranteeTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelGuaranteeTypeMutation, CancelGuaranteeTypeMutationVariables>(CancelGuaranteeTypeDocument, baseOptions);
      }
export type CancelGuaranteeTypeMutationHookResult = ReturnType<typeof useCancelGuaranteeTypeMutation>;
export type CancelGuaranteeTypeMutationResult = ApolloReactCommon.MutationResult<CancelGuaranteeTypeMutation>;
export type CancelGuaranteeTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelGuaranteeTypeMutation, CancelGuaranteeTypeMutationVariables>;
export const ActivateGuaranteeTypeDocument = gql`
    mutation activateGuaranteeType($input: GuarInsTypeInput!) {
  activateGuaranteeType(input: $input)
}
    `;
export type ActivateGuaranteeTypeMutationFn = ApolloReactCommon.MutationFunction<ActivateGuaranteeTypeMutation, ActivateGuaranteeTypeMutationVariables>;

/**
 * __useActivateGuaranteeTypeMutation__
 *
 * To run a mutation, you first call `useActivateGuaranteeTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateGuaranteeTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateGuaranteeTypeMutation, { data, loading, error }] = useActivateGuaranteeTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateGuaranteeTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateGuaranteeTypeMutation, ActivateGuaranteeTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateGuaranteeTypeMutation, ActivateGuaranteeTypeMutationVariables>(ActivateGuaranteeTypeDocument, baseOptions);
      }
export type ActivateGuaranteeTypeMutationHookResult = ReturnType<typeof useActivateGuaranteeTypeMutation>;
export type ActivateGuaranteeTypeMutationResult = ApolloReactCommon.MutationResult<ActivateGuaranteeTypeMutation>;
export type ActivateGuaranteeTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateGuaranteeTypeMutation, ActivateGuaranteeTypeMutationVariables>;
export const UpdateInsuranceTypeDocument = gql`
    mutation updateInsuranceType($input: GuarInsTypeInput!) {
  updateInsuranceType(input: $input) {
    ID
    name
  }
}
    `;
export type UpdateInsuranceTypeMutationFn = ApolloReactCommon.MutationFunction<UpdateInsuranceTypeMutation, UpdateInsuranceTypeMutationVariables>;

/**
 * __useUpdateInsuranceTypeMutation__
 *
 * To run a mutation, you first call `useUpdateInsuranceTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInsuranceTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInsuranceTypeMutation, { data, loading, error }] = useUpdateInsuranceTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInsuranceTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateInsuranceTypeMutation, UpdateInsuranceTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateInsuranceTypeMutation, UpdateInsuranceTypeMutationVariables>(UpdateInsuranceTypeDocument, baseOptions);
      }
export type UpdateInsuranceTypeMutationHookResult = ReturnType<typeof useUpdateInsuranceTypeMutation>;
export type UpdateInsuranceTypeMutationResult = ApolloReactCommon.MutationResult<UpdateInsuranceTypeMutation>;
export type UpdateInsuranceTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateInsuranceTypeMutation, UpdateInsuranceTypeMutationVariables>;
export const CreateInsuranceTypeDocument = gql`
    mutation createInsuranceType($input: GuarInsTypeInput!) {
  createInsuranceType(input: $input) {
    name
  }
}
    `;
export type CreateInsuranceTypeMutationFn = ApolloReactCommon.MutationFunction<CreateInsuranceTypeMutation, CreateInsuranceTypeMutationVariables>;

/**
 * __useCreateInsuranceTypeMutation__
 *
 * To run a mutation, you first call `useCreateInsuranceTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInsuranceTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInsuranceTypeMutation, { data, loading, error }] = useCreateInsuranceTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInsuranceTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateInsuranceTypeMutation, CreateInsuranceTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateInsuranceTypeMutation, CreateInsuranceTypeMutationVariables>(CreateInsuranceTypeDocument, baseOptions);
      }
export type CreateInsuranceTypeMutationHookResult = ReturnType<typeof useCreateInsuranceTypeMutation>;
export type CreateInsuranceTypeMutationResult = ApolloReactCommon.MutationResult<CreateInsuranceTypeMutation>;
export type CreateInsuranceTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateInsuranceTypeMutation, CreateInsuranceTypeMutationVariables>;
export const GetInsuranceTypeDocument = gql`
    query getInsuranceType($ID: String, $orderByAsc: String, $orderByDesc: String, $commonStatus: CommonStatus) {
  getInsuranceType(ID: $ID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, commonStatus: $commonStatus) {
    ID
    name
    commonStatus
    modTs
  }
  insuranceTypeDate
}
    `;

/**
 * __useGetInsuranceTypeQuery__
 *
 * To run a query within a React component, call `useGetInsuranceTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInsuranceTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInsuranceTypeQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      commonStatus: // value for 'commonStatus'
 *   },
 * });
 */
export function useGetInsuranceTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInsuranceTypeQuery, GetInsuranceTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInsuranceTypeQuery, GetInsuranceTypeQueryVariables>(GetInsuranceTypeDocument, baseOptions);
      }
export function useGetInsuranceTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInsuranceTypeQuery, GetInsuranceTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInsuranceTypeQuery, GetInsuranceTypeQueryVariables>(GetInsuranceTypeDocument, baseOptions);
        }
export type GetInsuranceTypeQueryHookResult = ReturnType<typeof useGetInsuranceTypeQuery>;
export type GetInsuranceTypeLazyQueryHookResult = ReturnType<typeof useGetInsuranceTypeLazyQuery>;
export type GetInsuranceTypeQueryResult = ApolloReactCommon.QueryResult<GetInsuranceTypeQuery, GetInsuranceTypeQueryVariables>;
export const DeleteInsuranceTypeDocument = gql`
    mutation deleteInsuranceType($ID: GuarInsTypeDeleteInput!) {
  deleteInsuranceType(input: $ID)
}
    `;
export type DeleteInsuranceTypeMutationFn = ApolloReactCommon.MutationFunction<DeleteInsuranceTypeMutation, DeleteInsuranceTypeMutationVariables>;

/**
 * __useDeleteInsuranceTypeMutation__
 *
 * To run a mutation, you first call `useDeleteInsuranceTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInsuranceTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInsuranceTypeMutation, { data, loading, error }] = useDeleteInsuranceTypeMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteInsuranceTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteInsuranceTypeMutation, DeleteInsuranceTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteInsuranceTypeMutation, DeleteInsuranceTypeMutationVariables>(DeleteInsuranceTypeDocument, baseOptions);
      }
export type DeleteInsuranceTypeMutationHookResult = ReturnType<typeof useDeleteInsuranceTypeMutation>;
export type DeleteInsuranceTypeMutationResult = ApolloReactCommon.MutationResult<DeleteInsuranceTypeMutation>;
export type DeleteInsuranceTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteInsuranceTypeMutation, DeleteInsuranceTypeMutationVariables>;
export const CancelInsuranceTypeDocument = gql`
    mutation cancelInsuranceType($input: GuarInsTypeInput!) {
  cancelInsuranceType(input: $input)
}
    `;
export type CancelInsuranceTypeMutationFn = ApolloReactCommon.MutationFunction<CancelInsuranceTypeMutation, CancelInsuranceTypeMutationVariables>;

/**
 * __useCancelInsuranceTypeMutation__
 *
 * To run a mutation, you first call `useCancelInsuranceTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelInsuranceTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelInsuranceTypeMutation, { data, loading, error }] = useCancelInsuranceTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelInsuranceTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelInsuranceTypeMutation, CancelInsuranceTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelInsuranceTypeMutation, CancelInsuranceTypeMutationVariables>(CancelInsuranceTypeDocument, baseOptions);
      }
export type CancelInsuranceTypeMutationHookResult = ReturnType<typeof useCancelInsuranceTypeMutation>;
export type CancelInsuranceTypeMutationResult = ApolloReactCommon.MutationResult<CancelInsuranceTypeMutation>;
export type CancelInsuranceTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelInsuranceTypeMutation, CancelInsuranceTypeMutationVariables>;
export const ActivateInsuranceTypeDocument = gql`
    mutation activateInsuranceType($input: GuarInsTypeInput!) {
  activateInsuranceType(input: $input)
}
    `;
export type ActivateInsuranceTypeMutationFn = ApolloReactCommon.MutationFunction<ActivateInsuranceTypeMutation, ActivateInsuranceTypeMutationVariables>;

/**
 * __useActivateInsuranceTypeMutation__
 *
 * To run a mutation, you first call `useActivateInsuranceTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateInsuranceTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateInsuranceTypeMutation, { data, loading, error }] = useActivateInsuranceTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateInsuranceTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateInsuranceTypeMutation, ActivateInsuranceTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateInsuranceTypeMutation, ActivateInsuranceTypeMutationVariables>(ActivateInsuranceTypeDocument, baseOptions);
      }
export type ActivateInsuranceTypeMutationHookResult = ReturnType<typeof useActivateInsuranceTypeMutation>;
export type ActivateInsuranceTypeMutationResult = ApolloReactCommon.MutationResult<ActivateInsuranceTypeMutation>;
export type ActivateInsuranceTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateInsuranceTypeMutation, ActivateInsuranceTypeMutationVariables>;
export const GetJobTypeDocument = gql`
    query getJobType($ID: String, $orderByDesc: String) {
  getJobType(ID: $ID, orderByDesc: $orderByDesc) {
    ID
    name
    marketRate
    commonStatus
  }
}
    `;

/**
 * __useGetJobTypeQuery__
 *
 * To run a query within a React component, call `useGetJobTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobTypeQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetJobTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetJobTypeQuery, GetJobTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetJobTypeQuery, GetJobTypeQueryVariables>(GetJobTypeDocument, baseOptions);
      }
export function useGetJobTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetJobTypeQuery, GetJobTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetJobTypeQuery, GetJobTypeQueryVariables>(GetJobTypeDocument, baseOptions);
        }
export type GetJobTypeQueryHookResult = ReturnType<typeof useGetJobTypeQuery>;
export type GetJobTypeLazyQueryHookResult = ReturnType<typeof useGetJobTypeLazyQuery>;
export type GetJobTypeQueryResult = ApolloReactCommon.QueryResult<GetJobTypeQuery, GetJobTypeQueryVariables>;
export const CreateJobTypeDocument = gql`
    mutation createJobType($input: JobTypeInput!) {
  createJobType(input: $input) {
    ID
  }
}
    `;
export type CreateJobTypeMutationFn = ApolloReactCommon.MutationFunction<CreateJobTypeMutation, CreateJobTypeMutationVariables>;

/**
 * __useCreateJobTypeMutation__
 *
 * To run a mutation, you first call `useCreateJobTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobTypeMutation, { data, loading, error }] = useCreateJobTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateJobTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateJobTypeMutation, CreateJobTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateJobTypeMutation, CreateJobTypeMutationVariables>(CreateJobTypeDocument, baseOptions);
      }
export type CreateJobTypeMutationHookResult = ReturnType<typeof useCreateJobTypeMutation>;
export type CreateJobTypeMutationResult = ApolloReactCommon.MutationResult<CreateJobTypeMutation>;
export type CreateJobTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateJobTypeMutation, CreateJobTypeMutationVariables>;
export const CancelJobTypeDocument = gql`
    mutation cancelJobType($input: JobTypeDeleteInput!) {
  cancelJobType(input: $input)
}
    `;
export type CancelJobTypeMutationFn = ApolloReactCommon.MutationFunction<CancelJobTypeMutation, CancelJobTypeMutationVariables>;

/**
 * __useCancelJobTypeMutation__
 *
 * To run a mutation, you first call `useCancelJobTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelJobTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelJobTypeMutation, { data, loading, error }] = useCancelJobTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelJobTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelJobTypeMutation, CancelJobTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelJobTypeMutation, CancelJobTypeMutationVariables>(CancelJobTypeDocument, baseOptions);
      }
export type CancelJobTypeMutationHookResult = ReturnType<typeof useCancelJobTypeMutation>;
export type CancelJobTypeMutationResult = ApolloReactCommon.MutationResult<CancelJobTypeMutation>;
export type CancelJobTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelJobTypeMutation, CancelJobTypeMutationVariables>;
export const UpdateJobTypeDocument = gql`
    mutation updateJobType($input: JobTypeInput!) {
  updateJobType(input: $input) {
    ID
  }
}
    `;
export type UpdateJobTypeMutationFn = ApolloReactCommon.MutationFunction<UpdateJobTypeMutation, UpdateJobTypeMutationVariables>;

/**
 * __useUpdateJobTypeMutation__
 *
 * To run a mutation, you first call `useUpdateJobTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobTypeMutation, { data, loading, error }] = useUpdateJobTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateJobTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateJobTypeMutation, UpdateJobTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateJobTypeMutation, UpdateJobTypeMutationVariables>(UpdateJobTypeDocument, baseOptions);
      }
export type UpdateJobTypeMutationHookResult = ReturnType<typeof useUpdateJobTypeMutation>;
export type UpdateJobTypeMutationResult = ApolloReactCommon.MutationResult<UpdateJobTypeMutation>;
export type UpdateJobTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateJobTypeMutation, UpdateJobTypeMutationVariables>;
export const DeleteJobTypeDocument = gql`
    mutation deleteJobType($input: JobTypeDeleteInput!) {
  deleteJobType(input: $input)
}
    `;
export type DeleteJobTypeMutationFn = ApolloReactCommon.MutationFunction<DeleteJobTypeMutation, DeleteJobTypeMutationVariables>;

/**
 * __useDeleteJobTypeMutation__
 *
 * To run a mutation, you first call `useDeleteJobTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJobTypeMutation, { data, loading, error }] = useDeleteJobTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteJobTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteJobTypeMutation, DeleteJobTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteJobTypeMutation, DeleteJobTypeMutationVariables>(DeleteJobTypeDocument, baseOptions);
      }
export type DeleteJobTypeMutationHookResult = ReturnType<typeof useDeleteJobTypeMutation>;
export type DeleteJobTypeMutationResult = ApolloReactCommon.MutationResult<DeleteJobTypeMutation>;
export type DeleteJobTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteJobTypeMutation, DeleteJobTypeMutationVariables>;
export const ActivateJobTypeDocument = gql`
    mutation activateJobType($input: JobTypeDeleteInput!) {
  activateJobType(input: $input)
}
    `;
export type ActivateJobTypeMutationFn = ApolloReactCommon.MutationFunction<ActivateJobTypeMutation, ActivateJobTypeMutationVariables>;

/**
 * __useActivateJobTypeMutation__
 *
 * To run a mutation, you first call `useActivateJobTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateJobTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateJobTypeMutation, { data, loading, error }] = useActivateJobTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateJobTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateJobTypeMutation, ActivateJobTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateJobTypeMutation, ActivateJobTypeMutationVariables>(ActivateJobTypeDocument, baseOptions);
      }
export type ActivateJobTypeMutationHookResult = ReturnType<typeof useActivateJobTypeMutation>;
export type ActivateJobTypeMutationResult = ApolloReactCommon.MutationResult<ActivateJobTypeMutation>;
export type ActivateJobTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateJobTypeMutation, ActivateJobTypeMutationVariables>;
export const GetCostItemByClassesDocument = gql`
    query getCostItemByClasses($costClass: [CostClass!]!, $isInventory: Boolean) {
  getCostItemByClasses(costClass: $costClass, isInventory: $isInventory) {
    createdTs
    createdBy
    modTs
    modBy
    ID
    uomID
    accountID
    taxSchemeID
    costCategoryID
    variancePerc
    commonStatus
    name
    code
    unitPrice
    onBehalf
    markupPerc
    UOM {
      ID
      name
      code
    }
    costCategory {
      ID
      name
    }
  }
}
    `;

/**
 * __useGetCostItemByClassesQuery__
 *
 * To run a query within a React component, call `useGetCostItemByClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostItemByClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostItemByClassesQuery({
 *   variables: {
 *      costClass: // value for 'costClass'
 *      isInventory: // value for 'isInventory'
 *   },
 * });
 */
export function useGetCostItemByClassesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostItemByClassesQuery, GetCostItemByClassesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostItemByClassesQuery, GetCostItemByClassesQueryVariables>(GetCostItemByClassesDocument, baseOptions);
      }
export function useGetCostItemByClassesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostItemByClassesQuery, GetCostItemByClassesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostItemByClassesQuery, GetCostItemByClassesQueryVariables>(GetCostItemByClassesDocument, baseOptions);
        }
export type GetCostItemByClassesQueryHookResult = ReturnType<typeof useGetCostItemByClassesQuery>;
export type GetCostItemByClassesLazyQueryHookResult = ReturnType<typeof useGetCostItemByClassesLazyQuery>;
export type GetCostItemByClassesQueryResult = ApolloReactCommon.QueryResult<GetCostItemByClassesQuery, GetCostItemByClassesQueryVariables>;
export const GetCostItemPaginationDocument = gql`
    query getCostItemPagination($ID: String, $costClass: CostClass, $searchValue: String) {
  getCostItemPagination(ID: $ID, costClass: $costClass, searchValue: $searchValue) {
    createdTs
    createdBy
    modTs
    modBy
    ID
    uomID
    accountID
    taxSchemeID
    costCategoryID
    variancePerc
    commonStatus
    name
    code
    unitPrice
    onBehalf
    isInventory
    markupPerc
    UOM {
      ID
      name
      code
    }
    costCategory {
      ID
      name
    }
  }
}
    `;

/**
 * __useGetCostItemPaginationQuery__
 *
 * To run a query within a React component, call `useGetCostItemPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostItemPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostItemPaginationQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      costClass: // value for 'costClass'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetCostItemPaginationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostItemPaginationQuery, GetCostItemPaginationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostItemPaginationQuery, GetCostItemPaginationQueryVariables>(GetCostItemPaginationDocument, baseOptions);
      }
export function useGetCostItemPaginationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostItemPaginationQuery, GetCostItemPaginationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostItemPaginationQuery, GetCostItemPaginationQueryVariables>(GetCostItemPaginationDocument, baseOptions);
        }
export type GetCostItemPaginationQueryHookResult = ReturnType<typeof useGetCostItemPaginationQuery>;
export type GetCostItemPaginationLazyQueryHookResult = ReturnType<typeof useGetCostItemPaginationLazyQuery>;
export type GetCostItemPaginationQueryResult = ApolloReactCommon.QueryResult<GetCostItemPaginationQuery, GetCostItemPaginationQueryVariables>;
export const GetProfitRecogGlAccountCodeDocument = gql`
    query getProfitRecogGLAccountCode($orderByDesc: String, $orderByAsc: String, $skip: Float, $take: Float) {
  getProfitRecogGLAccountCode(take: $take, skip: $skip, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc) {
    createdTs
    createdBy
    modTs
    modBy
    ID
    accountID
    wipCostRecognized
    wipBillingRecognized
    revenuePL
    costPL
  }
}
    `;

/**
 * __useGetProfitRecogGlAccountCodeQuery__
 *
 * To run a query within a React component, call `useGetProfitRecogGlAccountCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfitRecogGlAccountCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfitRecogGlAccountCodeQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetProfitRecogGlAccountCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProfitRecogGlAccountCodeQuery, GetProfitRecogGlAccountCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProfitRecogGlAccountCodeQuery, GetProfitRecogGlAccountCodeQueryVariables>(GetProfitRecogGlAccountCodeDocument, baseOptions);
      }
export function useGetProfitRecogGlAccountCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProfitRecogGlAccountCodeQuery, GetProfitRecogGlAccountCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProfitRecogGlAccountCodeQuery, GetProfitRecogGlAccountCodeQueryVariables>(GetProfitRecogGlAccountCodeDocument, baseOptions);
        }
export type GetProfitRecogGlAccountCodeQueryHookResult = ReturnType<typeof useGetProfitRecogGlAccountCodeQuery>;
export type GetProfitRecogGlAccountCodeLazyQueryHookResult = ReturnType<typeof useGetProfitRecogGlAccountCodeLazyQuery>;
export type GetProfitRecogGlAccountCodeQueryResult = ApolloReactCommon.QueryResult<GetProfitRecogGlAccountCodeQuery, GetProfitRecogGlAccountCodeQueryVariables>;
export const CreateProfitRecogGlAccountCodeDocument = gql`
    mutation createProfitRecogGLAccountCode($input: ProfitRecogGLAccountCodeInput!) {
  createProfitRecogGLAccountCode(input: $input) {
    ID
  }
}
    `;
export type CreateProfitRecogGlAccountCodeMutationFn = ApolloReactCommon.MutationFunction<CreateProfitRecogGlAccountCodeMutation, CreateProfitRecogGlAccountCodeMutationVariables>;

/**
 * __useCreateProfitRecogGlAccountCodeMutation__
 *
 * To run a mutation, you first call `useCreateProfitRecogGlAccountCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProfitRecogGlAccountCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProfitRecogGlAccountCodeMutation, { data, loading, error }] = useCreateProfitRecogGlAccountCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProfitRecogGlAccountCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProfitRecogGlAccountCodeMutation, CreateProfitRecogGlAccountCodeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProfitRecogGlAccountCodeMutation, CreateProfitRecogGlAccountCodeMutationVariables>(CreateProfitRecogGlAccountCodeDocument, baseOptions);
      }
export type CreateProfitRecogGlAccountCodeMutationHookResult = ReturnType<typeof useCreateProfitRecogGlAccountCodeMutation>;
export type CreateProfitRecogGlAccountCodeMutationResult = ApolloReactCommon.MutationResult<CreateProfitRecogGlAccountCodeMutation>;
export type CreateProfitRecogGlAccountCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProfitRecogGlAccountCodeMutation, CreateProfitRecogGlAccountCodeMutationVariables>;
export const UpdateProfitRecogGlAccountCodeDocument = gql`
    mutation updateProfitRecogGLAccountCode($input: ProfitRecogGLAccountCodeInput!) {
  updateProfitRecogGLAccountCode(input: $input) {
    ID
  }
}
    `;
export type UpdateProfitRecogGlAccountCodeMutationFn = ApolloReactCommon.MutationFunction<UpdateProfitRecogGlAccountCodeMutation, UpdateProfitRecogGlAccountCodeMutationVariables>;

/**
 * __useUpdateProfitRecogGlAccountCodeMutation__
 *
 * To run a mutation, you first call `useUpdateProfitRecogGlAccountCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfitRecogGlAccountCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfitRecogGlAccountCodeMutation, { data, loading, error }] = useUpdateProfitRecogGlAccountCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfitRecogGlAccountCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProfitRecogGlAccountCodeMutation, UpdateProfitRecogGlAccountCodeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProfitRecogGlAccountCodeMutation, UpdateProfitRecogGlAccountCodeMutationVariables>(UpdateProfitRecogGlAccountCodeDocument, baseOptions);
      }
export type UpdateProfitRecogGlAccountCodeMutationHookResult = ReturnType<typeof useUpdateProfitRecogGlAccountCodeMutation>;
export type UpdateProfitRecogGlAccountCodeMutationResult = ApolloReactCommon.MutationResult<UpdateProfitRecogGlAccountCodeMutation>;
export type UpdateProfitRecogGlAccountCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProfitRecogGlAccountCodeMutation, UpdateProfitRecogGlAccountCodeMutationVariables>;
export const GetSiteDocument = gql`
    query getSite($orderByDesc: String, $orderByAsc: String, $skip: Float, $take: Float, $ID: String, $siteID: String, $contractID: String) {
  getSite(orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, skip: $skip, take: $take, ID: $ID, siteID: $siteID, contractID: $contractID) {
    ID
    accountID
    contractID
    name
    address
    contactNo
    contract {
      title
      customerDetail {
        name
      }
    }
  }
}
    `;

/**
 * __useGetSiteQuery__
 *
 * To run a query within a React component, call `useGetSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      ID: // value for 'ID'
 *      siteID: // value for 'siteID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetSiteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteQuery, GetSiteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteQuery, GetSiteQueryVariables>(GetSiteDocument, baseOptions);
      }
export function useGetSiteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteQuery, GetSiteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteQuery, GetSiteQueryVariables>(GetSiteDocument, baseOptions);
        }
export type GetSiteQueryHookResult = ReturnType<typeof useGetSiteQuery>;
export type GetSiteLazyQueryHookResult = ReturnType<typeof useGetSiteLazyQuery>;
export type GetSiteQueryResult = ApolloReactCommon.QueryResult<GetSiteQuery, GetSiteQueryVariables>;
export const GetSiteTitleDocument = gql`
    query getSiteTitle($ID: String, $contractID: String) {
  totalSiteCount
  getSite(ID: $ID, contractID: $contractID, orderByAsc: "name") {
    ID
    accountID
    contractID
    name
    contract {
      ID
      title
    }
  }
}
    `;

/**
 * __useGetSiteTitleQuery__
 *
 * To run a query within a React component, call `useGetSiteTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteTitleQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetSiteTitleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteTitleQuery, GetSiteTitleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteTitleQuery, GetSiteTitleQueryVariables>(GetSiteTitleDocument, baseOptions);
      }
export function useGetSiteTitleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteTitleQuery, GetSiteTitleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteTitleQuery, GetSiteTitleQueryVariables>(GetSiteTitleDocument, baseOptions);
        }
export type GetSiteTitleQueryHookResult = ReturnType<typeof useGetSiteTitleQuery>;
export type GetSiteTitleLazyQueryHookResult = ReturnType<typeof useGetSiteTitleLazyQuery>;
export type GetSiteTitleQueryResult = ApolloReactCommon.QueryResult<GetSiteTitleQuery, GetSiteTitleQueryVariables>;
export const GetProjectSiteDocument = gql`
    query getProjectSite($ID: String, $siteID: String) {
  getProjectSite(ID: $ID, siteID: $siteID) {
    ID
    title
    description
    startDate
    endDate
    customerDetail {
      name
      address
      contactNo
      registrationNo
    }
    contractStatus
    site {
      ID
      stateID
      name
      address
    }
  }
}
    `;

/**
 * __useGetProjectSiteQuery__
 *
 * To run a query within a React component, call `useGetProjectSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectSiteQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useGetProjectSiteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectSiteQuery, GetProjectSiteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectSiteQuery, GetProjectSiteQueryVariables>(GetProjectSiteDocument, baseOptions);
      }
export function useGetProjectSiteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectSiteQuery, GetProjectSiteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectSiteQuery, GetProjectSiteQueryVariables>(GetProjectSiteDocument, baseOptions);
        }
export type GetProjectSiteQueryHookResult = ReturnType<typeof useGetProjectSiteQuery>;
export type GetProjectSiteLazyQueryHookResult = ReturnType<typeof useGetProjectSiteLazyQuery>;
export type GetProjectSiteQueryResult = ApolloReactCommon.QueryResult<GetProjectSiteQuery, GetProjectSiteQueryVariables>;
export const CreateSiteDocument = gql`
    mutation createSite($siteInput: [SiteInput!]!, $input: ProjectHeaderInput!) {
  createSite(input: $input, siteInput: $siteInput)
}
    `;
export type CreateSiteMutationFn = ApolloReactCommon.MutationFunction<CreateSiteMutation, CreateSiteMutationVariables>;

/**
 * __useCreateSiteMutation__
 *
 * To run a mutation, you first call `useCreateSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSiteMutation, { data, loading, error }] = useCreateSiteMutation({
 *   variables: {
 *      siteInput: // value for 'siteInput'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSiteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSiteMutation, CreateSiteMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSiteMutation, CreateSiteMutationVariables>(CreateSiteDocument, baseOptions);
      }
export type CreateSiteMutationHookResult = ReturnType<typeof useCreateSiteMutation>;
export type CreateSiteMutationResult = ApolloReactCommon.MutationResult<CreateSiteMutation>;
export type CreateSiteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSiteMutation, CreateSiteMutationVariables>;
export const CancelSiteDocument = gql`
    mutation cancelSite($input: SiteDeleteInput!) {
  cancelSite(input: $input)
}
    `;
export type CancelSiteMutationFn = ApolloReactCommon.MutationFunction<CancelSiteMutation, CancelSiteMutationVariables>;

/**
 * __useCancelSiteMutation__
 *
 * To run a mutation, you first call `useCancelSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSiteMutation, { data, loading, error }] = useCancelSiteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSiteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSiteMutation, CancelSiteMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSiteMutation, CancelSiteMutationVariables>(CancelSiteDocument, baseOptions);
      }
export type CancelSiteMutationHookResult = ReturnType<typeof useCancelSiteMutation>;
export type CancelSiteMutationResult = ApolloReactCommon.MutationResult<CancelSiteMutation>;
export type CancelSiteMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSiteMutation, CancelSiteMutationVariables>;
export const UpdateSiteDocument = gql`
    mutation updateSite($siteInput: [SiteInput!]!, $input: ProjectHeaderInput!) {
  updateSite(input: $input, siteInput: $siteInput)
}
    `;
export type UpdateSiteMutationFn = ApolloReactCommon.MutationFunction<UpdateSiteMutation, UpdateSiteMutationVariables>;

/**
 * __useUpdateSiteMutation__
 *
 * To run a mutation, you first call `useUpdateSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteMutation, { data, loading, error }] = useUpdateSiteMutation({
 *   variables: {
 *      siteInput: // value for 'siteInput'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSiteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSiteMutation, UpdateSiteMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSiteMutation, UpdateSiteMutationVariables>(UpdateSiteDocument, baseOptions);
      }
export type UpdateSiteMutationHookResult = ReturnType<typeof useUpdateSiteMutation>;
export type UpdateSiteMutationResult = ApolloReactCommon.MutationResult<UpdateSiteMutation>;
export type UpdateSiteMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSiteMutation, UpdateSiteMutationVariables>;
export const DeleteProjectSiteDocument = gql`
    mutation deleteProjectSite($ID: String!) {
  deleteProjectSite(ID: $ID)
}
    `;
export type DeleteProjectSiteMutationFn = ApolloReactCommon.MutationFunction<DeleteProjectSiteMutation, DeleteProjectSiteMutationVariables>;

/**
 * __useDeleteProjectSiteMutation__
 *
 * To run a mutation, you first call `useDeleteProjectSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectSiteMutation, { data, loading, error }] = useDeleteProjectSiteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteProjectSiteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProjectSiteMutation, DeleteProjectSiteMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteProjectSiteMutation, DeleteProjectSiteMutationVariables>(DeleteProjectSiteDocument, baseOptions);
      }
export type DeleteProjectSiteMutationHookResult = ReturnType<typeof useDeleteProjectSiteMutation>;
export type DeleteProjectSiteMutationResult = ApolloReactCommon.MutationResult<DeleteProjectSiteMutation>;
export type DeleteProjectSiteMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProjectSiteMutation, DeleteProjectSiteMutationVariables>;
export const GetPublicHolidayDocument = gql`
    query getPublicHoliday($ID: String) {
  getPublicHoliday(ID: $ID) {
    ID
    name
    code
    docDate
    commonStatus
  }
}
    `;

/**
 * __useGetPublicHolidayQuery__
 *
 * To run a query within a React component, call `useGetPublicHolidayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicHolidayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicHolidayQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetPublicHolidayQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPublicHolidayQuery, GetPublicHolidayQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPublicHolidayQuery, GetPublicHolidayQueryVariables>(GetPublicHolidayDocument, baseOptions);
      }
export function useGetPublicHolidayLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPublicHolidayQuery, GetPublicHolidayQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPublicHolidayQuery, GetPublicHolidayQueryVariables>(GetPublicHolidayDocument, baseOptions);
        }
export type GetPublicHolidayQueryHookResult = ReturnType<typeof useGetPublicHolidayQuery>;
export type GetPublicHolidayLazyQueryHookResult = ReturnType<typeof useGetPublicHolidayLazyQuery>;
export type GetPublicHolidayQueryResult = ApolloReactCommon.QueryResult<GetPublicHolidayQuery, GetPublicHolidayQueryVariables>;
export const CreatePublicHolidayDocument = gql`
    mutation createPublicHoliday($input: PublicHolidayInput!) {
  createPublicHoliday(input: $input) {
    ID
  }
}
    `;
export type CreatePublicHolidayMutationFn = ApolloReactCommon.MutationFunction<CreatePublicHolidayMutation, CreatePublicHolidayMutationVariables>;

/**
 * __useCreatePublicHolidayMutation__
 *
 * To run a mutation, you first call `useCreatePublicHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublicHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublicHolidayMutation, { data, loading, error }] = useCreatePublicHolidayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePublicHolidayMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePublicHolidayMutation, CreatePublicHolidayMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePublicHolidayMutation, CreatePublicHolidayMutationVariables>(CreatePublicHolidayDocument, baseOptions);
      }
export type CreatePublicHolidayMutationHookResult = ReturnType<typeof useCreatePublicHolidayMutation>;
export type CreatePublicHolidayMutationResult = ApolloReactCommon.MutationResult<CreatePublicHolidayMutation>;
export type CreatePublicHolidayMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePublicHolidayMutation, CreatePublicHolidayMutationVariables>;
export const CancelPublicHolidayDocument = gql`
    mutation cancelPublicHoliday($input: PublicHolidayDeleteInput!) {
  cancelPublicHoliday(input: $input)
}
    `;
export type CancelPublicHolidayMutationFn = ApolloReactCommon.MutationFunction<CancelPublicHolidayMutation, CancelPublicHolidayMutationVariables>;

/**
 * __useCancelPublicHolidayMutation__
 *
 * To run a mutation, you first call `useCancelPublicHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPublicHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPublicHolidayMutation, { data, loading, error }] = useCancelPublicHolidayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelPublicHolidayMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelPublicHolidayMutation, CancelPublicHolidayMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelPublicHolidayMutation, CancelPublicHolidayMutationVariables>(CancelPublicHolidayDocument, baseOptions);
      }
export type CancelPublicHolidayMutationHookResult = ReturnType<typeof useCancelPublicHolidayMutation>;
export type CancelPublicHolidayMutationResult = ApolloReactCommon.MutationResult<CancelPublicHolidayMutation>;
export type CancelPublicHolidayMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelPublicHolidayMutation, CancelPublicHolidayMutationVariables>;
export const UpdatePublicHolidayDocument = gql`
    mutation updatePublicHoliday($input: PublicHolidayInput!) {
  updatePublicHoliday(input: $input) {
    ID
  }
}
    `;
export type UpdatePublicHolidayMutationFn = ApolloReactCommon.MutationFunction<UpdatePublicHolidayMutation, UpdatePublicHolidayMutationVariables>;

/**
 * __useUpdatePublicHolidayMutation__
 *
 * To run a mutation, you first call `useUpdatePublicHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublicHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublicHolidayMutation, { data, loading, error }] = useUpdatePublicHolidayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePublicHolidayMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePublicHolidayMutation, UpdatePublicHolidayMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePublicHolidayMutation, UpdatePublicHolidayMutationVariables>(UpdatePublicHolidayDocument, baseOptions);
      }
export type UpdatePublicHolidayMutationHookResult = ReturnType<typeof useUpdatePublicHolidayMutation>;
export type UpdatePublicHolidayMutationResult = ApolloReactCommon.MutationResult<UpdatePublicHolidayMutation>;
export type UpdatePublicHolidayMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePublicHolidayMutation, UpdatePublicHolidayMutationVariables>;
export const DeletePublicHolidayDocument = gql`
    mutation deletePublicHoliday($input: PublicHolidayDeleteInput!) {
  deletePublicHoliday(input: $input)
}
    `;
export type DeletePublicHolidayMutationFn = ApolloReactCommon.MutationFunction<DeletePublicHolidayMutation, DeletePublicHolidayMutationVariables>;

/**
 * __useDeletePublicHolidayMutation__
 *
 * To run a mutation, you first call `useDeletePublicHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePublicHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePublicHolidayMutation, { data, loading, error }] = useDeletePublicHolidayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePublicHolidayMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePublicHolidayMutation, DeletePublicHolidayMutationVariables>) {
        return ApolloReactHooks.useMutation<DeletePublicHolidayMutation, DeletePublicHolidayMutationVariables>(DeletePublicHolidayDocument, baseOptions);
      }
export type DeletePublicHolidayMutationHookResult = ReturnType<typeof useDeletePublicHolidayMutation>;
export type DeletePublicHolidayMutationResult = ApolloReactCommon.MutationResult<DeletePublicHolidayMutation>;
export type DeletePublicHolidayMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePublicHolidayMutation, DeletePublicHolidayMutationVariables>;
export const ActivatePublicHolidayDocument = gql`
    mutation activatePublicHoliday($input: PublicHolidayDeleteInput!) {
  activatePublicHoliday(input: $input)
}
    `;
export type ActivatePublicHolidayMutationFn = ApolloReactCommon.MutationFunction<ActivatePublicHolidayMutation, ActivatePublicHolidayMutationVariables>;

/**
 * __useActivatePublicHolidayMutation__
 *
 * To run a mutation, you first call `useActivatePublicHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivatePublicHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activatePublicHolidayMutation, { data, loading, error }] = useActivatePublicHolidayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivatePublicHolidayMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivatePublicHolidayMutation, ActivatePublicHolidayMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivatePublicHolidayMutation, ActivatePublicHolidayMutationVariables>(ActivatePublicHolidayDocument, baseOptions);
      }
export type ActivatePublicHolidayMutationHookResult = ReturnType<typeof useActivatePublicHolidayMutation>;
export type ActivatePublicHolidayMutationResult = ApolloReactCommon.MutationResult<ActivatePublicHolidayMutation>;
export type ActivatePublicHolidayMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivatePublicHolidayMutation, ActivatePublicHolidayMutationVariables>;
export const GetPublicHolidayByStateYearDocument = gql`
    query getPublicHolidayByStateYear($year: String!) {
  getPublicHolidayByStateYear(year: $year)
}
    `;

/**
 * __useGetPublicHolidayByStateYearQuery__
 *
 * To run a query within a React component, call `useGetPublicHolidayByStateYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicHolidayByStateYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicHolidayByStateYearQuery({
 *   variables: {
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetPublicHolidayByStateYearQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPublicHolidayByStateYearQuery, GetPublicHolidayByStateYearQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPublicHolidayByStateYearQuery, GetPublicHolidayByStateYearQueryVariables>(GetPublicHolidayByStateYearDocument, baseOptions);
      }
export function useGetPublicHolidayByStateYearLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPublicHolidayByStateYearQuery, GetPublicHolidayByStateYearQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPublicHolidayByStateYearQuery, GetPublicHolidayByStateYearQueryVariables>(GetPublicHolidayByStateYearDocument, baseOptions);
        }
export type GetPublicHolidayByStateYearQueryHookResult = ReturnType<typeof useGetPublicHolidayByStateYearQuery>;
export type GetPublicHolidayByStateYearLazyQueryHookResult = ReturnType<typeof useGetPublicHolidayByStateYearLazyQuery>;
export type GetPublicHolidayByStateYearQueryResult = ApolloReactCommon.QueryResult<GetPublicHolidayByStateYearQuery, GetPublicHolidayByStateYearQueryVariables>;
export const GetCustomerReminderDocument = gql`
    query getCustomerReminder($ID: String, $commonStatus: CommonStatus) {
  getCustomerReminder(ID: $ID, commonStatus: $commonStatus) {
    ID
    paymentReminder1
    paymentReminder2
    paymentReminder3
    paymentReminder4
    minimumAmt
    reminderFor
    printedCopy
    emailCopy
    commonStatus
  }
}
    `;

/**
 * __useGetCustomerReminderQuery__
 *
 * To run a query within a React component, call `useGetCustomerReminderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerReminderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerReminderQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      commonStatus: // value for 'commonStatus'
 *   },
 * });
 */
export function useGetCustomerReminderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomerReminderQuery, GetCustomerReminderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomerReminderQuery, GetCustomerReminderQueryVariables>(GetCustomerReminderDocument, baseOptions);
      }
export function useGetCustomerReminderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerReminderQuery, GetCustomerReminderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomerReminderQuery, GetCustomerReminderQueryVariables>(GetCustomerReminderDocument, baseOptions);
        }
export type GetCustomerReminderQueryHookResult = ReturnType<typeof useGetCustomerReminderQuery>;
export type GetCustomerReminderLazyQueryHookResult = ReturnType<typeof useGetCustomerReminderLazyQuery>;
export type GetCustomerReminderQueryResult = ApolloReactCommon.QueryResult<GetCustomerReminderQuery, GetCustomerReminderQueryVariables>;
export const CreateCustomerReminderDocument = gql`
    mutation createCustomerReminder($inputReminder: CustomerReminderInput!) {
  createCustomerReminder(inputReminder: $inputReminder)
}
    `;
export type CreateCustomerReminderMutationFn = ApolloReactCommon.MutationFunction<CreateCustomerReminderMutation, CreateCustomerReminderMutationVariables>;

/**
 * __useCreateCustomerReminderMutation__
 *
 * To run a mutation, you first call `useCreateCustomerReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerReminderMutation, { data, loading, error }] = useCreateCustomerReminderMutation({
 *   variables: {
 *      inputReminder: // value for 'inputReminder'
 *   },
 * });
 */
export function useCreateCustomerReminderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCustomerReminderMutation, CreateCustomerReminderMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCustomerReminderMutation, CreateCustomerReminderMutationVariables>(CreateCustomerReminderDocument, baseOptions);
      }
export type CreateCustomerReminderMutationHookResult = ReturnType<typeof useCreateCustomerReminderMutation>;
export type CreateCustomerReminderMutationResult = ApolloReactCommon.MutationResult<CreateCustomerReminderMutation>;
export type CreateCustomerReminderMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCustomerReminderMutation, CreateCustomerReminderMutationVariables>;
export const GetRevenueCategoryDocument = gql`
    query getRevenueCategory($ID: String, $orderByAsc: String, $commonStatus: CommonStatus) {
  getRevenueCategory(ID: $ID, orderByAsc: $orderByAsc, commonStatus: $commonStatus) {
    modTs
    ID
    name
    taxSchemeID
    taxScheme {
      ID
      code
      description
      latestTax {
        taxDate
        taxRate
      }
    }
    creditTerm
    description
    isDaywork
    commonStatus
    classificationCodeID
    classificationCode {
      id
      classificationCode
      description
    }
    eInvoiceIntegrated
  }
}
    `;

/**
 * __useGetRevenueCategoryQuery__
 *
 * To run a query within a React component, call `useGetRevenueCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRevenueCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRevenueCategoryQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *      commonStatus: // value for 'commonStatus'
 *   },
 * });
 */
export function useGetRevenueCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRevenueCategoryQuery, GetRevenueCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRevenueCategoryQuery, GetRevenueCategoryQueryVariables>(GetRevenueCategoryDocument, baseOptions);
      }
export function useGetRevenueCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRevenueCategoryQuery, GetRevenueCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRevenueCategoryQuery, GetRevenueCategoryQueryVariables>(GetRevenueCategoryDocument, baseOptions);
        }
export type GetRevenueCategoryQueryHookResult = ReturnType<typeof useGetRevenueCategoryQuery>;
export type GetRevenueCategoryLazyQueryHookResult = ReturnType<typeof useGetRevenueCategoryLazyQuery>;
export type GetRevenueCategoryQueryResult = ApolloReactCommon.QueryResult<GetRevenueCategoryQuery, GetRevenueCategoryQueryVariables>;
export const GetRevenueCategoryListingDocument = gql`
    query getRevenueCategoryListing($ID: String, $orderByAsc: String, $orderByDesc: String, $take: Float, $skip: Float, $searchValue: String) {
  getRevenueCategory(ID: $ID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    name
    taxSchemeID
    taxScheme {
      ID
      code
      description
      latestTax {
        taxDate
        taxRate
      }
    }
    creditTerm
    description
    isDaywork
    commonStatus
    classificationCodeID
    classificationCode {
      id
      classificationCode
      description
    }
    eInvoiceIntegrated
    glAccountCode
  }
  getRevenueCategoryCount(ID: $ID, searchValue: $searchValue)
}
    `;

/**
 * __useGetRevenueCategoryListingQuery__
 *
 * To run a query within a React component, call `useGetRevenueCategoryListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRevenueCategoryListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRevenueCategoryListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetRevenueCategoryListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRevenueCategoryListingQuery, GetRevenueCategoryListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRevenueCategoryListingQuery, GetRevenueCategoryListingQueryVariables>(GetRevenueCategoryListingDocument, baseOptions);
      }
export function useGetRevenueCategoryListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRevenueCategoryListingQuery, GetRevenueCategoryListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRevenueCategoryListingQuery, GetRevenueCategoryListingQueryVariables>(GetRevenueCategoryListingDocument, baseOptions);
        }
export type GetRevenueCategoryListingQueryHookResult = ReturnType<typeof useGetRevenueCategoryListingQuery>;
export type GetRevenueCategoryListingLazyQueryHookResult = ReturnType<typeof useGetRevenueCategoryListingLazyQuery>;
export type GetRevenueCategoryListingQueryResult = ApolloReactCommon.QueryResult<GetRevenueCategoryListingQuery, GetRevenueCategoryListingQueryVariables>;
export const CreateRevenueCategoryDocument = gql`
    mutation createRevenueCategory($input: RevenueCategoryInput!) {
  createRevenueCategory(input: $input) {
    name
    taxSchemeID
    creditTerm
  }
}
    `;
export type CreateRevenueCategoryMutationFn = ApolloReactCommon.MutationFunction<CreateRevenueCategoryMutation, CreateRevenueCategoryMutationVariables>;

/**
 * __useCreateRevenueCategoryMutation__
 *
 * To run a mutation, you first call `useCreateRevenueCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRevenueCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRevenueCategoryMutation, { data, loading, error }] = useCreateRevenueCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRevenueCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRevenueCategoryMutation, CreateRevenueCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRevenueCategoryMutation, CreateRevenueCategoryMutationVariables>(CreateRevenueCategoryDocument, baseOptions);
      }
export type CreateRevenueCategoryMutationHookResult = ReturnType<typeof useCreateRevenueCategoryMutation>;
export type CreateRevenueCategoryMutationResult = ApolloReactCommon.MutationResult<CreateRevenueCategoryMutation>;
export type CreateRevenueCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRevenueCategoryMutation, CreateRevenueCategoryMutationVariables>;
export const UpdateRevenueCategoryDocument = gql`
    mutation updateRevenueCategory($input: RevenueCategoryInput!) {
  updateRevenueCategory(input: $input) {
    name
    taxSchemeID
  }
}
    `;
export type UpdateRevenueCategoryMutationFn = ApolloReactCommon.MutationFunction<UpdateRevenueCategoryMutation, UpdateRevenueCategoryMutationVariables>;

/**
 * __useUpdateRevenueCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateRevenueCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRevenueCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRevenueCategoryMutation, { data, loading, error }] = useUpdateRevenueCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRevenueCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRevenueCategoryMutation, UpdateRevenueCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRevenueCategoryMutation, UpdateRevenueCategoryMutationVariables>(UpdateRevenueCategoryDocument, baseOptions);
      }
export type UpdateRevenueCategoryMutationHookResult = ReturnType<typeof useUpdateRevenueCategoryMutation>;
export type UpdateRevenueCategoryMutationResult = ApolloReactCommon.MutationResult<UpdateRevenueCategoryMutation>;
export type UpdateRevenueCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRevenueCategoryMutation, UpdateRevenueCategoryMutationVariables>;
export const DeleteRevenueCategoryDocument = gql`
    mutation deleteRevenueCategory($input: RevenueCategoryDeleteInput!) {
  deleteRevenueCategory(input: $input)
}
    `;
export type DeleteRevenueCategoryMutationFn = ApolloReactCommon.MutationFunction<DeleteRevenueCategoryMutation, DeleteRevenueCategoryMutationVariables>;

/**
 * __useDeleteRevenueCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteRevenueCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRevenueCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRevenueCategoryMutation, { data, loading, error }] = useDeleteRevenueCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRevenueCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRevenueCategoryMutation, DeleteRevenueCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRevenueCategoryMutation, DeleteRevenueCategoryMutationVariables>(DeleteRevenueCategoryDocument, baseOptions);
      }
export type DeleteRevenueCategoryMutationHookResult = ReturnType<typeof useDeleteRevenueCategoryMutation>;
export type DeleteRevenueCategoryMutationResult = ApolloReactCommon.MutationResult<DeleteRevenueCategoryMutation>;
export type DeleteRevenueCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRevenueCategoryMutation, DeleteRevenueCategoryMutationVariables>;
export const CancelRevenueCategoryDocument = gql`
    mutation cancelRevenueCategory($input: RevenueCategoryUpdateInput!) {
  cancelRevenueCategory(input: $input)
}
    `;
export type CancelRevenueCategoryMutationFn = ApolloReactCommon.MutationFunction<CancelRevenueCategoryMutation, CancelRevenueCategoryMutationVariables>;

/**
 * __useCancelRevenueCategoryMutation__
 *
 * To run a mutation, you first call `useCancelRevenueCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelRevenueCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelRevenueCategoryMutation, { data, loading, error }] = useCancelRevenueCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelRevenueCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelRevenueCategoryMutation, CancelRevenueCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelRevenueCategoryMutation, CancelRevenueCategoryMutationVariables>(CancelRevenueCategoryDocument, baseOptions);
      }
export type CancelRevenueCategoryMutationHookResult = ReturnType<typeof useCancelRevenueCategoryMutation>;
export type CancelRevenueCategoryMutationResult = ApolloReactCommon.MutationResult<CancelRevenueCategoryMutation>;
export type CancelRevenueCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelRevenueCategoryMutation, CancelRevenueCategoryMutationVariables>;
export const ActivateRevenueCategoryDocument = gql`
    mutation activateRevenueCategory($input: RevenueCategoryUpdateInput!) {
  activateRevenueCategory(input: $input)
}
    `;
export type ActivateRevenueCategoryMutationFn = ApolloReactCommon.MutationFunction<ActivateRevenueCategoryMutation, ActivateRevenueCategoryMutationVariables>;

/**
 * __useActivateRevenueCategoryMutation__
 *
 * To run a mutation, you first call `useActivateRevenueCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateRevenueCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateRevenueCategoryMutation, { data, loading, error }] = useActivateRevenueCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateRevenueCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateRevenueCategoryMutation, ActivateRevenueCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateRevenueCategoryMutation, ActivateRevenueCategoryMutationVariables>(ActivateRevenueCategoryDocument, baseOptions);
      }
export type ActivateRevenueCategoryMutationHookResult = ReturnType<typeof useActivateRevenueCategoryMutation>;
export type ActivateRevenueCategoryMutationResult = ApolloReactCommon.MutationResult<ActivateRevenueCategoryMutation>;
export type ActivateRevenueCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateRevenueCategoryMutation, ActivateRevenueCategoryMutationVariables>;
export const CreateBatchRevenueCategoryDocument = gql`
    mutation createBatchRevenueCategory($input: [RevenueCategoryInput!]!) {
  createBatchRevenueCategory(input: $input)
}
    `;
export type CreateBatchRevenueCategoryMutationFn = ApolloReactCommon.MutationFunction<CreateBatchRevenueCategoryMutation, CreateBatchRevenueCategoryMutationVariables>;

/**
 * __useCreateBatchRevenueCategoryMutation__
 *
 * To run a mutation, you first call `useCreateBatchRevenueCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBatchRevenueCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBatchRevenueCategoryMutation, { data, loading, error }] = useCreateBatchRevenueCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBatchRevenueCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBatchRevenueCategoryMutation, CreateBatchRevenueCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBatchRevenueCategoryMutation, CreateBatchRevenueCategoryMutationVariables>(CreateBatchRevenueCategoryDocument, baseOptions);
      }
export type CreateBatchRevenueCategoryMutationHookResult = ReturnType<typeof useCreateBatchRevenueCategoryMutation>;
export type CreateBatchRevenueCategoryMutationResult = ApolloReactCommon.MutationResult<CreateBatchRevenueCategoryMutation>;
export type CreateBatchRevenueCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBatchRevenueCategoryMutation, CreateBatchRevenueCategoryMutationVariables>;
export const RevenueCategoryImportValidationDocument = gql`
    mutation revenueCategoryImportValidation($RevenueCategoryImportInput: [RevenueCategoryImportInput!]!) {
  revenueCategoryImportValidation(RevenueCategoryImportInput: $RevenueCategoryImportInput)
}
    `;
export type RevenueCategoryImportValidationMutationFn = ApolloReactCommon.MutationFunction<RevenueCategoryImportValidationMutation, RevenueCategoryImportValidationMutationVariables>;

/**
 * __useRevenueCategoryImportValidationMutation__
 *
 * To run a mutation, you first call `useRevenueCategoryImportValidationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevenueCategoryImportValidationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revenueCategoryImportValidationMutation, { data, loading, error }] = useRevenueCategoryImportValidationMutation({
 *   variables: {
 *      RevenueCategoryImportInput: // value for 'RevenueCategoryImportInput'
 *   },
 * });
 */
export function useRevenueCategoryImportValidationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RevenueCategoryImportValidationMutation, RevenueCategoryImportValidationMutationVariables>) {
        return ApolloReactHooks.useMutation<RevenueCategoryImportValidationMutation, RevenueCategoryImportValidationMutationVariables>(RevenueCategoryImportValidationDocument, baseOptions);
      }
export type RevenueCategoryImportValidationMutationHookResult = ReturnType<typeof useRevenueCategoryImportValidationMutation>;
export type RevenueCategoryImportValidationMutationResult = ApolloReactCommon.MutationResult<RevenueCategoryImportValidationMutation>;
export type RevenueCategoryImportValidationMutationOptions = ApolloReactCommon.BaseMutationOptions<RevenueCategoryImportValidationMutation, RevenueCategoryImportValidationMutationVariables>;
export const RevenueCategoryImportDocument = gql`
    mutation revenueCategoryImport($batchID: String!) {
  revenueCategoryImport(batchID: $batchID)
}
    `;
export type RevenueCategoryImportMutationFn = ApolloReactCommon.MutationFunction<RevenueCategoryImportMutation, RevenueCategoryImportMutationVariables>;

/**
 * __useRevenueCategoryImportMutation__
 *
 * To run a mutation, you first call `useRevenueCategoryImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevenueCategoryImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revenueCategoryImportMutation, { data, loading, error }] = useRevenueCategoryImportMutation({
 *   variables: {
 *      batchID: // value for 'batchID'
 *   },
 * });
 */
export function useRevenueCategoryImportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RevenueCategoryImportMutation, RevenueCategoryImportMutationVariables>) {
        return ApolloReactHooks.useMutation<RevenueCategoryImportMutation, RevenueCategoryImportMutationVariables>(RevenueCategoryImportDocument, baseOptions);
      }
export type RevenueCategoryImportMutationHookResult = ReturnType<typeof useRevenueCategoryImportMutation>;
export type RevenueCategoryImportMutationResult = ApolloReactCommon.MutationResult<RevenueCategoryImportMutation>;
export type RevenueCategoryImportMutationOptions = ApolloReactCommon.BaseMutationOptions<RevenueCategoryImportMutation, RevenueCategoryImportMutationVariables>;
export const GetSiteMaterialDocument = gql`
    query getSiteMaterial($ID: String, $siteID: String, $commonStatus: CommonStatus, $orderByAsc: String) {
  getSiteMaterial(ID: $ID, siteID: $siteID, commonStatus: $commonStatus, orderByAsc: $orderByAsc) {
    ID
    name
    stockCode
    uomID
    uom {
      ID
      code
      name
    }
    grnItem {
      ID
    }
    stockMovement {
      ID
    }
  }
}
    `;

/**
 * __useGetSiteMaterialQuery__
 *
 * To run a query within a React component, call `useGetSiteMaterialQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteMaterialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteMaterialQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      siteID: // value for 'siteID'
 *      commonStatus: // value for 'commonStatus'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetSiteMaterialQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteMaterialQuery, GetSiteMaterialQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteMaterialQuery, GetSiteMaterialQueryVariables>(GetSiteMaterialDocument, baseOptions);
      }
export function useGetSiteMaterialLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteMaterialQuery, GetSiteMaterialQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteMaterialQuery, GetSiteMaterialQueryVariables>(GetSiteMaterialDocument, baseOptions);
        }
export type GetSiteMaterialQueryHookResult = ReturnType<typeof useGetSiteMaterialQuery>;
export type GetSiteMaterialLazyQueryHookResult = ReturnType<typeof useGetSiteMaterialLazyQuery>;
export type GetSiteMaterialQueryResult = ApolloReactCommon.QueryResult<GetSiteMaterialQuery, GetSiteMaterialQueryVariables>;
export const CreateSiteMaterialDocument = gql`
    mutation createSiteMaterial($input: SiteMaterialInput!) {
  createSiteMaterial(input: $input) {
    ID
    name
    uom {
      ID
      name
    }
  }
}
    `;
export type CreateSiteMaterialMutationFn = ApolloReactCommon.MutationFunction<CreateSiteMaterialMutation, CreateSiteMaterialMutationVariables>;

/**
 * __useCreateSiteMaterialMutation__
 *
 * To run a mutation, you first call `useCreateSiteMaterialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSiteMaterialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSiteMaterialMutation, { data, loading, error }] = useCreateSiteMaterialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSiteMaterialMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSiteMaterialMutation, CreateSiteMaterialMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSiteMaterialMutation, CreateSiteMaterialMutationVariables>(CreateSiteMaterialDocument, baseOptions);
      }
export type CreateSiteMaterialMutationHookResult = ReturnType<typeof useCreateSiteMaterialMutation>;
export type CreateSiteMaterialMutationResult = ApolloReactCommon.MutationResult<CreateSiteMaterialMutation>;
export type CreateSiteMaterialMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSiteMaterialMutation, CreateSiteMaterialMutationVariables>;
export const DeleteSiteMaterialDocument = gql`
    mutation deleteSiteMaterial($input: SiteMaterialDeleteInput!) {
  deleteSiteMaterial(input: $input)
}
    `;
export type DeleteSiteMaterialMutationFn = ApolloReactCommon.MutationFunction<DeleteSiteMaterialMutation, DeleteSiteMaterialMutationVariables>;

/**
 * __useDeleteSiteMaterialMutation__
 *
 * To run a mutation, you first call `useDeleteSiteMaterialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSiteMaterialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSiteMaterialMutation, { data, loading, error }] = useDeleteSiteMaterialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSiteMaterialMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSiteMaterialMutation, DeleteSiteMaterialMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSiteMaterialMutation, DeleteSiteMaterialMutationVariables>(DeleteSiteMaterialDocument, baseOptions);
      }
export type DeleteSiteMaterialMutationHookResult = ReturnType<typeof useDeleteSiteMaterialMutation>;
export type DeleteSiteMaterialMutationResult = ApolloReactCommon.MutationResult<DeleteSiteMaterialMutation>;
export type DeleteSiteMaterialMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSiteMaterialMutation, DeleteSiteMaterialMutationVariables>;
export const CancelSiteMaterialDocument = gql`
    mutation cancelSiteMaterial($input: SiteMaterialDeleteInput!) {
  cancelSiteMaterial(input: $input)
}
    `;
export type CancelSiteMaterialMutationFn = ApolloReactCommon.MutationFunction<CancelSiteMaterialMutation, CancelSiteMaterialMutationVariables>;

/**
 * __useCancelSiteMaterialMutation__
 *
 * To run a mutation, you first call `useCancelSiteMaterialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSiteMaterialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSiteMaterialMutation, { data, loading, error }] = useCancelSiteMaterialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSiteMaterialMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSiteMaterialMutation, CancelSiteMaterialMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSiteMaterialMutation, CancelSiteMaterialMutationVariables>(CancelSiteMaterialDocument, baseOptions);
      }
export type CancelSiteMaterialMutationHookResult = ReturnType<typeof useCancelSiteMaterialMutation>;
export type CancelSiteMaterialMutationResult = ApolloReactCommon.MutationResult<CancelSiteMaterialMutation>;
export type CancelSiteMaterialMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSiteMaterialMutation, CancelSiteMaterialMutationVariables>;
export const UpdateSiteMaterialDocument = gql`
    mutation updateSiteMaterial($input: SiteMaterialInput!) {
  updateSiteMaterial(input: $input) {
    ID
  }
}
    `;
export type UpdateSiteMaterialMutationFn = ApolloReactCommon.MutationFunction<UpdateSiteMaterialMutation, UpdateSiteMaterialMutationVariables>;

/**
 * __useUpdateSiteMaterialMutation__
 *
 * To run a mutation, you first call `useUpdateSiteMaterialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteMaterialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteMaterialMutation, { data, loading, error }] = useUpdateSiteMaterialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSiteMaterialMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSiteMaterialMutation, UpdateSiteMaterialMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSiteMaterialMutation, UpdateSiteMaterialMutationVariables>(UpdateSiteMaterialDocument, baseOptions);
      }
export type UpdateSiteMaterialMutationHookResult = ReturnType<typeof useUpdateSiteMaterialMutation>;
export type UpdateSiteMaterialMutationResult = ApolloReactCommon.MutationResult<UpdateSiteMaterialMutation>;
export type UpdateSiteMaterialMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSiteMaterialMutation, UpdateSiteMaterialMutationVariables>;
export const CreateBulkSiteMaterialDocument = gql`
    mutation createBulkSiteMaterial($input: [SiteMaterialInput!]!) {
  createBulkSiteMaterial(input: $input)
}
    `;
export type CreateBulkSiteMaterialMutationFn = ApolloReactCommon.MutationFunction<CreateBulkSiteMaterialMutation, CreateBulkSiteMaterialMutationVariables>;

/**
 * __useCreateBulkSiteMaterialMutation__
 *
 * To run a mutation, you first call `useCreateBulkSiteMaterialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBulkSiteMaterialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBulkSiteMaterialMutation, { data, loading, error }] = useCreateBulkSiteMaterialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBulkSiteMaterialMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBulkSiteMaterialMutation, CreateBulkSiteMaterialMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBulkSiteMaterialMutation, CreateBulkSiteMaterialMutationVariables>(CreateBulkSiteMaterialDocument, baseOptions);
      }
export type CreateBulkSiteMaterialMutationHookResult = ReturnType<typeof useCreateBulkSiteMaterialMutation>;
export type CreateBulkSiteMaterialMutationResult = ApolloReactCommon.MutationResult<CreateBulkSiteMaterialMutation>;
export type CreateBulkSiteMaterialMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBulkSiteMaterialMutation, CreateBulkSiteMaterialMutationVariables>;
export const CreateMaterialPolicyDocument = gql`
    mutation createMaterialPolicy($procurementSubscriber: Boolean!, $materialIDs: [SiteMaterialPolicyInput!]!) {
  createMaterialPolicy(procurementSubscriber: $procurementSubscriber, materialIDs: $materialIDs)
}
    `;
export type CreateMaterialPolicyMutationFn = ApolloReactCommon.MutationFunction<CreateMaterialPolicyMutation, CreateMaterialPolicyMutationVariables>;

/**
 * __useCreateMaterialPolicyMutation__
 *
 * To run a mutation, you first call `useCreateMaterialPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMaterialPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMaterialPolicyMutation, { data, loading, error }] = useCreateMaterialPolicyMutation({
 *   variables: {
 *      procurementSubscriber: // value for 'procurementSubscriber'
 *      materialIDs: // value for 'materialIDs'
 *   },
 * });
 */
export function useCreateMaterialPolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMaterialPolicyMutation, CreateMaterialPolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMaterialPolicyMutation, CreateMaterialPolicyMutationVariables>(CreateMaterialPolicyDocument, baseOptions);
      }
export type CreateMaterialPolicyMutationHookResult = ReturnType<typeof useCreateMaterialPolicyMutation>;
export type CreateMaterialPolicyMutationResult = ApolloReactCommon.MutationResult<CreateMaterialPolicyMutation>;
export type CreateMaterialPolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMaterialPolicyMutation, CreateMaterialPolicyMutationVariables>;
export const CreateMaterialContractPolicyDocument = gql`
    mutation createMaterialContractPolicy($procurementSubscriber: Boolean!, $materialIDs: [SiteMaterialPolicyInput!]!, $contractID: String!) {
  createMaterialContractPolicy(procurementSubscriber: $procurementSubscriber, materialIDs: $materialIDs, contractID: $contractID)
}
    `;
export type CreateMaterialContractPolicyMutationFn = ApolloReactCommon.MutationFunction<CreateMaterialContractPolicyMutation, CreateMaterialContractPolicyMutationVariables>;

/**
 * __useCreateMaterialContractPolicyMutation__
 *
 * To run a mutation, you first call `useCreateMaterialContractPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMaterialContractPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMaterialContractPolicyMutation, { data, loading, error }] = useCreateMaterialContractPolicyMutation({
 *   variables: {
 *      procurementSubscriber: // value for 'procurementSubscriber'
 *      materialIDs: // value for 'materialIDs'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useCreateMaterialContractPolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMaterialContractPolicyMutation, CreateMaterialContractPolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMaterialContractPolicyMutation, CreateMaterialContractPolicyMutationVariables>(CreateMaterialContractPolicyDocument, baseOptions);
      }
export type CreateMaterialContractPolicyMutationHookResult = ReturnType<typeof useCreateMaterialContractPolicyMutation>;
export type CreateMaterialContractPolicyMutationResult = ApolloReactCommon.MutationResult<CreateMaterialContractPolicyMutation>;
export type CreateMaterialContractPolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMaterialContractPolicyMutation, CreateMaterialContractPolicyMutationVariables>;
export const GetDefaultMaterialPolicyIDsDocument = gql`
    query getDefaultMaterialPolicyIDs($contractID: String) {
  getDefaultMaterialPolicyIDs(contractID: $contractID)
}
    `;

/**
 * __useGetDefaultMaterialPolicyIDsQuery__
 *
 * To run a query within a React component, call `useGetDefaultMaterialPolicyIDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultMaterialPolicyIDsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultMaterialPolicyIDsQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetDefaultMaterialPolicyIDsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDefaultMaterialPolicyIDsQuery, GetDefaultMaterialPolicyIDsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDefaultMaterialPolicyIDsQuery, GetDefaultMaterialPolicyIDsQueryVariables>(GetDefaultMaterialPolicyIDsDocument, baseOptions);
      }
export function useGetDefaultMaterialPolicyIDsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDefaultMaterialPolicyIDsQuery, GetDefaultMaterialPolicyIDsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDefaultMaterialPolicyIDsQuery, GetDefaultMaterialPolicyIDsQueryVariables>(GetDefaultMaterialPolicyIDsDocument, baseOptions);
        }
export type GetDefaultMaterialPolicyIDsQueryHookResult = ReturnType<typeof useGetDefaultMaterialPolicyIDsQuery>;
export type GetDefaultMaterialPolicyIDsLazyQueryHookResult = ReturnType<typeof useGetDefaultMaterialPolicyIDsLazyQuery>;
export type GetDefaultMaterialPolicyIDsQueryResult = ApolloReactCommon.QueryResult<GetDefaultMaterialPolicyIDsQuery, GetDefaultMaterialPolicyIDsQueryVariables>;
export const CreateSiteWorkersDocument = gql`
    mutation createSiteWorkers($input: SiteWorkersInput!) {
  createSiteWorkers(input: $input) {
    ID
  }
}
    `;
export type CreateSiteWorkersMutationFn = ApolloReactCommon.MutationFunction<CreateSiteWorkersMutation, CreateSiteWorkersMutationVariables>;

/**
 * __useCreateSiteWorkersMutation__
 *
 * To run a mutation, you first call `useCreateSiteWorkersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSiteWorkersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSiteWorkersMutation, { data, loading, error }] = useCreateSiteWorkersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSiteWorkersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSiteWorkersMutation, CreateSiteWorkersMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSiteWorkersMutation, CreateSiteWorkersMutationVariables>(CreateSiteWorkersDocument, baseOptions);
      }
export type CreateSiteWorkersMutationHookResult = ReturnType<typeof useCreateSiteWorkersMutation>;
export type CreateSiteWorkersMutationResult = ApolloReactCommon.MutationResult<CreateSiteWorkersMutation>;
export type CreateSiteWorkersMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSiteWorkersMutation, CreateSiteWorkersMutationVariables>;
export const UpdateSiteWorkersDocument = gql`
    mutation updateSiteWorkers($input: SiteWorkersInput!) {
  updateSiteWorkers(input: $input) {
    ID
  }
}
    `;
export type UpdateSiteWorkersMutationFn = ApolloReactCommon.MutationFunction<UpdateSiteWorkersMutation, UpdateSiteWorkersMutationVariables>;

/**
 * __useUpdateSiteWorkersMutation__
 *
 * To run a mutation, you first call `useUpdateSiteWorkersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteWorkersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteWorkersMutation, { data, loading, error }] = useUpdateSiteWorkersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSiteWorkersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSiteWorkersMutation, UpdateSiteWorkersMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSiteWorkersMutation, UpdateSiteWorkersMutationVariables>(UpdateSiteWorkersDocument, baseOptions);
      }
export type UpdateSiteWorkersMutationHookResult = ReturnType<typeof useUpdateSiteWorkersMutation>;
export type UpdateSiteWorkersMutationResult = ApolloReactCommon.MutationResult<UpdateSiteWorkersMutation>;
export type UpdateSiteWorkersMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSiteWorkersMutation, UpdateSiteWorkersMutationVariables>;
export const CreateSubcontractorTypeDocument = gql`
    mutation createSubcontractorType($input: SubcontractorTypeInput!) {
  createSubcontractorType(input: $input) {
    ID
  }
}
    `;
export type CreateSubcontractorTypeMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractorTypeMutation, CreateSubcontractorTypeMutationVariables>;

/**
 * __useCreateSubcontractorTypeMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractorTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractorTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractorTypeMutation, { data, loading, error }] = useCreateSubcontractorTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubcontractorTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractorTypeMutation, CreateSubcontractorTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractorTypeMutation, CreateSubcontractorTypeMutationVariables>(CreateSubcontractorTypeDocument, baseOptions);
      }
export type CreateSubcontractorTypeMutationHookResult = ReturnType<typeof useCreateSubcontractorTypeMutation>;
export type CreateSubcontractorTypeMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractorTypeMutation>;
export type CreateSubcontractorTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractorTypeMutation, CreateSubcontractorTypeMutationVariables>;
export const UpdateSubcontractorTypeDocument = gql`
    mutation updateSubcontractorType($input: SubcontractorTypeInput!) {
  updateSubcontractorType(input: $input) {
    ID
  }
}
    `;
export type UpdateSubcontractorTypeMutationFn = ApolloReactCommon.MutationFunction<UpdateSubcontractorTypeMutation, UpdateSubcontractorTypeMutationVariables>;

/**
 * __useUpdateSubcontractorTypeMutation__
 *
 * To run a mutation, you first call `useUpdateSubcontractorTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubcontractorTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubcontractorTypeMutation, { data, loading, error }] = useUpdateSubcontractorTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubcontractorTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubcontractorTypeMutation, UpdateSubcontractorTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubcontractorTypeMutation, UpdateSubcontractorTypeMutationVariables>(UpdateSubcontractorTypeDocument, baseOptions);
      }
export type UpdateSubcontractorTypeMutationHookResult = ReturnType<typeof useUpdateSubcontractorTypeMutation>;
export type UpdateSubcontractorTypeMutationResult = ApolloReactCommon.MutationResult<UpdateSubcontractorTypeMutation>;
export type UpdateSubcontractorTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubcontractorTypeMutation, UpdateSubcontractorTypeMutationVariables>;
export const GetSubcontractorTypeDocument = gql`
    query getSubcontractorType($ID: String, $orderByAsc: String, $orderByDesc: String, $commonStatus: CommonStatus) {
  getSubcontractorType(ID: $ID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, commonStatus: $commonStatus) {
    ID
    description
    glAccountCode
    accrualAccountCode
    commonStatus
    modTs
  }
}
    `;

/**
 * __useGetSubcontractorTypeQuery__
 *
 * To run a query within a React component, call `useGetSubcontractorTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractorTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractorTypeQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      commonStatus: // value for 'commonStatus'
 *   },
 * });
 */
export function useGetSubcontractorTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractorTypeQuery, GetSubcontractorTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractorTypeQuery, GetSubcontractorTypeQueryVariables>(GetSubcontractorTypeDocument, baseOptions);
      }
export function useGetSubcontractorTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractorTypeQuery, GetSubcontractorTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractorTypeQuery, GetSubcontractorTypeQueryVariables>(GetSubcontractorTypeDocument, baseOptions);
        }
export type GetSubcontractorTypeQueryHookResult = ReturnType<typeof useGetSubcontractorTypeQuery>;
export type GetSubcontractorTypeLazyQueryHookResult = ReturnType<typeof useGetSubcontractorTypeLazyQuery>;
export type GetSubcontractorTypeQueryResult = ApolloReactCommon.QueryResult<GetSubcontractorTypeQuery, GetSubcontractorTypeQueryVariables>;
export const DeleteSubcontractorTypeDocument = gql`
    mutation deleteSubcontractorType($ID: SubcontractorTypeDeleteInput!) {
  deleteSubcontractorType(input: $ID)
}
    `;
export type DeleteSubcontractorTypeMutationFn = ApolloReactCommon.MutationFunction<DeleteSubcontractorTypeMutation, DeleteSubcontractorTypeMutationVariables>;

/**
 * __useDeleteSubcontractorTypeMutation__
 *
 * To run a mutation, you first call `useDeleteSubcontractorTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubcontractorTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubcontractorTypeMutation, { data, loading, error }] = useDeleteSubcontractorTypeMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteSubcontractorTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSubcontractorTypeMutation, DeleteSubcontractorTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSubcontractorTypeMutation, DeleteSubcontractorTypeMutationVariables>(DeleteSubcontractorTypeDocument, baseOptions);
      }
export type DeleteSubcontractorTypeMutationHookResult = ReturnType<typeof useDeleteSubcontractorTypeMutation>;
export type DeleteSubcontractorTypeMutationResult = ApolloReactCommon.MutationResult<DeleteSubcontractorTypeMutation>;
export type DeleteSubcontractorTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSubcontractorTypeMutation, DeleteSubcontractorTypeMutationVariables>;
export const CancelSubcontractorTypeDocument = gql`
    mutation cancelSubcontractorType($input: SubcontractorTypeInput!) {
  cancelSubcontractorType(input: $input)
}
    `;
export type CancelSubcontractorTypeMutationFn = ApolloReactCommon.MutationFunction<CancelSubcontractorTypeMutation, CancelSubcontractorTypeMutationVariables>;

/**
 * __useCancelSubcontractorTypeMutation__
 *
 * To run a mutation, you first call `useCancelSubcontractorTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubcontractorTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubcontractorTypeMutation, { data, loading, error }] = useCancelSubcontractorTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSubcontractorTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSubcontractorTypeMutation, CancelSubcontractorTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSubcontractorTypeMutation, CancelSubcontractorTypeMutationVariables>(CancelSubcontractorTypeDocument, baseOptions);
      }
export type CancelSubcontractorTypeMutationHookResult = ReturnType<typeof useCancelSubcontractorTypeMutation>;
export type CancelSubcontractorTypeMutationResult = ApolloReactCommon.MutationResult<CancelSubcontractorTypeMutation>;
export type CancelSubcontractorTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSubcontractorTypeMutation, CancelSubcontractorTypeMutationVariables>;
export const ActivateSubcontractorTypeDocument = gql`
    mutation activateSubcontractorType($input: SubcontractorTypeInput!) {
  activateSubcontractorType(input: $input)
}
    `;
export type ActivateSubcontractorTypeMutationFn = ApolloReactCommon.MutationFunction<ActivateSubcontractorTypeMutation, ActivateSubcontractorTypeMutationVariables>;

/**
 * __useActivateSubcontractorTypeMutation__
 *
 * To run a mutation, you first call `useActivateSubcontractorTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateSubcontractorTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateSubcontractorTypeMutation, { data, loading, error }] = useActivateSubcontractorTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateSubcontractorTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateSubcontractorTypeMutation, ActivateSubcontractorTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateSubcontractorTypeMutation, ActivateSubcontractorTypeMutationVariables>(ActivateSubcontractorTypeDocument, baseOptions);
      }
export type ActivateSubcontractorTypeMutationHookResult = ReturnType<typeof useActivateSubcontractorTypeMutation>;
export type ActivateSubcontractorTypeMutationResult = ApolloReactCommon.MutationResult<ActivateSubcontractorTypeMutation>;
export type ActivateSubcontractorTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateSubcontractorTypeMutation, ActivateSubcontractorTypeMutationVariables>;
export const UpdateSupplierTypeDocument = gql`
    mutation updateSupplierType($input: SupplierTypeInput!) {
  updateSupplierType(input: $input) {
    ID
  }
}
    `;
export type UpdateSupplierTypeMutationFn = ApolloReactCommon.MutationFunction<UpdateSupplierTypeMutation, UpdateSupplierTypeMutationVariables>;

/**
 * __useUpdateSupplierTypeMutation__
 *
 * To run a mutation, you first call `useUpdateSupplierTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplierTypeMutation, { data, loading, error }] = useUpdateSupplierTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSupplierTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSupplierTypeMutation, UpdateSupplierTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSupplierTypeMutation, UpdateSupplierTypeMutationVariables>(UpdateSupplierTypeDocument, baseOptions);
      }
export type UpdateSupplierTypeMutationHookResult = ReturnType<typeof useUpdateSupplierTypeMutation>;
export type UpdateSupplierTypeMutationResult = ApolloReactCommon.MutationResult<UpdateSupplierTypeMutation>;
export type UpdateSupplierTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSupplierTypeMutation, UpdateSupplierTypeMutationVariables>;
export const CreateSupplierTypeDocument = gql`
    mutation createSupplierType($input: SupplierTypeInput!) {
  createSupplierType(input: $input) {
    ID
  }
}
    `;
export type CreateSupplierTypeMutationFn = ApolloReactCommon.MutationFunction<CreateSupplierTypeMutation, CreateSupplierTypeMutationVariables>;

/**
 * __useCreateSupplierTypeMutation__
 *
 * To run a mutation, you first call `useCreateSupplierTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplierTypeMutation, { data, loading, error }] = useCreateSupplierTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSupplierTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSupplierTypeMutation, CreateSupplierTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSupplierTypeMutation, CreateSupplierTypeMutationVariables>(CreateSupplierTypeDocument, baseOptions);
      }
export type CreateSupplierTypeMutationHookResult = ReturnType<typeof useCreateSupplierTypeMutation>;
export type CreateSupplierTypeMutationResult = ApolloReactCommon.MutationResult<CreateSupplierTypeMutation>;
export type CreateSupplierTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSupplierTypeMutation, CreateSupplierTypeMutationVariables>;
export const GetSupplierTypeDocument = gql`
    query getSupplierType($ID: String, $orderByAsc: String, $orderByDesc: String, $commonStatus: CommonStatus) {
  getSupplierType(ID: $ID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, commonStatus: $commonStatus) {
    ID
    description
    glAccountCode
    accrualAccountCode
    commonStatus
    modTs
  }
}
    `;

/**
 * __useGetSupplierTypeQuery__
 *
 * To run a query within a React component, call `useGetSupplierTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierTypeQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      commonStatus: // value for 'commonStatus'
 *   },
 * });
 */
export function useGetSupplierTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierTypeQuery, GetSupplierTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierTypeQuery, GetSupplierTypeQueryVariables>(GetSupplierTypeDocument, baseOptions);
      }
export function useGetSupplierTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierTypeQuery, GetSupplierTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierTypeQuery, GetSupplierTypeQueryVariables>(GetSupplierTypeDocument, baseOptions);
        }
export type GetSupplierTypeQueryHookResult = ReturnType<typeof useGetSupplierTypeQuery>;
export type GetSupplierTypeLazyQueryHookResult = ReturnType<typeof useGetSupplierTypeLazyQuery>;
export type GetSupplierTypeQueryResult = ApolloReactCommon.QueryResult<GetSupplierTypeQuery, GetSupplierTypeQueryVariables>;
export const DeleteSupplierTypeDocument = gql`
    mutation deleteSupplierType($ID: SupplierTypeDeleteInput!) {
  deleteSupplierType(input: $ID)
}
    `;
export type DeleteSupplierTypeMutationFn = ApolloReactCommon.MutationFunction<DeleteSupplierTypeMutation, DeleteSupplierTypeMutationVariables>;

/**
 * __useDeleteSupplierTypeMutation__
 *
 * To run a mutation, you first call `useDeleteSupplierTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSupplierTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSupplierTypeMutation, { data, loading, error }] = useDeleteSupplierTypeMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteSupplierTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSupplierTypeMutation, DeleteSupplierTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSupplierTypeMutation, DeleteSupplierTypeMutationVariables>(DeleteSupplierTypeDocument, baseOptions);
      }
export type DeleteSupplierTypeMutationHookResult = ReturnType<typeof useDeleteSupplierTypeMutation>;
export type DeleteSupplierTypeMutationResult = ApolloReactCommon.MutationResult<DeleteSupplierTypeMutation>;
export type DeleteSupplierTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSupplierTypeMutation, DeleteSupplierTypeMutationVariables>;
export const CancelSupplierTypeDocument = gql`
    mutation cancelSupplierType($input: SupplierTypeInput!) {
  cancelSupplierType(input: $input)
}
    `;
export type CancelSupplierTypeMutationFn = ApolloReactCommon.MutationFunction<CancelSupplierTypeMutation, CancelSupplierTypeMutationVariables>;

/**
 * __useCancelSupplierTypeMutation__
 *
 * To run a mutation, you first call `useCancelSupplierTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSupplierTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSupplierTypeMutation, { data, loading, error }] = useCancelSupplierTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSupplierTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSupplierTypeMutation, CancelSupplierTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSupplierTypeMutation, CancelSupplierTypeMutationVariables>(CancelSupplierTypeDocument, baseOptions);
      }
export type CancelSupplierTypeMutationHookResult = ReturnType<typeof useCancelSupplierTypeMutation>;
export type CancelSupplierTypeMutationResult = ApolloReactCommon.MutationResult<CancelSupplierTypeMutation>;
export type CancelSupplierTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSupplierTypeMutation, CancelSupplierTypeMutationVariables>;
export const ActivateSupplierTypeDocument = gql`
    mutation activateSupplierType($input: SupplierTypeInput!) {
  activateSupplierType(input: $input)
}
    `;
export type ActivateSupplierTypeMutationFn = ApolloReactCommon.MutationFunction<ActivateSupplierTypeMutation, ActivateSupplierTypeMutationVariables>;

/**
 * __useActivateSupplierTypeMutation__
 *
 * To run a mutation, you first call `useActivateSupplierTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateSupplierTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateSupplierTypeMutation, { data, loading, error }] = useActivateSupplierTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateSupplierTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateSupplierTypeMutation, ActivateSupplierTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateSupplierTypeMutation, ActivateSupplierTypeMutationVariables>(ActivateSupplierTypeDocument, baseOptions);
      }
export type ActivateSupplierTypeMutationHookResult = ReturnType<typeof useActivateSupplierTypeMutation>;
export type ActivateSupplierTypeMutationResult = ApolloReactCommon.MutationResult<ActivateSupplierTypeMutation>;
export type ActivateSupplierTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateSupplierTypeMutation, ActivateSupplierTypeMutationVariables>;
export const GetVideoCamDocument = gql`
    query getVideoCam($ID: String, $orderByDesc: String) {
  getVideoCam(ID: $ID, orderByDesc: $orderByDesc) {
    ID
    name
    siteID
    networkConnection
    userName
    password
  }
}
    `;

/**
 * __useGetVideoCamQuery__
 *
 * To run a query within a React component, call `useGetVideoCamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoCamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoCamQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetVideoCamQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetVideoCamQuery, GetVideoCamQueryVariables>) {
        return ApolloReactHooks.useQuery<GetVideoCamQuery, GetVideoCamQueryVariables>(GetVideoCamDocument, baseOptions);
      }
export function useGetVideoCamLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetVideoCamQuery, GetVideoCamQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetVideoCamQuery, GetVideoCamQueryVariables>(GetVideoCamDocument, baseOptions);
        }
export type GetVideoCamQueryHookResult = ReturnType<typeof useGetVideoCamQuery>;
export type GetVideoCamLazyQueryHookResult = ReturnType<typeof useGetVideoCamLazyQuery>;
export type GetVideoCamQueryResult = ApolloReactCommon.QueryResult<GetVideoCamQuery, GetVideoCamQueryVariables>;
export const CreateVideoCamDocument = gql`
    mutation createVideoCam($input: VideoCamInput!) {
  createVideoCam(input: $input) {
    ID
  }
}
    `;
export type CreateVideoCamMutationFn = ApolloReactCommon.MutationFunction<CreateVideoCamMutation, CreateVideoCamMutationVariables>;

/**
 * __useCreateVideoCamMutation__
 *
 * To run a mutation, you first call `useCreateVideoCamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVideoCamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVideoCamMutation, { data, loading, error }] = useCreateVideoCamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVideoCamMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateVideoCamMutation, CreateVideoCamMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateVideoCamMutation, CreateVideoCamMutationVariables>(CreateVideoCamDocument, baseOptions);
      }
export type CreateVideoCamMutationHookResult = ReturnType<typeof useCreateVideoCamMutation>;
export type CreateVideoCamMutationResult = ApolloReactCommon.MutationResult<CreateVideoCamMutation>;
export type CreateVideoCamMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateVideoCamMutation, CreateVideoCamMutationVariables>;
export const CancelVideoCamDocument = gql`
    mutation cancelVideoCam($input: VideoCamDeleteInput!) {
  cancelVideoCam(input: $input)
}
    `;
export type CancelVideoCamMutationFn = ApolloReactCommon.MutationFunction<CancelVideoCamMutation, CancelVideoCamMutationVariables>;

/**
 * __useCancelVideoCamMutation__
 *
 * To run a mutation, you first call `useCancelVideoCamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelVideoCamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelVideoCamMutation, { data, loading, error }] = useCancelVideoCamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelVideoCamMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelVideoCamMutation, CancelVideoCamMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelVideoCamMutation, CancelVideoCamMutationVariables>(CancelVideoCamDocument, baseOptions);
      }
export type CancelVideoCamMutationHookResult = ReturnType<typeof useCancelVideoCamMutation>;
export type CancelVideoCamMutationResult = ApolloReactCommon.MutationResult<CancelVideoCamMutation>;
export type CancelVideoCamMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelVideoCamMutation, CancelVideoCamMutationVariables>;
export const UpdateVideoCamDocument = gql`
    mutation updateVideoCam($input: VideoCamInput!) {
  updateVideoCam(input: $input) {
    ID
  }
}
    `;
export type UpdateVideoCamMutationFn = ApolloReactCommon.MutationFunction<UpdateVideoCamMutation, UpdateVideoCamMutationVariables>;

/**
 * __useUpdateVideoCamMutation__
 *
 * To run a mutation, you first call `useUpdateVideoCamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVideoCamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVideoCamMutation, { data, loading, error }] = useUpdateVideoCamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVideoCamMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateVideoCamMutation, UpdateVideoCamMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateVideoCamMutation, UpdateVideoCamMutationVariables>(UpdateVideoCamDocument, baseOptions);
      }
export type UpdateVideoCamMutationHookResult = ReturnType<typeof useUpdateVideoCamMutation>;
export type UpdateVideoCamMutationResult = ApolloReactCommon.MutationResult<UpdateVideoCamMutation>;
export type UpdateVideoCamMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateVideoCamMutation, UpdateVideoCamMutationVariables>;
export const DeleteVideoCamDocument = gql`
    mutation deleteVideoCam($input: VideoCamDeleteInput!) {
  deleteVideoCam(input: $input)
}
    `;
export type DeleteVideoCamMutationFn = ApolloReactCommon.MutationFunction<DeleteVideoCamMutation, DeleteVideoCamMutationVariables>;

/**
 * __useDeleteVideoCamMutation__
 *
 * To run a mutation, you first call `useDeleteVideoCamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVideoCamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVideoCamMutation, { data, loading, error }] = useDeleteVideoCamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteVideoCamMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteVideoCamMutation, DeleteVideoCamMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteVideoCamMutation, DeleteVideoCamMutationVariables>(DeleteVideoCamDocument, baseOptions);
      }
export type DeleteVideoCamMutationHookResult = ReturnType<typeof useDeleteVideoCamMutation>;
export type DeleteVideoCamMutationResult = ApolloReactCommon.MutationResult<DeleteVideoCamMutation>;
export type DeleteVideoCamMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteVideoCamMutation, DeleteVideoCamMutationVariables>;
export const GetHolidayTypeDocument = gql`
    query getHolidayType($ID: String) {
  getHolidayType(ID: $ID) {
    ID
    name
    createdTs
    wagesRatePolicy {
      rate
    }
  }
}
    `;

/**
 * __useGetHolidayTypeQuery__
 *
 * To run a query within a React component, call `useGetHolidayTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHolidayTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHolidayTypeQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetHolidayTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetHolidayTypeQuery, GetHolidayTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetHolidayTypeQuery, GetHolidayTypeQueryVariables>(GetHolidayTypeDocument, baseOptions);
      }
export function useGetHolidayTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHolidayTypeQuery, GetHolidayTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetHolidayTypeQuery, GetHolidayTypeQueryVariables>(GetHolidayTypeDocument, baseOptions);
        }
export type GetHolidayTypeQueryHookResult = ReturnType<typeof useGetHolidayTypeQuery>;
export type GetHolidayTypeLazyQueryHookResult = ReturnType<typeof useGetHolidayTypeLazyQuery>;
export type GetHolidayTypeQueryResult = ApolloReactCommon.QueryResult<GetHolidayTypeQuery, GetHolidayTypeQueryVariables>;
export const GetWagesRatePolicyDocument = gql`
    query getWagesRatePolicy($orderByAsc: String) {
  getWagesRatePolicy(orderByAsc: $orderByAsc) {
    ID
    holidayTypeID
    modTs
    rate
    holidayType {
      name
    }
  }
}
    `;

/**
 * __useGetWagesRatePolicyQuery__
 *
 * To run a query within a React component, call `useGetWagesRatePolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWagesRatePolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWagesRatePolicyQuery({
 *   variables: {
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetWagesRatePolicyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWagesRatePolicyQuery, GetWagesRatePolicyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWagesRatePolicyQuery, GetWagesRatePolicyQueryVariables>(GetWagesRatePolicyDocument, baseOptions);
      }
export function useGetWagesRatePolicyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWagesRatePolicyQuery, GetWagesRatePolicyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWagesRatePolicyQuery, GetWagesRatePolicyQueryVariables>(GetWagesRatePolicyDocument, baseOptions);
        }
export type GetWagesRatePolicyQueryHookResult = ReturnType<typeof useGetWagesRatePolicyQuery>;
export type GetWagesRatePolicyLazyQueryHookResult = ReturnType<typeof useGetWagesRatePolicyLazyQuery>;
export type GetWagesRatePolicyQueryResult = ApolloReactCommon.QueryResult<GetWagesRatePolicyQuery, GetWagesRatePolicyQueryVariables>;
export const UpdateHolidayTypeDocument = gql`
    mutation updateHolidayType($input: HolidayTypeInput!) {
  updateHolidayType(input: $input) {
    ID
  }
}
    `;
export type UpdateHolidayTypeMutationFn = ApolloReactCommon.MutationFunction<UpdateHolidayTypeMutation, UpdateHolidayTypeMutationVariables>;

/**
 * __useUpdateHolidayTypeMutation__
 *
 * To run a mutation, you first call `useUpdateHolidayTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHolidayTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHolidayTypeMutation, { data, loading, error }] = useUpdateHolidayTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHolidayTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateHolidayTypeMutation, UpdateHolidayTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateHolidayTypeMutation, UpdateHolidayTypeMutationVariables>(UpdateHolidayTypeDocument, baseOptions);
      }
export type UpdateHolidayTypeMutationHookResult = ReturnType<typeof useUpdateHolidayTypeMutation>;
export type UpdateHolidayTypeMutationResult = ApolloReactCommon.MutationResult<UpdateHolidayTypeMutation>;
export type UpdateHolidayTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateHolidayTypeMutation, UpdateHolidayTypeMutationVariables>;
export const UpdateWagesRatePolicyDocument = gql`
    mutation updateWagesRatePolicy($holTypeRateIDs: [HolidayTypeRateInput!]!) {
  updateWagesRatePolicy(holTypeRateIDs: $holTypeRateIDs)
}
    `;
export type UpdateWagesRatePolicyMutationFn = ApolloReactCommon.MutationFunction<UpdateWagesRatePolicyMutation, UpdateWagesRatePolicyMutationVariables>;

/**
 * __useUpdateWagesRatePolicyMutation__
 *
 * To run a mutation, you first call `useUpdateWagesRatePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWagesRatePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWagesRatePolicyMutation, { data, loading, error }] = useUpdateWagesRatePolicyMutation({
 *   variables: {
 *      holTypeRateIDs: // value for 'holTypeRateIDs'
 *   },
 * });
 */
export function useUpdateWagesRatePolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateWagesRatePolicyMutation, UpdateWagesRatePolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateWagesRatePolicyMutation, UpdateWagesRatePolicyMutationVariables>(UpdateWagesRatePolicyDocument, baseOptions);
      }
export type UpdateWagesRatePolicyMutationHookResult = ReturnType<typeof useUpdateWagesRatePolicyMutation>;
export type UpdateWagesRatePolicyMutationResult = ApolloReactCommon.MutationResult<UpdateWagesRatePolicyMutation>;
export type UpdateWagesRatePolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateWagesRatePolicyMutation, UpdateWagesRatePolicyMutationVariables>;
export const GetWorkerDocument = gql`
    query getWorker($ID: String, $orderByAsc: String, $orderByDesc: String, $commonStatus: CommonStatus) {
  getWorker(ID: $ID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, commonStatus: $commonStatus) {
    ID
    commonStatus
    address
    wageRate
    wageType
    workPermitNo
    nationality
    identityNo
    docExpiryDate
    jobTypeID
    jobType {
      ID
      name
    }
    contact {
      name
      email
      contactNo
    }
    attachment {
      fileURL
      fileName
      mediaType
    }
  }
}
    `;

/**
 * __useGetWorkerQuery__
 *
 * To run a query within a React component, call `useGetWorkerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkerQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      commonStatus: // value for 'commonStatus'
 *   },
 * });
 */
export function useGetWorkerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWorkerQuery, GetWorkerQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWorkerQuery, GetWorkerQueryVariables>(GetWorkerDocument, baseOptions);
      }
export function useGetWorkerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWorkerQuery, GetWorkerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWorkerQuery, GetWorkerQueryVariables>(GetWorkerDocument, baseOptions);
        }
export type GetWorkerQueryHookResult = ReturnType<typeof useGetWorkerQuery>;
export type GetWorkerLazyQueryHookResult = ReturnType<typeof useGetWorkerLazyQuery>;
export type GetWorkerQueryResult = ApolloReactCommon.QueryResult<GetWorkerQuery, GetWorkerQueryVariables>;
export const CheckingWageTypeDocument = gql`
    query checkingWageType($workerID: String) {
  checkingWageType(workerID: $workerID)
}
    `;

/**
 * __useCheckingWageTypeQuery__
 *
 * To run a query within a React component, call `useCheckingWageTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckingWageTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckingWageTypeQuery({
 *   variables: {
 *      workerID: // value for 'workerID'
 *   },
 * });
 */
export function useCheckingWageTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckingWageTypeQuery, CheckingWageTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckingWageTypeQuery, CheckingWageTypeQueryVariables>(CheckingWageTypeDocument, baseOptions);
      }
export function useCheckingWageTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckingWageTypeQuery, CheckingWageTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckingWageTypeQuery, CheckingWageTypeQueryVariables>(CheckingWageTypeDocument, baseOptions);
        }
export type CheckingWageTypeQueryHookResult = ReturnType<typeof useCheckingWageTypeQuery>;
export type CheckingWageTypeLazyQueryHookResult = ReturnType<typeof useCheckingWageTypeLazyQuery>;
export type CheckingWageTypeQueryResult = ApolloReactCommon.QueryResult<CheckingWageTypeQuery, CheckingWageTypeQueryVariables>;
export const GetWorkerSiteAssignmentDocument = gql`
    query getWorkerSiteAssignment($workerID: String!) {
  getWorkerSiteAssignment(workerID: $workerID) {
    siteID
  }
}
    `;

/**
 * __useGetWorkerSiteAssignmentQuery__
 *
 * To run a query within a React component, call `useGetWorkerSiteAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkerSiteAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkerSiteAssignmentQuery({
 *   variables: {
 *      workerID: // value for 'workerID'
 *   },
 * });
 */
export function useGetWorkerSiteAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWorkerSiteAssignmentQuery, GetWorkerSiteAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWorkerSiteAssignmentQuery, GetWorkerSiteAssignmentQueryVariables>(GetWorkerSiteAssignmentDocument, baseOptions);
      }
export function useGetWorkerSiteAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWorkerSiteAssignmentQuery, GetWorkerSiteAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWorkerSiteAssignmentQuery, GetWorkerSiteAssignmentQueryVariables>(GetWorkerSiteAssignmentDocument, baseOptions);
        }
export type GetWorkerSiteAssignmentQueryHookResult = ReturnType<typeof useGetWorkerSiteAssignmentQuery>;
export type GetWorkerSiteAssignmentLazyQueryHookResult = ReturnType<typeof useGetWorkerSiteAssignmentLazyQuery>;
export type GetWorkerSiteAssignmentQueryResult = ApolloReactCommon.QueryResult<GetWorkerSiteAssignmentQuery, GetWorkerSiteAssignmentQueryVariables>;
export const CreateWorkerProfileDocument = gql`
    mutation createWorkerProfile($input: WorkerInput!, $siteID: [String!]!) {
  createWorker(input: $input, siteID: $siteID) {
    ID
  }
}
    `;
export type CreateWorkerProfileMutationFn = ApolloReactCommon.MutationFunction<CreateWorkerProfileMutation, CreateWorkerProfileMutationVariables>;

/**
 * __useCreateWorkerProfileMutation__
 *
 * To run a mutation, you first call `useCreateWorkerProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkerProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkerProfileMutation, { data, loading, error }] = useCreateWorkerProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useCreateWorkerProfileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateWorkerProfileMutation, CreateWorkerProfileMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateWorkerProfileMutation, CreateWorkerProfileMutationVariables>(CreateWorkerProfileDocument, baseOptions);
      }
export type CreateWorkerProfileMutationHookResult = ReturnType<typeof useCreateWorkerProfileMutation>;
export type CreateWorkerProfileMutationResult = ApolloReactCommon.MutationResult<CreateWorkerProfileMutation>;
export type CreateWorkerProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateWorkerProfileMutation, CreateWorkerProfileMutationVariables>;
export const UpdateWorkerProfileDocument = gql`
    mutation updateWorkerProfile($input: WorkerInput!, $siteID: [String!]!) {
  updateWorker(input: $input, siteID: $siteID) {
    ID
  }
}
    `;
export type UpdateWorkerProfileMutationFn = ApolloReactCommon.MutationFunction<UpdateWorkerProfileMutation, UpdateWorkerProfileMutationVariables>;

/**
 * __useUpdateWorkerProfileMutation__
 *
 * To run a mutation, you first call `useUpdateWorkerProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkerProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkerProfileMutation, { data, loading, error }] = useUpdateWorkerProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useUpdateWorkerProfileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateWorkerProfileMutation, UpdateWorkerProfileMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateWorkerProfileMutation, UpdateWorkerProfileMutationVariables>(UpdateWorkerProfileDocument, baseOptions);
      }
export type UpdateWorkerProfileMutationHookResult = ReturnType<typeof useUpdateWorkerProfileMutation>;
export type UpdateWorkerProfileMutationResult = ApolloReactCommon.MutationResult<UpdateWorkerProfileMutation>;
export type UpdateWorkerProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateWorkerProfileMutation, UpdateWorkerProfileMutationVariables>;
export const DeleteWorkerProfileDocument = gql`
    mutation deleteWorkerProfile($input: WorkerDeleteInput!) {
  deleteWorker(input: $input)
}
    `;
export type DeleteWorkerProfileMutationFn = ApolloReactCommon.MutationFunction<DeleteWorkerProfileMutation, DeleteWorkerProfileMutationVariables>;

/**
 * __useDeleteWorkerProfileMutation__
 *
 * To run a mutation, you first call `useDeleteWorkerProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkerProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkerProfileMutation, { data, loading, error }] = useDeleteWorkerProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteWorkerProfileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteWorkerProfileMutation, DeleteWorkerProfileMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteWorkerProfileMutation, DeleteWorkerProfileMutationVariables>(DeleteWorkerProfileDocument, baseOptions);
      }
export type DeleteWorkerProfileMutationHookResult = ReturnType<typeof useDeleteWorkerProfileMutation>;
export type DeleteWorkerProfileMutationResult = ApolloReactCommon.MutationResult<DeleteWorkerProfileMutation>;
export type DeleteWorkerProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteWorkerProfileMutation, DeleteWorkerProfileMutationVariables>;
export const CreateBulkWorkerDocument = gql`
    mutation createBulkWorker($input: [WorkerInput!]!) {
  createBulkWorker(input: $input)
}
    `;
export type CreateBulkWorkerMutationFn = ApolloReactCommon.MutationFunction<CreateBulkWorkerMutation, CreateBulkWorkerMutationVariables>;

/**
 * __useCreateBulkWorkerMutation__
 *
 * To run a mutation, you first call `useCreateBulkWorkerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBulkWorkerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBulkWorkerMutation, { data, loading, error }] = useCreateBulkWorkerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBulkWorkerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBulkWorkerMutation, CreateBulkWorkerMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBulkWorkerMutation, CreateBulkWorkerMutationVariables>(CreateBulkWorkerDocument, baseOptions);
      }
export type CreateBulkWorkerMutationHookResult = ReturnType<typeof useCreateBulkWorkerMutation>;
export type CreateBulkWorkerMutationResult = ApolloReactCommon.MutationResult<CreateBulkWorkerMutation>;
export type CreateBulkWorkerMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBulkWorkerMutation, CreateBulkWorkerMutationVariables>;
export const GetWorkingHourPolicyDocument = gql`
    query getWorkingHourPolicy($ID: String, $siteID: String) {
  getWorkingHourPolicy(ID: $ID, siteID: $siteID) {
    ID
    shiftStartTime
    shiftEndTime
    halfStartTime
    halfEndTime
  }
}
    `;

/**
 * __useGetWorkingHourPolicyQuery__
 *
 * To run a query within a React component, call `useGetWorkingHourPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkingHourPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkingHourPolicyQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useGetWorkingHourPolicyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWorkingHourPolicyQuery, GetWorkingHourPolicyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWorkingHourPolicyQuery, GetWorkingHourPolicyQueryVariables>(GetWorkingHourPolicyDocument, baseOptions);
      }
export function useGetWorkingHourPolicyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWorkingHourPolicyQuery, GetWorkingHourPolicyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWorkingHourPolicyQuery, GetWorkingHourPolicyQueryVariables>(GetWorkingHourPolicyDocument, baseOptions);
        }
export type GetWorkingHourPolicyQueryHookResult = ReturnType<typeof useGetWorkingHourPolicyQuery>;
export type GetWorkingHourPolicyLazyQueryHookResult = ReturnType<typeof useGetWorkingHourPolicyLazyQuery>;
export type GetWorkingHourPolicyQueryResult = ApolloReactCommon.QueryResult<GetWorkingHourPolicyQuery, GetWorkingHourPolicyQueryVariables>;
export const CreateWorkingHourPolicyDocument = gql`
    mutation createWorkingHourPolicy($input: WorkingHourPolicyInput!) {
  createWorkingHourPolicy(input: $input) {
    ID
  }
}
    `;
export type CreateWorkingHourPolicyMutationFn = ApolloReactCommon.MutationFunction<CreateWorkingHourPolicyMutation, CreateWorkingHourPolicyMutationVariables>;

/**
 * __useCreateWorkingHourPolicyMutation__
 *
 * To run a mutation, you first call `useCreateWorkingHourPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkingHourPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkingHourPolicyMutation, { data, loading, error }] = useCreateWorkingHourPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkingHourPolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateWorkingHourPolicyMutation, CreateWorkingHourPolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateWorkingHourPolicyMutation, CreateWorkingHourPolicyMutationVariables>(CreateWorkingHourPolicyDocument, baseOptions);
      }
export type CreateWorkingHourPolicyMutationHookResult = ReturnType<typeof useCreateWorkingHourPolicyMutation>;
export type CreateWorkingHourPolicyMutationResult = ApolloReactCommon.MutationResult<CreateWorkingHourPolicyMutation>;
export type CreateWorkingHourPolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateWorkingHourPolicyMutation, CreateWorkingHourPolicyMutationVariables>;
export const UpdateWorkingHourPolicyDocument = gql`
    mutation updateWorkingHourPolicy($input: WorkingHourPolicyInput!) {
  updateWorkingHourPolicy(input: $input) {
    ID
  }
}
    `;
export type UpdateWorkingHourPolicyMutationFn = ApolloReactCommon.MutationFunction<UpdateWorkingHourPolicyMutation, UpdateWorkingHourPolicyMutationVariables>;

/**
 * __useUpdateWorkingHourPolicyMutation__
 *
 * To run a mutation, you first call `useUpdateWorkingHourPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkingHourPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkingHourPolicyMutation, { data, loading, error }] = useUpdateWorkingHourPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkingHourPolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateWorkingHourPolicyMutation, UpdateWorkingHourPolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateWorkingHourPolicyMutation, UpdateWorkingHourPolicyMutationVariables>(UpdateWorkingHourPolicyDocument, baseOptions);
      }
export type UpdateWorkingHourPolicyMutationHookResult = ReturnType<typeof useUpdateWorkingHourPolicyMutation>;
export type UpdateWorkingHourPolicyMutationResult = ApolloReactCommon.MutationResult<UpdateWorkingHourPolicyMutation>;
export type UpdateWorkingHourPolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateWorkingHourPolicyMutation, UpdateWorkingHourPolicyMutationVariables>;
export const GetWorkshopDocument = gql`
    query getWorkshop($ID: String, $companyID: String, $orderByDesc: String, $orderByAsc: String, $skip: Float, $take: Float, $workshopStatus: WorkshopStatus) {
  getWorkshop(ID: $ID, companyID: $companyID, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, skip: $skip, take: $take, workshopStatus: $workshopStatus) {
    ID
    name
    modTs
    accountID
    registrationNo
    email
    contactNo
    description
    address
    contactPerson
    workshopStatus
    ratingType
  }
}
    `;

/**
 * __useGetWorkshopQuery__
 *
 * To run a query within a React component, call `useGetWorkshopQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkshopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkshopQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      workshopStatus: // value for 'workshopStatus'
 *   },
 * });
 */
export function useGetWorkshopQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWorkshopQuery, GetWorkshopQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWorkshopQuery, GetWorkshopQueryVariables>(GetWorkshopDocument, baseOptions);
      }
export function useGetWorkshopLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWorkshopQuery, GetWorkshopQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWorkshopQuery, GetWorkshopQueryVariables>(GetWorkshopDocument, baseOptions);
        }
export type GetWorkshopQueryHookResult = ReturnType<typeof useGetWorkshopQuery>;
export type GetWorkshopLazyQueryHookResult = ReturnType<typeof useGetWorkshopLazyQuery>;
export type GetWorkshopQueryResult = ApolloReactCommon.QueryResult<GetWorkshopQuery, GetWorkshopQueryVariables>;
export const CreateWorkshopDocument = gql`
    mutation createWorkshop($input: WorkshopInput!) {
  createWorkshop(input: $input) {
    ID
  }
}
    `;
export type CreateWorkshopMutationFn = ApolloReactCommon.MutationFunction<CreateWorkshopMutation, CreateWorkshopMutationVariables>;

/**
 * __useCreateWorkshopMutation__
 *
 * To run a mutation, you first call `useCreateWorkshopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkshopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkshopMutation, { data, loading, error }] = useCreateWorkshopMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkshopMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateWorkshopMutation, CreateWorkshopMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateWorkshopMutation, CreateWorkshopMutationVariables>(CreateWorkshopDocument, baseOptions);
      }
export type CreateWorkshopMutationHookResult = ReturnType<typeof useCreateWorkshopMutation>;
export type CreateWorkshopMutationResult = ApolloReactCommon.MutationResult<CreateWorkshopMutation>;
export type CreateWorkshopMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateWorkshopMutation, CreateWorkshopMutationVariables>;
export const UpdateWorkshopDocument = gql`
    mutation updateWorkshop($input: WorkshopInput!) {
  updateWorkshop(input: $input) {
    ID
  }
}
    `;
export type UpdateWorkshopMutationFn = ApolloReactCommon.MutationFunction<UpdateWorkshopMutation, UpdateWorkshopMutationVariables>;

/**
 * __useUpdateWorkshopMutation__
 *
 * To run a mutation, you first call `useUpdateWorkshopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkshopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkshopMutation, { data, loading, error }] = useUpdateWorkshopMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkshopMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateWorkshopMutation, UpdateWorkshopMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateWorkshopMutation, UpdateWorkshopMutationVariables>(UpdateWorkshopDocument, baseOptions);
      }
export type UpdateWorkshopMutationHookResult = ReturnType<typeof useUpdateWorkshopMutation>;
export type UpdateWorkshopMutationResult = ApolloReactCommon.MutationResult<UpdateWorkshopMutation>;
export type UpdateWorkshopMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateWorkshopMutation, UpdateWorkshopMutationVariables>;
export const GetHomeSummaryDocument = gql`
    query getHomeSummary {
  workDeskCount
  activeContractCount
  activeClientCount
  activeSubcontractCount
  activeSubcontractorCount
  activeSupplierCount
  lastMiscExpDate
  lastPurchaseDate
  loggedInUserProfile {
    ID
    accountID
    name
    email
    contactNo
    userName
    department
    superUser
    jobTitle
  }
  getRolePermission
  getContractPermission
}
    `;

/**
 * __useGetHomeSummaryQuery__
 *
 * To run a query within a React component, call `useGetHomeSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHomeSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>(GetHomeSummaryDocument, baseOptions);
      }
export function useGetHomeSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>(GetHomeSummaryDocument, baseOptions);
        }
export type GetHomeSummaryQueryHookResult = ReturnType<typeof useGetHomeSummaryQuery>;
export type GetHomeSummaryLazyQueryHookResult = ReturnType<typeof useGetHomeSummaryLazyQuery>;
export type GetHomeSummaryQueryResult = ApolloReactCommon.QueryResult<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>;
export const GetAccessSecuritySummaryDocument = gql`
    query getAccessSecuritySummary {
  conAuthDate
  userDate
  roleDate
}
    `;

/**
 * __useGetAccessSecuritySummaryQuery__
 *
 * To run a query within a React component, call `useGetAccessSecuritySummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccessSecuritySummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccessSecuritySummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccessSecuritySummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccessSecuritySummaryQuery, GetAccessSecuritySummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAccessSecuritySummaryQuery, GetAccessSecuritySummaryQueryVariables>(GetAccessSecuritySummaryDocument, baseOptions);
      }
export function useGetAccessSecuritySummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccessSecuritySummaryQuery, GetAccessSecuritySummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAccessSecuritySummaryQuery, GetAccessSecuritySummaryQueryVariables>(GetAccessSecuritySummaryDocument, baseOptions);
        }
export type GetAccessSecuritySummaryQueryHookResult = ReturnType<typeof useGetAccessSecuritySummaryQuery>;
export type GetAccessSecuritySummaryLazyQueryHookResult = ReturnType<typeof useGetAccessSecuritySummaryLazyQuery>;
export type GetAccessSecuritySummaryQueryResult = ApolloReactCommon.QueryResult<GetAccessSecuritySummaryQuery, GetAccessSecuritySummaryQueryVariables>;
export const GetDrawerPermissionDocument = gql`
    query getDrawerPermission($pageMode: String) {
  getDrawerPermission(pageMode: $pageMode)
}
    `;

/**
 * __useGetDrawerPermissionQuery__
 *
 * To run a query within a React component, call `useGetDrawerPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDrawerPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDrawerPermissionQuery({
 *   variables: {
 *      pageMode: // value for 'pageMode'
 *   },
 * });
 */
export function useGetDrawerPermissionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDrawerPermissionQuery, GetDrawerPermissionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDrawerPermissionQuery, GetDrawerPermissionQueryVariables>(GetDrawerPermissionDocument, baseOptions);
      }
export function useGetDrawerPermissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDrawerPermissionQuery, GetDrawerPermissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDrawerPermissionQuery, GetDrawerPermissionQueryVariables>(GetDrawerPermissionDocument, baseOptions);
        }
export type GetDrawerPermissionQueryHookResult = ReturnType<typeof useGetDrawerPermissionQuery>;
export type GetDrawerPermissionLazyQueryHookResult = ReturnType<typeof useGetDrawerPermissionLazyQuery>;
export type GetDrawerPermissionQueryResult = ApolloReactCommon.QueryResult<GetDrawerPermissionQuery, GetDrawerPermissionQueryVariables>;
export const GetRoleMatrixPermDocument = gql`
    query getRoleMatrixPerm($roleID: String) {
  getRoleMatrix(roleID: $roleID)
}
    `;

/**
 * __useGetRoleMatrixPermQuery__
 *
 * To run a query within a React component, call `useGetRoleMatrixPermQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleMatrixPermQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleMatrixPermQuery({
 *   variables: {
 *      roleID: // value for 'roleID'
 *   },
 * });
 */
export function useGetRoleMatrixPermQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRoleMatrixPermQuery, GetRoleMatrixPermQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRoleMatrixPermQuery, GetRoleMatrixPermQueryVariables>(GetRoleMatrixPermDocument, baseOptions);
      }
export function useGetRoleMatrixPermLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRoleMatrixPermQuery, GetRoleMatrixPermQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRoleMatrixPermQuery, GetRoleMatrixPermQueryVariables>(GetRoleMatrixPermDocument, baseOptions);
        }
export type GetRoleMatrixPermQueryHookResult = ReturnType<typeof useGetRoleMatrixPermQuery>;
export type GetRoleMatrixPermLazyQueryHookResult = ReturnType<typeof useGetRoleMatrixPermLazyQuery>;
export type GetRoleMatrixPermQueryResult = ApolloReactCommon.QueryResult<GetRoleMatrixPermQuery, GetRoleMatrixPermQueryVariables>;
export const GetRoleMatrixByIdsDocument = gql`
    query getRoleMatrixByIds($roleID: String, $limit: Float, $offset: Float, $softwareCode: String) {
  getRoleMatrixByIds(roleID: $roleID, limit: $limit, offset: $offset, softwareCode: $softwareCode)
  getRoleMatrixTotal: getRoleMatrixByIds(roleID: $roleID, softwareCode: $softwareCode)
}
    `;

/**
 * __useGetRoleMatrixByIdsQuery__
 *
 * To run a query within a React component, call `useGetRoleMatrixByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleMatrixByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleMatrixByIdsQuery({
 *   variables: {
 *      roleID: // value for 'roleID'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      softwareCode: // value for 'softwareCode'
 *   },
 * });
 */
export function useGetRoleMatrixByIdsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRoleMatrixByIdsQuery, GetRoleMatrixByIdsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRoleMatrixByIdsQuery, GetRoleMatrixByIdsQueryVariables>(GetRoleMatrixByIdsDocument, baseOptions);
      }
export function useGetRoleMatrixByIdsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRoleMatrixByIdsQuery, GetRoleMatrixByIdsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRoleMatrixByIdsQuery, GetRoleMatrixByIdsQueryVariables>(GetRoleMatrixByIdsDocument, baseOptions);
        }
export type GetRoleMatrixByIdsQueryHookResult = ReturnType<typeof useGetRoleMatrixByIdsQuery>;
export type GetRoleMatrixByIdsLazyQueryHookResult = ReturnType<typeof useGetRoleMatrixByIdsLazyQuery>;
export type GetRoleMatrixByIdsQueryResult = ApolloReactCommon.QueryResult<GetRoleMatrixByIdsQuery, GetRoleMatrixByIdsQueryVariables>;
export const GetRoleByIdsDocument = gql`
    query getRoleByIds($roleIDs: [String!], $softwareCode: String) {
  getRoleByIds(roleIDs: $roleIDs, softwareCode: $softwareCode) {
    ID
    name
  }
}
    `;

/**
 * __useGetRoleByIdsQuery__
 *
 * To run a query within a React component, call `useGetRoleByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleByIdsQuery({
 *   variables: {
 *      roleIDs: // value for 'roleIDs'
 *      softwareCode: // value for 'softwareCode'
 *   },
 * });
 */
export function useGetRoleByIdsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRoleByIdsQuery, GetRoleByIdsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRoleByIdsQuery, GetRoleByIdsQueryVariables>(GetRoleByIdsDocument, baseOptions);
      }
export function useGetRoleByIdsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRoleByIdsQuery, GetRoleByIdsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRoleByIdsQuery, GetRoleByIdsQueryVariables>(GetRoleByIdsDocument, baseOptions);
        }
export type GetRoleByIdsQueryHookResult = ReturnType<typeof useGetRoleByIdsQuery>;
export type GetRoleByIdsLazyQueryHookResult = ReturnType<typeof useGetRoleByIdsLazyQuery>;
export type GetRoleByIdsQueryResult = ApolloReactCommon.QueryResult<GetRoleByIdsQuery, GetRoleByIdsQueryVariables>;
export const GetAlertDocument = gql`
    query getAlert($orderByAsc: String) {
  getAlert(orderByAsc: $orderByAsc) {
    ID
    name
    description
    sqlCode
    commonStatus
  }
}
    `;

/**
 * __useGetAlertQuery__
 *
 * To run a query within a React component, call `useGetAlertQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlertQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlertQuery({
 *   variables: {
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetAlertQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAlertQuery, GetAlertQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAlertQuery, GetAlertQueryVariables>(GetAlertDocument, baseOptions);
      }
export function useGetAlertLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAlertQuery, GetAlertQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAlertQuery, GetAlertQueryVariables>(GetAlertDocument, baseOptions);
        }
export type GetAlertQueryHookResult = ReturnType<typeof useGetAlertQuery>;
export type GetAlertLazyQueryHookResult = ReturnType<typeof useGetAlertLazyQuery>;
export type GetAlertQueryResult = ApolloReactCommon.QueryResult<GetAlertQuery, GetAlertQueryVariables>;
export const CreateAlertDocument = gql`
    mutation createAlert($input: AlertInput!) {
  createAlert(input: $input) {
    ID
  }
}
    `;
export type CreateAlertMutationFn = ApolloReactCommon.MutationFunction<CreateAlertMutation, CreateAlertMutationVariables>;

/**
 * __useCreateAlertMutation__
 *
 * To run a mutation, you first call `useCreateAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAlertMutation, { data, loading, error }] = useCreateAlertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAlertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAlertMutation, CreateAlertMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAlertMutation, CreateAlertMutationVariables>(CreateAlertDocument, baseOptions);
      }
export type CreateAlertMutationHookResult = ReturnType<typeof useCreateAlertMutation>;
export type CreateAlertMutationResult = ApolloReactCommon.MutationResult<CreateAlertMutation>;
export type CreateAlertMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAlertMutation, CreateAlertMutationVariables>;
export const UpdateAlertDocument = gql`
    mutation updateAlert($input: AlertInput!) {
  updateAlert(input: $input) {
    ID
  }
}
    `;
export type UpdateAlertMutationFn = ApolloReactCommon.MutationFunction<UpdateAlertMutation, UpdateAlertMutationVariables>;

/**
 * __useUpdateAlertMutation__
 *
 * To run a mutation, you first call `useUpdateAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAlertMutation, { data, loading, error }] = useUpdateAlertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAlertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAlertMutation, UpdateAlertMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAlertMutation, UpdateAlertMutationVariables>(UpdateAlertDocument, baseOptions);
      }
export type UpdateAlertMutationHookResult = ReturnType<typeof useUpdateAlertMutation>;
export type UpdateAlertMutationResult = ApolloReactCommon.MutationResult<UpdateAlertMutation>;
export type UpdateAlertMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAlertMutation, UpdateAlertMutationVariables>;
export const GetAlertListDocument = gql`
    query getAlertList($ID: String, $commonStatus: CommonStatus) {
  getAlertList(ID: $ID, commonStatus: $commonStatus) {
    ID
    name
    description
    sqlCode
    commonStatus
    defaultFromAdmin
    alertCount
    alertAssignment {
      ID
      accountID
      userID
      reminder
      alertID
      isAdminControl
    }
  }
}
    `;

/**
 * __useGetAlertListQuery__
 *
 * To run a query within a React component, call `useGetAlertListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlertListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlertListQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      commonStatus: // value for 'commonStatus'
 *   },
 * });
 */
export function useGetAlertListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAlertListQuery, GetAlertListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAlertListQuery, GetAlertListQueryVariables>(GetAlertListDocument, baseOptions);
      }
export function useGetAlertListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAlertListQuery, GetAlertListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAlertListQuery, GetAlertListQueryVariables>(GetAlertListDocument, baseOptions);
        }
export type GetAlertListQueryHookResult = ReturnType<typeof useGetAlertListQuery>;
export type GetAlertListLazyQueryHookResult = ReturnType<typeof useGetAlertListLazyQuery>;
export type GetAlertListQueryResult = ApolloReactCommon.QueryResult<GetAlertListQuery, GetAlertListQueryVariables>;
export const GetShowAlertSqlDocument = gql`
    query getShowAlertSQL($ID: String!) {
  getShowAlertSQL(ID: $ID)
}
    `;

/**
 * __useGetShowAlertSqlQuery__
 *
 * To run a query within a React component, call `useGetShowAlertSqlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowAlertSqlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowAlertSqlQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetShowAlertSqlQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShowAlertSqlQuery, GetShowAlertSqlQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShowAlertSqlQuery, GetShowAlertSqlQueryVariables>(GetShowAlertSqlDocument, baseOptions);
      }
export function useGetShowAlertSqlLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShowAlertSqlQuery, GetShowAlertSqlQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShowAlertSqlQuery, GetShowAlertSqlQueryVariables>(GetShowAlertSqlDocument, baseOptions);
        }
export type GetShowAlertSqlQueryHookResult = ReturnType<typeof useGetShowAlertSqlQuery>;
export type GetShowAlertSqlLazyQueryHookResult = ReturnType<typeof useGetShowAlertSqlLazyQuery>;
export type GetShowAlertSqlQueryResult = ApolloReactCommon.QueryResult<GetShowAlertSqlQuery, GetShowAlertSqlQueryVariables>;
export const GetAlertAssignmentDocument = gql`
    query getAlertAssignment($ID: String, $userID: String, $alertID: String) {
  getAlertAssignment(ID: $ID, userID: $userID, alertID: $alertID) {
    createdTs
    createdBy
    modTs
    modBy
    ID
    accountID
    alertID
    userID
    reminder
    isAdminControl
  }
}
    `;

/**
 * __useGetAlertAssignmentQuery__
 *
 * To run a query within a React component, call `useGetAlertAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlertAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlertAssignmentQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      userID: // value for 'userID'
 *      alertID: // value for 'alertID'
 *   },
 * });
 */
export function useGetAlertAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAlertAssignmentQuery, GetAlertAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAlertAssignmentQuery, GetAlertAssignmentQueryVariables>(GetAlertAssignmentDocument, baseOptions);
      }
export function useGetAlertAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAlertAssignmentQuery, GetAlertAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAlertAssignmentQuery, GetAlertAssignmentQueryVariables>(GetAlertAssignmentDocument, baseOptions);
        }
export type GetAlertAssignmentQueryHookResult = ReturnType<typeof useGetAlertAssignmentQuery>;
export type GetAlertAssignmentLazyQueryHookResult = ReturnType<typeof useGetAlertAssignmentLazyQuery>;
export type GetAlertAssignmentQueryResult = ApolloReactCommon.QueryResult<GetAlertAssignmentQuery, GetAlertAssignmentQueryVariables>;
export const UpdateAlertAssignmentDocument = gql`
    mutation updateAlertAssignment($input: [AlertAssignmentInput!]!, $isFromAdmin: Boolean!) {
  updateAlertAssignment(input: $input, isFromAdmin: $isFromAdmin)
}
    `;
export type UpdateAlertAssignmentMutationFn = ApolloReactCommon.MutationFunction<UpdateAlertAssignmentMutation, UpdateAlertAssignmentMutationVariables>;

/**
 * __useUpdateAlertAssignmentMutation__
 *
 * To run a mutation, you first call `useUpdateAlertAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAlertAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAlertAssignmentMutation, { data, loading, error }] = useUpdateAlertAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      isFromAdmin: // value for 'isFromAdmin'
 *   },
 * });
 */
export function useUpdateAlertAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAlertAssignmentMutation, UpdateAlertAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAlertAssignmentMutation, UpdateAlertAssignmentMutationVariables>(UpdateAlertAssignmentDocument, baseOptions);
      }
export type UpdateAlertAssignmentMutationHookResult = ReturnType<typeof useUpdateAlertAssignmentMutation>;
export type UpdateAlertAssignmentMutationResult = ApolloReactCommon.MutationResult<UpdateAlertAssignmentMutation>;
export type UpdateAlertAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAlertAssignmentMutation, UpdateAlertAssignmentMutationVariables>;
export const GetBankAccountDocument = gql`
    query getBankAccount($ID: String, $companyID: String, $orderByAsc: String, $status: BankAccountStatus) {
  getBankAccount(ID: $ID, companyID: $companyID, orderByAsc: $orderByAsc, status: $status) {
    ID
    status
    accountID
    name
    accountHolder
    accountNo
    contactNo
    branch
    address
    swiftCode
    contactPerson
    glAccountCode
  }
}
    `;

/**
 * __useGetBankAccountQuery__
 *
 * To run a query within a React component, call `useGetBankAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankAccountQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      orderByAsc: // value for 'orderByAsc'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetBankAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBankAccountQuery, GetBankAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBankAccountQuery, GetBankAccountQueryVariables>(GetBankAccountDocument, baseOptions);
      }
export function useGetBankAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBankAccountQuery, GetBankAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBankAccountQuery, GetBankAccountQueryVariables>(GetBankAccountDocument, baseOptions);
        }
export type GetBankAccountQueryHookResult = ReturnType<typeof useGetBankAccountQuery>;
export type GetBankAccountLazyQueryHookResult = ReturnType<typeof useGetBankAccountLazyQuery>;
export type GetBankAccountQueryResult = ApolloReactCommon.QueryResult<GetBankAccountQuery, GetBankAccountQueryVariables>;
export const GetBankAccountWithPermDocument = gql`
    query getBankAccountWithPerm($ID: String, $companyID: String, $status: BankAccountStatus, $orderByAsc: String) {
  getBankAccountWithPerm(ID: $ID, orderByAsc: $orderByAsc, status: $status, companyID: $companyID) {
    ID
    status
    accountID
    name
    accountHolder
    accountNo
    contactNo
    branch
    address
    swiftCode
    contactPerson
    glAccountCode
  }
}
    `;

/**
 * __useGetBankAccountWithPermQuery__
 *
 * To run a query within a React component, call `useGetBankAccountWithPermQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankAccountWithPermQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankAccountWithPermQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      companyID: // value for 'companyID'
 *      status: // value for 'status'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetBankAccountWithPermQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBankAccountWithPermQuery, GetBankAccountWithPermQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBankAccountWithPermQuery, GetBankAccountWithPermQueryVariables>(GetBankAccountWithPermDocument, baseOptions);
      }
export function useGetBankAccountWithPermLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBankAccountWithPermQuery, GetBankAccountWithPermQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBankAccountWithPermQuery, GetBankAccountWithPermQueryVariables>(GetBankAccountWithPermDocument, baseOptions);
        }
export type GetBankAccountWithPermQueryHookResult = ReturnType<typeof useGetBankAccountWithPermQuery>;
export type GetBankAccountWithPermLazyQueryHookResult = ReturnType<typeof useGetBankAccountWithPermLazyQuery>;
export type GetBankAccountWithPermQueryResult = ApolloReactCommon.QueryResult<GetBankAccountWithPermQuery, GetBankAccountWithPermQueryVariables>;
export const CreateBankAccountDocument = gql`
    mutation createBankAccount($input: BankAccountInput!) {
  createBankAccount(input: $input) {
    ID
  }
}
    `;
export type CreateBankAccountMutationFn = ApolloReactCommon.MutationFunction<CreateBankAccountMutation, CreateBankAccountMutationVariables>;

/**
 * __useCreateBankAccountMutation__
 *
 * To run a mutation, you first call `useCreateBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBankAccountMutation, { data, loading, error }] = useCreateBankAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBankAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBankAccountMutation, CreateBankAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBankAccountMutation, CreateBankAccountMutationVariables>(CreateBankAccountDocument, baseOptions);
      }
export type CreateBankAccountMutationHookResult = ReturnType<typeof useCreateBankAccountMutation>;
export type CreateBankAccountMutationResult = ApolloReactCommon.MutationResult<CreateBankAccountMutation>;
export type CreateBankAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBankAccountMutation, CreateBankAccountMutationVariables>;
export const UpdateBankAccountDocument = gql`
    mutation updateBankAccount($input: BankAccountInput!) {
  updateBankAccount(input: $input) {
    ID
  }
}
    `;
export type UpdateBankAccountMutationFn = ApolloReactCommon.MutationFunction<UpdateBankAccountMutation, UpdateBankAccountMutationVariables>;

/**
 * __useUpdateBankAccountMutation__
 *
 * To run a mutation, you first call `useUpdateBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBankAccountMutation, { data, loading, error }] = useUpdateBankAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBankAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBankAccountMutation, UpdateBankAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBankAccountMutation, UpdateBankAccountMutationVariables>(UpdateBankAccountDocument, baseOptions);
      }
export type UpdateBankAccountMutationHookResult = ReturnType<typeof useUpdateBankAccountMutation>;
export type UpdateBankAccountMutationResult = ApolloReactCommon.MutationResult<UpdateBankAccountMutation>;
export type UpdateBankAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBankAccountMutation, UpdateBankAccountMutationVariables>;
export const CancelBankAccountDocument = gql`
    mutation cancelBankAccount($input: BankAccountCancellationInput!, $ID: String!) {
  cancelBankAccount(input: $input, ID: $ID)
}
    `;
export type CancelBankAccountMutationFn = ApolloReactCommon.MutationFunction<CancelBankAccountMutation, CancelBankAccountMutationVariables>;

/**
 * __useCancelBankAccountMutation__
 *
 * To run a mutation, you first call `useCancelBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelBankAccountMutation, { data, loading, error }] = useCancelBankAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCancelBankAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelBankAccountMutation, CancelBankAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelBankAccountMutation, CancelBankAccountMutationVariables>(CancelBankAccountDocument, baseOptions);
      }
export type CancelBankAccountMutationHookResult = ReturnType<typeof useCancelBankAccountMutation>;
export type CancelBankAccountMutationResult = ApolloReactCommon.MutationResult<CancelBankAccountMutation>;
export type CancelBankAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelBankAccountMutation, CancelBankAccountMutationVariables>;
export const ActivateBankAccountDocument = gql`
    mutation activateBankAccount($input: BankAccountDeleteInput!) {
  activateBankAccount(input: $input)
}
    `;
export type ActivateBankAccountMutationFn = ApolloReactCommon.MutationFunction<ActivateBankAccountMutation, ActivateBankAccountMutationVariables>;

/**
 * __useActivateBankAccountMutation__
 *
 * To run a mutation, you first call `useActivateBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateBankAccountMutation, { data, loading, error }] = useActivateBankAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateBankAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateBankAccountMutation, ActivateBankAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateBankAccountMutation, ActivateBankAccountMutationVariables>(ActivateBankAccountDocument, baseOptions);
      }
export type ActivateBankAccountMutationHookResult = ReturnType<typeof useActivateBankAccountMutation>;
export type ActivateBankAccountMutationResult = ApolloReactCommon.MutationResult<ActivateBankAccountMutation>;
export type ActivateBankAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateBankAccountMutation, ActivateBankAccountMutationVariables>;
export const DeleteBankAccountDocument = gql`
    mutation deleteBankAccount($input: BankAccountDeleteInput!) {
  deleteBankAccount(input: $input)
}
    `;
export type DeleteBankAccountMutationFn = ApolloReactCommon.MutationFunction<DeleteBankAccountMutation, DeleteBankAccountMutationVariables>;

/**
 * __useDeleteBankAccountMutation__
 *
 * To run a mutation, you first call `useDeleteBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBankAccountMutation, { data, loading, error }] = useDeleteBankAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBankAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteBankAccountMutation, DeleteBankAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteBankAccountMutation, DeleteBankAccountMutationVariables>(DeleteBankAccountDocument, baseOptions);
      }
export type DeleteBankAccountMutationHookResult = ReturnType<typeof useDeleteBankAccountMutation>;
export type DeleteBankAccountMutationResult = ApolloReactCommon.MutationResult<DeleteBankAccountMutation>;
export type DeleteBankAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteBankAccountMutation, DeleteBankAccountMutationVariables>;
export const GetCurrencySetupDocument = gql`
    query getCurrencySetup($ID: String, $name: String, $format: NumericFormatter) {
  getCurrencySetup(ID: $ID, name: $name, format: $format) {
    accountID
    createdTs
    createdBy
    modTs
    modBy
    ID
    name
    code
    symbol
    format
  }
}
    `;

/**
 * __useGetCurrencySetupQuery__
 *
 * To run a query within a React component, call `useGetCurrencySetupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrencySetupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrencySetupQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      name: // value for 'name'
 *      format: // value for 'format'
 *   },
 * });
 */
export function useGetCurrencySetupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrencySetupQuery, GetCurrencySetupQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCurrencySetupQuery, GetCurrencySetupQueryVariables>(GetCurrencySetupDocument, baseOptions);
      }
export function useGetCurrencySetupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCurrencySetupQuery, GetCurrencySetupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCurrencySetupQuery, GetCurrencySetupQueryVariables>(GetCurrencySetupDocument, baseOptions);
        }
export type GetCurrencySetupQueryHookResult = ReturnType<typeof useGetCurrencySetupQuery>;
export type GetCurrencySetupLazyQueryHookResult = ReturnType<typeof useGetCurrencySetupLazyQuery>;
export type GetCurrencySetupQueryResult = ApolloReactCommon.QueryResult<GetCurrencySetupQuery, GetCurrencySetupQueryVariables>;
export const CreateCurrencySetupDocument = gql`
    mutation createCurrencySetup($input: CurrencySetupInput!) {
  createCurrencySetup(input: $input) {
    ID
  }
}
    `;
export type CreateCurrencySetupMutationFn = ApolloReactCommon.MutationFunction<CreateCurrencySetupMutation, CreateCurrencySetupMutationVariables>;

/**
 * __useCreateCurrencySetupMutation__
 *
 * To run a mutation, you first call `useCreateCurrencySetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCurrencySetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCurrencySetupMutation, { data, loading, error }] = useCreateCurrencySetupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCurrencySetupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCurrencySetupMutation, CreateCurrencySetupMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCurrencySetupMutation, CreateCurrencySetupMutationVariables>(CreateCurrencySetupDocument, baseOptions);
      }
export type CreateCurrencySetupMutationHookResult = ReturnType<typeof useCreateCurrencySetupMutation>;
export type CreateCurrencySetupMutationResult = ApolloReactCommon.MutationResult<CreateCurrencySetupMutation>;
export type CreateCurrencySetupMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCurrencySetupMutation, CreateCurrencySetupMutationVariables>;
export const UpdateCurrencySetupDocument = gql`
    mutation updateCurrencySetup($input: CurrencySetupInput!) {
  updateCurrencySetup(input: $input) {
    ID
  }
}
    `;
export type UpdateCurrencySetupMutationFn = ApolloReactCommon.MutationFunction<UpdateCurrencySetupMutation, UpdateCurrencySetupMutationVariables>;

/**
 * __useUpdateCurrencySetupMutation__
 *
 * To run a mutation, you first call `useUpdateCurrencySetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrencySetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrencySetupMutation, { data, loading, error }] = useUpdateCurrencySetupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCurrencySetupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCurrencySetupMutation, UpdateCurrencySetupMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCurrencySetupMutation, UpdateCurrencySetupMutationVariables>(UpdateCurrencySetupDocument, baseOptions);
      }
export type UpdateCurrencySetupMutationHookResult = ReturnType<typeof useUpdateCurrencySetupMutation>;
export type UpdateCurrencySetupMutationResult = ApolloReactCommon.MutationResult<UpdateCurrencySetupMutation>;
export type UpdateCurrencySetupMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCurrencySetupMutation, UpdateCurrencySetupMutationVariables>;
export const DeleteCurrencySetupDocument = gql`
    mutation deleteCurrencySetup($input: CurrencySetupDeleteInput!) {
  deleteCurrencySetup(input: $input)
}
    `;
export type DeleteCurrencySetupMutationFn = ApolloReactCommon.MutationFunction<DeleteCurrencySetupMutation, DeleteCurrencySetupMutationVariables>;

/**
 * __useDeleteCurrencySetupMutation__
 *
 * To run a mutation, you first call `useDeleteCurrencySetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCurrencySetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCurrencySetupMutation, { data, loading, error }] = useDeleteCurrencySetupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCurrencySetupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCurrencySetupMutation, DeleteCurrencySetupMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCurrencySetupMutation, DeleteCurrencySetupMutationVariables>(DeleteCurrencySetupDocument, baseOptions);
      }
export type DeleteCurrencySetupMutationHookResult = ReturnType<typeof useDeleteCurrencySetupMutation>;
export type DeleteCurrencySetupMutationResult = ApolloReactCommon.MutationResult<DeleteCurrencySetupMutation>;
export type DeleteCurrencySetupMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCurrencySetupMutation, DeleteCurrencySetupMutationVariables>;
export const UpdateNumberFormatDocument = gql`
    mutation updateNumberFormat($input: NumberFormatInput!) {
  editNumberFormat(input: $input)
}
    `;
export type UpdateNumberFormatMutationFn = ApolloReactCommon.MutationFunction<UpdateNumberFormatMutation, UpdateNumberFormatMutationVariables>;

/**
 * __useUpdateNumberFormatMutation__
 *
 * To run a mutation, you first call `useUpdateNumberFormatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNumberFormatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNumberFormatMutation, { data, loading, error }] = useUpdateNumberFormatMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNumberFormatMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateNumberFormatMutation, UpdateNumberFormatMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateNumberFormatMutation, UpdateNumberFormatMutationVariables>(UpdateNumberFormatDocument, baseOptions);
      }
export type UpdateNumberFormatMutationHookResult = ReturnType<typeof useUpdateNumberFormatMutation>;
export type UpdateNumberFormatMutationResult = ApolloReactCommon.MutationResult<UpdateNumberFormatMutation>;
export type UpdateNumberFormatMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateNumberFormatMutation, UpdateNumberFormatMutationVariables>;
export const UpdateDocNumSetupDocument = gql`
    mutation updateDocNumSetup($ID: String!, $input: DocNumInput!) {
  updateDocNumSetup(ID: $ID, input: $input)
}
    `;
export type UpdateDocNumSetupMutationFn = ApolloReactCommon.MutationFunction<UpdateDocNumSetupMutation, UpdateDocNumSetupMutationVariables>;

/**
 * __useUpdateDocNumSetupMutation__
 *
 * To run a mutation, you first call `useUpdateDocNumSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocNumSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocNumSetupMutation, { data, loading, error }] = useUpdateDocNumSetupMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDocNumSetupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDocNumSetupMutation, UpdateDocNumSetupMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDocNumSetupMutation, UpdateDocNumSetupMutationVariables>(UpdateDocNumSetupDocument, baseOptions);
      }
export type UpdateDocNumSetupMutationHookResult = ReturnType<typeof useUpdateDocNumSetupMutation>;
export type UpdateDocNumSetupMutationResult = ApolloReactCommon.MutationResult<UpdateDocNumSetupMutation>;
export type UpdateDocNumSetupMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDocNumSetupMutation, UpdateDocNumSetupMutationVariables>;
export const GetDocTypeDocument = gql`
    query getDocType($companyID: String) {
  getDocType(companyID: $companyID)
}
    `;

/**
 * __useGetDocTypeQuery__
 *
 * To run a query within a React component, call `useGetDocTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocTypeQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetDocTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDocTypeQuery, GetDocTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDocTypeQuery, GetDocTypeQueryVariables>(GetDocTypeDocument, baseOptions);
      }
export function useGetDocTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDocTypeQuery, GetDocTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDocTypeQuery, GetDocTypeQueryVariables>(GetDocTypeDocument, baseOptions);
        }
export type GetDocTypeQueryHookResult = ReturnType<typeof useGetDocTypeQuery>;
export type GetDocTypeLazyQueryHookResult = ReturnType<typeof useGetDocTypeLazyQuery>;
export type GetDocTypeQueryResult = ApolloReactCommon.QueryResult<GetDocTypeQuery, GetDocTypeQueryVariables>;
export const GetNumberFormatDocument = gql`
    query getNumberFormat($ID: String, $docTypeID: String) {
  getNumberFormat(ID: $ID, docTypeID: $docTypeID) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    accountID
    docTypeID
    numberFormat
    numberLength
    prefix
    prefixExtra
    commonStatus
    softwareCode
    documentDivider
    contractID
    docType {
      docName
    }
  }
}
    `;

/**
 * __useGetNumberFormatQuery__
 *
 * To run a query within a React component, call `useGetNumberFormatQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNumberFormatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNumberFormatQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      docTypeID: // value for 'docTypeID'
 *   },
 * });
 */
export function useGetNumberFormatQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNumberFormatQuery, GetNumberFormatQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNumberFormatQuery, GetNumberFormatQueryVariables>(GetNumberFormatDocument, baseOptions);
      }
export function useGetNumberFormatLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNumberFormatQuery, GetNumberFormatQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNumberFormatQuery, GetNumberFormatQueryVariables>(GetNumberFormatDocument, baseOptions);
        }
export type GetNumberFormatQueryHookResult = ReturnType<typeof useGetNumberFormatQuery>;
export type GetNumberFormatLazyQueryHookResult = ReturnType<typeof useGetNumberFormatLazyQuery>;
export type GetNumberFormatQueryResult = ApolloReactCommon.QueryResult<GetNumberFormatQuery, GetNumberFormatQueryVariables>;
export const RunningNumCheckingDocument = gql`
    query runningNumChecking($docCode: ContractDocNum!, $companyID: String) {
  runningNumChecking(docCode: $docCode, companyID: $companyID)
}
    `;

/**
 * __useRunningNumCheckingQuery__
 *
 * To run a query within a React component, call `useRunningNumCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunningNumCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunningNumCheckingQuery({
 *   variables: {
 *      docCode: // value for 'docCode'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useRunningNumCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunningNumCheckingQuery, RunningNumCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<RunningNumCheckingQuery, RunningNumCheckingQueryVariables>(RunningNumCheckingDocument, baseOptions);
      }
export function useRunningNumCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunningNumCheckingQuery, RunningNumCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunningNumCheckingQuery, RunningNumCheckingQueryVariables>(RunningNumCheckingDocument, baseOptions);
        }
export type RunningNumCheckingQueryHookResult = ReturnType<typeof useRunningNumCheckingQuery>;
export type RunningNumCheckingLazyQueryHookResult = ReturnType<typeof useRunningNumCheckingLazyQuery>;
export type RunningNumCheckingQueryResult = ApolloReactCommon.QueryResult<RunningNumCheckingQuery, RunningNumCheckingQueryVariables>;
export const ProjMiscExpRunNumCheckingDocument = gql`
    query projMiscExpRunNumChecking($companyID: String) {
  projMiscExpRunNumChecking(companyID: $companyID)
}
    `;

/**
 * __useProjMiscExpRunNumCheckingQuery__
 *
 * To run a query within a React component, call `useProjMiscExpRunNumCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjMiscExpRunNumCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjMiscExpRunNumCheckingQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useProjMiscExpRunNumCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjMiscExpRunNumCheckingQuery, ProjMiscExpRunNumCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjMiscExpRunNumCheckingQuery, ProjMiscExpRunNumCheckingQueryVariables>(ProjMiscExpRunNumCheckingDocument, baseOptions);
      }
export function useProjMiscExpRunNumCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjMiscExpRunNumCheckingQuery, ProjMiscExpRunNumCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjMiscExpRunNumCheckingQuery, ProjMiscExpRunNumCheckingQueryVariables>(ProjMiscExpRunNumCheckingDocument, baseOptions);
        }
export type ProjMiscExpRunNumCheckingQueryHookResult = ReturnType<typeof useProjMiscExpRunNumCheckingQuery>;
export type ProjMiscExpRunNumCheckingLazyQueryHookResult = ReturnType<typeof useProjMiscExpRunNumCheckingLazyQuery>;
export type ProjMiscExpRunNumCheckingQueryResult = ApolloReactCommon.QueryResult<ProjMiscExpRunNumCheckingQuery, ProjMiscExpRunNumCheckingQueryVariables>;
export const GetSysAdminDocTypeDocument = gql`
    query getSysAdminDocType {
  getSysAdminDocType
}
    `;

/**
 * __useGetSysAdminDocTypeQuery__
 *
 * To run a query within a React component, call `useGetSysAdminDocTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSysAdminDocTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSysAdminDocTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSysAdminDocTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSysAdminDocTypeQuery, GetSysAdminDocTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSysAdminDocTypeQuery, GetSysAdminDocTypeQueryVariables>(GetSysAdminDocTypeDocument, baseOptions);
      }
export function useGetSysAdminDocTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSysAdminDocTypeQuery, GetSysAdminDocTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSysAdminDocTypeQuery, GetSysAdminDocTypeQueryVariables>(GetSysAdminDocTypeDocument, baseOptions);
        }
export type GetSysAdminDocTypeQueryHookResult = ReturnType<typeof useGetSysAdminDocTypeQuery>;
export type GetSysAdminDocTypeLazyQueryHookResult = ReturnType<typeof useGetSysAdminDocTypeLazyQuery>;
export type GetSysAdminDocTypeQueryResult = ApolloReactCommon.QueryResult<GetSysAdminDocTypeQuery, GetSysAdminDocTypeQueryVariables>;
export const GetDocNumHeaderDocument = gql`
    query getDocNumHeader($ID: String, $accountID: String, $contractID: String, $docTypeID: String) {
  getDocNumHeader(ID: $ID, accountID: $accountID, contractID: $contractID, docTypeID: $docTypeID) {
    ID
    accountID
    contractID
    docTypeID
    sampleOutput
    docNumDetail {
      sequence
      formatType
      formatValue
      startFrom
      nextNumber
      docNumHeaderID
    }
  }
}
    `;

/**
 * __useGetDocNumHeaderQuery__
 *
 * To run a query within a React component, call `useGetDocNumHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocNumHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocNumHeaderQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      accountID: // value for 'accountID'
 *      contractID: // value for 'contractID'
 *      docTypeID: // value for 'docTypeID'
 *   },
 * });
 */
export function useGetDocNumHeaderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDocNumHeaderQuery, GetDocNumHeaderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDocNumHeaderQuery, GetDocNumHeaderQueryVariables>(GetDocNumHeaderDocument, baseOptions);
      }
export function useGetDocNumHeaderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDocNumHeaderQuery, GetDocNumHeaderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDocNumHeaderQuery, GetDocNumHeaderQueryVariables>(GetDocNumHeaderDocument, baseOptions);
        }
export type GetDocNumHeaderQueryHookResult = ReturnType<typeof useGetDocNumHeaderQuery>;
export type GetDocNumHeaderLazyQueryHookResult = ReturnType<typeof useGetDocNumHeaderLazyQuery>;
export type GetDocNumHeaderQueryResult = ApolloReactCommon.QueryResult<GetDocNumHeaderQuery, GetDocNumHeaderQueryVariables>;
export const UpdateSystemAdminDocNumDocument = gql`
    mutation updateSystemAdminDocNum($DocNumHeaderInput: DocNumHeaderInput!, $DocNumDetailInput: [DocNumDetailInput!]!) {
  updateSystemAdminDocNum(DocNumHeaderInput: $DocNumHeaderInput, DocNumDetailInput: $DocNumDetailInput)
}
    `;
export type UpdateSystemAdminDocNumMutationFn = ApolloReactCommon.MutationFunction<UpdateSystemAdminDocNumMutation, UpdateSystemAdminDocNumMutationVariables>;

/**
 * __useUpdateSystemAdminDocNumMutation__
 *
 * To run a mutation, you first call `useUpdateSystemAdminDocNumMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSystemAdminDocNumMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSystemAdminDocNumMutation, { data, loading, error }] = useUpdateSystemAdminDocNumMutation({
 *   variables: {
 *      DocNumHeaderInput: // value for 'DocNumHeaderInput'
 *      DocNumDetailInput: // value for 'DocNumDetailInput'
 *   },
 * });
 */
export function useUpdateSystemAdminDocNumMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSystemAdminDocNumMutation, UpdateSystemAdminDocNumMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSystemAdminDocNumMutation, UpdateSystemAdminDocNumMutationVariables>(UpdateSystemAdminDocNumDocument, baseOptions);
      }
export type UpdateSystemAdminDocNumMutationHookResult = ReturnType<typeof useUpdateSystemAdminDocNumMutation>;
export type UpdateSystemAdminDocNumMutationResult = ApolloReactCommon.MutationResult<UpdateSystemAdminDocNumMutation>;
export type UpdateSystemAdminDocNumMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSystemAdminDocNumMutation, UpdateSystemAdminDocNumMutationVariables>;
export const GetClassificationCodeDocument = gql`
    query getClassificationCode($id: String, $orderByAsc: String, $orderByDesc: String, $take: Float, $skip: Float, $searchValue: String) {
  getClassificationCode(id: $id, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, take: $take, skip: $skip, searchValue: $searchValue) {
    id
    classificationCode
    description
  }
}
    `;

/**
 * __useGetClassificationCodeQuery__
 *
 * To run a query within a React component, call `useGetClassificationCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassificationCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassificationCodeQuery({
 *   variables: {
 *      id: // value for 'id'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetClassificationCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClassificationCodeQuery, GetClassificationCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClassificationCodeQuery, GetClassificationCodeQueryVariables>(GetClassificationCodeDocument, baseOptions);
      }
export function useGetClassificationCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClassificationCodeQuery, GetClassificationCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClassificationCodeQuery, GetClassificationCodeQueryVariables>(GetClassificationCodeDocument, baseOptions);
        }
export type GetClassificationCodeQueryHookResult = ReturnType<typeof useGetClassificationCodeQuery>;
export type GetClassificationCodeLazyQueryHookResult = ReturnType<typeof useGetClassificationCodeLazyQuery>;
export type GetClassificationCodeQueryResult = ApolloReactCommon.QueryResult<GetClassificationCodeQuery, GetClassificationCodeQueryVariables>;
export const GetClassificationCodeListingDocument = gql`
    query getClassificationCodeListing($id: String, $orderByAsc: String, $orderByDesc: String, $take: Float, $skip: Float, $searchValue: String) {
  getClassificationCode(id: $id, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, take: $take, skip: $skip, searchValue: $searchValue) {
    id
    classificationCode
    description
  }
  getClassificationCodeCount(id: $id, searchValue: $searchValue)
}
    `;

/**
 * __useGetClassificationCodeListingQuery__
 *
 * To run a query within a React component, call `useGetClassificationCodeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassificationCodeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassificationCodeListingQuery({
 *   variables: {
 *      id: // value for 'id'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetClassificationCodeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClassificationCodeListingQuery, GetClassificationCodeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClassificationCodeListingQuery, GetClassificationCodeListingQueryVariables>(GetClassificationCodeListingDocument, baseOptions);
      }
export function useGetClassificationCodeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClassificationCodeListingQuery, GetClassificationCodeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClassificationCodeListingQuery, GetClassificationCodeListingQueryVariables>(GetClassificationCodeListingDocument, baseOptions);
        }
export type GetClassificationCodeListingQueryHookResult = ReturnType<typeof useGetClassificationCodeListingQuery>;
export type GetClassificationCodeListingLazyQueryHookResult = ReturnType<typeof useGetClassificationCodeListingLazyQuery>;
export type GetClassificationCodeListingQueryResult = ApolloReactCommon.QueryResult<GetClassificationCodeListingQuery, GetClassificationCodeListingQueryVariables>;
export const GetMsicCodeDocument = gql`
    query getMsicCode($id: String, $orderByAsc: String, $orderByDesc: String, $take: Float, $skip: Float, $searchValue: String) {
  getMsicCode(id: $id, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, take: $take, skip: $skip, searchValue: $searchValue) {
    id
    msicCode
    description
    categoryReference
  }
}
    `;

/**
 * __useGetMsicCodeQuery__
 *
 * To run a query within a React component, call `useGetMsicCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMsicCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMsicCodeQuery({
 *   variables: {
 *      id: // value for 'id'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetMsicCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMsicCodeQuery, GetMsicCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMsicCodeQuery, GetMsicCodeQueryVariables>(GetMsicCodeDocument, baseOptions);
      }
export function useGetMsicCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMsicCodeQuery, GetMsicCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMsicCodeQuery, GetMsicCodeQueryVariables>(GetMsicCodeDocument, baseOptions);
        }
export type GetMsicCodeQueryHookResult = ReturnType<typeof useGetMsicCodeQuery>;
export type GetMsicCodeLazyQueryHookResult = ReturnType<typeof useGetMsicCodeLazyQuery>;
export type GetMsicCodeQueryResult = ApolloReactCommon.QueryResult<GetMsicCodeQuery, GetMsicCodeQueryVariables>;
export const GetMsicCodeListingDocument = gql`
    query getMsicCodeListing($id: String, $orderByAsc: String, $orderByDesc: String, $take: Float, $skip: Float, $searchValue: String) {
  getMsicCode(id: $id, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, take: $take, skip: $skip, searchValue: $searchValue) {
    id
    msicCode
    description
    categoryReference
  }
  getMsicCodeCount(id: $id, searchValue: $searchValue)
}
    `;

/**
 * __useGetMsicCodeListingQuery__
 *
 * To run a query within a React component, call `useGetMsicCodeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMsicCodeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMsicCodeListingQuery({
 *   variables: {
 *      id: // value for 'id'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetMsicCodeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMsicCodeListingQuery, GetMsicCodeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMsicCodeListingQuery, GetMsicCodeListingQueryVariables>(GetMsicCodeListingDocument, baseOptions);
      }
export function useGetMsicCodeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMsicCodeListingQuery, GetMsicCodeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMsicCodeListingQuery, GetMsicCodeListingQueryVariables>(GetMsicCodeListingDocument, baseOptions);
        }
export type GetMsicCodeListingQueryHookResult = ReturnType<typeof useGetMsicCodeListingQuery>;
export type GetMsicCodeListingLazyQueryHookResult = ReturnType<typeof useGetMsicCodeListingLazyQuery>;
export type GetMsicCodeListingQueryResult = ApolloReactCommon.QueryResult<GetMsicCodeListingQuery, GetMsicCodeListingQueryVariables>;
export const UploadCompanyLogoDocument = gql`
    mutation uploadCompanyLogo($file: Upload) {
  uploadCompanyLogo(file: $file)
}
    `;
export type UploadCompanyLogoMutationFn = ApolloReactCommon.MutationFunction<UploadCompanyLogoMutation, UploadCompanyLogoMutationVariables>;

/**
 * __useUploadCompanyLogoMutation__
 *
 * To run a mutation, you first call `useUploadCompanyLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCompanyLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCompanyLogoMutation, { data, loading, error }] = useUploadCompanyLogoMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadCompanyLogoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadCompanyLogoMutation, UploadCompanyLogoMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadCompanyLogoMutation, UploadCompanyLogoMutationVariables>(UploadCompanyLogoDocument, baseOptions);
      }
export type UploadCompanyLogoMutationHookResult = ReturnType<typeof useUploadCompanyLogoMutation>;
export type UploadCompanyLogoMutationResult = ApolloReactCommon.MutationResult<UploadCompanyLogoMutation>;
export type UploadCompanyLogoMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadCompanyLogoMutation, UploadCompanyLogoMutationVariables>;
export const UploadIsoLogoDocument = gql`
    mutation uploadISOLogo($file: Upload) {
  uploadISOLogo(file: $file)
}
    `;
export type UploadIsoLogoMutationFn = ApolloReactCommon.MutationFunction<UploadIsoLogoMutation, UploadIsoLogoMutationVariables>;

/**
 * __useUploadIsoLogoMutation__
 *
 * To run a mutation, you first call `useUploadIsoLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadIsoLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadIsoLogoMutation, { data, loading, error }] = useUploadIsoLogoMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadIsoLogoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadIsoLogoMutation, UploadIsoLogoMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadIsoLogoMutation, UploadIsoLogoMutationVariables>(UploadIsoLogoDocument, baseOptions);
      }
export type UploadIsoLogoMutationHookResult = ReturnType<typeof useUploadIsoLogoMutation>;
export type UploadIsoLogoMutationResult = ApolloReactCommon.MutationResult<UploadIsoLogoMutation>;
export type UploadIsoLogoMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadIsoLogoMutation, UploadIsoLogoMutationVariables>;
export const LogoViewDocument = gql`
    query logoView($refID: String, $description: String) {
  logoView(refID: $refID, description: $description) {
    fileName
    fileURL
    mediaType
  }
}
    `;

/**
 * __useLogoViewQuery__
 *
 * To run a query within a React component, call `useLogoViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogoViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogoViewQuery({
 *   variables: {
 *      refID: // value for 'refID'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useLogoViewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LogoViewQuery, LogoViewQueryVariables>) {
        return ApolloReactHooks.useQuery<LogoViewQuery, LogoViewQueryVariables>(LogoViewDocument, baseOptions);
      }
export function useLogoViewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LogoViewQuery, LogoViewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LogoViewQuery, LogoViewQueryVariables>(LogoViewDocument, baseOptions);
        }
export type LogoViewQueryHookResult = ReturnType<typeof useLogoViewQuery>;
export type LogoViewLazyQueryHookResult = ReturnType<typeof useLogoViewLazyQuery>;
export type LogoViewQueryResult = ApolloReactCommon.QueryResult<LogoViewQuery, LogoViewQueryVariables>;
export const GetStandardParameterDocument = gql`
    query getStandardParameter {
  getStandardParameter
}
    `;

/**
 * __useGetStandardParameterQuery__
 *
 * To run a query within a React component, call `useGetStandardParameterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStandardParameterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStandardParameterQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStandardParameterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStandardParameterQuery, GetStandardParameterQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStandardParameterQuery, GetStandardParameterQueryVariables>(GetStandardParameterDocument, baseOptions);
      }
export function useGetStandardParameterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStandardParameterQuery, GetStandardParameterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStandardParameterQuery, GetStandardParameterQueryVariables>(GetStandardParameterDocument, baseOptions);
        }
export type GetStandardParameterQueryHookResult = ReturnType<typeof useGetStandardParameterQuery>;
export type GetStandardParameterLazyQueryHookResult = ReturnType<typeof useGetStandardParameterLazyQuery>;
export type GetStandardParameterQueryResult = ApolloReactCommon.QueryResult<GetStandardParameterQuery, GetStandardParameterQueryVariables>;
export const CreatePolicyGuidelineDocument = gql`
    mutation createPolicyGuideline($roleApprovalIDs: [PolicyAssignmentInput!]!, $input: PolicyGuidelineInput!) {
  createPolicyGuideline(roleApprovalIDs: $roleApprovalIDs, input: $input)
}
    `;
export type CreatePolicyGuidelineMutationFn = ApolloReactCommon.MutationFunction<CreatePolicyGuidelineMutation, CreatePolicyGuidelineMutationVariables>;

/**
 * __useCreatePolicyGuidelineMutation__
 *
 * To run a mutation, you first call `useCreatePolicyGuidelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePolicyGuidelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPolicyGuidelineMutation, { data, loading, error }] = useCreatePolicyGuidelineMutation({
 *   variables: {
 *      roleApprovalIDs: // value for 'roleApprovalIDs'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePolicyGuidelineMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePolicyGuidelineMutation, CreatePolicyGuidelineMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePolicyGuidelineMutation, CreatePolicyGuidelineMutationVariables>(CreatePolicyGuidelineDocument, baseOptions);
      }
export type CreatePolicyGuidelineMutationHookResult = ReturnType<typeof useCreatePolicyGuidelineMutation>;
export type CreatePolicyGuidelineMutationResult = ApolloReactCommon.MutationResult<CreatePolicyGuidelineMutation>;
export type CreatePolicyGuidelineMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePolicyGuidelineMutation, CreatePolicyGuidelineMutationVariables>;
export const UpdatePolicyGuidelineDocument = gql`
    mutation updatePolicyGuideline($input: [PolicyAssignmentInput!]!, $docRefTable: String!) {
  updatePolicyGuideline(input: $input, docRefTable: $docRefTable)
}
    `;
export type UpdatePolicyGuidelineMutationFn = ApolloReactCommon.MutationFunction<UpdatePolicyGuidelineMutation, UpdatePolicyGuidelineMutationVariables>;

/**
 * __useUpdatePolicyGuidelineMutation__
 *
 * To run a mutation, you first call `useUpdatePolicyGuidelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePolicyGuidelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePolicyGuidelineMutation, { data, loading, error }] = useUpdatePolicyGuidelineMutation({
 *   variables: {
 *      input: // value for 'input'
 *      docRefTable: // value for 'docRefTable'
 *   },
 * });
 */
export function useUpdatePolicyGuidelineMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePolicyGuidelineMutation, UpdatePolicyGuidelineMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePolicyGuidelineMutation, UpdatePolicyGuidelineMutationVariables>(UpdatePolicyGuidelineDocument, baseOptions);
      }
export type UpdatePolicyGuidelineMutationHookResult = ReturnType<typeof useUpdatePolicyGuidelineMutation>;
export type UpdatePolicyGuidelineMutationResult = ApolloReactCommon.MutationResult<UpdatePolicyGuidelineMutation>;
export type UpdatePolicyGuidelineMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePolicyGuidelineMutation, UpdatePolicyGuidelineMutationVariables>;
export const CreatePolicyAmountGuidelineDocument = gql`
    mutation createPolicyAmountGuideline($notiRoleUserIDs: [NotificationGuidelineInput!]!, $roleApprovalIDs: [PolicyAssignmentInput!]!, $input: PolicyGuidelineInput!) {
  createPolicyAmountGuideline(notiRoleUserIDs: $notiRoleUserIDs, roleApprovalIDs: $roleApprovalIDs, input: $input)
}
    `;
export type CreatePolicyAmountGuidelineMutationFn = ApolloReactCommon.MutationFunction<CreatePolicyAmountGuidelineMutation, CreatePolicyAmountGuidelineMutationVariables>;

/**
 * __useCreatePolicyAmountGuidelineMutation__
 *
 * To run a mutation, you first call `useCreatePolicyAmountGuidelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePolicyAmountGuidelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPolicyAmountGuidelineMutation, { data, loading, error }] = useCreatePolicyAmountGuidelineMutation({
 *   variables: {
 *      notiRoleUserIDs: // value for 'notiRoleUserIDs'
 *      roleApprovalIDs: // value for 'roleApprovalIDs'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePolicyAmountGuidelineMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePolicyAmountGuidelineMutation, CreatePolicyAmountGuidelineMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePolicyAmountGuidelineMutation, CreatePolicyAmountGuidelineMutationVariables>(CreatePolicyAmountGuidelineDocument, baseOptions);
      }
export type CreatePolicyAmountGuidelineMutationHookResult = ReturnType<typeof useCreatePolicyAmountGuidelineMutation>;
export type CreatePolicyAmountGuidelineMutationResult = ApolloReactCommon.MutationResult<CreatePolicyAmountGuidelineMutation>;
export type CreatePolicyAmountGuidelineMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePolicyAmountGuidelineMutation, CreatePolicyAmountGuidelineMutationVariables>;
export const GetPolicyGuideLineDocument = gql`
    query getPolicyGuideLine($orderByDesc: String, $orderByAsc: String, $skip: Float, $take: Float, $ID: String, $docRefTable: String) {
  getPolicyGuideLine(orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, skip: $skip, take: $take, ID: $ID, docRefTable: $docRefTable) {
    ID
    accountID
    commonStatus
    docRefTable
    upperLimit
    lowerLimit
    policyRoles {
      ID
      policyID
      approvalLevel
      roleID
    }
  }
}
    `;

/**
 * __useGetPolicyGuideLineQuery__
 *
 * To run a query within a React component, call `useGetPolicyGuideLineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPolicyGuideLineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPolicyGuideLineQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      ID: // value for 'ID'
 *      docRefTable: // value for 'docRefTable'
 *   },
 * });
 */
export function useGetPolicyGuideLineQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPolicyGuideLineQuery, GetPolicyGuideLineQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPolicyGuideLineQuery, GetPolicyGuideLineQueryVariables>(GetPolicyGuideLineDocument, baseOptions);
      }
export function useGetPolicyGuideLineLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPolicyGuideLineQuery, GetPolicyGuideLineQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPolicyGuideLineQuery, GetPolicyGuideLineQueryVariables>(GetPolicyGuideLineDocument, baseOptions);
        }
export type GetPolicyGuideLineQueryHookResult = ReturnType<typeof useGetPolicyGuideLineQuery>;
export type GetPolicyGuideLineLazyQueryHookResult = ReturnType<typeof useGetPolicyGuideLineLazyQuery>;
export type GetPolicyGuideLineQueryResult = ApolloReactCommon.QueryResult<GetPolicyGuideLineQuery, GetPolicyGuideLineQueryVariables>;
export const GetPolicyGuideLineShorterDocument = gql`
    query getPolicyGuideLineShorter($ID: String, $docRefTable: String) {
  getPolicyGuideLine(ID: $ID, docRefTable: $docRefTable) {
    ID
    commonStatus
    docRefTable
    policyRoles {
      approvalLevel
      roleID
    }
  }
}
    `;

/**
 * __useGetPolicyGuideLineShorterQuery__
 *
 * To run a query within a React component, call `useGetPolicyGuideLineShorterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPolicyGuideLineShorterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPolicyGuideLineShorterQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      docRefTable: // value for 'docRefTable'
 *   },
 * });
 */
export function useGetPolicyGuideLineShorterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPolicyGuideLineShorterQuery, GetPolicyGuideLineShorterQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPolicyGuideLineShorterQuery, GetPolicyGuideLineShorterQueryVariables>(GetPolicyGuideLineShorterDocument, baseOptions);
      }
export function useGetPolicyGuideLineShorterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPolicyGuideLineShorterQuery, GetPolicyGuideLineShorterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPolicyGuideLineShorterQuery, GetPolicyGuideLineShorterQueryVariables>(GetPolicyGuideLineShorterDocument, baseOptions);
        }
export type GetPolicyGuideLineShorterQueryHookResult = ReturnType<typeof useGetPolicyGuideLineShorterQuery>;
export type GetPolicyGuideLineShorterLazyQueryHookResult = ReturnType<typeof useGetPolicyGuideLineShorterLazyQuery>;
export type GetPolicyGuideLineShorterQueryResult = ApolloReactCommon.QueryResult<GetPolicyGuideLineShorterQuery, GetPolicyGuideLineShorterQueryVariables>;
export const DeletePolicyAmountGuidelineDocument = gql`
    mutation deletePolicyAmountGuideline($ID: String!) {
  deletePolicyAmountGuideline(ID: $ID)
}
    `;
export type DeletePolicyAmountGuidelineMutationFn = ApolloReactCommon.MutationFunction<DeletePolicyAmountGuidelineMutation, DeletePolicyAmountGuidelineMutationVariables>;

/**
 * __useDeletePolicyAmountGuidelineMutation__
 *
 * To run a mutation, you first call `useDeletePolicyAmountGuidelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePolicyAmountGuidelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePolicyAmountGuidelineMutation, { data, loading, error }] = useDeletePolicyAmountGuidelineMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeletePolicyAmountGuidelineMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePolicyAmountGuidelineMutation, DeletePolicyAmountGuidelineMutationVariables>) {
        return ApolloReactHooks.useMutation<DeletePolicyAmountGuidelineMutation, DeletePolicyAmountGuidelineMutationVariables>(DeletePolicyAmountGuidelineDocument, baseOptions);
      }
export type DeletePolicyAmountGuidelineMutationHookResult = ReturnType<typeof useDeletePolicyAmountGuidelineMutation>;
export type DeletePolicyAmountGuidelineMutationResult = ApolloReactCommon.MutationResult<DeletePolicyAmountGuidelineMutation>;
export type DeletePolicyAmountGuidelineMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePolicyAmountGuidelineMutation, DeletePolicyAmountGuidelineMutationVariables>;
export const UpdatePolicyAmountGuidelineDocument = gql`
    mutation updatePolicyAmountGuideline($ID: String!, $roleApprovalIDs: [PolicyAssignmentInput!]!, $notiRoleUserIDs: [NotificationGuidelineInput!]!, $input: PolicyGuidelineInput!) {
  updatePolicyAmountGuideline(ID: $ID, roleApprovalIDs: $roleApprovalIDs, notiRoleUserIDs: $notiRoleUserIDs, input: $input)
}
    `;
export type UpdatePolicyAmountGuidelineMutationFn = ApolloReactCommon.MutationFunction<UpdatePolicyAmountGuidelineMutation, UpdatePolicyAmountGuidelineMutationVariables>;

/**
 * __useUpdatePolicyAmountGuidelineMutation__
 *
 * To run a mutation, you first call `useUpdatePolicyAmountGuidelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePolicyAmountGuidelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePolicyAmountGuidelineMutation, { data, loading, error }] = useUpdatePolicyAmountGuidelineMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      roleApprovalIDs: // value for 'roleApprovalIDs'
 *      notiRoleUserIDs: // value for 'notiRoleUserIDs'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePolicyAmountGuidelineMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePolicyAmountGuidelineMutation, UpdatePolicyAmountGuidelineMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePolicyAmountGuidelineMutation, UpdatePolicyAmountGuidelineMutationVariables>(UpdatePolicyAmountGuidelineDocument, baseOptions);
      }
export type UpdatePolicyAmountGuidelineMutationHookResult = ReturnType<typeof useUpdatePolicyAmountGuidelineMutation>;
export type UpdatePolicyAmountGuidelineMutationResult = ApolloReactCommon.MutationResult<UpdatePolicyAmountGuidelineMutation>;
export type UpdatePolicyAmountGuidelineMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePolicyAmountGuidelineMutation, UpdatePolicyAmountGuidelineMutationVariables>;
export const CreateNotificationGuidelineDocument = gql`
    mutation createNotificationGuideline($input: [NotificationGuidelineInput!]!, $docRefTable: String!) {
  createNotificationGuideline(input: $input, docRefTable: $docRefTable)
}
    `;
export type CreateNotificationGuidelineMutationFn = ApolloReactCommon.MutationFunction<CreateNotificationGuidelineMutation, CreateNotificationGuidelineMutationVariables>;

/**
 * __useCreateNotificationGuidelineMutation__
 *
 * To run a mutation, you first call `useCreateNotificationGuidelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotificationGuidelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotificationGuidelineMutation, { data, loading, error }] = useCreateNotificationGuidelineMutation({
 *   variables: {
 *      input: // value for 'input'
 *      docRefTable: // value for 'docRefTable'
 *   },
 * });
 */
export function useCreateNotificationGuidelineMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateNotificationGuidelineMutation, CreateNotificationGuidelineMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateNotificationGuidelineMutation, CreateNotificationGuidelineMutationVariables>(CreateNotificationGuidelineDocument, baseOptions);
      }
export type CreateNotificationGuidelineMutationHookResult = ReturnType<typeof useCreateNotificationGuidelineMutation>;
export type CreateNotificationGuidelineMutationResult = ApolloReactCommon.MutationResult<CreateNotificationGuidelineMutation>;
export type CreateNotificationGuidelineMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateNotificationGuidelineMutation, CreateNotificationGuidelineMutationVariables>;
export const UpdateNotificationGuidelineDocument = gql`
    mutation updateNotificationGuideline($input: [NotificationGuidelineInput!]!, $ID: String!) {
  updateNotificationGuideline(input: $input, ID: $ID)
}
    `;
export type UpdateNotificationGuidelineMutationFn = ApolloReactCommon.MutationFunction<UpdateNotificationGuidelineMutation, UpdateNotificationGuidelineMutationVariables>;

/**
 * __useUpdateNotificationGuidelineMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationGuidelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationGuidelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationGuidelineMutation, { data, loading, error }] = useUpdateNotificationGuidelineMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useUpdateNotificationGuidelineMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateNotificationGuidelineMutation, UpdateNotificationGuidelineMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateNotificationGuidelineMutation, UpdateNotificationGuidelineMutationVariables>(UpdateNotificationGuidelineDocument, baseOptions);
      }
export type UpdateNotificationGuidelineMutationHookResult = ReturnType<typeof useUpdateNotificationGuidelineMutation>;
export type UpdateNotificationGuidelineMutationResult = ApolloReactCommon.MutationResult<UpdateNotificationGuidelineMutation>;
export type UpdateNotificationGuidelineMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateNotificationGuidelineMutation, UpdateNotificationGuidelineMutationVariables>;
export const GetNotificationGuideLineDocument = gql`
    query getNotificationGuideLine($orderByDesc: String, $orderByAsc: String, $skip: Float, $take: Float, $ID: String, $policyID: String, $docRefTable: String) {
  getNotificationGuideLine(orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, skip: $skip, take: $take, policyID: $policyID, ID: $ID, docRefTable: $docRefTable) {
    ID
    accountID
    commonStatus
    docRefTable
    notiRoleUsers {
      ID
      accountID
      notiPolicyID
      roleID
      userID
    }
    roles
  }
}
    `;

/**
 * __useGetNotificationGuideLineQuery__
 *
 * To run a query within a React component, call `useGetNotificationGuideLineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationGuideLineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationGuideLineQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      ID: // value for 'ID'
 *      policyID: // value for 'policyID'
 *      docRefTable: // value for 'docRefTable'
 *   },
 * });
 */
export function useGetNotificationGuideLineQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNotificationGuideLineQuery, GetNotificationGuideLineQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNotificationGuideLineQuery, GetNotificationGuideLineQueryVariables>(GetNotificationGuideLineDocument, baseOptions);
      }
export function useGetNotificationGuideLineLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNotificationGuideLineQuery, GetNotificationGuideLineQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNotificationGuideLineQuery, GetNotificationGuideLineQueryVariables>(GetNotificationGuideLineDocument, baseOptions);
        }
export type GetNotificationGuideLineQueryHookResult = ReturnType<typeof useGetNotificationGuideLineQuery>;
export type GetNotificationGuideLineLazyQueryHookResult = ReturnType<typeof useGetNotificationGuideLineLazyQuery>;
export type GetNotificationGuideLineQueryResult = ApolloReactCommon.QueryResult<GetNotificationGuideLineQuery, GetNotificationGuideLineQueryVariables>;
export const UserRoleIDsDocument = gql`
    query userRoleIDs($contractID: String!) {
  userRoleIDs(contractID: $contractID) {
    roleID
    userID
  }
}
    `;

/**
 * __useUserRoleIDsQuery__
 *
 * To run a query within a React component, call `useUserRoleIDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRoleIDsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRoleIDsQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useUserRoleIDsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserRoleIDsQuery, UserRoleIDsQueryVariables>) {
        return ApolloReactHooks.useQuery<UserRoleIDsQuery, UserRoleIDsQueryVariables>(UserRoleIDsDocument, baseOptions);
      }
export function useUserRoleIDsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserRoleIDsQuery, UserRoleIDsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserRoleIDsQuery, UserRoleIDsQueryVariables>(UserRoleIDsDocument, baseOptions);
        }
export type UserRoleIDsQueryHookResult = ReturnType<typeof useUserRoleIDsQuery>;
export type UserRoleIDsLazyQueryHookResult = ReturnType<typeof useUserRoleIDsLazyQuery>;
export type UserRoleIDsQueryResult = ApolloReactCommon.QueryResult<UserRoleIDsQuery, UserRoleIDsQueryVariables>;
export const CreateEntityRoleUserDocument = gql`
    mutation createEntityRoleUser($input: EntityRoleUserAsgInput!) {
  CreateEntityRoleUser(input: $input)
}
    `;
export type CreateEntityRoleUserMutationFn = ApolloReactCommon.MutationFunction<CreateEntityRoleUserMutation, CreateEntityRoleUserMutationVariables>;

/**
 * __useCreateEntityRoleUserMutation__
 *
 * To run a mutation, you first call `useCreateEntityRoleUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEntityRoleUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEntityRoleUserMutation, { data, loading, error }] = useCreateEntityRoleUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEntityRoleUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEntityRoleUserMutation, CreateEntityRoleUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEntityRoleUserMutation, CreateEntityRoleUserMutationVariables>(CreateEntityRoleUserDocument, baseOptions);
      }
export type CreateEntityRoleUserMutationHookResult = ReturnType<typeof useCreateEntityRoleUserMutation>;
export type CreateEntityRoleUserMutationResult = ApolloReactCommon.MutationResult<CreateEntityRoleUserMutation>;
export type CreateEntityRoleUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEntityRoleUserMutation, CreateEntityRoleUserMutationVariables>;
export const CreateListEntityRoleUserDocument = gql`
    mutation createListEntityRoleUser($contractID: String!, $roleUserIDs: [RoleUserInput!]!) {
  CreateListEntityRoleUser(contractID: $contractID, roleUserIDs: $roleUserIDs)
}
    `;
export type CreateListEntityRoleUserMutationFn = ApolloReactCommon.MutationFunction<CreateListEntityRoleUserMutation, CreateListEntityRoleUserMutationVariables>;

/**
 * __useCreateListEntityRoleUserMutation__
 *
 * To run a mutation, you first call `useCreateListEntityRoleUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateListEntityRoleUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createListEntityRoleUserMutation, { data, loading, error }] = useCreateListEntityRoleUserMutation({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      roleUserIDs: // value for 'roleUserIDs'
 *   },
 * });
 */
export function useCreateListEntityRoleUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateListEntityRoleUserMutation, CreateListEntityRoleUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateListEntityRoleUserMutation, CreateListEntityRoleUserMutationVariables>(CreateListEntityRoleUserDocument, baseOptions);
      }
export type CreateListEntityRoleUserMutationHookResult = ReturnType<typeof useCreateListEntityRoleUserMutation>;
export type CreateListEntityRoleUserMutationResult = ApolloReactCommon.MutationResult<CreateListEntityRoleUserMutation>;
export type CreateListEntityRoleUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateListEntityRoleUserMutation, CreateListEntityRoleUserMutationVariables>;
export const GetContractListForProjectAndRoleAuthDocument = gql`
    query getContractListForProjectAndRoleAuth($ID: String, $softwareCode: String) {
  getContract(ID: $ID, contractStatus: ACTIVE) {
    ID
    title
    contractDate
    contractRolesUsersAssigned(softwareCode: $softwareCode)
  }
  getEntityRoleUserAssignment(entityID: $ID) {
    entityID
    userID
    roleID
  }
}
    `;

/**
 * __useGetContractListForProjectAndRoleAuthQuery__
 *
 * To run a query within a React component, call `useGetContractListForProjectAndRoleAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractListForProjectAndRoleAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractListForProjectAndRoleAuthQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      softwareCode: // value for 'softwareCode'
 *   },
 * });
 */
export function useGetContractListForProjectAndRoleAuthQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractListForProjectAndRoleAuthQuery, GetContractListForProjectAndRoleAuthQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractListForProjectAndRoleAuthQuery, GetContractListForProjectAndRoleAuthQueryVariables>(GetContractListForProjectAndRoleAuthDocument, baseOptions);
      }
export function useGetContractListForProjectAndRoleAuthLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractListForProjectAndRoleAuthQuery, GetContractListForProjectAndRoleAuthQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractListForProjectAndRoleAuthQuery, GetContractListForProjectAndRoleAuthQueryVariables>(GetContractListForProjectAndRoleAuthDocument, baseOptions);
        }
export type GetContractListForProjectAndRoleAuthQueryHookResult = ReturnType<typeof useGetContractListForProjectAndRoleAuthQuery>;
export type GetContractListForProjectAndRoleAuthLazyQueryHookResult = ReturnType<typeof useGetContractListForProjectAndRoleAuthLazyQuery>;
export type GetContractListForProjectAndRoleAuthQueryResult = ApolloReactCommon.QueryResult<GetContractListForProjectAndRoleAuthQuery, GetContractListForProjectAndRoleAuthQueryVariables>;
export const GetContractWithTotalDocument = gql`
    query getContractWithTotal($ID: String, $softwareCode: String) {
  getContract(ID: $ID) {
    ID
    title
    contractDate
    contractRolesUsersAssigned(softwareCode: $softwareCode)
  }
}
    `;

/**
 * __useGetContractWithTotalQuery__
 *
 * To run a query within a React component, call `useGetContractWithTotalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractWithTotalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractWithTotalQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      softwareCode: // value for 'softwareCode'
 *   },
 * });
 */
export function useGetContractWithTotalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractWithTotalQuery, GetContractWithTotalQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractWithTotalQuery, GetContractWithTotalQueryVariables>(GetContractWithTotalDocument, baseOptions);
      }
export function useGetContractWithTotalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractWithTotalQuery, GetContractWithTotalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractWithTotalQuery, GetContractWithTotalQueryVariables>(GetContractWithTotalDocument, baseOptions);
        }
export type GetContractWithTotalQueryHookResult = ReturnType<typeof useGetContractWithTotalQuery>;
export type GetContractWithTotalLazyQueryHookResult = ReturnType<typeof useGetContractWithTotalLazyQuery>;
export type GetContractWithTotalQueryResult = ApolloReactCommon.QueryResult<GetContractWithTotalQuery, GetContractWithTotalQueryVariables>;
export const GetUserRoleListingDocument = gql`
    query getUserRoleListing {
  getRole(orderByAsc: "name") {
    ID
    name
  }
  getUsersByAccountAndSoftware(superUserBool: NONSUPER, status: ACTIVE) {
    ID
    name
  }
}
    `;

/**
 * __useGetUserRoleListingQuery__
 *
 * To run a query within a React component, call `useGetUserRoleListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRoleListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRoleListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserRoleListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserRoleListingQuery, GetUserRoleListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserRoleListingQuery, GetUserRoleListingQueryVariables>(GetUserRoleListingDocument, baseOptions);
      }
export function useGetUserRoleListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserRoleListingQuery, GetUserRoleListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserRoleListingQuery, GetUserRoleListingQueryVariables>(GetUserRoleListingDocument, baseOptions);
        }
export type GetUserRoleListingQueryHookResult = ReturnType<typeof useGetUserRoleListingQuery>;
export type GetUserRoleListingLazyQueryHookResult = ReturnType<typeof useGetUserRoleListingLazyQuery>;
export type GetUserRoleListingQueryResult = ApolloReactCommon.QueryResult<GetUserRoleListingQuery, GetUserRoleListingQueryVariables>;
export const GetEntityRoleUserAssignmentDocument = gql`
    query getEntityRoleUserAssignment($ID: String, $entityID: String) {
  getEntityRoleUserAssignment(ID: $ID, entityID: $entityID) {
    userID
    roleID
  }
}
    `;

/**
 * __useGetEntityRoleUserAssignmentQuery__
 *
 * To run a query within a React component, call `useGetEntityRoleUserAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntityRoleUserAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntityRoleUserAssignmentQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      entityID: // value for 'entityID'
 *   },
 * });
 */
export function useGetEntityRoleUserAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEntityRoleUserAssignmentQuery, GetEntityRoleUserAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEntityRoleUserAssignmentQuery, GetEntityRoleUserAssignmentQueryVariables>(GetEntityRoleUserAssignmentDocument, baseOptions);
      }
export function useGetEntityRoleUserAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEntityRoleUserAssignmentQuery, GetEntityRoleUserAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEntityRoleUserAssignmentQuery, GetEntityRoleUserAssignmentQueryVariables>(GetEntityRoleUserAssignmentDocument, baseOptions);
        }
export type GetEntityRoleUserAssignmentQueryHookResult = ReturnType<typeof useGetEntityRoleUserAssignmentQuery>;
export type GetEntityRoleUserAssignmentLazyQueryHookResult = ReturnType<typeof useGetEntityRoleUserAssignmentLazyQuery>;
export type GetEntityRoleUserAssignmentQueryResult = ApolloReactCommon.QueryResult<GetEntityRoleUserAssignmentQuery, GetEntityRoleUserAssignmentQueryVariables>;
export const GetUsersRoleContractDocument = gql`
    query getUsersRoleContract($roleID: String!, $contractID: String!) {
  getUsersRoleContract(roleID: $roleID, contractID: $contractID)
}
    `;

/**
 * __useGetUsersRoleContractQuery__
 *
 * To run a query within a React component, call `useGetUsersRoleContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersRoleContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersRoleContractQuery({
 *   variables: {
 *      roleID: // value for 'roleID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetUsersRoleContractQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsersRoleContractQuery, GetUsersRoleContractQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUsersRoleContractQuery, GetUsersRoleContractQueryVariables>(GetUsersRoleContractDocument, baseOptions);
      }
export function useGetUsersRoleContractLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUsersRoleContractQuery, GetUsersRoleContractQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUsersRoleContractQuery, GetUsersRoleContractQueryVariables>(GetUsersRoleContractDocument, baseOptions);
        }
export type GetUsersRoleContractQueryHookResult = ReturnType<typeof useGetUsersRoleContractQuery>;
export type GetUsersRoleContractLazyQueryHookResult = ReturnType<typeof useGetUsersRoleContractLazyQuery>;
export type GetUsersRoleContractQueryResult = ApolloReactCommon.QueryResult<GetUsersRoleContractQuery, GetUsersRoleContractQueryVariables>;
export const TestPermissionDocument = gql`
    query testPermission($conPerm: ContractPermission!) {
  testPermission(conPerm: $conPerm)
}
    `;

/**
 * __useTestPermissionQuery__
 *
 * To run a query within a React component, call `useTestPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestPermissionQuery({
 *   variables: {
 *      conPerm: // value for 'conPerm'
 *   },
 * });
 */
export function useTestPermissionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TestPermissionQuery, TestPermissionQueryVariables>) {
        return ApolloReactHooks.useQuery<TestPermissionQuery, TestPermissionQueryVariables>(TestPermissionDocument, baseOptions);
      }
export function useTestPermissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TestPermissionQuery, TestPermissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TestPermissionQuery, TestPermissionQueryVariables>(TestPermissionDocument, baseOptions);
        }
export type TestPermissionQueryHookResult = ReturnType<typeof useTestPermissionQuery>;
export type TestPermissionLazyQueryHookResult = ReturnType<typeof useTestPermissionLazyQuery>;
export type TestPermissionQueryResult = ApolloReactCommon.QueryResult<TestPermissionQuery, TestPermissionQueryVariables>;
export const TestConPermissionDocument = gql`
    query testConPermission($contPerm: ContPermission!) {
  testConPermission(contPerm: $contPerm)
}
    `;

/**
 * __useTestConPermissionQuery__
 *
 * To run a query within a React component, call `useTestConPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestConPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestConPermissionQuery({
 *   variables: {
 *      contPerm: // value for 'contPerm'
 *   },
 * });
 */
export function useTestConPermissionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TestConPermissionQuery, TestConPermissionQueryVariables>) {
        return ApolloReactHooks.useQuery<TestConPermissionQuery, TestConPermissionQueryVariables>(TestConPermissionDocument, baseOptions);
      }
export function useTestConPermissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TestConPermissionQuery, TestConPermissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TestConPermissionQuery, TestConPermissionQueryVariables>(TestConPermissionDocument, baseOptions);
        }
export type TestConPermissionQueryHookResult = ReturnType<typeof useTestConPermissionQuery>;
export type TestConPermissionLazyQueryHookResult = ReturnType<typeof useTestConPermissionLazyQuery>;
export type TestConPermissionQueryResult = ApolloReactCommon.QueryResult<TestConPermissionQuery, TestConPermissionQueryVariables>;
export const SiteTestPermissionDocument = gql`
    query siteTestPermission($sitePerm: SitePermission!) {
  siteTestPermission(sitePerm: $sitePerm)
}
    `;

/**
 * __useSiteTestPermissionQuery__
 *
 * To run a query within a React component, call `useSiteTestPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteTestPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteTestPermissionQuery({
 *   variables: {
 *      sitePerm: // value for 'sitePerm'
 *   },
 * });
 */
export function useSiteTestPermissionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SiteTestPermissionQuery, SiteTestPermissionQueryVariables>) {
        return ApolloReactHooks.useQuery<SiteTestPermissionQuery, SiteTestPermissionQueryVariables>(SiteTestPermissionDocument, baseOptions);
      }
export function useSiteTestPermissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SiteTestPermissionQuery, SiteTestPermissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SiteTestPermissionQuery, SiteTestPermissionQueryVariables>(SiteTestPermissionDocument, baseOptions);
        }
export type SiteTestPermissionQueryHookResult = ReturnType<typeof useSiteTestPermissionQuery>;
export type SiteTestPermissionLazyQueryHookResult = ReturnType<typeof useSiteTestPermissionLazyQuery>;
export type SiteTestPermissionQueryResult = ApolloReactCommon.QueryResult<SiteTestPermissionQuery, SiteTestPermissionQueryVariables>;
export const CreateRolePermissionDocument = gql`
    mutation CreateRolePermission($input: RoleInput!, $permissionArr: [ContractPermission!]!) {
  CreateRolePermission(input: $input, permissionArr: $permissionArr)
}
    `;
export type CreateRolePermissionMutationFn = ApolloReactCommon.MutationFunction<CreateRolePermissionMutation, CreateRolePermissionMutationVariables>;

/**
 * __useCreateRolePermissionMutation__
 *
 * To run a mutation, you first call `useCreateRolePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRolePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRolePermissionMutation, { data, loading, error }] = useCreateRolePermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      permissionArr: // value for 'permissionArr'
 *   },
 * });
 */
export function useCreateRolePermissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRolePermissionMutation, CreateRolePermissionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRolePermissionMutation, CreateRolePermissionMutationVariables>(CreateRolePermissionDocument, baseOptions);
      }
export type CreateRolePermissionMutationHookResult = ReturnType<typeof useCreateRolePermissionMutation>;
export type CreateRolePermissionMutationResult = ApolloReactCommon.MutationResult<CreateRolePermissionMutation>;
export type CreateRolePermissionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRolePermissionMutation, CreateRolePermissionMutationVariables>;
export const UpdateRolePermissionDocument = gql`
    mutation UpdateRolePermission($input: RoleInput!, $permissionArr: [ContractPermission!]!) {
  UpdateRolePermission(input: $input, permissionArr: $permissionArr)
}
    `;
export type UpdateRolePermissionMutationFn = ApolloReactCommon.MutationFunction<UpdateRolePermissionMutation, UpdateRolePermissionMutationVariables>;

/**
 * __useUpdateRolePermissionMutation__
 *
 * To run a mutation, you first call `useUpdateRolePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRolePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRolePermissionMutation, { data, loading, error }] = useUpdateRolePermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      permissionArr: // value for 'permissionArr'
 *   },
 * });
 */
export function useUpdateRolePermissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRolePermissionMutation, UpdateRolePermissionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRolePermissionMutation, UpdateRolePermissionMutationVariables>(UpdateRolePermissionDocument, baseOptions);
      }
export type UpdateRolePermissionMutationHookResult = ReturnType<typeof useUpdateRolePermissionMutation>;
export type UpdateRolePermissionMutationResult = ApolloReactCommon.MutationResult<UpdateRolePermissionMutation>;
export type UpdateRolePermissionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRolePermissionMutation, UpdateRolePermissionMutationVariables>;
export const CreateContractRoleDocument = gql`
    mutation CreateContractRole($input: RoleInput!, $permissionArr: [ContPermission!]!) {
  CreateContractRole(input: $input, permissionArr: $permissionArr)
}
    `;
export type CreateContractRoleMutationFn = ApolloReactCommon.MutationFunction<CreateContractRoleMutation, CreateContractRoleMutationVariables>;

/**
 * __useCreateContractRoleMutation__
 *
 * To run a mutation, you first call `useCreateContractRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractRoleMutation, { data, loading, error }] = useCreateContractRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *      permissionArr: // value for 'permissionArr'
 *   },
 * });
 */
export function useCreateContractRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractRoleMutation, CreateContractRoleMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractRoleMutation, CreateContractRoleMutationVariables>(CreateContractRoleDocument, baseOptions);
      }
export type CreateContractRoleMutationHookResult = ReturnType<typeof useCreateContractRoleMutation>;
export type CreateContractRoleMutationResult = ApolloReactCommon.MutationResult<CreateContractRoleMutation>;
export type CreateContractRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractRoleMutation, CreateContractRoleMutationVariables>;
export const UpdateContractRoleDocument = gql`
    mutation UpdateContractRole($input: RoleInput!, $permissionArr: [ContPermission!]!) {
  UpdateContractRole(input: $input, permissionArr: $permissionArr)
}
    `;
export type UpdateContractRoleMutationFn = ApolloReactCommon.MutationFunction<UpdateContractRoleMutation, UpdateContractRoleMutationVariables>;

/**
 * __useUpdateContractRoleMutation__
 *
 * To run a mutation, you first call `useUpdateContractRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractRoleMutation, { data, loading, error }] = useUpdateContractRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *      permissionArr: // value for 'permissionArr'
 *   },
 * });
 */
export function useUpdateContractRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContractRoleMutation, UpdateContractRoleMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContractRoleMutation, UpdateContractRoleMutationVariables>(UpdateContractRoleDocument, baseOptions);
      }
export type UpdateContractRoleMutationHookResult = ReturnType<typeof useUpdateContractRoleMutation>;
export type UpdateContractRoleMutationResult = ApolloReactCommon.MutationResult<UpdateContractRoleMutation>;
export type UpdateContractRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContractRoleMutation, UpdateContractRoleMutationVariables>;
export const CreateSiteRoleDocument = gql`
    mutation CreateSiteRole($input: RoleInput!, $permissionArr: [SitePermission!]!) {
  CreateSiteRole(input: $input, permissionArr: $permissionArr)
}
    `;
export type CreateSiteRoleMutationFn = ApolloReactCommon.MutationFunction<CreateSiteRoleMutation, CreateSiteRoleMutationVariables>;

/**
 * __useCreateSiteRoleMutation__
 *
 * To run a mutation, you first call `useCreateSiteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSiteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSiteRoleMutation, { data, loading, error }] = useCreateSiteRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *      permissionArr: // value for 'permissionArr'
 *   },
 * });
 */
export function useCreateSiteRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSiteRoleMutation, CreateSiteRoleMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSiteRoleMutation, CreateSiteRoleMutationVariables>(CreateSiteRoleDocument, baseOptions);
      }
export type CreateSiteRoleMutationHookResult = ReturnType<typeof useCreateSiteRoleMutation>;
export type CreateSiteRoleMutationResult = ApolloReactCommon.MutationResult<CreateSiteRoleMutation>;
export type CreateSiteRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSiteRoleMutation, CreateSiteRoleMutationVariables>;
export const UpdateSiteRoleDocument = gql`
    mutation UpdateSiteRole($input: RoleInput!, $permissionArr: [SitePermission!]!) {
  UpdateSiteRole(input: $input, permissionArr: $permissionArr)
}
    `;
export type UpdateSiteRoleMutationFn = ApolloReactCommon.MutationFunction<UpdateSiteRoleMutation, UpdateSiteRoleMutationVariables>;

/**
 * __useUpdateSiteRoleMutation__
 *
 * To run a mutation, you first call `useUpdateSiteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteRoleMutation, { data, loading, error }] = useUpdateSiteRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *      permissionArr: // value for 'permissionArr'
 *   },
 * });
 */
export function useUpdateSiteRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSiteRoleMutation, UpdateSiteRoleMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSiteRoleMutation, UpdateSiteRoleMutationVariables>(UpdateSiteRoleDocument, baseOptions);
      }
export type UpdateSiteRoleMutationHookResult = ReturnType<typeof useUpdateSiteRoleMutation>;
export type UpdateSiteRoleMutationResult = ApolloReactCommon.MutationResult<UpdateSiteRoleMutation>;
export type UpdateSiteRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSiteRoleMutation, UpdateSiteRoleMutationVariables>;
export const GetRoleDocument = gql`
    query getRole($ID: String, $softwareCode: SoftwareCode, $orderByAsc: String, $limit: Float, $take: Float) {
  getRole(ID: $ID, softwareCode: $softwareCode, orderByAsc: $orderByAsc, limit: $limit, take: $take) {
    ID
    name
    softwareCode
    assignedUserEntity
    conRolePerm {
      ID
      permName
      permissionID
    }
    rolePerm {
      permissionID
      roleID
      permName
    }
    siteRolePerm {
      ID
      permName
      permissionID
    }
  }
  roleDate
}
    `;

/**
 * __useGetRoleQuery__
 *
 * To run a query within a React component, call `useGetRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      softwareCode: // value for 'softwareCode'
 *      orderByAsc: // value for 'orderByAsc'
 *      limit: // value for 'limit'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetRoleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, baseOptions);
      }
export function useGetRoleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, baseOptions);
        }
export type GetRoleQueryHookResult = ReturnType<typeof useGetRoleQuery>;
export type GetRoleLazyQueryHookResult = ReturnType<typeof useGetRoleLazyQuery>;
export type GetRoleQueryResult = ApolloReactCommon.QueryResult<GetRoleQuery, GetRoleQueryVariables>;
export const GetPolicyRolesDocument = gql`
    query getPolicyRoles($ID: String, $softwareCode: SoftwareCode) {
  getRole(ID: $ID, softwareCode: $softwareCode) {
    ID
    name
  }
  getPolicyRole {
    ID
    name
  }
}
    `;

/**
 * __useGetPolicyRolesQuery__
 *
 * To run a query within a React component, call `useGetPolicyRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPolicyRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPolicyRolesQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      softwareCode: // value for 'softwareCode'
 *   },
 * });
 */
export function useGetPolicyRolesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPolicyRolesQuery, GetPolicyRolesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPolicyRolesQuery, GetPolicyRolesQueryVariables>(GetPolicyRolesDocument, baseOptions);
      }
export function useGetPolicyRolesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPolicyRolesQuery, GetPolicyRolesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPolicyRolesQuery, GetPolicyRolesQueryVariables>(GetPolicyRolesDocument, baseOptions);
        }
export type GetPolicyRolesQueryHookResult = ReturnType<typeof useGetPolicyRolesQuery>;
export type GetPolicyRolesLazyQueryHookResult = ReturnType<typeof useGetPolicyRolesLazyQuery>;
export type GetPolicyRolesQueryResult = ApolloReactCommon.QueryResult<GetPolicyRolesQuery, GetPolicyRolesQueryVariables>;
export const GetRoleNameDocument = gql`
    query getRoleName($ID: String, $softwareCode: SoftwareCode, $take: Float) {
  getRole(ID: $ID, orderByAsc: "name", softwareCode: $softwareCode, take: $take) {
    ID
    name
  }
}
    `;

/**
 * __useGetRoleNameQuery__
 *
 * To run a query within a React component, call `useGetRoleNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleNameQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      softwareCode: // value for 'softwareCode'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetRoleNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRoleNameQuery, GetRoleNameQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRoleNameQuery, GetRoleNameQueryVariables>(GetRoleNameDocument, baseOptions);
      }
export function useGetRoleNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRoleNameQuery, GetRoleNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRoleNameQuery, GetRoleNameQueryVariables>(GetRoleNameDocument, baseOptions);
        }
export type GetRoleNameQueryHookResult = ReturnType<typeof useGetRoleNameQuery>;
export type GetRoleNameLazyQueryHookResult = ReturnType<typeof useGetRoleNameLazyQuery>;
export type GetRoleNameQueryResult = ApolloReactCommon.QueryResult<GetRoleNameQuery, GetRoleNameQueryVariables>;
export const DeleteRolePermissionDocument = gql`
    mutation DeleteRolePermission($roleID: String!, $forceDelete: Boolean) {
  DeleteRolePermission(roleID: $roleID, forceDelete: $forceDelete)
}
    `;
export type DeleteRolePermissionMutationFn = ApolloReactCommon.MutationFunction<DeleteRolePermissionMutation, DeleteRolePermissionMutationVariables>;

/**
 * __useDeleteRolePermissionMutation__
 *
 * To run a mutation, you first call `useDeleteRolePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRolePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRolePermissionMutation, { data, loading, error }] = useDeleteRolePermissionMutation({
 *   variables: {
 *      roleID: // value for 'roleID'
 *      forceDelete: // value for 'forceDelete'
 *   },
 * });
 */
export function useDeleteRolePermissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRolePermissionMutation, DeleteRolePermissionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRolePermissionMutation, DeleteRolePermissionMutationVariables>(DeleteRolePermissionDocument, baseOptions);
      }
export type DeleteRolePermissionMutationHookResult = ReturnType<typeof useDeleteRolePermissionMutation>;
export type DeleteRolePermissionMutationResult = ApolloReactCommon.MutationResult<DeleteRolePermissionMutation>;
export type DeleteRolePermissionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRolePermissionMutation, DeleteRolePermissionMutationVariables>;
export const GetSAccountDocument = gql`
    query getSAccount($accountID: String) {
  getSAccount(accountID: $accountID) {
    ID
    companyCode
    currencyCode
    companyName
    currencyName
    gstRegNo
    gstExpDate
    sstRegNo
    logoPosition
    watermarkImplementation
    watermarkWording
    isoLogoPosition
    isoImplementation
    address
  }
}
    `;

/**
 * __useGetSAccountQuery__
 *
 * To run a query within a React component, call `useGetSAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSAccountQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useGetSAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSAccountQuery, GetSAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSAccountQuery, GetSAccountQueryVariables>(GetSAccountDocument, baseOptions);
      }
export function useGetSAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSAccountQuery, GetSAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSAccountQuery, GetSAccountQueryVariables>(GetSAccountDocument, baseOptions);
        }
export type GetSAccountQueryHookResult = ReturnType<typeof useGetSAccountQuery>;
export type GetSAccountLazyQueryHookResult = ReturnType<typeof useGetSAccountLazyQuery>;
export type GetSAccountQueryResult = ApolloReactCommon.QueryResult<GetSAccountQuery, GetSAccountQueryVariables>;
export const UpdateSAccountDocument = gql`
    mutation updateSAccount($input: SAccountInput!) {
  updateSAccount(input: $input) {
    ID
    watermarkWording
    logoPosition
    watermarkImplementation
    gstRegNo
    gstExpDate
    sstRegNo
    isoImplementation
    isoLogoPosition
  }
}
    `;
export type UpdateSAccountMutationFn = ApolloReactCommon.MutationFunction<UpdateSAccountMutation, UpdateSAccountMutationVariables>;

/**
 * __useUpdateSAccountMutation__
 *
 * To run a mutation, you first call `useUpdateSAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSAccountMutation, { data, loading, error }] = useUpdateSAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSAccountMutation, UpdateSAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSAccountMutation, UpdateSAccountMutationVariables>(UpdateSAccountDocument, baseOptions);
      }
export type UpdateSAccountMutationHookResult = ReturnType<typeof useUpdateSAccountMutation>;
export type UpdateSAccountMutationResult = ApolloReactCommon.MutationResult<UpdateSAccountMutation>;
export type UpdateSAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSAccountMutation, UpdateSAccountMutationVariables>;
export const GetSubcontractorSetupDocument = gql`
    query getSubcontractorSetup($ID: String, $orderByAsc: String) {
  getSubcontractor(ID: $ID, orderByAsc: $orderByAsc) {
    ID
    name
    email
    accountID
    registrationNo
    contactNo
    creditTerm
    ratingType
    cidbDetails
    description
    address
    createdTs
    createdBy
    modTs
    modBy
    nominatedSC
    overdueAdvInfo
    overdueInvInfo
    overdueInvInfoNoBound
    associatedStatus
    activeSubcontract
    contactPerson {
      ID
      name
      designation
      email
      contactNo
      associateID
    }
  }
}
    `;

/**
 * __useGetSubcontractorSetupQuery__
 *
 * To run a query within a React component, call `useGetSubcontractorSetupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractorSetupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractorSetupQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetSubcontractorSetupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractorSetupQuery, GetSubcontractorSetupQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractorSetupQuery, GetSubcontractorSetupQueryVariables>(GetSubcontractorSetupDocument, baseOptions);
      }
export function useGetSubcontractorSetupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractorSetupQuery, GetSubcontractorSetupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractorSetupQuery, GetSubcontractorSetupQueryVariables>(GetSubcontractorSetupDocument, baseOptions);
        }
export type GetSubcontractorSetupQueryHookResult = ReturnType<typeof useGetSubcontractorSetupQuery>;
export type GetSubcontractorSetupLazyQueryHookResult = ReturnType<typeof useGetSubcontractorSetupLazyQuery>;
export type GetSubcontractorSetupQueryResult = ApolloReactCommon.QueryResult<GetSubcontractorSetupQuery, GetSubcontractorSetupQueryVariables>;
export const CreateSubcontractorSetupDocument = gql`
    mutation createSubcontractorSetup($input: SubcontractorHeaderInput!, $costCategoryID: [String!]!, $contactInput: [ContactPersonInput!]!) {
  createSubcontractorSetup(input: $input, costCategoryID: $costCategoryID, contactInput: $contactInput)
}
    `;
export type CreateSubcontractorSetupMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractorSetupMutation, CreateSubcontractorSetupMutationVariables>;

/**
 * __useCreateSubcontractorSetupMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractorSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractorSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractorSetupMutation, { data, loading, error }] = useCreateSubcontractorSetupMutation({
 *   variables: {
 *      input: // value for 'input'
 *      costCategoryID: // value for 'costCategoryID'
 *      contactInput: // value for 'contactInput'
 *   },
 * });
 */
export function useCreateSubcontractorSetupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractorSetupMutation, CreateSubcontractorSetupMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractorSetupMutation, CreateSubcontractorSetupMutationVariables>(CreateSubcontractorSetupDocument, baseOptions);
      }
export type CreateSubcontractorSetupMutationHookResult = ReturnType<typeof useCreateSubcontractorSetupMutation>;
export type CreateSubcontractorSetupMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractorSetupMutation>;
export type CreateSubcontractorSetupMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractorSetupMutation, CreateSubcontractorSetupMutationVariables>;
export const UpdateSubcontractorSetupDocument = gql`
    mutation updateSubcontractorSetup($input: SubcontractorHeaderInput!, $costCategoryID: [String!]!, $contactInput: [ContactPersonInput!]!) {
  updateSubcontractorSetup(input: $input, costCategoryID: $costCategoryID, contactInput: $contactInput)
}
    `;
export type UpdateSubcontractorSetupMutationFn = ApolloReactCommon.MutationFunction<UpdateSubcontractorSetupMutation, UpdateSubcontractorSetupMutationVariables>;

/**
 * __useUpdateSubcontractorSetupMutation__
 *
 * To run a mutation, you first call `useUpdateSubcontractorSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubcontractorSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubcontractorSetupMutation, { data, loading, error }] = useUpdateSubcontractorSetupMutation({
 *   variables: {
 *      input: // value for 'input'
 *      costCategoryID: // value for 'costCategoryID'
 *      contactInput: // value for 'contactInput'
 *   },
 * });
 */
export function useUpdateSubcontractorSetupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubcontractorSetupMutation, UpdateSubcontractorSetupMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubcontractorSetupMutation, UpdateSubcontractorSetupMutationVariables>(UpdateSubcontractorSetupDocument, baseOptions);
      }
export type UpdateSubcontractorSetupMutationHookResult = ReturnType<typeof useUpdateSubcontractorSetupMutation>;
export type UpdateSubcontractorSetupMutationResult = ApolloReactCommon.MutationResult<UpdateSubcontractorSetupMutation>;
export type UpdateSubcontractorSetupMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubcontractorSetupMutation, UpdateSubcontractorSetupMutationVariables>;
export const DeleteSubcontractorSetupDocument = gql`
    mutation deleteSubcontractorSetup($ID: String!) {
  deleteSubcontractorSetup(ID: $ID)
}
    `;
export type DeleteSubcontractorSetupMutationFn = ApolloReactCommon.MutationFunction<DeleteSubcontractorSetupMutation, DeleteSubcontractorSetupMutationVariables>;

/**
 * __useDeleteSubcontractorSetupMutation__
 *
 * To run a mutation, you first call `useDeleteSubcontractorSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubcontractorSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubcontractorSetupMutation, { data, loading, error }] = useDeleteSubcontractorSetupMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteSubcontractorSetupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSubcontractorSetupMutation, DeleteSubcontractorSetupMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSubcontractorSetupMutation, DeleteSubcontractorSetupMutationVariables>(DeleteSubcontractorSetupDocument, baseOptions);
      }
export type DeleteSubcontractorSetupMutationHookResult = ReturnType<typeof useDeleteSubcontractorSetupMutation>;
export type DeleteSubcontractorSetupMutationResult = ApolloReactCommon.MutationResult<DeleteSubcontractorSetupMutation>;
export type DeleteSubcontractorSetupMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSubcontractorSetupMutation, DeleteSubcontractorSetupMutationVariables>;
export const GetSubcontractorAssocListingDocument = gql`
    query getSubcontractorAssocListing($ID: String, $orderByAsc: String) {
  getSubcontractor(ID: $ID, orderByAsc: $orderByAsc) {
    ID
    name
    overdueAdvInfo
    overdueInvInfo
    activeSubcontract
  }
}
    `;

/**
 * __useGetSubcontractorAssocListingQuery__
 *
 * To run a query within a React component, call `useGetSubcontractorAssocListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractorAssocListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractorAssocListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetSubcontractorAssocListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractorAssocListingQuery, GetSubcontractorAssocListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractorAssocListingQuery, GetSubcontractorAssocListingQueryVariables>(GetSubcontractorAssocListingDocument, baseOptions);
      }
export function useGetSubcontractorAssocListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractorAssocListingQuery, GetSubcontractorAssocListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractorAssocListingQuery, GetSubcontractorAssocListingQueryVariables>(GetSubcontractorAssocListingDocument, baseOptions);
        }
export type GetSubcontractorAssocListingQueryHookResult = ReturnType<typeof useGetSubcontractorAssocListingQuery>;
export type GetSubcontractorAssocListingLazyQueryHookResult = ReturnType<typeof useGetSubcontractorAssocListingLazyQuery>;
export type GetSubcontractorAssocListingQueryResult = ApolloReactCommon.QueryResult<GetSubcontractorAssocListingQuery, GetSubcontractorAssocListingQueryVariables>;
export const GetSubcontractorCCategoryDocument = gql`
    query getSubcontractorCCategory($subcontractorID: String) {
  getSubcontractorCCategory(subcontractorID: $subcontractorID) {
    subcontractorID
    costCategoryID
  }
}
    `;

/**
 * __useGetSubcontractorCCategoryQuery__
 *
 * To run a query within a React component, call `useGetSubcontractorCCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractorCCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractorCCategoryQuery({
 *   variables: {
 *      subcontractorID: // value for 'subcontractorID'
 *   },
 * });
 */
export function useGetSubcontractorCCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractorCCategoryQuery, GetSubcontractorCCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractorCCategoryQuery, GetSubcontractorCCategoryQueryVariables>(GetSubcontractorCCategoryDocument, baseOptions);
      }
export function useGetSubcontractorCCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractorCCategoryQuery, GetSubcontractorCCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractorCCategoryQuery, GetSubcontractorCCategoryQueryVariables>(GetSubcontractorCCategoryDocument, baseOptions);
        }
export type GetSubcontractorCCategoryQueryHookResult = ReturnType<typeof useGetSubcontractorCCategoryQuery>;
export type GetSubcontractorCCategoryLazyQueryHookResult = ReturnType<typeof useGetSubcontractorCCategoryLazyQuery>;
export type GetSubcontractorCCategoryQueryResult = ApolloReactCommon.QueryResult<GetSubcontractorCCategoryQuery, GetSubcontractorCCategoryQueryVariables>;
export const GetOutstandingSubconClaimDnDocument = gql`
    query getOutstandingSubconClaimDN($subcontractorID: String, $transactionStatus: TransactionStatus) {
  getSubcontractClaim(subcontractorID: $subcontractorID, transactionStatus: $transactionStatus) {
    ID
    claimDocNo
    docNo
    docDate
    description
    outstandingAmt
    outstandingCertifiedAmt
    transactionStatus
  }
  getSubcontractDN(subcontractorID: $subcontractorID, transactionStatus: $transactionStatus) {
    ID
    docNo
    docDate
    description
    outstandingAmt
    transactionStatus
  }
}
    `;

/**
 * __useGetOutstandingSubconClaimDnQuery__
 *
 * To run a query within a React component, call `useGetOutstandingSubconClaimDnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutstandingSubconClaimDnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutstandingSubconClaimDnQuery({
 *   variables: {
 *      subcontractorID: // value for 'subcontractorID'
 *      transactionStatus: // value for 'transactionStatus'
 *   },
 * });
 */
export function useGetOutstandingSubconClaimDnQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOutstandingSubconClaimDnQuery, GetOutstandingSubconClaimDnQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOutstandingSubconClaimDnQuery, GetOutstandingSubconClaimDnQueryVariables>(GetOutstandingSubconClaimDnDocument, baseOptions);
      }
export function useGetOutstandingSubconClaimDnLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOutstandingSubconClaimDnQuery, GetOutstandingSubconClaimDnQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOutstandingSubconClaimDnQuery, GetOutstandingSubconClaimDnQueryVariables>(GetOutstandingSubconClaimDnDocument, baseOptions);
        }
export type GetOutstandingSubconClaimDnQueryHookResult = ReturnType<typeof useGetOutstandingSubconClaimDnQuery>;
export type GetOutstandingSubconClaimDnLazyQueryHookResult = ReturnType<typeof useGetOutstandingSubconClaimDnLazyQuery>;
export type GetOutstandingSubconClaimDnQueryResult = ApolloReactCommon.QueryResult<GetOutstandingSubconClaimDnQuery, GetOutstandingSubconClaimDnQueryVariables>;
export const GetSystemAdminSummaryDocument = gql`
    query getSystemAdminSummary {
  loggedInUserProfile {
    ID
    accountID
  }
  conAuthDate
  costCategoryDates
  userDate
  roleDate
  uomDate
  bankAccountDate
  taxSchemeDate
  docNumDate
  uomConversionDate
  companyDate
  policyDate
  notificationDate
  costCenterDate
  currencyExchangeDate
  currencySetupDate
  projectSiteDate
  subconSetupDate
}
    `;

/**
 * __useGetSystemAdminSummaryQuery__
 *
 * To run a query within a React component, call `useGetSystemAdminSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSystemAdminSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSystemAdminSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSystemAdminSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSystemAdminSummaryQuery, GetSystemAdminSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSystemAdminSummaryQuery, GetSystemAdminSummaryQueryVariables>(GetSystemAdminSummaryDocument, baseOptions);
      }
export function useGetSystemAdminSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSystemAdminSummaryQuery, GetSystemAdminSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSystemAdminSummaryQuery, GetSystemAdminSummaryQueryVariables>(GetSystemAdminSummaryDocument, baseOptions);
        }
export type GetSystemAdminSummaryQueryHookResult = ReturnType<typeof useGetSystemAdminSummaryQuery>;
export type GetSystemAdminSummaryLazyQueryHookResult = ReturnType<typeof useGetSystemAdminSummaryLazyQuery>;
export type GetSystemAdminSummaryQueryResult = ApolloReactCommon.QueryResult<GetSystemAdminSummaryQuery, GetSystemAdminSummaryQueryVariables>;
export const GetCommonSettingSummaryDocument = gql`
    query getCommonSettingSummary {
  currencySetupDate
  notificationDate
  taxSchemeDate
  uomDate
  uomConversionDate
}
    `;

/**
 * __useGetCommonSettingSummaryQuery__
 *
 * To run a query within a React component, call `useGetCommonSettingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommonSettingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommonSettingSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCommonSettingSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCommonSettingSummaryQuery, GetCommonSettingSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCommonSettingSummaryQuery, GetCommonSettingSummaryQueryVariables>(GetCommonSettingSummaryDocument, baseOptions);
      }
export function useGetCommonSettingSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCommonSettingSummaryQuery, GetCommonSettingSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCommonSettingSummaryQuery, GetCommonSettingSummaryQueryVariables>(GetCommonSettingSummaryDocument, baseOptions);
        }
export type GetCommonSettingSummaryQueryHookResult = ReturnType<typeof useGetCommonSettingSummaryQuery>;
export type GetCommonSettingSummaryLazyQueryHookResult = ReturnType<typeof useGetCommonSettingSummaryLazyQuery>;
export type GetCommonSettingSummaryQueryResult = ApolloReactCommon.QueryResult<GetCommonSettingSummaryQuery, GetCommonSettingSummaryQueryVariables>;
export const GetSiteSystemAdminSummaryDocument = gql`
    query getSiteSystemAdminSummary {
  loggedInUserProfile {
    ID
    accountID
  }
  projectSiteDate
  subconSetupDate
  taxSchemeDate
  uomDate
  uomConversionDate
  companyDate
  policyDate
}
    `;

/**
 * __useGetSiteSystemAdminSummaryQuery__
 *
 * To run a query within a React component, call `useGetSiteSystemAdminSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteSystemAdminSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteSystemAdminSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSiteSystemAdminSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteSystemAdminSummaryQuery, GetSiteSystemAdminSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteSystemAdminSummaryQuery, GetSiteSystemAdminSummaryQueryVariables>(GetSiteSystemAdminSummaryDocument, baseOptions);
      }
export function useGetSiteSystemAdminSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteSystemAdminSummaryQuery, GetSiteSystemAdminSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteSystemAdminSummaryQuery, GetSiteSystemAdminSummaryQueryVariables>(GetSiteSystemAdminSummaryDocument, baseOptions);
        }
export type GetSiteSystemAdminSummaryQueryHookResult = ReturnType<typeof useGetSiteSystemAdminSummaryQuery>;
export type GetSiteSystemAdminSummaryLazyQueryHookResult = ReturnType<typeof useGetSiteSystemAdminSummaryLazyQuery>;
export type GetSiteSystemAdminSummaryQueryResult = ApolloReactCommon.QueryResult<GetSiteSystemAdminSummaryQuery, GetSiteSystemAdminSummaryQueryVariables>;
export const GetTaxSchemeDocument = gql`
    query getTaxScheme($ID: String) {
  getTaxScheme(ID: $ID) {
    id
    name
    description
    commonStatus
    isExclusive
  }
}
    `;

/**
 * __useGetTaxSchemeQuery__
 *
 * To run a query within a React component, call `useGetTaxSchemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxSchemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxSchemeQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetTaxSchemeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaxSchemeQuery, GetTaxSchemeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaxSchemeQuery, GetTaxSchemeQueryVariables>(GetTaxSchemeDocument, baseOptions);
      }
export function useGetTaxSchemeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaxSchemeQuery, GetTaxSchemeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaxSchemeQuery, GetTaxSchemeQueryVariables>(GetTaxSchemeDocument, baseOptions);
        }
export type GetTaxSchemeQueryHookResult = ReturnType<typeof useGetTaxSchemeQuery>;
export type GetTaxSchemeLazyQueryHookResult = ReturnType<typeof useGetTaxSchemeLazyQuery>;
export type GetTaxSchemeQueryResult = ApolloReactCommon.QueryResult<GetTaxSchemeQuery, GetTaxSchemeQueryVariables>;
export const UpdateConTaxTypeDocument = gql`
    mutation updateConTaxType($input: ConTaxTypeInput!) {
  updateConTaxType(input: $input) {
    ID
    code
    description
  }
}
    `;
export type UpdateConTaxTypeMutationFn = ApolloReactCommon.MutationFunction<UpdateConTaxTypeMutation, UpdateConTaxTypeMutationVariables>;

/**
 * __useUpdateConTaxTypeMutation__
 *
 * To run a mutation, you first call `useUpdateConTaxTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConTaxTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConTaxTypeMutation, { data, loading, error }] = useUpdateConTaxTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConTaxTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateConTaxTypeMutation, UpdateConTaxTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateConTaxTypeMutation, UpdateConTaxTypeMutationVariables>(UpdateConTaxTypeDocument, baseOptions);
      }
export type UpdateConTaxTypeMutationHookResult = ReturnType<typeof useUpdateConTaxTypeMutation>;
export type UpdateConTaxTypeMutationResult = ApolloReactCommon.MutationResult<UpdateConTaxTypeMutation>;
export type UpdateConTaxTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateConTaxTypeMutation, UpdateConTaxTypeMutationVariables>;
export const CreateConTaxTypeDocument = gql`
    mutation createConTaxType($input: ConTaxTypeInput!) {
  createConTaxType(input: $input) {
    ID
  }
}
    `;
export type CreateConTaxTypeMutationFn = ApolloReactCommon.MutationFunction<CreateConTaxTypeMutation, CreateConTaxTypeMutationVariables>;

/**
 * __useCreateConTaxTypeMutation__
 *
 * To run a mutation, you first call `useCreateConTaxTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConTaxTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConTaxTypeMutation, { data, loading, error }] = useCreateConTaxTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateConTaxTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateConTaxTypeMutation, CreateConTaxTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateConTaxTypeMutation, CreateConTaxTypeMutationVariables>(CreateConTaxTypeDocument, baseOptions);
      }
export type CreateConTaxTypeMutationHookResult = ReturnType<typeof useCreateConTaxTypeMutation>;
export type CreateConTaxTypeMutationResult = ApolloReactCommon.MutationResult<CreateConTaxTypeMutation>;
export type CreateConTaxTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateConTaxTypeMutation, CreateConTaxTypeMutationVariables>;
export const GetConTaxTypeDocument = gql`
    query getConTaxType($ID: String, $commonStatus: CommonStatus) {
  getConTaxType(ID: $ID, commonStatus: $commonStatus) {
    createdTs
    ID
    code
    commonStatus
    description
    glTaxDynamic {
      ID
      accountCode
    }
  }
}
    `;

/**
 * __useGetConTaxTypeQuery__
 *
 * To run a query within a React component, call `useGetConTaxTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConTaxTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConTaxTypeQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      commonStatus: // value for 'commonStatus'
 *   },
 * });
 */
export function useGetConTaxTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetConTaxTypeQuery, GetConTaxTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetConTaxTypeQuery, GetConTaxTypeQueryVariables>(GetConTaxTypeDocument, baseOptions);
      }
export function useGetConTaxTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetConTaxTypeQuery, GetConTaxTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetConTaxTypeQuery, GetConTaxTypeQueryVariables>(GetConTaxTypeDocument, baseOptions);
        }
export type GetConTaxTypeQueryHookResult = ReturnType<typeof useGetConTaxTypeQuery>;
export type GetConTaxTypeLazyQueryHookResult = ReturnType<typeof useGetConTaxTypeLazyQuery>;
export type GetConTaxTypeQueryResult = ApolloReactCommon.QueryResult<GetConTaxTypeQuery, GetConTaxTypeQueryVariables>;
export const DeleteConTaxTypeDocument = gql`
    mutation deleteConTaxType($input: ConTaxTypeInput!) {
  deleteConTaxType(input: $input)
}
    `;
export type DeleteConTaxTypeMutationFn = ApolloReactCommon.MutationFunction<DeleteConTaxTypeMutation, DeleteConTaxTypeMutationVariables>;

/**
 * __useDeleteConTaxTypeMutation__
 *
 * To run a mutation, you first call `useDeleteConTaxTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConTaxTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConTaxTypeMutation, { data, loading, error }] = useDeleteConTaxTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteConTaxTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteConTaxTypeMutation, DeleteConTaxTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteConTaxTypeMutation, DeleteConTaxTypeMutationVariables>(DeleteConTaxTypeDocument, baseOptions);
      }
export type DeleteConTaxTypeMutationHookResult = ReturnType<typeof useDeleteConTaxTypeMutation>;
export type DeleteConTaxTypeMutationResult = ApolloReactCommon.MutationResult<DeleteConTaxTypeMutation>;
export type DeleteConTaxTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteConTaxTypeMutation, DeleteConTaxTypeMutationVariables>;
export const CreateConTaxSchemeDocument = gql`
    mutation createConTaxScheme($input: ConTaxSchemeInput!) {
  createConTaxScheme(input: $input) {
    ID
  }
}
    `;
export type CreateConTaxSchemeMutationFn = ApolloReactCommon.MutationFunction<CreateConTaxSchemeMutation, CreateConTaxSchemeMutationVariables>;

/**
 * __useCreateConTaxSchemeMutation__
 *
 * To run a mutation, you first call `useCreateConTaxSchemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConTaxSchemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConTaxSchemeMutation, { data, loading, error }] = useCreateConTaxSchemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateConTaxSchemeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateConTaxSchemeMutation, CreateConTaxSchemeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateConTaxSchemeMutation, CreateConTaxSchemeMutationVariables>(CreateConTaxSchemeDocument, baseOptions);
      }
export type CreateConTaxSchemeMutationHookResult = ReturnType<typeof useCreateConTaxSchemeMutation>;
export type CreateConTaxSchemeMutationResult = ApolloReactCommon.MutationResult<CreateConTaxSchemeMutation>;
export type CreateConTaxSchemeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateConTaxSchemeMutation, CreateConTaxSchemeMutationVariables>;
export const UpdateConTaxSchemeDocument = gql`
    mutation updateConTaxScheme($input: ConTaxSchemeInput!) {
  updateConTaxScheme(input: $input) {
    ID
  }
}
    `;
export type UpdateConTaxSchemeMutationFn = ApolloReactCommon.MutationFunction<UpdateConTaxSchemeMutation, UpdateConTaxSchemeMutationVariables>;

/**
 * __useUpdateConTaxSchemeMutation__
 *
 * To run a mutation, you first call `useUpdateConTaxSchemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConTaxSchemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConTaxSchemeMutation, { data, loading, error }] = useUpdateConTaxSchemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConTaxSchemeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateConTaxSchemeMutation, UpdateConTaxSchemeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateConTaxSchemeMutation, UpdateConTaxSchemeMutationVariables>(UpdateConTaxSchemeDocument, baseOptions);
      }
export type UpdateConTaxSchemeMutationHookResult = ReturnType<typeof useUpdateConTaxSchemeMutation>;
export type UpdateConTaxSchemeMutationResult = ApolloReactCommon.MutationResult<UpdateConTaxSchemeMutation>;
export type UpdateConTaxSchemeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateConTaxSchemeMutation, UpdateConTaxSchemeMutationVariables>;
export const DeleteConTaxSchemeDocument = gql`
    mutation deleteConTaxScheme($input: ConTaxSchemeInput!) {
  deleteConTaxScheme(input: $input)
}
    `;
export type DeleteConTaxSchemeMutationFn = ApolloReactCommon.MutationFunction<DeleteConTaxSchemeMutation, DeleteConTaxSchemeMutationVariables>;

/**
 * __useDeleteConTaxSchemeMutation__
 *
 * To run a mutation, you first call `useDeleteConTaxSchemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConTaxSchemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConTaxSchemeMutation, { data, loading, error }] = useDeleteConTaxSchemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteConTaxSchemeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteConTaxSchemeMutation, DeleteConTaxSchemeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteConTaxSchemeMutation, DeleteConTaxSchemeMutationVariables>(DeleteConTaxSchemeDocument, baseOptions);
      }
export type DeleteConTaxSchemeMutationHookResult = ReturnType<typeof useDeleteConTaxSchemeMutation>;
export type DeleteConTaxSchemeMutationResult = ApolloReactCommon.MutationResult<DeleteConTaxSchemeMutation>;
export type DeleteConTaxSchemeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteConTaxSchemeMutation, DeleteConTaxSchemeMutationVariables>;
export const CreateConTaxEffectiveDateDocument = gql`
    mutation createConTaxEffectiveDate($input: ConTaxEffectiveDateInput!) {
  createConTaxEffectiveDate(input: $input) {
    ID
  }
}
    `;
export type CreateConTaxEffectiveDateMutationFn = ApolloReactCommon.MutationFunction<CreateConTaxEffectiveDateMutation, CreateConTaxEffectiveDateMutationVariables>;

/**
 * __useCreateConTaxEffectiveDateMutation__
 *
 * To run a mutation, you first call `useCreateConTaxEffectiveDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConTaxEffectiveDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConTaxEffectiveDateMutation, { data, loading, error }] = useCreateConTaxEffectiveDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateConTaxEffectiveDateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateConTaxEffectiveDateMutation, CreateConTaxEffectiveDateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateConTaxEffectiveDateMutation, CreateConTaxEffectiveDateMutationVariables>(CreateConTaxEffectiveDateDocument, baseOptions);
      }
export type CreateConTaxEffectiveDateMutationHookResult = ReturnType<typeof useCreateConTaxEffectiveDateMutation>;
export type CreateConTaxEffectiveDateMutationResult = ApolloReactCommon.MutationResult<CreateConTaxEffectiveDateMutation>;
export type CreateConTaxEffectiveDateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateConTaxEffectiveDateMutation, CreateConTaxEffectiveDateMutationVariables>;
export const GetConTaxSchemeDocument = gql`
    query getConTaxScheme($ID: String, $taxTypeID: String, $docDate: String, $taxClass: TaxClass, $orderByAsc: String, $commonStatus: CommonStatus) {
  getConTaxScheme(ID: $ID, taxTypeID: $taxTypeID, docDate: $docDate, taxClass: $taxClass, orderByAsc: $orderByAsc, commonStatus: $commonStatus) {
    createdTs
    ID
    taxTypeID
    taxType {
      ID
      code
    }
    code
    description
    taxCategory
    taxClass
    isClaimable
    commonStatus
    latestTax(docDate: $docDate) {
      taxRate
      taxDate
    }
    taxEffective {
      date
      taxRate
      createdTs
      createdBy
    }
  }
}
    `;

/**
 * __useGetConTaxSchemeQuery__
 *
 * To run a query within a React component, call `useGetConTaxSchemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConTaxSchemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConTaxSchemeQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      taxTypeID: // value for 'taxTypeID'
 *      docDate: // value for 'docDate'
 *      taxClass: // value for 'taxClass'
 *      orderByAsc: // value for 'orderByAsc'
 *      commonStatus: // value for 'commonStatus'
 *   },
 * });
 */
export function useGetConTaxSchemeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetConTaxSchemeQuery, GetConTaxSchemeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetConTaxSchemeQuery, GetConTaxSchemeQueryVariables>(GetConTaxSchemeDocument, baseOptions);
      }
export function useGetConTaxSchemeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetConTaxSchemeQuery, GetConTaxSchemeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetConTaxSchemeQuery, GetConTaxSchemeQueryVariables>(GetConTaxSchemeDocument, baseOptions);
        }
export type GetConTaxSchemeQueryHookResult = ReturnType<typeof useGetConTaxSchemeQuery>;
export type GetConTaxSchemeLazyQueryHookResult = ReturnType<typeof useGetConTaxSchemeLazyQuery>;
export type GetConTaxSchemeQueryResult = ApolloReactCommon.QueryResult<GetConTaxSchemeQuery, GetConTaxSchemeQueryVariables>;
export const GetConTaxSchemeWithPermDocument = gql`
    query getConTaxSchemeWithPerm($ID: String, $taxTypeID: String, $docDate: String, $taxClass: TaxClass, $commonStatus: CommonStatus) {
  getConTaxSchemeWithPerm(ID: $ID, taxTypeID: $taxTypeID, taxClass: $taxClass, commonStatus: $commonStatus) {
    ID
    code
    description
    taxCategory
    taxTypeID
    taxClass
    createdTs
    commonStatus
    isClaimable
    latestTax(docDate: $docDate) {
      taxRate
      taxDate
    }
    taxEffective {
      ID
      date
      taxRate
      createdTs
      createdBy
      modBy
    }
  }
}
    `;

/**
 * __useGetConTaxSchemeWithPermQuery__
 *
 * To run a query within a React component, call `useGetConTaxSchemeWithPermQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConTaxSchemeWithPermQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConTaxSchemeWithPermQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      taxTypeID: // value for 'taxTypeID'
 *      docDate: // value for 'docDate'
 *      taxClass: // value for 'taxClass'
 *      commonStatus: // value for 'commonStatus'
 *   },
 * });
 */
export function useGetConTaxSchemeWithPermQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetConTaxSchemeWithPermQuery, GetConTaxSchemeWithPermQueryVariables>) {
        return ApolloReactHooks.useQuery<GetConTaxSchemeWithPermQuery, GetConTaxSchemeWithPermQueryVariables>(GetConTaxSchemeWithPermDocument, baseOptions);
      }
export function useGetConTaxSchemeWithPermLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetConTaxSchemeWithPermQuery, GetConTaxSchemeWithPermQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetConTaxSchemeWithPermQuery, GetConTaxSchemeWithPermQueryVariables>(GetConTaxSchemeWithPermDocument, baseOptions);
        }
export type GetConTaxSchemeWithPermQueryHookResult = ReturnType<typeof useGetConTaxSchemeWithPermQuery>;
export type GetConTaxSchemeWithPermLazyQueryHookResult = ReturnType<typeof useGetConTaxSchemeWithPermLazyQuery>;
export type GetConTaxSchemeWithPermQueryResult = ApolloReactCommon.QueryResult<GetConTaxSchemeWithPermQuery, GetConTaxSchemeWithPermQueryVariables>;
export const GetConTaxSchemeCodeDocument = gql`
    query getConTaxSchemeCode($ID: String, $taxTypeID: String, $commonStatus: CommonStatus) {
  getConTaxScheme(ID: $ID, taxTypeID: $taxTypeID, commonStatus: $commonStatus) {
    ID
    code
  }
}
    `;

/**
 * __useGetConTaxSchemeCodeQuery__
 *
 * To run a query within a React component, call `useGetConTaxSchemeCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConTaxSchemeCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConTaxSchemeCodeQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      taxTypeID: // value for 'taxTypeID'
 *      commonStatus: // value for 'commonStatus'
 *   },
 * });
 */
export function useGetConTaxSchemeCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetConTaxSchemeCodeQuery, GetConTaxSchemeCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetConTaxSchemeCodeQuery, GetConTaxSchemeCodeQueryVariables>(GetConTaxSchemeCodeDocument, baseOptions);
      }
export function useGetConTaxSchemeCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetConTaxSchemeCodeQuery, GetConTaxSchemeCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetConTaxSchemeCodeQuery, GetConTaxSchemeCodeQueryVariables>(GetConTaxSchemeCodeDocument, baseOptions);
        }
export type GetConTaxSchemeCodeQueryHookResult = ReturnType<typeof useGetConTaxSchemeCodeQuery>;
export type GetConTaxSchemeCodeLazyQueryHookResult = ReturnType<typeof useGetConTaxSchemeCodeLazyQuery>;
export type GetConTaxSchemeCodeQueryResult = ApolloReactCommon.QueryResult<GetConTaxSchemeCodeQuery, GetConTaxSchemeCodeQueryVariables>;
export const GetConTaxEffectiveDateDocument = gql`
    query getConTaxEffectiveDate($ID: String, $taxSchemeID: String, $orderByAsc: String, $orderByDesc: String) {
  getConTaxEffectiveDate(ID: $ID, taxSchemeID: $taxSchemeID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    ID
    taxSchemeID
    date
    taxRate
  }
}
    `;

/**
 * __useGetConTaxEffectiveDateQuery__
 *
 * To run a query within a React component, call `useGetConTaxEffectiveDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConTaxEffectiveDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConTaxEffectiveDateQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      taxSchemeID: // value for 'taxSchemeID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetConTaxEffectiveDateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetConTaxEffectiveDateQuery, GetConTaxEffectiveDateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetConTaxEffectiveDateQuery, GetConTaxEffectiveDateQueryVariables>(GetConTaxEffectiveDateDocument, baseOptions);
      }
export function useGetConTaxEffectiveDateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetConTaxEffectiveDateQuery, GetConTaxEffectiveDateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetConTaxEffectiveDateQuery, GetConTaxEffectiveDateQueryVariables>(GetConTaxEffectiveDateDocument, baseOptions);
        }
export type GetConTaxEffectiveDateQueryHookResult = ReturnType<typeof useGetConTaxEffectiveDateQuery>;
export type GetConTaxEffectiveDateLazyQueryHookResult = ReturnType<typeof useGetConTaxEffectiveDateLazyQuery>;
export type GetConTaxEffectiveDateQueryResult = ApolloReactCommon.QueryResult<GetConTaxEffectiveDateQuery, GetConTaxEffectiveDateQueryVariables>;
export const DeleteTaxSchemeDocument = gql`
    mutation deleteTaxScheme($ID: String!) {
  deleteTaxScheme(ID: $ID)
}
    `;
export type DeleteTaxSchemeMutationFn = ApolloReactCommon.MutationFunction<DeleteTaxSchemeMutation, DeleteTaxSchemeMutationVariables>;

/**
 * __useDeleteTaxSchemeMutation__
 *
 * To run a mutation, you first call `useDeleteTaxSchemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaxSchemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaxSchemeMutation, { data, loading, error }] = useDeleteTaxSchemeMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteTaxSchemeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTaxSchemeMutation, DeleteTaxSchemeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteTaxSchemeMutation, DeleteTaxSchemeMutationVariables>(DeleteTaxSchemeDocument, baseOptions);
      }
export type DeleteTaxSchemeMutationHookResult = ReturnType<typeof useDeleteTaxSchemeMutation>;
export type DeleteTaxSchemeMutationResult = ApolloReactCommon.MutationResult<DeleteTaxSchemeMutation>;
export type DeleteTaxSchemeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTaxSchemeMutation, DeleteTaxSchemeMutationVariables>;
export const InsertConTaxDocument = gql`
    mutation insertConTax($TaxEffectiveInput: [TaxEffectiveInput!]!, $ConTaxSchemeInput: ConTaxSchemeInput!) {
  insertConTax(taxEffectiveInput: $TaxEffectiveInput, conTaxSchemeInput: $ConTaxSchemeInput) {
    ID
  }
}
    `;
export type InsertConTaxMutationFn = ApolloReactCommon.MutationFunction<InsertConTaxMutation, InsertConTaxMutationVariables>;

/**
 * __useInsertConTaxMutation__
 *
 * To run a mutation, you first call `useInsertConTaxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertConTaxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertConTaxMutation, { data, loading, error }] = useInsertConTaxMutation({
 *   variables: {
 *      TaxEffectiveInput: // value for 'TaxEffectiveInput'
 *      ConTaxSchemeInput: // value for 'ConTaxSchemeInput'
 *   },
 * });
 */
export function useInsertConTaxMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertConTaxMutation, InsertConTaxMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertConTaxMutation, InsertConTaxMutationVariables>(InsertConTaxDocument, baseOptions);
      }
export type InsertConTaxMutationHookResult = ReturnType<typeof useInsertConTaxMutation>;
export type InsertConTaxMutationResult = ApolloReactCommon.MutationResult<InsertConTaxMutation>;
export type InsertConTaxMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertConTaxMutation, InsertConTaxMutationVariables>;
export const UpdateConTaxSchemeWithEffectiveDateDocument = gql`
    mutation updateConTaxSchemeWithEffectiveDate($TaxEffectiveInput: [TaxEffectiveInput!]!, $ConTaxSchemeInput: ConTaxSchemeInput!) {
  updateConTax(taxEffectiveInput: $TaxEffectiveInput, conTaxSchemeInput: $ConTaxSchemeInput)
}
    `;
export type UpdateConTaxSchemeWithEffectiveDateMutationFn = ApolloReactCommon.MutationFunction<UpdateConTaxSchemeWithEffectiveDateMutation, UpdateConTaxSchemeWithEffectiveDateMutationVariables>;

/**
 * __useUpdateConTaxSchemeWithEffectiveDateMutation__
 *
 * To run a mutation, you first call `useUpdateConTaxSchemeWithEffectiveDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConTaxSchemeWithEffectiveDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConTaxSchemeWithEffectiveDateMutation, { data, loading, error }] = useUpdateConTaxSchemeWithEffectiveDateMutation({
 *   variables: {
 *      TaxEffectiveInput: // value for 'TaxEffectiveInput'
 *      ConTaxSchemeInput: // value for 'ConTaxSchemeInput'
 *   },
 * });
 */
export function useUpdateConTaxSchemeWithEffectiveDateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateConTaxSchemeWithEffectiveDateMutation, UpdateConTaxSchemeWithEffectiveDateMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateConTaxSchemeWithEffectiveDateMutation, UpdateConTaxSchemeWithEffectiveDateMutationVariables>(UpdateConTaxSchemeWithEffectiveDateDocument, baseOptions);
      }
export type UpdateConTaxSchemeWithEffectiveDateMutationHookResult = ReturnType<typeof useUpdateConTaxSchemeWithEffectiveDateMutation>;
export type UpdateConTaxSchemeWithEffectiveDateMutationResult = ApolloReactCommon.MutationResult<UpdateConTaxSchemeWithEffectiveDateMutation>;
export type UpdateConTaxSchemeWithEffectiveDateMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateConTaxSchemeWithEffectiveDateMutation, UpdateConTaxSchemeWithEffectiveDateMutationVariables>;
export const ActivateConTaxSchemeDocument = gql`
    mutation activateConTaxScheme($input: ConTaxSchemeInput!) {
  activateConTaxScheme(input: $input)
}
    `;
export type ActivateConTaxSchemeMutationFn = ApolloReactCommon.MutationFunction<ActivateConTaxSchemeMutation, ActivateConTaxSchemeMutationVariables>;

/**
 * __useActivateConTaxSchemeMutation__
 *
 * To run a mutation, you first call `useActivateConTaxSchemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateConTaxSchemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateConTaxSchemeMutation, { data, loading, error }] = useActivateConTaxSchemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateConTaxSchemeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateConTaxSchemeMutation, ActivateConTaxSchemeMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateConTaxSchemeMutation, ActivateConTaxSchemeMutationVariables>(ActivateConTaxSchemeDocument, baseOptions);
      }
export type ActivateConTaxSchemeMutationHookResult = ReturnType<typeof useActivateConTaxSchemeMutation>;
export type ActivateConTaxSchemeMutationResult = ApolloReactCommon.MutationResult<ActivateConTaxSchemeMutation>;
export type ActivateConTaxSchemeMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateConTaxSchemeMutation, ActivateConTaxSchemeMutationVariables>;
export const GetFilterTaxCodeDocument = gql`
    query getFilterTaxCode($contractID: String) {
  getFilterTaxCode(contractID: $contractID)
}
    `;

/**
 * __useGetFilterTaxCodeQuery__
 *
 * To run a query within a React component, call `useGetFilterTaxCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilterTaxCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilterTaxCodeQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetFilterTaxCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFilterTaxCodeQuery, GetFilterTaxCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFilterTaxCodeQuery, GetFilterTaxCodeQueryVariables>(GetFilterTaxCodeDocument, baseOptions);
      }
export function useGetFilterTaxCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFilterTaxCodeQuery, GetFilterTaxCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFilterTaxCodeQuery, GetFilterTaxCodeQueryVariables>(GetFilterTaxCodeDocument, baseOptions);
        }
export type GetFilterTaxCodeQueryHookResult = ReturnType<typeof useGetFilterTaxCodeQuery>;
export type GetFilterTaxCodeLazyQueryHookResult = ReturnType<typeof useGetFilterTaxCodeLazyQuery>;
export type GetFilterTaxCodeQueryResult = ApolloReactCommon.QueryResult<GetFilterTaxCodeQuery, GetFilterTaxCodeQueryVariables>;
export const GetTaxSchemeTypeListingDocument = gql`
    query getTaxSchemeTypeListing {
  getTaxSchemeTypeListing {
    id
    name
  }
}
    `;

/**
 * __useGetTaxSchemeTypeListingQuery__
 *
 * To run a query within a React component, call `useGetTaxSchemeTypeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxSchemeTypeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxSchemeTypeListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTaxSchemeTypeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaxSchemeTypeListingQuery, GetTaxSchemeTypeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaxSchemeTypeListingQuery, GetTaxSchemeTypeListingQueryVariables>(GetTaxSchemeTypeListingDocument, baseOptions);
      }
export function useGetTaxSchemeTypeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaxSchemeTypeListingQuery, GetTaxSchemeTypeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaxSchemeTypeListingQuery, GetTaxSchemeTypeListingQueryVariables>(GetTaxSchemeTypeListingDocument, baseOptions);
        }
export type GetTaxSchemeTypeListingQueryHookResult = ReturnType<typeof useGetTaxSchemeTypeListingQuery>;
export type GetTaxSchemeTypeListingLazyQueryHookResult = ReturnType<typeof useGetTaxSchemeTypeListingLazyQuery>;
export type GetTaxSchemeTypeListingQueryResult = ApolloReactCommon.QueryResult<GetTaxSchemeTypeListingQuery, GetTaxSchemeTypeListingQueryVariables>;
export const GetTaxSchemeTypeSortByDocument = gql`
    query getTaxSchemeTypeSortBy {
  getTaxSchemeTypeSortBy {
    id
    name
    code
    count
  }
}
    `;

/**
 * __useGetTaxSchemeTypeSortByQuery__
 *
 * To run a query within a React component, call `useGetTaxSchemeTypeSortByQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxSchemeTypeSortByQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxSchemeTypeSortByQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTaxSchemeTypeSortByQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaxSchemeTypeSortByQuery, GetTaxSchemeTypeSortByQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaxSchemeTypeSortByQuery, GetTaxSchemeTypeSortByQueryVariables>(GetTaxSchemeTypeSortByDocument, baseOptions);
      }
export function useGetTaxSchemeTypeSortByLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaxSchemeTypeSortByQuery, GetTaxSchemeTypeSortByQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaxSchemeTypeSortByQuery, GetTaxSchemeTypeSortByQueryVariables>(GetTaxSchemeTypeSortByDocument, baseOptions);
        }
export type GetTaxSchemeTypeSortByQueryHookResult = ReturnType<typeof useGetTaxSchemeTypeSortByQuery>;
export type GetTaxSchemeTypeSortByLazyQueryHookResult = ReturnType<typeof useGetTaxSchemeTypeSortByLazyQuery>;
export type GetTaxSchemeTypeSortByQueryResult = ApolloReactCommon.QueryResult<GetTaxSchemeTypeSortByQuery, GetTaxSchemeTypeSortByQueryVariables>;
export const GetTaxListingDocument = gql`
    query getTaxListing {
  getTaxListing {
    tax_id
    code
    description
    class_type
    tax_status
    effective_date
    rate
    scheme_type_id
    createdTs
  }
}
    `;

/**
 * __useGetTaxListingQuery__
 *
 * To run a query within a React component, call `useGetTaxListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTaxListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaxListingQuery, GetTaxListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaxListingQuery, GetTaxListingQueryVariables>(GetTaxListingDocument, baseOptions);
      }
export function useGetTaxListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaxListingQuery, GetTaxListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaxListingQuery, GetTaxListingQueryVariables>(GetTaxListingDocument, baseOptions);
        }
export type GetTaxListingQueryHookResult = ReturnType<typeof useGetTaxListingQuery>;
export type GetTaxListingLazyQueryHookResult = ReturnType<typeof useGetTaxListingLazyQuery>;
export type GetTaxListingQueryResult = ApolloReactCommon.QueryResult<GetTaxListingQuery, GetTaxListingQueryVariables>;
export const GetTaxDocument = gql`
    query getTax($taxID: String!) {
  getTax(taxID: $taxID) {
    id
    accountId
    schemeTypeId
    code
    description
    classType
    isClaimable
    commonStatus
    taxDetail {
      id
      accountId
      taxId
      effectiveDate
      rate
      commonStatus
    }
  }
}
    `;

/**
 * __useGetTaxQuery__
 *
 * To run a query within a React component, call `useGetTaxQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxQuery({
 *   variables: {
 *      taxID: // value for 'taxID'
 *   },
 * });
 */
export function useGetTaxQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaxQuery, GetTaxQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaxQuery, GetTaxQueryVariables>(GetTaxDocument, baseOptions);
      }
export function useGetTaxLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaxQuery, GetTaxQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaxQuery, GetTaxQueryVariables>(GetTaxDocument, baseOptions);
        }
export type GetTaxQueryHookResult = ReturnType<typeof useGetTaxQuery>;
export type GetTaxLazyQueryHookResult = ReturnType<typeof useGetTaxLazyQuery>;
export type GetTaxQueryResult = ApolloReactCommon.QueryResult<GetTaxQuery, GetTaxQueryVariables>;
export const CreateTaxMasterFileDocument = gql`
    mutation createTaxMasterFile($input: TaxInput!) {
  createTaxMasterFile(input: $input)
}
    `;
export type CreateTaxMasterFileMutationFn = ApolloReactCommon.MutationFunction<CreateTaxMasterFileMutation, CreateTaxMasterFileMutationVariables>;

/**
 * __useCreateTaxMasterFileMutation__
 *
 * To run a mutation, you first call `useCreateTaxMasterFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaxMasterFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaxMasterFileMutation, { data, loading, error }] = useCreateTaxMasterFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTaxMasterFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTaxMasterFileMutation, CreateTaxMasterFileMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTaxMasterFileMutation, CreateTaxMasterFileMutationVariables>(CreateTaxMasterFileDocument, baseOptions);
      }
export type CreateTaxMasterFileMutationHookResult = ReturnType<typeof useCreateTaxMasterFileMutation>;
export type CreateTaxMasterFileMutationResult = ApolloReactCommon.MutationResult<CreateTaxMasterFileMutation>;
export type CreateTaxMasterFileMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTaxMasterFileMutation, CreateTaxMasterFileMutationVariables>;
export const EditTaxMasterFileDocument = gql`
    mutation editTaxMasterFile($input: EditTaxInput!) {
  editTaxMasterFile(input: $input)
}
    `;
export type EditTaxMasterFileMutationFn = ApolloReactCommon.MutationFunction<EditTaxMasterFileMutation, EditTaxMasterFileMutationVariables>;

/**
 * __useEditTaxMasterFileMutation__
 *
 * To run a mutation, you first call `useEditTaxMasterFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTaxMasterFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTaxMasterFileMutation, { data, loading, error }] = useEditTaxMasterFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTaxMasterFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditTaxMasterFileMutation, EditTaxMasterFileMutationVariables>) {
        return ApolloReactHooks.useMutation<EditTaxMasterFileMutation, EditTaxMasterFileMutationVariables>(EditTaxMasterFileDocument, baseOptions);
      }
export type EditTaxMasterFileMutationHookResult = ReturnType<typeof useEditTaxMasterFileMutation>;
export type EditTaxMasterFileMutationResult = ApolloReactCommon.MutationResult<EditTaxMasterFileMutation>;
export type EditTaxMasterFileMutationOptions = ApolloReactCommon.BaseMutationOptions<EditTaxMasterFileMutation, EditTaxMasterFileMutationVariables>;
export const DeleteTaxMasterFileDocument = gql`
    mutation deleteTaxMasterFile($deleteInput: [DeleteTaxInput!]!, $taxId: String!) {
  deleteTaxMasterFile(deleteInput: $deleteInput, taxId: $taxId)
}
    `;
export type DeleteTaxMasterFileMutationFn = ApolloReactCommon.MutationFunction<DeleteTaxMasterFileMutation, DeleteTaxMasterFileMutationVariables>;

/**
 * __useDeleteTaxMasterFileMutation__
 *
 * To run a mutation, you first call `useDeleteTaxMasterFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaxMasterFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaxMasterFileMutation, { data, loading, error }] = useDeleteTaxMasterFileMutation({
 *   variables: {
 *      deleteInput: // value for 'deleteInput'
 *      taxId: // value for 'taxId'
 *   },
 * });
 */
export function useDeleteTaxMasterFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTaxMasterFileMutation, DeleteTaxMasterFileMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteTaxMasterFileMutation, DeleteTaxMasterFileMutationVariables>(DeleteTaxMasterFileDocument, baseOptions);
      }
export type DeleteTaxMasterFileMutationHookResult = ReturnType<typeof useDeleteTaxMasterFileMutation>;
export type DeleteTaxMasterFileMutationResult = ApolloReactCommon.MutationResult<DeleteTaxMasterFileMutation>;
export type DeleteTaxMasterFileMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTaxMasterFileMutation, DeleteTaxMasterFileMutationVariables>;
export const ActivateTaxMasterFileDocument = gql`
    mutation activateTaxMasterFile($taxId: String!) {
  activateTaxMasterFile(taxId: $taxId)
}
    `;
export type ActivateTaxMasterFileMutationFn = ApolloReactCommon.MutationFunction<ActivateTaxMasterFileMutation, ActivateTaxMasterFileMutationVariables>;

/**
 * __useActivateTaxMasterFileMutation__
 *
 * To run a mutation, you first call `useActivateTaxMasterFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateTaxMasterFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateTaxMasterFileMutation, { data, loading, error }] = useActivateTaxMasterFileMutation({
 *   variables: {
 *      taxId: // value for 'taxId'
 *   },
 * });
 */
export function useActivateTaxMasterFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateTaxMasterFileMutation, ActivateTaxMasterFileMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateTaxMasterFileMutation, ActivateTaxMasterFileMutationVariables>(ActivateTaxMasterFileDocument, baseOptions);
      }
export type ActivateTaxMasterFileMutationHookResult = ReturnType<typeof useActivateTaxMasterFileMutation>;
export type ActivateTaxMasterFileMutationResult = ApolloReactCommon.MutationResult<ActivateTaxMasterFileMutation>;
export type ActivateTaxMasterFileMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateTaxMasterFileMutation, ActivateTaxMasterFileMutationVariables>;
export const DeactivateTaxMasterFileDocument = gql`
    mutation deactivateTaxMasterFile($taxId: String!) {
  deactivateTaxMasterFile(taxId: $taxId)
}
    `;
export type DeactivateTaxMasterFileMutationFn = ApolloReactCommon.MutationFunction<DeactivateTaxMasterFileMutation, DeactivateTaxMasterFileMutationVariables>;

/**
 * __useDeactivateTaxMasterFileMutation__
 *
 * To run a mutation, you first call `useDeactivateTaxMasterFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateTaxMasterFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateTaxMasterFileMutation, { data, loading, error }] = useDeactivateTaxMasterFileMutation({
 *   variables: {
 *      taxId: // value for 'taxId'
 *   },
 * });
 */
export function useDeactivateTaxMasterFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeactivateTaxMasterFileMutation, DeactivateTaxMasterFileMutationVariables>) {
        return ApolloReactHooks.useMutation<DeactivateTaxMasterFileMutation, DeactivateTaxMasterFileMutationVariables>(DeactivateTaxMasterFileDocument, baseOptions);
      }
export type DeactivateTaxMasterFileMutationHookResult = ReturnType<typeof useDeactivateTaxMasterFileMutation>;
export type DeactivateTaxMasterFileMutationResult = ApolloReactCommon.MutationResult<DeactivateTaxMasterFileMutation>;
export type DeactivateTaxMasterFileMutationOptions = ApolloReactCommon.BaseMutationOptions<DeactivateTaxMasterFileMutation, DeactivateTaxMasterFileMutationVariables>;
export const GetUomDocument = gql`
    query getUOM($ID: String, $name: String, $code: String, $orderByAsc: String, $orderByDesc: String, $commonStatus: CommonStatus) {
  getUOM(ID: $ID, name: $name, code: $code, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, commonStatus: $commonStatus) {
    ID
    name
    code
    commonStatus
  }
  uomDate
}
    `;

/**
 * __useGetUomQuery__
 *
 * To run a query within a React component, call `useGetUomQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUomQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      name: // value for 'name'
 *      code: // value for 'code'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      commonStatus: // value for 'commonStatus'
 *   },
 * });
 */
export function useGetUomQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUomQuery, GetUomQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUomQuery, GetUomQueryVariables>(GetUomDocument, baseOptions);
      }
export function useGetUomLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUomQuery, GetUomQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUomQuery, GetUomQueryVariables>(GetUomDocument, baseOptions);
        }
export type GetUomQueryHookResult = ReturnType<typeof useGetUomQuery>;
export type GetUomLazyQueryHookResult = ReturnType<typeof useGetUomLazyQuery>;
export type GetUomQueryResult = ApolloReactCommon.QueryResult<GetUomQuery, GetUomQueryVariables>;
export const GetUomListingDocument = gql`
    query getUOMListing($ID: String, $commonStatus: CommonStatus, $orderByAsc: String, $take: Float, $skip: Float, $searchValue: String) {
  getUOM(ID: $ID, orderByAsc: $orderByAsc, commonStatus: $commonStatus, take: $take, skip: $skip, searchValue: $searchValue) {
    ID
    accountID
    name
    code
    commonStatus
  }
  uomDate
  getUOMCount(ID: $ID, commonStatus: $commonStatus, searchValue: $searchValue)
}
    `;

/**
 * __useGetUomListingQuery__
 *
 * To run a query within a React component, call `useGetUomListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUomListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUomListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      commonStatus: // value for 'commonStatus'
 *      orderByAsc: // value for 'orderByAsc'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetUomListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUomListingQuery, GetUomListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUomListingQuery, GetUomListingQueryVariables>(GetUomListingDocument, baseOptions);
      }
export function useGetUomListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUomListingQuery, GetUomListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUomListingQuery, GetUomListingQueryVariables>(GetUomListingDocument, baseOptions);
        }
export type GetUomListingQueryHookResult = ReturnType<typeof useGetUomListingQuery>;
export type GetUomListingLazyQueryHookResult = ReturnType<typeof useGetUomListingLazyQuery>;
export type GetUomListingQueryResult = ApolloReactCommon.QueryResult<GetUomListingQuery, GetUomListingQueryVariables>;
export const GetUomWithPermListingDocument = gql`
    query getUOMWithPermListing($ID: String, $accountID: String, $commonStatus: CommonStatus, $orderByAsc: String, $searchValue: String, $take: Float, $skip: Float) {
  getUOMWithPerm(ID: $ID, accountID: $accountID, orderByAsc: $orderByAsc, commonStatus: $commonStatus, searchValue: $searchValue, take: $take, skip: $skip) {
    ID
    code
    name
    commonStatus
  }
  getUOMWithPermCount(accountID: $accountID, commonStatus: $commonStatus)
}
    `;

/**
 * __useGetUomWithPermListingQuery__
 *
 * To run a query within a React component, call `useGetUomWithPermListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUomWithPermListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUomWithPermListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      accountID: // value for 'accountID'
 *      commonStatus: // value for 'commonStatus'
 *      orderByAsc: // value for 'orderByAsc'
 *      searchValue: // value for 'searchValue'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetUomWithPermListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUomWithPermListingQuery, GetUomWithPermListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUomWithPermListingQuery, GetUomWithPermListingQueryVariables>(GetUomWithPermListingDocument, baseOptions);
      }
export function useGetUomWithPermListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUomWithPermListingQuery, GetUomWithPermListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUomWithPermListingQuery, GetUomWithPermListingQueryVariables>(GetUomWithPermListingDocument, baseOptions);
        }
export type GetUomWithPermListingQueryHookResult = ReturnType<typeof useGetUomWithPermListingQuery>;
export type GetUomWithPermListingLazyQueryHookResult = ReturnType<typeof useGetUomWithPermListingLazyQuery>;
export type GetUomWithPermListingQueryResult = ApolloReactCommon.QueryResult<GetUomWithPermListingQuery, GetUomWithPermListingQueryVariables>;
export const CreateUomDocument = gql`
    mutation createUOM($input: UOMInput!) {
  createUOM(input: $input) {
    name
    code
  }
}
    `;
export type CreateUomMutationFn = ApolloReactCommon.MutationFunction<CreateUomMutation, CreateUomMutationVariables>;

/**
 * __useCreateUomMutation__
 *
 * To run a mutation, you first call `useCreateUomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUomMutation, { data, loading, error }] = useCreateUomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUomMutation, CreateUomMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUomMutation, CreateUomMutationVariables>(CreateUomDocument, baseOptions);
      }
export type CreateUomMutationHookResult = ReturnType<typeof useCreateUomMutation>;
export type CreateUomMutationResult = ApolloReactCommon.MutationResult<CreateUomMutation>;
export type CreateUomMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUomMutation, CreateUomMutationVariables>;
export const CreateBatchUomDocument = gql`
    mutation createBatchUOM($input: [UOMInput!]!) {
  createBatchUOM(input: $input)
}
    `;
export type CreateBatchUomMutationFn = ApolloReactCommon.MutationFunction<CreateBatchUomMutation, CreateBatchUomMutationVariables>;

/**
 * __useCreateBatchUomMutation__
 *
 * To run a mutation, you first call `useCreateBatchUomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBatchUomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBatchUomMutation, { data, loading, error }] = useCreateBatchUomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBatchUomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBatchUomMutation, CreateBatchUomMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBatchUomMutation, CreateBatchUomMutationVariables>(CreateBatchUomDocument, baseOptions);
      }
export type CreateBatchUomMutationHookResult = ReturnType<typeof useCreateBatchUomMutation>;
export type CreateBatchUomMutationResult = ApolloReactCommon.MutationResult<CreateBatchUomMutation>;
export type CreateBatchUomMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBatchUomMutation, CreateBatchUomMutationVariables>;
export const UpdateUomDocument = gql`
    mutation updateUOM($input: UOMInput!) {
  updateUOM(input: $input) {
    ID
    name
    code
  }
}
    `;
export type UpdateUomMutationFn = ApolloReactCommon.MutationFunction<UpdateUomMutation, UpdateUomMutationVariables>;

/**
 * __useUpdateUomMutation__
 *
 * To run a mutation, you first call `useUpdateUomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUomMutation, { data, loading, error }] = useUpdateUomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUomMutation, UpdateUomMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUomMutation, UpdateUomMutationVariables>(UpdateUomDocument, baseOptions);
      }
export type UpdateUomMutationHookResult = ReturnType<typeof useUpdateUomMutation>;
export type UpdateUomMutationResult = ApolloReactCommon.MutationResult<UpdateUomMutation>;
export type UpdateUomMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUomMutation, UpdateUomMutationVariables>;
export const DeleteUomDocument = gql`
    mutation deleteUOM($ID: UOMDeleteInput!) {
  deleteUOM(input: $ID)
}
    `;
export type DeleteUomMutationFn = ApolloReactCommon.MutationFunction<DeleteUomMutation, DeleteUomMutationVariables>;

/**
 * __useDeleteUomMutation__
 *
 * To run a mutation, you first call `useDeleteUomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUomMutation, { data, loading, error }] = useDeleteUomMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteUomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUomMutation, DeleteUomMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUomMutation, DeleteUomMutationVariables>(DeleteUomDocument, baseOptions);
      }
export type DeleteUomMutationHookResult = ReturnType<typeof useDeleteUomMutation>;
export type DeleteUomMutationResult = ApolloReactCommon.MutationResult<DeleteUomMutation>;
export type DeleteUomMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUomMutation, DeleteUomMutationVariables>;
export const CancelUomDocument = gql`
    mutation cancelUOM($input: UOMDeleteInput!) {
  cancelUOM(input: $input)
}
    `;
export type CancelUomMutationFn = ApolloReactCommon.MutationFunction<CancelUomMutation, CancelUomMutationVariables>;

/**
 * __useCancelUomMutation__
 *
 * To run a mutation, you first call `useCancelUomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelUomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelUomMutation, { data, loading, error }] = useCancelUomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelUomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelUomMutation, CancelUomMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelUomMutation, CancelUomMutationVariables>(CancelUomDocument, baseOptions);
      }
export type CancelUomMutationHookResult = ReturnType<typeof useCancelUomMutation>;
export type CancelUomMutationResult = ApolloReactCommon.MutationResult<CancelUomMutation>;
export type CancelUomMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelUomMutation, CancelUomMutationVariables>;
export const ActivateUomDocument = gql`
    mutation activateUOM($input: UOMDeleteInput!) {
  activateUOM(input: $input)
}
    `;
export type ActivateUomMutationFn = ApolloReactCommon.MutationFunction<ActivateUomMutation, ActivateUomMutationVariables>;

/**
 * __useActivateUomMutation__
 *
 * To run a mutation, you first call `useActivateUomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUomMutation, { data, loading, error }] = useActivateUomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateUomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateUomMutation, ActivateUomMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateUomMutation, ActivateUomMutationVariables>(ActivateUomDocument, baseOptions);
      }
export type ActivateUomMutationHookResult = ReturnType<typeof useActivateUomMutation>;
export type ActivateUomMutationResult = ApolloReactCommon.MutationResult<ActivateUomMutation>;
export type ActivateUomMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateUomMutation, ActivateUomMutationVariables>;
export const UomImportDocument = gql`
    mutation uomImport($batchID: String!) {
  uomImport(batchID: $batchID)
}
    `;
export type UomImportMutationFn = ApolloReactCommon.MutationFunction<UomImportMutation, UomImportMutationVariables>;

/**
 * __useUomImportMutation__
 *
 * To run a mutation, you first call `useUomImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUomImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uomImportMutation, { data, loading, error }] = useUomImportMutation({
 *   variables: {
 *      batchID: // value for 'batchID'
 *   },
 * });
 */
export function useUomImportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UomImportMutation, UomImportMutationVariables>) {
        return ApolloReactHooks.useMutation<UomImportMutation, UomImportMutationVariables>(UomImportDocument, baseOptions);
      }
export type UomImportMutationHookResult = ReturnType<typeof useUomImportMutation>;
export type UomImportMutationResult = ApolloReactCommon.MutationResult<UomImportMutation>;
export type UomImportMutationOptions = ApolloReactCommon.BaseMutationOptions<UomImportMutation, UomImportMutationVariables>;
export const UomImportValidationDocument = gql`
    mutation uomImportValidation($input: [UOMImportInput!]!) {
  uomImportValidation(input: $input)
}
    `;
export type UomImportValidationMutationFn = ApolloReactCommon.MutationFunction<UomImportValidationMutation, UomImportValidationMutationVariables>;

/**
 * __useUomImportValidationMutation__
 *
 * To run a mutation, you first call `useUomImportValidationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUomImportValidationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uomImportValidationMutation, { data, loading, error }] = useUomImportValidationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUomImportValidationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UomImportValidationMutation, UomImportValidationMutationVariables>) {
        return ApolloReactHooks.useMutation<UomImportValidationMutation, UomImportValidationMutationVariables>(UomImportValidationDocument, baseOptions);
      }
export type UomImportValidationMutationHookResult = ReturnType<typeof useUomImportValidationMutation>;
export type UomImportValidationMutationResult = ApolloReactCommon.MutationResult<UomImportValidationMutation>;
export type UomImportValidationMutationOptions = ApolloReactCommon.BaseMutationOptions<UomImportValidationMutation, UomImportValidationMutationVariables>;
export const GetUomExchangeDocument = gql`
    query getUOMExchange($ID: String, $orderByAsc: String, $orderByDesc: String, $fromUomID: String, $toUomID: String) {
  getUOMExchange(ID: $ID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, fromUomID: $fromUomID, toUomID: $toUomID) {
    ID
    fromAmt
    toAmt
    exchangeRate
    fromUomID
    toUomID
    fromUOM {
      ID
      name
      code
    }
    toUOM {
      ID
      name
      code
    }
  }
}
    `;

/**
 * __useGetUomExchangeQuery__
 *
 * To run a query within a React component, call `useGetUomExchangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUomExchangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUomExchangeQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      fromUomID: // value for 'fromUomID'
 *      toUomID: // value for 'toUomID'
 *   },
 * });
 */
export function useGetUomExchangeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUomExchangeQuery, GetUomExchangeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUomExchangeQuery, GetUomExchangeQueryVariables>(GetUomExchangeDocument, baseOptions);
      }
export function useGetUomExchangeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUomExchangeQuery, GetUomExchangeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUomExchangeQuery, GetUomExchangeQueryVariables>(GetUomExchangeDocument, baseOptions);
        }
export type GetUomExchangeQueryHookResult = ReturnType<typeof useGetUomExchangeQuery>;
export type GetUomExchangeLazyQueryHookResult = ReturnType<typeof useGetUomExchangeLazyQuery>;
export type GetUomExchangeQueryResult = ApolloReactCommon.QueryResult<GetUomExchangeQuery, GetUomExchangeQueryVariables>;
export const CreateUomExchangeDocument = gql`
    mutation createUOMExchange($input: UomExchangeInput!) {
  createUOMExchange(input: $input)
}
    `;
export type CreateUomExchangeMutationFn = ApolloReactCommon.MutationFunction<CreateUomExchangeMutation, CreateUomExchangeMutationVariables>;

/**
 * __useCreateUomExchangeMutation__
 *
 * To run a mutation, you first call `useCreateUomExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUomExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUomExchangeMutation, { data, loading, error }] = useCreateUomExchangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUomExchangeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUomExchangeMutation, CreateUomExchangeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUomExchangeMutation, CreateUomExchangeMutationVariables>(CreateUomExchangeDocument, baseOptions);
      }
export type CreateUomExchangeMutationHookResult = ReturnType<typeof useCreateUomExchangeMutation>;
export type CreateUomExchangeMutationResult = ApolloReactCommon.MutationResult<CreateUomExchangeMutation>;
export type CreateUomExchangeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUomExchangeMutation, CreateUomExchangeMutationVariables>;
export const UpdateUomExchangeDocument = gql`
    mutation updateUOMExchange($input: UomExchangeInput!) {
  updateUOMExchange(input: $input)
}
    `;
export type UpdateUomExchangeMutationFn = ApolloReactCommon.MutationFunction<UpdateUomExchangeMutation, UpdateUomExchangeMutationVariables>;

/**
 * __useUpdateUomExchangeMutation__
 *
 * To run a mutation, you first call `useUpdateUomExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUomExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUomExchangeMutation, { data, loading, error }] = useUpdateUomExchangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUomExchangeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUomExchangeMutation, UpdateUomExchangeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUomExchangeMutation, UpdateUomExchangeMutationVariables>(UpdateUomExchangeDocument, baseOptions);
      }
export type UpdateUomExchangeMutationHookResult = ReturnType<typeof useUpdateUomExchangeMutation>;
export type UpdateUomExchangeMutationResult = ApolloReactCommon.MutationResult<UpdateUomExchangeMutation>;
export type UpdateUomExchangeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUomExchangeMutation, UpdateUomExchangeMutationVariables>;
export const DeleteUomExchangeDocument = gql`
    mutation deleteUOMExchange($ID: String!) {
  deleteUOMExchange(ID: $ID)
}
    `;
export type DeleteUomExchangeMutationFn = ApolloReactCommon.MutationFunction<DeleteUomExchangeMutation, DeleteUomExchangeMutationVariables>;

/**
 * __useDeleteUomExchangeMutation__
 *
 * To run a mutation, you first call `useDeleteUomExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUomExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUomExchangeMutation, { data, loading, error }] = useDeleteUomExchangeMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteUomExchangeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUomExchangeMutation, DeleteUomExchangeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUomExchangeMutation, DeleteUomExchangeMutationVariables>(DeleteUomExchangeDocument, baseOptions);
      }
export type DeleteUomExchangeMutationHookResult = ReturnType<typeof useDeleteUomExchangeMutation>;
export type DeleteUomExchangeMutationResult = ApolloReactCommon.MutationResult<DeleteUomExchangeMutation>;
export type DeleteUomExchangeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUomExchangeMutation, DeleteUomExchangeMutationVariables>;
export const GetUomExchangeDataDocument = gql`
    query getUomExchangeData {
  getUomExchangeData
}
    `;

/**
 * __useGetUomExchangeDataQuery__
 *
 * To run a query within a React component, call `useGetUomExchangeDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUomExchangeDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUomExchangeDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUomExchangeDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUomExchangeDataQuery, GetUomExchangeDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUomExchangeDataQuery, GetUomExchangeDataQueryVariables>(GetUomExchangeDataDocument, baseOptions);
      }
export function useGetUomExchangeDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUomExchangeDataQuery, GetUomExchangeDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUomExchangeDataQuery, GetUomExchangeDataQueryVariables>(GetUomExchangeDataDocument, baseOptions);
        }
export type GetUomExchangeDataQueryHookResult = ReturnType<typeof useGetUomExchangeDataQuery>;
export type GetUomExchangeDataLazyQueryHookResult = ReturnType<typeof useGetUomExchangeDataLazyQuery>;
export type GetUomExchangeDataQueryResult = ApolloReactCommon.QueryResult<GetUomExchangeDataQuery, GetUomExchangeDataQueryVariables>;
export const GetUomExchangeListByWbsDocument = gql`
    query getUOMExchangeListByWbs($uomIDs: [String!]!) {
  getUOMExchangeListByWbs(uomIDs: $uomIDs)
}
    `;

/**
 * __useGetUomExchangeListByWbsQuery__
 *
 * To run a query within a React component, call `useGetUomExchangeListByWbsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUomExchangeListByWbsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUomExchangeListByWbsQuery({
 *   variables: {
 *      uomIDs: // value for 'uomIDs'
 *   },
 * });
 */
export function useGetUomExchangeListByWbsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUomExchangeListByWbsQuery, GetUomExchangeListByWbsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUomExchangeListByWbsQuery, GetUomExchangeListByWbsQueryVariables>(GetUomExchangeListByWbsDocument, baseOptions);
      }
export function useGetUomExchangeListByWbsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUomExchangeListByWbsQuery, GetUomExchangeListByWbsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUomExchangeListByWbsQuery, GetUomExchangeListByWbsQueryVariables>(GetUomExchangeListByWbsDocument, baseOptions);
        }
export type GetUomExchangeListByWbsQueryHookResult = ReturnType<typeof useGetUomExchangeListByWbsQuery>;
export type GetUomExchangeListByWbsLazyQueryHookResult = ReturnType<typeof useGetUomExchangeListByWbsLazyQuery>;
export type GetUomExchangeListByWbsQueryResult = ApolloReactCommon.QueryResult<GetUomExchangeListByWbsQuery, GetUomExchangeListByWbsQueryVariables>;
export const GetUserDocument = gql`
    query getUser($ID: String!) {
  getUser(ID: $ID) {
    ID
    name
    department
    email
    jobTitle
    contactNo
    superUser
    commonStatus
    userName
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
      }
export function useGetUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = ApolloReactCommon.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUsersByAccountAndSoftwareDocument = gql`
    query getUsersByAccountAndSoftware($superUserBool: UserSelection, $status: CommonStatus) {
  getUsersByAccountAndSoftware(superUserBool: $superUserBool, status: $status) {
    ID
    name
    department
    email
    contactNo
    superUser
    commonStatus
    jobTitle
    userName
    confirmed
  }
}
    `;

/**
 * __useGetUsersByAccountAndSoftwareQuery__
 *
 * To run a query within a React component, call `useGetUsersByAccountAndSoftwareQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByAccountAndSoftwareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByAccountAndSoftwareQuery({
 *   variables: {
 *      superUserBool: // value for 'superUserBool'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetUsersByAccountAndSoftwareQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>(GetUsersByAccountAndSoftwareDocument, baseOptions);
      }
export function useGetUsersByAccountAndSoftwareLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>(GetUsersByAccountAndSoftwareDocument, baseOptions);
        }
export type GetUsersByAccountAndSoftwareQueryHookResult = ReturnType<typeof useGetUsersByAccountAndSoftwareQuery>;
export type GetUsersByAccountAndSoftwareLazyQueryHookResult = ReturnType<typeof useGetUsersByAccountAndSoftwareLazyQuery>;
export type GetUsersByAccountAndSoftwareQueryResult = ApolloReactCommon.QueryResult<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>;
export const GetUserEmailCheckerDocument = gql`
    query getUserEmailChecker($superUserBool: UserSelection, $status: CommonStatus) {
  getUsersByAccountAndSoftware(superUserBool: $superUserBool, status: $status) {
    ID
    email
    userName
    confirmed
  }
}
    `;

/**
 * __useGetUserEmailCheckerQuery__
 *
 * To run a query within a React component, call `useGetUserEmailCheckerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEmailCheckerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEmailCheckerQuery({
 *   variables: {
 *      superUserBool: // value for 'superUserBool'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetUserEmailCheckerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserEmailCheckerQuery, GetUserEmailCheckerQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserEmailCheckerQuery, GetUserEmailCheckerQueryVariables>(GetUserEmailCheckerDocument, baseOptions);
      }
export function useGetUserEmailCheckerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserEmailCheckerQuery, GetUserEmailCheckerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserEmailCheckerQuery, GetUserEmailCheckerQueryVariables>(GetUserEmailCheckerDocument, baseOptions);
        }
export type GetUserEmailCheckerQueryHookResult = ReturnType<typeof useGetUserEmailCheckerQuery>;
export type GetUserEmailCheckerLazyQueryHookResult = ReturnType<typeof useGetUserEmailCheckerLazyQuery>;
export type GetUserEmailCheckerQueryResult = ApolloReactCommon.QueryResult<GetUserEmailCheckerQuery, GetUserEmailCheckerQueryVariables>;
export const GetUserCompanyAssignmentDocument = gql`
    query getUserCompanyAssignment($userID: String) {
  getUserCompanyAssignment(userID: $userID) {
    ID
    companyID
    userID
  }
}
    `;

/**
 * __useGetUserCompanyAssignmentQuery__
 *
 * To run a query within a React component, call `useGetUserCompanyAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCompanyAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCompanyAssignmentQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useGetUserCompanyAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserCompanyAssignmentQuery, GetUserCompanyAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserCompanyAssignmentQuery, GetUserCompanyAssignmentQueryVariables>(GetUserCompanyAssignmentDocument, baseOptions);
      }
export function useGetUserCompanyAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserCompanyAssignmentQuery, GetUserCompanyAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserCompanyAssignmentQuery, GetUserCompanyAssignmentQueryVariables>(GetUserCompanyAssignmentDocument, baseOptions);
        }
export type GetUserCompanyAssignmentQueryHookResult = ReturnType<typeof useGetUserCompanyAssignmentQuery>;
export type GetUserCompanyAssignmentLazyQueryHookResult = ReturnType<typeof useGetUserCompanyAssignmentLazyQuery>;
export type GetUserCompanyAssignmentQueryResult = ApolloReactCommon.QueryResult<GetUserCompanyAssignmentQuery, GetUserCompanyAssignmentQueryVariables>;
export const CreateUserDocument = gql`
    mutation createUser($input: UserInput!) {
  createUser(input: $input) {
    ID
    email
  }
}
    `;
export type CreateUserMutationFn = ApolloReactCommon.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, baseOptions);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = ApolloReactCommon.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($input: UserInput!) {
  updateUser(input: $input) {
    ID
  }
}
    `;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UnblockUserDocument = gql`
    mutation unblockUser($input: passwordInfo!) {
  unblockUser(input: $input)
}
    `;
export type UnblockUserMutationFn = ApolloReactCommon.MutationFunction<UnblockUserMutation, UnblockUserMutationVariables>;

/**
 * __useUnblockUserMutation__
 *
 * To run a mutation, you first call `useUnblockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnblockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unblockUserMutation, { data, loading, error }] = useUnblockUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnblockUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnblockUserMutation, UnblockUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UnblockUserMutation, UnblockUserMutationVariables>(UnblockUserDocument, baseOptions);
      }
export type UnblockUserMutationHookResult = ReturnType<typeof useUnblockUserMutation>;
export type UnblockUserMutationResult = ApolloReactCommon.MutationResult<UnblockUserMutation>;
export type UnblockUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UnblockUserMutation, UnblockUserMutationVariables>;
export const ResendInvitationDocument = gql`
    mutation resendInvitation($ID: String!) {
  resendInvitation(ID: $ID) {
    ID
    name
    email
  }
}
    `;
export type ResendInvitationMutationFn = ApolloReactCommon.MutationFunction<ResendInvitationMutation, ResendInvitationMutationVariables>;

/**
 * __useResendInvitationMutation__
 *
 * To run a mutation, you first call `useResendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInvitationMutation, { data, loading, error }] = useResendInvitationMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useResendInvitationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResendInvitationMutation, ResendInvitationMutationVariables>) {
        return ApolloReactHooks.useMutation<ResendInvitationMutation, ResendInvitationMutationVariables>(ResendInvitationDocument, baseOptions);
      }
export type ResendInvitationMutationHookResult = ReturnType<typeof useResendInvitationMutation>;
export type ResendInvitationMutationResult = ApolloReactCommon.MutationResult<ResendInvitationMutation>;
export type ResendInvitationMutationOptions = ApolloReactCommon.BaseMutationOptions<ResendInvitationMutation, ResendInvitationMutationVariables>;
export const ChangePasswordSuDocument = gql`
    mutation changePasswordSU($input: passwordInfo!) {
  changePasswordSU(input: $input)
}
    `;
export type ChangePasswordSuMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordSuMutation, ChangePasswordSuMutationVariables>;

/**
 * __useChangePasswordSuMutation__
 *
 * To run a mutation, you first call `useChangePasswordSuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordSuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordSuMutation, { data, loading, error }] = useChangePasswordSuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordSuMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordSuMutation, ChangePasswordSuMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordSuMutation, ChangePasswordSuMutationVariables>(ChangePasswordSuDocument, baseOptions);
      }
export type ChangePasswordSuMutationHookResult = ReturnType<typeof useChangePasswordSuMutation>;
export type ChangePasswordSuMutationResult = ApolloReactCommon.MutationResult<ChangePasswordSuMutation>;
export type ChangePasswordSuMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordSuMutation, ChangePasswordSuMutationVariables>;
export const UpdateUserWithPasswordDocument = gql`
    mutation updateUserWithPassword($input: UserInput!) {
  updateUserWithPassword(input: $input) {
    ID
  }
}
    `;
export type UpdateUserWithPasswordMutationFn = ApolloReactCommon.MutationFunction<UpdateUserWithPasswordMutation, UpdateUserWithPasswordMutationVariables>;

/**
 * __useUpdateUserWithPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateUserWithPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserWithPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserWithPasswordMutation, { data, loading, error }] = useUpdateUserWithPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserWithPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserWithPasswordMutation, UpdateUserWithPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserWithPasswordMutation, UpdateUserWithPasswordMutationVariables>(UpdateUserWithPasswordDocument, baseOptions);
      }
export type UpdateUserWithPasswordMutationHookResult = ReturnType<typeof useUpdateUserWithPasswordMutation>;
export type UpdateUserWithPasswordMutationResult = ApolloReactCommon.MutationResult<UpdateUserWithPasswordMutation>;
export type UpdateUserWithPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserWithPasswordMutation, UpdateUserWithPasswordMutationVariables>;
export const AssignUserCompanyDocument = gql`
    mutation assignUserCompany($userID: String!, $companyIDs: [String!]!) {
  assignUserCompany(userID: $userID, companyIDs: $companyIDs)
}
    `;
export type AssignUserCompanyMutationFn = ApolloReactCommon.MutationFunction<AssignUserCompanyMutation, AssignUserCompanyMutationVariables>;

/**
 * __useAssignUserCompanyMutation__
 *
 * To run a mutation, you first call `useAssignUserCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignUserCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignUserCompanyMutation, { data, loading, error }] = useAssignUserCompanyMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      companyIDs: // value for 'companyIDs'
 *   },
 * });
 */
export function useAssignUserCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignUserCompanyMutation, AssignUserCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignUserCompanyMutation, AssignUserCompanyMutationVariables>(AssignUserCompanyDocument, baseOptions);
      }
export type AssignUserCompanyMutationHookResult = ReturnType<typeof useAssignUserCompanyMutation>;
export type AssignUserCompanyMutationResult = ApolloReactCommon.MutationResult<AssignUserCompanyMutation>;
export type AssignUserCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignUserCompanyMutation, AssignUserCompanyMutationVariables>;
export const UnassignUserCompanyDocument = gql`
    mutation unassignUserCompany($userID: String!, $companyIDs: [String!]!) {
  unassignUserCompany(userID: $userID, companyIDs: $companyIDs)
}
    `;
export type UnassignUserCompanyMutationFn = ApolloReactCommon.MutationFunction<UnassignUserCompanyMutation, UnassignUserCompanyMutationVariables>;

/**
 * __useUnassignUserCompanyMutation__
 *
 * To run a mutation, you first call `useUnassignUserCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignUserCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignUserCompanyMutation, { data, loading, error }] = useUnassignUserCompanyMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      companyIDs: // value for 'companyIDs'
 *   },
 * });
 */
export function useUnassignUserCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnassignUserCompanyMutation, UnassignUserCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<UnassignUserCompanyMutation, UnassignUserCompanyMutationVariables>(UnassignUserCompanyDocument, baseOptions);
      }
export type UnassignUserCompanyMutationHookResult = ReturnType<typeof useUnassignUserCompanyMutation>;
export type UnassignUserCompanyMutationResult = ApolloReactCommon.MutationResult<UnassignUserCompanyMutation>;
export type UnassignUserCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<UnassignUserCompanyMutation, UnassignUserCompanyMutationVariables>;
export const UpdateLastEntityDocument = gql`
    mutation updateLastEntity($companyID: String!) {
  updateLastEntity(companyID: $companyID)
}
    `;
export type UpdateLastEntityMutationFn = ApolloReactCommon.MutationFunction<UpdateLastEntityMutation, UpdateLastEntityMutationVariables>;

/**
 * __useUpdateLastEntityMutation__
 *
 * To run a mutation, you first call `useUpdateLastEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLastEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLastEntityMutation, { data, loading, error }] = useUpdateLastEntityMutation({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useUpdateLastEntityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateLastEntityMutation, UpdateLastEntityMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateLastEntityMutation, UpdateLastEntityMutationVariables>(UpdateLastEntityDocument, baseOptions);
      }
export type UpdateLastEntityMutationHookResult = ReturnType<typeof useUpdateLastEntityMutation>;
export type UpdateLastEntityMutationResult = ApolloReactCommon.MutationResult<UpdateLastEntityMutation>;
export type UpdateLastEntityMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateLastEntityMutation, UpdateLastEntityMutationVariables>;
export const WatermarkViewDocument = gql`
    query WatermarkView($refID: String, $opacity: String) {
  WatermarkView(refID: $refID, opacity: $opacity) {
    fileName
    fileURL
    description
  }
}
    `;

/**
 * __useWatermarkViewQuery__
 *
 * To run a query within a React component, call `useWatermarkViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useWatermarkViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatermarkViewQuery({
 *   variables: {
 *      refID: // value for 'refID'
 *      opacity: // value for 'opacity'
 *   },
 * });
 */
export function useWatermarkViewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<WatermarkViewQuery, WatermarkViewQueryVariables>) {
        return ApolloReactHooks.useQuery<WatermarkViewQuery, WatermarkViewQueryVariables>(WatermarkViewDocument, baseOptions);
      }
export function useWatermarkViewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WatermarkViewQuery, WatermarkViewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<WatermarkViewQuery, WatermarkViewQueryVariables>(WatermarkViewDocument, baseOptions);
        }
export type WatermarkViewQueryHookResult = ReturnType<typeof useWatermarkViewQuery>;
export type WatermarkViewLazyQueryHookResult = ReturnType<typeof useWatermarkViewLazyQuery>;
export type WatermarkViewQueryResult = ApolloReactCommon.QueryResult<WatermarkViewQuery, WatermarkViewQueryVariables>;
export const GetCompanyDocument = gql`
    query getCompany($companyID: String) {
  getCompany(companyID: $companyID) {
    createdTs
    modTs
    CompanyID
    Name
    Code
    CompanyTax
    Description
    CompanyRegNo
    ContactNo
    Email
    SSTNo
    GSTNo
    GSTExpiryDate
    Image
    ImageUrl
    BaseCurrencyID
    CompanyTax
    Address {
      address
      address2
      country
      city
      district
      state
      postCode
    }
    ImageLogoPosition
    WatermarkImplementation
    WatermarkWording
    IsoLogoPosition
    IsoImplementation
    RecordStatus
  }
}
    `;

/**
 * __useGetCompanyQuery__
 *
 * To run a query within a React component, call `useGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, baseOptions);
      }
export function useGetCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, baseOptions);
        }
export type GetCompanyQueryHookResult = ReturnType<typeof useGetCompanyQuery>;
export type GetCompanyLazyQueryHookResult = ReturnType<typeof useGetCompanyLazyQuery>;
export type GetCompanyQueryResult = ApolloReactCommon.QueryResult<GetCompanyQuery, GetCompanyQueryVariables>;
export const GetCompanyWatermarkDocument = gql`
    query getCompanyWatermark($companyID: String) {
  getCompany(companyID: $companyID) {
    CompanyID
    Name
    WatermarkWording
    WatermarkImplementation
  }
}
    `;

/**
 * __useGetCompanyWatermarkQuery__
 *
 * To run a query within a React component, call `useGetCompanyWatermarkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyWatermarkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyWatermarkQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetCompanyWatermarkQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyWatermarkQuery, GetCompanyWatermarkQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyWatermarkQuery, GetCompanyWatermarkQueryVariables>(GetCompanyWatermarkDocument, baseOptions);
      }
export function useGetCompanyWatermarkLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyWatermarkQuery, GetCompanyWatermarkQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyWatermarkQuery, GetCompanyWatermarkQueryVariables>(GetCompanyWatermarkDocument, baseOptions);
        }
export type GetCompanyWatermarkQueryHookResult = ReturnType<typeof useGetCompanyWatermarkQuery>;
export type GetCompanyWatermarkLazyQueryHookResult = ReturnType<typeof useGetCompanyWatermarkLazyQuery>;
export type GetCompanyWatermarkQueryResult = ApolloReactCommon.QueryResult<GetCompanyWatermarkQuery, GetCompanyWatermarkQueryVariables>;
export const GetToDoListingDocument = gql`
    query getToDoListing($contractID: String, $customerID: String, $subcontractID: String, $subcontractorID: String, $supplierID: String) {
  getSubconProgressClaim(subcontractID: $subcontractID, subcontractorID: $subcontractorID) {
    ID
    isGlExport
    accountID
    balanceAdvanceAmt
    balanceDeductionAmt
    balanceRetentionAmt
    baseAmt
    cancellationDate
    cancellationRemarks
    claimDocNo
    contractID
    createdBy
    createdTs
    currencyID
    currentAdvRecoupmentAmt
    currentCertifiedAmt
    currentDayWorkAmt
    currentDeductionAmt
    currentMosAmt
    currentVOClaimAmt
    description
    docAmt
    docDate
    docDueDate
    docNo
    docRef
    docRefID
    docRefTable
    exchangeRate
    localBaseAmt
    localDocAmt
    localTaxAmt
    modBy
    modTs
    previousCertifiedAmt
    previousMosAmt
    previousVOClaimAmt
    rejectionDate
    rejectionRemarks
    retentionAmt
    revisedNo
    sequenceNo
    subcontractID
    subcontractorID
    submittedCertifiedAmt
    submittedDate
    submittedDayWorkAmt
    submittedDeductionAmt
    submittedMosAmt
    submittedRetentionAmt
    submittedVOClaimAmt
    taxAmt
    taxRate
    taxSchemeID
    toDo {
      ID
      accountID
      approvalLevel
      approvalLog {
        ID
        accountID
        approverID
        createdBy
        createdTs
        logDate
        modBy
        modTs
        notifiedDate
        remarks
        toDoID
        toDoStatus
      }
      body
      createdBy
      createdTs
      docRefAmt
      docRefID
      docRefTable
      modBy
      modTs
      notifiedDate
      subject
      submitterID
      toDoStatus
    }
    transactionDate
    transactionStatus
    voDisplayAmt
  }
  getProgressClaim(contractID: $contractID, customerID: $customerID) {
    ID
    isGlExport
    accountID
    contractClaimAllocation {
      ID
      previousCertifiedAmt
      submittedCertifiedAmt
      currentCertifiedAmt
      contractClaimID
      wbsBudgetDetailID
      wbsID
      revenueCategoryID
    }
    contractClaimVOAllocation {
      ID
      contractVOID
      currentVOClaimAmt
      submittedVOClaimAmt
      previousVOClaimAmt
    }
    contractClaimVORevenueWbsAllocation {
      ID
      contractVORevenueWbsID
      currentVOClaimAmt
      submittedVOClaimAmt
      previousVOClaimAmt
    }
    balanceAdvanceAmt
    balanceDeductionAmt
    balanceRetentionAmt
    baseAmt
    cancellationRemarks
    cancellationDate
    claimDocNo
    contractID
    createdBy
    createdTs
    currencyID
    currentAdvRecoupmentAmt
    currentCertifiedAmt
    currentDayWorkAmt
    currentDeductionAmt
    currentMosAmt
    customerID
    currentVOClaimAmt
    description
    docAmt
    docDate
    docDueDate
    docNo
    docRef
    docRefID
    docRefTable
    exchangeRate
    localBaseAmt
    localDocAmt
    localTaxAmt
    modBy
    modTs
    previousCertifiedAmt
    previousMosAmt
    previousVOClaimAmt
    rejectionDate
    rejectionRemarks
    retentionAmt
    revisedNo
    sequenceNo
    submittedCertifiedAmt
    submittedDate
    submittedDayWorkAmt
    submittedDeductionAmt
    submittedMosAmt
    submittedRetentionAmt
    submittedVOClaimAmt
    taxAmt
    taxRate
    taxSchemeID
    toDo {
      ID
      accountID
      approvalLevel
      approvalLog {
        ID
        accountID
        approverID
        createdBy
        createdTs
        logDate
        modBy
        modTs
        notifiedDate
        remarks
        toDoID
        toDoStatus
      }
      body
      createdBy
      createdTs
      docRefAmt
      docRefID
      docRefTable
      modBy
      modTs
      notifiedDate
      subject
      submitterID
      toDoStatus
    }
    transactionDate
    transactionStatus
    voDisplayAmt
  }
  getPurchaseReq(contractID: $contractID) {
    ID
    contractID
    subcontractID
    docNo
    prDate
    expectedDate
    instructions
    purchaseStatus
  }
  getPurchaseOrder(contractID: $contractID) {
    ID
    accountID
    contractID
    subcontractID
    docDate
    instructions
    totalAmt
    purchaseStatus
  }
  getSubcontractPayment(subcontractorID: $subcontractorID, subcontractID: $subcontractID) {
    ID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    bankAccountID
    bankDetails
    receiptStatus
    subcontractorID
    contractID
    subcontractID
    outstandingAmt
  }
  getAPPayment(supplierID: $supplierID) {
    ID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    bankAccountID
    bankDetails
    receiptStatus
    supplierID
    outstandingAmt
  }
  getContractReceipt(contractID: $contractID, customerID: $customerID) {
    ID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    accountID
    bankAccountID
    bankDetails
    receiptStatus
    customerID
    contractID
    outstandingAmt
  }
  getContractVO(contractID: $contractID) {
    ID
    accountID
    contractID
    docNo
    docRef
    startDate
    endDate
    description
    architectNo
    engineeringNo
    VOType
    VOStatus
    submissionAmt
    approvedAmt
    docDate
    remarks
  }
  getSubcontractVO(subcontractID: $subcontractID) {
    ID
    accountID
    subcontractID
    docNo
    docRef
    startDate
    endDate
    description
    architectNo
    engineeringNo
    VOType
    VOStatus
    submissionAmt
    approvedAmt
    remarks
    docDate
  }
}
    `;

/**
 * __useGetToDoListingQuery__
 *
 * To run a query within a React component, call `useGetToDoListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetToDoListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetToDoListingQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetToDoListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetToDoListingQuery, GetToDoListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetToDoListingQuery, GetToDoListingQueryVariables>(GetToDoListingDocument, baseOptions);
      }
export function useGetToDoListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetToDoListingQuery, GetToDoListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetToDoListingQuery, GetToDoListingQueryVariables>(GetToDoListingDocument, baseOptions);
        }
export type GetToDoListingQueryHookResult = ReturnType<typeof useGetToDoListingQuery>;
export type GetToDoListingLazyQueryHookResult = ReturnType<typeof useGetToDoListingLazyQuery>;
export type GetToDoListingQueryResult = ApolloReactCommon.QueryResult<GetToDoListingQuery, GetToDoListingQueryVariables>;
export const CreateContractNewsDocument = gql`
    mutation createContractNews($input: ContractNewsInput!) {
  createContractNews(input: $input) {
    ID
  }
}
    `;
export type CreateContractNewsMutationFn = ApolloReactCommon.MutationFunction<CreateContractNewsMutation, CreateContractNewsMutationVariables>;

/**
 * __useCreateContractNewsMutation__
 *
 * To run a mutation, you first call `useCreateContractNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractNewsMutation, { data, loading, error }] = useCreateContractNewsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractNewsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractNewsMutation, CreateContractNewsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractNewsMutation, CreateContractNewsMutationVariables>(CreateContractNewsDocument, baseOptions);
      }
export type CreateContractNewsMutationHookResult = ReturnType<typeof useCreateContractNewsMutation>;
export type CreateContractNewsMutationResult = ApolloReactCommon.MutationResult<CreateContractNewsMutation>;
export type CreateContractNewsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractNewsMutation, CreateContractNewsMutationVariables>;
export const UpdateContractNewsDocument = gql`
    mutation updateContractNews($input: ContractNewsInput!) {
  updateContractNews(input: $input) {
    ID
  }
}
    `;
export type UpdateContractNewsMutationFn = ApolloReactCommon.MutationFunction<UpdateContractNewsMutation, UpdateContractNewsMutationVariables>;

/**
 * __useUpdateContractNewsMutation__
 *
 * To run a mutation, you first call `useUpdateContractNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractNewsMutation, { data, loading, error }] = useUpdateContractNewsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractNewsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContractNewsMutation, UpdateContractNewsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContractNewsMutation, UpdateContractNewsMutationVariables>(UpdateContractNewsDocument, baseOptions);
      }
export type UpdateContractNewsMutationHookResult = ReturnType<typeof useUpdateContractNewsMutation>;
export type UpdateContractNewsMutationResult = ApolloReactCommon.MutationResult<UpdateContractNewsMutation>;
export type UpdateContractNewsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContractNewsMutation, UpdateContractNewsMutationVariables>;
export const GetContractNewsDocument = gql`
    query getContractNews($ID: String, $orderByAsc: String, $orderByDesc: String) {
  getContractNews(ID: $ID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    ID
    title
    content
    startDate
    endDate
    postStatus
    createdTs
    createdBy
  }
}
    `;

/**
 * __useGetContractNewsQuery__
 *
 * To run a query within a React component, call `useGetContractNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractNewsQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetContractNewsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractNewsQuery, GetContractNewsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractNewsQuery, GetContractNewsQueryVariables>(GetContractNewsDocument, baseOptions);
      }
export function useGetContractNewsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractNewsQuery, GetContractNewsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractNewsQuery, GetContractNewsQueryVariables>(GetContractNewsDocument, baseOptions);
        }
export type GetContractNewsQueryHookResult = ReturnType<typeof useGetContractNewsQuery>;
export type GetContractNewsLazyQueryHookResult = ReturnType<typeof useGetContractNewsLazyQuery>;
export type GetContractNewsQueryResult = ApolloReactCommon.QueryResult<GetContractNewsQuery, GetContractNewsQueryVariables>;
export const GetContractNotificationDocument = gql`
    query getContractNotification($docRefTable: String, $recipientID: String) {
  getContractNotification(docRefTable: $docRefTable, recipientID: $recipientID) {
    ID
    accountID
    docRefID
    docRefTable
    subject
    body
    createdTs
    notificationRecipient {
      isRead
    }
  }
}
    `;

/**
 * __useGetContractNotificationQuery__
 *
 * To run a query within a React component, call `useGetContractNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractNotificationQuery({
 *   variables: {
 *      docRefTable: // value for 'docRefTable'
 *      recipientID: // value for 'recipientID'
 *   },
 * });
 */
export function useGetContractNotificationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractNotificationQuery, GetContractNotificationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractNotificationQuery, GetContractNotificationQueryVariables>(GetContractNotificationDocument, baseOptions);
      }
export function useGetContractNotificationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractNotificationQuery, GetContractNotificationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractNotificationQuery, GetContractNotificationQueryVariables>(GetContractNotificationDocument, baseOptions);
        }
export type GetContractNotificationQueryHookResult = ReturnType<typeof useGetContractNotificationQuery>;
export type GetContractNotificationLazyQueryHookResult = ReturnType<typeof useGetContractNotificationLazyQuery>;
export type GetContractNotificationQueryResult = ApolloReactCommon.QueryResult<GetContractNotificationQuery, GetContractNotificationQueryVariables>;
export const GetContractToDoDocument = gql`
    query getContractToDo($docRefTable: String, $docRefID: String) {
  getContractToDo(docRefTable: $docRefTable, docRefID: $docRefID) {
    ID
    approvalLog {
      ID
      accountID
      toDoID
      approverID
      logDate
      notifiedDate
      toDoStatus
      remarks
    }
  }
}
    `;

/**
 * __useGetContractToDoQuery__
 *
 * To run a query within a React component, call `useGetContractToDoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractToDoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractToDoQuery({
 *   variables: {
 *      docRefTable: // value for 'docRefTable'
 *      docRefID: // value for 'docRefID'
 *   },
 * });
 */
export function useGetContractToDoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractToDoQuery, GetContractToDoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractToDoQuery, GetContractToDoQueryVariables>(GetContractToDoDocument, baseOptions);
      }
export function useGetContractToDoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractToDoQuery, GetContractToDoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractToDoQuery, GetContractToDoQueryVariables>(GetContractToDoDocument, baseOptions);
        }
export type GetContractToDoQueryHookResult = ReturnType<typeof useGetContractToDoQuery>;
export type GetContractToDoLazyQueryHookResult = ReturnType<typeof useGetContractToDoLazyQuery>;
export type GetContractToDoQueryResult = ApolloReactCommon.QueryResult<GetContractToDoQuery, GetContractToDoQueryVariables>;
export const ToDoListingDocument = gql`
    query ToDoListing {
  getToDoListing
}
    `;

/**
 * __useToDoListingQuery__
 *
 * To run a query within a React component, call `useToDoListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useToDoListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useToDoListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useToDoListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ToDoListingQuery, ToDoListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ToDoListingQuery, ToDoListingQueryVariables>(ToDoListingDocument, baseOptions);
      }
export function useToDoListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ToDoListingQuery, ToDoListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ToDoListingQuery, ToDoListingQueryVariables>(ToDoListingDocument, baseOptions);
        }
export type ToDoListingQueryHookResult = ReturnType<typeof useToDoListingQuery>;
export type ToDoListingLazyQueryHookResult = ReturnType<typeof useToDoListingLazyQuery>;
export type ToDoListingQueryResult = ApolloReactCommon.QueryResult<ToDoListingQuery, ToDoListingQueryVariables>;
export const ReadNotificationDocument = gql`
    mutation readNotification($ID: String) {
  readNotification(ID: $ID)
}
    `;
export type ReadNotificationMutationFn = ApolloReactCommon.MutationFunction<ReadNotificationMutation, ReadNotificationMutationVariables>;

/**
 * __useReadNotificationMutation__
 *
 * To run a mutation, you first call `useReadNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readNotificationMutation, { data, loading, error }] = useReadNotificationMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useReadNotificationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReadNotificationMutation, ReadNotificationMutationVariables>) {
        return ApolloReactHooks.useMutation<ReadNotificationMutation, ReadNotificationMutationVariables>(ReadNotificationDocument, baseOptions);
      }
export type ReadNotificationMutationHookResult = ReturnType<typeof useReadNotificationMutation>;
export type ReadNotificationMutationResult = ApolloReactCommon.MutationResult<ReadNotificationMutation>;
export type ReadNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<ReadNotificationMutation, ReadNotificationMutationVariables>;
export const ApproveToDoDocument = gql`
    mutation approveToDo($IDs: [String!]!) {
  approveToDo(IDs: $IDs)
}
    `;
export type ApproveToDoMutationFn = ApolloReactCommon.MutationFunction<ApproveToDoMutation, ApproveToDoMutationVariables>;

/**
 * __useApproveToDoMutation__
 *
 * To run a mutation, you first call `useApproveToDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveToDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveToDoMutation, { data, loading, error }] = useApproveToDoMutation({
 *   variables: {
 *      IDs: // value for 'IDs'
 *   },
 * });
 */
export function useApproveToDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveToDoMutation, ApproveToDoMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveToDoMutation, ApproveToDoMutationVariables>(ApproveToDoDocument, baseOptions);
      }
export type ApproveToDoMutationHookResult = ReturnType<typeof useApproveToDoMutation>;
export type ApproveToDoMutationResult = ApolloReactCommon.MutationResult<ApproveToDoMutation>;
export type ApproveToDoMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveToDoMutation, ApproveToDoMutationVariables>;
export const RejectToDoDocument = gql`
    mutation rejectToDo($IDs: [String!]!, $remark: String!) {
  rejectToDo(IDs: $IDs, remark: $remark)
}
    `;
export type RejectToDoMutationFn = ApolloReactCommon.MutationFunction<RejectToDoMutation, RejectToDoMutationVariables>;

/**
 * __useRejectToDoMutation__
 *
 * To run a mutation, you first call `useRejectToDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectToDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectToDoMutation, { data, loading, error }] = useRejectToDoMutation({
 *   variables: {
 *      IDs: // value for 'IDs'
 *      remark: // value for 'remark'
 *   },
 * });
 */
export function useRejectToDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectToDoMutation, RejectToDoMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectToDoMutation, RejectToDoMutationVariables>(RejectToDoDocument, baseOptions);
      }
export type RejectToDoMutationHookResult = ReturnType<typeof useRejectToDoMutation>;
export type RejectToDoMutationResult = ApolloReactCommon.MutationResult<RejectToDoMutation>;
export type RejectToDoMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectToDoMutation, RejectToDoMutationVariables>;
export const GetNotificationListingDocument = gql`
    query getNotificationListing {
  getNotificationListing
}
    `;

/**
 * __useGetNotificationListingQuery__
 *
 * To run a query within a React component, call `useGetNotificationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNotificationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNotificationListingQuery, GetNotificationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNotificationListingQuery, GetNotificationListingQueryVariables>(GetNotificationListingDocument, baseOptions);
      }
export function useGetNotificationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNotificationListingQuery, GetNotificationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNotificationListingQuery, GetNotificationListingQueryVariables>(GetNotificationListingDocument, baseOptions);
        }
export type GetNotificationListingQueryHookResult = ReturnType<typeof useGetNotificationListingQuery>;
export type GetNotificationListingLazyQueryHookResult = ReturnType<typeof useGetNotificationListingLazyQuery>;
export type GetNotificationListingQueryResult = ApolloReactCommon.QueryResult<GetNotificationListingQuery, GetNotificationListingQueryVariables>;